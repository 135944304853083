import React from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import styled from 'styled-components/macro'
import { MinimalDialog } from './../../components/modal/Modal'
import { useAutoDemo } from './functions'
import { LiveDemo } from './LiveDemo'
import { Button } from '@mission.io/styles'
import { useScreenOrientation } from './../../utility/hooks'
import { config } from '../../config'
import { Link } from 'react-router-dom'

const LAUNCHED_STATE_KEY = 'is_launched'

/**
 * A full screen, automated demo experience of glory of the Mission.io program.
 */
export function AutoDemo(): React$Node {
	const { isLoading, data: autoDemo } = useAutoDemo()
	const location = useLocation()
	const history = useHistory()
	const orientation = useScreenOrientation()

	const isLaunched =
		typeof location.state === 'object' && location.state?.[LAUNCHED_STATE_KEY] === true

	return (
		<AutoDemoWrapper $isPortrait={orientation?.startsWith('portrait')}>
			{!isLaunched || isLoading || !autoDemo ? (
				<LandingPage
					onLaunch={() => history.push(location.pathname, { [LAUNCHED_STATE_KEY]: true })}
					// Only show loading if the user has launched, but the demo is not ready
					loading={isLaunched && isLoading}
				/>
			) : (
				<LiveDemo autoDemo={autoDemo} />
			)}
		</AutoDemoWrapper>
	)
}

const AutoDemoWrapper = styled.div`
	height: 100vh;
	width: 100vw;

	${({ $isPortrait }) =>
		$isPortrait &&
		`
		transform: rotate(-90deg);
		transform-origin: left top;
		width: 100vh;
		height: 100vw;
		overflow-x: hidden;
		position: absolute;
		top: 100%;
		left: 0;
	`}
`

/**
 * The landing page for the auto demo, mainly consisting of a dialog prompting
 * the user to launch the demo.
 */
function LandingPage({ onLaunch, loading = false }: { onLaunch: () => mixed, loading?: boolean }) {
	return (
		<Background>
			<BackgroundVideo />
			<WelcomeDialog onLaunch={onLaunch} loading={loading} />
		</Background>
	)
}

const Background = styled.div`
	height: 100%;
	width: 100%;
`

const BACKGROUND_VIDEO = 'https://resources-cdn.mission.io/public/videos/loginBackgroundVideo.mp4'

function BackgroundVideo() {
	return (
		<div css="background-color: black; position: absolute; width: 100%; height: 100%;">
			<video autoPlay muted css="width: 100%; height: 100%; object-fit: cover;" loop>
				<source src={BACKGROUND_VIDEO} />
			</video>
		</div>
	)
}

/**
 * The welcome dialog for the auto demo. Calls the `onLaunch` prop when the user clicks the Launch button.
 */
function WelcomeDialog({ onLaunch, loading = false }: { onLaunch: () => mixed, loading: boolean }) {
	const orientation = useScreenOrientation()
	const reactModalStyles = {
		overlay: { backgroundColor: 'transparent' },
		content: {
			maxWidth: 'min(80%, 800px)',
			background: 'transparent',
			borderRadius: '8px',
			padding: 0,
		},
	}
	if (orientation?.startsWith('portrait')) {
		reactModalStyles.overlay = {
			...reactModalStyles.overlay,
			transform: 'rotate(-90deg)',
			transformOrigin: 'left top',
			height: '100vw',
			width: '100vh',
			position: 'absolute',
			top: '100%',
			left: 0,
		}
	}

	return (
		<MinimalDialog isOpen style={reactModalStyles} ariaHideApp={false}>
			<div
				className="text-white bg-primary-500"
				css="padding: var(--spacing4x-dont-use); font-size: 1.2rem;">
				<p>
					We’ve all had that student ask, “When am I ever going to actually use this?”
					With {config.companyName.base}, your answer gets to be, “You need to know this
					for our next mission. If you don’t learn this now, you probably won’t survive.”
				</p>
				<p>
					We believe students deserve more than just one way to show their learning. Most
					importantly, we think that learning should be something they actually look
					forward to each day. This demo (which will last 10-20 minutes depending on the
					choices you make) will demonstrate how {config.companyName.base} provides
					whole-student assessment through gamified, problem-based learning.{' '}
					{config.companyName.base} is an assessment your students will beg you to assign.
				</p>
				<div className="mt-8 flex justify-between">
					<Link to="/">
						<img src={config.logos.main} alt="logo" width="200" />
					</Link>

					{loading ? (
						'Loading...'
					) : (
						<Button variant="secondary" onClick={onLaunch}>
							Launch
						</Button>
					)}
				</div>
			</div>
		</MinimalDialog>
	)
}
