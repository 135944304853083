import React from 'react'
import { useEffect, useState } from 'react'
import { LightTheme as theme } from '../styles/theme'
import 'styled-components/macro'
import { MissionInfo, Standards } from './basics'
import { round } from 'lodash'
import { IndividualSELPerformance, GameScoreCard } from './scoring'
import { useRelevantStandardsForSimulation } from '../services/hooks/standards'
import { toast } from 'react-toastify'
import {
	QuestionResponses,
	QuestionTotalsTable,
	type QuestionMap,
	type NameLookup,
	QUESTIONS_DOWNLOAD_DATA,
	CULMINATING_DOWNLOAD_DATA,
} from './questions'
import type { ScoreFormatType } from '../routes/analytics/constants'
import { createAnalyticsForSingleMission } from '../routes/analytics/AnalyticsCharts/dataHelpers'
import * as ReactPdf from '@react-pdf/renderer'

import sharedStyles from './sharedStyles'
import type { AnalyticsObject } from '../models/Analytics'
import type { AutomatedSimulation } from '../models/Simulation'
import type { DBQuestion } from '../models/Question'
import {
	useAnalyticsQuestionColumns,
	type QuestionColumn,
	useAnalyticsCulminatingMomentColumns,
} from '../routes/analytics/AnalyticsCharts/hooks'
import { Button } from '@mission.io/styles'
import {
	PdfStudentResponses,
	PdfQuestionGrid,
	useLoadPdfDependencies,
} from '@mission.io/question-views'
import { saveAs } from 'file-saver'
import { getFullName, getQuestionKey } from '../utility/helpers'
import type { CurriculumStandard } from '../utility/standards'
import ProficiencyBarChartPDF from './barChart'
import type { DownloadQuestionsConfig } from './questions'
import { config } from '../config'
import { getStudentDocumentData } from '../routes/analytics/AnalyticsCharts/MissionDocumentReview'
import { StudentDocuments } from './studentDocuments'

const { Document, Page, StyleSheet, View, Text, Image, Font, PDFViewer } = ReactPdf

// A simple Header used in the PDF document with the mission.io logo and title.
const Header: () => React$Node = () => {
	return (
		<View style={[styles.pageHeader, sharedStyles.row]} fixed>
			<Text>{config.companyName.base} Mission Report</Text>
			<Image style={styles.logo} src={config.logos.pdf} />
		</View>
	)
}

// A simple PDF page break
const PageBreak: () => React$Node = () => {
	return <Text break />
}

// A simple PDF footer that just applies white space between the bottom of the page (appears on every page)
const Footer: () => React$Node = () => {
	return <Text styles={styles.pageFooter} fixed />
}

/**
 * The rendered PDF document that can be downloaded for a specific ran mission and its analytics
 */
const AnalyticsDocument = ({
	nameLookup,
	analyticsData,
	simulation,
	missionId,
	questions,
	culminatingMomentQuestions,
	questionColumns,
	culminatingMomentColumns,
	standards,
	scoreFormat,
}: {
	nameLookup: NameLookup,
	analyticsData: AnalyticsObject,
	simulation: AutomatedSimulation,
	missionId: string,
	questions: DBQuestion[],
	culminatingMomentQuestions: DBQuestion[],
	questionColumns: QuestionColumn[],
	culminatingMomentColumns: QuestionColumn[],
	standards: CurriculumStandard[],
	scoreFormat: ScoreFormatType,
}): React$Node => {
	const individualSelScores = createAnalyticsForSingleMission(analyticsData, nameLookup).display
		.individualSelScores

	const studentDocumentData = getStudentDocumentData(analyticsData.analytics, nameLookup)

	return (
		<Document>
			<Page style={styles.page} orientation="landscape">
				<Header />
				<View style={styles.body}>
					<View style={sharedStyles.row}>
						<MissionInfo analyticsData={analyticsData} simulation={simulation} />
						{standards.length > 0 && <Standards standards={standards} />}
					</View>

					<View style={{ marginTop: '16px' }}>
						<View style={styles.card}>
							<GameScoreCard classAnalytics={analyticsData.analytics} />
						</View>
						<ProficiencyBarChartPDF
							controlSet={analyticsData?.controlSet}
							individualSelScores={individualSelScores}
						/>
					</View>
				</View>
			</Page>
			<Page style={styles.page} wrap>
				<Header />
				<View style={styles.body}>
					<IndividualSELPerformance
						individualSelScores={individualSelScores}
						nameLookup={nameLookup}
						scoreFormat={scoreFormat}
						controlSet={analyticsData?.controlSet}
					/>
					{questionColumns.length && (
						<QuestionsPortion
							analyticsData={analyticsData}
							questions={questions}
							questionColumns={questionColumns}
							nameLookup={nameLookup}
							config={QUESTIONS_DOWNLOAD_DATA}
						/>
					)}
					{culminatingMomentColumns.length && (
						<QuestionsPortion
							analyticsData={analyticsData}
							questions={culminatingMomentQuestions}
							questionColumns={culminatingMomentColumns}
							nameLookup={nameLookup}
							config={CULMINATING_DOWNLOAD_DATA}
						/>
					)}
					{studentDocumentData?.length && (
						<StudentDocuments
							studentDocumentData={studentDocumentData}
							missionId={missionId}
						/>
					)}
				</View>
				<Footer />
			</Page>
		</Document>
	)
}

/**
 * The part of the PDF document that displays the questions and student answers.
 */
const QuestionsPortion = ({
	questions,
	questionColumns,
	analyticsData,
	nameLookup,
	config,
}: {
	analyticsData: AnalyticsObject,
	questionColumns: QuestionColumn[],
	questions: DBQuestion[],
	nameLookup: NameLookup,
	config: DownloadQuestionsConfig,
}): React$Node => {
	const classAnalytics = analyticsData.analytics
	if (!questionColumns.length || !classAnalytics) {
		return null
	}

	let studentAnswerLookup = {}
	let questionAnswerTotalsLookup = {}
	questionColumns.forEach(column => {
		const columnReferenceKey = getQuestionKey(column.questionId, column.questionVersion)

		Object.keys(column.studentAnswerLookup).forEach(studentId => {
			studentAnswerLookup[studentId] ??= {}
			studentAnswerLookup[studentId][columnReferenceKey] ??= []
			studentAnswerLookup[studentId][columnReferenceKey].push(
				...column.studentAnswerLookup[studentId].map(({ score, answer }) => ({
					score,
					answer,
				}))
			)

			const firstResponseScore = column.studentAnswerLookup[studentId][0]?.score
			if (firstResponseScore == null) {
				return
			}

			questionAnswerTotalsLookup[columnReferenceKey] ??= { count: 0, totalScore: 0 }
			questionAnswerTotalsLookup[columnReferenceKey].count += 1
			questionAnswerTotalsLookup[columnReferenceKey].totalScore +=
				firstResponseScore.score === firstResponseScore.perfectScore ? 1 : 0
		})
	})
	const questionScoresMap = Object.keys(questionAnswerTotalsLookup).reduce((map, questionId) => {
		const answerTotals = questionAnswerTotalsLookup[questionId]
		if (answerTotals.count === 0) {
			map[questionId] = null
		} else {
			map[questionId] = `${round((answerTotals.totalScore / answerTotals.count) * 100)}%`
		}
		return map
	}, {})

	const questionMap: QuestionMap = {}
	questions.forEach((question: DBQuestion) => {
		questionMap[getQuestionKey(question._id, question.version)] = question
	})

	let studentNameLookUp = {}
	for (let key in nameLookup) {
		studentNameLookUp[key] = getFullName(nameLookup[key])
	}

	return (
		<>
			<PageBreak />
			<View>
				<Text style={[styles.questionHeader]}>{config.sectionHeader}</Text>
			</View>
			<PdfQuestionGrid
				ReactPdf={ReactPdf}
				questions={questions}
				anchorPrefix={config.anchorPrefix}
				questionPrefix={config.questionPrefix}
			/>
			<QuestionTotalsTable
				classQuestionScores={questionColumns.map(
					column =>
						questionScoresMap[getQuestionKey(column.questionId, column.questionVersion)]
				)}
				config={config}
			/>

			<PageBreak />
			<QuestionResponses
				classAnalytics={classAnalytics}
				questionColumns={questionColumns}
				questionMap={questionMap}
				nameLookup={nameLookup}
				config={config}
			/>
			<PageBreak />
			<PdfStudentResponses
				anchorPrefix={config.anchorPrefix}
				ReactPdf={ReactPdf}
				questions={questions.map(question => ({
					...question,
					_id: getQuestionKey(question._id, question.version),
				}))}
				studentAnswerLookup={studentAnswerLookup}
				nameLookup={studentNameLookUp}
				questionPrefix={config.questionPrefix}
				header={config.individualStudentResponsesHeader}
			/>
		</>
	)
}

// WE MUST REGISTER THE FONTS WITH FONT.REGISTER BECAUSE PDF HAS NO OTHER WAY TO ACCESS OUR FONTS. ALL REACT MEDIA FILES MUST EXIST IN THE PUBLIC DIRECTORY - SEE
// DOCS FOR REACT-PDF FONTS HERE: https://react-pdf.org/fonts#register
Font.register({
	family: 'default',
	fonts: [
		{
			src: '/fonts/OpenSans/regular.ttf',
			fontStyle: 'normal',
			fontWeight: 'normal',
		},
		{
			src: '/fonts/OpenSans/italic.ttf',
			fontStyle: 'italic',
			fontWeight: 'normal',
		},
		{
			src: '/fonts/OpenSans/bold.ttf',
			fontWeight: 'bold',
			fontStyle: 'normal',
		},
		{
			src: '/fonts/OpenSans/light.ttf',
			fontWeight: '200',
			fontStyle: 'normal',
		},
	],
})

// Styles used for the page level of the analytics doc
const styles = StyleSheet.create({
	page: {
		fontFamily: 'default',
	},
	body: {
		padding: `0 32px`,
		fontSize: 10,
	},
	logo: {
		height: 35,
		width: 'auto',
	},
	pageHeader: {
		width: '100%',
		backgroundColor: theme.neutralLight,
		padding: `8px 8px`,
		fontWeight: '700',
		alignItems: 'center',
		marginBottom: '16px',
	},
	pageFooter: {
		marginTop: '16px',
	},
	card: {
		border: `1pt solid ${theme.neutral}`,
	},
	questionHeader: {
		fontWeight: '700',
		fontSize: 15,
		marginTop: 25,
		marginBottom: 25,
	},
})

const workingOnPDFInDev = false

/**
 * A link that allows one to download the analytics as a PDF for a specific mission.
 */
export function DownloadLink({
	selectedMission,
	nameLookup,
	...props
}: {
	simulation: AutomatedSimulation,
	missionId: string,
	missionName: string,
	scoreFormat: ScoreFormatType,
	selectedMission: AnalyticsObject,
	nameLookup: NameLookup,
}): React$Node {
	const [questionColumns, questionMap, isLoading] = useAnalyticsQuestionColumns()
	const [culminatingMomentColumns, culminatingMomentMap] = useAnalyticsCulminatingMomentColumns()
	const [isSaving, setIsSaving] = useState(false)

	const questions: DBQuestion[] = (Object.values(questionMap): any[])
	const culminatingMomentQuestions: DBQuestion[] = (Object.values(culminatingMomentMap): any[])

	const {
		loaded: loadedPDFDependencies,
		error: errorLoadingPDFDependencies,
	} = useLoadPdfDependencies()

	useEffect(() => {
		if (errorLoadingPDFDependencies) {
			console.error({ errorLoadingPDFDependencies })
			toast.error(
				'There was a problem loading the analytics pdf generator. The pdf may not be correct.'
			)
		}
	}, [errorLoadingPDFDependencies])

	const standards = useRelevantStandardsForSimulation(props.simulation)
	const generateAndSavePdfDocument = async () => {
		if (isSaving) {
			toast.info('Your PDF is currently generating. This can take a few minutes.')
			return
		}
		if (!standards) {
			toast.info('Your data is still loading. Please wait a few seconds and try again.')
			return
		}
		setIsSaving(true)
		try {
			toast.info('Generating your PDF. This can take a few minutes.')
			const blob = await ReactPdf.pdf(
				<AnalyticsDocument
					{...props}
					analyticsData={selectedMission}
					nameLookup={nameLookup}
					questions={questions}
					culminatingMomentQuestions={culminatingMomentQuestions}
					questionColumns={questionColumns}
					culminatingMomentColumns={culminatingMomentColumns}
					standards={standards}
				/>
			).toBlob()
			saveAs(blob, `Analytics - ${props.missionName}.pdf`)
		} catch (err) {
			console.error(err)
			toast.error(`An error occurred while generating your pdf.`)
		}
		setIsSaving(false)
	}

	if (workingOnPDFInDev && standards) {
		return (
			<PDFViewer
				style={{
					position: 'fixed',
					top: 0,
					left: 0,
					width: '100vw',
					height: '100vh',
					zIndex: 100,
				}}>
				<AnalyticsDocument
					{...props}
					analyticsData={selectedMission}
					nameLookup={nameLookup}
					questions={questions}
					culminatingMomentQuestions={culminatingMomentQuestions}
					questionColumns={questionColumns}
					culminatingMomentColumns={culminatingMomentColumns}
					standards={standards}
				/>
			</PDFViewer>
		)
	}

	const loadingDependencies =
		isLoading ||
		// we can generate a pdf even if the dependencies fail to load
		(!loadedPDFDependencies && !errorLoadingPDFDependencies) ||
		!standards

	return (
		<Button
			disabled={loadingDependencies}
			onClick={() => generateAndSavePdfDocument()}
			className="py-0.5 px-1 [&_a]:text-primary-50">
			{loadingDependencies
				? 'Downloading Data...'
				: isSaving
				? 'Generating PDF'
				: 'Download PDF'}
		</Button>
	)
}
