import React from 'react'

import type { SchoolsInDistrict } from '../LeaderBoardAnalytics/types'
import { useSchoolsInLeaderBoardAnalytics } from './LeaderBoardAnalyticsHooks'
import { Select } from '../../../components/inputs/Select.jsx'

type SelectOption = {
	label: string,
	value: string | null,
}

// todo: replace this component with SchoolPicker
/**
 * District Admins can choose what schools they want specific analytics for (as if they were a schoolAdmin).
 * We make a ReactSelect dropdown for them so they can choose schools.
 * Selecting a school updates the analytics.
 */
export function AnalyticsSelector({
	schoolId,
	setSchoolId,
}: {
	schoolId: string | null,
	setSchoolId: (schoolId: string | null) => mixed,
}): React$Node {
	// We don't want to pass the schoolId here because we want to get the district analytics so that we can have the school list.
	const schoolsInDistrict: ?SchoolsInDistrict = useSchoolsInLeaderBoardAnalytics()

	const schoolSelectOptions = createSchoolSelectOptions(schoolsInDistrict)

	const selectedOption = schoolSelectOptions.find(option => option.value === schoolId)

	return (
		<Select
			className="w-1/5 mr-6 inline-block ml-4 text-base"
			defaultValue={schoolSelectOptions[0]}
			value={selectedOption}
			onChange={(chosenOption: SelectOption) => {
				setSchoolId(chosenOption.value)
			}}
			options={schoolSelectOptions}
		/>
	)
}

/**
 * Creates the dropdown list for the DistrictAdmin School dropdown.
 * The default value is used to get the default analytics (All Schools),
 * And then if any schools exist, we append Select options for each school in
 * the district.
 */
function createSchoolSelectOptions(schoolsInDistrict: ?SchoolsInDistrict): Array<SelectOption> {
	let defaultSelectOption = { label: 'View All Schools', value: null }
	if (!schoolsInDistrict) {
		return [defaultSelectOption]
	}

	let schoolSelectOptions = []
	schoolsInDistrict.forEach(({ schoolName, schoolId }) => {
		schoolSelectOptions.push({ label: schoolName, value: schoolId })
	})
	schoolSelectOptions.sort((a, b) => a.label.localeCompare(b.label))

	return [defaultSelectOption, ...schoolSelectOptions]
}
