import React from 'react'
import { Button } from '@mission.io/styles'
import MdLock from 'react-icons/lib/md/lock'
import FaClock from 'react-icons/lib/fa/clock-o'
import classnames from 'classnames'

/**
 * LockableButton - a wrapper which allows a button to be locked and greyed out
 *
 * @param {boolean} isLocked - true to display a lock on the bottom right of the button
 * @param {boolean = false} isUnlockedDueToPromotion? - true if the action is only unlocked due to a promotion
 * @param {() => void} onLockClick - a callback for when the icon is clicked when `isLocked` is `true`
 * @param {() => void | string} onUnlockClick - a callback for when the icon is clicked when `isLocked` is `false`. If
 *                                   it's a string, make the button a link and use the string as the `href` attribute
 * @param {React$Node} children - the children of the component
 * @param {boolean} greyOut - mock disabled styles but still allow clicking
 */
export function LockableButton({
	isLocked,
	isUnlockedDueToPromotion = false,
	onUnlockClick,
	onLockClick,
	children,
	greyOut,
	className,
	...passThroughProps
}: {
	isLocked: boolean,
	isUnlockedDueToPromotion: boolean,
	onUnlockClick: (() => void) | string,
	onLockClick: () => void,
	children: React$Node,
	greyOut: boolean,
	className?: string,
	outline?: boolean,
	variant?: 'primary' | 'secondary',
}): React$Node {
	const props = {}
	if (isLocked) {
		props.onClick = onLockClick
	} else if (typeof onUnlockClick !== 'string') {
		props.onClick = onUnlockClick
	}
	const button = (
		<Button
			{...passThroughProps}
			{...props}
			className={classnames(
				'relative text-center flex items-center gap-1',
				greyOut && 'opacity-75',
				className
			)}>
			{isLocked ? (
				<MdLock aria-label="locked" />
			) : isUnlockedDueToPromotion ? (
				<FaClock aria-label="locked" />
			) : null}
			{children}
		</Button>
	)

	if (typeof onUnlockClick === 'string' && !isLocked) {
		return (
			<a className={className} href={onUnlockClick}>
				{button}
			</a>
		)
	}

	return button
}
