const months = [
	'January',
	'February',
	'March',
	'April',
	'May',
	'June',
	'July',
	'August',
	'September',
	'October',
	'November',
	'December',
]

/**
 * Format date takes a string date and spits out a readable one
 * @return {String} A displayable date in string format
 */
export function getLongDate(stringDate: string): string {
	const date = new Date(stringDate)
	return months[date.getMonth()] + ' ' + date.getDate() + ', ' + date.getFullYear()
}

/**
 * Format string timestamp to string time to display
 * @return {String} a displayable time in string format
 */
export function getTime(stringDate: string): string {
	const date = new Date(stringDate)
	const minutes = date.getMinutes()
	return date.getHours() + ':' + (minutes < 10 ? '0' + minutes : minutes)
}
