import React, { useState } from 'react'
import Modal, { ModalHeader, ExitX, ModalBody, ModalFooter } from '../../components/modal/Modal'
import { Button } from '@mission.io/styles'
import styled from 'styled-components/macro'
import Loading from '../../components/loading/Loading'
import {
	CLASS_STATUS,
	useActivateClasses,
	useUserClasses,
	useRemovePendingClasses,
} from '../../services/hooks/classes'
import EmptyClassImage from '../../assets/EmptyClassImage.png'
import { ErrorLinkButton, ErrorOccurred } from '../../components/Error'
import GoogleClassPresenter from './GoogleClassPresenter'
import type { QueryObserverResult } from 'react-query'
import type { ClassType } from '../../models/ClassType'
import { toast } from 'react-toastify'

/**
 * Modal that allows a user to select which Google classes the user would like to activate.
 */
export default function AddGoogleClassModal({
	isOpen,
	onClose,
	refetchUserClasses,
}: {
	isOpen: boolean,
	onClose: () => void,
	refetchUserClasses: () => Promise<QueryObserverResult<Array<ClassType>, mixed>>,
}): React$Node {
	const { data: pendingClasses, isLoading, isError, refetch } = useUserClasses({
		statuses: [CLASS_STATUS.PENDING, CLASS_STATUS.WAS_ARCHIVED_NOW_PENDING],
	})

	const [selectedClassesIds, setSelectedClassesIds] = useState([])

	const activateClassesMutation = useActivateClasses()
	const removePendingClasses = useRemovePendingClasses()

	const addClasses = () => {
		activateClassesMutation.mutate(selectedClassesIds, {
			onSuccess: ({ errors }) => {
				refetchUserClasses()
				onClose()
				errors?.forEach(err => {
					console.error(err)
					toast.error(err.message)
				})
			},
		})
	}

	const onCancel = () => {
		removePendingClasses.mutate(null, {
			onSuccess: ({ errors }) => {
				errors?.forEach(err => {
					console.error(err)
					toast.error(err.message)
				})
			},
		})
		onClose()
	}

	return (
		<Modal isOpen={isOpen} onRequestClose={onCancel}>
			<ModalHeader>
				Select Google Classes to Add
				<ExitX
					onClick={onCancel}
					role={'button'}
					aria-label={'Close Add Google Class Modal'}
				/>
			</ModalHeader>
			<ModalBody>
				<BodyWrapper>
					{isLoading ? (
						<>
							<StyledLoader />
							<div>Loading your google classes</div>
						</>
					) : isError || activateClassesMutation.error ? (
						<div>
							<ErrorOccurred>
								Failed to {isError ? 'load' : 'add'} classes
								<ErrorLinkButton
									onClick={() => {
										if (isError) refetch()
										else addClasses()
									}}>
									Retry
								</ErrorLinkButton>
							</ErrorOccurred>
						</div>
					) : (
						<>
							{pendingClasses && pendingClasses.length > 0 && (
								<GoogleClassPresenter
									classes={pendingClasses || []}
									selectedClassesIds={selectedClassesIds}
									setSelectedClassesIds={setSelectedClassesIds}
								/>
							)}
							{pendingClasses?.length === 0 && (
								<div>
									<img
										src={EmptyClassImage}
										css="height: 20vw; margin-right: 5vw; padding: 24px;"
										alt=""
									/>
									<p>Looks like you don’t have any Google Classes to add! </p>
								</div>
							)}
						</>
					)}
				</BodyWrapper>
			</ModalBody>
			<ModalFooter>
				<Button outline onClick={onCancel}>
					Cancel
				</Button>
				<Button
					onClick={() => {
						if (selectedClassesIds.length < 1) {
							toast.warn('Please select at least one class to add.')
							return
						}

						addClasses()
					}}>
					{activateClassesMutation.isLoading ? <StyledLoader /> : 'Add Classes'}
				</Button>
			</ModalFooter>
		</Modal>
	)
}

const StyledLoader = styled(Loading)`
	max-width: 10vw;
`

const BodyWrapper = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	width: 100%;
	justify-content: center;
	text-align: center;
`
