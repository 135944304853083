import React from 'react'
import { useMemo } from 'react'
import { useUserClasses } from './../services/hooks/classes'
import type { ClassType } from '../models/ClassType'
import { useUser } from '../services/hooks'
import styled from 'styled-components'
import { getClassOwnerName, splitClassesByOwner } from '../services/hooks/classes'
import { ErrorLinkButton, ErrorOccurred } from './Error'
import { Select } from './inputs/Select'

/**
 * A select component that lets the user select a class from their existing classes.
 */
export function ClassSelector({
	onSelect,
	selectedClass,
	...passThroughProps
}: {
	onSelect: ClassType => mixed,
	selectedClass: ?ClassType,
}): React$Node {
	const {
		data: classes,
		isLoading: isLoadingClasses,
		isError: hadErrorLoadingClasses,
		refetch,
	} = useUserClasses()

	const { user } = useUser()

	const groups = useMemo(
		() =>
			splitClassesByOwner(classes ?? []).sort((groupA, groupB) => {
				if (groupA.owner._id === user?._id) {
					return -1
				}
				return getClassOwnerName(groupA.owner).localeCompare(
					getClassOwnerName(groupB.owner)
				)
			}),
		[classes, user]
	)

	const isError = hadErrorLoadingClasses

	return (
		<>
			<Select
				{...passThroughProps}
				placeholder={
					isError ? 'Error' : isLoadingClasses ? 'Loading classes' : 'Choose a class'
				}
				value={
					selectedClass
						? {
								value: selectedClass._id,
								label: <Label classRoom={selectedClass} />,
								_class: selectedClass,
						  }
						: null
				}
				onChange={({ value }) => {
					const chosenClass = classes?.find(_class => _class._id === value)
					if (chosenClass) {
						onSelect(chosenClass)
					}
				}}
				options={groups.map(group => ({
					label: group.owner._id === user?._id ? '' : getClassOwnerName(group.owner),
					options: group.classes
						.sort((a, b) => a.name.localeCompare(b.name))
						.map(_class => ({
							value: _class._id,
							label: <Label classRoom={_class} />,
							_class,
						})),
				}))}
				isLoading={isLoadingClasses}
				isDisabled={isError}
				menuPortalTarget={document.body}
				menuPosition="fixed"
				styles={{
					menuPortal: base => ({ ...base, zIndex: 9999 }),
					singleValue: base => ({ ...base, width: '100%', padding: '4px' }),
				}}
				filterOption={({ data }, filter) => {
					if (!filter) {
						return true
					}
					const lowerCaseFilter = filter.toLowerCase()
					return (
						data._class.name.toLowerCase().includes(lowerCaseFilter) ||
						data._class.owners.some(owner =>
							getClassOwnerName(owner)
								.toLocaleLowerCase()
								.includes(lowerCaseFilter)
						)
					)
				}}
			/>
			{isError ? (
				<ErrorOccurred>
					Failed to load classes.
					<ErrorLinkButton onClick={() => refetch()}>Retry</ErrorLinkButton>
				</ErrorOccurred>
			) : null}
		</>
	)
}

/**
 * Label - a custom option renderer to show the class name and the owners name (if the owner is not the current user)
 *
 * @param props - the react props
 * @param  {ClassType} props.classRoom - the class room to create the label for
 *
 * @returns React$Node - a react select label component
 */
function Label({ classRoom }: { classRoom: ClassType }): React$Node {
	const { user: currentUser } = useUser()

	if (!currentUser || classRoom.owners.some(({ _id }) => _id === currentUser._id)) {
		return classRoom.name
	}

	return (
		<LabelWrapper>
			<div>{classRoom.name}</div>
			<UserName>
				{classRoom.owners
					.filter(({ _id }) => _id !== currentUser._id)
					.map(owner => getClassOwnerName(owner))
					.join(', ')}
			</UserName>
		</LabelWrapper>
	)
}

const LabelWrapper = styled.div`
	display: flex;
	justify-content: space-between;
	flex: 1 1 100%;
`

const UserName = styled.div`
	opacity: 0.5;
`
