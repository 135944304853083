import React from 'react'
import styled from 'styled-components'
import { Button } from '@mission.io/styles'
import type { StyledType } from 'global-types'
import classnames from 'classnames'

export const GoBackButton: StyledType<> = styled(Button)`
	${({ theme }) => `
		margin-right: var(--spacing1x-dont-use);
	`}
`

export function IconCircleWrapper({
	className,
	$greyOut,
	...props
}: {
	className?: string,
	$greyOut?: boolean,
}): React$Node {
	return (
		<button
			{...props}
			className={classnames(
				'border-none disabled:opacity-50 rounded-full p-2 flex justify-center items-center bg-primary-200 text-primary-950',
				$greyOut ? 'opacity-50' : 'hover:brightness-90 active:brightness-75',
				className
			)}
		/>
	)
}

export function PageTitleHeader({ className, ...props }: { className?: string }): React$Node {
	return <div {...props} className={classnames(className, 'mb-4 pt-3 text-4xl')} />
}

export function SpinnerHolder({ className, ...props }: { className?: string }): React$Node {
	return <div {...props} className={classnames(className, 'flex justify-center mt-16')} />
}

export const Spinner: StyledType<> = styled.div`
	height: 6rem !important;
	width: 6rem !important;
	${({ theme }) => `
		color: ${theme.primary};
	`}
`
