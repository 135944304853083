import React from 'react'
import { Text, View, Image } from '@react-pdf/renderer'
import config from '../config'
import styles from './sharedStyles'
import { Check, Close, Unknown } from './questions'
import { LightTheme as theme } from '../styles/theme'

import type { DocumentData } from '../routes/analytics/AnalyticsCharts/MissionDocumentReview'

/**
 * PDF Formatted view for the student documents. Uses components from react-pdf to display and style each document, student names, document prompt, and
 * the rubric criteria for each document.
 * @param {DocumentData[]} props.studentDocumentData
 * @param {string} props.missionId
 * @returns {React$Node}
 */
export function StudentDocuments({
	studentDocumentData,
	missionId,
}: {
	studentDocumentData: DocumentData[],
	missionId: string,
}): React$Node {
	return (
		<View>
			<Text style={[styles.h5]}>Student Documents</Text>

			{studentDocumentData.map(data => (
				<View
					wrap={false}
					key={data.documentId}
					style={{ marginBottom: '24px', width: '100%' }}>
					<View
						style={{
							borderRadius: '12px',
							backgroundColor: theme._cloudBlue,
							color: theme._deepBlue,
							paddingLeft: '8px',
							paddingRight: '8px',
						}}>
						<Text style={[styles.h6]}>Prompt: {data.latest.issue.prompt}</Text>
						<View
							style={[
								styles.h6,
								{
									display: 'flex',
									flexDirection: 'row',
									marginTop: 0,
									flexWrap: 'wrap',
								},
							]}>
							{data.latest.studentNames.map(name => (
								<View
									key={name}
									style={{
										borderRadius: '12px',
										backgroundColor: theme._horizonBlue,
										color: theme._cloudBlue,
										paddingLeft: '8px',
										paddingRight: '8px',
										marginLeft: '8px',
									}}>
									<Text>{name}</Text>
								</View>
							))}
						</View>
					</View>
					<View style={{ display: 'flex', flexDirection: 'row', marginTop: '8px' }}>
						<View style={{ flex: '3' }}>
							<SVGImage
								src={`${config.missionsAPIURL}/api/creative-canvas-image/${data.documentId}?missionId=${missionId}`}
							/>
						</View>
						<View style={{ flex: '1', marginLeft: '8px' }}>
							{data.latest.issue.rubric.criteria.map(criteria => {
								const selectedOption = criteria.gradingOptions.find(
									option =>
										option.id ===
										data.latest.score.criteriaId[criteria.id]?.selectedOption
								)
								return (
									<View
										key={criteria.id}
										style={{
											display: 'flex',
											alignItems: 'center',
											flexDirection: 'row',
											borderRadius: '4px',
											borderWidth: '2px',
											borderColor:
												selectedOption?.correct == null
													? theme.neutral
													: selectedOption.correct
													? theme.success
													: theme.error,
											paddingLeft: '4px',
											paddingRight: '8px',
											marginBottom: '4px',
											color: theme._deepBlue,
										}}>
										{selectedOption?.correct == null ? (
											<Unknown />
										) : selectedOption.correct ? (
											<Check />
										) : (
											<Close />
										)}
										<Text style={{ marginLeft: '4px' }}>{criteria.text}</Text>
									</View>
								)
							})}
						</View>
					</View>
				</View>
			))}
		</View>
	)
}

const failedToLoadImageUrl =
	'https://resources-cdn.mission.io/public/pdf-resources/images/failedToLoad.png'

/**
 * Component that renders an SVG image from a URL. If the image fails to load, it will display a default image.
 * @param {string} props.src

 * @returns
 */
const SVGImage = ({ src, ...rest }: { src: string }): React$Node => {
	return (
		<Image
			{...rest}
			src={() =>
				fetchSVGString(src).then(result => {
					if (result) {
						if (result.mimetype === 'svg+xml') {
							return svgToDataUri(result.text).then(dataUri => {
								if (dataUri) {
									return dataUri
								} else {
									return failedToLoadImageUrl
								}
							})
						} else if (result.mimetype === 'png') {
							return result.blob
						} else if (result.mimetype === 'jpeg') {
							return result.blob
						}
					} else {
						return failedToLoadImageUrl
					}
				})
			}
		/>
	)
}

/**
 * Uses fetch to get the SVG string from the given url
 * @param {string} url
 * @returns {Promise<{ mimetype: 'png' | 'jpeg', blob: Blob} | {mimetype: 'svg+xml', text: string } | null> } The SVG string or null if there was an error
 */
async function fetchSVGString(
	url: string
): Promise<
	{ mimetype: 'png' | 'jpeg', blob: Blob } | { mimetype: 'svg+xml', text: string } | null
> {
	try {
		// Fetch the SVG from the URL
		const response = await fetch(url, {
			credentials: 'include',
			headers: {
				'X-Mission-Origin': window.location.href,
			},
		})
		if (!response.ok) {
			throw new Error('Network response was not ok')
		}
		// Check if the response is an SVG
		const contentType = response.headers.get('content-type')
		if (contentType) {
			if (contentType.includes('image/svg+xml')) {
				const text = await response.text()
				return { mimetype: 'svg+xml', text }
			} else if (contentType.includes('image/png')) {
				const blob = await response.blob()
				return { mimetype: 'png', blob }
			} else if (contentType.includes('image/jpeg')) {
				const blob = await response.blob()
				return { mimetype: 'jpeg', blob }
			} else {
				throw new Error(
					`Response content type is not in an accepted format: ${contentType}.`
				)
			}
		} else {
			throw new Error('Could not determine content type for the response')
		}
	} catch (error) {
		console.error('Error fetching or converting the SVG:', error)
		return null
	}
}

/**
 * Converts the SVG string to a data URI which can be rendered as an image in react-pdf.
 * Uses the npm package canvg to render the SVG on a canvas and then convert the canvas to a data URI.
 * @param {?string} svgString
 * @returns {Promise<string>}
 */
const svgToDataUri = async (svgString: ?string): Promise<?string> => {
	try {
		if (!svgString) {
			return null
		}
		const canvas: HTMLCanvasElement = document.createElement('canvas')
		const context = canvas.getContext('2d')
		if (context) {
			// When we switch to typescript use actual types instead of any
			const Canvg: any = await import('canvg').then(({ Canvg }) => Canvg)

			const v = Canvg.fromString(context, svgString.trim())
			await v.render()
			const dataUri = canvas.toDataURL('image/png')
			return dataUri
		}
	} catch (error) {
		console.error('Could not convert svg to png uri:', error)
		return null
	}
}
