// @flow
import React, { useEffect, useMemo } from 'react'
import CustomModal from './modal/Modal'
import { useQuery, type UseQueryResult } from 'react-query'
import styled from 'styled-components/macro'
import axios from 'axios'
import config from '../config'
import Loading from './loading/Loading'
import { useUserStandardState } from '../services/hooks/user'

/**
 * MissionGuideModal - a model which shows the simulation's mission guide
 */
export default function MissionGuideModal({
	simulationId,
	isOpen,
	onRequestClose,
}: {
	simulationId: string,
	isOpen: boolean,
	onRequestClose: () => mixed,
}): React$Node {
	const { data: missionGuide } = useMissionGuide(simulationId)

	const url = useMemo(() => missionGuide && URL.createObjectURL(missionGuide), [missionGuide])

	useEffect(() => {
		return () => {
			if (url) {
				URL.revokeObjectURL(url)
			}
		}
	}, [url])

	return (
		<CustomModal
			isOpen={isOpen}
			style={{
				content: { maxHeight: '95vh', top: '2.5vh', height: '95vh', overflow: 'hidden' },
			}}
			onRequestClose={() => {
				onRequestClose()
			}}>
			{!url ? (
				<CenterText>
					<Loading />
					Loading Mission Guide
				</CenterText>
			) : (
				<iframe src={url} css="width: 100%; height: 100%;" title="Mission Guide" />
			)}
		</CustomModal>
	)
}

const CenterText = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 100%;
`

/**
 * Use react-query to get the mission guide for a simulation
 */
export function useMissionGuide(simulationId: ?string): UseQueryResult<Blob, Error> {
	const state = useUserStandardState()

	return useQuery(
		['missionGuide', simulationId, state],
		() => {
			let url = `${config.simulationsApiUrl}/api/simulations/${String(
				simulationId
			)}/guide.pdf`
			if (state) {
				url += `?state=${state}`
			}
			return axios
				.get(url, {
					responseType: 'blob',
				})
				.then(res => res.data)
		},
		{
			staleTime: Infinity,
			enabled: !!simulationId,
		}
	)
}
