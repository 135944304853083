import React from 'react'
import AsyncSelect from 'react-select/async'
import { debounce } from '../../utility/functions'
import NetworkCommunicator from '../../services/NetworkCommunicator'
import config from '../../config'
import styled from 'styled-components'

import type { School } from './types'
import { AppReactSelectClassNames, AppReactSelectTheme } from '../../components/inputs/styles'

type FetchSchoolsArg = {
	inputValue: string,
	districtId?: string,
	callback: (School[]) => void,
	state?: string,
}

/**
 * Fetches schools from the server based on a query string and a districtId.
 * If no district ID is provided, then no schools are fetched.
 * Calls a given callback function with the result from the query
 * @param {string} inputValue
 * @param {?string} districtId
 * @param {(Array<{ schoolName: string, schoolId: string }>) => void} callback
 *  */
function fetchSchoolsWithoutDebounce({
	inputValue,
	districtId,
	state,
	callback,
}: FetchSchoolsArg): void {
	if (!districtId && !state) {
		callback([])
	} else {
		const searchParams = new URLSearchParams({ text: inputValue })
		if (districtId) {
			searchParams.append('districtId', districtId)
		}
		if (state) {
			searchParams.append('state', state)
		}

		NetworkCommunicator.GET(`/api/schools?${searchParams.toString()}`, {
			host: config.loginServer,
		}).then(response => {
			callback(
				response.map(school => ({
					schoolName: school.name,
					schoolId: school._id,
					districtName: school.district,
				}))
			)
		})
	}
}

const fetchSchools: (arg: FetchSchoolsArg) => void = debounce(fetchSchoolsWithoutDebounce, 500)

// A custom form component to select a school. The picker will only fetch schools that belong to the user's district.
export default function SchoolPicker({
	value,
	onChange,
	placeholder,
	districtId,
	state,
	...rest
}: {
	value: ?School,
	onChange: (?School) => void,
	placeholder?: string,
	districtId?: string,
	state?: string,
}): React$Node {
	return (
		<AsyncSelect
			{...rest}
			name="school"
			value={value}
			onChange={onChange}
			placeholder={placeholder || 'Type the school name'}
			loadOptions={(inputValue: string, callback: (School[]) => void) => {
				fetchSchools({ inputValue, districtId, state, callback })
			}}
			getOptionLabel={option => (
				<span>
					{option.schoolName}{' '}
					{option.districtName ? (
						<DistrictName>- {option.districtName}</DistrictName>
					) : null}
				</span>
			)}
			getOptionValue={option => option.schoolId}
			isSearchable
			cacheOptions
			menuPortalTarget={document.body}
			noOptionsMessage={({ inputValue }: { inputValue: string }) =>
				!inputValue?.length
					? `Start typing your school's name`
					: 'No school matches your search'
			}
			theme={AppReactSelectTheme}
			classNames={AppReactSelectClassNames}
		/>
	)
}

const DistrictName = styled.span`
	opacity: 0.5;
`
