import React from 'react'
import styled from 'styled-components/macro'

type Props = {
	shouldShowSpinner: boolean,
	beforeActionText?: string,
	style?: { [string]: string },
	className?: string,
}

/**
 * Custom spinner that takes in display text and styles to be customizable.
 */
export default function LoadingSpinner({
	shouldShowSpinner,
	beforeActionText = '',
	className,
	style = {},
}: Props): React$Node {
	return shouldShowSpinner ? (
		<div css="display: flex; justify-content: center;">
			<LoaderSpinner style={style} className={className} aria-label="Loading" />
		</div>
	) : (
		beforeActionText
	)
}

const LoaderSpinner = styled.div`
	border-radius: 50%;
	width: 20px;
	height: 20px;

	@keyframes spin {
		0% {
			transform: rotate(0deg);
		}
		100% {
			transform: rotate(360deg);
		}
	}
	animation: spin 2s linear infinite;
	border: 4px solid var(--app-lightest-gray);
	border-top: 4px solid var(--primary-dark);
`
