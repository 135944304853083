import React from 'react'
import { useLocation } from 'react-router-dom'
import styled from 'styled-components/macro'
import MissionLibrary from './MissionLibrary'
import MissionReview from './MissionReview'
import { MissionPreppedDialog } from './MissionPreppedDialog'
import { useUser } from '../../services/hooks'
import { useQueryNotifications } from '../../utility/hooks'

export default function MissionLibraryMain(): React$Node {
	useQueryNotifications('linkError')
	const location = useLocation()
	const { user } = useUser()

	const preppedMissionId: ?string =
		(typeof location.state === 'object' &&
			typeof location.state?.preppedMissionId === 'string' &&
			location.state.preppedMissionId) ||
		undefined

	const isLoggedIn = Boolean(user)

	return (
		<>
			<MissionLibraryWrapper>
				<MissionLibraryStyles $isLoggedIn={isLoggedIn} />
				{isLoggedIn && <MissionReview />}
			</MissionLibraryWrapper>
			{preppedMissionId && <MissionPreppedDialog missionId={preppedMissionId} />}
		</>
	)
}

const MissionLibraryWrapper = styled.div`
	display: flex;
	flex-direction: row;
	height: 100%;
	width: 100%;
`

const MissionLibraryStyles = styled(MissionLibrary)`
	width: 100%;

	${({ theme, $isLoggedIn }) => `
		margin-bottom: var(--spacing4x-dont-use);

	`}
`
