import { gradeLabelMap, gradeLevelAbbreviationMap } from '../routes/classes/constants'
import { groupStandardsForState, type CurriculumStandard } from './standards'

import { GRADE_ARRAY } from '../store/missionPrep'
import type { EventTypes, SimpleMission } from '../models/Mission'
import type { AutomatedSimulation } from '../models/Simulation'
import type { StandardSet } from './standards'
import { AUTOMATED_SIMULATION_STATUSES } from '../constants'
import { LightTheme } from '../styles/theme'

// A configuration for react-query. Makes it so the query does not retry if a 401 error is returned from a query
// TODO: Put this retry config in the react-query QueryClient object when we update react-query
export const smartQueryRetry = {
	retry: (count: number, error: mixed): boolean => {
		if (typeof error === 'object' && error?.status === 401) {
			return false
		}
		return count >= 3
	},
}

/**
 * This function sorts a list of missions based on a given event type (i.e. 'BEGIN_PREP' or 'END_MISSION')
 * It finds an event in the mission with the given event type, and sorts the missions
 * based on that event's timestamp from more recent to oldest.
 * 'END_MISSION' is a useful event type if the mission is already complete.
 * If the mission is not yet complete, 'BEGIN_PREP' is useful.
 */
export function sortMissions(missionList: SimpleMission[], eventType: EventTypes): SimpleMission[] {
	missionList.sort((a, b) => {
		const aEvent = getEventWithType(a.events, eventType)
		const bEvent = getEventWithType(b.events, eventType)
		const aEndDate = aEvent ? new Date(aEvent.timestamp) : 0
		const bEndDate = bEvent ? new Date(bEvent.timestamp) : 0

		return bEndDate - aEndDate
	})
	return missionList
}

/**
 * Finds and returns an event with the given type in a list of events
 */
export function getEventWithType<S, U: { type: S }>(events: U[], type: S): ?U {
	return events.find(event => event.type === type)
}

/**
 * Converts a list of grades into a string for display. With one grade, the
 * string will be 'Kindergarten', '1st Grade', '2nd Grade', etc. With multiple grades
 * the grades will show in ranges. e.g. 'Grades K-3', 'Grades 4-9', 'Grades 2-3, 6-8', etc.
 * Expects grades to be a list of strings containing the grade in the format of
 * GRADE_ARRAY in store/missionPrep/index.js
 */
export function getGradesString(grades: string[]): string {
	if (grades.length === 1) {
		const grade = grades[0]
		if (grade === 'K') {
			return 'Kindergarten'
		}
		return gradeLabelMap[grade] + ' Grade'
	}
	const numberGrades: number[] = grades
		.map(grade => {
			if (grade === 'K') {
				return 0
			}

			return Number(grade)
		})
		.filter(grade => !isNaN(grade))
		.sort((a, b) => a - b)

	const ranges: Array<[number, number]> = getConsecutiveRanges(numberGrades)

	const rangesString = ranges
		.map(([min, max]) => {
			const minString = min === 0 ? 'K' : min
			if (min === max) {
				return minString
			}
			return minString + '-' + max
		})
		.join(', ')

	return 'Grades ' + rangesString
}

/**
 * Converts a list of grades into a string for display. With one grade, the
 * string will be 'Kindergarten', '1st Grade', '2nd Grade', etc. With two grades
 * the grades will show with a slash e.g. '1st/2nd Grade', etc.
 * If there are more than two grades, an empty string will be returned.
 * Expects grades to be a list of strings containing the grade in the format of
 * GRADE_ARRAY in store/missionPrep/index.js
 */
export function getMetaGradeString(grades: string[]): string {
	if (grades.length === 0) return ''

	if (grades.length === 1) {
		const grade = grades[0]
		if (grade === 'K') {
			return 'Kindergarten '
		}
		return gradeLabelMap[grade] + ' Grade '
	} else if (grades.length > 2) return ''
	return `${gradeLabelMap[grades[0]]}/${gradeLabelMap[grades[1]]} Grade `
}

/**
 * Converts a list of grades into ranges of grades for display. With one grade, the
 * string will be ['K'], ['1'], ['2'], etc. With multiple grades
 * the grades will show in ranges. e.g. ['K-3'], ['4-9'], ['2-3', '6-8'], etc.
 *
 * @param {string[]} grades - the list of grades
 *
 * @return {string[]} gradeRanges - the ranges of the grades
 */
export function getSmallGradesRanges(grades: string[]): string[] {
	if (grades.length === 1) {
		return [gradeLevelAbbreviationMap[grades[0]]]
	}
	const numberGrades: number[] = grades
		.map(grade => {
			if (grade === 'K') {
				return 0
			}

			return Number(grade)
		})
		.filter(grade => !isNaN(grade))
		.sort((a, b) => a - b)

	const ranges: Array<[number, number]> = getConsecutiveRanges(numberGrades)

	return ranges.map(([min, max]) => {
		let minString = gradeLevelAbbreviationMap[min === 0 ? 'K' : min] ?? ''
		let maxString = gradeLevelAbbreviationMap[max] ?? ''
		if (minString === maxString) {
			return minString
		}

		return minString + '-' + maxString
	})
}

/**
 * Given a *sorted* list of numbers, returns an array of tuples that represent each range
 * of consecutive numbers in the list. The first element of each tuple will be the min of a range,
 * and the second element will be the max of the same range. min and max can be the same number
 * Example:
 * input: [0, 1, 2, 4, 5, 6]          input: [1, 2, 3, 8]
 * output: [[0, 2], [4, 6]]           ouput: [[1, 3], [8, 8]]
 */
function getConsecutiveRanges(numbers: number[]): Array<[number, number]> {
	const ranges: Array<[number, number]> = []
	let currentMin = null
	numbers.forEach((grade, i) => {
		if (currentMin === null) {
			currentMin = grade
			return
		}

		const isConsecutive = grade === numbers[i - 1] + 1

		if (isConsecutive) {
			return
		}

		// The current number is not consecutive, so the previous number is the end of the range
		ranges.push([currentMin, numbers[i - 1]])
		currentMin = grade
	})
	if (currentMin !== null) {
		ranges.push([currentMin, numbers[numbers.length - 1]])
	}
	return ranges
}

/**
 * Takes a first name and last name and joins them together.
 * If no name exists, return null.
 */
export function getName(firstName: ?string, lastName: ?string): string | null {
	if (!firstName && !lastName) {
		return null
	}
	if (lastName) {
		if (firstName) {
			return firstName + ' ' + lastName
		}
		return lastName
	}
	return firstName || null
}

/**
 * Gets the grades where the given simulation should be sorted when the user is in the state represented by `stateAbbreviation`
 */
export function getSimulationGradesForState(
	simulation: AutomatedSimulation,
	stateAbbreviation: ?string,
	standardSets: StandardSet[]
): Array<string> {
	const groupedStandards = groupStandardsForState(
		stateAbbreviation,
		simulation.standards,
		standardSets
	)
	return (
		getGradesForStandards(groupedStandards.stateSpecific) ||
		getGradesForStandards(groupedStandards.nationSpecific) ||
		getGradesForStandards(groupedStandards.otherStandards) ||
		simulation.grades
	)
}

// Grade range regex examples: 'K-3', '4-6', 'K-5', '3-5' etc.
const gradeRangeRegex = new RegExp(/(K|[1-9]|(10)|(11))-([1-9]|(10)|(11)|(12))/)
/**
 * Gets the set of grades associated with the given standards. If there are no grades associated with the standards or no standards
 * are given, returns null.
 */
function getGradesForStandards(standards: Array<CurriculumStandard>): null | string[] {
	const grades = new Set(standards.map(standard => standard.grade).filter(Boolean))
	if (grades.size === 0) {
		return null
	}
	return Array.from(grades).flatMap(grade => {
		if (grade === 'MS') {
			return ['6', '7', '8']
		} else if (grade === 'HS') {
			return ['9', '10', '11', '12']
		} else if (gradeRangeRegex.test(grade)) {
			const bounds = grade.split('-')
			const gradeIndexOfFirstBound = GRADE_ARRAY.indexOf(bounds[0])
			const gradeIndexOfLastBound = GRADE_ARRAY.indexOf(bounds[1])
			if (
				gradeIndexOfFirstBound > -1 &&
				gradeIndexOfLastBound > -1 &&
				gradeIndexOfFirstBound < gradeIndexOfLastBound
			) {
				return GRADE_ARRAY.slice(gradeIndexOfFirstBound, gradeIndexOfLastBound + 1)
			} else {
				return []
			}
		} else {
			return grade
		}
	})
}

export const getFullName = (student?: { firstName: string, lastName: string }): string | null => {
	if (student) {
		const { firstName, lastName } = student
		return `${firstName} ${lastName}`
	} else return null
}

/**
 * UrlSearchParams does not parse array queries ('?key[]=x&key[]=y&key[]=z') correctly in the url.search string.
 * This custom class does it for us! this is used for network mocks and testing purposes.
 */
export class UrlParamsWithArrayParser {
	qs: string
	params: { [string]: string | string[] }
	constructor(search: string) {
		this.qs = search.substr(1)
		this.params = {}
		this.parseQueryString()
	}
	parseQueryString(): void {
		this.qs.split('&').reduce((a, b) => {
			let [key, val] = b.split('=')
			if (key.substring(key.length - 2) === '[]') {
				let newKey = key.substring(0, key.length - 2)
				if (!a[newKey]) {
					a[newKey] = []
				}
				a[newKey] = a[newKey].concat(decodeURIComponent(val))
			} else {
				a[key] = decodeURIComponent(val).replaceAll('+', ' ')
			}

			return a
		}, this.params)
	}
	get(key: string): string | string[] {
		return this.params[key]
	}
	getAll(key: string): string[] {
		const params = this.params[key]
		if (Array.isArray(params)) {
			return params
		} else {
			return [params]
		}
	}
}

/**
 * getQuestionKey - generates a string unique to the questionId and questionVersion
 *
 * @param {string} questionId - the id of the question
 * @param {string|number} questionVersion - the version of the question
 *
 * @return {string} - a string encoding the questionId and questionVersion
 */
export function getQuestionKey(questionId: string, questionVersion: string | number): string {
	return `${questionId}-${questionVersion}`
}

/**
 * Gets info about the banner to display for a given simulation status. If the status should not have a banner, returns null.
 */
export function getBannerInfo(
	status: ?$Keys<typeof AUTOMATED_SIMULATION_STATUSES>
): null | { text: string, longText?: boolean, color: string } {
	const eightyPercentOpacity = 'CC'

	if (status === AUTOMATED_SIMULATION_STATUSES.COMING_SOON) {
		return { text: 'Coming Soon', color: LightTheme.secondary + eightyPercentOpacity }
	} else if (status === AUTOMATED_SIMULATION_STATUSES.UNDER_CONSTRUCTION) {
		return {
			text: 'Under Construction',
			color: LightTheme.warning + eightyPercentOpacity,
			longText: true,
		}
	} else if (status === AUTOMATED_SIMULATION_STATUSES.BETA) {
		return { text: 'BETA', color: LightTheme.primary + eightyPercentOpacity }
	}

	return null
}
