import React from 'react'
import { components } from 'react-select'
import styled from 'styled-components'
import type { Category } from '../../../store/categories'
import type { SelectOption } from '../../../routes/analytics/analyticsHelpers'

type Props = {
	selectProps: {
		value: SelectOption[],
		options: SelectOption[],
		categories: Array<Category>,
		isSelected: string => boolean,
		disabledValues: Set<string>,
		onCategoryClick: (selected: boolean, categoryId: string, categoryName: string) => mixed,
	},
}

export const MenuList = (props: Props): React$Node => (
	<components.MenuList {...props}>
		<div className="flex flex-wrap">
			{props.selectProps.categories.map(categoryItem => {
				const selected = props.selectProps.isSelected(categoryItem._id)
				const categoryDisabled = props.selectProps.disabledValues.has(categoryItem._id)
				return (
					!categoryDisabled && (
						<Option
							key={categoryItem._id}
							selected={selected}
							disabled={categoryDisabled}
							onClick={() => {
								if (!categoryDisabled) {
									props.selectProps.onCategoryClick(
										selected,
										categoryItem._id,
										categoryItem.name
									)
								}
							}}>
							{categoryItem.name}
						</Option>
					)
				)
			})}
		</div>
	</components.MenuList>
)

/**
 * This is as readonly input that display the total of grades selected used in Analytics views.
 */
export const GradeInput = ({ selectProps, ...props }: Props): React$Node => {
	const currentValue = selectProps.value.map(v => v.label)
	let value = ''

	if (selectProps.options.length === currentValue.length) value = `All Grades`
	else if (currentValue.length < 7) value = currentValue.join(', ')
	else value = `${currentValue.length} Grades Selected`

	return <components.Input {...props} readOnly value={value} />
}

const Option = styled.div.attrs({
	className: 'w-[95%] rounded my-1 mx-auto text-center cursor-pointer text-sm py-1',
})`
	${({ theme, selected, header, disabled }) => `
		background-color: ${
			disabled ? theme.neutral : selected ? `${theme.primary}` : `${theme.primary300}`
		};
		color: ${disabled ? theme.neutralLight : theme.white};
		${
			!disabled
				? `&:hover {
						background-color: ${theme.primary400};
		}`
				: ``
		}
	`}
`
