// @flow
import React, { useEffect, useMemo, useState } from 'react'
import { useQueryClient, useMutation } from 'react-query'
import axios from 'axios'
import GoPencil from 'react-icons/lib/go/pencil'
import GoCheck from 'react-icons/lib/io/checkmark'
import styled from 'styled-components/macro'
import Select from 'react-select'
import config from '../../config'
import Loading from '../../components/loading/Loading'
import { getStandardsForState } from '../../utility/standards'
import states from '../../constants/states'
import useUser, {
	useUserPreference,
	useUpdateUser,
	useUserEmailChangeRequests,
	useDeleteEmailChangeRequest,
	getUserPreferenceQueryKey,
	useMyLicenseData,
	LICENSE_OWNER_TYPES,
} from '../../services/hooks/user'
import EmailPreferences from './EmailPreferences'
import { ServiceSignIn } from './ServiceSignIn'
import { StandardBubble } from '../mission/prep/PrepMission'
import { toast } from 'react-toastify'
import { LoaderWrapper } from './sharedProfileComponents'

import type { User } from '../../store/types'
import { useStandardSets } from '../../services/hooks/standards'
import type { StandardSet } from '../../utility/standards'
import { IconButton } from '../../components/helpers/Buttons'
import { StatePicker } from '../../components/StatePicker'
import SchoolPicker from '../classes/SchoolPicker'
import { useDistrict, useSchool } from '../../services/hooks/organizations'
import { rolesEnum } from '../../store/types'
import Modal, { ModalBody, ModalHeader, ModalFooter } from '../../components/modal/Modal'
import { Button } from '@mission.io/styles'
import { getName } from '../../utility/helpers'
import { setUserLoggedInLocalStorage } from '../../components/popups/SessionEndedPopup'
import { ProfileNav } from '../../components/header/NavLinks'
import { screenSizes_dontUse } from '../../styles/theme'
import classnames from 'classnames'
import { Input } from '../../components/inputs/Input'
import { AppReactSelectClassNames, AppReactSelectTheme } from '../../components/inputs/styles'
import { PageTitleHeader } from '../../styles/sharedComponents'
import { usePermissions } from '../../services/hooks'

const StandardWrapper = styled.span`
	display: inline-flex;
	flex-direction: row;
	justify-content: space-between;
	flex-wrap: wrap;
`

export default function Profile(): React$Node {
	const { user } = useUser()

	if (!user) {
		return <div>Unknown User</div>
	}

	const profileFields = [
		{
			title: 'Basic Info',
			subtitle: 'The basic user information that we have on file.',
			component: <UserData user={user} />,
		},
		{
			title: 'Service Sign-in',
			subtitle: 'Connect a service for sign-in and classroom syncing.',
			component: <ServiceSignIn user={user} />,
		},
		{
			title: 'Email Preferences',
			subtitle: `Manage which emails you receive from ${config.companyName.base}.`,
			component: <EmailPreferences />,
		},
		{
			title: 'License',
			subtitle: 'Manage your license.',
			component: <License user={user} />,
		},
		{
			title: 'Promotions',
			subtitle: '',
			component: <Promotions />,
		},
	]

	return (
		<div className="mx-3">
			<PageTitleHeader>Profile</PageTitleHeader>
			<ProfileNav />
			<div className="pt-2">
				{profileFields.map(({ title, subtitle, component }, i) => {
					return (
						<React.Fragment key={title}>
							<div css="display: flex;">
								<div css="padding: 0 var(--spacing2x-dont-use); flex: 0 0 33.33333%;">
									<h4 className="text-2xl">{title}</h4>
									<p>{subtitle}</p>
								</div>
								{component}
							</div>
							<hr className="my-3" />
						</React.Fragment>
					)
				})}
				<div className="flex justify-end w-full">
					<LogoutButton />
				</div>
				{/* TODO: add prompts for more info, add edit profile functionality*/}
			</div>
		</div>
	)
}

// A button for the user to log out from.
function LogoutButton() {
	return (
		<Button
			onClick={() => {
				setUserLoggedInLocalStorage(false)
				axios
					.post(
						config.loginServer + '/logout',
						{},
						{
							withCredentials: true,
						}
					)
					.then(response => {
						if (response.headers && response.headers.location) {
							window.location.replace(response.headers.location)
						}
					})
					.catch(err => {
						// TODO: do something about the error
						console.log('Logout Error: ', err)
					})
			}}>
			Sign out
		</Button>
	)
}

const StyledSelect = styled(Select)`
	width: 25em;
`

/**
 * Display user data such as name and email. Also allows the user to edit the state whose standards they will see
 */
function UserData({ user }: { user: User }) {
	const isDistrictUser = user.roles.some(({ role }) => role === rolesEnum.DISTRICT_ADMIN)

	const { data: district } = useDistrict(user.districtId)

	return (
		<div className="flex-1">
			<NameUserField
				initialValue={user.firstName ?? ''}
				user={user}
				fieldName="firstName"
				label={'First Name'}
				autocomplete="given-name"
			/>
			<NameUserField
				initialValue={user.lastName ?? ''}
				user={user}
				fieldName="lastName"
				label={'Last Name'}
				autocomplete="family-name"
			/>
			<EmailField user={user} />
			{isDistrictUser ? (
				district && <div>District: {district.name}</div>
			) : (
				<School
					{...{
						schoolName: user.schoolName,
						schoolId: user.schoolId,
						state: user.state,
						user,
					}}
				/>
			)}
			<StateForStandards user={user} />
		</div>
	)
}

type StateOption = { value: string | null, label: string }

/**
 * A component that allows the user to select a state that will determine which standards will be shown.
 */
function StateForStandards({ user }: { user: User }) {
	const [isEditing, setIsEditing] = useState(false)

	const queryClient = useQueryClient()

	const { data: userPreferences } = useUserPreference()
	const { data: standardSets, isLoading: isLoadingStandardSets } = useStandardSets()
	const userPreferenceMutation = useMutation(
		(data: {| stateForStandards?: ?string |}) =>
			axios
				.patch(`${config.loginServer}/api/user-preferences`, data, {
					withCredentials: true,
				})
				.then(res => res.data?.preferences),
		{
			onSuccess: data => {
				queryClient.setQueryData(getUserPreferenceQueryKey(Boolean(user)), data)
				setIsEditing(false)
			},
			onError: () => {
				toast.error('An error occurred while updating your preferences.')
			},
		}
	)
	const userState = user?.state
	const allUserStateOptions: StateOption[] = useMemo(() => {
		const userStateOptions: Array<StateOption> = states.map(state => ({
			value: state.abbreviation,
			label: state.name,
		}))

		if (!userState) {
			return userStateOptions
		}

		return [
			{
				value: null,
				label: userState,
			},
			...userStateOptions,
		]
	}, [userState])
	if (!userPreferences) {
		return null
	}

	const currentOption = allUserStateOptions.find(
		option => option.value === (userPreferences.stateForStandards || user?.state)
	)

	return (
		<div className="flex items-center gap-1">
			Curriculum:
			{!isEditing ? (
				<>
					{currentOption ? (
						<SelectionOptionLabel
							{...{
								name: getDisplayLabel(currentOption),
								standardSets: getStandardsForOption(
									currentOption,
									standardSets || []
								),
								small: true,
							}}
						/>
					) : (
						' Unknown'
					)}
					<IconButton
						onClick={() => {
							setIsEditing(true)
						}}
						title="Edit Curriculum State">
						<GoPencil />
					</IconButton>
				</>
			) : (
				<>
					{isLoadingStandardSets || !standardSets ? (
						<Loading />
					) : (
						<StyledSelect
							defaultMenuIsOpen
							autoFocus
							theme={AppReactSelectTheme}
							classnames={AppReactSelectClassNames}
							styles={{
								singleValue: currentStyles => ({
									...currentStyles,
									minHeight: '1em',
									overflow: 'visible',
									paddingRight: 'var(--spacing1x-dont-use)',
								}),
								valueContainer: currentStyles => ({
									...currentStyles,
									overflow: 'visible',
								}),
							}}
							value={currentOption}
							isDisabled={userPreferenceMutation.isLoading}
							options={allUserStateOptions}
							onChange={value =>
								userPreferenceMutation.mutate({
									stateForStandards:
										value.value === userState ? null : value.value,
								})
							}
							formatOptionLabel={option => (
								<SelectionOptionLabel
									name={getDisplayLabel(option)}
									standardSets={getStandardsForOption(option, standardSets)}
								/>
							)}
						/>
					)}
					{userPreferenceMutation.isLoading ? (
						<LoaderWrapper>
							<Loading />
						</LoaderWrapper>
					) : null}
				</>
			)}
		</div>
	)
}

/**
 * Gets the label to display for a StateOption
 */
function getDisplayLabel(option: StateOption) {
	return option.value ? option.label : `My State (${option.label})`
}

/**
 * Gets the standard sets for a StateOption
 */
function getStandardsForOption(option: StateOption, standardSets: StandardSet[]) {
	return getStandardsForState(option.value ?? option.label, standardSets)
}

/**
 * SelectionOptionLabel - a label for a react-select option
 *
 * @param {Object} props - the react props
 * @param {sting} name - the name (usually a state) of the value to put in front of the standards bubble
 * @param standardSets - the standard sets to display
 *
 * @return {React$Node}
 */
function SelectionOptionLabel({
	name,
	standardSets,
	className,
	small,
}: {
	name: string,
	standardSets: StandardSet[],
	className?: string,
	small?: boolean,
}): React$Node {
	return (
		<StandardWrapper className={classnames(className, 'text-primary-950')}>
			{name}
			<div>
				{[...standardSets]
					.sort((ss1, ss2) => {
						// sort state specific standard sets to the front
						if (ss1.scope === 'STATE_SPECIFIC') {
							return -1
						} else if (ss2.scope === 'STATE_SPECIFIC') {
							return 1
						}

						return 0
					})
					.map(standardSet => {
						return (
							<StandardBubble
								key={standardSet._id}
								color={standardSet.color}
								className={classnames('text-sm', small && 'py-1 px-2')}>
								{standardSet.abbreviation || standardSet.name}
							</StandardBubble>
						)
					})}
			</div>
		</StandardWrapper>
	)
}

/**
 * A component that displays the user's school and allows them to select a new school
 */
function School({
	schoolName,
	schoolId,
	state: _state,
	user,
}: {
	schoolName: ?string,
	schoolId: ?string,
	state: ?string,
	user: User,
}) {
	const [isEditing, setIsEditing] = useState(false)
	const [state, setState] = useState(_state)
	const [newSchool, setNewSchool] = useState(null)

	const updateUser = useUpdateUser()

	return (
		<div className="flex items-center gap-1">
			School:
			{!isEditing ? (
				<>
					{<span>{schoolName}</span> || (
						<span className="note">School not specified</span>
					)}
					<IconButton
						onClick={() => {
							setIsEditing(true)
						}}
						title="Edit School">
						<GoPencil />
					</IconButton>
				</>
			) : (
				<>
					<StatePicker value={state} onChange={setState} autoFocus />
					<SchoolPicker
						css="flex: 1; max-width: 450px;"
						aria-label="School"
						value={
							state !== _state || !schoolId
								? null
								: { schoolName: schoolName || 'Unknown School Name', schoolId }
						}
						onChange={school => {
							if (!school) {
								return
							}

							setNewSchool(school)
						}}
						disabled={!state}
						state={state || undefined}
					/>
				</>
			)}
			<SmallModal
				isOpen={!!newSchool}
				onClose={() => setNewSchool(null)}
				aria={{
					labelledby: 'confirm-school-change-header',
					describedby: 'confirm-school-change-body',
				}}>
				<ModalHeader id="confirm-school-change-header">Confirm School Change</ModalHeader>
				<ModalBody id="confirm-school-change-body">
					<p className="m-0">
						Are you sure you want to change your school to{' '}
						<b>{newSchool?.schoolName || 'Unknown School'}</b>? By doing so, you will
						lose access to your current classes. Any classes that are imported from a
						3rd party (Google Classroom, Canvas, etc) will be restored the next time you
						log in.
						{user.roles.length > 0 ? (
							<>
								{' '}
								You will also lose access to your existing roles within the school
								and district. If you wish to regain your role for the new school,
								simply get in touch with us through live chat or email us at{' '}
								<a href={`mailto:${config.supportEmail}`}>{config.supportEmail}</a>.
							</>
						) : (
							''
						)}
					</p>
				</ModalBody>
				<ModalFooter>
					<Button
						onClick={() => {
							setNewSchool(null)
						}}
						outline>
						Cancel
					</Button>
					<Button
						disabled={updateUser.isLoading}
						onClick={() => {
							if (!newSchool) {
								throw new Error(
									'No newSchool in dialgo box. This should have been impossible'
								)
							}

							updateUser
								.mutateAsync({
									userId: user._id,
									userUpdate: {
										schoolId: newSchool.schoolId,
									},
								})
								.then(() => {
									setNewSchool(null)
									setIsEditing(false)
								})
						}}>
						Change My School
					</Button>
				</ModalFooter>
			</SmallModal>
		</div>
	)
}

const SmallModal = styled(Modal)`
	margin: 0.25rem;
	${({ theme }) => `
		@media (min-width: ${screenSizes_dontUse.mobileDevice}px) {
			max-width: 500px;
			margin: 1.75rem auto;
		}
	`}
`

/**
 * NameUserField - a react component which allows the user to edit their name(s)
 *
 * @param {Object} props - the react props
 * @param {'firstName' | 'lastName'} props.fieldName - the name of the field on the user object that is being edited
 * @param {User} props.user - the user being edited
 * @param {string} props.label - the label for the field
 * @param {string} props.autocomplete - the auto complete html hint
 *
 * @return {React$Node}
 */
function NameUserField({
	fieldName,
	user,
	label,
	autocomplete,
}: {
	fieldName: 'firstName' | 'lastName',
	user: User,
	label: string,
	autocomplete: string,
}): React$Node {
	const updateUser = useUpdateUser()
	const initialValue = user[fieldName] ?? ''
	const [isEditing, setIsEditing] = useState(false)
	const [currentValue, setCurrentValue] = useState(initialValue)

	useEffect(() => {
		setCurrentValue(initialValue)
	}, [initialValue])

	const showEditingFields = isEditing || updateUser.isLoading
	const RowComponent = showEditingFields ? RowLabel : RowDiv

	return (
		<RowDiv>
			<RowComponent>
				{label}:{' '}
				{showEditingFields ? (
					<StyledInput
						value={currentValue}
						disabled={updateUser.isLoading}
						onChange={e => setCurrentValue(e.target.value)}
						autoFocus={true}
						autoComplete={autocomplete}
					/>
				) : (
					<>{initialValue}</>
				)}
			</RowComponent>
			{showEditingFields ? (
				<Button
					onClick={e => {
						if (currentValue !== initialValue) {
							updateUser
								.mutateAsync({
									userId: user._id,
									userUpdate: {
										[(fieldName: string)]: currentValue,
									},
								})
								.then(() => {
									toast.success(`Updated your ${label.toLowerCase()}.`, {
										position: toast.POSITION.BOTTOM_RIGHT,
									})
								})
								.catch(e => {
									toast.error(
										`Failed to update your ${label.toLowerCase()}. ${
											typeof e.message === 'string' ? e.message : ''
										}`,
										{
											position: toast.POSITION.BOTTOM_RIGHT,
										}
									)
								})
						}

						setIsEditing(false)
					}}
					disabled={updateUser.isLoading}
					title={`Save ${label.toLowerCase()}`}>
					{updateUser.isLoading ? (
						<LoaderWrapper>
							<ButtonTextRow>
								saving <Loading />
							</ButtonTextRow>
						</LoaderWrapper>
					) : (
						<ButtonTextRow>
							<GoCheck /> save
						</ButtonTextRow>
					)}
				</Button>
			) : (
				<IconButton
					onClick={e => {
						setIsEditing(true)
					}}
					title={`Edit ${label.toLowerCase()}`}>
					<GoPencil />
				</IconButton>
			)}
		</RowDiv>
	)
}

const LICENSE_TYPE_TO_TITLE = {
	[LICENSE_OWNER_TYPES.DISTRICT]: 'District',
	[LICENSE_OWNER_TYPES.SCHOOL]: 'School',
	[LICENSE_OWNER_TYPES.SINGLE_OWNER]: 'Single User',
	[LICENSE_OWNER_TYPES.FREE]: 'Unknown',
}

/**
 * EmailField - a field for viewing and editing the email of the user
 *
 * @param {{user:User}} {user} - the current user data
 *
 * @return {React$Node}
 */
function EmailField({ user }: { user: User }): React$Node {
	const updateUser = useUpdateUser()
	const initialValue = user.email ?? ''
	const [isEditing, setIsEditing] = useState(false)
	const [currentValue, setCurrentValue] = useState(initialValue)
	const [isVerificationModalOpen, setIsVerificationModalOpen] = useState(false)
	const [isEmailSentModalOpen, setIsEmailSentModalOpen] = useState(false)

	useEffect(() => {
		setCurrentValue(initialValue)
	}, [initialValue])

	const showEditingFields = isEditing || updateUser.isLoading
	const RowComponent = showEditingFields ? RowLabel : RowDiv

	return (
		<>
			<RowDiv>
				<RowComponent>
					Email:{' '}
					{showEditingFields ? (
						<>
							<StyledInput
								value={currentValue}
								disabled={updateUser.isLoading}
								onChange={e => setCurrentValue(e.target.value)}
								autoFocus={true}
								type="email"
								autoComplete="email"
							/>
						</>
					) : (
						<>{initialValue}</>
					)}
				</RowComponent>
				{showEditingFields ? (
					<Button
						onClick={e => {
							if (!emailIsValid(currentValue)) {
								toast.error('Invalid email address')
								return
							}

							if (currentValue === initialValue) {
								setIsEditing(false)
								return
							}

							setIsVerificationModalOpen(true)
						}}
						disabled={updateUser.isLoading || isVerificationModalOpen}
						title={`Save Email`}>
						{updateUser.isLoading || isVerificationModalOpen ? (
							<LoaderWrapper>
								<ButtonTextRow>
									saving <Loading />
								</ButtonTextRow>
							</LoaderWrapper>
						) : (
							<ButtonTextRow>
								<GoCheck /> save
							</ButtonTextRow>
						)}
					</Button>
				) : (
					<IconButton
						onClick={e => {
							setIsEditing(true)
						}}
						title={`Edit Email`}>
						<GoPencil />
					</IconButton>
				)}
			</RowDiv>
			<EmailChangeRequests userId={user._id} />
			<Modal
				isOpen={isVerificationModalOpen}
				onClose={() => {
					setIsVerificationModalOpen(false)
				}}
				aria={{
					labelledby: 'confirm-email-change-header',
					describedby: 'confirm-email-change-body',
				}}>
				<ModalHeader id="confirm-email-change-header">Confirm Email Change</ModalHeader>
				<ModalBody id="confirm-email-change-body">
					<p className="m-0">
						Are you sure you want to change your email to <b>{currentValue}</b>?
					</p>
					<p />
					<p>
						You will continue using your current email <b>{initialValue}</b> until you
						verify the new email.
					</p>
				</ModalBody>
				<ModalFooter>
					<Button
						onClick={() => {
							setIsVerificationModalOpen(false)
						}}
						disabled={updateUser.isLoading}
						outline>
						Cancel
					</Button>
					<Button
						disabled={updateUser.isLoading}
						onClick={() => {
							if (!emailIsValid(currentValue)) {
								setIsVerificationModalOpen(false)
								throw new Error(
									'Invalid email after verification modal. This should have been impossible'
								)
							}
							updateUser
								.mutateAsync({
									userId: user._id,
									userUpdate: {
										email: currentValue,
									},
								})
								.then(() => {
									toast.success(`Sent email verification to ${currentValue}.`, {
										position: toast.POSITION.BOTTOM_RIGHT,
									})
									setIsEmailSentModalOpen(true)
								})
								.catch(e => {
									toast.error(
										`Failed to update your email. ${
											typeof e.message === 'string' ? e.message : ''
										}`,
										{
											position: toast.POSITION.BOTTOM_RIGHT,
										}
									)
								})
						}}>
						Send Verification Email
					</Button>
				</ModalFooter>
			</Modal>
			<SmallModal
				isOpen={isEmailSentModalOpen}
				onClose={() => {
					setIsEmailSentModalOpen(false)
					setIsVerificationModalOpen(false)
					setIsEditing(false)
				}}
				aria={{
					labelledby: 'email-sent-header',
					describedby: 'email-sent-body',
				}}>
				<ModalHeader id="email-sent-header">Verification Email Sent</ModalHeader>
				<ModalBody id="email-sent-body">
					<p className="m-0">
						A verification email has been sent to <b>{currentValue}</b>.
					</p>
					<p />
					<p>Follow the steps in that email to complete the email change.</p>
				</ModalBody>
				<ModalFooter>
					<Button
						onClick={() => {
							setIsEmailSentModalOpen(false)
							setIsVerificationModalOpen(false)
							setIsEditing(false)
						}}
						outline>
						Close
					</Button>
				</ModalFooter>
			</SmallModal>
		</>
	)
}

/**
 * License - allows the user to manage the license they are a part of
 *
 * @param {Object} props - the react props
 * @param {User} props.user - the user to manage the license information for
 *
 * @return {React$Node}
 */
function License({ user }: { user: User }): React$Node {
	const { data: licenseData, isError, isLoading } = useMyLicenseData()
	const updateUser = useUpdateUser()
	const [isVerificationModalOpen, setIsVerificationModalOpen] = useState(false)

	if (isLoading) {
		return <SmallLoading />
	}

	if (isError) {
		return <div>An unexpected error occurred while loading your license data.</div>
	}

	if (!licenseData) {
		return <div>You are not connected to a license</div>
	}

	const isAdminForLicense = Boolean(
		user.roles.find(role => role.role === rolesEnum.LICENSE_ADMIN && role.verified)
	)

	const isOwnerOfLicense =
		(licenseData.owner.type === LICENSE_OWNER_TYPES.FREE ||
			licenseData.owner.type === LICENSE_OWNER_TYPES.SINGLE_OWNER) &&
		user._id === licenseData.owner.userId

	const isFreeLicense = licenseData.owner.type === LICENSE_OWNER_TYPES.FREE

	let ownerComponent = null
	if (
		licenseData.owner.type === LICENSE_OWNER_TYPES.FREE ||
		licenseData.owner.type === LICENSE_OWNER_TYPES.SINGLE_OWNER
	) {
		ownerComponent = <UserOwner userId={licenseData.owner.userId} currentUser={user} />
	} else if (licenseData.owner.type === LICENSE_OWNER_TYPES.SCHOOL) {
		ownerComponent = <SchoolOwner schoolId={licenseData.owner.schoolId} />
	} else if (licenseData.owner.type === LICENSE_OWNER_TYPES.DISTRICT) {
		ownerComponent = <DistrictOwner districtId={licenseData.owner.districtId} />
	}

	return (
		<div>
			<div>
				<div className="text-xl">
					<u>{LICENSE_TYPE_TO_TITLE[licenseData.owner.type] ?? 'Unknown'} License</u>
				</div>
				<RowDiv>Managed by: {ownerComponent || 'Unknown owner'}</RowDiv>
				<Spacer />
				{!(isAdminForLicense || isOwnerOfLicense) ? (
					<Button
						disabled={updateUser.isLoading}
						variant="danger"
						onClick={() => {
							setIsVerificationModalOpen(true)
						}}>
						{updateUser.isLoading ? (
							<>
								<Loading />
								Leaving License
							</>
						) : (
							'Leave License'
						)}
					</Button>
				) : null}
			</div>
			<SmallModal
				isOpen={isVerificationModalOpen}
				onClose={() => {
					setIsVerificationModalOpen(false)
				}}
				aria={{
					labelledby: 'confirm-email-change-header',
					describedby: 'confirm-email-change-body',
				}}>
				<ModalHeader id="confirm-email-change-header">Confirm Leaving License</ModalHeader>
				<ModalBody id="confirm-email-change-body">
					{isFreeLicense ? (
						<p className="m-0">Are you sure you want to leave this license?</p>
					) : (
						<>
							<p className="m-0">Are you sure you want to leave this paid license?</p>
							<br />
							<p>
								You will no longer be able to run missions which require a paid
								license.
							</p>
						</>
					)}
				</ModalBody>
				<ModalFooter>
					<Button
						onClick={() => {
							setIsVerificationModalOpen(false)
						}}
						disabled={updateUser.isLoading}
						outline>
						Cancel
					</Button>
					<Button
						disabled={updateUser.isLoading}
						onClick={() => {
							updateUser
								.mutateAsync({
									userId: user._id,
									userUpdate: {
										license: null,
									},
								})
								.then(() => {
									setIsVerificationModalOpen(false)
									toast.success(`Left the license.`, {
										position: toast.POSITION.BOTTOM_RIGHT,
									})
								})
								.catch(e => {
									toast.error(
										`Failed to leave the license. ${
											typeof e.message === 'string' ? e.message : ''
										}`,
										{
											position: toast.POSITION.BOTTOM_RIGHT,
										}
									)
								})
						}}>
						{updateUser.isLoading ? (
							<LoaderWrapper>
								<ButtonTextRow>
									Leaving License <Loading />
								</ButtonTextRow>
							</LoaderWrapper>
						) : (
							'Leave License'
						)}
					</Button>
				</ModalFooter>
			</SmallModal>
		</div>
	)
}

/**
 * Promotions - a component which shows the promotions attached to the user's license
 *
 * @return {React$Node}
 */
function Promotions(): React$Node {
	const { permissions, isLoading, isError } = usePermissions()

	if (isLoading || !permissions) {
		return <SmallLoading />
	}

	if (isError) {
		return <div>An unexpected error occurred while loading your current promotions.</div>
	}

	if (!permissions.promotions.length) {
		return <div>There are no promotions on your account.</div>
	}

	const currentDate = Date.now()
	return (
		<table className="table-auto w-full">
			<thead>
				<tr>
					<th>Promotion</th>
					<th>Expiration Date</th>
				</tr>
			</thead>
			<tbody>
				{permissions.promotions
					.sort((a, b) => b.expirationDate - a.expirationDate)
					.map((promotion, index) => {
						const isExpired = promotion.expirationDate < currentDate
						return (
							<tr
								key={index}
								className="hover:bg-white/10"
								aria-label={isExpired ? 'Expired Promotion' : 'Active Promotion'}>
								<td>{promotion.clientMessage}</td>
								<td
									className={classnames(
										'p-0 px-2 rounded flex flex-col items-center m-1 gap-0 text-xs whitespace-nowrap',
										isExpired ? 'text-error' : null
									)}>
									{new Date(promotion.expirationDate).toLocaleString()}
								</td>
							</tr>
						)
					})}
			</tbody>
		</table>
	)
}

/**
 * EmailChangeRequests - display a list of the currently pending user email change requests (there should only ever be 0 or 1 request)
 *
 * @param {{userId:string}} {userId} - the id of the user to display the requests for
 *
 * @return {React$Node}
 */
function EmailChangeRequests({ userId }: { userId: string }) {
	const { data: emailChangeRequests, isLoading, isError } = useUserEmailChangeRequests({ userId })
	const {
		mutateAsync: deleteEmailChangeRequest,
		isLoading: isDeleting,
	} = useDeleteEmailChangeRequest()
	const { mutateAsync: resendEmail, isLoading: isResending } = useUpdateUser()

	if (isDeleting) {
		return <div>Deleting</div>
	}

	if (isResending) {
		return <div>Resending Email</div>
	}

	if (isLoading || !emailChangeRequests?.length) {
		return null
	}

	if (isError) {
		return <div>An error occurred while getting your email change requests</div>
	}

	return (
		<EmailChangeRequestWrapper>
			<div>Pending email update (waiting for you to verify):</div>
			{emailChangeRequests.map(({ newEmail, _id }) => (
				<div key={_id}>
					<div>{newEmail}</div>
					<RowDiv>
						<Button
							onClick={e => {
								resendEmail({
									userId,
									userUpdate: {
										email: newEmail,
									},
								})
									.then(() => {
										toast.success(`Verification email sent to ${newEmail}.`, {
											position: toast.POSITION.BOTTOM_RIGHT,
										})
									})
									.catch(e => {
										toast.error(
											`Failed to resend verification email. ${
												typeof e.message === 'string' ? e.message : ''
											}`,
											{
												position: toast.POSITION.BOTTOM_RIGHT,
											}
										)
									})
							}}
							title={`Resend Verification Email`}>
							Resend Email
						</Button>
						<Button
							onClick={e => {
								deleteEmailChangeRequest({ requestId: _id }).catch(error => {
									console.error(
										`Error deleting change email request ${_id}`,
										error
									)
									toast.error(
										`Failed to delete email change request to email ${newEmail}`
									)
								})
							}}
							variant="danger"
							title={`Delete Email Change`}>
							Delete Request
						</Button>
					</RowDiv>
				</div>
			))}
		</EmailChangeRequestWrapper>
	)
}

/**
 * DistrictOwner - shows information about the district which owns the license
 *
 * @param {Object} props - the react props
 * @param {string} props.districtId - the id of the district which owns the license
 *
 * @return {React$Node}
 */
function DistrictOwner({ districtId }: { districtId: string }) {
	const { data: district, isLoading, isError } = useDistrict(districtId)

	if (isLoading) {
		return (
			<>
				<SmallLoading />
				Loading District
			</>
		)
	}

	if (isError) {
		return <>An Error occurred while loading the district</>
	}

	if (!district) {
		return <>No such district</>
	}
	return <div>{district.name}</div>
}

/**
 * SchoolOwner - shows information about the school which owns the license
 *
 * @param {Object} props - the react props
 * @param {string} props.schoolId - the id of the school which owns the license
 *
 * @return {React$Node}
 */
function SchoolOwner({ schoolId }: { schoolId: string }) {
	const { data: school, isLoading, isError } = useSchool(schoolId)

	if (isLoading) {
		return (
			<>
				<SmallLoading />
				Loading School
			</>
		)
	}

	if (isError) {
		return <>An Error occurred while loading the school</>
	}

	if (!school) {
		return <>No such school</>
	}
	return (
		<div>
			{school.name}{' '}
			<SubData>
				({school.city}, {school.state})
			</SubData>
		</div>
	)
}

/**
 * UserOwner - shows information about the user which owns the license
 *
 * @param {Object} props - the react props
 * @param {string} props.userId - the id of the user which owns the license
 * @param {User} props.currentUser - the user looking at the page
 *
 * @return {React$Node}
 */
function UserOwner({ userId, currentUser }: { userId: string, currentUser: User }) {
	if (userId === currentUser._id) {
		return (
			<div>
				{getName(currentUser.firstName, currentUser.lastName)} <SubData>(Me)</SubData>
			</div>
		)
	}

	return <div>You do not have permission to see this user&apos;s information.</div>
}

const RowLabel = styled.label`
	display: flex;
	flex-direction: row;
	gap: var(--spacing1x-dont-use);
	align-items: center;
	margin-bottom: 0;
`

const SubData = styled.span`
	opacity: 0.5;
`

const RowDiv = styled.div`
	display: flex;
	flex-direction: row;
	gap: var(--spacing1x-dont-use);
	align-items: center;
	margin-bottom: 0;
`

const ButtonTextRow = styled.div`
	display: flex;
	flex-direction: row;
	gap: var(--spacing1x-dont-use);
	align-items: center;
	justify-content: center;
`

const StyledInput = styled(Input)`
	width: unset;
	min-width: 10em;
`

const SmallLoading = styled(Loading)`
	width: 4em;
`

const Spacer = styled.div`
	margin: 0 0 var(--spacing1x-dont-use) 0;
`

const EmailChangeRequestWrapper = styled.div`
	padding-left: var(--spacing2x-dont-use);
	padding-top: var(--spacing2x-dont-use);
	padding-bottom: var(--spacing2x-dont-use);
`

/**
 * emailIsValid - a function which checks if a string is a valid email
 *
 * @param {string} email - the string to check
 *
 * @return boolean - true if the string is a valid email, false otherwise
 */
export function emailIsValid(email: string): boolean {
	// eslint-disable-next-line
	const emailRegex = /^.+@.+\..+$/
	return emailRegex.test(String(email).toLowerCase()) && email.length < 200
}
