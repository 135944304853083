import React from 'react'
import { BreadCrumbs, FileGrid } from './Components'
import type { File } from '../../utility/FileHelpers'
import type { OnFileClick, OnFolderClick } from './Components'
import { ExitX, ModalBody, ModalHeader } from '../modal/Modal'
import 'styled-components/macro'

/**
 * SlimFileBrowser - a simple file browser with breadcrumbs
 *
 * @param {Object} props - the react props
 * @param {Array<File>} props.files - the files/folders in the current directory
 * @param {onFileClick} props.onFileClick - a callback for when a file is clicked
 * @param {onFolderClick} props.OnFolderClick - a callback for when a folder is clicked
 * @param {string} props.directory - the current directory
 * @param {() => void} props.onRequestClose - a callback for when the close button is pressed
 *
 * @return {React$Node}
 */
export default function BasicFileBrowser({
	files,
	onFileClick,
	onFolderClick,
	directory,
	onRequestClose,
}: {
	files: File[],
	onFileClick: OnFileClick,
	onFolderClick: OnFolderClick,
	directory: string,
	onRequestClose: () => void,
}): React$Node {
	return (
		<div className="contents" aria-label="file browser">
			<ModalHeader>
				<BreadCrumbs directory={directory} onFolderClick={onFolderClick} />
				<ExitX onClick={onRequestClose} />
			</ModalHeader>
			<ModalBody className="flex-1">
				<FileGrid
					files={files}
					onFileClick={onFileClick}
					onFolderClick={onFolderClick}
					className="text-primary-950"
				/>
			</ModalBody>
		</div>
	)
}
