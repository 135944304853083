import React, { useRef, useState } from 'react'
import { Question } from '@mission.io/question-views'
import styled from 'styled-components'
import FaArrowRight from 'react-icons/lib/fa/arrow-right'
import FaArrowLeft from 'react-icons/lib/fa/arrow-left'
import type { ResponseData } from './AnalyticsCharts/TableHelper'

/**
 * QuestionResponseAttempts - a component which shows the attempts a student made when answering a question
 *
 * @param  {Array} responses - the attempts the student made on the question
 * @param  {AnalyticsQuestionResponse} responses[#].studentResponse - the response form the student (from the analytics document)
 * @param  {DBQuestion} responses[#].question - the question that the student responded to (could be different through the responses due to question versions)
 * @param  {number} startingAttemptToView - the attempt number to jump to when the component is mounted
 * @param  {string} name - the name of the student the attempts are being showed for
 * @param  {string} studentId - the id of the student the attempts are being showed for
 * @param  {() => void} onClose - a callback used remove this component
 *
 * @returns React$Node
 */
export default function QuestionResponseAttempts({
	responses,
	startingAttemptToView,
	name,
	studentId,
	onClose,
}: {
	responses: Array<ResponseData>,
	name: string,
	studentId: string,
	startingAttemptToView: number,
	onClose: () => void,
}): React$Node {
	const finishedInitialJump = useRef(false)
	const backgroundRef = useRef()
	const attemptRefs = useRef([])
	const [currentIndex, setCurrentIndex] = useState(startingAttemptToView)

	const jumpToAttempt = index => {
		attemptRefs.current[index]?.scrollIntoView({
			behavior: 'smooth',
			block: 'center',
			inline: 'center',
		})
	}
	return (
		<>
			<StudentResponseFlipBook
				ref={backgroundRef}
				onClick={e => {
					if (e.target !== backgroundRef.current) {
						// do not close if a child is clicked
						e.stopPropagation()
						return
					}
					onClose()
				}}
				onScroll={() => {
					if (!backgroundRef.current || !attemptRefs.current?.length) {
						return
					}

					// determine question closest to the middle
					let viewPortMidX = backgroundRef.current.getBoundingClientRect().width / 2
					let closestToMidIndex = -1
					let closestToMidAmount = Infinity

					attemptRefs.current.forEach((element, index) => {
						if (!element) {
							return
						}

						const boundingRect = element.getBoundingClientRect()
						const displacementFromMidX = Math.abs(
							boundingRect.left + boundingRect.width / 2 - viewPortMidX
						)

						if (displacementFromMidX < closestToMidAmount) {
							closestToMidAmount = displacementFromMidX
							closestToMidIndex = index
						}
					})

					// update the current question index if necessary
					if (closestToMidIndex < 0 || closestToMidIndex === currentIndex) {
						return
					}
					setCurrentIndex(closestToMidIndex)
				}}>
				{responses.map((response, index) => (
					<AttemptWrapper
						ref={ref => {
							if (
								!finishedInitialJump.current &&
								ref &&
								response.studentResponse.answerNumber === startingAttemptToView
							) {
								finishedInitialJump.current = true // element may be remounted, only jump during initial render
								ref.scrollIntoView({
									behavior: 'instant', // this has to be "instant" for this to work with scroll snapping
									block: 'center',
									inline: 'center',
								})
							}
							attemptRefs.current[index] = ref
						}}
						key={index}>
						{responses.length > 1 ? (
							<AttemptLabel onClick={() => onClose()}>
								Attempt {index + 1}
							</AttemptLabel>
						) : null}
						<div
							onClick={() => {
								jumpToAttempt(index)
							}}>
							<Question
								className="max-h-[85vh] overflow-y-scroll"
								teacherQuestion
								isQuestionAnalytics
								studentResponse={response.studentResponse}
								studentName={name}
								studentId={studentId}
								onRequestClose={onClose}
								question={response.question}
							/>
						</div>
					</AttemptWrapper>
				))}
			</StudentResponseFlipBook>
			<div className="absolute top-0 bottom-0 left-2 right-2 flex flex-row items-center justify-between pointer-events-none text-3xl">
				{currentIndex > 0 ? (
					<Circle onClick={() => jumpToAttempt(currentIndex - 1)}>
						<FaArrowLeft />
					</Circle>
				) : (
					<div />
				)}
				{currentIndex < responses.length - 1 ? (
					<Circle onClick={() => jumpToAttempt(currentIndex + 1)}>
						<FaArrowRight />
					</Circle>
				) : (
					<div />
				)}
			</div>
		</>
	)
}

const StudentResponseFlipBook = styled.div`
	display: flex;
	flex-direction: row;
	gap: 128px;
	padding-left: 25vw;
	padding-right: 25vw;
	overflow-x: scroll;
	align-items: center;
	height: 100%;
	scroll-snap-type: x proximity;
`

const AttemptWrapper = styled.div`
	flex: 0 0 auto;
	scroll-snap-align: center;
	min-width: min(40vw, 800px);
	max-width: 80vw;
`

const AttemptLabel = styled.h2`
	color: var(--app-lightest-gray);
	text-align: center;
`

function Circle(props) {
	return (
		<div
			{...props}
			className="pointer-events-all cursor-pointer text-white bg-gray p-2 flex items-center justify-center rounded-full w-10 h-10"
		/>
	)
}
