// @flow
import React, { useState, useMemo, useCallback } from 'react'
import { Helmet } from 'react-helmet'
import { useLocation, useHistory } from 'react-router-dom'
import { useQueryParams, StringParam, DelimitedArrayParam, withDefault } from 'use-query-params'
import styled from 'styled-components/macro'
import { animated, useTransition, config as reactSpringConfig } from 'react-spring'
import EmptyAnalyticsImage from '../../assets/NoData.png'
import AnalyticsDataViewer from './AnalyticsDataViewer'
import { ALL_OPTION, ALL, PROFICIENCY_COLORS } from './constants'
import { useVerifiedRoles } from '../../services/hooks/user'
import { PageTitleHeader, Spinner, SpinnerHolder } from '../../styles/sharedComponents'
import { Button } from '@mission.io/styles'
import {
	getSortedOptions,
	createMissionOptions,
	filterAnalytics,
	type AnalyticsFilters,
	type SelectOption,
	getTotalStudentCount,
} from './analyticsHelpers'
import { getAllDemoAnalyticsObjects, useCanUseDemo } from './demoAnalytics'
import type { AnalyticsObject } from '../../models/Analytics'
import { SurveyModal } from '../../surveys/MissionSurvey'
import StudentPage from './StudentPage'
import type { NameLookup } from '../../download/questions'
import { useLocationParameters } from '../../utility/hooks'
import {
	customSelectStyles,
	EmptyComponent,
	Input,
	CheckMenuList,
} from '../../components/helpers/MissionMenuList'
import { getFullName } from '../../utility/helpers'
import { useAllAnalytics, useTimeOptions } from './hooks'
import { getCSVDateRangeString } from './AnalyticsCharts/csvGenerator'
import { Select } from '../../components/inputs/Select'
import { UsageData } from './UsageData.jsx'
import { rolesEnum } from '../../store/types'
import type { TimeSelectOption } from './hooks'

/**
 * A hook that returns whether the user is currently in demo mode, the set state function for demo mode, and the analytics and
 * idToNameMap. If isDemoMode is true, the returned data will be the *demo* data.
 */
function useDemoAnalyticsToggle(
	analytics: AnalyticsObject[],
	idToNameMap: NameLookup
): {
	allAnalytics: Array<AnalyticsObject>,
	idToNameMap: NameLookup,
	isDemoMode: boolean,
	setIsDemoMode: boolean => mixed,
} {
	const [isDemoMode, setIsDemoMode] = useState(false)

	const { demoAnalytics, demoIdToNameMap } = useMemo(() => getAllDemoAnalyticsObjects(), [])

	return {
		allAnalytics: isDemoMode ? demoAnalytics : analytics,
		idToNameMap: isDemoMode ? demoIdToNameMap : idToNameMap,
		isDemoMode,
		setIsDemoMode,
	}
}

const StringParamWithAllDefault = withDefault(StringParam, ALL)

const USE_QUERY_PARAMS_CONFIG = {
	school: StringParamWithAllDefault,
	grade: StringParamWithAllDefault,
	teacher: StringParamWithAllDefault,
	class: StringParamWithAllDefault,
	missions: DelimitedArrayParam,
	selectedTimeRange: StringParam,
}
type Query = {
	school: string,
	grade: string,
	teacher: string,
	class: string,
	missions: string[] | void,
	selectedTimeRange: ?string,
}

type SetQuery = (query: $Partial<$ObjMap<Query, <V>(V) => V>>, method?: 'push' | 'replace') => mixed

export default function Analytics(): React$Node {
	const url = useLocation()
	const history = useHistory()
	const { survey: paramsMissionSurveyId, student: focusedStudent } = useLocationParameters()
	const [surveyModalMissionId, setSurveyModalMissionId] = useState(paramsMissionSurveyId)
	const closeSurveyModal = useCallback(() => {
		const params = new URLSearchParams(url.search)
		params.delete('survey')
		history.replace({
			search: params.toString(),
		})

		setSurveyModalMissionId(null)
	}, [url, history])

	const [query, setQuery]: [Query, SetQuery] = useQueryParams(USE_QUERY_PARAMS_CONFIG, {
		removeDefaultsFromUrl: true,
	})
	const timeOptions = useTimeOptions()
	const selectedTimeOption = useMemo(
		() => getSelectedTimeOption(timeOptions.allOptions, query.selectedTimeRange),
		[query.selectedTimeRange, timeOptions]
	)
	const { data, isLoading: isLoadingAnalytics, error: analyticsError } = useAllAnalytics(
		selectedTimeOption.queryRangeTimestamps
	)

	const isLoading = isLoadingAnalytics

	const { allAnalytics, idToNameMap, isDemoMode, setIsDemoMode } = useDemoAnalyticsToggle(
		data?.validAnalytics || [],
		data?.idToNameMap || {}
	)
	const canUseDemo = useCanUseDemo()

	const { schoolOptions, gradeOptions, teacherOptions, classOptions } = useMemo(
		() => getSortedOptions(allAnalytics, query.school, query.grade, query.teacher, idToNameMap),
		[allAnalytics, query.school, query.grade, query.teacher, idToNameMap]
	)

	const {
		filters,
		selectedSchoolOption,
		selectedGradeOption,
		selectedTeacherOption,
		selectedClassOption,
	}: {
		filters: AnalyticsFilters,
		selectedSchoolOption: SelectOption,
		selectedGradeOption: SelectOption,
		selectedTeacherOption: SelectOption,
		selectedClassOption: SelectOption,
	} = useMemo(() => {
		const selectedSchoolOption =
			schoolOptions.find(option => option.value === query.school) || ALL_OPTION
		const selectedGradeOption =
			gradeOptions.find(option => option.value === query.grade) || ALL_OPTION
		const selectedTeacherOption =
			teacherOptions.find(option => option.value === query.teacher) || ALL_OPTION
		const selectedClassOption =
			classOptions.find(option => option.value === query.class) || ALL_OPTION

		const filters: AnalyticsFilters = { time: selectedTimeOption.rangeTimestamps }

		if (selectedSchoolOption.value !== ALL) {
			filters.schoolId = selectedSchoolOption.value
		}

		if (selectedGradeOption.value !== ALL) {
			filters.grade = selectedGradeOption.value
		}
		if (selectedTeacherOption.value !== ALL) {
			filters.teacherId = selectedTeacherOption.value
		}
		if (selectedClassOption.value !== ALL) {
			filters.className = selectedClassOption.value
		}
		return {
			filters,
			selectedSchoolOption,
			selectedGradeOption,
			selectedTeacherOption,
			selectedClassOption,
			selectedTimeOption,
		}
	}, [
		classOptions,
		gradeOptions,
		query.class,
		query.grade,
		query.school,
		query.teacher,
		selectedTimeOption,
		schoolOptions,
		teacherOptions,
	])

	const {
		filteredAnalytics, // the list of analytics after applying the filters, including the mission filter
		isMissionSelected, // a function that returns whether a mission with a given id is selected
		selectedMissionOptions, // the list of mission options that are selected from the missions dropdown
		missionOptions, // the list of all mission options. There is one option for each item in the filtered analytics
	} = useMemo(() => {
		// Analytics objects filtered by everything except for the mission filter. This list of analytics
		// represent the missions that should be selectable by the user based on the filters they have selected.
		const _filteredAnalytics = filterAnalytics(allAnalytics, filters)
		const missionOptions = createMissionOptions(_filteredAnalytics)
		const selectedMissionOptions = missionOptions.filter(option =>
			query.missions === undefined ? true : query.missions.some(val => val === option.value)
		)
		const isMissionSelected = (id: string) =>
			selectedMissionOptions.some(val => val.value === id)
		return {
			filteredAnalytics: _filteredAnalytics.filter(analyticsObject =>
				isMissionSelected(analyticsObject.missionId)
			),
			missionOptions,
			selectedMissionOptions,
			isMissionSelected,
		}
	}, [allAnalytics, filters, query.missions])

	const teacherViewSpecified =
		selectedTeacherOption.value !== ALL || selectedClassOption.value !== ALL
	const userRoles = useVerifiedRoles()
	const showSchoolPicker = userRoles.has(rolesEnum.DISTRICT_ADMIN)
	const showTeacherPicker =
		userRoles.has(rolesEnum.SCHOOL_ADMIN) ||
		userRoles.has(rolesEnum.DEPRECATED_SCHOOL_ADMIN) ||
		userRoles.has(rolesEnum.DISTRICT_ADMIN)
	const showClassAndMissionPicker = !userRoles.has(rolesEnum.DISTRICT_ADMIN)

	const metaDescription =
		'Revolutionize student assessment. Track real-world skill and knowledge mastery live in every mission.'
	return (
		<Wrapper>
			<Helmet>
				<title>Analytics: Unprecedented Insight Into Student Knowledge & Skills</title>
				<meta name="description" content={metaDescription} />
				<meta name="og:description" content={metaDescription} />
			</Helmet>
			{surveyModalMissionId && (
				<SurveyModal missionId={surveyModalMissionId} requestClose={closeSurveyModal} />
			)}
			<BlockIfSharingScreen maybeSharingScreen={!!paramsMissionSurveyId}>
				{focusedStudent ? (
					<StudentPage
						studentId={focusedStudent}
						analytics={allAnalytics}
						isLoading={isLoading}
						name={getFullName(idToNameMap[focusedStudent])}
					/>
				) : (
					<>
						<PageTitleHeader css="display: flex; justify-content: space-between; align-items: center;">
							Analytics
							{canUseDemo && (
								<FadingButton
									className="leading-none"
									onClick={() => setIsDemoMode(true)}
									isVisible={!isDemoMode}>
									It’s demo time!
								</FadingButton>
							)}
						</PageTitleHeader>
						<AnalyticsFiltersHeader
							{...{
								selectedGradeOption,
								isMissionSelected,
								missionOptions,
								setQuery,
								classOptions,
								selectedClassOption,
								selectedMissionOptions,
								selectedSchoolOption,
								selectedTeacherOption,
								selectedTimeOption,
								gradeOptions,
								schoolOptions,
								teacherOptions,
								showSchoolPicker,
								showTeacherPicker,
								showClassAndMissionPicker,
								timeSelectOptions: timeOptions.reactSelectOptions,
							}}
						/>
						{isLoading ? (
							<SpinnerHolder>
								<Spinner className="spinner-border" role="status">
									<span className="only-visible-to-screen-readers">
										Loading...
									</span>
								</Spinner>
							</SpinnerHolder>
						) : analyticsError ? (
							<MessageDiv>
								{analyticsError.message === 'Failed to fetch' &&
								analyticsError.name === 'TypeError' ? (
									// This is the error that the `fetch` api rejects with when it encounters a network error. We've had multiple
									// reports in this specific request to the analytics endpoint that the user can't see analytics, and it's because
									// of their ad blocker. When blocked by an ad blocker, there is usually an error in the console and network tab that
									// says `ERR_BLOCKED_BY_CLIENT`, but I don't believe we have access to that in js, and so without access to that
									// there does not appear to be a way to differentiate between an unavailable server and being blocked by an ad blocker.
									<div>
										<h4>Failed to retrieve analytics</h4>
										You may be able to fix this problem by disabling your ad
										blocker for this site. This is because some ad blockers will
										block any data that includes the word “analytics”.
									</div>
								) : (
									analyticsError.message || 'Failed to retrieve analytics'
								)}
							</MessageDiv>
						) : filteredAnalytics.length > 0 ? (
							<>
								<UsageData
									{...{
										funFacts: [
											showSchoolPicker
												? {
														title: 'Total Schools',
														value:
															query.school !== ALL
																? 1
																: // -1 to exclude the "All" option
																  schoolOptions.length - 1,
												  }
												: null,
											showTeacherPicker
												? {
														title: 'Total Teachers',
														value:
															query.teacher !== ALL
																? 1
																: // -1 to exclude the "All" option
																  teacherOptions.length - 1,
												  }
												: null,
											{
												title: 'Total Missions',
												value:
													query.missions?.length || missionOptions.length,
											},
											{
												title: 'Total Students',
												value: getTotalStudentCount(filteredAnalytics),
											},
										].filter(Boolean),
										showHighestUsageTable:
											userRoles.has(rolesEnum.DISTRICT_ADMIN) ||
											userRoles.has(rolesEnum.SCHOOL_ADMIN),
										schoolId:
											selectedSchoolOption.value === ALL
												? undefined
												: selectedSchoolOption.value,
									}}
								/>
								<AnalyticsDataViewer
									isDemoMode={isDemoMode}
									analyticsData={filteredAnalytics}
									isSpecificSchool={selectedSchoolOption.value !== ALL}
									teacherViewSpecified={teacherViewSpecified}
									nameLookup={idToNameMap}
									onRequestSurveyOpen={setSurveyModalMissionId}
									csvDateRange={getCSVDateRangeString(selectedTimeOption)}
								/>
							</>
						) : (
							<div className="flex flex-col items-center [&_p]:text-2xl">
								<img
									src={EmptyAnalyticsImage}
									alt="No Analytics"
									className="max-w-96"
								/>
								{allAnalytics.length === 0 ? (
									<>
										<p>Looks like you don&#39;t have any data yet!</p>
										<p>
											Go to the mission library to run a mission and get some
											data.
										</p>
									</>
								) : (
									<p>There is no mission selected!</p>
								)}
							</div>
						)}
					</>
				)}
			</BlockIfSharingScreen>
		</Wrapper>
	)
}

/**
 * When returning to Dashboard from a mission, it is possible and likely that the user is sharing their screen with the class. This
 * component will warn the user that they are about to share personal data with the class, and ask them to confirm that they are not
 * sharing their screen.
 * @param props.children - The children to render if the user is not sharing their screen
 * @param props.potentiallySharingScreen - Whether the user is potentially sharing their screen
 */
function BlockIfSharingScreen({
	children,
	maybeSharingScreen,
}: {
	children: React$Node,
	maybeSharingScreen: boolean,
}) {
	const [shouldBlock, setShouldBlock] = useState(maybeSharingScreen)

	if (shouldBlock) {
		return (
			<div className="flex items-center justify-center flex-col m-4 text-xl text-center lg:mx-8 xl:mx-16 2xl:mx-24">
				You are about to view personal data about your students. Please verify that you are
				not sharing your screen with the class.
				<Button onClick={() => setShouldBlock(false)} className="mt-4">
					I am not sharing my screen
				</Button>
			</div>
		)
	}

	return children
}

/**
 * The header for the analytics page that contains the filters for the analytics data
 */
function AnalyticsFiltersHeader({
	selectedSchoolOption,
	setQuery,
	schoolOptions,
	selectedGradeOption,
	gradeOptions,
	selectedTeacherOption,
	teacherOptions,
	selectedClassOption,
	classOptions,
	selectedMissionOptions,
	missionOptions,
	isMissionSelected,
	selectedTimeOption,
	showSchoolPicker,
	showTeacherPicker,
	showClassAndMissionPicker,
	timeSelectOptions,
}: {
	selectedSchoolOption: SelectOption,
	setQuery: SetQuery,
	schoolOptions: Array<SelectOption>,
	selectedGradeOption: SelectOption,
	gradeOptions: Array<SelectOption>,
	selectedTeacherOption: SelectOption,
	teacherOptions: Array<SelectOption>,
	selectedClassOption: SelectOption,
	classOptions: Array<SelectOption>,
	selectedMissionOptions: Array<SelectOption>,
	missionOptions: Array<SelectOption>,
	isMissionSelected: (id: string) => boolean,
	selectedTimeOption: TimeSelectOption,
	showSchoolPicker: boolean,
	showTeacherPicker: boolean,
	showClassAndMissionPicker: boolean,
	timeSelectOptions: Array<{| label: string, options: Array<TimeSelectOption> |}>,
}) {
	return (
		<div className="flex items-center mb-8">
			<div className="flex w-full">
				{showSchoolPicker && (
					<div className="flex flex-col w-[15%] mr-[3%]">
						<label id="school-select">School:</label>
						<StyledSelect
							aria-labelledby="school-select"
							value={selectedSchoolOption}
							onChange={school => {
								setQuery(
									{
										school: school.value,
									},
									'push' // remove everything from query except 'school'
								)
							}}
							options={schoolOptions}
						/>
					</div>
				)}
				<Grade>
					Grade:
					<StyledSelect
						value={selectedGradeOption}
						onChange={grade => {
							setQuery({
								grade: grade.value,
								teacher: undefined,
								class: undefined,
								missions: undefined,
							})
						}}
						options={gradeOptions}
					/>
				</Grade>
				{showTeacherPicker && (
					<Teacher>
						Teacher:
						<StyledSelect
							value={selectedTeacherOption}
							onChange={teacher => {
								setQuery({
									teacher: teacher.value,
									class: undefined,
									missions: undefined,
								})
							}}
							options={teacherOptions}
						/>
					</Teacher>
				)}
				{showClassAndMissionPicker && (
					<>
						<ClassName>
							Class:
							<StyledSelect
								value={selectedClassOption}
								onChange={className => {
									setQuery({
										class: className.value,
										missions: undefined,
									})
								}}
								options={classOptions}
							/>
						</ClassName>
						<MissionName>
							Mission:
							<Select
								placeholder=""
								isMulti
								closeMenuOnSelect={false}
								isError={selectedMissionOptions.length === 0}
								value={selectedMissionOptions}
								options={missionOptions}
								isSelected={isMissionSelected}
								components={{
									MultiValueContainer: EmptyComponent,
									ClearIndicator: EmptyComponent,
									MenuList: CheckMenuList,
									Input,
								}}
								styles={customSelectStyles}
								onChange={(missionList: SelectOption[]) => {
									let missions: string[] = missionList.map(m => m.value)
									if (missions.length === 0) missions = ['']
									const all = missionList.length === missionOptions.length
									setQuery({
										missions: all ? undefined : missions,
									})
								}}
							/>
						</MissionName>
					</>
				)}
			</div>
			<div className="flex flex-col w-1/5">
				Time Period:
				<StyledSelect
					value={selectedTimeOption}
					onChange={option => {
						setQuery({
							selectedTimeRange: option.id,
							missions: undefined,
						})
					}}
					options={timeSelectOptions}
				/>
			</div>
		</div>
	)
}

const AnimatedButton = animated(Button)

/**
 * A button that will fade out when its `isVisible` prop changes from true to false
 */
function FadingButton({
	children,
	isVisible,
	...props
}: {
	children: React$Node,
	isVisible: boolean,
}) {
	const transitions = useTransition(isVisible, {
		enter: { opacity: 1 },
		leave: { opacity: 0 },
		config: reactSpringConfig.slow,
	})
	return transitions(
		(styles, visible) =>
			visible && (
				<AnimatedButton {...props} style={{ ...styles }}>
					{children}
				</AnimatedButton>
			)
	)
}

const Wrapper = styled.div`
	font-style: normal;
	letter-spacing: 0;
	${({ theme }) => `
		padding: 0 var(--spacing2x-dont-use);
	`}

	--proficiency-level-1: ${PROFICIENCY_COLORS[0]};
	--proficiency-level-2: ${PROFICIENCY_COLORS[1]};
	--proficiency-level-3: ${PROFICIENCY_COLORS[2]};
	--proficiency-level-4: ${PROFICIENCY_COLORS[3]};
`

const Grade = styled.div`
	display: flex;
	flex-direction: column;
	width: 10%;
	margin-right: 3%;
`

const Teacher = styled.div`
	display: flex;
	flex-direction: column;
	width: 20%;
	margin-right: 3%;
`

const ClassName = styled.div`
	display: flex;
	flex-direction: column;
	width: 20%;
	margin-right: 3%;
`

const MissionName = styled.div`
	display: flex;
	flex-direction: column;
	width: 25%;
`

const Z_INDEX = 3
const StyledSelect = styled(Select)`
	z-index: ${Z_INDEX};
`

const MessageDiv = styled.div.attrs({
	className: 'flex justify-center items-center text-center rounded text-xl',
})`
	${({ theme }) => `
		background-color: ${theme.neutral};
		color:  ${theme.black};
	`}
`

/**
 * getSelectedTimeOption - get the selected time option
 *
 * @param {Array<TimeSelectOption>} timeOptions - the possible selected time options
 * @param {?string} selectedOptionId - the currently select option's id
 *
 * @return {TimeSelectOption} the selected TimeSelectOption, or the first option if no option is currently selected
 */
function getSelectedTimeOption(
	timeOptions: Array<TimeSelectOption>,
	selectedOptionId: ?string
): TimeSelectOption {
	return timeOptions.find(option => selectedOptionId === option.id) ?? timeOptions[0]
}
