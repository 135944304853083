import React from 'react'
import { type TooltipRenderProps } from 'react-joyride'
import { ExitX } from '../../modal/Modal'
import { Button } from '@mission.io/styles'
import styled from 'styled-components/macro'
import { GestureLeftPoint } from './variationsOnRalf'
import { useInteractionContext } from '../interactions'
import { INTRODUCTORY_STEP_COUNT } from '../steps'

/**
 * A custom component to override the react-joyride tooltip component. User walkthrough steps render this component by default.
 * @param {TooltipRenderProps} props provided by react-joyride
 * @returns {React$Node}
 */
export default function Popup({
	closeProps,
	continuous,
	primaryProps,
	step,
	tooltipProps,
	backProps,
	index,
	size,
}: TooltipRenderProps): React$Node {
	const styles = step.styles || {}
	const { sendClickInteraction, lookups } = useInteractionContext()
	return (
		<PopupStyled {...tooltipProps} className="react-joyride__tooltip" style={styles.tooltip}>
			<div className="popup__header">
				<ExitX
					css="position: absolute; top: 4px; right:4px; color: white; font-size: .75em;"
					onClick={e => {
						sendClickInteraction(lookups.clickIds.CLOSE_TOUR)
						closeProps.onClick(e)
					}}
				/>
			</div>
			<div style={styles.tooltipContainer}>
				{step.title && (
					<h4 style={styles.tooltipTitle}>
						<span>{step.title}</span>
					</h4>
				)}
				<div style={styles.tooltipContent} css="z-index: 2; position: relative;">
					{step.content}
				</div>
				{!step.hideFooter && (
					<div style={styles.tooltipFooter}>
						<div className="flex justify-between w-full items-center">
							<div>
								{step.showProgress && (
									<i>
										{index + 1 - INTRODUCTORY_STEP_COUNT} of{' '}
										{size - INTRODUCTORY_STEP_COUNT}
									</i>
								)}
							</div>
							{continuous && (
								<div className="flex flex-row gap-2">
									{!step.hideBackButton && (
										<Button {...backProps} variant="secondary">
											{backProps.title}
										</Button>
									)}
									{/* $FlowFixMe[incompatible-use] the step has been customized and does have an optional hideNextButton */}
									{!step.hideNextButton && (
										<Button {...primaryProps} variant="secondary">
											{primaryProps.title}
										</Button>
									)}
								</div>
							)}
							{!continuous && <Button {...closeProps}>{closeProps.title}</Button>}
						</div>
					</div>
				)}
			</div>
			<ImageWrapper>
				{/* $FlowFixMe[incompatible-use] the step has been customized and does have an optional image */}
				{step?.image || <GestureLeftPoint />}
			</ImageWrapper>
		</PopupStyled>
	)
}

const PopupStyled = styled.div`
	position: relative;
`

const ImageWrapper = styled.div`
	position: absolute;
	z-index: 1;
	bottom: -50px;
	left: -50px;
	height: 150px;
	svg {
		height: 100%;
	}
`
