import React from 'react'
import { useState } from 'react'

import { FaClose, FaCheck, FaCircle } from 'react-icons/lib/fa'

import {
	didAnalyticsDocumentPass,
	type CreativeCanvasStationIssue,
	type CollaborativeCulminatingMomentAnalyticsScore,
	type ClassStudentAnalytics,
} from '@mission.io/mission-toolkit'

import Loading from '../../../styles/LoadingSpinner'
import { useSpecificMissionContext } from '../SpecificMissionContext'
import { config } from '../../../config'
import { getFullName } from '../../../utility/helpers'
import type { NameLookup } from '../../../download/questions'

export type DocumentData = {
	documentId: string,
	latest: {
		issue: CreativeCanvasStationIssue,
		score: CollaborativeCulminatingMomentAnalyticsScore,
		studentNames: string[],
	},
}

/**
 * A function to get data to display creative canvas documents on a mission.
 * For each document it returns the document id, the issue, the score, and the students who created it.
 * @param {?ClassStudentAnalytics} analytics
 * @param {NameLookup} nameLookup
 * @returns {DocumentData[]}
 */
export function getStudentDocumentData(
	analytics: ?ClassStudentAnalytics,
	nameLookup: NameLookup
): ?Array<DocumentData> {
	if (!analytics) {
		return null
	}
	const documentIdToLatestCollaborativeCulminatingMomentData: {
		[documentId: string]: {
			issue: CreativeCanvasStationIssue,
			score: CollaborativeCulminatingMomentAnalyticsScore,
			studentNames: string[],
		},
	} = {}

	Object.keys(analytics?.collaborativeCulminatingMoments ?? {}).forEach(
		collaborativeCulminatingMomentDataId => {
			const collaborativeCulminatingMomentData =
				analytics?.collaborativeCulminatingMoments?.[collaborativeCulminatingMomentDataId]
			if (!collaborativeCulminatingMomentData) {
				return
			}

			const issueIdToIssue = {}
			collaborativeCulminatingMomentData.issues.forEach(issue => {
				issueIdToIssue[issue.id] = issue
			})

			collaborativeCulminatingMomentData.documents.forEach(doc => {
				const documentIssue = issueIdToIssue[doc.issueId]
				if (!documentIssue) {
					return
				}
				documentIdToLatestCollaborativeCulminatingMomentData[doc.documentId] = {
					issue: documentIssue,
					score: doc.scores,
					studentNames: [],
				}
			})
		}
	)
	Object.keys(analytics?.studentAnalytics ?? {}).forEach(studentId => {
		const creativeCanvases = analytics?.studentAnalytics?.[studentId]?.creativeCanvases
		creativeCanvases?.forEach(creativeCanvas => {
			const documentId = creativeCanvas.documentId
			if (!documentIdToLatestCollaborativeCulminatingMomentData[documentId]) {
				return
			}
			const fullName = getFullName(nameLookup[studentId])
			if (!fullName) {
				return
			}
			documentIdToLatestCollaborativeCulminatingMomentData[documentId].studentNames.push(
				fullName
			)
		})
	})

	return Object.keys(documentIdToLatestCollaborativeCulminatingMomentData).map(documentId => {
		const latestData = documentIdToLatestCollaborativeCulminatingMomentData[documentId]
		return {
			documentId,
			latest: { ...latestData, studentNames: latestData.studentNames.sort() },
		}
	})
}

/**
 * MissionDocumentReview - shows each most recent documents for all collaborative culminating moments in a mission.
 *
 * @param {Object} props - the react props
 * @param {?Array<CollaborativeCulminatingMomentDocumentData>} props.culminatingMomentData - the collaborative culminating moment data for the student
 *
 * @return {React$Node}
 */
export function MissionDocumentReview(): React$Node {
	const { selectedMission, nameLookup } = useSpecificMissionContext()
	const analytics = selectedMission?.analytics
	const documentData: ?Array<DocumentData> = React.useMemo(() => {
		return getStudentDocumentData(analytics, nameLookup)
	}, [analytics, nameLookup])
	if (!documentData || documentData.length === 0) {
		return null
	}

	return (
		<div className="mb-6">
			<h1 className="mb-2 pt-1 text-2xl flex gap-2 ">Student Documents</h1>
			<div className="grid grid-cols-1 gap-12">
				{documentData.map(data => (
					<DocumentView data={data} key={data.documentId} />
				))}
			</div>
		</div>
	)
}

/**
 *
 * DocumentView - A component for showing the document, the students who created it, and the score that the document received.
 *
 * @param {Object} props - the react props
 * @param {CollaborativeCulminatingMomentDocumentData} props.data - the collaborative culminating moment data for a single document
 *
 * @return {React$Node}
 */
function DocumentView({ data }: { data: DocumentData }): React$Node {
	const documentPassed = data
		? didAnalyticsDocumentPass(data.latest.score, data.latest.issue)
		: false

	return (
		<div className="flex-col rounded-lg">
			<div className="grid w-full grid-cols-4 grid-rows-[auto,_1fr] gap-2">
				<div className="col-span-4 row-span-1 rounded-lg bg-primary-50 text-primary-950 p-2">
					<div className="font-semibold text-xl mb-2 pt-1">
						Prompt: {data.latest.issue.prompt ?? ''}
					</div>

					<ul className="flex items-center gap-2 flex-wrap">
						{data.latest.studentNames.map(studentName => (
							<li
								key={studentName}
								className="rounded-lg bg-primary-500 text-primary-50 px-2">
								{studentName}
							</li>
						))}
					</ul>
				</div>
				<div className="col-span-3 row-span-2 relative group h-fit">
					<CanvasImage documentId={data.documentId} />
					<div className="transition-opacity absolute bottom-1 right-2 z-1 text-l opacity-50 group-hover:opacity-0">
						{documentPassed ? (
							<FaCheck className={`size-8 text-success`} />
						) : (
							<FaClose className={`size-8 text-failure`} />
						)}
					</div>
				</div>
				<div className="overflow-y-auto col-span-1">
					<CriteriaScoreViewer issue={data.latest.issue} score={data.latest.score} />
				</div>
			</div>
		</div>
	)
}

/**
 * CriteriaScoreViewer - a component which shows the criteria and how the document was scored during a mission
 *
 * @param {Object} props - the react props
 * @param {CollaborativeCulminatingMomentDocumentScores} props.scoreData - the scores the student got
 *
 * @return {React$Node}
 */
function CriteriaScoreViewer({
	issue,
	score,
}: {
	issue: CreativeCanvasStationIssue,
	score: CollaborativeCulminatingMomentAnalyticsScore,
}): React$Node {
	return (
		<div className="space-y-2 flex-col gap-2">
			{issue.rubric.criteria.map(criteria => {
				const selectedOption = criteria.gradingOptions.find(
					option => option.id === score.criteriaId[criteria.id]?.selectedOption
				)
				return (
					<div
						key={criteria.id}
						className={`flex items-center gap-2 rounded-lg px-1 py-2 text-white ${
							selectedOption?.correct == null
								? 'bg-neutral text-neutral-dark'
								: selectedOption.correct
								? 'bg-success'
								: 'bg-error'
						}`}>
						{selectedOption?.correct == null ? (
							<FaCircle />
						) : selectedOption.correct ? (
							<FaCheck />
						) : (
							<FaClose />
						)}
						<div className="flex-1">{criteria.text}</div>
					</div>
				)
			})}
		</div>
	)
}

/**
 * Displays the canvas as an svg image. If the canvas is not available, it will display an error message
 * @param {string} props.documentId the id of the document to display
 * @returns {React$Node}
 */
function CanvasImage({ documentId }: { documentId: string }): React$Node {
	const missionId = useSpecificMissionContext().selectedMission?.missionId
	let src
	if (missionId) {
		src = `${config.missionsAPIURL}/api/creative-canvas-image/${documentId}?missionId=${missionId}`
	}

	const [status, setStatus] = useState('loading')

	return (
		<>
			{status === 'loading' && (
				<div className="h-20 place-content-center">
					<Loading shouldShowSpinner className="status loading size-20" />
				</div>
			)}
			{src && status !== 'error' ? (
				<img
					className="rounded-lg"
					src={src}
					alt={`Canvas design for issue`}
					onError={() => {
						setStatus('error')
					}}
					onLoad={() => {
						setStatus('complete')
					}}
				/>
			) : (
				<div className="rounded-lg status error text-center h-full bg-neutral text-neutral-dark place-content-center min-h-[20vh] ">
					Unable to display image
				</div>
			)}
		</>
	)
}
