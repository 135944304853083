import React from 'react'
import styled from 'styled-components'
import { Button } from '@mission.io/styles'
import {
	ImageWrapper,
	Row,
	EndOfMissionResultsBlurb,
	MissionGuideButton,
	AdvancedDescription,
} from './components'
import { Link } from 'react-router-dom'
import { PERCENTAGE_SCORES } from '../AnalyticsCharts/ProficiencyBarGraph/constants'
import { CATEGORIES } from '../StudentPage/constants'
import { MAIN_ANALYTICS_CATEGORIES } from '@mission.io/mission-toolkit'

const PROFICIENCY_PERCENT = PERCENTAGE_SCORES[2]

export const infoIdToCategory: { [string]: string } = {
	[CATEGORIES.questions.key]: 'knowledge',
	[CATEGORIES.application.key]: 'knowledge',
	[CATEGORIES.collaboration.key]: 'collaboration',
	[CATEGORIES.criticalThinking.key]: 'critical-thinking',
	[CATEGORIES.grit.key]: 'resilience',
	[CATEGORIES.initiative.key]: 'initiative',
}

const BaseAlign = styled.div`
	display: flex;
	justify-content: center;
	align-items: baseline;
`

const CATEGORY_INFO_DATA: {
	['knowledge' | 'resilience' | 'critical-thinking' | 'initiative' | 'collaboration']: {
		title: string,
		header: React$Node,
		subHeader: string,
		measurementQuestion: string,
		measurement: React$Node,
		proficiencyExplained: React$Node,
		intervention: React$Node,
		extension: React$Node,
	},
} = {
	knowledge: {
		title: 'Knowledge and Application',
		header: (
			<BaseAlign>
				<Header>Knowledge</Header>&nbsp;and&nbsp;<Header>Application</Header>
			</BaseAlign>
		),
		subHeader: 'Mastering content and transferring knowledge to real-world situations',
		measurementQuestion: 'How are knowledge and application measured?',
		measurement: (
			<div>
				<Row>
					<ImageWrapper>
						<img
							className="not-prose"
							alt="Graduation cap"
							src="https://resources-cdn.mission.io/public/pdf-resources/images/AcademicMasteryIcon.png"
						/>
					</ImageWrapper>
					<p>
						Students earn their <b>KNOWLEDGE</b>&nbsp;proficiency level based on their
						performance on quiz questions. In general, quiz questions tend to assess
						remembering, recall, and foundational understanding of key academic skills
						and concepts. Learn how to customize missions with your own questions&nbsp;
						<Link to="training?trainingStage=creatingandeditingquestions&training=basicTraining">
							here
						</Link>
						.
					</p>
				</Row>
				<Row>
					<ImageWrapper>
						<img
							className="not-prose"
							alt="Graduation cap"
							src="https://resources-cdn.mission.io/public/pdf-resources/images/TechLitIcon.png"
						/>
					</ImageWrapper>
					<p>
						The <b>APPLICATION</b>&nbsp;proficiency level is based on student
						performance on critical decision moments that follow investigations. These
						decision moments involve deeper application, data analysis, strategic
						thinking, synthesis, and evaluation.
					</p>
				</Row>
			</div>
		),
		proficiencyExplained: (
			<div>
				If a student received a 3 or 4 in these categories, they are considered proficient.
				If a student is proficient in <b>KNOWLEDGE</b>&nbsp;, that means they got at least
				{PROFICIENCY_PERCENT}% of their quiz questions correct the first time. If a student
				is proficient in&nbsp;
				<b>APPLICATION</b>&nbsp;, that means they got at least {PROFICIENCY_PERCENT}% of the
				application questions correct on the first time and are able to analyze data, draw
				conclusions, and make deeper connections.
			</div>
		),
		intervention: (
			<div>
				<EndOfMissionResultsBlurb />
				<Row>
					<p>
						Check out the mission guide to see supplemental <b>INTERVENTION</b>{' '}
						resources you can use with students who struggled with the content of this
						mission. Now is a great time to intentionally intervene with these students
						while the mission is still fresh!
					</p>
					<MissionGuideButton />
				</Row>
			</div>
		),
		extension: (
			<Row>
				<Row>
					<p>
						Challenge proficient students by giving them a chance to dig even deeper
						into the content! Check out the mission guide for supplemental{' '}
						<b>EXTENSION</b> resources (including writing, discussion, research, and/or
						engineering prompts related to the mission).
					</p>
				</Row>
				<MissionGuideButton />
			</Row>
		),
	},
	initiative: {
		header: <Header>Initiative</Header>,
		title: 'Initiative',
		subHeader: 'Responding to tasks promptly and independently',
		measurementQuestion: 'How is initiative measured?',
		measurement: (
			<div>
				<Row>
					<ImageWrapper>
						<img
							className="not-prose"
							alt="Gears"
							src="https://resources-cdn.mission.io/public/pdf-resources/images/GearsIcon.png"
						/>
					</ImageWrapper>
					<p>
						Students earn their <b>INITIATIVE</b>&nbsp;proficiency level based on how
						quickly they respond to alerts, initiate stations, cast votes, etc. and how
						interactive students are during the mission.
					</p>
				</Row>
				<AdvancedDescription category={MAIN_ANALYTICS_CATEGORIES.INITIATIVE} />
			</div>
		),
		proficiencyExplained: (
			<p>
				If a student received a 3 or 4, they are considered proficient. If a student is
				proficient in <b>INITIATIVE</b>, that means that they were actively engaged in the
				experience and responded promptly to alerts, characters, and tasks as soon as they
				are received.
			</p>
		),
		intervention: (
			<div>
				<Row>
					<p>
						Encourage students to be completely on task during the mission and to
						respond immediately to all alerts, stations, and questions that display on
						their individual screens.
					</p>
				</Row>
				<Row>
					<p>
						Stay tuned and get excited! One day in the not-too-distant future, this
						section will be filled with <b>INTERVENTION</b> resources specifically for
						initiative.
					</p>
					<Button disabled>Resources Coming Soon</Button>
				</Row>
			</div>
		),
		extension: (
			<Row>
				<p>
					Stay tuned and get excited! One day in the not-too-distant future, this section
					will be filled with <b>EXTENSION</b> resources specifically for initiative.
				</p>
				<Button disabled>Resources Coming Soon</Button>
			</Row>
		),
	},
	collaboration: {
		header: <Header>Collaboration</Header>,
		title: 'Collaboration',
		subHeader: 'Working together and communicating effectively',
		measurementQuestion: 'How is collaboration measured?',
		measurement: (
			<div>
				<Row>
					<ImageWrapper>
						<img
							className="not-prose"
							alt="Gears"
							src="https://resources-cdn.mission.io/public/pdf-resources/images/CollabIcon.png"
						/>
					</ImageWrapper>
					<p>
						Students earn their <b>COLLABORATION</b>&nbsp;proficiency level based on how
						effectively they work with others during the mission, such as when they are
						completing health and repair tasks.
					</p>
				</Row>
				<AdvancedDescription category={MAIN_ANALYTICS_CATEGORIES.COLLABORATION} />
			</div>
		),
		proficiencyExplained: (
			<p>
				If a student received a 3 or 4, they are considered proficient. If a student is
				proficient in <b>COLLABORATION</b>,that means they communicated quickly and
				effectively with others and saw collaborative tasks through to completion.
			</p>
		),
		intervention: (
			<div>
				<Row>
					<p>
						Encourage students to talk with others about the tasks and questions at hand
						and make sure they are working together to complete repairs in time. No
						flying solo during a mission!
					</p>
				</Row>
				<Row>
					<p>
						Stay tuned and get excited! One day in the not-too-distant future, this
						section will be filled with <b>INTERVENTION</b> resources specifically for
						collaboration.
					</p>
					<Button disabled>Resources Coming Soon</Button>
				</Row>
			</div>
		),
		extension: (
			<Row>
				<p>
					Stay tuned and get excited! One day in the not-too-distant future, this section
					will be filled with <b>EXTENSION</b> resources specifically for collaboration.
				</p>
				<Button disabled>Resources Coming Soon</Button>
			</Row>
		),
	},
	'critical-thinking': {
		header: <Header>Critical Thinking</Header>,
		title: 'Critical Thinking',
		subHeader: 'Analyzing data and drawing conclusions',
		measurementQuestion: 'How is critical thinking measured?',
		measurement: (
			<div>
				<Row>
					<ImageWrapper>
						<img
							className="not-prose"
							alt="Gears"
							src="https://resources-cdn.mission.io/public/pdf-resources/images/SpeechBubbleIcon.png"
						/>
					</ImageWrapper>
					<p>
						Students earn their <b>CRITICAL THINKING</b> proficiency level based on how
						effectively they are able to organize information, prioritize, and make
						deeper connections as they analyze data to make decisions.
					</p>
				</Row>
				<AdvancedDescription category={MAIN_ANALYTICS_CATEGORIES.CRITICAL_THINKING} />
			</div>
		),
		proficiencyExplained: (
			<p>
				If a student received a 3 or 4, they are considered proficient. If a student is
				proficient in <b>CRITICAL THINKING</b>, that means they effectively identified what
				pieces of information were most relevant to solving a given problem and came to the
				correct conclusions based on data regarding complex scenarios.
			</p>
		),
		intervention: (
			<div>
				<Row>
					<p>
						Throughout missions, make sure to ask your students what they are thinking.
						As they are analyzing data, have them summarize the data/information they
						are seeing and what it means. Then, encourage students to justify their
						conclusions.
					</p>
				</Row>
				<Row>
					<p>
						Stay tuned and get excited! One day in the not-too-distant future, this
						section will be filled with <b>INTERVENTION</b> resources specifically for
						critical thinking.
					</p>
					<Button disabled>Resources Coming Soon</Button>
				</Row>
			</div>
		),
		extension: (
			<div>
				<Row>
					<p>
						Challenge your students to grow more in their critical thinking by having
						them dig even deeper into the content of the mission with our provided
						<b>EXTENSION</b> activities (including writing, discussion, research, and/or
						engineering prompts).
					</p>
					<MissionGuideButton />
				</Row>
				<Row>
					<p>
						Stay tuned and get excited! One day in the not-too-distant future, this
						section will be filled with <b>EXTENSION</b> resources specifically for
						critical thinking.
					</p>
					<Button disabled>Resources Coming Soon</Button>
				</Row>
			</div>
		),
	},
	resilience: {
		title: 'Resilience',
		header: <Header>Resilience</Header>,
		subHeader: 'Bouncing back quickly after setbacks',
		measurementQuestion: 'How is resilience measured?',
		measurement: (
			<div>
				<Row>
					<ImageWrapper>
						<img
							className="not-prose"
							alt="Graduation cap"
							src="https://resources-cdn.mission.io/public/pdf-resources/images/ResilienceIcon.png"
						/>
					</ImageWrapper>
					<p>
						Students earn their <b>RESILIENCE</b>&nbsp;proficiency level based on how
						quickly students can bounce back and keep trying after setbacks or failure.
					</p>
				</Row>
				<AdvancedDescription category={MAIN_ANALYTICS_CATEGORIES.GRIT} />
			</div>
		),
		proficiencyExplained: (
			<p>
				If a student received a 3 or 4, they are considered proficient. If a student is
				proficient in <b>RESILIENCE</b>, that means they consistently tried again after
				setbacks, failures, or mistakes and never gave up!
			</p>
		),
		intervention: (
			<div>
				<Row>
					<p>
						When your students get a question wrong, encourage them to quickly try
						again. If they don’t succeed at a station and get another shot at it, remind
						them that the more quickly they jump back in, the higher their resilience
						score will be.
					</p>
				</Row>
				<Row>
					<p>
						Stay tuned and get excited! One day in the not-too-distant future, this
						section will be filled with <b>INTERVENTION</b> resources specifically for
						resilience.
					</p>
					<Button disabled>Resources Coming Soon</Button>
				</Row>
			</div>
		),
		extension: (
			<Row>
				<p>
					Stay tuned and get excited! One day in the not-too-distant future, this section
					will be filled with <b>EXTENSION</b> resources specifically for resilience.
				</p>
				<Button disabled>Resources Coming Soon</Button>
			</Row>
		),
	},
}

function Header({ children, ...props }: { children: React$Node }) {
	return (
		<h1 {...props} className="uppercase font-bold text-3xl my-4">
			{children}
		</h1>
	)
}

export default CATEGORY_INFO_DATA
