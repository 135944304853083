import React from 'react'
import styled from 'styled-components'
import type { PreviewProps } from './types'
import File from 'react-icons/lib/fa/file'

/**
 * Unknown - a "preview" component for file sources which do not match any other preview component
 *
 * @return {React$Node}
 */
export function Unknown(props: PreviewProps): React$Node {
	return <StyledFile aria-label="Unknown File Type" />
}

const StyledFile = styled(File)`
	font-size: 70vh;
	color: white;
	pointer-events: all;
`
