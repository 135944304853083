import { useQuery, type UseQueryOptions, type QueryObserverOptions } from 'react-query'
import axios, { type $AxiosError } from 'axios'
import type { MissionEvents } from '../constants'
import type { Role } from '../store/types'
import config from '../config'

type MissionUser = {
	_id: string,
	email: string,
	firstName: string,
	lastName: string,
	state: string,
	schoolId: string,
	schoolName: string,
	roles: Role[],
}

type BasicMission = {
	_id: string,
	events: Array<{
		type: MissionEvents,
		timestamp: Date,
	}>,
	simulationName: string,
	code: string,
	fullState: {
		version: number,
		missionPhase: string,
	},
	score: number,
	grades: string,
	flyer: MissionUser,
	owner: MissionUser,
	missionCategory: 'ON_SITE' | 'WHOLE_CLASS_REMOTE' | 'SQUADRON_REMOTE' | 'SQUADRON_HOMEWORK',
}

/**
 * A hook to fetch a basic mission from missions api
 * @param {string} missionId
 * @param {?QueryObserverOptions<?BasicMission, $AxiosError<mixed>>} options optional query observer options
 * @returns {{ mission: query.data, isLoading: query.isLoading }} the mission and an isLoading value
 */
export function useBasicMission(
	missionId: string,
	options?: QueryObserverOptions<?BasicMission, $AxiosError<mixed>>
): { mission: ?BasicMission, isLoading: boolean } {
	const query = useQuery(
		({
			...options,
			queryKey: ['basic-mission', missionId],
			queryFn: () =>
				axios
					.get(`${config.missionsAPIURL}/api/missions/${missionId}`, {
						withCredentials: true,
					})
					.then(res => res.data?.mission),
		}: UseQueryOptions<?BasicMission, $AxiosError<mixed>, ?BasicMission>)
	)

	return { mission: query.data, isLoading: query.isLoading }
}
