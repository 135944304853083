import React from 'react'
import { useMemo } from 'react'
import { ModalHeader, ModalBody, ExitX } from '../../../components/modal/Modal'
import { startCase, isNumber, snakeCase, toUpper, round } from 'lodash'
import { getAnalyticsVersionDescription } from '@mission.io/mission-toolkit'
import type { ProficiencyTableEntry } from '../../../models/Analytics'

export type ScoreInfoProps = {
	header: string,
	proficiencyScores: ProficiencyTableEntry,
	name: string,
	onRequestClose: () => void,
	version: number,
}

export default function ScoreInfo({
	header,
	proficiencyScores,
	name,
	onRequestClose,
	version,
}: ScoreInfoProps): React$Node {
	const analyticsScoreInfo = useMemo(() => {
		return getAnalyticsVersionDescription(version)?.description?.descriptionOfCategories[
			// $FlowExpectedError this should create a MainAnalyticsCategory string, but in the case it does not, this will result in null/undefined which is handled below
			toUpper(snakeCase(header))
		]
	}, [version, header])

	if (!analyticsScoreInfo || proficiencyScores[header] == null)
		return (
			<>
				<ModalHeader>
					{name} - {startCase(header)} Score
					<ExitX onClick={onRequestClose} />
				</ModalHeader>
				<ModalBody>
					<div className="text-sm">There is no score available for this category</div>
				</ModalBody>
			</>
		)

	const data = proficiencyScores[header]

	return (
		<>
			<ModalHeader>
				{name} - {startCase(header)} Score
				<ExitX onClick={onRequestClose} />
			</ModalHeader>
			<ModalBody>
				<ul className="list-none mb-6">
					{isNumber(data) && (
						<li>
							<div className="text-base font-semibold">
								{startCase(header)}: {round(data)}%
							</div>
							{analyticsScoreInfo &&
								Object.keys(analyticsScoreInfo.metricCalculationDescriptions).map(
									(key, i) => (
										<div key={String(header + '-' + i)} className="text-sm">
											-{' '}
											{
												analyticsScoreInfo.metricCalculationDescriptions[
													key
												].generalDescription
											}
										</div>
									)
								)}
						</li>
					)}
				</ul>
			</ModalBody>
		</>
	)
}
