import {
	useQuery,
	type UseQueryOptions,
	type QueryKey,
	type QueryClient,
	type QueryObserverOptions,
} from 'react-query'
import axios, { type $AxiosError } from 'axios'
import config from '../config'

type TeacherImpression = {|
	missionRating?: ?number,
	missionImprovementSuggestions?: ?string,
	technicalDifficulties?: ?string,
	generalFeedback?: ?string,
|}

type StudentImpression = {|
	studentId: string,
	missionRating: number,
|}

export type MissionImpressions = {
	missionId: string,
	teacher: ?TeacherImpression,
	students: StudentImpression[],
}

export function getImpressionQueryKey(missionId: string): QueryKey {
	return ['impressions', missionId]
}

/**
 * useImpression - get the impressions for a specific mission
 *
 * @param {string} missionId - the id of the mission to get the impressions for
 * @param {?ReactQueryOptions} options - any extra react-query options
 *
 * @return {{ impression: ?MissionImpressions, isLoading: boolean }}
 */
export function useImpression(
	missionId: string,
	options?: QueryObserverOptions<?MissionImpressions, $AxiosError<mixed>>
): { impression: ?MissionImpressions, isLoading: boolean } {
	const query = useQuery(
		({
			...options,
			queryKey: getImpressionQueryKey(missionId),
			queryFn: () =>
				axios
					.get(`${config.missionsAPIURL}/api/impressions/${missionId}`, {
						withCredentials: true,
					})
					.then(res => res.data?.impression),
		}: UseQueryOptions<?MissionImpressions, $AxiosError<mixed>, ?MissionImpressions>)
	)

	return { impression: query.data, isLoading: query.isLoading }
}

/**
 * respondToImpression - send teacher impression response to the server
 *
 * @param {string} missionId - the id of the mission impressions being sent
 * @param {number} missionRating - the rating the teacher gave
 * @param {string} feedback - any feedback about the mission
 * @param {QueryClient} queryClient - the react-query client
 *
 * @return {Promise<mixed>}
 */
export function respondToImpression(
	missionId: string,
	missionRating: number,
	feedback: string,
	queryClient: QueryClient
): Promise<mixed> {
	return axios
		.put(
			`${config.missionsAPIURL}/api/impressions/${missionId}`,
			{ missionRating, feedback },
			{
				withCredentials: true,
			}
		)
		.then(res => {
			queryClient.invalidateQueries(getImpressionQueryKey(missionId))
		})
}
