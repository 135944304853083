import React, { useState } from 'react'
import styled from 'styled-components/macro'
import FaTrash from 'react-icons/lib/io/trash-b'
import { ClockIcon } from '../../../assets/icons/miscellaneous'
import RemovePendingInviteModal from '../modals/RemovePendingInviteModal.js'
// $FlowFixMe ReactComponent is not defined in the module namespace
import { ReactComponent as EmptyInvitesImage } from '../../../assets/pageDefaults/EmptyInvites.svg'
import { IconCircleWrapper } from '../../../styles/sharedComponents'
import { Button } from '@mission.io/styles'
import { EmailInvitationModal } from '../modals/InviteTeacherModal'
import { screenSizes_dontUse } from '../../../styles/theme'

/**
 * Shows all the pending invites for a license.
 * If there are none, show a custom image.
 */
export default function PendingInvitesPage({
	pendingInvitations,
}: {
	pendingInvitations: string[],
}): React$Node {
	const [emailToRemove, setEmailToRemove] = useState(null)

	const [emailsToResend, setEmailsToResend] = useState(null)

	const confirmResend = (emails: string[]) => {
		setEmailsToResend(emails)
	}
	const closeResendConfirmation = () => {
		setEmailsToResend(null)
	}

	const sortedInvites = pendingInvitations?.sort((a, b) => a.localeCompare(b))

	return (
		<Wrapper>
			{sortedInvites.length === 0 ? (
				<div className="w-full text-center mt-8 text-2xl">
					<EmptyInvitesImage title="Empty Invites" className="mb-2 w-1/5 h-1/5" />
					<p>Looks like you don’t have any pending invites!</p>
					<p>Click Invite Teachers to get started.</p>
				</div>
			) : (
				<>
					<ButtonPositioner>
						<Button onClick={() => confirmResend(sortedInvites)} outline>
							Resend All Invites
						</Button>
					</ButtonPositioner>
					<Grid>
						{sortedInvites.map(email => (
							<div
								key={email}
								data-testid={`invite-${email}`}
								className="bg-primary-750 flex justify-between items-center p-2 mb-4 rounded-lg">
								<div css="flex: 1">
									<ClockIconStyles />
									{email}
								</div>
								<Button
									className="mr-2"
									onClick={() => {
										confirmResend([email])
									}}>
									Resend Invite
								</Button>
								<IconCircleWrapper>
									<FaTrash
										role={'button'}
										title={'Remove Invitation'}
										aria-label={'Remove Invitation'}
										onClick={() => {
											setEmailToRemove(email)
										}}
									/>
								</IconCircleWrapper>
							</div>
						))}
						{emailToRemove && (
							<RemovePendingInviteModal
								isOpen={true}
								email={emailToRemove}
								onRequestClose={() => setEmailToRemove(null)}
							/>
						)}
						{emailsToResend?.length && (
							<EmailInvitationModal
								isOpen={!!emailsToResend.length}
								onRequestClose={closeResendConfirmation}
								emails={emailsToResend}>
								{emailsToResend.length === 1 ? (
									<p>
										Are you sure you want to send another email to the address{' '}
										{emailsToResend[0]}? They should have already received an
										email.
									</p>
								) : (
									<>
										<p>
											These people should have already received an invitation
											email. Are you sure you want to send another email to
											these addresses?
										</p>
										<ul>
											{emailsToResend.map((email, index) => (
												<li key={index}>{email}</li>
											))}
										</ul>
									</>
								)}
							</EmailInvitationModal>
						)}
					</Grid>
				</>
			)}
		</Wrapper>
	)
}

const Wrapper = styled.div`
	width: 100%;
	height: 100%;
	${({ theme }) => `
		padding-right: var(--spacing3x-dont-use);
	`}
`

const Grid = styled.div`
	display: grid;
	grid-template-columns: 50% 1fr;
	${({ theme }) => `
	grid-column-gap: var(--spacing2x-dont-use);
	
	@media screen and (max-width: ${screenSizes_dontUse.mobileDevice}px) {
		grid-template-columns: 100%;
	}`}
`

const ClockIconStyles = styled(ClockIcon)`
	width: 2em;
	height: 2em;
	${({ theme }) => `
		margin-right: var(--spacing1x-dont-use);
	`}
`

const ButtonPositioner = styled.div`
	width: 100%;
	display: flex;
	justify-content: flex-end;
	margin: var(--spacing1x-dont-use);
`
