import React from 'react'
import styled from 'styled-components/macro'
import FaQuestion from 'react-icons/lib/fa/question'
import { Tooltip } from 'react-tooltip'
import { useInteractionLookups } from './UserGuide/interactions'
import { useUser } from '../services/hooks'

const RESTART_TOUR_TOOLTIP_ID = 'restart-tour-tooltip-id'

/**
 * A help menu on the app header. An icon that, when clicked, opens a popover menu which displays the following options:
 * Restart the Walkthrough.
 * @returns  {React$Node}
 */
export default function HelpMenu({ startTourNoUser }: { startTourNoUser: () => void }): React$Node {
	const lookups = useInteractionLookups()

	const { user } = useUser()

	return (
		// TODO: Support walkthrough on mobile/ipad, for now hide it.
		<div className="hidden lg:block fixed bottom-5 right-5 z-30">
			<ButtonNoStyle
				className="text-primary-50"
				data-walkthrough="help-menu"
				data-user-interaction-id={lookups.clickIds.RESTART_TOUR}
				onClick={() => {
					if (!user) startTourNoUser()
				}}
				data-tooltip-id={RESTART_TOUR_TOOLTIP_ID}>
				<FaQuestion
					aria-label="help"
					title="help"
					size={25}
					className="rounded-full border-2 pointer-events-none"
				/>
			</ButtonNoStyle>
			<Tooltip css="max-width: 400px;" id={RESTART_TOUR_TOOLTIP_ID} place="top-end">
				Run Walkthrough
			</Tooltip>
		</div>
	)
}

const ButtonNoStyle = styled.button`
	border: none;

	background-color: transparent;
	padding: 0;
	:focus {
		outline: none;
	}
	align-self: start;
	position: relative;
	display: flex;
	font-size: 20px;
`
