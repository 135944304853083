import {
	useQuery,
	useQueryClient,
	useMutation,
	type UseQueryResult,
	type UseMutationResult,
	type UseMutationOptions,
} from 'react-query'
import { useDispatch } from 'react-redux'
import { encodeMediaFiles } from '@mission.io/question-toolkit'
import config from '../../config'
import NetworkCommunicator from '../NetworkCommunicator'
import { addQuestionGroup, getSubjects } from '../../store/question'
import type { QuestionGroup, ReduxQuestionGroup } from '../../models'
import type { Category } from '../../store/categories'
import { useUser } from '.'
import { EXAMPLE_QUESTION_GROUPS } from '../../constants/exampleData'

const QUESTION_GROUP_KEY = 'questionGroup'

export const getQuestionGroupQuery = async (
	questionGroupId: string
): Promise<ReduxQuestionGroup> => {
	return NetworkCommunicator.GET(`/questionGroups/${questionGroupId}`, {
		host: `${config.missionsAPIURL}/api`,
	}).then(({ questionGroup }: { questionGroup: ReduxQuestionGroup }) => {
		return questionGroup
	})
}
/**
 * Get the react-query key for a question group with the given id
 */
function getQuestionGroupKey(questionGroupId: string, isUser: boolean) {
	return [QUESTION_GROUP_KEY, questionGroupId, isUser.toString()]
}

/**
 * useQuestionGroup - get the public question group search by id
 *
 * @returns the question group
 */
export function useQuestionGroup(questionGroupId: string): UseQueryResult<ReduxQuestionGroup, any> {
	const { user } = useUser()
	const response = useQuery(getQuestionGroupKey(questionGroupId, Boolean(user)), () => {
		if (!user) {
			const exampleQuestionGroup = EXAMPLE_QUESTION_GROUPS.find(
				gp => gp._id === questionGroupId
			)
			if (exampleQuestionGroup) return exampleQuestionGroup
		}
		return getQuestionGroupQuery(questionGroupId)
	})

	return response
}

/**
 *  Convert ReduxQuestionGroup to QuestionGroup
 * @param {ReduxQuestionGroup} questionGroup
 * @param {?IdMap<Category>} categoryLookup
 * @returns The question group
 */
export function convertQuestionGroup(
	questionGroup: ReduxQuestionGroup,
	categoryLookup: ?IdMap<Category>
): QuestionGroup {
	return {
		...questionGroup,
		subjects: getSubjects(categoryLookup, questionGroup?.subjects),
	}
}

/**
 * update a question group on the server.
 *
 * @param {UseMutationOptions} options options for the mutation
 * @returns UseMutationResult for updating the question group
 */
export function useUpdateQuestionGroup(
	options: ?UseMutationOptions<ReduxQuestionGroup, Error, ReduxQuestionGroup>
): UseMutationResult<ReduxQuestionGroup, Error, ReduxQuestionGroup> {
	const dispatch = useDispatch()
	const queryClient = useQueryClient()
	const { user } = useUser()

	return useMutation(
		async (questionGroup: ReduxQuestionGroup) => {
			// questionGroup must go before files in formData
			let formData = new FormData()
			formData.append('questionGroup', JSON.stringify(questionGroup))

			// Questions are valid: these are the expected flow issues:
			// 1. a new question could be missing an id,
			// 2. recently uploaded media has the extra fields 'localUrl' and 'file'.
			// $FlowFixMe[incompatible-call]
			encodeMediaFiles(questionGroup.questions, formData)

			const {
				questionGroup: updatedQuestionGroup,
			}: { questionGroup: ReduxQuestionGroup } = await NetworkCommunicator.PUT(
				`/questionGroups/${questionGroup._id}`,
				{
					host: `${config.missionsAPIURL}/api`,
					body: formData,
				}
			)
			return updatedQuestionGroup
		},
		{
			...options,
			onSuccess: (
				updatedQuestionGroup: ReduxQuestionGroup,
				...args: [ReduxQuestionGroup, mixed]
			) => {
				// update the question group in the redux store so it is listed
				// properly in the question group list
				dispatch(addQuestionGroup(updatedQuestionGroup))
				queryClient.setQueryData(
					getQuestionGroupKey(updatedQuestionGroup._id, Boolean(user)),
					updatedQuestionGroup
				)
				options?.onSuccess?.(updatedQuestionGroup, ...args)
			},
		}
	)
}
