import React, { Component, useEffect } from 'react'
import { Provider, useDispatch } from 'react-redux'
import { ThemeProvider } from 'styled-components'
import { BrowserRouter as Router } from 'react-router-dom'
import { QueryParamProvider } from 'use-query-params'
import { ReactRouter5Adapter } from 'use-query-params/adapters/react-router-5'
import Main from './Main'
import styled from 'styled-components'
import { ToastContainer, Slide } from 'react-toastify'
import store from './store'
import { getSimulations, getAutomatedSimulations } from './store/simulation'
import config from './config'
import { Button } from '@mission.io/styles'
import { captureException } from '@sentry/browser'
import useUser from './services/hooks/user'
import { getQuestions } from './store/question'
import { fetchCategories } from './store/categories'
import GlobalStyle from './styles/globalStyle'
// $FlowFixMe Flow doesn't know where ReactToastify css is
import 'react-toastify/dist/ReactToastify.min.css'
import { LightTheme } from './styles/theme'
import { QueryClient, QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import { FileViewerProvider } from './components/FileViewer/FileViewerContext'
import { FileBrowserProvider } from './components/FileBrowser/FileBrowserContext'
import { UserInteractionProvider } from './components/UserGuide/interactions'
import type { ThemeType } from '@mission.io/styles'

const queryClient = new QueryClient()

export default class App extends Component<{}, { error: ?Error }> {
	state: { error: ?Error } = {
		error: null,
	}

	componentDidCatch(error: Error, errorInfo: {}) {
		if (config.isProd) {
			captureException(error, { extra: errorInfo })
		}
		this.setState({ error })
	}
	componentDidMount() {
		store.dispatch(getSimulations())
		store.dispatch(getAutomatedSimulations())
	}

	render(): React$Node {
		return (
			<Provider store={store}>
				<QueryClientProvider client={queryClient}>
					<UserInteractionProvider>
						<ThemeProvider theme={LightTheme}>
							{this.state.error ? (
								<ErrorPage error={this.state.error} />
							) : (
								<LoadData>
									<Router>
										<QueryParamProvider adapter={ReactRouter5Adapter}>
											<FileViewerProvider>
												<FileBrowserProvider>
													<Main />
													<StyledToastContainer
														position="bottom-right"
														autoClose={5000}
														hideProgressBar
														newestOnTop
														closeOnClick
														rtl={false}
														transition={Slide}
														pauseOnVisibilityChange
														pauseOnHover
													/>
												</FileBrowserProvider>
											</FileViewerProvider>
										</QueryParamProvider>
									</Router>
								</LoadData>
							)}
							<ReactQueryDevtools initialIsOpen={false} />
							<GlobalStyle />
						</ThemeProvider>
					</UserInteractionProvider>
				</QueryClientProvider>
			</Provider>
		)
	}
}

/**
 * Identifies the current user's information in the live chat widget.
 * Some lackluster documentation for this feature can be found here https://legacydocs.hubspot.com/docs/methods/tracking_code_api/identify_visitor
 * A simple code example can be found here https://community.hubspot.com/t5/APIs-Integrations/How-to-identify-a-contact-in-the-chat/m-p/272830/highlight/true#M24773
 */
function useUpdateHubSpotInfo({
	firstName,
	lastName,
	email,
}: {
	firstName: ?string,
	lastName: ?string,
	email: ?string,
}): void {
	useEffect(() => {
		const userInfo = {}
		if (firstName) {
			userInfo.firstName = firstName
		}
		if (lastName) {
			userInfo.lastName = lastName
		}
		if (email) {
			userInfo.email = email
		}

		if (Object.keys(userInfo).length === 0) {
			return
		}

		function onConversationsAPIReady() {
			let _hsq = (window._hsq = window._hsq || [])
			_hsq.push(['identify', userInfo])
			_hsq.push(['trackPageView'])
			setTimeout(() => {
				window.HubSpotConversations.widget.refresh()
			}, 1000)
		}

		if (window.HubSpotConversations) {
			// The HubSpot API is ready
			onConversationsAPIReady()
		} else {
			// Call onConversationsAPIReady once it *becomes* ready
			window.hsConversationsOnReady = [onConversationsAPIReady]
		}
	}, [firstName, lastName, email])
}

/**
 * This function is necessary to ensure that we load data that depends on an existing user.
 * Eventually all of the extra data : categories, questions, missions, and leaderBoard analytics will be accessed
 * using react query as well, and this LoadData component will not be necessary.
 * @param {{children: React$Node}} props
 * @returns
 */
function LoadData(props: { children: React$Node }) {
	const { user } = useUser()
	const { firstName = null, lastName = null, email = null, _id: userId = null } = user || {}

	const dispatch = useDispatch()
	// Dispatch initial fetchers TODO: Eventually use react query for each of these values
	useEffect(() => {
		dispatch(fetchCategories())
		dispatch(
			getQuestions(
				{ owner: userId, includeOrganizationQuestionGroups: true },
				Boolean(userId)
			)
		)
	}, [userId, dispatch])

	useUpdateHubSpotInfo({
		firstName,
		lastName,
		email,
	})

	return props.children
}

/**
 * A page to show if there is an error during rendering of the app.
 */
function ErrorPage({ error }: { error: Error }): React$Node {
	return (
		<main css="display: flex; flex-direction: column; align-items: center; margin: 36px;">
			<h1 css="margin: 36px;">Oops! Something went wrong.</h1>
			<p css="font-size: 1.25rem;">
				To try again,{' '}
				<Button
					onClick={() => {
						window.location.reload()
					}}
					outline>
					refresh the page
				</Button>
				. If the problem persists, please reach out to {config.companyName.base} Support.
			</p>
			<p>
				Error Message: <code>{error.message}</code>
			</p>
		</main>
	)
}

// AUTOMATED TODO:
// * Make new mission type depend on the selected simulation
const StyledToastContainer = styled(ToastContainer)`
${({ theme }: { theme: ThemeType }) => `
	.Toastify__toast {
		color: ${theme.white};
		background-color: ${theme.primary600};
		padding: var(--spacing1x-dont-use);
		box-shadow: 0px 4px 6px -1 rgb(0 0 0 / 0.1); // simplified version of shadow-md from tailwind
		border-radius: 4px;
		border-left: 4px solid ${theme.info};
	}
	.Toastify__toast--error {
		border-left-color: ${theme.error};
	}
	.Toastify__toast--warning {
		border-left-color: ${theme.warning};
	}
	.Toastify__toast--success {
		border-left-color: ${theme.success};
	}
	.Toastify__close-button {
		color: ${theme.white};
	}
    `}
	}
	
`
