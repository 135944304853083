import { StyleSheet } from '@react-pdf/renderer'
import { LightTheme as theme } from '../styles/theme'
import { PROFICIENCY_COLORS } from '../routes/analytics/constants'

/**
 * Shared styles used by most components in the react pdf document
 */
export default (StyleSheet.create({
	p: {
		marginTop: '4px',
		marginBottom: '4px',
	},
	b: {
		fontWeight: 'bold',
	},
	italic: {
		fontStyle: 'italic',
	},
	row: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
	},
	pageH5: {
		marginTop: 0,
		marginBottom: '16px',
		fontWeight: 'bold',
	},
	h5: {
		marginTop: '16px',
		marginBottom: '16px',
		fontWeight: 'bold',
	},
	h6: {
		marginBottom: '8px',
		marginTop: '8px',
		fontWeight: 'bold',
	},
	h1: {
		fontSize: 14,
		padding: 10,
		borderBottom: `1pt solid ${theme.neutral}`,
	},
	table: {
		fontSize: 8,
	},
	tr: {
		display: 'flex',
		flexDirection: 'row',
	},
	td: {
		flex: 1,
		padding: '8px 0px',
		textAlign: 'center',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		borderRight: `1pt solid ${theme.white}`,
	},
	bordered: {
		border: `1pt solid ${theme.neutral}`,
	},
	grid: {
		display: 'flex',
		flexDirection: 'row',
		flexWrap: 'wrap',
		justifyContent: 'space-between',
		fontSize: '8pt',
	},
	flexColumn: {
		flex: 1,
		flexGrow: 1,
		flexShrink: 1,
		flexBasis: 0,
		marginRight: '8px',
		marginBottom: '8px',
	},
	gridColumn: {
		marginRight: '8px',
		marginBottom: '8px',
		width: '48%',
		boxSizing: 'border-box',
	},
	image: {
		marginTop: '8px',
		marginBottom: '8px',
		maxHeight: '120px',
		maxWidth: '120px',
	},
	questionTableCell: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'center',
		width: '100%',
	},
}): {
	[string]: {},
})

/**
 * Gets the color that represents the given proficiency level.
 * @param level The proficiency level to get the color for. In the range [1, 4].
 */
export function getColorForProficiencyLevel(level: number): string {
	if (level < 1 || level > 4) {
		throw new Error(`Invalid proficiency level: ${level}`)
	}
	return PROFICIENCY_COLORS[level - 1]
}

/**
 * Gets the text color that should be used when the background is the color of the given proficiency level.
 * @param level The proficiency level to get the text color for. In the range [1, 4].
 */
export function getTextColorForProficiencyLevelBackground(level: number | null): string {
	return '#fff'
}
