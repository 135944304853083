import { useQuery, useQueryClient, type UseQueryResult } from 'react-query'

import config from '../config'
import { NetworkCommunicator } from '../services'

import type { SimpleMission, Team } from '../models/Mission'
import type { PreppedMission } from '../store/types'
import { useUser } from '../services/hooks'

const FIELDS_REQUIRED =
	'_id simulationId crew code useJrPlus events owner questions missionCategory flightDirector teams'

/**
 * prepMissionToMissionsAPIMission - convert the given mission to an update or create object for missions-api
 *
 * @param {PreppedMission} mission - the mission to create or update on missions-api
 * @return {any}
 */
function prepMissionToMissionsAPIMission(
	mission: PreppedMission
): {
	classId: ?string,
	simulationId: string,
	juniorTeams: ?(Team[]),
	missionCategory: string,
	questions: { _id: ?string, version: ?number }[],
} {
	return {
		classId: mission.crew?.classId,
		simulationId: mission.simulationId,
		juniorTeams: mission.juniorTeams,
		missionCategory: mission.missionCategory,
		questions: mission.questions.map(question => ({
			_id: question._id,
			version: question.version,
		})),
	}
}

/**
 * useSaveMission - get a callback used to create a new mission
 *
 * @return (mission: PreppedMission) => Promise<string> - call with the mission fields to create a mission
 */
export function useSaveMission(): (mission: PreppedMission) => Promise<?string> {
	const updateUnfinishedMissions = useUpdateUnfinishedMissions()

	return (mission: PreppedMission) =>
		NetworkCommunicator.POST(`/api/missions`, {
			body: { mission: prepMissionToMissionsAPIMission(mission) },
			host: config.missionsAPIURL,
		})
			.then(({ mission }) => {
				updateUnfinishedMissions(oldData => ({
					...(oldData || ({}: IdMap<SimpleMission>)),
					[mission._id]: mission,
				}))
				return mission._id
			})
			.catch(e => {
				console.log(e)
				return undefined
			})
}

/**
 * useEditMission - get a callback used to edit a mission
 *
 * @return (id: string, mission: PreppedMission) => Promise<void> - call with the id of the mission to edit, and the editable mission fields
 */
export function useEditMission(): (id: string, mission: PreppedMission) => Promise<void> {
	const updateUnfinishedMissions = useUpdateUnfinishedMissions()

	return (id: string, mission: PreppedMission): Promise<void> =>
		NetworkCommunicator.PATCH(`/api/missions/${id}`, {
			body: { mission: prepMissionToMissionsAPIMission(mission), edit: true },
			host: config.missionsAPIURL,
		}).then(({ mission }) => {
			updateUnfinishedMissions(oldData => ({
				...(oldData || ({}: IdMap<SimpleMission>)),
				[mission._id]: mission,
			}))
		})
}

/**
 * useDeleteMission - get a callback used to delete a mission
 *
 * @return (id: string) => Promise<void> - delete the mission with the given id
 */
export function useDeleteMission(): (id: string) => Promise<void> {
	const updateUnfinishedMissions = useUpdateUnfinishedMissions()

	return (id: string): Promise<void> =>
		NetworkCommunicator.DELETE(`/api/missions/${id}`, {
			host: config.missionsAPIURL,
			retryUrl: `/api/missions/${id}?forceDelete=true`,
			retryConfirmText: 'Yes, Proceed',
		}).then(id => {
			updateUnfinishedMissions(oldData => {
				if (!oldData) {
					return {}
				}
				const newData = { ...oldData }
				delete newData[id]
				return newData
			})
		})
}

/**
 * useCompleteMission - get a callback used to mark an unfinished mission as complete
 *
 * @return (id: string) => Promise<void> - call with the mission Id to mark that mission as complete
 */
export function useCompleteMission(): (id: string) => Promise<void> {
	const updateUnfinishedMissions = useUpdateUnfinishedMissions()

	return (id: string): Promise<void> =>
		NetworkCommunicator.PATCH(`/api/missions/${id}`, {
			host: config.missionsAPIURL,
			retryUrl: `/api/missions/${id}?forceEnd=true`,
			retryConfirmText: 'Yes, Proceed',
			body: { end: true },
		}).then(() => {
			updateUnfinishedMissions(oldData => {
				if (!oldData) {
					return {}
				}
				const newData = { ...oldData }
				delete newData[id]
				return newData
			})
		})
}

const UNFINISHED_MISSION_QUERY_KEY = 'simple-unfinished-missions'

const getUnfinishedMissionQueryKey = (isUser: boolean) => {
	return [UNFINISHED_MISSION_QUERY_KEY, isUser.toString()]
}

/**
 * useUpdateUnfinishedMissions - a react hook used to get a callback to update the missions within react query
 *
 * @return ((oldData: ?IdMap<SimpleMission>) => IdMap<SimpleMission>) => void - a callback used to update the missions within react query
 */
function useUpdateUnfinishedMissions(): (
	(oldData: ?IdMap<SimpleMission>) => IdMap<SimpleMission>
) => void {
	const queryClient = useQueryClient()
	const { user } = useUser()

	return (updateFunction: (oldData: ?IdMap<SimpleMission>) => IdMap<SimpleMission>) =>
		queryClient.setQueryData(getUnfinishedMissionQueryKey(Boolean(user)), updateFunction)
}

/**
 * useUnfinishedMissions - a function used to get all the unfinished missions belonging to a user
 *
 * @returns UseQueryResult<IdMap<SimpleMission>, Error>  - the react query result object for getting the unfinished missions belonging to a user
 */
export function useUnfinishedMissions(): UseQueryResult<IdMap<SimpleMission>, Error> {
	const { user } = useUser()
	return useQuery(
		getUnfinishedMissionQueryKey(Boolean(user)),
		() => {
			if (!user) {
				return {}
			}
			return NetworkCommunicator.GET(
				`/api/missions?fields=${FIELDS_REQUIRED}&unfinished=true`,
				{
					host: config.missionsAPIURL,
				}
			)
				.then(missions => {
					let missionMap = {}
					missions.forEach(
						mission => (missionMap[mission._id] = { ...mission, simple: true })
					)
					return missionMap
				})
				.catch(error => {
					console.error(error)
					throw error
				})
		},
		{
			refetchOnMount: false,
			refetchOnWindowFocus: false,
			refetchOnReconnect: false,
			refetchInterval: false,
		}
	)
}

export type FinishedMission = {
	_id: string,
	simulationId: string,
}

const getFinishedMissionsQueryKey = (isUser: boolean) => {
	return ['finished-missions', isUser.toString()]
}

/**
 * useFinishedMissions - a function used to get all the finished missions belonging to the user
 */
export function useFinishedMissions(): UseQueryResult<IdMap<FinishedMission>, Error> {
	const { user } = useUser()
	return useQuery(
		getFinishedMissionsQueryKey(Boolean(user)),
		async () => {
			if (!user) return {}
			const queryParams = new URLSearchParams({
				fields: '_id simulationId',
				finished: 'true',
				limit: '2',
				owner: 'me',
			})
			const missions = await NetworkCommunicator.GET(
				`/api/missions?${queryParams.toString()}`,
				{
					host: config.missionsAPIURL,
				}
			)
			let missionMap = {}
			missions.forEach(mission => (missionMap[mission._id] = { ...mission, simple: true }))
			return missionMap
		},
		{
			staleTime: 1000 * 60 * 5,
		}
	)
}
