// @flow
import React, { useState, useEffect, useRef } from 'react'
import { Button } from '@mission.io/styles'
import type { Student_New, NewStudent as NewStudentType } from '../../models/Student'
import { usePrevious } from '../../utility/hooks'
import NewStudent from './NewStudent'

type StudentArray = $ReadOnlyArray<Student_New | NewStudentType>

/**
 * A component that allows editing a list of students. Allows updating student names, adding, and removing students. This is
 * a controlled component, meaning the parent must provide the student list and the update function for editing the students.
 * @param {StudentArray} students The list of students to edit
 * @param {SetSTate<StudentArray>} onChange The update function called with the new student list any time it is updated
 */
export default function MultiStudentEditor({
	students,
	onChange,
}: {
	students: StudentArray,
	onChange: SetState<StudentArray>,
}): React$Node {
	const [focusedRowIndex, setFocusedRowIndex] = useState<?number>(null)

	const previousStudentLength = usePrevious(students.length)
	const studentRows = useRef([])

	useEffect(() => {
		// If new student was added, focus the new row
		if (students.length === Number(previousStudentLength) + 1 && studentRows.current) {
			const lastRow = studentRows.current[students.length - 1]
			if (lastRow) {
				lastRow.focus()
			}
		}
	}, [students.length, previousStudentLength])

	function removeStudent(studentIndex: number) {
		onChange(students => students.filter((_, i) => i !== studentIndex))
	}

	function addStudent() {
		onChange(students => [...students, getEmptyStudent()])
	}

	return (
		<>
			<div className="grid sm:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4 gap-4">
				{students.map((student, i) => {
					return (
						<NewStudent
							key={i}
							index={i}
							student={student}
							onChange={student => {
								onChange(students => {
									const studentsCopy = [...students]
									studentsCopy[i] = student
									return studentsCopy
								})
							}}
							onFocus={() => {
								setFocusedRowIndex(i)
							}}
							onPressEnter={e => {
								if (typeof focusedRowIndex === 'number' && studentRows.current) {
									const nextRowIndex: number =
										focusedRowIndex + (e.shiftKey ? -1 : 1)
									const nextRow = studentRows.current[nextRowIndex]
									if (nextRow) {
										nextRow.focus()
									} else if (nextRowIndex === students.length) {
										// We are focused on the last row
										addStudent()
									}
								}
							}}
							onPressTabFromLastInput={e => {
								if (!e.shiftKey && focusedRowIndex === students.length - 1) {
									e.preventDefault() // we already advance focus to the added student's first name when a student is added, so we do not advance the focus here
									addStudent()
								}
							}}
							removeStudent={() => removeStudent(i)}
							firstNameRef={ref => {
								if (studentRows.current) {
									studentRows.current[i] = ref
								}
							}}
						/>
					)
				})}
			</div>
			<Button variant="secondary" type="button" onClick={addStudent} className="text-xs mt-2">
				Add Student
			</Button>
		</>
	)
}

/**
 * A non controlled version of the `MultiStudentEditor`. Allows passing a render function as the `children` prop
 * which will be provided with the current list of students.
 */
export function ManagedMultiStudentEditor({
	children,
	initialStudents,
}: {
	children: (students: StudentArray) => React$Node,
	initialStudents?: StudentArray,
}): React$Node {
	const [students, setStudents] = useState<StudentArray>(initialStudents || getNewStudentArray())

	return (
		<>
			<MultiStudentEditor students={students} onChange={setStudents} />
			{children(students)}
		</>
	)
}

/**
 * Gets an empty student object.
 */
function getEmptyStudent(): NewStudentType {
	return {
		firstName: '',
		lastName: '',
		linkedAccounts: [],
	}
}

const DEFAULT_STUDENT_COUNT = 1

/**
 * Gets an array of new students. Can be used to create an initial students array
 * to pass into MultiStudentEditor.
 */
export function getNewStudentArray(): Array<NewStudentType> {
	return new Array(DEFAULT_STUDENT_COUNT).fill(getEmptyStudent())
}
