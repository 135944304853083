import type { ViewOptionType } from './constants'
import type {
	LeaderBoardAnalytics,
	ViewBoxItem,
	ClassTotalAnalytics,
	StudentTotalAnalytics,
	SchoolAnalyticsSummary,
	ComparisonScore,
} from './types'
import {
	TOP_SCORES,
	MOST_IMPROVED,
	HIGHEST_USAGE,
	LOCAL,
	TOP_SCHOOLS,
	TOP_DISTRICTS,
	DEATHS,
	PROBLEMS_SOLVED,
	TEACHER,
	SCHOOL_ADMIN,
	DISTRICT_ADMIN,
	analyticsViewOptions,
} from './constants'

const NEGATIVE_INF = Number.NEGATIVE_INFINITY
const MILLISECONDS_IN_A_SECOND = 1000
const SECONDS_IN_A_MINUTE = 60
const MINUTES_IN_AN_HOUR = 60
const MILLISECONDS_IN_AN_HOUR = MILLISECONDS_IN_A_SECOND * SECONDS_IN_A_MINUTE * MINUTES_IN_AN_HOUR
export const DECIMAL_TO_PERCENT = 100

/**
 * There are up to 6 boxes to show a user, but each box has different values for each of the 3 user types.
 * This switch statement goes through each box type, and return the correct value based on each user's type.
 * If able, we also sort and map each result to get the correct rankings, and the correct return object.
 * Luckily, the code for showing the leaderBoard is the same for all user types.
 */
export function getAnalyticsDataByViewId(
	leaderBoardAnalytics: ?LeaderBoardAnalytics,
	id: string,
	studentIdsToNames: { [string]: string } = {},
	category: ?string
): $ReadOnlyArray<ViewBoxItem> {
	if (!leaderBoardAnalytics) {
		return []
	}
	switch (id) {
		case TOP_SCORES: {
			if (leaderBoardAnalytics.type === TEACHER) {
				if (!leaderBoardAnalytics.results.classOfRequestingTeacher) {
					return []
				}
				return sortTopScoresForTeacherView(
					leaderBoardAnalytics.results.classOfRequestingTeacher.studentResults,
					studentIdsToNames
				)
			} else if (leaderBoardAnalytics.type === SCHOOL_ADMIN) {
				return sortTopScoresForSchoolAdminView(leaderBoardAnalytics.results.classes)
			} else if (leaderBoardAnalytics.type === DISTRICT_ADMIN) {
				if (leaderBoardAnalytics.results.classes) {
					return sortTopScoresForSchoolAdminView(leaderBoardAnalytics.results.classes)
				}
				return sortTopScoresForDistrictView(leaderBoardAnalytics.results.schools)
			}
			return []
		}
		case MOST_IMPROVED: {
			if (leaderBoardAnalytics.type === TEACHER) {
				if (!leaderBoardAnalytics.results.classOfRequestingTeacher) {
					return []
				}
				return sortMostImprovedForTeacherView(
					leaderBoardAnalytics.results.classOfRequestingTeacher.studentResults,
					studentIdsToNames
				)
			} else if (leaderBoardAnalytics.type === SCHOOL_ADMIN) {
				return sortMostImprovedForSchoolAdminView(leaderBoardAnalytics.results.classes)
			} else if (leaderBoardAnalytics.type === DISTRICT_ADMIN) {
				if (leaderBoardAnalytics.results.classes) {
					return sortMostImprovedForSchoolAdminView(leaderBoardAnalytics.results.classes)
				}
				return sortMostImprovedForDistrictView(leaderBoardAnalytics.results.schools)
			}
			return []
		}
		case HIGHEST_USAGE: {
			if (leaderBoardAnalytics.type === SCHOOL_ADMIN) {
				return sortUsageForSchoolAdminView(leaderBoardAnalytics.results.classes)
			} else if (leaderBoardAnalytics.type === DISTRICT_ADMIN) {
				if (leaderBoardAnalytics.results.classes) {
					return sortUsageForSchoolAdminView(leaderBoardAnalytics.results.classes)
				}
				return sortUsageForDistrictView(leaderBoardAnalytics.results.schools)
			}
			return []
		}
		case LOCAL: {
			if (leaderBoardAnalytics.type === TEACHER) {
				return sortLocalScoresForTeacherView(leaderBoardAnalytics.results.comparisonScores)
			} else if (leaderBoardAnalytics.type === SCHOOL_ADMIN) {
				return sortLocalScoresForSchoolAdminView(leaderBoardAnalytics.results.classes)
			} else if (leaderBoardAnalytics.type === DISTRICT_ADMIN) {
				if (leaderBoardAnalytics.results.classes) {
					return sortLocalScoresForSchoolAdminView(leaderBoardAnalytics.results.classes)
				}
				return sortLocalScoresForDistrictView(leaderBoardAnalytics.results.schools)
			}
			return []
		}
		case TOP_SCHOOLS: {
			if (!category || !leaderBoardAnalytics.leaderboard.categories[category]) {
				return []
			}
			return leaderBoardAnalytics.leaderboard.categories[category].schoolsLeaderBoard.scores
		}
		case TOP_DISTRICTS: {
			if (!category || !leaderBoardAnalytics.leaderboard.categories[category]) {
				return []
			}
			return leaderBoardAnalytics.leaderboard.categories[category].districtsLeaderBoard.scores
		}
		default: {
			return []
		}
	}
}

/**
 * getExplanationByViewId - Get the explanation of an analytic view
 *
 * @param {?LeaderBoardAnalytics} leaderBoardAnalytics - the analytics from redux for the leaderboard page
 * @param {string} id - the id of the view
 *
 * @return string - the explanation, an empty string if an explanation could not be found
 */
export function getExplanationByViewId(
	leaderBoardAnalytics: ?LeaderBoardAnalytics,
	id: ViewOptionType,
	category: ?string
): string {
	if (!leaderBoardAnalytics) {
		return ''
	}
	switch (id) {
		case TOP_SCHOOLS: {
			if (!category || !leaderBoardAnalytics.leaderboard.categories[category]) {
				return `unknown leaderboard category "${String(category)}"`
			}
			return leaderBoardAnalytics.leaderboard.categories[category].schoolsLeaderBoard
				.explanation
		}
		case TOP_DISTRICTS: {
			if (!category || !leaderBoardAnalytics.leaderboard.categories[category]) {
				return `unknown leaderboard category "${String(category)}"`
			}
			return leaderBoardAnalytics.leaderboard.categories[category].districtsLeaderBoard
				.explanation
		}
		default: {
			return analyticsViewOptions[id]?.info || ''
		}
	}
}

/**
 * Similar to getAnalyticsDataByViewId, The variables for DEATHS and PROBLEMS_SOLVED differ on location
 * depending on user type, we just check the user type and return the correct variable.
 */
export function getAnalyticsByFunFactId(
	leaderBoardAnalytics: ?LeaderBoardAnalytics,
	id: string
): ?number {
	if (!leaderBoardAnalytics) {
		return null
	}
	switch (id) {
		case DEATHS: {
			if (leaderBoardAnalytics.type === TEACHER) {
				if (!leaderBoardAnalytics.results.classOfRequestingTeacher) {
					return null
				}
				return leaderBoardAnalytics.results.classOfRequestingTeacher.deaths
			} else {
				return leaderBoardAnalytics.results.deaths
			}
		}
		case PROBLEMS_SOLVED: {
			if (leaderBoardAnalytics.type === TEACHER) {
				if (!leaderBoardAnalytics.results.classOfRequestingTeacher) {
					return null
				}
				return leaderBoardAnalytics.results.classOfRequestingTeacher.problemsSolved
			} else {
				return leaderBoardAnalytics.results.problemsSolved
			}
		}
		default: {
			return null
		}
	}
}

/**
 * Depending on the box type, we treat the score value differently.
 * If no score, we return NA, but if the box is of overall scores, then we
 * want the percent instead of decimal values.  If out box is of time, then
 * want to add hrs to the value for clarity.
 * All variable are toFixed() to remove long decimals.
 */
export function getValueById(score: ?number, id: ViewOptionType): string {
	if (!score && score !== 0) {
		return 'NA'
	}
	let returnScore = score
	if (id === TOP_SCORES || id === MOST_IMPROVED) {
		returnScore = returnScore * DECIMAL_TO_PERCENT
	}
	let returnScoreString = Math.floor(returnScore).toLocaleString()
	if (id === HIGHEST_USAGE) {
		returnScoreString = String(returnScoreString) + ' hrs'
	}

	return returnScoreString
}

function sortTopScoresForTeacherView(
	studentResults: StudentTotalAnalytics[],
	studentIdsToNames: { [string]: string }
): ViewBoxItem[] {
	return studentResults
		.sort(
			(a, b) =>
				(b.averageStudentSELScore ?? NEGATIVE_INF) -
				(a.averageStudentSELScore ?? NEGATIVE_INF)
		)
		.map(studentResult => {
			return {
				name: studentIdsToNames[studentResult.studentId] || 'Cannot find student name',
				score: studentResult.averageStudentSELScore,
			}
		})
}

function sortTopScoresForSchoolAdminView(classResults: ClassTotalAnalytics[]): ViewBoxItem[] {
	return classResults
		.sort(
			(a, b) =>
				(b.averageClassSELScore ?? NEGATIVE_INF) - (a.averageClassSELScore ?? NEGATIVE_INF)
		)
		.map(classResult => {
			return {
				name: classResult.teacherName || 'Cannot find teacher name',
				score: classResult.averageClassSELScore,
			}
		})
}

function sortTopScoresForDistrictView(schoolResults: SchoolAnalyticsSummary[]): ViewBoxItem[] {
	return schoolResults
		.sort(
			(a, b) =>
				(b.averageSchoolSELScore ?? NEGATIVE_INF) -
				(a.averageSchoolSELScore ?? NEGATIVE_INF)
		)
		.map(schoolResult => {
			return {
				name: schoolResult.schoolName,
				score: schoolResult.averageSchoolSELScore,
			}
		})
}

function sortMostImprovedForTeacherView(
	studentResults: StudentTotalAnalytics[],
	studentIdsToNames: { [string]: string }
): ViewBoxItem[] {
	return studentResults
		.sort(
			(a, b) =>
				(b.averageStudentSELChange ?? NEGATIVE_INF) -
				(a.averageStudentSELChange ?? NEGATIVE_INF)
		)
		.map(studentResult => {
			return {
				name: studentIdsToNames[studentResult.studentId] || 'Cannot find student name',
				score: studentResult.averageStudentSELChange,
			}
		})
}

function sortMostImprovedForSchoolAdminView(classResults: ClassTotalAnalytics[]): ViewBoxItem[] {
	return classResults
		.sort(
			(a, b) =>
				(b.averageClassSELChange ?? NEGATIVE_INF) -
				(a.averageClassSELChange ?? NEGATIVE_INF)
		)
		.map(classResult => {
			return {
				name: classResult.teacherName || 'Cannot find teacher name',
				score: classResult.averageClassSELChange,
			}
		})
}

function sortMostImprovedForDistrictView(schoolResults: SchoolAnalyticsSummary[]): ViewBoxItem[] {
	return schoolResults
		.sort(
			(a, b) =>
				(b.averageSchoolSELChange ?? NEGATIVE_INF) -
				(a.averageSchoolSELChange ?? NEGATIVE_INF)
		)
		.map(schoolResult => {
			return {
				name: schoolResult.schoolName,
				score: schoolResult.averageSchoolSELChange,
			}
		})
}

function sortUsageForSchoolAdminView(classResults: ClassTotalAnalytics[]): ViewBoxItem[] {
	return classResults
		.sort((a, b) => b.time - a.time)
		.map(classResult => {
			return {
				name: classResult.teacherName || 'Cannot find teacher name',
				score: classResult.time / MILLISECONDS_IN_AN_HOUR,
			}
		})
}

function sortUsageForDistrictView(schoolResults: SchoolAnalyticsSummary[]): ViewBoxItem[] {
	return schoolResults
		.sort((a, b) => b.time - a.time)
		.map(schoolResult => {
			return {
				name: schoolResult.schoolName,
				score: schoolResult.time / MILLISECONDS_IN_AN_HOUR,
			}
		})
}

function sortLocalScoresForTeacherView(studentResults: ComparisonScore[]): ViewBoxItem[] {
	return studentResults
		.sort((a, b) => b.score - a.score)
		.map(classResult => {
			return {
				name: classResult.teacherName,
				score: classResult.score,
			}
		})
}

function sortLocalScoresForSchoolAdminView(classResults: ClassTotalAnalytics[]): ViewBoxItem[] {
	return classResults
		.sort((a, b) => b.score - a.score)
		.map(classResult => {
			return {
				name: classResult.teacherName || 'Cannot find teacher name',
				score: classResult.score,
			}
		})
}

function sortLocalScoresForDistrictView(schoolResults: SchoolAnalyticsSummary[]): ViewBoxItem[] {
	return schoolResults
		.sort((a, b) => b.score - a.score)
		.map(schoolResult => {
			return {
				name: schoolResult.schoolName,
				score: schoolResult.score,
			}
		})
}
