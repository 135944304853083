// @flow
import React, { useRef, useState, useEffect } from 'react'
import 'styled-components/macro'
import { Tooltip } from 'react-tooltip'
import FaInfo from 'react-icons/lib/fa/info-circle'
import FaArrowLeft from 'react-icons/lib/fa/arrow-left'

import { toast } from 'react-toastify'
import CustomModal, { ExitX, ModalHeader, ModalBody } from '../../components/modal/Modal'
import { Button } from '@mission.io/styles'
import {
	sortClassGrades,
	classNameIsValid,
	classGradesAreValid,
	getClassValidationErrorMessage,
	getNonEmptyStudents,
} from './helpers'
import { classGradeOptions } from './constants'
import LoadCanvasCourse, {
	type OnSelectArg as LoadCanvasCourseOnSelectArg,
} from './LoadCanvasCourse'
import UploadClassCsv from './UploadClassCSV'
import { useUserClasses, type UpdateClass } from '../../services/hooks/classes'
import { useUserHasNoSchool, useIsDistrictAdmin } from '../../services/hooks/user'
import { SimpleConfirmation, ACTIONABLE_TOAST_PROPERTIES } from '../../components'
import SchoolPicker from './SchoolPicker'
import { IconCircleWrapper } from '../../styles/sharedComponents'
import thirdPartyIcons from '../../assets/thirdPartyIcons'
import { ManagedMultiStudentEditor } from './MultiStudentEditor'
import { useCreateClass } from './../../services/hooks/classes'
import { usePanelStack, type PartialPanel, useQueryNotifications } from '../../utility/hooks'
import config from '../../config'

import type { ClassType } from '../../models/ClassType'
import type { NewStudent } from '../../models/Student'
import type { GradeOption, School } from './types'
import { useUser } from '../../services/hooks'
import { Input } from '../../components/inputs/Input'
import { Select } from '../../components/inputs/Select'

type ImportType = 'manual' | 'google' | 'csv' | 'canvas'

/**
 * A button to select an import method and display a logo.
 * @param {React$Node} children The text for the button
 * @param {string} src The logo to use
 * @param {string} alt The alt attribute for the logo
 */
function ButtonWithLogo({
	children,
	src,
	alt,
}: {
	children: React$Node,
	alt: string,
	src: string,
}) {
	const widthAndHeight = 'width: 20px; height: 20px;'

	return (
		<div css="display: flex; justify-content: center; align-items: center;">
			<img
				src={src}
				alt={alt}
				aria-hidden
				css={`
					${widthAndHeight} margin-right: var(--spacing2x-dont-use);
				`}
			/>
			{children}
			<span
				css={`
					${widthAndHeight} margin-left: var(--spacing2x-dont-use);
				`}
			/>
		</div>
	)
}

/**
 * A modal that walks the user through creating a class. Saves the created class to the server. After the
 * class is saved on the server, the `onCreateClass` callback is called with the newly created class.
 */
export default function AddClassModal({
	cancelNewClassCreation,
	onCreateClass,
	isOpen,
}: {
	cancelNewClassCreation: boolean => void,
	onCreateClass: ClassType => void,
	isOpen: boolean,
}): React$Node {
	const students = useRef<Array<NewStudent>>([])
	const canvasCourseId = useRef(null)
	const isConfirmImportToastOpen = useRef(false)

	const createClassMutation = useCreateClass()

	const classes = useUserClasses()?.data ?? []

	const { panelStack, goBack, add, goToStart } = usePanelStack({
		component: ClassImportTypeSelect,
		props: {
			onSelect: (type: ImportType, userClasses?: Array<ClassType>) => {
				/**
				 * Gets the "Class Details" panel for adding class details. If there are no students, the panel progresses
				 * to the student editor screen.
				 */
				function getClassDetailsPanel(extraProps?: {| className: string |}): PartialPanel {
					const needsStudents = students.current.length === 0
					return {
						component: ClassDetailsInputs,
						props: {
							// If we need students, make the next panel be a student editor
							buttonText: needsStudents ? 'Add Students' : 'Create Class',
							onContinue: !needsStudents
								? createClass
								: continueArgs => {
										// Render function to pass to `ManagedMultiStudentEditor` to display a "Create Class" button
										const createClassRender = enteredStudents => {
											students.current = enteredStudents
											return (
												<div className="flex justify-end">
													<Button
														onClick={() => {
															createClass({
																...continueArgs,
																userClasses,
															})
														}}>
														Create Class
													</Button>
												</div>
											)
										}
										const props = {}
										props.children = createClassRender
										if (students.current.length > 0) {
											props.initialStudents = students.current
										}
										add({
											component: ManagedMultiStudentEditor,
											props: props,
										})
								  },
							...extraProps,
						},
					}
				}
				if (type === 'manual') {
					add(getClassDetailsPanel())
				} else if (type === 'csv') {
					add({
						component: UploadClassCsv,
						props: {
							onSave: csvStudents => {
								students.current = csvStudents
								add(getClassDetailsPanel())
							},
						},
					})
				} else if (type === 'canvas') {
					add({
						component: LoadCanvasCourse,
						props: {
							onSelect: ({
								className,
								canvasCourseId: selectedCourseId,
								students: canvasStudents,
							}: LoadCanvasCourseOnSelectArg) => {
								students.current = canvasStudents.map(
									({ canvasId, ...student }) => ({
										...student,
										linkedAccounts: [{ provider: 'canvas', id: canvasId }],
									})
								)
								canvasCourseId.current = selectedCourseId
								add(getClassDetailsPanel({ className }))
							},
						},
					})
				} else {
					throw new Error('Invalid class import type selected')
				}
			},
		},
	})

	useEffect(() => {
		if (!isOpen) {
			// restart the class import process once the modal closes
			goToStart()
		}
	}, [isOpen, goToStart])

	// Verifies that the class to be created is valid, and then creates the class on the server.
	const createClass = ({
		className,
		grades: classGrades,
		demoSchool,
		userClasses,
	}: OnCreateClassArg) => {
		const classesToUse = userClasses ?? classes

		const newClass: UpdateClass = (function() {
			const classData: UpdateClass = {
				name: className,
				students: getNonEmptyStudents(students.current),
				grades: classGrades.map(gradeObject => gradeObject.value),
				externalReferences: [],
			}
			if (canvasCourseId.current) {
				classData.externalReferences.push({
					provider: 'canvas',
					id: canvasCourseId.current,
				})
			}
			if (demoSchool) {
				classData.demoSchoolId = demoSchool.schoolId
			}

			return classData
		})()

		const validationErrorMessage = getClassValidationErrorMessage(newClass)

		if (validationErrorMessage) {
			toast.warn(validationErrorMessage)
			return
		}

		function _createClass() {
			createClassMutation.mutate(newClass, {
				onSuccess: (_class: ClassType) => {
					onCreateClass(_class)
					toast.success('Class saved!')
				},
				onError: err => {
					let errorMessage =
						err.status === 400 ? err.message : 'Our bad! Error saving class'
					toast.error(errorMessage)
				},
			})
		}

		// Function to handle warnings during the creation of a class
		function processClassCreationWarning(message: string, confirmText: string) {
			isConfirmImportToastOpen.current = true
			const toastId = toast.warning(
				<SimpleConfirmation
					message={message}
					confirmText={confirmText}
					onConfirm={() => {
						toast.dismiss(toastId)
						isConfirmImportToastOpen.current = false
						_createClass()
					}}
					onCancel={() => {
						toast.dismiss(toastId)
						isConfirmImportToastOpen.current = false
					}}
				/>,
				{
					...ACTIONABLE_TOAST_PROPERTIES,
					position: toast.POSITION.BOTTOM_CENTER,
				}
			)
		}

		/**
		 * Whether the given `provider` + `id` combo is already used on any existing classes
		 */
		function providerIdIsUsed(provider: string, id: ?string): boolean {
			if (!id) {
				return false
			}
			return classesToUse.some(_class =>
				_class.externalReferences.some(
					externalReference =>
						externalReference.provider === provider && externalReference.id === id
				)
			)
		}
		if (providerIdIsUsed('canvas', canvasCourseId.current)) {
			if (isConfirmImportToastOpen.current) {
				return
			}
			processClassCreationWarning(
				`You have already imported this class from Canvas. If you import this class again, the student data will not be shared between the two versions of the class. We recommend modifying the existing class instead of re-importing.`,
				'Import class again'
			)
		} else if (classesToUse.some(_class => _class.name === className)) {
			if (isConfirmImportToastOpen.current) {
				return
			}
			processClassCreationWarning(
				`You already have a class with the name "${className}". Are you sure you want a class with a duplicate name?`,
				'Create class'
			)
		} else {
			_createClass()
		}
	}

	const currentPanel = panelStack[panelStack.length - 1]

	const isSelectingImportType = currentPanel.component === ClassImportTypeSelect

	/**
	 * Reset gathered data if the user goes back to the ClassImportType panel
	 */
	useEffect(() => {
		if (isSelectingImportType) {
			students.current = []
			canvasCourseId.current = null
		}
	}, [isSelectingImportType])

	useQueryNotifications('linkError', 'requestClassroomError')

	return (
		<>
			<CustomModal
				isOpen={isOpen}
				className="modal w-50"
				aria={{ labelledby: 'create-class-modal-header' }}>
				<ModalHeader id="create-class-modal-header">
					<div className="flex items-center">
						{panelStack.length > 1 ? (
							<IconCircleWrapper className="mr-4" onClick={() => goBack()}>
								<FaArrowLeft />
							</IconCircleWrapper>
						) : (
							<span css="height: 32px;" />
						)}
						New Class
					</div>
					<ExitX
						onClick={() => cancelNewClassCreation(false)}
						data-walkthrough="leave-class-creation"
					/>
				</ModalHeader>

				<ModalBody css="display: flex; flex-direction: column;">
					{panelStack.map((panel, i) => {
						const style = {}
						if (i !== panelStack.length - 1) {
							style.display = 'none'
						}
						return (
							<div key={panel.id} style={style}>
								{panel.title && (
									<h5 css="display: flex; align-items: center; justify-content: space-between;">
										{panel.title}
									</h5>
								)}
								<panel.component {...panel.props} addPanelToStack={add} />
							</div>
						)
					})}
				</ModalBody>
			</CustomModal>
		</>
	)
}

/**
 * A component that lets the user select how they want to import the class.
 */
function ClassImportTypeSelect({
	onSelect,
}: {
	onSelect: (type: ImportType, userClasses?: Array<ClassType>) => mixed,
}) {
	const IMPORT_TYPES: Array<{
		key: ImportType,
		buttonContent: React$Node,
		extraProps?: {| as: 'a', href: string |},
	}> = [
		{ key: 'manual', buttonContent: 'Manual' },
		{ key: 'csv', buttonContent: 'Upload CSV' },
		{
			key: 'google',
			buttonContent: (
				<ButtonWithLogo src={thirdPartyIcons.google} alt="Google Classroom Logo">
					Google Classroom
				</ButtonWithLogo>
			),
			extraProps: {
				as: 'a',
				href:
					config.loginServer +
					'/auth/google?link=true&requestClassroom=true&sendto=' +
					window.location.href,
			},
		},
		{
			key: 'canvas',
			buttonContent: (
				<ButtonWithLogo src={thirdPartyIcons.canvas} alt="Canvas Logo">
					Canvas
				</ButtonWithLogo>
			),
		},
	]

	const classes = useUserClasses()?.data ?? []

	return (
		<>
			<p className="text-m mb-2">How would you like to enter your class info?</p>
			<div className="grid grid-cols-2 gap-2">
				{IMPORT_TYPES.map(({ key, buttonContent, extraProps }) => {
					const button = (
						<Button
							key={key}
							className="w-full"
							onClick={
								// if it's not a button element, don't call onSelect
								extraProps?.as === 'a' ? null : () => onSelect(key, classes)
							}
							outline>
							{buttonContent}
						</Button>
					)
					if (extraProps?.as === 'a') {
						return (
							<a key={key} href={extraProps.href}>
								{button}
							</a>
						)
					}
					return button
				})}
			</div>
		</>
	)
}

type OnCreateClassArg = {
	className: string,
	grades: GradeOption[],
	demoSchool: ?School,
	userClasses?: Array<ClassType>,
}

/**
 * Input fields for class details. Includes inputs for class name, grades, and a school selector when required.
 * Also includes the final create class button.
 */
function ClassDetailsInputs({
	onContinue,
	buttonText,
	className: initialClassName,
}: {
	className?: string,
	onContinue: OnCreateClassArg => mixed,
	buttonText: string,
}) {
	const [className, setClassName] = useState(initialClassName || '')
	const [classGrades, setClassGrades] = useState([])
	const [demoSchool, setDemoSchool] = useState()
	const { user } = useUser()

	const userNeedsSchool = useUserHasNoSchool()
	const userIsDistrictAdmin = useIsDistrictAdmin()

	const requireDemoSchool = userNeedsSchool && userIsDistrictAdmin
	return (
		<>
			<div className="grid grid-cols-[2fr_1fr] gap-4">
				<div className="flex flex-col justify-end">
					<label className="text-sm mb-1" htmlFor="className">
						Class Name
					</label>
					<Input
						id="className"
						required
						placeholder="Class Name"
						type="text"
						value={className}
						onChange={e => {
							setClassName(e.currentTarget.value)
						}}
					/>
				</div>
				<div>
					<label className="text-sm mb-1" htmlFor="classGrade">
						Grade
					</label>
					<Select
						inputId="classGrade"
						placeholder={''}
						isMulti
						styles={{
							menuPortal: base => ({ ...base, zIndex: 9999 }),
						}}
						value={classGrades}
						onChange={(grades: GradeOption[]) => {
							const sortedGrades = sortClassGrades(grades)
							setClassGrades(sortedGrades)
						}}
						options={classGradeOptions}
						menuPortalTarget={document.body}
						menuPosition="fixed"
					/>
				</div>
				{requireDemoSchool && (
					<div>
						<label className="text-sm mb-1">School</label>
						<div css="display: flex; align-items: center;">
							<div css="flex: 1;">
								<SchoolPicker
									value={demoSchool}
									onChange={setDemoSchool}
									placeholder="Type your school's name"
									districtId={user?.districtId}
								/>
							</div>
							<FaInfo
								size={20}
								css="margin-left: 5px;"
								data-tooltip-id="info"
								data-tooltip-content={
									'Since you do not belong to a specific school, you must select a school for your demo class to belong to.'
								}
							/>
							<Tooltip id="info" />
						</div>
					</div>
				)}
			</div>
			<div className="flex justify-end mt-2">
				<Button
					onClick={() => {
						const errorMessage = (() => {
							if (!classNameIsValid(className)) {
								return 'Please add a class name.'
							}
							if (!classGradesAreValid(classGrades)) {
								return 'Please select a grade for your class.'
							}
							if (requireDemoSchool && !demoSchool) {
								return 'Please select a school for your demo class'
							}
						})()

						if (errorMessage) {
							toast.warn(errorMessage)
							return
						}

						onContinue({ className, grades: classGrades, demoSchool })
					}}>
					{buttonText}
				</Button>
			</div>
		</>
	)
}
