import React, { useState, useRef } from 'react'
import useInterval from '@use-it/interval'
import type { TooltipRenderProps } from 'react-joyride'
import { Button } from '@mission.io/styles'
import classnames from 'classnames'

const INTERVAL_TIME = 2000
const SEEN_ENOUGH = 30000

/**
 * A modal to override the react-joyride tooltip component. Used when showing the hype video.
 */
export default function HypeVideoModal({
	continuous,
	step,
	closeProps,
	primaryProps,
	backProps,
	tooltipProps,
}: {
	...TooltipRenderProps,
}): React$Node {
	const [disableNext, setDisableNext] = useState(true)
	const videoRef = useRef(null)

	// TODO this needs to be tested when we have a video. We may be able to use `onProgress` on the video itself.
	useInterval(() => {
		if (videoRef.current) {
			if (videoRef.current?.currentTime * 1000 > SEEN_ENOUGH) setDisableNext(false)
			if (videoRef.current && videoRef.current.currentTime === 0) videoRef.current.play()
		}
	}, INTERVAL_TIME)

	return (
		<div
			{...tooltipProps}
			className={classnames(
				// $FlowIgnore[prop-missing] tooltipProps is not well documented, so it's unclear if this object will include className. Either way, we want to merge the two classes.
				tooltipProps.className,
				'bg-primary-50 p-4 rounded-lg max-h-[70vh] overflow-y-auto'
			)}>
			<video
				ref={videoRef}
				src="https://resources-cdn.mission.io/public/videos/dashboard_hype_video.mp4"
				controls
				autoPlay
				className="w-[50vw]"
			/>
			{!step.hideFooter && (
				<div className="mt-2 flex justify-between">
					{continuous && (
						<>
							<Button {...backProps}>{backProps.title}</Button>
							<Button {...primaryProps} disabled={disableNext}>
								{primaryProps.title}
							</Button>
						</>
					)}
					{!continuous && <Button {...closeProps}>{closeProps.title}</Button>}
				</div>
			)}
		</div>
	)
}
