import React, { useEffect, useMemo, useState } from 'react'
import { Helmet } from 'react-helmet'
import styled from 'styled-components/macro'
import { splitClassesByOwner, useUserClasses } from '../../services/hooks/classes'
import ClassLayoutPresenter from './ClassLayoutPresenter'
import ClassModuleHeader from './ClassModuleHeader'
import EmptyClassImage from '../../assets/EmptyClassImage.png'
import { GRADE } from './constants'
import { PageTitleHeader } from '../../styles/sharedComponents'

import Loading from '../../components/loading/Loading'
import { ErrorLinkButton, ErrorOccurred } from '../../components/Error'
import { useUser } from '../../services/hooks'
import { EXAMPLE_USER_ID } from '../../constants/exampleData'
import { useQueryParam, StringParam, withDefault } from 'use-query-params'
import AddGoogleClassModal from './AddGoogleClassModal'

/**
 * If a teacher has no classes, we want to show them Jerem's picture and text.
 * This function gets it for us, and since it is outside the functional component,
 * it is not re-rendered all the time.
 */
function NoClassesImage() {
	return (
		<div className="flex w-full flex-col items-center">
			<img
				src={EmptyClassImage}
				css="height: 20vw; margin-right: 5vw; padding: 24px;"
				alt=""
			/>
			<p className="text-2xl">Looks like you don’t have any classes! </p>
			<p className="text-2xl">Click Add Class to upload a class with Google Classroom, </p>
			<p className="text-2xl">or create your own.</p>
		</div>
	)
}

const AddGoogleStringParam = withDefault(StringParam, 'HIDE')

export default function Classes(): React$Node {
	const [classSortType, setClassSortType] = useState(GRADE)
	const [classOwner, setClassOwner] = useState(null)
	const { user } = useUser()
	const userId = user?._id ?? EXAMPLE_USER_ID

	const { data: _classes, isLoading, isError, refetch } = useUserClasses()

	useEffect(() => {
		setClassOwner(userId)
	}, [userId])

	const [requestClassroomSuccess, setRequestClassroomSuccess] = useQueryParam(
		'requestClassroomSuccess',
		AddGoogleStringParam,
		{
			updateType: 'replaceIn',
			removeDefaultsFromUrl: true,
		}
	)

	const classOwnerGrouping = useMemo(() => (_classes ? splitClassesByOwner(_classes) : []), [
		_classes,
	])

	const onlyHasCurrentUserClasses = (_classes ?? []).every(class_ =>
		class_.owners.every(({ _id }) => _id === userId)
	)

	const classesMatchingFilter = classOwnerGrouping.find(({ owner }) => owner._id === classOwner)

	const metaDescription =
		'Upload or import your class lists for a seamless launch into 100+ standards-aligned missions.'

	return (
		<ClassWrapper data-walkthrough="classes">
			<AddGoogleClassModal
				isOpen={requestClassroomSuccess === 'SUCCESS'}
				onClose={() => setRequestClassroomSuccess('HIDE')}
				refetchUserClasses={refetch}
			/>
			<Helmet>
				<title>Classes: Hassle-Free Mission Prep</title>
				<meta name="description" content={metaDescription} />
				<meta name="og:description" content={metaDescription} />
			</Helmet>
			<PageTitleHeader>Classes</PageTitleHeader>
			<ClassModuleHeader
				classSortType={classSortType}
				setClassSortType={setClassSortType}
				classOwners={
					onlyHasCurrentUserClasses ? [] : classOwnerGrouping.map(({ owner }) => owner)
				}
				setClassOwner={setClassOwner}
				selectedOwner={classOwner}
			/>
			<hr className="my-3" />
			{isLoading ? (
				<InfoPositioner>
					<StyledLoader />
					<LoadingText>Loading your classes</LoadingText>
				</InfoPositioner>
			) : isError ? (
				<InfoPositioner>
					<ErrorOccurred>
						Failed to load classes
						<ErrorLinkButton onClick={() => refetch()}>Retry</ErrorLinkButton>
					</ErrorOccurred>
				</InfoPositioner>
			) : (
				<>
					<ClassLayoutPresenter
						classSortType={classSortType}
						classes={classesMatchingFilter?.classes || []}
					/>
					{_classes?.length === 0 && <NoClassesImage />}
				</>
			)}
		</ClassWrapper>
	)
}

const ClassWrapper = styled.div`
	${({ theme }) => `
		padding: 0 var(--spacing2x-dont-use);
	`}
`

const StyledLoader = styled(Loading)`
	max-width: 10vw;
`

const InfoPositioner = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: var(--spacing1x-dont-use);
	height: 100%;
`

const LoadingText = styled.div`
	color: ${({ theme }) => theme.primary};
`
