import { ONE_MINUTE } from '../constants'
import type { StudentAnalytics, AnalyticsQuestionResponse } from '@mission.io/mission-toolkit'
import type { DBQuestion } from '../models/Question'
import { variantTypes } from '@mission.io/question-toolkit'
import type { AnalyticsObject } from '../models/Analytics'

// Fake questions used for analytics data generation
export const questions: DBQuestion[] = [
	{
		_id: '5c10b024ca7c6fbfaaf501d3',
		phrase: 'Molecules are composed of',
		type: 'MULTIPLE_CHOICE',
		default: true,
		createdFor: '5afb076c5a109679238615cc',
		version: 1,
		choices: [
			{ _id: '5c10b024ca7c6fbfaaf501d8', text: 'atoms', answer: true },
			{ _id: '5c10b024ca7c6fbfaaf501d7', text: 'electron clouds', answer: false },
			{
				_id: '5c10b024ca7c6fbfaaf501d6',
				text: 'carbon, hydrogen, oxygen, and nitrogen',
				answer: false,
			},
			{ _id: '5c10b024ca7c6fbfaaf501d5', text: 'proteins', answer: false },
		],
	},
	{
		_id: '5c10b024ca7c6fbfaaf501d9',
		phrase: 'The DNA Molecule is represented by',
		type: 'MULTIPLE_CHOICE',
		default: true,
		createdFor: '5afb076c5a109679238615cc',
		version: 1,
		choices: [
			{ _id: '5c10b024ca7c6fbfaaf501de', text: 'a ball and stick model', answer: false },
			{ _id: '5c10b024ca7c6fbfaaf501dd', text: 'a space filling model', answer: false },
			{ _id: '5c10b024ca7c6fbfaaf501dc', text: 'a line and angle model', answer: false },
			{ _id: '5c10b024ca7c6fbfaaf501db', text: 'a ribbon model', answer: true },
		],
	},
	{
		_id: '5c10b024ca7c6fbfaaf501df',
		phrase: "DNA is housed in a cell's ",
		type: 'MULTIPLE_CHOICE',
		default: true,
		createdFor: '5afb076c5a109679238615cc',
		version: 1,
		choices: [
			{ _id: '5c10b024ca7c6fbfaaf501e4', text: 'ribosomes', answer: false },
			{ _id: '5c10b024ca7c6fbfaaf501e3', text: 'organelles', answer: false },
			{ _id: '5c10b024ca7c6fbfaaf501e2', text: 'nucleus', answer: true },
			{ _id: '5c10b024ca7c6fbfaaf501e1', text: 'mitochondria', answer: false },
		],
	},
	{
		_id: '5c10b024ca7c6fbfaaf501e5',
		phrase: 'DNA is essentially a code that is made up of chemical bases.  Those bases are:',
		type: 'MULTIPLE_CHOICE',
		default: true,
		createdFor: '5afb076c5a109679238615cc',
		version: 1,
		choices: [
			{ _id: '5c10b024ca7c6fbfaaf501ea', text: 'sugar and phosphate', answer: false },
			{
				_id: '5c10b024ca7c6fbfaaf501e9',
				text: 'adenine, guanine, cytosine, and thymine',
				answer: true,
			},
			{
				_id: '5c10b024ca7c6fbfaaf501e8',
				text: 'adenine, guanine, cytosine, and uracil',
				answer: false,
			},
			{ _id: '5c10b024ca7c6fbfaaf501e7', text: ' nucleic acids', answer: false },
		],
	},
	{
		_id: '5c10b024ca7c6fbfaaf501f1',
		phrase:
			'A DNA molecule consists of two nucleotide chains, running in____________ direction and coiled into a ________ helix',
		type: 'MULTIPLE_CHOICE',
		default: true,
		createdFor: '5afb076c5a109679238615cc',
		version: 1,
		choices: [
			{ _id: '5c10b024ca7c6fbfaaf501f6', text: 'the same, double', answer: false },
			{ _id: '5c10b024ca7c6fbfaaf501f5', text: 'opposite, single', answer: false },
			{ _id: '5c10b024ca7c6fbfaaf501f4', text: 'parallel, double', answer: false },
			{ _id: '5c10b024ca7c6fbfaaf501f3', text: 'opposite, double', answer: true },
		],
	},
	{
		_id: '5c10b024ca7c6fbfaaf50210',
		phrase: 'What is the color of the earth as seen from space?',
		type: 'MULTIPLE_CHOICE',
		default: true,
		createdFor: '5afb076d5a1096792386235b',
		version: 1,
		choices: [
			{ _id: '5c10b024ca7c6fbfaaf50214', text: 'White and black', answer: false },
			{ _id: '5c10b024ca7c6fbfaaf50213', text: 'White and pink', answer: false },
			{ _id: '5c10b024ca7c6fbfaaf50212', text: 'White and blue', answer: true },
		],
	},
	{
		_id: '5c10b024ca7c6fbfaaf50229',
		phrase: 'When you gaze at the moon in the night sky, you might see all of these except for',
		type: 'MULTIPLE_CHOICE',
		default: true,
		createdFor: '5afb076d5a1096792386235b',
		version: 1,
		choices: [
			{ _id: '5c10b024ca7c6fbfaaf5022e', text: 'less than half of the moon', answer: false },
			{ _id: '5c10b024ca7c6fbfaaf5022d', text: 'half of the moon', answer: false },
			{ _id: '5c10b024ca7c6fbfaaf5022c', text: 'a full moon', answer: false },
			{ _id: '5c10b024ca7c6fbfaaf5022b', text: 'more than a full moon', answer: true },
		],
	},
	{
		_id: '5c10b024ca7c6fbfaaf50215',
		phrase: 'When we say that the earth rotates on its axis, this means that the earth',
		type: 'MULTIPLE_CHOICE',
		default: true,
		createdFor: '5afb076d5a1096792386235b',
		version: 1,
		choices: [
			{ _id: '5c10b024ca7c6fbfaaf50219', text: 'spins', answer: true },
			{ _id: '5c10b024ca7c6fbfaaf50218', text: 'moves back and forth', answer: false },
			{ _id: '5c10b024ca7c6fbfaaf50217', text: 'moves up and down', answer: false },
		],
	},
	{
		_id: '5c10b024ca7c6fbfaaf50243',
		phrase: 'Which of these does not happen every month',
		type: 'MULTIPLE_CHOICE',
		default: true,
		createdFor: '5afb076d5a1096792386235b',
		version: 1,
		choices: [
			{ _id: '5c10b024ca7c6fbfaaf50247', text: 'half moon', answer: false },
			{ _id: '5c10b024ca7c6fbfaaf50246', text: 'full moon', answer: false },
			{ _id: '5c10b024ca7c6fbfaaf50245', text: 'eclipse', answer: true },
		],
	},
	{
		_id: '5c10b024ca7c6fbfaaf5025d',
		phrase: 'What statement best describes the earth, the moon, and the sun?',
		type: 'MULTIPLE_CHOICE',
		default: true,
		createdFor: '5afb076d5a1096792386235b',
		version: 1,
		choices: [
			{
				_id: '5c10b024ca7c6fbfaaf50262',
				text: 'The moon travels around the earth and the earth travels around the sun',
				answer: false,
			},
			{
				_id: '5c10b024ca7c6fbfaaf50261',
				text: 'The moon is smaller than the earth and the earth is smaller than the sun',
				answer: false,
			},
			{
				_id: '5c10b024ca7c6fbfaaf50260',
				text:
					'The earth is kept in its orbit by the gravity of the sun and the moon is kept in its orbit by the gravity of earth',
				answer: false,
			},
			{
				_id: '5c10b024ca7c6fbfaaf5025f',
				text: 'All of these statements are correct',
				answer: true,
			},
		],
	},
]

// Fake question answers shared between all analytics students
const questionAnswers: Array<AnalyticsQuestionResponse> = [
	{
		questionId: '5c10b024ca7c6fbfaaf501d9',
		questionVersion: 1,
		requestId: '205',
		score: { score: 0, perfectScore: 1 },
		answer: {
			type: variantTypes.MULTIPLE_CHOICE,
			value: '5c10b024ca7c6fbfaaf501de',
		},
		timestamp: 1626874738388,
	},
	{
		questionId: '5c10b024ca7c6fbfaaf501df',
		questionVersion: 1,
		requestId: '206',
		score: { score: 0, perfectScore: 1 },
		answer: {
			type: variantTypes.MULTIPLE_CHOICE,
			value: '5c10b024ca7c6fbfaaf501e4',
		},
		timestamp: 1626874834985,
	},
	{
		questionId: '5c10b024ca7c6fbfaaf501e5',
		questionVersion: 1,
		requestId: '207',
		score: { score: 0, perfectScore: 1 },
		answer: {
			type: variantTypes.MULTIPLE_CHOICE,
			value: '5c10b024ca7c6fbfaaf501ea',
		},
		timestamp: 1626875383967,
	},
	{
		questionId: '5c10b024ca7c6fbfaaf501f1',
		questionVersion: 1,
		requestId: '208',
		score: { score: 1, perfectScore: 1 },
		answer: {
			type: variantTypes.MULTIPLE_CHOICE,
			value: '5c10b024ca7c6fbfaaf501f3',
		},
		timestamp: 1626875359890,
	},
	{
		questionId: '5c10b024ca7c6fbfaaf50210',
		questionVersion: 1,
		requestId: '209',
		score: { score: 1, perfectScore: 1 },
		answer: {
			type: variantTypes.MULTIPLE_CHOICE,
			value: '5c10b024ca7c6fbfaaf50212',
		},
		timestamp: 1626876714007,
	},
	{
		questionId: '5c10b024ca7c6fbfaaf50229',
		questionVersion: 1,
		requestId: '210',
		score: { score: 0, perfectScore: 1 },
		answer: {
			type: variantTypes.MULTIPLE_CHOICE,
			value: '5c10b024ca7c6fbfaaf5022c',
		},
		timestamp: 1626874640527,
	},
	{
		questionId: '5c10b024ca7c6fbfaaf50215',
		questionVersion: 1,
		requestId: '211',
		score: { score: 1, perfectScore: 1 },
		answer: {
			type: variantTypes.MULTIPLE_CHOICE,
			value: '5c10b024ca7c6fbfaaf50219',
		},
		timestamp: 1626876360203,
	},
	{
		questionId: '5c10b024ca7c6fbfaaf50243',
		questionVersion: 1,
		requestId: '212',
		score: { score: 0, perfectScore: 1 },
		answer: {
			type: variantTypes.MULTIPLE_CHOICE,
			value: '5c10b024ca7c6fbfaaf50247',
		},
		timestamp: 1626873875721,
	},
	{
		questionId: '5c10b024ca7c6fbfaaf5025d',
		questionVersion: 1,
		requestId: '213',
		score: { score: 0, perfectScore: 1 },
		answer: {
			type: variantTypes.MULTIPLE_CHOICE,
			value: '5c10b024ca7c6fbfaaf50261',
		},
		timestamp: 1626875720404,
	},
]

// Create a fake analytics score for a student
const getStudentScore = (start: number, seed: number): StudentAnalytics => {
	const values = [
		start * 8,
		start * 10,
		start * 12,
		start * 22,
		start * 30,
		start * 45,
		start * 50,
		start * 60,
	]
	const index = seed >= values.length ? values.length : seed
	return {
		knowledge: {
			questions: values[index] / 100,
			application: (56 + seed) / 100,
		},
		skills: {
			collaboration: {
				endOfMissionSurvey: 1,
				socialHealthScore: values[index] / 100,
				engineerPanelScore: { time: 0, score: (50 + seed) / 100 },
			},
			criticalThinking: {
				endOfMissionSurvey: 1,
				scannedData: (values[index] + seed) / 100,
			},
		},
		disposition: {
			grit: {
				endOfMissionSurvey: 1,
				gritAfterNegativeEvents: {
					APMDetails: [],
					averageAPM: 3,
					score: (50 + seed) / 100,
				},
			},
			initiative: {
				endOfMissionSurvey: 1,
				alertResponse: {
					time: 0,
					score: (values[index] + 15) / 100,
				},
				scannedDataResponse: {
					time: 0,
					score: (values[index] + 20) / 100,
				},
				stationResponse: {
					time: 0,
					score: (45 + seed) / 100,
				},
				mapParticipation: {
					percentActive: 0,
					score: (13 + seed) / 100,
				},
				boostParticipation: {
					percentActive: 0,
					score: (15 + seed) / 100,
				},
			},
		},
		studentPoints: {
			DECKS: getRandomInt(10, 700) * 10,
			COMMUNICATION: getRandomInt(10, 700) * 10,
			TRANSPORTER: getRandomInt(5, 700) * 10,
			THRUSTERS: getRandomInt(5, 700) * 10,
			POWER: getRandomInt(5, 700) * 10,
			DEFENSE: getRandomInt(5, 700) * 10,
			TRACTOR_BEAM: getRandomInt(5, 700) * 10,
			QUESTIONS: getRandomInt(5, 700) * 10,
			SENSOR: getRandomInt(5, 700) * 10,
		},
		questionAnswers,
	}
}

/* Get fake student analytics for a group of students.
 Analytics scores for the class will gradually increase 
 the more this function is called. Capping at scores close to 100 after the function is called  around 
 8 times.
*/
const getFakeStudentAnalytics = (() => {
	let seed = 0
	return (
		studentIds: Array<string>,
		reset: boolean
	): { [studentId: string]: StudentAnalytics } => {
		seed += 1
		if (reset) {
			seed = 0
		}
		const analytics = {}
		studentIds.forEach(studentId => {
			analytics[studentId] = getStudentScore(getRandomInt(0, 10), seed)
		})
		return analytics
	}
})()
// Bank of first names to generate students and teachers
const firstNameBank = [
	'Alex',
	'Abdou',
	'Bryce',
	'Becca',
	'Cal',
	'Charlie',
	'Dean',
	'Dennis',
	'Erin',
	'Evan',
	'Flow',
	'Fynn',
	'Greg',
	'Greta',
	'Hannah',
	'Hank',
	'Ian',
	'Igor',
	'John',
	'Jessica',
	'Kevin',
	'Kasey',
	'Liam',
	'Landie',
	'Maddy',
	'Mark',
	'Nora',
	'Ned',
	'Oscar',
	'Olivia',
	'Penny',
	'Percy',
	'Quinn',
	'Quincey',
	'Rafael',
	'Rose',
	'Sarah',
	'Samine',
	'Tricia',
	'Tim',
	'Ursula',
	'Veronica',
	'Will',
	'Xenia',
	'Yi',
	'Zach',
]
// Bank of last names to generate students and teachers
const lastNameBank = [
	'Anderson',
	'Booker',
	'Conrad',
	'Denver',
	'Ericson',
	'Frank',
	'Gloussen',
	'Hall',
	'Ilstoy',
	'Johnson',
	'Kelp',
	'Landing',
	'Matheson',
	'Noble',
	'Osguthorpe',
	'Pope',
	'Guerrero',
	'Quadro',
	'Rios',
	'Lopez',
	'Sola',
	'Thorpe',
	'Truman',
	'Udy',
	'Ville',
	'Wilson',
	'Xenos',
	'Yakishoba',
	'Zillow',
	'Porter',
	'Jensen',
	'Nelson',
	'Wood',
	'White',
	'Black',
	'Green',
	'Jasper',
]

// A "class" object that is only used to generate analytics in tests
type TempAnalyticsClass = {|
	classId: string,
	className: string,
	schoolName: string,
	schoolId: string,
	students: string[],
	classSize: number,
	teacherId: string,
|}

/* Generates a new class everytime it is called time it is called until it cannot.
16 classes are generated per school in the schools array and then generation stops
*/
const generateClass = (
	school: { name: string, id: string },
	classIndex: number,
	generateNameAndId: () => { id: string, lastName: string, firstName: string }
): TempAnalyticsClass => {
	const grade = classIndex % 7 || 'K'
	const controlSet = grade === 'K' || grade < 4 ? 'K-3' : '4+'

	const { id: teacherId, lastName } = generateNameAndId()
	const className = `${grade} ${lastName}`
	const classSize = getRandomInt(18, 30)
	return {
		classId: `${classIndex}${controlSet}-${school.name}`,
		className,
		schoolName: school.name,
		schoolId: school.id,
		teacherId,
		classSize,
		students: new Array(classSize).fill(0).map((_, i) => generateNameAndId().id),
	}
}

const k3Missions = [
	{
		missionName: 'Push and Pull',
		simulationId: '5f0390bc4852c2001d747435',
		controlSet: 'K-3',
	},
	{ missionName: 'Growth', simulationId: '5f0390bc4852c2001d747435', controlSet: 'K-3' },
	{
		missionName: 'Weather Patterns',
		simulationId: '5f0390bc4852c2001d747435',
		controlSet: 'K-3',
	},
	{ missionName: 'Lost in Space', simulationId: '5f0390bc4852c2001d747435', controlSet: 'K-3' },
	{ missionName: 'Undergrowth', simulationId: '5f0390bc4852c2001d747435', controlSet: 'K-3' },
	{ missionName: 'Pollinate', simulationId: '5f0390bc4852c2001d747435', controlSet: 'K-3' },
	{ missionName: 'Musical Moss', simulationId: '5f0390bc4852c2001d747435', controlSet: 'K-3' },
	{ missionName: 'Training', simulationId: '5f0390bc4852c2001d747435', controlSet: 'K-3' },
]
const fourPlusMissions = [
	{ missionName: 'Mt. Kalamity', simulationId: '6328b637086482001d23bd67', controlSet: '4-6' },
	{
		missionName: 'Sinister Science',
		simulationId: '6328b637086482001d23bd67',
		controlSet: '4-6',
	},
	{ missionName: 'First Frenzy', simulationId: '6328b637086482001d23bd67', controlSet: '4-6' },

	{
		missionName: 'Physics Unhinged',
		simulationId: '6328b637086482001d23bd67',
		controlSet: '4-6',
	},
	{
		missionName: 'Excalibrus',
		simulationId: '6328b637086482001d23bd67',
		controlSet: '4-6',
	},
	{
		missionName: 'Pursuit',
		simulationId: '6328b637086482001d23bd67',
		controlSet: '4-6',
	},
	{
		missionName: 'Meet the Moodoos',
		simulationId: '6328b637086482001d23bd67',
		controlSet: '4-6',
	},
	{
		missionName: 'Vanquishers',
		simulationId: '6328b637086482001d23bd67',
		controlSet: '4-6',
	},
]

// Helper function gets random integer between min inclusive and max inclusive
function getRandomInt(min, max): number {
	min = Math.ceil(min)
	max = Math.floor(max)
	return Math.floor(Math.random() * (max - min + 1)) + min
}
// Helper functions adds a certain amount of days to a date
function addDays(date, days) {
	var result = new Date(date)
	result.setDate(result.getDate() + days)
	return result
}

const AUGUST = 7
export function getStartDateOfRecentSchoolYear(): Date {
	const today = new Date()
	const thisYear = today.getFullYear()
	const mostRecentAugustYear = today.getMonth() < AUGUST ? thisYear - 1 : thisYear
	return new Date(`${mostRecentAugustYear}-08-03T09:00`)
}

// Generate analytics for a class.
const getAnalyticsForClass = (() => {
	let missionId = 0
	return (_class: TempAnalyticsClass, missionCount: number): AnalyticsObject[] => {
		const controlSet = _class.classId.includes('K-3') ? 'K-3' : '4+'
		const missions = controlSet === '4+' ? fourPlusMissions : k3Missions

		const startDate = getStartDateOfRecentSchoolYear()
		const totalDays = (new Date().getTime() - 10 - startDate.getTime()) / 86400000
		let intervals = [
			Math.floor(totalDays / missions.length / 2),
			Math.floor(totalDays / missions.length),
		]
		let date

		const analytics: AnalyticsObject[] = []
		for (let count = 0; count < missionCount; count += 1) {
			const mission = missions[count % missions.length]
			missionId += 1
			const daysBetweenMissions = getRandomInt(intervals[0], intervals[1])
			date = date ? addDays(date, daysBetweenMissions) : startDate
			analytics.push({
				missionId: `${missionId}`,
				version: 1,
				classId: _class.classId,
				createdTime: String(date),
				// $FlowFixMe[incompatible-call] first characters of classnames defined above are GradeEnums
				grades: [_class.className[0]],
				schoolName: _class.schoolName,
				schoolId: _class.schoolId,
				teacherId: _class.teacherId,
				className: _class.className,
				simulationId: `${mission.simulationId}`,
				missionName: mission.missionName,
				controlSet: controlSet,
				analytics: {
					_id: `analytics-${missionId}`,
					weights: {},
					studentAnalytics: getFakeStudentAnalytics(_class.students, date === startDate),
					missionLength: 30 * ONE_MINUTE,
					activeStudents: _class.classSize,
					shipDeaths: 0,
					totalCorrectCulminatingMoments: 1,
					usedHackerMode: false,
				},
			})
		}
		return analytics
	}
})()

// School names to generate analytics for.
const defaultSchools = [
	{ name: 'Porter Rockwell Elementary', id: 'p_r_elem', totalClasses: 16 },
	{ name: 'American Science Academy', id: 'a_s_acad', totalClasses: 20 },
	{ name: 'Obsidian Elementary', id: 'o_elem', totalClasses: 10 },
	{ name: 'Salt Lake Elementary', id: 'slc_elem', totalClasses: 22 },
]

// Generate all analytics for the schools provided
export function generateAnalyticsForSchools(
	schools: Array<{ name: string, id: string, totalClasses: number }> = defaultSchools,
	missionsPerClass: number = 8
): {
	analytics: AnalyticsObject[],
	idToNameMap: { [string]: { firstName: string, lastName: string } },
	questions: DBQuestion[],
} {
	const allAnalytics = []
	// Generates names with unique ids and adds them to a map of names.
	const [generateNameAndId, _idToNameMap]: [
		() => { id: string, firstName: string, lastName: string },
		{ [string]: { firstName: string, lastName: string } }
	] = (() => {
		const idToNameMap: { [string]: { firstName: string, lastName: string } } = {}
		return [
			() => {
				let id = ''
				let firstNameIndex
				let lastNameIndex
				let tries = 10
				do {
					firstNameIndex = getRandomInt(0, firstNameBank.length - 1)
					lastNameIndex = getRandomInt(0, lastNameBank.length - 1)
					id = `${firstNameIndex} ${lastNameIndex}`
					tries++
					if (tries > 10) {
						id = `${firstNameIndex}${lastNameIndex}-${tries}`
					}
					if (!_idToNameMap[id]) {
						const firstName = firstNameBank[firstNameIndex]
						const lastName = lastNameBank[lastNameIndex]
						idToNameMap[id] = {
							firstName,
							lastName,
						}
						return { id, firstName, lastName }
					}
				} while (idToNameMap[id])
				return { id: '', firstName: '', lastName: '' }
			},
			idToNameMap,
		]
	})()

	schools.forEach(school => {
		for (let i = 0; i < school.totalClasses; i++) {
			allAnalytics.push(
				...getAnalyticsForClass(
					generateClass(school, i, generateNameAndId),
					missionsPerClass
				)
			)
		}
	})
	return { analytics: allAnalytics, idToNameMap: _idToNameMap, questions }
}
const data = generateAnalyticsForSchools()
export const analytics: AnalyticsObject[] = data.analytics
export const idToNameMap: { [string]: { firstName: string, lastName: string } } = data.idToNameMap
