// @flow
import React, { useMemo } from 'react'
import CSVReader from 'react-csv-reader'
import { Button } from '@mission.io/styles'
import styled from 'styled-components'

type WantedFieldWithAliases = {|
	field: string, // the field in the objects to be returned
	aliases?: string[], // other names this field can go by in the csv
|}
type Props = {
	wantedFields: WantedFieldWithAliases[],
	onLoad: (Array<any>) => void,
	onError: (error: any) => void,
	exampleData: Array<string[]>,
}

/**
 * A component that reads rows from a CSV file into an array of objects. The properties (keys) on
 * the items in the returned array will be the intersection of
 * 1. The values that are found in the first row in the CSV file, and
 * 2. The values in the `keys` prop
 *
 * This component provides a button to upload a CSV file, as well as a button to download an example CSV.
 * When the file is selected by the user, the function provided as the `onLoad` prop will be called with
 * the array of objects parsed from the CSV.
 *
 * Note: order of the columns in the CSV does not matter
 */
export default function FileLoaderComponent({
	wantedFields,
	onLoad,
	onError,
	exampleData,
}: Props): React$Node {
	const exampleDataUrl = useMemo(() => {
		let csv = 'data:text/csv;charset=utf-8,'
		exampleData.forEach((row, i) => {
			let csvRow = row.join(',')
			csv += csvRow
			if (i !== exampleData.length - 1) {
				csv += '\r\n'
			}
		})
		return encodeURI(csv)
	}, [exampleData])

	return (
		<Container>
			<Button className="w-fit mr-2">
				<StyledA
					target="_blank"
					rel="noopener noreferrer"
					download="example-csv.csv"
					href={exampleDataUrl}>
					Download Example
				</StyledA>
			</Button>
			<CSVReader
				accept=".csv,text/csv"
				label={'Upload CSV'}
				onFileLoaded={(rows: string[][]) => {
					onLoad(parseMatrix(rows, wantedFields))
				}}
				onError={onError}
			/>
		</Container>
	)
}

/**
 * parseMatrix - pull out the wantedKeys from the given rows
 *
 * @param {string[][]} rows - the rows of the csv
 * @param {Set<string>} wantedKeys - the keys wanted from the csv
 *
 * @return {Array<{ [wantedKey: string]: string }>} - a list of the objects (one object to one row) with the wanted keys
 */
export function parseMatrix(
	rows: string[][],
	wantedFields: WantedFieldWithAliases[]
): Array<{ [wantedKey: string]: string }> {
	if (!rows.length) {
		return []
	}

	// determine what header values map to which wanted fields
	const aliasesToFieldNames: {
		[aliasHeader: string]: {
			fieldNames: [], // the name of the fields that have this alias
		},
	} = {}
	wantedFields.forEach(wantedField => {
		let aliases = [normalizeHeader(wantedField.field)]
		if (wantedField.aliases) {
			wantedField.aliases.forEach(alias => aliases.push(normalizeHeader(alias)))
		}

		aliases.forEach(alias => {
			aliasesToFieldNames[alias] ??= { fieldNames: [] }
			aliasesToFieldNames[alias].fieldNames.push(wantedField.field)
		})
	})

	// find where fields are located in the row of the csv
	const keyLocationMapping = []
	const neededFields = new Set(wantedFields.map(({ field }) => field))
	rows.splice(0, 1)[0].forEach((header, index) => {
		const normalizedHeader = normalizeHeader(header)
		const nonAliasedFieldNames = aliasesToFieldNames[normalizedHeader]?.fieldNames
		if (nonAliasedFieldNames) {
			nonAliasedFieldNames.forEach(wantedField => {
				keyLocationMapping.push({
					wantedField: wantedField,
					location: index,
				})
				neededFields.delete(wantedField)
			})
		}
	})

	// determine which fields are wanted, but are missing from the csv
	const missingKeys = Array.from(neededFields)

	// pull data from the rows
	const parsedRows = []
	rows.forEach(row => {
		if (row[0] !== '') {
			parsedRows.push(parseRow(row, keyLocationMapping, missingKeys))
		}
	})

	return parsedRows
}

/**
 * parseRow - pull out the wantedKeys from the given row
 *
 * @param {string[]} rows - a row of the csv
 * @param {Array<{wantedField: string, location: number}>} fieldLocationMapping - the location of the wanted fields in the row
 * @param {Set<string>} wantedKeys - fields which do not exist in the csv but should be assigned a default value
 *
 * @return {{ [wantedKey: string]: string }} - an objects with the wanted keys with values taken from the given row
 */
export function parseRow(
	row: string[],
	fieldLocationMapping: Array<{ wantedField: string, location: number }>,
	missingKeys: Array<string>
): { [wantedKey: string]: string } {
	const newRow: { [fileKey: string]: string } = {}
	fieldLocationMapping.forEach(({ wantedField, location }) => {
		newRow[wantedField] ||= row[location]?.trim() ?? ''
	})

	missingKeys.forEach(key => {
		newRow[key] ||= ''
	})

	return newRow
}

/**
 * normalizeHeader - a function used to normalize headers for comparison
 *
 * @param {string} header - the header to normalize
 *
 * @return {string} - the normalized header
 */
function normalizeHeader(header: string): string {
	return header.trim().toLowerCase()
}

const Container = styled.div`
	display: flex;
	align-items: center;

	.csv-label {
		display: none;
	}
`

const StyledA = styled.a`
	${({ theme }) => `
		padding: 0 var(--spacing2x-dont-use);
		color: ${theme.white} !important;
	`}
`
