import React from 'react'

import { Button } from '@mission.io/styles'
import { FaDownload } from 'react-icons/lib/fa'
import type { ScoreFormatType } from '../constants'
import { scoreFormatEnum } from '../constants'
import styled from 'styled-components'
import { getCsvUrl } from './csvGenerator'
import type { AnalyticsObject } from '../../../models/Analytics'
import type { NameLookup } from '../../../download/questions'
import { DownloadAllStudentDataButton } from './DownloadAllStudentDataButton'
import { downloadFile } from '../../../utility/functions'
import { toast } from 'react-toastify'

/**
 * AnalyticsScoreTableToolbar - a toolbar for analytics tables which have scores
 *
 * @param {Object} props - the react props
 * @param {any[]} props.columns - the columns for the student analytics tables
 * @param {any[]} props.data - the data to render into the columns in the student analytics table
 * @param {?string} props.csvName - the file name of the csv to generate, null/undefined will cause the csv to not be generated and downloadable
 * @param {ScoreFormatType} props.displayType - the type of the values being displayed on the table
 * @param {(newDisplayType: ScoreFormatType) => void} props.setDisplayType - a callback to update the type of the values being displayed on the table
 * @param {filteredAnalytics} props.filteredAnalytics? - the list of analytics the data was generated from
 * @param {NameLookup} props.nameLookup? - a map of studentIds to studentNames for the students in the filteredAnalytics
 * @param {boolean = false} props.allowDownloadingAllStudentDataInAnalytics? - will show a button to download all student data if true and filteredAnalytics and nameLookup are passed, otherwise will not show the button
 *
 */
export function AnalyticsScoreTableToolbar({
	columns,
	data,
	csvName,
	displayType,
	setDisplayType,
	filteredAnalytics,
	nameLookup,
	allowDownloadingAllStudentDataInAnalytics = false,
}: {
	columns: any[],
	data: any[],
	csvName: ?string,
	displayType: ScoreFormatType,
	setDisplayType: (newDisplayType: ScoreFormatType) => void,
	filteredAnalytics?: AnalyticsObject[],
	nameLookup?: NameLookup,
	allowDownloadingAllStudentDataInAnalytics?: boolean,
}): React$Node {
	return (
		<ToolbarSplitter>
			<ButtonGroup>
				{csvName != null ? (
					<Button
						onClick={() => {
							try {
								const csvFile = getCsvUrl(data, columns)
								if (!csvFile) {
									toast.error('Failed to generate csv file')
									return
								}
								downloadFile(csvFile, `${csvName}.csv`)
								URL.revokeObjectURL(csvFile)
							} catch (error) {
								toast.error('Failed to generate analytics csv file')
								console.error(
									'Failed to generate the analytics csv file due to the following error',
									error
								)
							}
						}}>
						<FaDownload className="inline" /> Download CSV
					</Button>
				) : null}
				{allowDownloadingAllStudentDataInAnalytics && filteredAnalytics && nameLookup ? (
					<DownloadAllStudentDataButton
						displayType={displayType}
						filteredAnalytics={filteredAnalytics}
						nameLookup={nameLookup}
						csvName={csvName ?? 'Unknown'}
					/>
				) : null}
			</ButtonGroup>
			<ButtonGroup>
				<Button
					className="mr-2"
					disabled={displayType === scoreFormatEnum.PROFICIENCY_LEVEL}
					onClick={() => setDisplayType(scoreFormatEnum.PROFICIENCY_LEVEL)}>
					Show Levels
				</Button>
				<Button
					disabled={displayType === scoreFormatEnum.PERCENT}
					onClick={() => setDisplayType(scoreFormatEnum.PERCENT)}>
					Show Percentages
				</Button>
			</ButtonGroup>
		</ToolbarSplitter>
	)
}

const ButtonGroup = styled.div`
	display: flex;
	gap: var(--spacing1x-dont-use);
`

const ToolbarSplitter = styled.div`
	display: flex;
	justify-content: space-between;
	margin-bottom: var(--spacing2x-dont-use);
`
