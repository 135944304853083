import type { AutomatedSimulation } from '../../models/Simulation'
import type { Standard } from './helpers'

import Markdown from '../../components/Markdown'
import { sortStandardsTableData } from './helpers'

import React, { useMemo } from 'react'
import { Link } from 'react-router-dom'
import { useReactTable, getCoreRowModel, flexRender } from '@tanstack/react-table'
import styled from 'styled-components/macro'

const GRADE = 'GRADE'
const STANDARD_CODE = 'STANDARD_CODE'

export type StandardTableData = {
	standard: ?Standard,
	simulations: Array<AutomatedSimulation>,
}

/**
 * Render a mission name linking to the prep mission page
 */
function RenderMissionName({
	getValue,
}: {
	getValue: () => Array<AutomatedSimulation>,
}): React$Node {
	return (
		<SplitCell divisions={getValue().length}>
			{getValue().map(simulation => (
				<MultipleWrapper key={`mission_name_${simulation._id}`}>
					<Link
						className="text-secondary hover:text-secondary-light"
						to={`/prep/${simulation._id}`}>
						{simulation.name}
					</Link>
				</MultipleWrapper>
			))}
		</SplitCell>
	)
}

/**
 * Render the mission summary using regex to remove text surrounded by double asterisks
 */
function RenderMissionSummary({
	getValue,
}: {
	getValue: () => Array<AutomatedSimulation>,
}): React$Node {
	const value = getValue()
	return (
		<SplitCell divisions={value.length}>
			{value.map(simulation => {
				const estTime = simulation.summary.match(/([*]Est[.] time: [0-9]+)\w+/)
				const summary = simulation.summary.substring(0, estTime?.index)
				return (
					<MultipleWrapper key={`mission_summary_${simulation._id}`}>
						<div>{summary.replace(/\*\*([^,*]+)\*\*/g, '')}</div>
					</MultipleWrapper>
				)
			})}
		</SplitCell>
	)
}

/**
 * Renders the mission time estimate using regex to parse the time estimate from the mission summary
 */
function RenderTimeEstimate({
	getValue,
}: {
	getValue: () => Array<AutomatedSimulation>,
}): React$Node {
	const value = getValue()
	return (
		<SplitCell divisions={value.length}>
			{value.map(simulation => {
				const regexMatch = simulation.summary.match(/([*]Est[.] time: [0-9]+)\w+/)
				const estimateString = regexMatch
					? simulation.summary.substring(regexMatch?.index).replace(/\D/g, '') + ' min.'
					: ''
				return (
					<MultipleWrapper key={`mission_time_estimate_${simulation._id}`}>
						<div>{estimateString || 'Unknown'}</div>
					</MultipleWrapper>
				)
			})}
		</SplitCell>
	)
}

/**
 * @param standardToSimulations map standardId to a list of automated simulations associated with that standard
 * @param standards a list of all the standards to be displayed
 * @param stateStandardNames the name of the standards for the given state
 * @param filtersHeight the height of the filters div above the standards table
 * @param standardSetName the abbreviation or name of the current standard set
 * a Table that displays standards and simulations associated with the standards
 */
export default function StandardsTable({
	standardToSimulations,
	standards,
	filtersHeight,
	standardSetName,
}: {
	standardToSimulations: { [string]: Array<AutomatedSimulation> },
	standards: ?Array<Standard>,
	filtersHeight: number,
	standardSetName: string,
}): React$Node {
	const columns = useMemo(() => {
		const columnValues = [
			{
				header: 'Standard Code',
				accessorFn: element => element.standard?.name,
				cell: ({ getValue }: { getValue: () => string }): React$Node => (
					<CellPadding>{getValue()}</CellPadding>
				),
				id: STANDARD_CODE,
				size: 50,
			},
			{
				header: 'Grade',
				accessorFn: element => element.standard?.grade,
				cell: ({ getValue }: { getValue: () => string }): React$Node => (
					<CellPadding>{getValue()}</CellPadding>
				),
				id: GRADE,
				size: 50,
			},
			{
				header: `${standardSetName} Evidence Outcomes`,
				accessorFn: element => element.standard?.full,
				cell: ({ getValue }: { getValue: () => string }): React$Node => (
					<CellPadding>
						<Markdown>{getValue().replace(/\*\*([^,*]+)\*\*/, '')}</Markdown>
					</CellPadding>
				),
				id: 'EVIDENCE_OUTCOMES',
			},
			{
				header: 'Mission Name',
				accessorFn: element => element.simulations,
				cell: RenderMissionName,
				id: 'MISSION_NAME',
				size: 100,
			},
			{
				header: 'Mission Summary',
				accessorFn: element => element.simulations,
				cell: RenderMissionSummary,
				id: 'MISSION_SUMMARY',
			},
			{
				header: 'Mission Length',
				accessorFn: element => element.simulations,
				cell: RenderTimeEstimate,
				size: 50,
				id: 'MISSION_LENGTH',
			},
		]
		return columnValues
	}, [standardSetName])

	const data = useMemo(() => {
		const dataVals = []

		Object.keys(standardToSimulations).forEach(standardId => {
			const simulations = standardToSimulations[standardId]
			const standard = standards?.find(standard => standard._id === standardId)
			dataVals.push({ standard, simulations })
		})

		const orderedStandards = sortStandardsTableData(dataVals)

		return orderedStandards
	}, [standardToSimulations, standards])

	const table = useReactTable({
		columns,
		data,
		getCoreRowModel: getCoreRowModel(),
	})

	return (
		<table className="rounded-lg shadow">
			<thead
				className="sticky text-sm text-primary-50"
				style={{ top: `calc(var(--header-height) + ${filtersHeight}px)` }}>
				{table.getHeaderGroups().map(headerGroup => (
					<tr key={headerGroup.id}>
						{headerGroup.headers.map(header => {
							return (
								<th
									key={header.id}
									className="p-2 text-center first:rounded-tl-lg last:rounded-tr-lg bg-primary-500"
									id={header.id}
									colSpan={header.colSpan}>
									{flexRender(
										header.column.columnDef.header,
										header.getContext()
									)}
								</th>
							)
						})}
					</tr>
				))}
			</thead>
			<tbody>
				{table.getRowModel().rows.map(row => {
					return (
						<tr key={row.id} className="even:bg-primary-800 odd:bg-primary-900">
							{row.getVisibleCells().map(cell => {
								return (
									<Cell key={cell.id}>
										{flexRender(cell.column.columnDef.cell, cell.getContext())}
									</Cell>
								)
							})}
						</tr>
					)
				})}
			</tbody>
		</table>
	)
}

const Cell = styled.td`
	text-align: center;
	:not(:last-child) {
		border-right: 1px solid var(--app-gray);
	}
`

const SplitCell = styled.div`
	width: 100%;
	height: 100%;
	display: grid;
	grid-auto-rows: 1fr;
	grid-template-columns: 1;
`

const MultipleWrapper = styled.div`
	:not(:last-child) {
		border-bottom: 1px solid var(--app-gray);
	}
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	${({ theme }) => `
        padding: var(--spacing2x-dont-use);
    `}
`

const CellPadding = styled.div`
	${({ theme }) => `
		padding:  var(--spacing2x-dont-use);
	`}
`
