import React, { useContext } from 'react'
import styled from 'styled-components'
import { ThemeContext } from 'styled-components'
import type { ClassType } from '../../models/ClassType'
import { CLASS_STATUS, useClass } from '../../services/hooks/classes'
import Loading from '../../components/loading/Loading'
import { ErrorLinkButton, ErrorOccurred } from '../../components/Error'
import { Students } from './SingleClass'
import { FaMinus, FaPlus } from 'react-icons/lib/fa'
import Dropdown from '../../components/helpers/Dropdown'
import QuestionButtonAction from '../../components/QuestionButtonAction'
import classnames from 'classnames'

type Props = {
	classes: $ReadOnlyArray<ClassType>,
	selectedClassesIds: $ReadOnlyArray<string>,
	setSelectedClassesIds: (newVal: string[]) => void,
}

/**
 * Component that displays all google classes for the user, allowing the user to select which google classes they would like to import.
 */
export default function GoogleClassPresenter({
	classes,
	selectedClassesIds,
	setSelectedClassesIds,
}: Props): React$Node {
	return (
		<div className="w-full grid grid-cols-2 gap-2">
			{[...classes]
				.sort((a, b) => {
					const aName = a.name || ''
					const bName = b.name || ''
					return aName.localeCompare(bName)
				})
				.map(_class => {
					return (
						<GoogleClass
							key={_class._id}
							classId={_class._id}
							className={_class.name}
							selected={Boolean(
								selectedClassesIds.find(classId => classId === _class._id)
							)}
							addClass={() =>
								setSelectedClassesIds([...selectedClassesIds, _class._id])
							}
							removeClass={() =>
								setSelectedClassesIds(
									selectedClassesIds.filter(
										currentClassId => currentClassId !== _class._id
									)
								)
							}
						/>
					)
				})}
		</div>
	)
}

/**
 * Displays one google class. Allows a user to select and deselect this google class.
 */
const GoogleClass = ({
	classId,
	className,
	addClass,
	removeClass,
	selected,
}: {
	classId: string,
	className: string,
	addClass: () => void,
	removeClass: () => void,
	selected: boolean,
}): React$Node => {
	const { success, error } = useContext(ThemeContext)

	return (
		<Dropdown
			className={classnames('border-2', selected ? 'border-success' : 'border-transparent')}
			header={
				<div className="w-full flex justify-between items-center">
					{className}
					<div className="pr-1 mr-2 border-r">
						<QuestionButtonAction
							Icon={selected ? FaMinus : FaPlus}
							questionColor={selected ? error : success}
							onClick={e => {
								e?.stopPropagation()
								if (!selected) addClass()
								else removeClass()
							}}
						/>
					</div>
				</div>
			}
			content={<StudentsWrapper classId={classId} />}
		/>
	)
}

/**
 * Wrapper around all of the students of the google class. This wrapper allows us to prevent loading the students
 * until the dropdown is open.
 */
const StudentsWrapper = ({ classId }: { classId: string }): React$Node => {
	const { data: googleClass, isLoading, isError, refetch } = useClass(classId, {
		statuses: [CLASS_STATUS.PENDING, CLASS_STATUS.WAS_ARCHIVED_NOW_PENDING],
	})
	return (
		<>
			{isLoading ? (
				<Centered>
					<StyledLoader />
				</Centered>
			) : isError ? (
				<ErrorOccurred>
					An error occurred while loading this class.{' '}
					<ErrorLinkButton
						onClick={e => {
							e.stopPropagation()
							refetch()
						}}>
						Retry
					</ErrorLinkButton>
				</ErrorOccurred>
			) : googleClass ? (
				<Students students={googleClass.students || []} />
			) : (
				<div>Something went wrong</div>
			)}
		</>
	)
}

const StyledLoader = styled(Loading)`
	max-width: 10vw;
`

const Centered = styled.div`
	display: flex;
	justify-content: center;
`
