// @flow
import React, { createContext, useContext, useEffect } from 'react'
import { NetworkCommunicator } from '../../services'
import config from '../../config'
import { INTERACTION_QUERY_KEY } from './userGuideHelpers'
import { useQueryClient, useQuery, useMutation } from 'react-query'
import { useUser } from '../../services/hooks'

export type InteractionLookups = {
	clickIds: { [string]: string },
	pathnames: { [string]: string },
}
type InteractionContextType = {
	lookups: InteractionLookups,
	sendClickInteraction: (id: string) => void,
}

const UserInteractionContext = createContext<InteractionContextType>({
	lookups: { clickIds: {}, pathnames: {} },
	sendClickInteraction: () => {},
})

const getInteractionQueryKey = (isUser: boolean) => {
	return ['interaction-constants', isUser.toString()]
}

/**
 * FileBrowserProvider - context used for the fileBrowser to easily show a file and folder structure to the user
 *
 * @param {{children:React$Node}} {children} - the children the context should be applied to (should have one FileBrowser component so structure can be shown the user)
 *
 * @return {React$Node} - the context provider
 */
export function UserInteractionProvider({ children }: { children: React$Node }): React$Node {
	const { user } = useUser()
	const { data: lookups = { clickIds: {}, pathnames: {} } } = useQuery(
		getInteractionQueryKey(Boolean(user)),
		() => {
			if (!user) return { clickIds: {}, pathnames: {} }
			return NetworkCommunicator.GET('/api/user-interactions/constants', {
				host: config.loginServer,
			})
				.then(response => {
					return {
						clickIds: response.clickIds || {},
						pathnames: response.pathnames || {},
					}
				})
				.catch(error => {
					console.error(
						`Failed to fetch interaction id constants from server, this will result in failure to log user interactions: ${error}`
					)
				})
		},
		{
			refetchOnWindowFocus: false,
			refetchOnMount: false,
			staleTime: Infinity,
		}
	)
	const queryCache = useQueryClient()
	const { mutate: sendClickInteraction } = useMutation(
		(id: string) => {
			if (!user) return Promise.resolve(null)
			return NetworkCommunicator.POST(`/api/user-interactions`, {
				host: config.loginServer,
				body: { service: 'DASHBOARD', type: 'CLICK', clickedId: id },
			})
		},
		{
			onSuccess: () => {
				queryCache.invalidateQueries(INTERACTION_QUERY_KEY)
			},
			onError: console.error,
		}
	)

	useEffect(() => {
		const callback = event => {
			if (event.target.getAttribute('data-user-interaction-id')) {
				const clickId = event.target.getAttribute('data-user-interaction-id')
				if (lookups.clickIds[clickId]) {
					sendClickInteraction(clickId)
				}
			}
		}
		window.addEventListener('click', callback)
		window.addEventListener('touch', callback)
		return () => {
			window.removeEventListener('click', callback)
			window.removeEventListener('touch', callback)
		}
	}, [lookups, sendClickInteraction])

	return (
		<UserInteractionContext.Provider value={{ lookups: lookups, sendClickInteraction }}>
			{children}
		</UserInteractionContext.Provider>
	)
}

export function useInteractionContext(): InteractionContextType {
	return useContext(UserInteractionContext)
}
export function useInteractionLookups(): InteractionLookups {
	return useInteractionContext().lookups
}
