import {
	HEADER_TO_CATEGORY,
	COLLABORATION,
	CRITICAL_THINKING,
	GRIT,
	INITIATIVE,
	TEACHER_ID,
	SCHOOL_ID,
	analyticsViewEnum,
} from '../constants'
import { forIn, values, meanBy, filter, round, isFinite } from 'lodash'
import type { ClassStudentAnalytics, StudentAnalytics } from '@mission.io/mission-toolkit'
import type {
	SelScores,
	AnalyticsObject,
	DisplayAnalytics,
	SelScoresByEntityId,
	ProficiencyTableEntry,
} from '../../../models/Analytics'
import type { NameLookup } from '../../../download/questions'
import { getName } from '../../../utility/helpers'
import { formatDateForCSV } from './csvGenerator'

/**
 * Find the highest version an analytics data array has
 * Default to one, because 1 is the original version
 */
function getHighestAnalyticsVersion(analyticsData: AnalyticsObject[]): number {
	return Math.max(1, ...analyticsData.map(analyticsObject => analyticsObject.version))
}

/**
 * Takes an array of { [id: string]: SelScores } and averages them into a single selScore per id.
 */
export function averageSelScores(selScores: Array<SelScoresByEntityId>): SelScoresByEntityId {
	const cumulativeScores = {}

	// for each selScore in our array
	selScores.forEach(individualSelScore => {
		// for each id in the selScore object
		Object.keys(individualSelScore).forEach(id => {
			// create or get the object to keep a running total of all scores and occurrences for an id
			let cumulativeScore = {
				totalQuestions: 0,
				totalQuestionsScore: 0,
				totalApplications: 0,
				totalApplicationScore: 0,
				totalCollaborations: 0,
				totalCollaborationScore: 0,
				totalCriticalThinking: 0,
				totalCriticalThinkingScore: 0,
				totalGrit: 0,
				totalGritScore: 0,
				totalInitiatives: 0,
				totalInitiativeScore: 0,
			}
			if (cumulativeScores[id]) {
				cumulativeScore = cumulativeScores[id]
			} else {
				cumulativeScores[id] = cumulativeScore
			}

			const { knowledge, skills, disposition } = individualSelScore[id]
			// if there is a knowledge score, check and add questions and application
			if (knowledge) {
				const { questions, application } = knowledge
				if (isFinite(questions)) {
					cumulativeScore.totalQuestions++
					cumulativeScore.totalQuestionsScore += questions
				}
				if (isFinite(application)) {
					cumulativeScore.totalApplications++
					cumulativeScore.totalApplicationScore += application || 0
				}
			}

			// if there is a skills score, check and add collaboration and criticalThinking
			if (skills) {
				const { collaboration, criticalThinking } = skills
				if (isFinite(collaboration)) {
					cumulativeScore.totalCollaborations++
					cumulativeScore.totalCollaborationScore += collaboration
				}
				if (isFinite(criticalThinking)) {
					cumulativeScore.totalCriticalThinking++
					cumulativeScore.totalCriticalThinkingScore += criticalThinking
				}
			}

			// if there is a disposition score, check and add grit and initiative
			if (disposition) {
				const { grit, initiative } = disposition
				if (isFinite(grit)) {
					cumulativeScore.totalGrit++
					cumulativeScore.totalGritScore += grit
				}
				if (isFinite(initiative)) {
					cumulativeScore.totalInitiatives++
					cumulativeScore.totalInitiativeScore += initiative
				}
			}
		})
	})

	const averagedScores = {}
	// once we have stored all the selValues per id, average them up
	Object.keys(cumulativeScores).forEach(id => {
		const {
			totalQuestions,
			totalQuestionsScore,
			totalApplications,
			totalApplicationScore,
			totalCollaborations,
			totalCollaborationScore,
			totalCriticalThinking,
			totalCriticalThinkingScore,
			totalGrit,
			totalGritScore,
			totalInitiatives,
			totalInitiativeScore,
		} = cumulativeScores[id]

		const round = value => Math.round(value * 100) / 100
		// knowledge
		let knowledge: { questions: ?number, application: ?number } = {
			questions: null,
			application: null,
		}
		if (totalQuestions > 0) {
			knowledge.questions = round(totalQuestionsScore / totalQuestions)
		}
		if (totalApplications > 0) {
			knowledge.application = round(totalApplicationScore / totalApplications)
		}

		// skills
		let skills: { collaboration: ?number, criticalThinking: ?number } = {
			collaboration: null,
			criticalThinking: null,
		}
		if (totalCollaborations > 0) {
			skills.collaboration = round(totalCollaborationScore / totalCollaborations)
		}
		if (totalCriticalThinking > 0) {
			skills.criticalThinking = round(totalCriticalThinkingScore / totalCriticalThinking)
		}

		// disposition
		let disposition: { grit: ?number, initiative: ?number } = { grit: null, initiative: null }
		if (totalGrit > 0) {
			disposition.grit = round(totalGritScore / totalGrit)
		}
		if (totalInitiatives > 0) {
			disposition.initiative = round(totalInitiativeScore / totalInitiatives)
		}
		averagedScores[id] = { knowledge, skills, disposition }
	})
	return averagedScores
}

/**
 * A wrapper function to check if a value is a number
 */

function isANumber(value: ?number): boolean %checks {
	return (
		typeof value === 'number' && value != null && Infinity !== Math.abs(value) && !isNaN(value)
	)
}

/**
 * Takes in a student's analytics object, the current weights we are using to
 * score analytics, and the type of analytics we are checking. It then calculates
 * the score for the specified type using the passed in value and the weight percentages.
 * Returns null if no score available, or the weighted score.
 */
function calculateWeightedScore(
	studentAnalytics: StudentAnalytics,
	type: string,
	weights?: ?{ [category: string]: { [subCategory: string]: number } }
): ?number {
	const category = HEADER_TO_CATEGORY[type]
	if (studentAnalytics[category] && studentAnalytics[category][type]) {
		const items = studentAnalytics[category][type]
		let total = 0
		let totalWeight = 0
		forIn(items, (value, key) => {
			const weight = weights?.[type]?.[key] ?? 1
			if (isFinite(value)) {
				total += value * weight
				totalWeight += weight
			} else {
				if (value != null && isFinite(value.score)) {
					total += value.score * weight
					totalWeight += weight
				}
			}
		})
		const score = total / totalWeight
		return isFinite(score) ? score * 100 : null
	}
	return null
}

export function getSelScoresForStudent(
	classStudentAnalytics: ClassStudentAnalytics,
	studentId: string
): SelScores {
	const weights = classStudentAnalytics.weights

	const studentAnalytics = classStudentAnalytics.studentAnalytics?.[studentId]
	if (studentAnalytics) {
		const questionsScore = studentAnalytics.knowledge?.questions
		const applicationScore = studentAnalytics.knowledge?.application
		const collaborationScore = calculateWeightedScore(studentAnalytics, COLLABORATION, weights)
		const criticalThinkingScore = calculateWeightedScore(
			studentAnalytics,
			CRITICAL_THINKING,
			weights
		)
		const gritScore = calculateWeightedScore(studentAnalytics, GRIT, weights)
		const initiativeScore = calculateWeightedScore(studentAnalytics, INITIATIVE, weights)
		const selScores: SelScores = {
			/**
			 *
			 * ONLY CHANGE THESE IF YOU ARE MAKING CHANGES TO THE MISSION SERVER ANALYTICS CREATION
			 *
			 */
			knowledge: {
				questions: isANumber(questionsScore) ? round(questionsScore * 100) : null,
				application: isANumber(applicationScore) ? round(applicationScore * 100) : null,
			},
			skills: {
				collaboration: isANumber(collaborationScore) ? round(collaborationScore) : null,
				criticalThinking: isANumber(criticalThinkingScore)
					? round(criticalThinkingScore)
					: null,
			},
			disposition: {
				grit: isANumber(gritScore) ? round(gritScore) : null,
				initiative: isANumber(initiativeScore) ? round(initiativeScore) : null,
			},
		}
		return selScores
	} else {
		return {
			knowledge: {
				questions: null,
				application: null,
			},
			skills: {
				collaboration: null,
				criticalThinking: null,
			},
			disposition: {
				grit: null,
				initiative: null,
			},
		}
	}
}

/**
 * Takes in an analytics object for a mission, and creates an SelScores object
 * for each student in a mission.
 */
function getStudentSelScores(classStudentAnalytics: ?ClassStudentAnalytics): SelScoresByEntityId {
	if (!classStudentAnalytics) {
		return {}
	}
	const studentIds = Object.keys(classStudentAnalytics?.studentAnalytics || {})
	let studentSelScores: SelScoresByEntityId = {}
	studentIds.forEach(studentId => {
		studentSelScores[studentId] = {
			...getSelScoresForStudent(classStudentAnalytics, studentId),
			nameId: studentId,
		}
	})
	return studentSelScores
}

/**
 * Takes in an idMap of id to SelScores objects, and combines it into a singular
 * SelScores object by getting the average of each available value.
 */
function getCombinedSelScores(studentSelScores: SelScoresByEntityId): SelScores {
	const theSelScores = values(studentSelScores)
	const questionScore = round(
		meanBy(
			filter(theSelScores, (selScore: SelScores) => isFinite(selScore.knowledge.questions)),
			(selScore: SelScores) => selScore.knowledge.questions
		)
	)
	const applicationScore = round(
		meanBy(
			filter(theSelScores, (selScore: SelScores) => isFinite(selScore.knowledge.application)),
			(selScore: SelScores) => selScore.knowledge.application
		)
	)
	const collaborationScore = round(
		meanBy(
			filter(theSelScores, (selScore: SelScores) => isFinite(selScore.skills.collaboration)),
			(selScore: SelScores) => selScore.skills.collaboration
		)
	)
	const ctScore = round(
		meanBy(
			filter(theSelScores, (selScore: SelScores) =>
				isFinite(selScore.skills.criticalThinking)
			),
			(selScore: SelScores) => selScore.skills.criticalThinking
		)
	)
	const gritScore = round(
		meanBy(
			filter(theSelScores, (selScore: SelScores) => isFinite(selScore.disposition.grit)),
			(selScore: SelScores) => selScore.disposition.grit
		)
	)
	const initiativeScore = round(
		meanBy(
			filter(theSelScores, (selScore: SelScores) =>
				isFinite(selScore.disposition.initiative)
			),
			(selScore: SelScores) => selScore.disposition.initiative
		)
	)
	return {
		knowledge: {
			questions: isFinite(questionScore) ? questionScore : null,
			application: isFinite(applicationScore) ? applicationScore : null,
		},
		skills: {
			collaboration: isFinite(collaborationScore) ? collaborationScore : null,
			criticalThinking: isFinite(ctScore) ? ctScore : null,
		},
		disposition: {
			grit: isFinite(gritScore) ? gritScore : null,
			initiative: isFinite(initiativeScore) ? initiativeScore : null,
		},
	}
}

type FormattedAnalyticsData = {|
	csvGroupName: string, // describes how the analytics are related, used for csv file naming
	...
		| {|
				// when type is 'MISSION', `display` is the `DisplayAnalytics` for that mission
				type: 'MISSION',
				display: DisplayAnalytics,
		  |}
		| {|
				// when type is something else, add the new `proficiencyTableEntries` which has all of the info to
				// display in the proficiency table
				type: 'DISTRICT' | 'ADMIN' | 'TEACHER',
				proficiencyTableEntries: Array<ProficiencyTableEntry>,
				// The analytics objects with only the info necessary for the `type`. The `display` property exists so
				// that old functionality still works, but could be removed eventually because it is kind of weird
				display: Array<DisplayAnalytics>,
		  |},
|}

/**
 * Determines how to combine and process the analytics based on role and other selected dropdown fields on the main analytics page.
 * Once we have finished processing, we sort by time.
 */
export function formatAnalyticsForDisplay({
	isDistrictAdmin,
	isSchoolAdmin,
	isSpecificSchool,
	teacherViewSpecified,
	analyticsData,
	userId,
	nameLookup,
	currentUser,
}: {
	isDistrictAdmin: boolean,
	isSpecificSchool: boolean,
	isSchoolAdmin: boolean,
	teacherViewSpecified: boolean,
	analyticsData: AnalyticsObject[],
	userId: ?string,
	nameLookup: NameLookup,
	currentUser: { firstName: ?string, lastName: ?string, schoolName: ?string, id: ?string },
}): FormattedAnalyticsData {
	const isSpecificMission = analyticsData.length === 1
	if (isDistrictAdmin) {
		if (isSpecificMission) {
			return {
				type: analyticsViewEnum.MISSION,
				...createAnalyticsForSingleMission(analyticsData[0], nameLookup),
			}
		}
		if (teacherViewSpecified) {
			//  teacher view
			return {
				type: analyticsViewEnum.TEACHER,
				...createAnalyticsForTeacher(analyticsData, nameLookup),
			}
		}
		if (isSpecificSchool) {
			// SchoolAdmin main view
			return {
				type: analyticsViewEnum.ADMIN,
				...createAnalyticsForAdmin(analyticsData, TEACHER_ID, nameLookup),
			}
		}

		//  DistrictAdmin main view
		return {
			type: analyticsViewEnum.DISTRICT,
			...createAnalyticsForAdmin(analyticsData, SCHOOL_ID, nameLookup),
		}
	} else if (isSchoolAdmin) {
		if (isSpecificMission) {
			//  singular mission view
			return {
				type: analyticsViewEnum.MISSION,
				...createAnalyticsForSingleMission(analyticsData[0], nameLookup),
			}
		}
		if (teacherViewSpecified) {
			//  teacher main view
			return {
				type: analyticsViewEnum.TEACHER,
				...createAnalyticsForTeacher(analyticsData, nameLookup),
			}
		}
		//  SchoolAdmin main view
		const schoolAnalytics = createAnalyticsForAdmin(analyticsData, TEACHER_ID, nameLookup)
		return {
			type: analyticsViewEnum.ADMIN,
			...schoolAnalytics,
			csvGroupName: currentUser.schoolName ?? schoolAnalytics.csvGroupName,
		}
	} else {
		if (isSpecificMission) {
			//  singular mission view
			return {
				type: analyticsViewEnum.MISSION,
				...createAnalyticsForSingleMission(analyticsData[0], nameLookup),
			}
		}

		//  teacher main view (also happens when the user is a flight director)
		const nameLookupWithCurrentUserAdded = { ...nameLookup }
		if (currentUser.id != null) {
			// nameLookup is not populated with the teacher when the current user only has a teacher role
			nameLookupWithCurrentUserAdded[currentUser.id] = {
				firstName: currentUser.firstName ?? 'Unknown',
				lastName: currentUser.lastName ?? 'Unknown',
			}
		}
		return {
			type: analyticsViewEnum.TEACHER,
			...createAnalyticsForTeacher(
				analyticsData,
				nameLookupWithCurrentUserAdded,
				currentUser.id
			),
		}
	}
}

/**
 * Finds a single analyticsObject by a specified missionId and returns one DisplayAnalytics object,
 * else it returns an empty array.
 */
export function createAnalyticsForSingleMission(
	analytics: AnalyticsObject,
	nameLookup: NameLookup
): {| display: DisplayAnalytics, csvGroupName: string |} {
	const individualSelScores = getStudentSelScores(analytics.analytics)
	const combinedSelScores = getCombinedSelScores(individualSelScores)
	const csvGroupName = `${formatDateForCSV(
		new Date(analytics.createdTime)
	)} ${analytics.missionName ?? 'Unknown Mission'}`

	return {
		display: {
			classId: analytics.classId,
			teacherId: analytics.teacherId,
			teacherName: analytics.teacherId ? nameLookup[analytics.teacherId] : undefined,
			simulationId: analytics.simulationId,
			schoolId: analytics.schoolId,
			schoolName: analytics.schoolName,
			individualSelScores,
			combinedSelScores,
			time: new Date(analytics.createdTime),
			version: analytics.version,
		},

		csvGroupName,
	}
}

/**
 * For each analytics object, create a DisplayAnalytics object and return it all.
 * Since a teacher wants to see all their missions specified, we do not need to worry about grouping
 * missions by time, but to make the chart read left to right, we do need to sort them.
 */
function createAnalyticsForTeacher(
	analyticsData: AnalyticsObject[],
	nameLookup: NameLookup,
	defaultTeacherId: ?string
): {|
	display: DisplayAnalytics[],
	csvGroupName: string,
	proficiencyTableEntries: Array<ProficiencyTableEntry>,
|} {
	let returnArray: Array<DisplayAnalytics> = []
	let seenTeachers = {}
	analyticsData.forEach(analyticsObject => {
		const individualSelScores = getStudentSelScores(analyticsObject.analytics)

		returnArray.push({
			classId: analyticsObject.classId,
			teacherId: analyticsObject.teacherId,
			teacherName: analyticsObject.teacherId
				? nameLookup[analyticsObject.teacherId]
				: undefined,
			simulationId: analyticsObject.simulationId,
			schoolId: analyticsObject.schoolId,
			schoolName: analyticsObject.schoolName,
			individualSelScores,
			combinedSelScores: getCombinedSelScores(individualSelScores),
			time: new Date(analyticsObject.createdTime),
			version: getHighestAnalyticsVersion(analyticsData),
		})
		const teacherId = analyticsObject.teacherId ?? defaultTeacherId // teacherIds are not set on analytics objects when the user only has the teacher role
		if (teacherId && nameLookup[teacherId] && !seenTeachers[teacherId]) {
			seenTeachers[teacherId] = nameLookup[teacherId]
		}
	})

	const teachers = Object.keys(seenTeachers).map(teacherId => seenTeachers[teacherId])

	return {
		display: returnArray.sort((a, b) => a.time - b.time),
		csvGroupName: teachers.length
			? // $FlowExpectedError[prop-missing] ListFormat was introduced in 2021
			  new Intl.ListFormat(undefined, {
					style: 'long',
					type: 'conjunction',
			  }).format(
					teachers
						.map(teacher => getName(teacher?.firstName, teacher?.lastName) ?? '')
						.filter(str => str)
			  )
			: 'Unknown Teacher',
		proficiencyTableEntries: getProficiencyTableEntries({
			displayAnalytics: returnArray,
			getEntityInfo: d =>
				Object.keys(d.individualSelScores).map(studentId => {
					const nameObject = nameLookup[studentId]
					return {
						id: studentId,
						name: nameObject
							? `${nameObject.lastName}, ${nameObject.firstName}`
							: 'Unknown',
					}
				}),
			entityType: 'STUDENT',
		}),
	}
}

/**
 * Takes the analytics array and returns the corresponding DisplayAnalytics.
 * The ONLY different between what is returned here and what is returned in createAnalyticsForTeacher, is
 * the individualSelScore - instead of including scores for each and every student - the admin only has access to
 * a combined score.
 *
 * `individualSelScores` for each analytics here is returned as an object with 1 key - the teacherId or school Name
 * of the analytics.
 */
function createAnalyticsForAdmin(
	analyticsData: AnalyticsObject[],
	propertyForEntityId: 'teacherId' | 'schoolId',
	nameLookup: NameLookup
): {|
	display: DisplayAnalytics[],
	csvGroupName: string,
	proficiencyTableEntries: Array<ProficiencyTableEntry>,
|} {
	let returnArray: Array<DisplayAnalytics> = []
	let schoolName = null
	analyticsData.forEach(analyticsObject => {
		const entityId = analyticsObject[propertyForEntityId]
		if (!entityId) return
		const combinedSelScores = getCombinedSelScores(
			getStudentSelScores(analyticsObject.analytics)
		)
		const version = getHighestAnalyticsVersion(analyticsData)
		returnArray.push({
			classId: analyticsObject.classId,
			simulationId: analyticsObject.simulationId,
			schoolId: analyticsObject.schoolId,
			schoolName: analyticsObject.schoolName,
			teacherId: analyticsObject.teacherId,
			teacherName: analyticsObject.teacherId
				? nameLookup[analyticsObject.teacherId]
				: undefined,
			individualSelScores: { [entityId]: combinedSelScores },
			combinedSelScores,
			time: new Date(analyticsObject.createdTime),
			version,
		})
		schoolName ??= analyticsObject.schoolName
	})

	return {
		display: returnArray.sort((a, b) => a.time - b.time),
		csvGroupName: schoolName ?? 'Unknown School',
		proficiencyTableEntries: getProficiencyTableEntries({
			displayAnalytics: returnArray,
			getEntityInfo: displayAnalytics => {
				const entityId = displayAnalytics[propertyForEntityId]
				if (!entityId) {
					return []
				}

				return [
					{
						id: entityId,
						name: (() => {
							if (propertyForEntityId === 'schoolId') {
								return displayAnalytics.schoolName || 'Unknown'
							}

							if (!displayAnalytics.teacherName) {
								return 'Unknown'
							}

							return `${displayAnalytics.teacherName.lastName}, ${displayAnalytics.teacherName.firstName}`
						})(),
					},
				]
			},
			entityType: propertyForEntityId === 'schoolId' ? 'SCHOOL' : 'TEACHER',
		}),
	}
}

/**
 * Gets the average proficiency scores for each entity represented in `individualSelScores` of the `displayAnalytics`.
 * Each of those entities will have an entry in the returned array.
 *
 * @param displayAnalytics - The analytics to get the proficiency scores from
 * @param getEntityInfo - A function that takes a `DisplayAnalytics` object and returns an array of entity info objects that
 * 					  represent the entities in the `individualSelScores` of the `DisplayAnalytics` object.
 * @param entityType - The type of entity that the proficiency scores are for
 */
function getProficiencyTableEntries({
	displayAnalytics,
	getEntityInfo,
	entityType,
}: {
	displayAnalytics: Array<DisplayAnalytics>,
	getEntityInfo: (d: DisplayAnalytics) => Array<{ id: string, name: string }> | null,
	entityType: 'SCHOOL' | 'TEACHER' | 'STUDENT',
}): Array<ProficiencyTableEntry> {
	const individualSelScores: Array<SelScoresByEntityId> = []
	const entityIdToName = {}
	displayAnalytics.forEach(d => {
		individualSelScores.push(d.individualSelScores)
		const entityInfo = getEntityInfo(d)
		if (entityInfo) {
			entityInfo.forEach(({ id, name }) => {
				entityIdToName[id] = name
			})
		}
	})
	const averageSelScoresByEntityId = averageSelScores(individualSelScores)

	return Object.keys(averageSelScoresByEntityId).map(entityId => {
		const selScores = averageSelScoresByEntityId[entityId]
		return {
			type: entityType,
			name: entityIdToName[entityId] || 'Unknown',
			id: entityId,
			grit: selScores.disposition.grit,
			initiative: selScores.disposition.initiative,
			application: selScores.knowledge.application,
			questions: selScores.knowledge.questions,
			collaboration: selScores.skills.collaboration,
			criticalThinking: selScores.skills.criticalThinking,
		}
	})
}
