import React, { useState, useEffect } from 'react'
import { Tooltip } from 'react-tooltip'
import { toast } from 'react-toastify'
import styled from 'styled-components/macro'
import CustomModal, {
	ModalHeader,
	ModalBody,
	ModalFooter,
	ExitX,
} from '../../../components/modal/Modal'
import { Button } from '@mission.io/styles'
import Plus from 'react-icons/lib/go/plus'
import CSVEmailModal from './CSVEmailModal'
import { getEmailsFromString } from '../helpers'
import { inviteUsersToLicense } from '../LicenseNetworkRoutes'
import { config } from '../../../config'
import { Input } from '../../../components/inputs/Input'

type Props = { isOpen: boolean, onRequestClose: () => void }

/**
 * This modal allows a user to enter in emails, or upload emails from a CSV,
 * and then invites those emails to join the the user's license.
 */
export default function InviteTeacherModal({ isOpen, onRequestClose }: Props): React$Node {
	const [showCSVModal, setShowCSVModal] = useState(false)
	const [newEmailString, setNewEmailString] = useState('')
	const [validEmails, setValidEmails] = useState(new Set())
	const [invalidInput, setInvalidInput] = useState()

	useEffect(() => {
		if (newEmailString === '' && invalidInput) {
			setInvalidInput(false)
		}
	}, [newEmailString, invalidInput])

	const onEmailSubmit = () => {
		const [possibleEmails, invalidEmailsStr] = getEmailsFromString(newEmailString)
		if (possibleEmails) {
			const duplicateFreeEmails = new Set(possibleEmails)
			setValidEmails(oldEmailSet => {
				return new Set([...oldEmailSet, ...duplicateFreeEmails])
			})
			setNewEmailString(invalidEmailsStr || '')
		}
		if (invalidEmailsStr) {
			setInvalidInput(true)
		}
	}

	const showSuccessToast = (isSuccess: boolean) => {
		if (isSuccess) {
			toast.success('Emails sent!', {
				position: toast.POSITION.TOP_RIGHT,
			})
		} else {
			toast.error('Failed to send invite to listed emails.', {
				position: toast.POSITION.TOP_RIGHT,
			})
		}
	}

	const processFailedEmails = (failedEmails: string[] = []) => {
		if (failedEmails.length > 0) {
			toast.warn(
				<div>
					We were unable to send emails to these emails:
					<ToastEmails>
						{failedEmails.map(email => (
							<div key={email}>{email}</div>
						))}
					</ToastEmails>
				</div>,
				{
					position: toast.POSITION.TOP_RIGHT,
					autoClose: false,
				}
			)
		}
	}

	return (
		<>
			<CustomModal
				aria={{
					labelledby: 'InviteTeacherModalHeader',
				}}
				style={{ content: { width: '40%' }, overlay: { top: '5%' } }}
				isOpen={isOpen}
				shouldCloseOnOverlayClick
				onRequestClose={onRequestClose}>
				<ModalHeader id="InviteTeacherModalHeader">
					Inviting Teachers
					<ExitX
						role={'button'}
						title={'Close'}
						aria-label={'Close Invite Teachers Dialog'}
						onClick={onRequestClose}
					/>
				</ModalHeader>
				<ModalBody>
					<form
						onSubmit={e => {
							e.preventDefault()
							onEmailSubmit()
						}}>
						<label htmlFor="email-input-field">
							Add emails of educators that you want to add to your{' '}
							{config.companyName.base}
							License. You can insert multiple emails if they are separated by spaces
							or commas.
						</label>
						<div className="flex flex-row gap-0">
							<Input
								invalid={invalidInput}
								type="textarea"
								id="email-input-field"
								placeholder={'example@email.com, test@email.com ...'}
								className="px-2"
								onChange={e => {
									setNewEmailString(e.target.value)
								}}
								value={newEmailString}
							/>
							<PlusSvg
								className="-ml-1"
								role={'button'}
								title={'Add Email to Invite List'}
								aria-label={'Add Email to Invite List'}
								onClick={onEmailSubmit}
							/>
						</div>
					</form>

					<EmailDisplay aria-label={'current-email-list'}>
						{[...validEmails].map(email => (
							<div
								key={email}
								className="border-b border-b-solid border-b-[--app-gray] p-2 text-base font-normal w-[95%] flex justify-between">
								<div>{email}</div>
								<StyledX
									role={'button'}
									onClick={() =>
										setValidEmails(emails => {
											const newSet = new Set(emails)
											newSet.delete(email)
											return newSet
										})
									}
								/>
							</div>
						))}
					</EmailDisplay>
				</ModalBody>
				<StyledModalFooter>
					<Button
						data-tooltip-id={`tooltipInfo`}
						outline
						onClick={() => {
							setShowCSVModal(true)
						}}>
						CSV Upload
					</Button>
					<Tooltip id="tooltipInfo" effect="solid" globalEventOff="click">
						To upload a CSV file, make sure there is a column header labeled “email”
					</Tooltip>
					<div>
						<Button className="mr-2" outline onClick={onRequestClose}>
							Cancel
						</Button>
						<Button
							disabled={validEmails.size === 0 || newEmailString.length > 0}
							onClick={() => {
								inviteUsersToLicense({
									emails: [...validEmails],
									onSuccess: () => {
										showSuccessToast(true)
										setValidEmails(new Set())
										onRequestClose()
									},
									onFailedEmails: (failedEmails: string[]) => {
										processFailedEmails(failedEmails)
										setValidEmails(new Set(failedEmails))
										onRequestClose()
									},
									onError: () => showSuccessToast(false),
								})
							}}>
							Invite
						</Button>
					</div>
				</StyledModalFooter>
			</CustomModal>
			<CSVEmailModal
				isOpen={showCSVModal}
				onComplete={csvEmails =>
					setValidEmails(currentEmails => {
						const newSet = new Set(currentEmails)
						csvEmails.forEach(csvEmail => newSet.add(csvEmail))
						return newSet
					})
				}
				onRequestClose={() => setShowCSVModal(false)}
			/>
		</>
	)
}

/**
 * A modal that will invite all emails in `emails` to the current user's license when the user clicks the send button.
 * @param props
 * @param props.isOpen
 * @param props.emails All email addresses to which an invitation should be sent
 * @param props.onRequestClose A function called when the user requests to close the modal
 * @param props.children The contents of the modal. Should be a message to the user letting them know what will happen when they press send
 * @param props.resend Whether or not the intent is to resend emails to `emails`. This changes some text, as well as what is considered a failure
 */
export function EmailInvitationModal({
	isOpen,
	emails,
	onRequestClose,
	children,
	resend,
}: {
	isOpen: boolean,
	emails: string[],
	onRequestClose: () => void,
	children: React$Node,
	resend?: boolean,
}): React$Node {
	return (
		<CustomModal
			style={{ content: { width: '40%', top: '20%' } }}
			isOpen={isOpen}
			shouldCloseOnOverlayClick
			onRequestClose={onRequestClose}>
			<ModalHeader>
				{resend ? 'Resend' : 'Send'} License Invitation
				<ExitX onClick={onRequestClose} />
			</ModalHeader>
			<ModalBody css="max-height: 50vh; overflow-y: auto;">{children}</ModalBody>
			<ModalFooter>
				<Button
					css="margin-right: var(--spacing1x-dont-use);"
					outline
					onClick={() => {
						inviteUsersToLicense({
							emails: emails,
							onSuccess: res => {
								let emailsFailedToSend: Set<string>
								if (resend) {
									emailsFailedToSend = new Set(emails)
									if (res.resentEmails) {
										res.resentEmails.forEach(email =>
											emailsFailedToSend.delete(email)
										)
									}
								} else {
									emailsFailedToSend = new Set(res.failedEmails)
								}
								if (!emailsFailedToSend.size) {
									toast.success(
										`${resend ? 'Resent' : 'Sent'} email${
											emails.length > 1 ? 's' : ''
										}`
									)
								} else {
									toast.error(
										`Failed to ${resend ? 'resend' : 'send'} email${
											emailsFailedToSend.size > 1 ? 's' : ''
										} to: ${Array.from(emailsFailedToSend).join('\n')}`
									)
								}
							},
							onError: () => {
								toast.error(
									`Failed to ${resend ? 'resend' : 'send'} email${
										emails.length > 1 ? 's' : ''
									}`
								)
							},
						})
						onRequestClose()
					}}>
					Send {resend ? 'Another ' : ''}Invitation
				</Button>
				<Button className="mr-2" onClick={onRequestClose}>
					Cancel
				</Button>
			</ModalFooter>
		</CustomModal>
	)
}

const EmailDisplay = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	overflow-y: auto;
	max-height: 30vh;
`

const StyledX = styled(ExitX)`
	${({ theme }) => `
		color: ${theme.neutralDark};
		:hover{
			color: ${theme.neutral};
			padding-bottom: 2px;
		}
	`}
`

const PlusSvg = styled(Plus)`
	${({ theme }) => `
		background: ${theme.primary};
		color: ${theme.white};
		:hover {
			border: 2px solid ${theme.neutralLight};
			background-color: ${theme.neutralLight};
		}
	`}
	height: 2em;
	width: 2em;
	border-radius: 4px;
	cursor: pointer;
`

const StyledModalFooter = styled(ModalFooter)`
	justify-content: space-between;
`

const ToastEmails = styled.div`
	overflow-y: auto;
	height: 15vh;
`
