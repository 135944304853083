import React, { useState } from 'react'
import { Button } from '@mission.io/styles'
import { useQueryParam, ArrayParam } from 'use-query-params'
import { useAnalyticsForMission } from '../../hooks'
import MissionGuideModal, { useMissionGuide } from '../../../../components/missionGuideModal'

// Makes sure there is a mission id in the url that we can use to access the mission guide
export default function Validated(props: { disabled?: boolean, pdfId?: string }): React$Node {
	const [missions] = useQueryParam('missions', ArrayParam)
	let skip = !missions || missions.length === 0
	return skip ? null : <MissionGuideButton {...props} missionId={missions[0]} />
}

/**
 * Button that will open mission guide
 * @param {{missionId: string, pdfId: string}} props
 * @returns
 */
function MissionGuideButton({
	missionId,
	pdfId,
	...props
}: {
	missionId: string,
	pdfId?: string,
	disabled?: boolean,
}): React$Node {
	const simulationId = useAnalyticsForMission(missionId)?.simulationId
	const { data: missionGuide, isLoading } = useMissionGuide(simulationId)
	const [openMissionGuide, setOpenMissionGuide] = useState(false)

	return simulationId ? (
		<>
			<Button
				{...props}
				disabled={props.disabled ? true : !missionGuide}
				onClick={() => setOpenMissionGuide(true)}>
				{isLoading
					? 'Loading...'
					: missionGuide
					? 'Mission Guide'
					: 'Resources Coming Soon'}
			</Button>
			<MissionGuideModal
				simulationId={simulationId}
				isOpen={openMissionGuide}
				onRequestClose={() => setOpenMissionGuide(false)}
				pageId={pdfId ?? undefined}
			/>
		</>
	) : null
}
