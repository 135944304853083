// @flow
import React, { Component } from 'react'
import { Button } from '@mission.io/styles'
import {
	ModalHeader as Header,
	ExitX,
	ModalBody as Body,
	ModalFooter as Footer,
} from '../modal/Modal'
import {
	CUSTOM_CLASS_NAME_ACTIONABLE_TOAST,
	CUSTOM_CLASS_NAME_ACTIONABLE_TOAST_BODY,
} from '../../constants'

type Props = {
	message: string,
	confirmText?: string,
	title?: string,
	cancelText?: string,
	onConfirm: Function,
	onCancel?: Function,
	secondButton?: boolean,
}
/*
Simple Confirmation

Props:
	message: a string message, such as "Are you sure you want to delete this class?",
	confirmText: the text to be displayed on the confirm button,
	cancelText: the text to be displayed on the cancel button,
	onConfirm: the confirmation callback,
	onCancel: the cancel callback

Functionality:
	a simple confirmation component to be plugged ideally into a toast or modal popup.
	Can/should be used to get simple yes/no submit/cancel feedback from a user on an action.
*/

export default class SimpleConfirmation extends Component<Props> {
	static defaultProps: {| secondButton: boolean |} = {
		secondButton: true,
	}

	render(): React$Node {
		return (
			<div className="bg-primary-800">
				<Header>
					{this.props.title || this.props.confirmText || 'Confirm'}
					<ExitX onClick={this.props.onCancel} />
				</Header>
				<Body>{this.props.message}</Body>
				<Footer>
					{this.props.secondButton && (
						<Button outline onClick={this.props.onCancel}>
							{this.props.cancelText || 'Cancel'}
						</Button>
					)}
					<Button onClick={this.props.onConfirm}>
						{this.props.confirmText || 'Confirm'}
					</Button>
				</Footer>
			</div>
		)
	}
}

/**
 * Properties for react.toast when the SimpleConfirmation component is used as a toast component.
 * Usage: toast.info(<SimpleConfirmation ... />, ACTIONABLE_TOAST_PROPERTIES)
 */
export const ACTIONABLE_TOAST_PROPERTIES = {
	icon: false,
	className: CUSTOM_CLASS_NAME_ACTIONABLE_TOAST,
	bodyClassName: CUSTOM_CLASS_NAME_ACTIONABLE_TOAST_BODY,
	closeOnClick: false,
	autoClose: false,
	closeButton: false,
	draggable: false,
}
