// @flow
import React, { useState, useEffect, useRef, useMemo, useCallback } from 'react'
import { Link } from 'react-router-dom'
import MuxVideo from '@mux-elements/mux-video-react'
import FaPlay from 'react-icons/lib/fa/play'
import FaPause from 'react-icons/lib/fa/pause'
import styled from 'styled-components/macro'
import { animated, useTrail, useTransition } from 'react-spring'
import config from '../../config'
import { useScaledAspectRatio } from './../../utility/hooks'
import useUser from '../../services/hooks/user'
import {
	VIDEO,
	PATH_SELECT,
	type AutoDemo,
	type AutoDemoStep,
	type VideoAutoDemoStep,
	type PathSelectAutoDemoStep,
} from './types'
import { Button } from './shared'
import { StepProgress } from './StepProgress'
import { getOrderedPathSelectSteps } from './functions'

const INITIAL_STEP = 'INITIAL_STEP_CONSTANT'
const FINAL_STEP = 'FINAL_STEP_CONSTANT'

const AnimatedButton = animated(Button)

type SetStep = (stepId: string | void) => mixed
type FreezeFrameStep = { step: VideoAutoDemoStep, needsLoad: boolean }
type VideoInfo = { muxPlaybackId?: string, url: string }
type VisitedSteps = Array<?string>

/**
 * Hook used to interact with the current state of the auto demo.
 * @param {AutoDemo} autoDemo The data for the auto demo
 * @return {?AutoDemoStep} obj.currentStep - The current step the user is on in the auto demo
 * @return {(stepId) => mixed} obj.setStep - A function to call to move to a new step
 */
function useAutoDemoSteps(
	autoDemo: AutoDemo
): {
	currentStep: ?AutoDemoStep,
	freezeFrameStep: ?FreezeFrameStep,
	setStep: SetStep,
	visitedSteps: VisitedSteps,
} {
	const [currentStepId, setCurrentStepId] = useState<string | void>(autoDemo.initialStep)
	const [visitedSteps, setVisitedSteps] = useState<VisitedSteps>([autoDemo.initialStep])

	const currentStep = currentStepId ? autoDemo.steps[currentStepId] : undefined

	const previousStepId = visitedSteps.length > 1 ? visitedSteps[visitedSteps.length - 2] : null
	const previousStep: ?AutoDemoStep = previousStepId ? autoDemo.steps[previousStepId] : null

	// During PATH_SELECT steps we use the `freezeFrameStep` to know which video step to use for the freeze frame
	const freezeFrameStep: ?FreezeFrameStep = useMemo(() => {
		if (
			(!currentStep || currentStep.type === PATH_SELECT) &&
			previousStep &&
			previousStep.type === VIDEO &&
			// It's possible for stepIds to be undefined/null, so we need to use `==`
			// eslint-disable-next-line eqeqeq
			previousStep.nextStepId == currentStep?._id
		) {
			// No need to look for the right video, we already have access to it.
			// This most often happens in the automatic progression, when progressing from video step to path select
			return { step: previousStep, needsLoad: false }
		}

		if (currentStep && currentStep.type !== PATH_SELECT) {
			return null
		}

		const previousVideoStep = findPreviousVideoStep(currentStep?._id, autoDemo)
		return previousVideoStep && { step: previousVideoStep, needsLoad: true }
	}, [autoDemo, currentStep, previousStep])

	return {
		currentStep,
		freezeFrameStep,
		setStep: (stepId: string | void) => {
			setCurrentStepId(stepId)
			setVisitedSteps(visitedSteps => {
				return [...visitedSteps, stepId]
			})
		},
		visitedSteps,
	}
}

/**
 * Finds a single video step in `autoDemo` that comes immediately before `pathSelectStepId`
 */
function findPreviousVideoStep(
	pathSelectStepId: string | void,
	autoDemo: AutoDemo
): ?VideoAutoDemoStep {
	for (const stepId of Object.keys(autoDemo.steps)) {
		const step = autoDemo.steps[stepId]
		// We want to use == to compare null and undefined
		// eslint-disable-next-line eqeqeq
		if (step && step.type === VIDEO && step.nextStepId == pathSelectStepId) {
			return step
		}
	}
}

/**
 * Gets the id of the most recently visited path select step. If the initial step was visited more recently than any path select
 * step, or if none of the path select steps have been visited, returns null.
 * @param {PathSelectAutoDemoStep[]} orderedPathSelectSteps All path select steps in order
 * @param {string[]} visitedStepsArray An array of all visited steps so far
 * @return {string | null} The id of the most recently visited path select step
 */
function getCurrentPathSelectStepId(
	autoDemo: AutoDemo,
	visitedStepsArray: VisitedSteps
): string | null {
	let currentPathSelectStepId = null
	visitedStepsArray.forEach(stepId => {
		if (stepId == null) {
			currentPathSelectStepId = null
			return
		}
		if (autoDemo.steps[stepId]?.type === PATH_SELECT) {
			currentPathSelectStepId = stepId
		}
		if (autoDemo.initialStep === stepId) {
			currentPathSelectStepId = null
		}
	})
	return currentPathSelectStepId
}

const FPS = 60 // Assuming 60 fps videos
const SINGLE_FRAME_LENGTH = 1 / FPS
// On 60 FPS videos, using SINGLE_FRAME_LENGTH will hypothetically take us to the last frame of the video. In testing, using SINGLE_FRAME_LENGTH caused it to not work
// properly on some videos, while numbers higher then SINGLE_FRAME_LENGTH * 2 also caused issues. I did not come across any problems when using SINGLE_FRAME_LENGTH * 2
const FINAL_FRAME_LENGTH = SINGLE_FRAME_LENGTH * 2

/**
 * The live auto demo. Progresses through steps of the auto demo until completion.
 */
export function LiveDemo({ autoDemo }: { autoDemo: AutoDemo }): React$Node {
	const { currentStep, freezeFrameStep, setStep: _setStep, visitedSteps } = useAutoDemoSteps(
		autoDemo
	)
	const [isPaused, setIsPaused] = useState(false)
	const videoRef = useRef<?HTMLVideoElement>()
	const [freezeFrameIsLoaded, setFreezeFrameIsLoaded] = useState(false)

	// The step whose video should be currently showing
	const stepForVideo = freezeFrameStep?.step || (currentStep?.type === VIDEO ? currentStep : null)

	/**
	 * Sets the videoRef to the final frame
	 */
	function goToLastFrame() {
		if (videoRef.current) {
			videoRef.current.currentTime = videoRef.current.duration - FINAL_FRAME_LENGTH
		}
	}
	/**
	 * Sets videoRef to the beginning of the video and calls play.
	 */
	function goToFirstFrame() {
		if (videoRef.current) {
			videoRef.current.currentTime = 0
			videoRef.current.play()
		}
	}

	/**
	 * When the user clicks to set the step, we sometimes need to seek to the beginning or end of the video. This function handles those cases.
	 */
	const setStep: SetStep = stepId => {
		if (stepId === currentStep?._id) {
			return
		}
		const nextStepId =
			stepId === INITIAL_STEP
				? autoDemo.initialStep
				: stepId === FINAL_STEP
				? undefined
				: stepId
		// It's possible for the step ids to be undefined/null, so we want to use `==`
		// eslint-disable-next-line eqeqeq
		if (currentStep && currentStep.type === VIDEO && nextStepId == currentStep.nextStepId) {
			goToLastFrame()
			if (videoRef.current) {
				// Let the video end on its own
				videoRef.current.play()
				return
			}
		} else if (nextStepId === stepForVideo?._id) {
			goToFirstFrame()
		} else {
			setFreezeFrameIsLoaded(false)
		}
		_setStep(nextStepId)
	}

	const isWaitingForFreezeFrameLoad =
		!!freezeFrameStep && freezeFrameStep.needsLoad && !freezeFrameIsLoaded
	const style = {}
	if (isWaitingForFreezeFrameLoad) {
		// Before seeking to the last frame, the video's first frame will be showing, causing a stutter.
		// display: none makes us use a black screen instead.
		style.display = 'none'
	}

	return (
		<AspectRatio width={1920} height={1080}>
			<CurrentVideo
				key={String(stepForVideo?._id)}
				videoInfo={stepForVideo?.video || { url: '' }}
				aria-label={currentStep?.name || 'The End'}
				setPausedStatus={setIsPaused}
				onEnded={() => {
					if (!currentStep || currentStep.type === PATH_SELECT) {
						// Somehow a video ended during a path select step.. Do nothing
						return
					}
					_setStep(currentStep.nextStepId)
				}}
				onLoadedData={() => {
					if (isWaitingForFreezeFrameLoad && videoRef.current) {
						goToLastFrame()
					}
				}}
				onSeeked={() => {
					if (isWaitingForFreezeFrameLoad) {
						// Made it to the seeked frame. Safe to display video now.
						setFreezeFrameIsLoaded(true)
					}
				}}
				style={style}
				ref={videoRef}
			/>
			{currentStep && currentStep.type === PATH_SELECT && (
				<PathSelectStep
					step={currentStep}
					setStep={setStep}
					key={'path_select' + currentStep._id}
				/>
			)}
			{!currentStep && <FinalStep />}
			<Controls
				{...{
					autoDemo,
					visitedSteps,
					isPaused,
					onSelectStep: setStep,
					onClickPausePlayButton: () =>
						isPaused ? videoRef.current?.play() : videoRef.current?.pause(),
					showPausePlayButton: currentStep?.type === VIDEO,
					isFinished: !currentStep,
				}}
			/>
		</AspectRatio>
	)
}

/**
 * A component that fits its children into a div that matches the provided aspect ratio, without exceeding the width
 * or height of the window
 * @param {number} props.width The width of the desired aspect ratio
 * @param {number} props.height The height of the desired aspect ratio
 * @param {React$Node} children The children to display
 */
function AspectRatio({
	width: aspectRatioWidth,
	height: aspectRatioHeight,
	children,
}: {
	width: number,
	height: number,
	children: React$Node,
}) {
	const { width, height } = useScaledAspectRatio(aspectRatioWidth, aspectRatioHeight)

	return (
		<div css="height: 100%; width: 100%; display: flex; justify-content: center; align-items: center; background-color: black;">
			<div
				style={{
					height: height + 'px',
					maxHeight: height + 'px',
					width: width + 'px',
					maxWidth: width + 'px',
					overflow: 'hidden',
					position: 'relative',
				}}>
				{children}
			</div>
		</div>
	)
}

/**
 * Displays the current video. Calls the `setPausedStatus` prop with the new paused status whenever the video's pause status changes. Calls the `onEnded` prop when the video ends.
 * All extra props that aren't explicitly used are pass on to the underlying video element.
 * @param {VideoInfo} props.videoInfo info for running the video, such as the mux playback id and/or the video url
 * @param {Function} setPausedStatus A function called with the video's new paused status when it changes
 * @param {Function} [onEnd] A function called when the video ends
 */
const CurrentVideo = React.forwardRef(function CurrentVideo(
	{
		videoInfo,
		setPausedStatus,
		onEnded,
		...props
	}: {
		videoInfo: VideoInfo,
		setPausedStatus: (isPaused: boolean) => mixed,
		onEnded?: () => mixed,
	},
	videoRef
) {
	return (
		<>
			<AutoDemoVideo
				{...props}
				videoInfo={videoInfo}
				onEnded={() => {
					if (onEnded) {
						onEnded()
					}
					// Play button should not switch to pause
					setPausedStatus(false)
				}}
				onPlay={() => setPausedStatus(false)}
				onPause={() => setPausedStatus(true)}
				ref={videoRef}
			/>
		</>
	)
})

/**
 * A button with a pause/play icon, depending on the `isPaused` prop.
 * @param {boolean} props.isPaused When true, the button will show a play button. Otherwise shows a pause button
 * @param {Function} props.onClick A function called when the button is clicked
 */
const PausePlayButton = React.forwardRef(function PausePlayButton(
	{ isPaused, onClick }: { isPaused: boolean, onClick: () => mixed },
	ref
) {
	const Icon = isPaused ? FaPlay : FaPause

	const iconProps = {}
	iconProps.width = 25
	if (isPaused) {
		// Make the play button look centered
		iconProps.style = { marginRight: '-2px', marginLeft: '2px' }
		iconProps['aria-label'] = 'Play'
	} else {
		iconProps['aria-label'] = 'Pause'
	}
	return (
		<GhostButton ref={ref} onClick={onClick}>
			<Icon {...iconProps} />
		</GhostButton>
	)
})

/**
 * The final step of the auto demo. Displays a `ButtonGroup` where the buttons are links to things that can be done after completing the auto demo.
 */
function FinalStep() {
	const { user } = useUser()

	return (
		<ButtonGroup
			options={[
				{
					buttonText: 'Schedule a conversation',
					id: 'SCHEDULE_A_CONVERSATION',
					as: 'a',
					href: 'https://mission.io/meetings/mission1/meeting-request',
				},
				user
					? {
							buttonText: 'Return to Dashboard',
							id: 'RETURN_TO_DASHBOARD',
							// $FlowIgnore[incompatible-type] We are allowed to pass Link for the `as` prop
							as: Link,
							to: '/',
					  }
					: {
							buttonText: 'Create a free trial account',
							id: 'FREE_ACCOUNT',
							as: 'a',
							href: config.loginClientUrl + '/sign-up',
					  },
			]}
		/>
	)
}

/**
 * Displays options for a path select step for the automated demo. Selecting an option will cause `setStep` to be called
 * with that option's `nextStepId`.
 */
function PathSelectStep({ step, setStep }: { step: PathSelectAutoDemoStep, setStep: SetStep }) {
	return (
		<ButtonGroup
			{...{
				options: step.options.map(option => ({
					buttonText: option.buttonText,
					id: option.nextStepId,
				})),
				onSelect: setStep,
				optionLayout: step.optionLayout,
			}}
		/>
	)
}

const DEFAULT_PATH_SELECT_OPTION_STYLE = {
	top: '18%',
	bottom: '42%',
	left: '31.35%',
	right: '31.25%',
	columns: 1,
}

/**
 * Renders each option in the provided `options` as a button. When a button is clicked, `onSelect` will be called with the corresponding option's `id`
 * passed as an arg. The buttons will be rendered in a box defined by the provided `optionLayout`.
 */
function ButtonGroup({
	options,
	onSelect,
	optionLayout = {},
}: {
	options: Array<{|
		buttonText: string,
		id: string | void,
		as?: string | React$ComponentType<{}>,
		href?: string,
		to?: string,
	|}>,
	onSelect?: (string | void) => mixed,
	optionLayout?: {
		top?: string,
		bottom?: string,
		left?: string,
		right?: string,
		columns?: number,
	},
}) {
	const isMounted = useRef(true)
	useEffect(() => {
		return () => {
			isMounted.current = false
		}
	}, [])
	const [isTransitioning, setIsTransitioning] = useState(true)

	const [trail, api] = useTrail(options.length, index => {
		const config = {}
		config.from = { opacity: 0 }
		config.opacity = 1
		config.config = { tension: 190, friction: 20 }
		if (index === options.length - 1) {
			config.onRest = () => {
				if (isMounted.current) {
					setIsTransitioning(false)
				}
			}
		}
		return config
	})

	const populatedOptionLayout = Object.assign({}, DEFAULT_PATH_SELECT_OPTION_STYLE, optionLayout)

	return (
		<div
			css={`
				// Don't let buttons appear outside of the box during animation. We don't want it always hidden
				// because when the box is too small the buttons will overflow, in which case they still need to be clickable
				${isTransitioning ? 'overflow: hidden;' : ''}
				padding: var(--spacing1x-dont-use);
				position: absolute;
				display: grid;
				gap: var(--spacing2x-dont-use);
				grid-template-columns: repeat(${populatedOptionLayout.columns}, 1fr);
				top: ${populatedOptionLayout.top};
				bottom: ${populatedOptionLayout.bottom};
				left: ${populatedOptionLayout.left};
				right: ${populatedOptionLayout.right};
			`}>
			{trail.map(({ opacity }, index) => {
				const { buttonText, id, ...extraProps } = options[index]
				return (
					<AnimatedButton
						key={buttonText}
						style={{
							opacity,
							translateY: opacity.to({ range: [0, 1], output: ['200%', '0%'] }),
						}}
						disabled={isTransitioning}
						onClick={() => {
							if (isTransitioning) {
								// don't allow clicking while animating
								return
							}
							setIsTransitioning(true)
							api.start({ opacity: 0 })
							if (onSelect) {
								setTimeout(() => {
									onSelect(id)
									// Wait for the buttons to leave, but don't wait until `onRest` which takes too long
								}, 1400)
							}
						}}
						{...extraProps}>
						{buttonText}
					</AnimatedButton>
				)
			})}
		</div>
	)
}

type AutoDemoVideoProps = { videoInfo: { muxPlaybackId?: string, url: string } }

/**
 * A video component that autoplays a 100% width and height video
 */
const AutoDemoVideo = React.forwardRef<AutoDemoVideoProps, HTMLVideoElement>(function AutoDemoVideo(
	{ videoInfo, ...props }: AutoDemoVideoProps,
	ref
) {
	const videoProp = {}

	if (videoInfo.muxPlaybackId) {
		videoProp.playbackId = videoInfo.muxPlaybackId
	} else {
		videoProp.src = videoInfo.url
	}

	return (
		<MuxVideo
			{...props}
			{...videoProp}
			streamType="on-demand"
			width="100%"
			height="100%"
			autoPlay
			ref={ref}
		/>
	)
})

// The distance in pixels that the mouse must move to trigger controls opening
const MOUSE_MOVE_THRESHOLD = 5
// The delay in ms after the last open controls action after which the controls should close
export const CONTROLS_AUTO_CLOSE_DELAY = 4000
/**
 * Hook that manages the open state of the controls component. The open state is toggled when clicking on any part of the
 * document that is not one of the refs in `controlsRefs` or a button element. The open state is also set to `true` when the mouse
 * is moved.
 * @param {Array<ref>} controlsRefs An array of refs which should not toggle the controls open state when clicked on
 * @return {boolean} The open state of the controls
 */
function useControlsOpenState(controlsRefs): boolean {
	const [isOpen, _setIsOpen] = useState(true)

	// Close controls after CONTROLS_AUTO_CLOSE_DELAY ms after the last opening action
	const closeControlsTimeout = useRef()
	// A function to be used to set the open state. Handles all side effects of the open state changing.
	const setIsOpen = useCallback((isOpen: boolean) => {
		if (closeControlsTimeout) {
			clearTimeout(closeControlsTimeout.current)
		}
		if (isOpen) {
			closeControlsTimeout.current = setTimeout(() => {
				setIsOpen(false)
			}, CONTROLS_AUTO_CLOSE_DELAY)
		} else {
			closeControlsTimeout.current = null
		}
		_setIsOpen(isOpen)
	}, [])

	// Clear the closeControlsTimeout on unmount to avoid making state updates after unmount
	useEffect(() => {
		return () => clearTimeout(closeControlsTimeout.current)
	}, [])

	// Start the timer to close the controls. Should only run once on mount
	useEffect(() => {
		closeControlsTimeout.current = setTimeout(() => {
			setIsOpen(false)
		}, CONTROLS_AUTO_CLOSE_DELAY)
	}, [setIsOpen])

	const toggle = useCallback(() => {
		setIsOpen(!isOpen)
	}, [isOpen, setIsOpen])

	// Clicking will toggle the controls
	useEffect(() => {
		const listener = (event: PointerEvent) => {
			// Do nothing if clicking any of the controls elements or their descendants
			for (const ref of controlsRefs) {
				// $FlowIssue[incompatible-call] `contains` does accept Events as well
				if (ref.current && ref.current.contains(event.target)) {
					return
				}
			}
			// Do nothing if clicking on any button
			for (const button of document.getElementsByTagName('button')) {
				// $FlowIssue[incompatible-call] `contains` does accept Events as well
				if (button.contains(event.target)) {
					return
				}
			}
			toggle()
			event.stopPropagation()
		}
		document.addEventListener('pointerdown', listener)
		return () => {
			document.removeEventListener('pointerdown', listener)
		}
	}, [toggle, controlsRefs])

	// moving the mouse will display controls
	const lastSeenCoordinates = useRef(null)
	useEffect(() => {
		const listener = (e: PointerEvent) => {
			if (lastSeenCoordinates.current) {
				const { x: lastX, y: lastY } = lastSeenCoordinates.current
				if (
					Math.sqrt((lastY - e.clientY) ** 2 + (lastX - e.clientX) ** 2) >=
					MOUSE_MOVE_THRESHOLD
				) {
					setIsOpen(true)
				}
			}

			lastSeenCoordinates.current = { x: e.clientX, y: e.clientY }
		}

		document.addEventListener('pointermove', listener)
		return () => {
			document.removeEventListener('pointermove', listener)
		}
	}, [setIsOpen])

	return isOpen
}

/**
 * Component that holds the interactive user controls for the auto demo.
 */
function Controls({
	onSelectStep,
	autoDemo,
	visitedSteps,
	isPaused,
	onClickPausePlayButton,
	showPausePlayButton,
	isFinished,
}: {
	onSelectStep: (stepId: string) => mixed,
	autoDemo: AutoDemo,
	visitedSteps: VisitedSteps,
	isPaused: boolean,
	onClickPausePlayButton: () => mixed,
	showPausePlayButton: boolean,
	isFinished: boolean,
}) {
	const stepProgressRef = useRef<?HTMLElement>()
	const pausePlayButtonRef = useRef()
	const controlsRefs = useMemo(() => [stepProgressRef, pausePlayButtonRef], [])
	const isOpen = useControlsOpenState(controlsRefs)

	const transition = useTransition(isOpen, {
		from: { opacity: 0, bottom: '-25%' },
		enter: { opacity: 1, bottom: '0%' },
		leave: { opacity: 0, bottom: '-25%' },
	})

	const currentPathSelectStepId = getCurrentPathSelectStepId(autoDemo, visitedSteps)

	return transition((styles, isOpen) => {
		return (
			isOpen && (
				<ControlsWrapper style={styles}>
					<StepProgress
						steps={[
							{ stepId: INITIAL_STEP, name: 'Restart Demo' },
							...getOrderedPathSelectSteps(autoDemo).map(step => ({
								stepId: step._id,
								name: step.name,
							})),
							{ stepId: FINAL_STEP, name: 'The End' },
						]}
						currentPathSelectStepId={
							isFinished ? FINAL_STEP : currentPathSelectStepId || INITIAL_STEP
						}
						onClickStep={onSelectStep}
						ref={stepProgressRef}
					/>
					<div className="absolute bottom-12 right-8 h-[40px] inline-flex items-center">
						{showPausePlayButton && (
							<PausePlayButton
								{...{
									isPaused,
									onClick: () => {
										onClickPausePlayButton()
									},
									ref: pausePlayButtonRef,
								}}
							/>
						)}
						{!isFinished && (
							<Button
								css="margin-left: var(--spacing1x-dont-use); padding: var(--spacing-half-dont-use) var(--spacing1x-dont-use);"
								small
								onClick={() => onSelectStep(FINAL_STEP)}>
								Skip to end
							</Button>
						)}
					</div>
				</ControlsWrapper>
			)
		)
	})
}

const ControlsWrapper = animated(styled.div`
	position: absolute;
	height: 25%;
	bottom: 0;
	width: 100%;
	background: linear-gradient(to top, #111111, #00000000);
`)

/**
 * A simple button with a dark, semi transparent background.
 */
const GhostButton = React.forwardRef(function GhostButton({ ...props }, ref) {
	return (
		<button
			ref={ref}
			{...props}
			// Don't let the button take focus when clicked
			onMouseDown={e => e.preventDefault()}
			css={`
				background-color: rgba(0, 0, 0, 0.8);
				color: white;
				border-radius: 50%;
				padding: var(--spacing1x-dont-use);
				border: none;
				transition: all 0.2s;
				&:hover {
					background-color: rgba(40, 40, 40, 0.8);
				}
			`}
		/>
	)
})
