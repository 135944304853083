import type {
	CurriculumStandard,
	StandardSet,
	SimpleCurriculumStandard,
} from '../../utility/standards'
import type { AutomatedSimulation } from '../../models/Simulation'

import { GRADE_MAP, NATIONAL_STATE } from './Standards'
import type { GradeCategory } from './Standards'
import type { StandardTableData } from './StandardsTable'
import { getStandardsForState } from '../../utility/standards'

export type Standard = {|
	...CurriculumStandard,
	standardSet: ?StandardSet,
|}

/**
 * @param standardSets a list of standard sets
 * @param currentState the abbreviation for the selected state
 * @param selectedGrade the selected grade category
 * @param standards a list of all curriculum standards
 * @param simulations a list of all automated simulations
 * Returns an object where Standard Ids are keys mapped to an array of automated simulations associated with that standard.
 * The simulations are filtered so that only simulations with the given current state and the selected grade category are included.
 *
 */
export const getFilteredStandardsToSimulations = ({
	standardSets,
	currentState,
	selectedGrade,
	standards,
	simulations,
	subjects,
}: {
	standardSets: ?(StandardSet[]),
	currentState: ?string,
	selectedGrade: GradeCategory,
	standards: ?(Standard[]),
	simulations: ?Array<AutomatedSimulation>,
	subjects: string[],
}): { [string]: Array<AutomatedSimulation> } => {
	if (!standardSets || !standards || !simulations) return {}
	const stateStandardSets = getStandardsForState(
		currentState ?? NATIONAL_STATE.abbreviation,
		standardSets
	)
	if (!stateStandardSets) return {}

	const standardToSimulations = {}

	const filteredStateStandardSets = stateStandardSets.filter(standardSet =>
		subjects.find(subject => subject === standardSet?.subject?.name)
	)

	standards
		.filter(
			standard =>
				filteredStateStandardSets.find(
					standardSet => standardSet._id === standard.standardSet?._id
				) &&
				(GRADE_MAP[selectedGrade].includes(standard.grade) ||
					(selectedGrade === 'All Grades' && !standard.grade))
		)
		.forEach(standard => {
			standardToSimulations[standard._id] = []
		})

	simulations.forEach(simulation => {
		simulation.standards.forEach(standard => {
			if (standardToSimulations[standard._id])
				standardToSimulations[standard._id].push(simulation)
		})
	})

	return standardToSimulations
}

/**
 * @param dataValues the rows of data for the standard table data
 * returns sorted data. Sorts K to the top and then by grade after that using localeCompare
 */
export const sortStandardsTableData = (
	dataValues: Array<StandardTableData>
): Array<StandardTableData> => {
	return dataValues.sort((a, b) => {
		if (a.standard?.grade?.includes('K') && !b.standard?.grade?.includes('K')) return -1
		if (b.standard?.grade?.includes('K') && !a.standard?.grade?.includes('K')) return 1
		if (a.standard?.grade === b.standard?.grade) {
			return (a.standard?.name ?? '').localeCompare(b.standard?.name ?? '', undefined, {
				numeric: true,
				sensitivity: 'base',
			})
		}
		return (a.standard?.grade ?? '').localeCompare(b.standard?.grade ?? '')
	})
}

/**
 * @param standardSets list of standard sets
 * @param standards list of Simple Curriculum Standards
 * Converts simple standards into Standards using the provided standardSets
 */
export const getCurriculumStandards = (
	standardSets: ?Array<StandardSet>,
	standards: ?Array<SimpleCurriculumStandard>
): Array<Standard> => {
	if (!standardSets || !standards) return []

	return standards.map(({ standardSetId, ...simpleStandard }: SimpleCurriculumStandard) => {
		return {
			...simpleStandard,
			standardSet: standardSets.find(standardSet => standardSet._id === standardSetId),
		}
	})
}
