import React, { useState } from 'react'
import styled from 'styled-components/macro'
import InviteTeacherModal from './modals/InviteTeacherModal'
import { PageTitleHeader } from '../../styles/sharedComponents'
import PersonOutline from 'react-icons/lib/md/person'
import { Button } from '@mission.io/styles'
import MembersPage from './pages/MembersPage'
import PendingInvitesPage from './pages/PendingInvitesPage'
import { Spinner, SpinnerHolder } from '../../styles/sharedComponents'
import { getUsersOnLicense } from './LicenseNetworkRoutes'
import { ADMIN_PAGE_USE_QUERY_ID } from './helpers'
import { useQuery, useQueryClient } from 'react-query'
import { ProfileNav } from '../../components/header/NavLinks'
import { ControlledTabs } from '../../components'

const TAB_ENUMS = {
	MEMBERS: 'MEMBERS',
	PENDING: 'PENDING',
}

/**
 * Page for Licensed Admins to manage their license.
 * They can see their license members and pending license invites.
 * They can also invite others to the license.
 */
export default function AdminPage(): React$Node {
	// React Query V2 cache- allows us to refetch the invitations on a license after we send invites
	const queryCache = useQueryClient()

	const [showInviteModal, setShowInviteModal] = useState(false)

	const { data, isLoading } = useQuery(ADMIN_PAGE_USE_QUERY_ID, () => getUsersOnLicense(), {
		staleTime: 1000 * 60 * 20,
	})

	if (isLoading) {
		return (
			<SpinnerHolder>
				<Spinner className="spinner-border" role="status" title="Page Loading">
					<span className="only-visible-to-screen-readers">Loading...</span>
				</Spinner>
			</SpinnerHolder>
		)
	}

	const { licenseUsers, pendingInvitations, otherUsersInOrganization } = data || {
		licenseUsers: [],
		pendingInvitations: [],
		otherUsersInOrganization: [],
	}

	return (
		<Wrapper>
			<PageTitleHeader css="display: flex; justify-content: space-between; align-items: baseline;">
				Admin Controls
				<Button
					className="flex items-center justify-center"
					onClick={() => setShowInviteModal(true)}>
					<StyledPersonOutline /> Invite Teachers
				</Button>
			</PageTitleHeader>
			<div className="mb-2">
				<ProfileNav />
			</div>
			<ControlledTabs
				tabs={[
					{
						key: TAB_ENUMS.MEMBERS,
						tabNode: 'License Members',
						content: (
							<MembersPage
								licenseUsers={licenseUsers}
								otherUsersInOrganization={otherUsersInOrganization}
							/>
						),
					},
					{
						key: TAB_ENUMS.PENDING,
						tabNode: 'Pending Invites',
						content: <PendingInvitesPage pendingInvitations={pendingInvitations} />,
					},
				]}
			/>
			<InviteTeacherModal
				isOpen={showInviteModal}
				onRequestClose={() => {
					queryCache.invalidateQueries(ADMIN_PAGE_USE_QUERY_ID, {
						exact: true,
					})
					setShowInviteModal(false)
				}}
			/>
		</Wrapper>
	)
}

const Wrapper = styled.div`
	${({ theme }) => `
		padding: 0 var(--spacing2x-dont-use);
	`}
	.show {
		width: 15vw;
	}
`

const StyledPersonOutline = styled(PersonOutline)`
	width: 1.5vw;
	height: 2.5vh;
`
