import type { LeaderBoardAnalytics, SchoolsInDistrict } from './types'
import type { UseQueryResult } from 'react-query'

import { config } from '../../../config'
import { ONE_MINUTE } from '../../../constants'
import { EXAMPLE_LEADER_BOARD_ANALYTICS } from '../../../constants/exampleData'
import NetworkCommunicator from '../../../services/NetworkCommunicator'
import { useUser } from '../../../services/hooks'
import { DISTRICT_ADMIN } from './constants'
import { useQuery } from 'react-query'

/**
 * Get the leader board analytics for the current user. Passes the given schoolId as a query param if provided
 *
 * @param schoolId - the schoolId to get the analytics for. This is optional and only used for district admins when they want to
 *                   get the analytics for a specific school
 */
export function useLeaderBoardAnalytics(
	schoolId?: string | null
): UseQueryResult<LeaderBoardAnalytics> {
	const user = useUser()

	return useQuery({
		queryKey: ['leaderBoardAnalytics', !!user, schoolId],
		queryFn: (): LeaderBoardAnalytics | Promise<LeaderBoardAnalytics> => {
			if (!user) {
				return EXAMPLE_LEADER_BOARD_ANALYTICS
			}
			let url = '/api/analytics'
			if (schoolId) {
				url += '?schoolId=' + schoolId
			}
			return NetworkCommunicator.GET(url, {
				host: config.missionsAPIURL,
			})
		},
		staleTime: 5 * ONE_MINUTE,
	})
}

/**
 * Get the schools described in the LeaderBoardAnalytics. This will only be populated if the user is a district admin
 */
export function useSchoolsInLeaderBoardAnalytics(): ?SchoolsInDistrict {
	const { data: leaderBoardAnalytics } = useLeaderBoardAnalytics()
	if (!leaderBoardAnalytics || leaderBoardAnalytics.type !== DISTRICT_ADMIN) {
		return null
	}

	return leaderBoardAnalytics.schoolsInDistrict
}
