import type { User } from '../store/types'
import type { ClientQuestion } from '@mission.io/question-toolkit'

export const OWNER_TYPES = {
	USER: 'USER',
	DISTRICT: 'DISTRICT',
}

export type SubjectType = { id: string, name: string }

export type QuestionGroup = {
	_id: string,
	name: string,
	createdBy: User,
	createdAt: string,
	public: boolean,
	questions: Array<ClientQuestion>,
	subjects: ?Array<SubjectType>,
	grades: Array<string>,
	owner: {
		type: $Keys<typeof OWNER_TYPES>,
		id: string,
	},
}

export type QuestionGroupWithFavorite = {
	...QuestionGroup,
	isFavorite: boolean,
}

export type ReduxQuestionGroup = {
	...QuestionGroup,
	subjects: Array<string>,
}

export type FavoriteQuestionGroup = {
	_id: string,
	userId: string,
	questionGroupId: string,
}
