import config from '../config'
import NetworkCommunicator from './NetworkCommunicator'

export function sendEmail(
	payload: { senderEmail: string, emailType: string, emailText: string },
	onSuccess: () => void,
	onError: () => void,
	onRateLimited: () => void
): Promise<void> {
	return NetworkCommunicator.POST('/api/email', {
		host: config.loginServer,
		body: {
			senderEmail: payload.senderEmail,
			emailText: payload.emailText,
			emailType: payload.emailType,
		},
	})
		.then(() => onSuccess())
		.catch(error => {
			console.error(error)
			if (error.status === 429) {
				return onRateLimited()
			}
			onError()
		})
}
