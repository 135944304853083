import { useSelector } from 'react-redux'
import config from '../../config'

import type { Dispatch } from 'redux'
import type { GetState } from '../rootReducer'
import NetworkCommunicatorType from '../../services/NetworkCommunicator'

export type Category = {
	_id: string,
	name: string,
	badge?: ?string,
	order?: ?number,
	availableForSimulations?: ?boolean,
	availableForQuestions?: ?boolean,
}

export type CategoryStore = {
	fetching: boolean,
	error: ?string,
	categories: ?Array<Category>,
}

const types = {
	SET_FETCHING_CATEGORIES: 'SET_FETCHING_CATEGORIES',
	SET_CATEGORIES: 'SET_CATEGORIES',
}

// Action Types
type SetFetchingAction = {
	type: 'SET_FETCHING_CATEGORIES',
	payload: boolean,
}

type SetCategoriesAction = {
	type: 'SET_CATEGORIES',
	payload: {| categories: ?Array<Category>, error: ?string |},
}

export type Actions = SetFetchingAction | SetCategoriesAction

function setFetching(fetching: boolean): SetFetchingAction {
	return {
		type: types.SET_FETCHING_CATEGORIES,
		payload: fetching,
	}
}

function setCategories(data: {|
	categories: ?Array<Category>,
	error: ?string,
|}): SetCategoriesAction {
	return {
		type: types.SET_CATEGORIES,
		payload: data,
	}
}

export function fetchCategories(): (
	dispatch: Dispatch<*>,
	getState: GetState,
	NetworkCommunicator: typeof NetworkCommunicatorType
) => void {
	return (
		dispatch: Dispatch<*>,
		getState: GetState,
		NetworkCommunicator: typeof NetworkCommunicatorType
	): void => {
		const state = getState()
		if (state.categories.fetching) {
			return
		}
		dispatch(setFetching(true))
		NetworkCommunicator.GET(`/api/categories`, { host: config.simulationsApiUrl })
			.then(data => {
				dispatch(setCategories(data))
			})
			.catch(errData => {
				if (errData instanceof Error) {
					console.error('ERROR: ', errData)
					return dispatch(setCategories({ categories: null, error: errData.message }))
				}
				console.error(errData.error)
				dispatch(setCategories({ categories: null, error: errData.error }))
			})
	}
}

const initialState = {
	fetching: false,
	categories: null,
	error: null,
}

export default function categoryReducer(
	state: CategoryStore = initialState,
	action: Actions
): CategoryStore {
	switch (action.type) {
		case types.SET_CATEGORIES: {
			const payload = action.payload
			return { ...state, ...payload, fetching: false }
		}
		case types.SET_FETCHING_CATEGORIES: {
			const payload = action.payload
			return { ...state, fetching: payload }
		}
		default:
			return state
	}
}

// selectors

/**
 * getCategories - get all categories available in the correct order
 *
 * @param  {{categories:CategoryStore}} state - the state to get the categories from
 *
 * @returns ?Array<Category> - all the categories in the correct order
 */
export function getCategories(state: { categories: CategoryStore }): ?Array<Category> {
	return state.categories.categories?.sort(
		(category1, category2) => (category1.order ?? Infinity) - (category2.order ?? Infinity)
	)
}

/**
 * getSimulationCategories - get the categories available for simulations from the redux state
 *
 * @param  {{categories:CategoryStore}} state - the state to get the categories from
 *
 * @returns ?Array<Category> - the categories available for simulations
 */
export function getSimulationCategories(state: { categories: CategoryStore }): ?Array<Category> {
	return getCategories(state)?.filter(({ availableForSimulations }) => availableForSimulations)
}

/**
 * getQuestionCategories - get the categories available for question groups
 *
 * @param  {{categories:CategoryStore}} state - the state to get the categories from
 *
 * @returns ?Array<Category> - the categories available for question groups
 */
export function getQuestionCategories(state: { categories: CategoryStore }): ?Array<Category> {
	return getCategories(state)?.filter(({ availableForQuestions }) => availableForQuestions)
}

// hooks

/**
 * A hook that gets the id of a category with the name `categoryName`
 */
export function useCategoryId(categoryName: string): ?string {
	return useSelector(getCategories)?.find(category => category.name === categoryName)?._id
}

/**
 * A hook that gets the id of the Training category
 */
export function useTrainingCategoryId(): ?string {
	return useCategoryId('Training')
}
