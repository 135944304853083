import React, { useMemo, useState } from 'react'
import { ButtonLink } from '../../../../components/helpers'
import { FaQuestion } from 'react-icons/lib/fa'
import 'styled-components/macro'
import Collapse from '../../../../components/Collapse.jsx'
import {
	CURRENT_ANALYTICS_VERSION,
	MISSION_TYPES,
	getAnalyticsVersionDescription,
} from '@mission.io/mission-toolkit'
import type {
	AnalyticsVersionMetricDescription,
	MainAnalyticsCategories,
} from '@mission.io/mission-toolkit'

// An advanced description of technical details for an analytics category id.
export default function AdvancedDescription({
	category,
}: {
	category: MainAnalyticsCategories,
}): React$Node {
	const [juniorAndStandard, standardOnly] = useMemo(() => {
		const categoryDescription = getAnalyticsVersionDescription(CURRENT_ANALYTICS_VERSION)
			?.description?.descriptionOfCategories[category]

		if (!categoryDescription) {
			return [[], []]
		}

		const metricsInCategory: Array<AnalyticsVersionMetricDescription> = Object.keys(
			categoryDescription.metricCalculationDescriptions
		).map(metricName => categoryDescription.metricCalculationDescriptions[metricName])

		return [
			metricsInCategory.filter(metric =>
				metric.forMissionType.includes(MISSION_TYPES.K_THROUGH_3)
			),
			metricsInCategory.filter(
				metric => !metric.forMissionType.includes(MISSION_TYPES.K_THROUGH_3)
			),
		]
	}, [category])
	const [visible, setVisible] = useState(false)

	if (!juniorAndStandard.length && !standardOnly.length) {
		return null
	}

	return (
		<div css="margin-top: var(--spacing1x-dont-use)">
			<div css="display: flex; justify-content: flex-end;">
				<ButtonLink onClick={() => setVisible(state => !state)} className="flex items-end">
					See {visible ? 'Less' : 'More'}
					<FaQuestion
						aria-label="help"
						title="help"
						css="border: 2px solid var(--blue); border-radius: 50%; margin: 0px 4px 4px 4px;"
					/>
				</ButtonLink>
			</div>
			<Collapse expanded={visible} className="flex flex-col gap-4">
				{juniorAndStandard.length > 0 && (
					<>
						<h6>
							The following metrics are collected and averaged to form scores for both
							junior and standard missions:
						</h6>
						<MetricDescriptionTable metrics={juniorAndStandard} />
					</>
				)}
				{standardOnly.length > 0 && (
					<>
						<h6>
							The following metrics are <b>only</b> collected for standard missions:
						</h6>
						<MetricDescriptionTable metrics={standardOnly} />
					</>
				)}
			</Collapse>
		</div>
	)
}

/**
 * MetricDescriptionTable - a react component which shows a table of the metric names with their descriptions
 *
 * @param {Object} props - the react props
 * @param {Array<AnalyticsVersionMetricDescription>} props.metrics - the metrics to display in the table
 *
 * @return {React$Node}
 */
function MetricDescriptionTable({
	metrics,
}: {
	metrics: Array<AnalyticsVersionMetricDescription>,
}): React$Node {
	return (
		<table>
			{metrics.map((metric, index) => (
				<tr key={index}>
					<td>
						<b>{metric.metricTitle}</b>
					</td>
					<td>{metric.generalDescription}</td>
				</tr>
			))}
		</table>
	)
}
