import config from '../../config'

import NetworkCommunicatorType from '../../services/NetworkCommunicator'
import type { Dispatch } from 'redux'
import type { GetState, ReduxStore } from '../rootReducer'
import type { SimulationsStore } from '../types'
import type { Simulation, AutomatedSimulation } from '../../models'

const types = {
	SET_SIMULATIONS: 'SET_SIMULATIONS',
	SET_QUESTS: 'SET_QUESTS',
	SET_AUTOMATED_SIMULATIONS: 'SET_AUTOMATED_SIMULATIONS',
}

// Action Types
type SetSimulationsAction = {
	type: 'SET_SIMULATIONS',
	payload: { missionTypes: ?(Simulation[]), questTypes: ?(Simulation[]) },
}

type SetAutomatedSimulationsAction = {
	type: 'SET_AUTOMATED_SIMULATIONS',
	payload: AutomatedSimulation[],
}

export type Actions = SetSimulationsAction | SetAutomatedSimulationsAction

// Action Creators
export function setAutomatedSimulations(
	simulations: AutomatedSimulation[]
): SetAutomatedSimulationsAction {
	return {
		type: types.SET_AUTOMATED_SIMULATIONS,
		payload: simulations,
	}
}

export function setSimulations(simulations: Array<Simulation>): SetSimulationsAction {
	const simulationMissions = simulations.filter(simulation => !simulation.isQuest)
	const simulationQuests = simulations.filter(simulation => simulation.isQuest)
	return {
		type: types.SET_SIMULATIONS,
		payload: {
			missionTypes: simulationMissions.length ? simulationMissions : null,
			questTypes: simulationQuests.length ? simulationQuests : null,
		},
	}
}

const initialState: SimulationsStore = {
	missionTypes: null,
	questTypes: null,
	automatedSimulations: null,
}

// Network Communicators
export function getSimulations(): (
	dispatch: Dispatch<*>,
	getState: GetState,
	NetworkCommunicator: typeof NetworkCommunicatorType
) => void {
	return (
		dispatch: Dispatch<*>,
		getState: GetState,
		NetworkCommunicator: typeof NetworkCommunicatorType
	): void => {
		NetworkCommunicator.GET(`/simulations?public`, { host: `${config.simulationsApiUrl}/api` })
			.then(data => {
				dispatch(setSimulations(data))
			})
			.catch(err => {
				console.log('ERROR: ', err)
				return {}
			})
	}
}

export function getAutomatedSimulations(): (
	dispatch: Dispatch<*>,
	getState: GetState,
	NetworkCommunicator: typeof NetworkCommunicatorType
) => void {
	return (
		dispatch: Dispatch<*>,
		getState: GetState,
		NetworkCommunicator: typeof NetworkCommunicatorType
	): void => {
		NetworkCommunicator.GET(`/simulations?automated=true&public`, {
			host: `${config.simulationsApiUrl}/api`,
		})
			.then(data => {
				dispatch(setAutomatedSimulations(data.simulations))
			})
			.catch(err => {
				console.log('ERROR: ', err)
				return {}
			})
	}
}

// reducers
export default function simulationReducer(
	state: SimulationsStore = initialState,
	action: Actions
): SimulationsStore {
	switch (action.type) {
		case types.SET_SIMULATIONS: {
			const payload = action.payload
			if (payload) {
				return {
					...state,
					questTypes: payload.questTypes,
					missionTypes: payload.missionTypes,
				}
			}
			return state
		}
		case types.SET_AUTOMATED_SIMULATIONS: {
			const payload = action.payload
			if (payload) {
				return { ...state, automatedSimulations: payload }
			}

			return state
		}
		default:
			return state
	}
}

export const getAutomatedSimulationFromId = (
	simulationId: ?string
): ((state: ReduxStore) => ?AutomatedSimulation) => (state: ReduxStore): ?AutomatedSimulation => {
	if (!simulationId) {
		return null
	}
	return state.simulations.automatedSimulations
		? state.simulations.automatedSimulations.find(sim => {
				return sim._id === simulationId
		  })
		: null
}

/**
 * Returns true if the simulationId is found in automatedSimulations.
 * If there are no automatedSimulations, null is returned.
 */
export function isSimulationAutomated(state: ReduxStore, simulationId: string): ?boolean {
	if (!state.simulations.automatedSimulations) {
		return null
	}
	return state.simulations.automatedSimulations.some(sim => sim._id === simulationId)
}
