import type { TooltipRenderProps } from 'react-joyride'
import { getAutomatedSimulationFromId } from '../../../store/simulation'
import SimulationTile from '../../helpers/SimulationTile'
import WelcomeDialog from './WelcomeModal'

import * as React from 'react'
import { useSelector } from 'react-redux'
import 'styled-components/macro'
import { FinalModalIllustration } from '../../../assets/walkthrough'

/**
 * A dialog that is displayed after the user has completed the station training. This is used as a step in the User Guide.
 */
export function StationTrainingCompleteDialog(props: TooltipRenderProps): React$Node {
	const k_2StemSimulationId = '63d405db839be0001c7bc49f'
	const _3_5StemSimulationId = '63c5a9c2839be0001c79c5a3'
	const _6_9StemSimulationId = '63e2804faa0758001d013813'

	const simulations = [
		{
			header: 'Grades K-2',
			simulation: useSelector(getAutomatedSimulationFromId(k_2StemSimulationId)),
		},
		{
			header: 'Grades 3-5',
			simulation: useSelector(getAutomatedSimulationFromId(_3_5StemSimulationId)),
		},
		{
			header: 'Grades 6-9',
			simulation: useSelector(getAutomatedSimulationFromId(_6_9StemSimulationId)),
		},
	]

	return (
		<WelcomeDialog
			{...props}
			css="max-width: 90vw; width: min(890px, 80vw); .welcome-modal__header {
			img {
				width: 100%;
				height: 100%;
				margin: -7% 0 0;
			}
			}"
			backgroundImageSrc={FinalModalIllustration}
			step={{
				...props.step,
				styles: {
					...props.step.styles,
				},
				content: (
					<div>
						<p css="text-align: center;">
							Great job! You’ve completed the station training! Now let’s run your
							first real mission. You&nbsp;can either pick a subject you covered in
							class OR you can choose one of our STEM&nbsp;missions.
						</p>
						<div
							css={`
								display: grid;
								grid-column-gap: var(--spacing4x-dont-use);
								grid-template-columns: repeat(auto-fill, minmax(225px, 1fr));
								justify-content: center;
								--simulation-tile-width: 225px;
							`}>
							{simulations.map(({ header, simulation }) => {
								if (!simulation) {
									return null
								}

								return (
									<div
										key={simulation._id}
										css="display: flex; justify-content: center;">
										<div css="width: var(--simulation-tile-width);">
											<div css="text-align: center;">{header}</div>
											<SimulationTile
												simulation={simulation}
												link={'/prep/' + simulation._id}
												className="mt-1 [&&]:w-full border-2 border-dusk"
											/>
										</div>
									</div>
								)
							})}
						</div>
					</div>
				),
			}}
		/>
	)
}
