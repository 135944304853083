import classnames from 'classnames'
import React from 'react'
/**
 * Selector - a horizontal styled react component which allows the user to select one option from a list of options
 *
 * @param  {{
 *	 		selected: number, the index of the option which is currently selected
 * 			options: Array<string>, all the options which can be picked
 *			onSelect: (index: number) => any, a callback for when an element is selected
 *			className?: ?string, - a css class name for the component
 * 			}} props - props for the react component
 */
export default function Selector({
	selected,
	options,
	onSelect,
	className,
	itemStyles,
}: {
	selected: number,
	options: string[],
	onSelect: (index: number) => any,
	className?: ?string,
	itemStyles?: { [string]: mixed },
}): React$Node {
	let characterCount = options.reduce(
		(characterCount: number, string: string) => characterCount + string.length,
		0
	)
	return (
		<div
			className={classnames(
				className,
				'flex justify-center items-center bg-primary-100 rounded-lg text-primary-950 border-2 border-primary-50 overflow-hidden whitespace-nowrap cursor-pointer text-sm'
			)}
			style={{
				width: `calc(${characterCount} * 1em + ${options.length} * var(--spacing2x-dont-use) * 2)`,
			}}>
			{options.map((option, index) => (
				<button
					className={classnames(
						'rounded-lg py-1 px-4 m-0 tracking-wide flex-1 cursor-pointer text-center',
						index === selected && 'text-white bg-primary-500'
					)}
					key={String(index)}
					selected={index === selected}
					onClick={() => onSelect(index)}
					style={{ ...itemStyles }}>
					{option}
				</button>
			))}
		</div>
	)
}
