import React, { useCallback } from 'react'
import styled from 'styled-components'
import { Line } from 'rc-progress'
import { config } from '../../config'

type OnboardingItem = {
	finished: boolean,
	label: string,
}

type Props = {
	togglePopupView: () => void,
	onboardingItems: Array<OnboardingItem>,
}

/**
 * A collapsed onboarding popup menu. Shows only when the user collapsed the popup menu manually.
 * Can be expanded into initial popup menu using togglePopupView callback.
 * @callback togglePopupView togglePopupView callback. Used for expanding popup menu
 * @param onboardingItems array with all onboarding items. Used to get the value of finished items  and progress percentage
 */
export default function OnboardingCollapsedPopup({
	togglePopupView,
	onboardingItems,
}: Props): React$Node {
	/**
	 * @function getProgressPercentage used inside progress line.
	 * Gets all onboarding items, counts finished items and returns finished percentage
	 */
	const getProgressPercentage = useCallback(() => {
		const onboardingItemsLength = onboardingItems.length
		const finishedItemsLength = onboardingItems.filter(item => item.finished).length
		return (100 / onboardingItemsLength) * finishedItemsLength
	}, [onboardingItems])
	return (
		<CollapsedPopupWrapper onClick={togglePopupView}>
			<CollapsedPopupBody>
				<span className="font-semibold">Finish Setup</span>
				<div className="flex">
					<span className="font-semibold text-[--primary] mr-[6px]">
						{onboardingItems.filter(item => item.finished).length}/
						{onboardingItems.length}
					</span>
					<div className="grow">
						<Line
							percent={getProgressPercentage()}
							strokeWidth={3}
							trailWidth={3}
							strokeLinecap="round"
							strokeColor="var(--primary)"
						/>
					</div>
				</div>
			</CollapsedPopupBody>
			<img src={config.logos.glyph} alt="logo" className="infinid-logo" />
		</CollapsedPopupWrapper>
	)
}

const CollapsedPopupWrapper = styled.div`
	display: flex;
	justify-content: space-between;
	&:hover {
		cursor: pointer;
	}
	${({ theme }) => `
    padding: var(--spacing2x-dont-use);
		.infinid-logo {
			width: 50px;
			height: 50px;
			margin-left: var(--spacing2x-dont-use);
		}
  `}
`
const CollapsedPopupBody = styled.div`
	flex-grow: 1;
`
