import React from 'react'
import { Link, type LocationShape } from 'react-router-dom'
import FaArrowLeft from 'react-icons/lib/fa/arrow-left'
import classnames from 'classnames'

/**
 * A back button rendered next to the provided `children`. If `link` is provided, a `Link` component from react-router-dom
 * is rendered instead of a button.
 * @param {string | LocationShape} link A value that would be a valid `to` prop for the `Link` component
 * @param {Function} onClick A function to call when the button is clicked. Not required if using `link`
 * @param {React$Node} children Something to render next to the back icon
 */
export function BackMenuButtons({
	onClick,
	children,
	link,
}: {
	onClick?: () => void,
	children: React$Node,
	link?: string | LocationShape,
}): React$Node {
	const props = {}
	if (onClick) {
		props.onClick = onClick
	}
	if (link) {
		props.to = link
		props.as = Link
	}
	return (
		<button {...props} className="border-none bg-transparent flex items-center">
			<FaArrowLeft className="cursor-pointer rounded-full p-1 size-8 bg-primary-400" />
			<div className="cursor-pointer ml-4 text-base font-medium inline-block">{children}</div>
		</button>
	)
}

/**
 * This is the same component as `BackMenuButtons`, with the addition of a colored background div as
 * a wrapper for the back button.
 */
export default function BackMenu({
	onClick,
	link,
	children,
	className,
}: {
	onClick?: () => void,
	link?: string | LocationShape,
	children: React$Node,
	className?: string,
}): React$Node {
	return (
		<div
			className={classnames(
				className,
				'flex items-center justify-start bg-primary-750 p-6 rounded-b-lg text-primary-50'
			)}>
			<BackMenuButtons onClick={onClick} link={link}>
				{children}
			</BackMenuButtons>
		</div>
	)
}
