// @flow
import update from 'immutability-helper'
import { useSelector } from 'react-redux'
import { toIdMap, values } from '../../utility/functions'
import { toast } from 'react-toastify'
import config from '../../config'
import NetworkCommunicatorType from '../../services/NetworkCommunicator'
import { type Category, getQuestionCategories } from '../categories'
import { OWNER_TYPES, type SubjectType } from '../../models/QuestionGroup'
import { useUser } from '../../services/hooks'
import { useUserDistrictId, useFavoriteQuestionGroups } from '../../services/hooks/user'
import type { Dispatch } from 'redux'
import type { ReduxStore, GetState } from '../rootReducer'
import type { QuestionsStore, LoadingState } from '../types'
import type { ClientQuestion as Question } from '@mission.io/question-toolkit'
import type { QuestionGroupWithFavorite, QuestionGroup, ReduxQuestionGroup } from '../../models'
import { EXAMPLE_USER, EXAMPLE_USER_ID } from '../../constants/exampleData'

const types = {
	SET_QUESTIONS: 'SET_QUESTIONS',
	ADD_QUESTIONS: 'ADD_QUESTIONS',
	ADD_QUESTION_GROUP: 'ADD_QUESTION_GROUP',
	SET_QUESTION_GROUPS: 'SET_QUESTION_GROUPS',
	DELETE_QUESTION_GROUP: 'DELETE_QUESTION_GROUP',
	SET_LOADING_STATE: 'SET_LOADING_STATE',
	SET_MEDIA_MODAL_STATUS: 'SET_MEDIA_MODAL_STATUS',
}

const NOT_LOADED = 'NOT_LOADED'
const LOADING = 'LOADING'
const LOADED = 'LOADED'

// Action Types
type SetQuestionsAction = {
	type: 'SET_QUESTION_GROUPS',
	payload: QuestionGroup[],
}

type AddQuestionsAction = {
	type: 'ADD_QUESTIONS',
	payload: Question[],
}

type AddQuestionGroupAction = {
	type: 'ADD_QUESTION_GROUP',
	payload: ReduxQuestionGroup,
}

type RemoveQuestionGroupAction = {
	type: 'DELETE_QUESTION_GROUP',
	payload: string,
}

type SetLoadingStateAction = {
	type: 'SET_LOADING_STATE',
	payload: LoadingState,
}

export type Actions =
	| SetQuestionsAction
	| AddQuestionsAction
	| SetLoadingStateAction
	| AddQuestionGroupAction
	| RemoveQuestionGroupAction

// Action Creators
function setQuestionGroups(questionGroups: QuestionGroup[]): SetQuestionsAction {
	return {
		type: types.SET_QUESTION_GROUPS,
		payload: questionGroups,
	}
}
export function addQuestions(questions: Question[]): AddQuestionsAction {
	return {
		type: types.ADD_QUESTIONS,
		payload: questions,
	}
}

export function addQuestionGroup(questionGroup: ReduxQuestionGroup): AddQuestionGroupAction {
	return {
		type: types.ADD_QUESTION_GROUP,
		payload: questionGroup,
	}
}

export function removeQuestionGroup(questionGroupId: string): RemoveQuestionGroupAction {
	return {
		type: types.DELETE_QUESTION_GROUP,
		payload: questionGroupId,
	}
}

export function setLoadingState(loadingState: LoadingState): SetLoadingStateAction {
	return {
		type: types.SET_LOADING_STATE,
		payload: loadingState,
	}
}

// Network Communicators
export function getQuestions(
	params?: {
		[key: string]: any,
	},
	userLoggedIn: boolean
): (
	dispatch: Dispatch<*>,
	getState: GetState,
	NetworkCommunicator: typeof NetworkCommunicatorType
) => void {
	return (
		dispatch: Dispatch<*>,
		getState: GetState,
		NetworkCommunicator: typeof NetworkCommunicatorType
	): void => {
		dispatch(setLoadingState(LOADING))
		if (!userLoggedIn) {
			dispatch(setQuestionGroups([]))
			dispatch(setLoadingState(LOADED))
			return
		}
		const searchParams = new URLSearchParams(params).toString()
		NetworkCommunicator.GET(`/questionGroups/?${searchParams}`, {
			host: `${config.missionsAPIURL}/api`,
		})
			.then(({ questionGroups }) => {
				dispatch(setQuestionGroups(questionGroups))
			})
			.catch(err => {
				return { error: err }
			})
			.then(() => {
				dispatch(setLoadingState(LOADED))
			})
	}
}

export function saveQuestionGroup(questionGroup: {
	name: string,
	subjects: string[],
	grades: string[],
}): (
	dispatch: Dispatch<*>,
	getState: GetState,
	NetworkCommunicator: typeof NetworkCommunicatorType
) => Promise<{ id: string } | { error: Error }> {
	return (
		dispatch: Dispatch<*>,
		getState: GetState,
		NetworkCommunicator: typeof NetworkCommunicatorType
	): Promise<{ id: string } | { error: Error }> => {
		return NetworkCommunicator.POST(`/questionGroups`, {
			host: `${config.missionsAPIURL}/api`,
			body: { questionGroup },
		})
			.then(({ questionGroup: newQuestionGroup }: { questionGroup: ReduxQuestionGroup }) => {
				toast.success('Saved question group!')
				dispatch(addQuestionGroup(newQuestionGroup))
				return { id: newQuestionGroup._id }
			})
			.catch(err => {
				toast.error(`Failed to save question group because ${err.message}`)
				return { error: err }
			})
	}
}

export function deleteQuestionGroup(
	questionGroupId: string
): (
	dispatch: Dispatch<*>,
	getState: GetState,
	NetworkCommunicator: typeof NetworkCommunicatorType
) => Promise<?{ error: Error }> {
	return (
		dispatch: Dispatch<*>,
		getState: GetState,
		NetworkCommunicator: typeof NetworkCommunicatorType
	): Promise<?{ error: Error }> => {
		return NetworkCommunicator.DELETE(`/questionGroups/${questionGroupId}`, {
			host: `${config.missionsAPIURL}/api`,
		})
			.then(({ questionGroupId }) => {
				toast.success('Deleted question group!')
				dispatch(removeQuestionGroup(questionGroupId))
			})
			.catch(err => {
				toast.error(`Failed to delete question group because ${err.message}`)
				return { error: err }
			})
	}
}

// reducers
const initialState: QuestionsStore = {
	allQuestions: {},
	allQuestionGroups: {},
	questionGroupsLoadingState: NOT_LOADED,
}

export default function questionReducer(
	state: QuestionsStore = initialState,
	action: Actions
): QuestionsStore {
	switch (action.type) {
		case types.ADD_QUESTIONS: {
			const questions = action.payload
			let mappedQuestions = {}
			questions.forEach(question => {
				if (question._id) {
					mappedQuestions[question._id] = question
				}
			})
			return {
				...state,
				allQuestions: {
					...state.allQuestions,
					...mappedQuestions,
				},
			}
		}
		case types.SET_QUESTION_GROUPS: {
			const questionGroups = action.payload
			let mappedQuestionGroups = {}
			questionGroups.forEach(questionGroup => {
				mappedQuestionGroups[questionGroup._id] = questionGroup
			})
			return {
				...state,
				allQuestionGroups: mappedQuestionGroups,
			}
		}
		case types.DELETE_QUESTION_GROUP: {
			const id = action.payload
			return update(state, { allQuestionGroups: { $unset: [id] } })
		}
		case types.ADD_QUESTION_GROUP: {
			const questionGroup = action.payload
			return update(state, {
				allQuestionGroups: { [questionGroup._id]: { $set: questionGroup } },
			})
		}
		case types.SET_LOADING_STATE: {
			return {
				...state,
				questionGroupsLoadingState: action.payload,
			}
		}

		default:
			return state
	}
}

// Selectors
export const getQuestion = (questionId: string): ((state: ReduxStore) => ?Question) => (
	state: ReduxStore
): ?Question => {
	return state.questions.allQuestions[questionId]
}

export function getSubjects(
	categoryLookup: ?IdMap<Category>,
	categoryIds: string[]
): SubjectType[] {
	return categoryIds?.map(categoryId => {
		if (categoryLookup && categoryLookup[categoryId]) {
			return { name: categoryLookup[categoryId].name, id: categoryId }
		}
		return {
			name: 'N/A',
			id: categoryId,
		}
	})
}

export function useSortedQuestionGroups(): {
	myQuestionGroups: QuestionGroupWithFavorite[],
	schoolQuestionGroups: QuestionGroupWithFavorite[],
	districtQuestionGroups: QuestionGroupWithFavorite[],
	favoriteQuestionGroups: QuestionGroupWithFavorite[],
} {
	const { user } = useUser()
	const userDistrictId = useUserDistrictId()
	const userFavoriteQuestionGroups = useFavoriteQuestionGroups()

	const questions = useSelector(state => state.questions)
	let sortedQuestionGroups = {
		myQuestionGroups: [],
		schoolQuestionGroups: [],
		districtQuestionGroups: [],
		favoriteQuestionGroups: [],
	}
	const categoryLookup = toIdMap(useSelector(getQuestionCategories) ?? [])
	if (!categoryLookup) return sortedQuestionGroups

	userFavoriteQuestionGroups?.forEach(q => {
		sortedQuestionGroups.favoriteQuestionGroups.push({
			...q,
			subjects: getSubjects(categoryLookup, q.subjects),
			isFavorite: true,
		})
	})

	if (questions.allQuestionGroups) {
		values(questions.allQuestionGroups).forEach(q => {
			const isFavorite = userFavoriteQuestionGroups?.some(qg => qg?._id === q._id)
			const questionGroup = {
				...q,
				subjects: getSubjects(categoryLookup, q.subjects),
				isFavorite,
			}
			const userId = user ? user._id : EXAMPLE_USER_ID
			const schoolName = user ? user.schoolName : EXAMPLE_USER.schoolName
			if (q && q.owner) {
				// add to 'my questions'
				if (q.owner.type === OWNER_TYPES.USER && q.owner.id === userId) {
					sortedQuestionGroups.myQuestionGroups.push(questionGroup)
				} else if (
					userDistrictId &&
					q.owner.type === OWNER_TYPES.DISTRICT &&
					q.owner.id === userDistrictId
				) {
					sortedQuestionGroups.districtQuestionGroups.push(questionGroup)
				}

				// add to their school questions if the school matches:
				if (q.createdBy.schoolName && q.createdBy.schoolName === schoolName) {
					sortedQuestionGroups.schoolQuestionGroups.push(questionGroup)
				}
			}
		})
	}

	return sortedQuestionGroups
}

/**
 * Whether the dependencies for question groups are loaded
 */
export function canReadQuestionGroups(state: ReduxStore): boolean {
	return state.questions.questionGroupsLoadingState === LOADED && !!state.categories.categories
}
