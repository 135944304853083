export const scoreFormatEnum = { PROFICIENCY_LEVEL: 'PROFICIENCY_LEVEL', PERCENT: 'PERCENT' }
export type ScoreFormatType = $Keys<typeof scoreFormatEnum>
export const SCORE_FORMAT_DISPLAY: { [scoreFormat: ScoreFormatType]: string } = {
	[scoreFormatEnum.PROFICIENCY_LEVEL]: 'Proficiency Level',
	[scoreFormatEnum.PERCENT]: 'Percent',
}

export const analyticsViewEnum = {
	ADMIN: 'ADMIN',
	DISTRICT: 'DISTRICT',
	TEACHER: 'TEACHER',
	MISSION: 'MISSION',
}

export const NA = 'NA'
export const NAME = 'name'

export const QUESTIONS = 'questions'
export const CULMINATING_MOMENT = 'application'
export const SEL_SCORE = 'selScore'

export type AnalyticsViewType = $Keys<typeof analyticsViewEnum>

export const END_MISSION = 'END_MISSION'

export const IMPROVEMENT = '#FF0000'

export const ORDERED_CATEGORIES: string[] = [
	'questions',
	'application',
	'initiative',
	'collaboration',
	'criticalThinking',
	'grit',
]

export const HEADER_TO_TITLE = {
	questions: 'Knowledge',
	application: 'Application',
	initiative: 'Initiative',
	collaboration: 'Collaboration',
	criticalThinking: 'Critical Thinking',
	grit: 'Resilience',
}

export const HEADER_TO_CATEGORY = {
	questions: 'knowledge',
	application: 'knowledge',
	criticalThinking: 'skills',
	collaboration: 'skills',
	grit: 'disposition',
	initiative: 'disposition',
}

export const ALL = 'All'
export const ALL_OPTION = { label: ALL, value: ALL }

export const COLLABORATION = 'collaboration'
export const CRITICAL_THINKING = 'criticalThinking'
export const GRIT = 'grit'
export const INITIATIVE = 'initiative'
export const MISSION_SCORE = 'missionScore'

// We can use 12 because the server only returns the current school year. 12 is guaranteed to include all of current school year.
export const HALF_YEAR = 6
export const QUARTER = 3
export const MONTH = 1

const DAY = 1000 * 60 * 60 * 24
export const TWO_WEEKS = DAY * 14
export const ONE_MONTH = DAY * 30

export const TEACHER_ID = 'teacherId'
export const SCHOOL_ID = 'schoolId'

export const PROFICIENCY_COLORS = ['#a30071', '#9238a8', '#6c54f0', '#501fff']
