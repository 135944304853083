import React from 'react'
import styled from 'styled-components'
import { PDFViewer } from '@react-pdf/renderer'
import type { PreviewProps } from './types'

/**
 * Image - a component used to view a pdf file
 *
 * @param {PreviewProps} props - the preview props from the FileViewer component
 *
 * @return {React$Node}
 */
export function PDF({ file }: PreviewProps): React$Node {
	return <StyledDocument src={file} />
}

const StyledDocument = styled(PDFViewer)`
	width: 80vw;
	height: 80vh;
`
