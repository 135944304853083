import React from 'react'
import styled from 'styled-components'
import { useFileViewerFile, useShowFileInFileViewer } from './FileViewerContext'
import FaClose from 'react-icons/lib/fa/close'
import { getFileExtensionFromSrc, getFileNameFromSrc } from '../../utility/fileFunctions'
import { FILE_EXTENSION_TO_COMPONENT_MAP } from './FileTypeMap'
import { Unknown } from './Previewers/Unkown'
import { Button } from '@mission.io/styles'

import FaDownload from 'react-icons/lib/fa/download'
import RequireSignIn from '../helpers/RequireSignIn'

/**
 * FileViewer - a global "modal" used to preview files. Interact with using `useShowFileInFileViewer`.
 *
 * @return {React$Node} - the file viewer "modal"
 */
export default function FileViewer(): React$Node {
	const fileData = useFileViewerFile()
	const setFileToView = useShowFileInFileViewer()

	const fileToView = fileData?.preview || fileData?.src

	const fileExtension = fileToView && getFileExtensionFromSrc(fileToView)

	const Component = fileExtension && (FILE_EXTENSION_TO_COMPONENT_MAP[fileExtension] || Unknown)

	if (!fileData || !Component || !fileToView) {
		return null
	}

	return (
		<Positioner test-id="file-viewer">
			<Background
				onClick={e => {
					setFileToView(null)
				}}>
				<StyledClose aria-label="close" />
				<Component file={fileToView} captions={fileData.captions} />
				<FileName>{fileData.name || getFileNameFromSrc(fileData.src)}</FileName>
				{!fileData.disallowDownload ? (
					<a
						className="mt-4"
						href={fileData.src}
						download={getFileNameFromSrc(fileData.src)}
						onClick={e => {
							e.stopPropagation()
						}}>
						<RequireSignIn>
							<Button className="text-lg py-2 px-8">
								<FaDownload className="inline" /> Download
							</Button>
						</RequireSignIn>
					</a>
				) : null}
			</Background>
		</Positioner>
	)
}

const Positioner = styled.div`
	max-height: 0;
	max-width: 0;
	position: sticky;
	top: 0;
	left: 0;
	overflow: visible;
	z-index: 102;
`

const Background = styled.div`
	position: relative;
	width: 100vw;
	height: 100vh;
	background-color: #000000b0;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
`

const StyledClose = styled(FaClose).attrs({
	className: 'absolute top-4 right-4 text-4xl cursor-pointer hover:text-white',
})`
	color: #ccc;
`

const FileName = styled.h3`
	margin-top: var(--spacing1x-dont-use);
	color: white;
	max-width: 60vw;
	text-align: center;
`
