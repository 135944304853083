// @flow
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components/macro'
import { toast } from 'react-toastify'
import CustomModal, { ModalHeader, ExitX } from '../../../components/modal/Modal'
import PrepModalContent from './PrepModalContent'
import { getQuestions, removePrepMission } from '../../../store/missionPrep'
import { usePanelStack } from './../../../utility/hooks'
import { ACTIONABLE_TOAST_PROPERTIES, SimpleConfirmation } from '../../../components'

import type { ClientQuestion as QuestionType } from '@mission.io/question-toolkit'
import lodash from 'lodash'

type PrepMissionModalProps = {
	onClose: () => void,
}

export default function PrepMissionModal({ onClose }: PrepMissionModalProps): React$Node {
	const dispatch = useDispatch()

	const close = () => {
		dispatch(removePrepMission())
		onClose()
	}
	const { panelStack, add, goBack: _goBack } = usePanelStack({
		title: 'Mission Prep',
		component: PrepModalContent,
		props: {
			close,
		},
	})

	const width = panelStack.length > 1 ? '90%' : '75%'

	const reduxQuestions = useSelector(getQuestions) || []
	const [questions, setQuestions] = useState<QuestionType[]>(reduxQuestions)

	const goBack = (showAlert = true) => {
		const formIsDirty = !lodash.isEqual(reduxQuestions, questions)

		if (showAlert && formIsDirty) {
			const toastId = toast.info(
				<SimpleConfirmation
					title="Unsaved Changes"
					message="You have unsaved changes. Are you sure you want to go back?"
					confirmText="Go back"
					onConfirm={() => {
						toast.dismiss(toastId)
						_goBack()
						setQuestions(reduxQuestions)
					}}
					onCancel={() => {
						toast.dismiss(toastId)
					}}
				/>,
				{
					...ACTIONABLE_TOAST_PROPERTIES,
					position: toast.POSITION.TOP_CENTER,
				}
			)
		} else {
			_goBack()
		}
	}
	return (
		<CustomModal
			data={{ walkthrough: 'prep-mission-modal' }}
			isOpen={true}
			style={{
				content: {
					width,
					maxHeight: '85vh',
					display: 'flex',
					flexDirection: 'column',
					overflow: 'hidden',
				},
			}}>
			<ModalHeader>
				<nav aria-label="Mission Prep Breadcrumb">
					<BreadcrumbList>
						{panelStack.map((navigationItem, i) => {
							return i === panelStack.length - 1 ? (
								<span key={navigationItem.title} href="#" className="font-medium">
									{navigationItem.title}
								</span>
							) : (
								<button
									className="font-medium text-sky-blue hover:brightness-150"
									onClick={() => goBack()}>
									{navigationItem.title}
								</button>
							)
						})}
					</BreadcrumbList>
				</nav>
				<ExitX onClick={close} data-walkthrough="prep-mission-back" />
			</ModalHeader>
			<div css="min-height: 0; display: flex; flex-direction: column;">
				{panelStack.map((panel, i) => (
					<div
						key={panel.id}
						css={`
							display: flex;
							flex-direction: column;
							min-height: 0;
							height: 100%;
							${i !== panelStack.length - 1 ? 'display: none;' : ''}
						`}>
						<panel.component
							{...panel.props}
							add={add}
							goBack={goBack}
							questions={questions}
							setQuestions={setQuestions}
						/>
					</div>
				))}
			</div>
		</CustomModal>
	)
}

const BreadcrumbList = styled.ol`
	margin-bottom: 0;
	padding: 0;
	& > *:not(:first-child) {
		padding-left: 0.5rem;
		::before {
			display: inline-block;
			padding-right: 0.5rem;
			color: #6c757d;
			content: '/';
		}
	}
	& button {
		border: none;
		background-color: transparent;
		padding: 0;
	}
`
