import React from 'react'
import styled from 'styled-components'
import type { PreviewProps } from './types'

/**
 * Image - a component used to view an image file
 *
 * @param {PreviewProps} props - the preview props from the FileViewer component
 *
 * @return {React$Node}
 */
export function Image({ file }: PreviewProps): React$Node {
	return (
		<StyledImage
			src={file}
			onClick={e => {
				e.stopPropagation()
			}}
		/>
	)
}

const StyledImage = styled.img`
	max-width: 80vw;
	max-height: 80vh;
	pointer-events: all;
`
