import React, { useState } from 'react'
import { ScoresList } from './ScoresList'
import { AnalyticsSelector } from './AnalyticsSelector'
import FunFactViewer from './FunFactViewer'
import Selector from './Selector'
import { useLeaderBoardAnalytics } from './LeaderBoardAnalyticsHooks'
import {
	TOP_SCORES,
	MOST_IMPROVED,
	HIGHEST_USAGE,
	LOCAL,
	TOP_SCHOOLS,
	TOP_DISTRICTS,
	PROBLEMS_SOLVED,
	DEATHS,
	TEACHER,
	DISTRICT_ADMIN,
} from './constants'
import { config } from '../../../config'
import { PageTitleHeader } from '../../../styles/sharedComponents'
import { useIsDistrictAdmin } from '../../../services/hooks/user'
import { useQueryParam, withDefault, StringParam } from 'use-query-params'
import {
	getAnalyticsByFunFactId,
	getAnalyticsDataByViewId,
	getExplanationByViewId,
} from './helpers'
import { useStudentIdsToName } from '../../../services/hooks/classes'
import type { ViewOptionType } from './constants'
import type { LeaderBoardAnalytics as LeaderBoardAnalyticsType } from './types'

const StringParamWithDefault = withDefault(StringParam, null)

export default function LeaderBoardAnalytics(): React$Node {
	const [selectedSchoolId, setSelectedSchoolId]: [
		string | null,
		(schoolId: string | null) => mixed
	] = useQueryParam('schoolId', StringParamWithDefault, {
		updateType: 'replaceIn',
		removeDefaultsFromUrl: true,
	})
	const { data: leaderBoardAnalytics } = useLeaderBoardAnalytics(selectedSchoolId)
	const isTeacher = leaderBoardAnalytics?.type === TEACHER
	const leaderBoardCategories = Object.keys(leaderBoardAnalytics?.leaderboard.categories || {})
	const isDistrictAdmin = useIsDistrictAdmin()

	const [selectedCategoryIndex, setSelectedCategoryIndex] = useState(0)

	const category = leaderBoardCategories[selectedCategoryIndex]
	const leaderBoardDescription =
		(category && leaderBoardAnalytics?.leaderboard.categories[category]?.description) || ''

	const studentIdsToName = useStudentIdsToName().data ?? {}
	const funFactBoxes = (
		<>
			<FunFactViewer
				value={getAnalyticsByFunFactId(leaderBoardAnalytics, DEATHS) ?? 0}
				title="Game Revivals"
				explanation="Combined number of times students bounced back after disastrous endings (where students let their health hit 0, or they allowed their ship to be destroyed)."
			/>
			<FunFactViewer
				value={getAnalyticsByFunFactId(leaderBoardAnalytics, PROBLEMS_SOLVED) ?? 0}
				title="Problems Solved"
				explanation="Total number of questions answered correctly and interactive tasks completed."
			/>
		</>
	)

	return (
		<>
			<PageTitleHeader>Local and National Rankings</PageTitleHeader>
			<div className="flex gap-4 items-center">
				{leaderBoardDescription ? (
					<div className="mb-4 text-xl font-semibold">{leaderBoardDescription}</div>
				) : null}
				<Selector
					className="mb-6 font-semibold"
					options={leaderBoardCategories}
					selected={selectedCategoryIndex}
					onSelect={setSelectedCategoryIndex}
				/>
			</div>
			<div className="flex justify-between">
				{[LOCAL, TOP_SCHOOLS, TOP_DISTRICTS].map(type => (
					<ScoresListWrapper
						key={type}
						{...{
							type,
							leaderBoardAnalytics,
							studentIdsToName,
							category,
						}}
					/>
				))}
			</div>
			<div className="mt-12">
				<h2 className="text-2xl font-medium">
					{config.companyName.base} Highlights
					{isDistrictAdmin && (
						<AnalyticsSelector
							{...{ schoolId: selectedSchoolId, setSchoolId: setSelectedSchoolId }}
						/>
					)}
				</h2>
			</div>
			<div className="flex justify-between mt-6">
				{[TOP_SCORES, MOST_IMPROVED].map(type => (
					<ScoresListWrapper
						key={type}
						{...{
							type,
							leaderBoardAnalytics,
							studentIdsToName,
							category,
						}}
					/>
				))}
				{isTeacher ? (
					<div className="flex flex-col w-[30%] gap-4">{funFactBoxes}</div>
				) : (
					<ScoresListWrapper
						{...{
							type: HIGHEST_USAGE,
							leaderBoardAnalytics,
							studentIdsToName,
							category,
						}}
					/>
				)}
			</div>
			{!isTeacher ? (
				<div className="flex justify-evenly gap-4 my-14 [&>*]:w-[30%]">{funFactBoxes}</div>
			) : (
				<div className="my-14" />
			)}
		</>
	)
}

/**
 * A small wrapper around the ScoresList component to reduce duplication
 */
function ScoresListWrapper({
	type,
	leaderBoardAnalytics,
	studentIdsToName,
	category,
}: {|
	type: ViewOptionType,
	leaderBoardAnalytics: LeaderBoardAnalyticsType | void,
	studentIdsToName: { [id: string]: string },
	category: string,
|}) {
	return (
		<ScoresList
			{...{
				type: type,
				scores: getAnalyticsDataByViewId(
					leaderBoardAnalytics,
					type,
					studentIdsToName,
					category
				),
				explanation: getExplanationByViewId(leaderBoardAnalytics, type, category),
				isLoading: !leaderBoardAnalytics,
				title:
					type !== LOCAL || !leaderBoardAnalytics
						? undefined
						: leaderBoardAnalytics.type === DISTRICT_ADMIN &&
						  leaderBoardAnalytics.results.schools
						? 'School Rankings'
						: 'Class Rankings',
				className: 'w-[30%]',
			}}
		/>
	)
}
