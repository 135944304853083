// @flow
import React, { useState } from 'react'
import type { Node } from 'react'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import 'styled-components/macro'
import { saveQuestionGroup } from '../../store/question'
import { EditQuestionGroupDetails, QuestionGroups } from './components'
import Filters, { INITIAL_CREATION_GRADE_FILTER } from '../../components/helpers/Filters/Filters'
import Modal, { ModalBody, ExitX, ModalHeader } from '../../components/modal/Modal'
import { PageTitleHeader } from '../../styles/sharedComponents'

import type { SubjectType } from '../../models/QuestionGroup'
import { DelimitedArrayParam, StringParam, useQueryParams } from 'use-query-params'
import { GRADE_ARRAY } from '../../store/missionPrep'
import { getQuestionCategories } from '../../store/categories'
import { Button } from '@mission.io/styles'
import { InputSearch } from '../../components/helpers/Filters/InputSearch'
import type { ClientQuestion } from '@mission.io/question-toolkit'

type Props = {
	onQuestionGroupSelect?: ?(questionGroupId: string) => void,
	disableCreation?: ?true,
	title?: ?Node,
	noQuestionGroupsExplanation?: ?Node,
	className?: string,
	defaultTab?: ?{
		canAddQuestion: string => boolean,
		onAdd: (question: ClientQuestion) => void,
		onRemove: (questionId: string) => void,
	},
	isSmallContainer?: boolean,
}

export default function Library({
	onQuestionGroupSelect,
	disableCreation,
	title,
	noQuestionGroupsExplanation,
	className,
	defaultTab,
	isSmallContainer = false,
}: Props): React$Node {
	const history = useHistory()
	const dispatch = useDispatch()

	const [showNewQuestionGroupModal, setShowNewQuestionGroupModal] = useState(false)

	const openNewQuestionGroupModal = () => setShowNewQuestionGroupModal(true)
	const closeNewQuestionGroupModal = () => setShowNewQuestionGroupModal(false)

	const setQuestionGroupId = (id: string) => {
		history.push(`/questionGroups/${id}`)
	}

	const [initialDetails, setInitialDetails] = useState<{
		grades: string[],
		subjects: ?(SubjectType[]),
		name: string,
	}>({
		grades: [INITIAL_CREATION_GRADE_FILTER],
		subjects: null,
		name: '',
	})
	const updateInitialDetail = detail => {
		switch (detail.type) {
			case 'grades':
				setInitialDetails(state => ({ ...state, grades: detail.value }))
				return
			case 'subjects':
				setInitialDetails(state => ({ ...state, subjects: detail.value }))
				return
			case 'name':
				setInitialDetails(state => ({ ...state, name: detail.value }))
				return
			default:
				return
		}
	}

	const saveQuestionGroupWrapper = questionGroup => {
		const { subjects, ...moreFields } = initialDetails

		if (moreFields.grades && subjects && moreFields.name) {
			const subjectsId = subjects ? subjects.map(s => s.id) : []
			dispatch(
				saveQuestionGroup({
					...moreFields,
					subjects: subjectsId,
					public: true,
				})
			).then(result => {
				if (result.id) {
					setQuestionGroupId(String(result.id))
				}
			})
			setShowNewQuestionGroupModal(false)
		} else {
			// show validation error
			toast.error('Missing required values for question group')
		}
	}

	const [query, setQuery] = useQueryParams({
		grades: DelimitedArrayParam,
		subjects: DelimitedArrayParam,
		search: StringParam,
	})

	const gradesFilter = GRADE_ARRAY.filter(option =>
		query.grades === undefined ? true : query.grades.some(val => val === option)
	).map(g => ({ label: g, value: g }))

	const categories = useSelector(getQuestionCategories)

	const subjectFilter = categories
		?.filter(category => {
			return query.subjects === undefined
				? false
				: query.subjects.some(val => val === category._id)
		})
		.map(category => {
			return { label: category.name, value: category._id }
		})

	const searchFilter = query.search ?? ''

	const setSearchFilter = search => {
		setQuery({ search }, 'replaceIn')
	}

	const setCategoryFilter = subjectList => {
		let subjects: string[] = []
		if (Array.isArray(subjectList)) {
			subjects = subjectList.map(m => m.value)
		}
		if (subjects.length === 0) subjects = ['']
		setQuery({ subjects }, 'replaceIn')
	}

	const setGradeFilter = gradesList => {
		let grades: string[] | void = []
		if (Array.isArray(gradesList)) {
			grades = gradesList.map(m => m.value)
		}

		if (grades?.length === 0) grades = ['']
		if (grades?.length === GRADE_ARRAY.length) grades = undefined

		setQuery({ grades }, 'replaceIn')
	}

	const clearFilters = () => {
		setQuery(
			{
				grades: undefined,
				subjects: undefined,
				search: undefined,
			},
			'replaceIn'
		)
	}

	const filtersSection = (
		<FiltersWrapper isSmallContainer={isSmallContainer}>
			<Filters
				filters={{
					grades: {
						values: gradesFilter,
						set: gradesList => setGradeFilter(gradesList),
					},
					category: {
						values: subjectFilter ?? [],
						set: subjectList => setCategoryFilter(subjectList),
					},
				}}
				filterOptions={{ categories: categories ?? [] }}
				categories={categories || []}
			/>
			<InputSearch
				css="grid-area: input-area;"
				setNameFilter={setSearchFilter}
				nameFilter={searchFilter}
			/>
			<Button
				variant="secondary"
				css="grid-area: button-area; max-width: 150px;"
				onClick={() => {
					clearFilters()
				}}>
				Clear
			</Button>
		</FiltersWrapper>
	)

	return (
		<QuestionLibraryWrapper className={className}>
			{title || <PageTitleHeader>Questions</PageTitleHeader>}
			<QuestionGroups
				onSelect={id => {
					if (onQuestionGroupSelect) {
						onQuestionGroupSelect(id)
					} else {
						setQuestionGroupId(id)
					}
				}}
				defaultTab={defaultTab}
				selectable={false}
				noQuestionGroupsExplanation={noQuestionGroupsExplanation}
				filters={{
					grades: gradesFilter.map(data => data.value),
					subjects: subjectFilter?.map(data => data.value) || [],
					search: searchFilter,
				}}
				clearFilters={clearFilters}
				filtersSection={filtersSection}
				addQuestionGroup={!disableCreation ? openNewQuestionGroupModal : undefined}
			/>
			<Modal
				isOpen={showNewQuestionGroupModal}
				onRequestClose={closeNewQuestionGroupModal}
				style={{ content: { maxWidth: '1000px' } }}>
				<ModalHeader>
					<div>New Question Group</div>
					<ExitX onClick={closeNewQuestionGroupModal} />
				</ModalHeader>
				<ModalBody>
					<EditQuestionGroupDetails
						onSave={saveQuestionGroupWrapper}
						updateValue={updateInitialDetail}
						values={initialDetails}
						creating
					/>
				</ModalBody>
			</Modal>
		</QuestionLibraryWrapper>
	)
}

const QuestionLibraryWrapper = styled.div`
	${({ theme }) => `
		padding: 0 var(--spacing2x-dont-use);
	`}
`
const FiltersWrapper = styled.div`
	.category-wrapper {
		grid-area: category-area;
	}
	.grade-wrapper {
		grid-area: grade-area;
	}
	display: grid;
	gap: var(--spacing1x-dont-use);
	margin: 0 0 var(--spacing4x-dont-use) 0;
	align-items: end;

	grid-template-areas: ${({ isSmallContainer }) =>
		isSmallContainer
			? `'category-area' 'grade-area' 'input-area' 'button-area'`
			: `'category-area grade-area button-area input-area'`};

	@media (max-width: 1024px) {
		grid-template-areas: 'category-area' 'grade-area' 'input-area' 'button-area';
	}
	@media (min-width: 1600px) {
		grid-template-areas: 'category-area grade-area button-area input-area' !important;
	}
`
