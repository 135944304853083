import React from 'react'
import styled from 'styled-components/macro'
import { ArrayParam, StringParam, useQueryParams } from 'use-query-params'
import { MdChevronRight } from 'react-icons/lib/md'
import { ButtonLink } from '../../../components/helpers'
import content from './content'
import { FaCaretDown, FaCaretUp } from 'react-icons/lib/fa'
import Collapse from '../../../components/Collapse'

/**
 * A page that will display to the user information regarding how their class was scored in a given category. If
 * the user navigated to this page and there is a mission id in the analytics url, then extra buttons will be accessible to
 * the user such as a button the end of mission results or a button to open the mission guide. See content.js for specific content that will be displayed
 * per category
 * @returns {React$Node}
 */
export default function CategoryInfoPage(): React$Node {
	const [{ focus, category }, setQuery] = useQueryParams({
		focus: ArrayParam,
		category: StringParam,
	})

	const categoryInfo = content[category]
	if (!categoryInfo) {
		setQuery({ category: undefined, focus: undefined })
		return null
	}
	const crumbs = [
		{
			title: 'Analytics',
			onClick: () => {
				setQuery({ focus: undefined, category: null })
			},
		},
		{ title: categoryInfo.title, onClick: undefined },
	]

	const collapsibleSections = [
		{
			title: categoryInfo.measurementQuestion,
			key: 'measurement',
			content: categoryInfo.measurement,
		},
		{
			title: `What does proficiency look like?`,
			key: 'explain-proficiency',
			content: categoryInfo.proficiencyExplained,
		},
		{
			title: `How can I support my students who are not yet proficient?`,
			key: 'intervention',
			content: categoryInfo.intervention,
		},
		{
			title: `How can I challenge my students who are already proficient?`,
			key: 'extension',
			content: categoryInfo.extension,
		},
	]
	return (
		<div className="text-primary-50" css="max-width: 890px; margin: 0 auto;">
			<Breadcrumbs crumbs={crumbs} />
			<Heading>
				<img
					alt=""
					src="https://resources-cdn.mission.io/public/pdf-resources/images/HexPatternBG.png"
				/>
				{categoryInfo.header}
				<div className="text-xl">{categoryInfo.subHeader}</div>
			</Heading>
			<div>
				{collapsibleSections.map(section => (
					<CollapsibleSection
						key={section.key}
						isOpen={focus?.includes(section.key)}
						onToggle={isOpen => {
							if (!isOpen) {
								setQuery({ focus: [...(focus ?? []), section.key] })
							} else {
								setQuery({
									focus: focus.filter(focused => focused !== section.key),
								})
							}
						}}
						title={section.title}
						content={section.content}
					/>
				))}
			</div>
		</div>
	)
}

/* Simple display for a list of navigation links.
 */
function Breadcrumbs({ crumbs }: { crumbs: Array<{ title: string, onClick: ?() => void }> }) {
	return (
		<div className="flex items-center">
			{crumbs.map((crumb, index) => {
				let title: React$Node = crumb.onClick ? (
					<ButtonLink onClick={crumb.onClick}>{crumb.title}</ButtonLink>
				) : (
					crumb.title
				)
				return (
					<>
						{index > 0 && <MdChevronRight size={20} />} {title}
					</>
				)
			})}
		</div>
	)
}

/**
 * A Section that can be expanded and collapsed at a click.
 * @param {string} props.title the title that will display as a header: on click it will expand to show content.
 * @param {React$Node} props.content content visible when section is set to open..
 * @param {boolean} props.isOpen whether the section content is visible.
 * @param {onToggle~cb} props.onToggle callback when the title is clicked: should toggle the isOpen state.
 *
 * @returns {React$Node}
 */
function CollapsibleSection({
	title,
	content,
	onToggle,
	isOpen,
}: {
	title: string,
	content: React$Node,
	onToggle: boolean => void,
	isOpen: boolean,
}): React$Node {
	return (
		<div css=":not(:first-child) {margin-top: var(--spacing2x-dont-use);}">
			<button
				onClick={() => onToggle(isOpen)}
				className="text-xl w-full flex justify-between items-center bg-transparent text-primary-50 border border-primary-50 rounded-lg p-4 font-bold focus:outline-none">
				<div>{title}</div>
				{isOpen ? (
					<FaCaretUp size={24} css="transform: translateY(-10%);" />
				) : (
					<FaCaretDown size={24} />
				)}
			</button>
			<Collapse expanded={isOpen}>
				<div className="prose prose-invert max-w-none px-4 pt-4">{content}</div>
			</Collapse>
		</div>
	)
}

const Heading = styled.div`
	text-align: center;
	width: 100%;
	padding: var(--spacing3x-dont-use) 0;
	margin: var(--spacing3x-dont-use) 0;
	position: relative;
	img {
		position: absolute;
		max-width: 540px;
		left: 50%;
		transform: translateX(-50%);
		height: auto;
	}
`
