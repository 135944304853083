import React from 'react'
import styled from 'styled-components/macro'
import { Helmet } from 'react-helmet'
import ProgressBar from '../../components/ProgressBar'
import Loading from '../../components/loading'
import { getStageCompletionPercentage, getOrderedLeafTrainings } from './helpers'
import { PageTitleHeader } from '../../styles/sharedComponents'
import { useTrainingStages, type StageWithIsComplete } from '../../services/hooks/training'
import StagesList from './StagesList'

/**
 * The trainings page.
 */
export default function Trainings(): React$Node {
	const { isLoading, stages: trainings } = useTrainingStages()

	const HEADER_ID = 'training-header'

	const metaDescription =
		'Get up to speed fast with quick tutorials. From mission setup to expert tips, become a pro in no time.'
	return (
		<TrainingsWrapper>
			<Helmet>
				<title>Training: Our Helpful Library of Tips & Tricks</title>
				<meta name="description" content={metaDescription} />
				<meta name="og:description" content={metaDescription} />
			</Helmet>
			<PageTitleHeader id={HEADER_ID}>Training</PageTitleHeader>
			{!trainings && isLoading && (
				<Loading
					style={{
						width: '30%',
						marginLeft: 'auto',
						marginRight: 'auto',
						marginTop: '5%',
					}}
					aria-describedby={HEADER_ID}
				/>
			)}
			{trainings && (
				<div>
					{trainings.map(training => (
						<TopLevelTraining training={training} key={training.id} />
					))}
				</div>
			)}
		</TrainingsWrapper>
	)
}

/**
 * A top level training that display the name, a progress bar, and all of its child trainings.
 */
const TopLevelTraining = ({ training }: { training: StageWithIsComplete }) => {
	const completionPercentage = getStageCompletionPercentage(training) || 0
	const HEADER_ID = `training-header-${training.id}`
	return (
		<div>
			<h3 id={HEADER_ID} className="text-2xl">
				{training.name}
			</h3>
			<div css="display: flex; align-items: center; gap: var(--spacing2x-dont-use);">
				<ProgressBar
					percent={completionPercentage}
					width={0.8}
					aria-labelledby={HEADER_ID}
				/>
				<span css="width: 50px; text-align: right;">
					{completionPercentage.toLocaleString('en', { maximumFractionDigits: 0 })}%
				</span>
			</div>
			{!training.stages ? (
				`No available stages for "${training.name}"`
			) : (
				<StagesList
					stages={getOrderedLeafTrainings(training.stages)}
					trainingId={training.id}
					css="margin: var(--spacing1x-dont-use) 0;"
				/>
			)}
		</div>
	)
}

const TrainingsWrapper = styled.div`
	${({ theme }) => `
		padding: 0 var(--spacing2x-dont-use);
	`}
`
