const domain = 'mission.io'
type Config = {|
	isProd: boolean,
	isStaging: boolean,
	missionServerUrl: string,
	simulationsApiUrl: string,
	loginServer: string,
	loginClientUrl: string,
	controlsUrl: string,
	sentryUrl: string,
	OSName: 'Windows' | 'MacOS' | 'Unknown',
	missionsAPIURL: string,
	missionControllerUrl: string,
	// Features that are enabled or disabled based on the environment
	featureFlags: {|
		// Skips the license permission checks, allowing all users to run all simulations. On by default in dev.
		skipLicensePermissionChecks: boolean,
		showPageLoadingVideo: boolean,
	|},
	// Network mocks that are enabled or disabled based on the environment. See `src/mocks/browser.js` for more info.
	networkMocks?: {|
		// Sets up network mocks for the announcements. Makes it so developers don't get so many errors in their console for failing to get announcements.
		announcements: boolean,
		// Sets up a network mock for the auto demo. Makes it so developers don't need to have the full autodemo in their local db.
		autoDemo: boolean,
		// Sets up a network mock for the user: See src/mocks/user.js to set up the user how you would expect.
		user: boolean,
		// Sets up a network mock for analytics. Makes it so developers have enough analytics data to work on UI for all different groups (school admin, district admin, teacher)
		analytics: boolean,
		// Sets up necessary network mocks so that the user has only run a single training mission. Useful when working on the walkthrough.
		finishedTrainingMissionWalkthrough: boolean,
	|},
	logos: {|
		main: string,
		glyph: string,
		pdf: string, // appears on grey background
	|},
	supportEmail: string,
	companyName: { base: string, withIndefiniteArticle: string },
|}

let config: Config

const logos = {
	main:
		'https://resources-cdn.mission.io/public/logos/mission.io/transparent-Rectangle-wm-WHITE-400x70px.png',
	glyph: 'https://resources-cdn.mission.io/public/logos/mission.io/sq-icon-HB.png',
	pdf: 'https://resources-cdn.mission.io/public/logos/mission.io/transparent-Rectangle-wm-HB.png',
}

const supportEmail = 'support@mission.io'

const companyName = { base: 'Mission.io', withIndefiniteArticle: 'a Mission.io' }

if (process.env.NODE_ENV === 'production') {
	let isStaging = process.env.REACT_APP_ENV === 'staging'

	config = {
		isProd: true,
		isStaging: isStaging,
		missionServerUrl: isStaging
			? `https://staging-mission-server.${domain}`
			: `https://mission-server.${domain}`,
		simulationsApiUrl: `https://simulations-api.${domain}`,
		loginServer: isStaging
			? `https://staging-login-server.${domain}`
			: `https://login-server.${domain}`,
		loginClientUrl: `https://${isStaging ? `staging.` : ``}login.${domain}`,
		controlsUrl: isStaging ? `https://staging.launch.${domain}` : `https://launch.${domain}`,
		sentryUrl: isStaging
			? `https://a6fef49308b74a428ddc7c816c92f39d@o185870.ingest.sentry.io/1470042`
			: `https://7d2f2ed94b4f4e94864d0a69d8352f3f@o185870.ingest.sentry.io/1459480`,
		OSName: `Unknown`,
		missionsAPIURL: isStaging
			? `https://staging-missions-api.${domain}`
			: `https://missions-api.${domain}`,
		missionControllerUrl: isStaging ? `https://staging.run.${domain}` : `https://run.${domain}`,
		featureFlags: {
			skipLicensePermissionChecks: false,
			showPageLoadingVideo: true,
		},
		logos,
		supportEmail,
		companyName,
	}
} else {
	const devHost =
		process.env.REACT_APP_USE_LOCALHOST_DOT_COM === `true` ? `localhost.com` : `localhost`
	config = {
		isProd: false,
		isStaging: false,
		missionServerUrl: `http://${devHost}:8000`,
		simulationsApiUrl: `https://simulations-api.mission.io`,
		loginServer: `http://${devHost}:3000`,
		loginClientUrl: `http://${devHost}:3010`,
		controlsUrl: `http://${devHost}:8011`,
		sentryUrl: '',
		OSName: 'Unknown',
		missionsAPIURL: `http://${devHost}:8100`,
		missionControllerUrl: `http://${devHost}:8017`,
		featureFlags: {
			skipLicensePermissionChecks: false,
			showPageLoadingVideo: true,
		},
		networkMocks: {
			announcements: true,
			autoDemo: false,
			user: false,
			analytics: false,
			finishedTrainingMissionWalkthrough: false,
		},
		logos,
		supportEmail,
		companyName,
	}
	if (process.env.NODE_ENV === 'development') {
		config.featureFlags.skipLicensePermissionChecks = true
		config.networkMocks.autoDemo = true
		config.featureFlags.showPageLoadingVideo = false
	}
	// config.simulationsApiUrl = `http://${devHost}:5001`
}

// set OSName
if (navigator.appVersion.indexOf('Win') !== -1) config.OSName = 'Windows'
if (navigator.appVersion.indexOf('Mac') !== -1) config.OSName = 'MacOS'

export { config }
export default config
