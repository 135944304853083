import type { SelScores } from '../../../store/types'
import type { ScoreFormatType } from '../constants'
import type { Category, StudentMissionScoreData } from './constants'
import moment from 'moment'

import { RenderNumberScore, getAccessorAndCellFunctions } from '../AnalyticsCharts/TableHelper'
import { NAME } from '../constants'

/**
 * getStudentData - translate the mission score data into scores to show up on the table. This is only used when creating CSVs, so
 * this function can probably be removed without too much difficulty.
 *
 * @@param {Object} args - the arguments
 * @param {StudentMissionScoreData[]} args.missions - the missions score data to translate scores for
 *
 * @return {TranslatedScore[]}
 */
export function getStudentData({
	missions,
}: {
	missions: StudentMissionScoreData[],
}): Array<{
	...SelScores,
	missionName: string,
	missionDate: Date,
	missionScore: ?number,
}> {
	return missions.map(scoreData => {
		const selScore = scoreData.scores
		return {
			...selScore,
			missionName: scoreData.missionName,
			missionDate: scoreData.missionDate,
			missionScore: scoreData.studentPoints,
		}
	})
}

/**
 * getStudentColumns - get the table columns for student tables. This is only used to get columns when creating CSVs, so
 * some of the UI fields are not necessary on the columns. This function can probably be removed without too much difficulty.
 *
 * @param {Object} args - the arguments
 * @param {Array<Category>} args.categories - the analytics categories to display on the table
 * @param {ScoreFormatType} args.displayType - what type of score to display for the data points
 *
 * @return {any[]} the columns for the table
 */
export function getStudentColumns({
	categories,
	displayType,
}: {
	categories: Array<Category>,
	displayType: ScoreFormatType,
}): any[] {
	const columns = [
		{
			header: 'Mission',
			id: NAME,
			accessorKey: 'missionDate',
			sortingFn: 'datetime',
			minSize: 100,
			meta: {
				csvAccessor: element =>
					`${moment(element.missionDate).format('l')} ${element.missionName}`,
			},
		},
		{
			header: 'Mission Score',
			id: 'missionScore',
			accessorKey: 'missionScore',
			cell: RenderNumberScore,
			minSize: 75,
		},
	]
	categories.forEach(category => {
		const { meta, ...rest } = getAccessorAndCellFunctions(displayType, category.accessor)
		columns.push({
			header: category.title,
			id: `${category.key}-${displayType}`,
			minSize: 75,
			...rest,
			meta,
		})
	})
	return columns
}
