import React from 'react'
import AnalyticsPage from './Analytics'
import CategoryInfoPage from './CategoryInfoPage'
import { useQueryParam, StringParam } from 'use-query-params'

function Analytics(): React$Node {
	const [categoryQuery] = useQueryParam('category', StringParam)
	if (categoryQuery) {
		return <CategoryInfoPage />
	}
	return <AnalyticsPage />
}

export { Analytics }
