import React from 'react'
import { Helmet } from 'react-helmet'
import { useHistory, useParams } from 'react-router-dom'
import Library from './Library'
import { QuestionGroup } from './components'

import type { ClientQuestion as Question } from '@mission.io/question-toolkit'

type Props = {
	myQuestions: Question[],
}

export default function Questions(props: Props): React$Node {
	const params = useParams()
	const history = useHistory()
	const questionGroupId = params.id

	const metaDescription =
		'Craft personalized questions for your missions with our easy-to-use tool. Get the most out of every mission!'

	return (
		<div className="Questions">
			<Helmet>
				<title>Questions Library: Enhance Missions With Your Own Custom Questions</title>
				<meta name="description" content={metaDescription} />
				<meta name="og:description" content={metaDescription} />
			</Helmet>
			{questionGroupId ? (
				<QuestionGroup questionGroupId={questionGroupId} leave={history.goBack} />
			) : (
				<Library />
			)}
		</div>
	)
}
