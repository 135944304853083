// @flow
import { createContext, useContext } from 'react'
import type { NameLookup } from '../../download/questions'
import type { DBQuestion } from '../../models/Question'
import type { AnalyticsObject, SelScoresByEntityId } from '../../models/Analytics'
import type { ClassStudentAnalytics } from '@mission.io/mission-toolkit'
import { getAllDemoAnalyticsObjects } from './demoAnalytics'

export type SpecificMissionContextType = {
	nameLookup: NameLookup,
	individualSelScores: SelScoresByEntityId,
	isDemo: boolean,
	selectedMission: ?AnalyticsObject,
}
// A Context for analytics components to access scores for students and other pieces of data necessary to display analytics. This is
// used mainly to avoid passing information through many levels of components.
export const SpecificMissionContext: React$Context<SpecificMissionContextType> = createContext({
	nameLookup: {},
	individualSelScores: {},
	isDemo: false,
	selectedMission: null,
})

// A hook that enables a child component of the SpecificMissionContext.Provider to access the context of a specific mission's analytics.
export const useSpecificMissionContext = (): SpecificMissionContextType => {
	return useContext(SpecificMissionContext)
}

// Gets the class analytics for a selected mission.
export const useSelectedMissionAnalytics = (): ?ClassStudentAnalytics => {
	const { selectedMission } = useSpecificMissionContext()
	if (selectedMission) {
		return selectedMission.analytics
	}
	return null
}

const demoQuestionMap = getAllDemoAnalyticsObjects().demoQuestionMap

export const useDemoQuestionMap = (): ?{ [questionId: string]: DBQuestion } => {
	const { isDemo } = useSpecificMissionContext()

	if (isDemo) {
		return demoQuestionMap
	}
	return null
}
