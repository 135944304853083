// @flow
import React, { useMemo } from 'react'
import type { SelScores } from '../../../../store/types'
import styled from 'styled-components/macro'
import { CATEGORIES, SCORES, translateScoreToProficiencyLevel } from './constants'
import Legend from './Legend'
import { useSpecificMissionContext } from '../../SpecificMissionContext'
import { ListItem, Line, ColorLevel } from './sharedComponents'
import { Tooltip } from 'react-tooltip'
import { infoIdToCategory } from '../../CategoryInfoPage/content'
import { useQueryParams } from 'use-query-params'
import classnames from 'classnames'
import { getFullName } from '../../../../utility/helpers'
import { Button } from '@mission.io/styles'
import type { SelScoresByEntityId } from '../../../../models/Analytics'

const TOOLTIP_ID = 'ProficiencyBarGraph-tooltip'

/**
 * A Bar Graph that shows the distribution of a classes proficiency scores for each analytics metric category:
 * Questions, Application, Collaboration, CriticalThinking, Resilience, and Initiative.
 * @param {{ [string]: SelScores }} props.analytics - The analytics object containing all the students scores for the mission.
 * @returns
 */
export default function ProficiencyBarGraph(): React$Node {
	const [, setQuery] = useQueryParams()
	const { nameLookup } = useSpecificMissionContext()

	return (
		<div className="flex py-4 mb-3">
			<Legend className="border-r pe-6 me-6" />
			<Scores />
			<Tooltip
				id={TOOLTIP_ID}
				className="z-50 !bg-primary-800 !text-white"
				opacity={1}
				clickable
				placement="left"
				render={({ activeAnchor }: { activeAnchor: HTMLElement | null }) => {
					if (!activeAnchor) {
						return null
					}

					const { level: _level, studentsInLevel, categoryId } = activeAnchor.dataset
					const level = parseInt(_level, 10)

					return (
						<div className="max-h-[80vh] flex flex-col gap-2 text-center">
							{SCORES.find(v => v.level === level)?.description}
							<div className="grid grid-cols-2 gap-2 overflow-y-auto flex-auto">
								{studentsInLevel
									.split(',')
									.sort((a, b) => {
										const aName =
											nameLookup[a]?.lastName ||
											nameLookup[a]?.firstName ||
											''
										const bName =
											nameLookup[b]?.lastName ||
											nameLookup[b]?.firstName ||
											''
										return aName.localeCompare(bName)
									})
									.map(id => {
										const name = getFullName(nameLookup[id])
										return (
											<Button
												outline
												className="text-xs"
												onClick={() => {
													setQuery({ student: id })
												}}
												key={name}>
												{name}
											</Button>
										)
									})}
							</div>
							<Button
								onClick={() => {
									setQuery({
										focus: level > 2 ? 'extension' : 'intervention',
										category: infoIdToCategory[categoryId],
									})
								}}
								className="text-xs">
								{level > 2 ? 'Extension Ideas' : 'Intervention Ideas'}
							</Button>
						</div>
					)
				}}
			/>
		</div>
	)
}
/**
 * Display scores in a bar graph for each analytics category.
 * @returns {React$Node}
 */
function Scores() {
	return (
		<ScoresStyle>
			<div className="flex">
				<div className="text-right">
					{CATEGORIES.map(({ title, lookup }) => (
						<ListItem key={title} className="justify-end">
							{title}
						</ListItem>
					))}
				</div>
				<div className="flex-1 ml-2">
					{CATEGORIES.map(({ title, lookup, id }) => (
						<ListItem key={title}>
							<ScoreRow
								lookup={lookup}
								// TODO: Support national averages
								// nationalAverages={}
								title={title}
								categoryId={id}
							/>
						</ListItem>
					))}
				</div>
			</div>
		</ScoresStyle>
	)
}

/**
 * Sorts students into 4 different proficiency levels for a specific category score lookup.
 * @param {SelScores => ?number} lookup takes an SelScores instance and returns a specific score for a category (application, knowledge, etc...)
 * @param { {[string]: SelScores}} individualSelScores a map of student ids to their SelScore object
 */
export const useSortStudentsIntoLevels = (
	lookup: SelScores => ?number,
	individualSelScores: SelScoresByEntityId
): {
	classAverage: number,
	levelsByStudentId: [string[], string[], string[], string[]],
	scoredStudentCount: number,
} => {
	const memoizedData = useMemo(() => {
		let classTotalScore = 0
		let scoredStudentCount = 0
		const levelsByStudentId: [string[], string[], string[], string[]] = [[], [], [], []]
		Object.keys(individualSelScores).forEach(studentId => {
			const score = translateScoreToProficiencyLevel(lookup(individualSelScores[studentId]), {
				getDecimals: true,
			})
			if (score == null) {
				return
			}
			classTotalScore += score
			scoredStudentCount += 1
			const levelCategory = Math.min(Math.floor(score), 4)
			levelsByStudentId[levelCategory - 1].push(studentId)
		})
		return {
			classAverage: classTotalScore / scoredStudentCount,
			levelsByStudentId,
			scoredStudentCount,
		}
	}, [lookup, individualSelScores])
	return memoizedData
}

/** A row in the bar graph to display how a class scored in a given category */
function ScoreRow({
	lookup,
	nationalAverages,
	title,
	categoryId,
}: {
	nationalAverages?: SelScores,
	lookup: SelScores => ?number,
	title: string,
	categoryId: string,
}) {
	const { individualSelScores } = useSpecificMissionContext()
	const { classAverage, levelsByStudentId, scoredStudentCount } = useSortStudentsIntoLevels(
		lookup,
		individualSelScores
	)
	const nationalAverage = nationalAverages
		? translateScoreToProficiencyLevel(lookup(nationalAverages), { getDecimals: true })
		: null
	const firstVisibleLevel =
		levelsByStudentId.findIndex(studentsInLevel => studentsInLevel.length) + 1
	return (
		<ScoreRowStyle>
			{levelsByStudentId.map((studentsInLevel, levelIndex) => {
				const level = levelIndex + 1
				const percentStudentsAtLevel = Math.round(
					(studentsInLevel.length / scoredStudentCount) * 100
				)
				const showClassAverage = Math.floor(classAverage) === level
				const showNationalAverage = nationalAverage
					? Math.floor(nationalAverage) === level
					: false

				return percentStudentsAtLevel ? (
					<ColorLevel
						key={level}
						data-tooltip-id={TOOLTIP_ID}
						data-category-id={categoryId}
						data-students-in-level={studentsInLevel.join(',')}
						data-level={level}
						level={level}
						className={classnames(
							'relative',
							Boolean(level > firstVisibleLevel && percentStudentsAtLevel) && 'ml-0.5'
						)}
						style={{ width: `${percentStudentsAtLevel}%` }}>
						<div className="content">
							{percentStudentsAtLevel ? `${percentStudentsAtLevel}%` : null}
							{showClassAverage && (
								<AverageLine average={classAverage} level={level} />
							)}
							{showNationalAverage && (
								<AverageLine dotted average={nationalAverage} level={level} />
							)}
						</div>
					</ColorLevel>
				) : null
			})}
		</ScoreRowStyle>
	)
}

const AverageLine = styled(Line)`
	position: absolute;
	left: ${({ average, level }) => (average - level) * 100}%;
	top: 0;
`

const ScoresStyle = styled.div`
flex: 1;'
`
const ScoreRowStyle = styled.div.attrs(() => ({
	className: 'w-full h-6 flex overflow-hidden [&_.ColorLevel]:text-xs',
}))`
	border-radius: 1em;
	${ColorLevel} {
		text-align: center;
		display: flex;
		justify-content: center;
		align-items: center;
	}
`
