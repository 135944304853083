import config from '../../config'
import NetworkCommunicator from '../../services/NetworkCommunicator'

import type { UserOnLicense } from './types'
import type { SimulationGroup } from '../../store/types'

/**
 * Calls the PUT route on loginServer's /remove-license-user route,
 * and passes in the passed in userId.
 * OnSuccess or onError is called accordingly.
 */
export function removeUserOnLicense(
	userId: string,
	onSuccess: () => void,
	onError: () => void
): Promise<void> {
	return NetworkCommunicator.PUT('/remove-license-user?id=' + userId, {
		host: config.loginServer,
		withCredentials: true,
	})
		.then(onSuccess)
		.catch(e => {
			console.error(e)
			onError()
		})
}

/**
 * Calls the PUT route on loginServer's /change-user-role route,
 * and passes in the passed in userId and newRole.
 * OnSuccess or onError is called accordingly.
 */
export function editUserOnLicense(
	userId: string,
	newRole: string,
	onSuccess: () => void,
	onError: () => void
): Promise<void> {
	return NetworkCommunicator.PUT(`/edit-roles?id=${userId}&newRole=${newRole}`, {
		host: config.loginServer,
		withCredentials: true,
	})
		.then(onSuccess)
		.catch(e => {
			console.error(e)
			onError()
		})
}

export type GetUsersOnLicenseReturnType = {
	licenseUsers: UserOnLicense[],
	pendingInvitations: string[],
	otherUsersInOrganization: UserOnLicense[],
}

/**
 * Makes a call to fetch all users on the current user's license.
 * @return {GetUsersOnLicenseReturnType}
 */
export async function getUsersOnLicense(): Promise<GetUsersOnLicenseReturnType> {
	const res = await NetworkCommunicator.GET('/license-users', {
		host: config.loginServer,
		withCredentials: true,
	})
	const mapUser = userOnLicense => {
		return {
			name: userOnLicense.firstName + ' ' + userOnLicense.lastName,
			roles: userOnLicense.roles || [],
			email: userOnLicense.email,
			id: String(userOnLicense._id),
			school: userOnLicense.schoolId
				? {
						id: userOnLicense.schoolId,
						name: userOnLicense.schoolName,
				  }
				: null,
		}
	}
	return {
		licenseUsers: res.users.map(mapUser),
		pendingInvitations: res.pendingInvitations,
		otherUsersInOrganization: res.otherUsersInOrganization.map(mapUser),
	}
}

/**
 * fetchSimulationGroups - fetch all the simulation groups from the server
 *
 * @return {Promise<?(SimulationGroup[])>} - the simulation groups
 */
export function fetchSimulationGroups(): Promise<?(SimulationGroup[])> {
	return NetworkCommunicator.GET('/api/groups', {
		host: config.simulationsApiUrl,
		withCredentials: true,
	}).then(groups => groups.groups)
}

/**
 * Calls the POST route on loginServer's /invite route.
 * OnSuccess or onError is called accordingly.
 */
export function inviteUsersToLicense({
	emails,
	onSuccess,
	onFailedEmails,
	onError,
}: {
	emails: string[],
	onSuccess: ({ resentEmails?: string[], failedEmails?: string[] }) => void,
	onFailedEmails?: (string[]) => void,
	onError?: () => void,
}): Promise<void> {
	return NetworkCommunicator.POST('/invite', {
		host: config.loginServer,
		withCredentials: true,
		body: { emailAddresses: emails },
	})
		.then(res => {
			if (res.failedEmails && onFailedEmails) {
				return onFailedEmails(res.failedEmails)
			}
			return onSuccess(res)
		})
		.catch(e => {
			console.error(e)
			onError && onError()
		})
}

/**
 * Calls the PUT route on loginServer's /api/licenses/revoke-invitation route,
 * to remove an email's license invitation.
 * OnSuccess or onError is called accordingly.
 */
export function removePendingInvite(
	email: string,
	onSuccess: () => void,
	onError: () => void
): Promise<void> {
	return NetworkCommunicator.PUT('/api/licenses/revoke-invitation?email=' + email, {
		host: config.loginServer,
		withCredentials: true,
	})
		.then(onSuccess)
		.catch(e => {
			console.error(e)
			onError()
		})
}
