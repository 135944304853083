import type { SubjectType } from '../models/QuestionGroup'

export type CurriculumStandardGrade =
	| 'K'
	| '1'
	| '2'
	| '3'
	| '4'
	| '4'
	| '5'
	| '6'
	| '7'
	| '8'
	| '9'
	| '10'
	| '11'
	| '12'
	| 'MS'
	| 'HS'

export type OldCurriculumStandard = {
	keyword: string,
	summary: string,
	full: string,
	name: string,
	url: string,
	type?: string,
	grade?: CurriculumStandardGrade,
}

export type StandardSet = {|
	_id: string,
	name: string,
	abbreviation: string,
	color: string,
	scope: 'NATIONAL' | 'STATE_SPECIFIC',
	states: Array<{|
		_id: string,
		name: string,
		abbreviation: string,
	|}>,
	subject: SubjectType,
|}

export type CurriculumStandard = {|
	_id: string,
	keyword: string,
	summary: string,
	full: string,
	name: string,
	url: string,
	grade: string,
	standardSet: StandardSet,
|}

export type SimpleCurriculumStandard = {|
	_id: string,
	keyword: string,
	summary: string,
	full: string,
	name: string,
	url: string,
	grade: string,
	standardSetId: string,
|}

// These are the actual names of the subjects as defined in "categories" in the db. If those change, these must change as well
export const MATH = 'Math'
export const SCIENCE = 'Science'
export const LITERACY = 'Literacy/ELA'

/**
 * groupStandardsForState - separate the standards into the groups which relate to the user
 *
 * @param  {?string} stateAbbreviation - the state that the user should see the standards for
 * @param  {CurriculumStandard[]} standards - the standards to separate into groups
 *
 * @returns {
 *	stateSpecific: CurriculumStandard[], - the standards relevant to the user's state
 *	nationSpecific: CurriculumStandard[], - the standards relevant to the user's nation
 *	otherStandards: CurriculumStandard[], - any standards not in the other two groups
 * }
 */
export function groupStandardsForState(
	stateAbbreviation: ?string,
	standards: CurriculumStandard[],
	standardSets: StandardSet[]
): {
	stateSpecific: CurriculumStandard[],
	nationSpecific: CurriculumStandard[],
	otherStandards: CurriculumStandard[],
} {
	// If the state is not specified in any of the standard sets, use the national standards for the state
	const stateHasSpecificStandardSet = standardSets.some(standardSet =>
		standardSet.states.some(state => state.abbreviation === stateAbbreviation)
	)
	const stateSpecific = []
	const nationSpecific = []
	const otherStandards = []

	standards.forEach(standard => {
		if (!standard.standardSet) {
			otherStandards.push(standard)
			return
		}
		if (
			(!stateHasSpecificStandardSet && standard.standardSet.scope === 'NATIONAL') ||
			standard.standardSet.states.some(state => state.abbreviation === stateAbbreviation)
		) {
			stateSpecific.push(standard)
			return
		}
		if (standard.standardSet.scope === 'NATIONAL') {
			nationSpecific.push(standard)
			return
		}
		otherStandards.push(standard)
	})

	return {
		stateSpecific: sortStandardList(stateSpecific),
		nationSpecific: sortStandardList(nationSpecific),
		otherStandards: sortStandardList(otherStandards),
	}
}

/**
 *sortStandardList - sort the standards by type in alphabetical order
 *
 * @param  {CurriculumStandard[]} standards - the standards to sort
 *
 * @returns CurriculumStandard - the sorted standards
 */
function sortStandardList(standards: CurriculumStandard[]): CurriculumStandard[] {
	if (!standards.length) {
		return standards
	}

	const typeMap: { [standardType: string]: CurriculumStandard[] } = {}
	standards.forEach(standard => {
		const type = standard.standardSet.abbreviation || standard.standardSet.name
		if (!typeMap[type]) {
			typeMap[type] = []
		}
		typeMap[type].push(standard)
	})

	let orderedStandards = []
	Object.keys(typeMap)
		.sort()
		.forEach(type => {
			orderedStandards.push(
				...typeMap[type].sort((a, b) =>
					a.name.localeCompare(b.name, undefined, { numeric: true, sensitivity: 'base' })
				)
			)
		})

	return orderedStandards
}

/**
 * getMostRelevantStandards - get the standards most relevant to the user from the list of standards
 *
 * @param  {?string} stateAbbreviation - the state the user wants to get standards for
 * @param  {CurriculumStandard[]} standards - the standard list to get the relevant standards from
 * @param {boolean} [fallback=false] - Whether or not to fallback to the 'nation' and 'other' standards if the mission does not
 * 									have any standards in the requested state
 *
 * @returns CurriculumStandard[] - the standards most relevant tot he users
 */
export function getMostRelevantStandards({
	stateAbbreviation,
	standards,
	fallback = false,
	standardSets,
}: {
	stateAbbreviation: ?string,
	standards: CurriculumStandard[],
	fallback?: boolean,
	standardSets: StandardSet[],
}): CurriculumStandard[] {
	if (!standards.length) {
		return standards
	}
	const groupedStandards = groupStandardsForState(stateAbbreviation, standards, standardSets)
	const standardsToUse = groupedStandards.stateSpecific
	if (standardsToUse.length > 0 || !fallback) {
		return standardsToUse
	}

	return groupedStandards.nationSpecific.length > 0
		? groupedStandards.nationSpecific
		: groupedStandards.otherStandards
}

/**
 * Get the list of standard types for the given state
 *
 * @param  {string} stateAbbreviation - the state to get the standard types for
 * @param {StandardSet[]} standardSets - the list of standard sets to filter
 *
 * @returns {StandardSet[]} The list of standard sets for the given state
 */
export function getStandardsForState(
	stateAbbreviation: string,
	standardSets: StandardSet[]
): StandardSet[] {
	const standardsForState = []
	const nationalStandards = []
	for (const standardSet of standardSets) {
		if (standardSet.states.some(state => state.abbreviation === stateAbbreviation)) {
			standardsForState.push(standardSet)
		}
		if (standardSet.scope === 'NATIONAL') {
			nationalStandards.push(standardSet)
		}
	}
	if (standardsForState.length === nationalStandards.length) {
		return standardsForState
	}
	const existingSubjects = new Set(standardsForState.map(standard => standard.subject.name))
	// add standards from the national standards that have a subject that we do not have in the existing standardsForState
	return [
		...standardsForState,
		...nationalStandards.filter(standardSet => !existingSubjects.has(standardSet.subject.name)),
	]
}
