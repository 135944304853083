import { AUTOMATED_SIMULATION_STATUSES } from '../constants'
import type { GradeEnum } from '../store/missionPrep'
import type { ClientQuestion as Question } from '@mission.io/question-toolkit'
import type { CurriculumStandard, OldCurriculumStandard } from '../utility/standards'

export const READABLE_CONTROL_SETS = {
	JUNIOR: 'K-3',
	JUNIOR_PLUS: '4+',
}

const CONTROL_SETS = {
	'K-3': 'K-3',
	'4+': '4+',
}

export type ControlSetType = $Keys<typeof CONTROL_SETS>

export type Simulation = {|
	_id: string,
	name: string, // Simulation title
	originalName: string, // TODO delete this when automated simulations are merged in if it doesn't exist
	imageUrl: string,
	imageThumbnailUrl: string,
	summary: string, // A brief overview (usually 1-2 paragraphs) of the simulation.
	questions: any[],
	isQuest: boolean,
	// new stuff:
	crossCurricularConnections: string, // Information students apply across different curriculums during the mission.
	briefing: string, // Introductory information to tell students before beginning mission.
	checkpoints: string[], // Key mission points that help the teacher through the story. Commonly includes actions students make during the mission.
	prerequisiteKnowledge: string, // Information students should be taught before the mission.
	standards: OldCurriculumStandard[], // Curriculum standards covered in this simulation.
	suggestedVocabulary: Array<{| word: string, definition: string |}>, // vocabulary words for the students to know for the mission
	walkthroughVideoUrl: ?string,
	briefingVideoUrl: ?string,
	grades: GradeEnum[],
	duration: string,
	categories: string[],
|}

export type AutomatedSimulation = {|
	_id: string,
	name: string,
	subjectMatter?: string,
	summary: string,
	grades: string[],
	categories: string[],
	narration: string,
	questions: Question[],
	imageUrl: ?string,
	imageThumbnailUrl: ?string,
	walkthroughVideoUrl: ?string,
	briefingVideoUrl: ?string,
	briefing: string,
	status: $Keys<typeof AUTOMATED_SIMULATION_STATUSES>,
	standards: CurriculumStandard[],
	prerequisiteKnowledge: string,
	crossCurricularConnections: string,
	suggestedVocabulary: Array<{| word: string, definition: string |}>,
	controlSet: ControlSetType,
	maxQuestions: number,
	science3D: {|
		phenomenon: string,
		scienceAndEngineeringPractices: Array<{|
			_id: string,
			practiceText: string,
			description: string,
		|}>,
		crosscuttingConcepts: Array<{|
			_id: string,
			conceptText: string,
			description: string,
		|}>,
		disciplinaryCoreIdeas: Array<{|
			_id: string,
			ideaText: string,
			description: string,
		|}>,
	|},
|}
