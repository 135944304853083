import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { Marquee } from '../Marquee'
import styled from 'styled-components/macro'
import { isSimulationAutomated } from '../../store/simulation/index'
import ComingSoonImage from '../../assets/ComingSoon.jpg'
// $FlowIgnore[missing-export] `ReactComponent` is a valid way to import an SVG in create-react-app
import { ReactComponent as NewMissionBadge } from '../../assets/NewMissionBadge.svg'
import { AUTOMATED_SIMULATION_STATUSES, STATE_KEYS } from '../../constants'
import Markdown from '../Markdown'
import { useRelevantStandardsForSimulation } from '../../services/hooks/standards'
import { StandardBubble } from '../../routes/mission/prep/PrepMission'
import { useSimulationPermissions } from '../../services/hooks/permissions'
import MdLock from 'react-icons/lib/md/lock'
import FaClock from 'react-icons/lib/fa/clock-o'

import type { AutomatedSimulation } from '../../models'
import { useTheme } from 'styled-components'
import { getBannerInfo, getSmallGradesRanges } from '../../utility/helpers'
import { screenSizes_dontUse } from '../../styles/theme'
import type { ThemeType } from '@mission.io/styles'
import classnames from 'classnames'

type Props = {
	simulation: AutomatedSimulation,
	link: string,
	className?: string,
}

export default function SimulationTile({ simulation, link, className }: Props): React$Node {
	const [hovered, setHovered] = useState(false)
	const [headersHovered, setHeadersHovered] = useState(false)
	const simulationPermissions = useSimulationPermissions(simulation._id)
	const isAutomated = useSelector(state => isSimulationAutomated(state, simulation._id))
	const userStandards = useRelevantStandardsForSimulation(simulation)
	const theme = useTheme()

	if (!isAutomated || !simulationPermissions) {
		// note: if it is a mission and doesn't have a simulation associated
		// then this will break, therefore skip it, it's bad data.
		return null
	}

	let gradeRanges: string[] = getSmallGradesRanges(simulation.grades || [])

	const elementId = `restore-${simulation._id}`
	const locationState = {
		[STATE_KEYS.restoreToTop]: 'prep-mission',
	}
	const clickyProps = !simulationPermissions.userCanRun
		? { as: 'div' }
		: {
				to: { pathname: link, state: locationState },
				id: elementId,
				'data-walkthrough': 'mission-tile',
		  }

	const banner = getBannerInfo(simulation.status)

	return (
		<StyledLink
			{...clickyProps}
			onMouseLeave={() => setHovered(false)}
			className={classnames('rounded-2xl bg-transparent', className)}>
			<div
				onMouseLeave={() => setHeadersHovered(false)}
				onMouseEnter={() => setHeadersHovered(true)}>
				<div className="py-2.5 px-2 bg-primary-750">
					<Marquee
						shouldScroll={headersHovered}
						onMouseLeaveOverride={() => {}}
						className="min-w-0 text-center text-xl font-bold">
						{simulation.name}
					</Marquee>
				</div>
				{simulation.subjectMatter && (
					<Marquee
						shouldScroll={headersHovered}
						onMouseLeaveOverride={() => {}}
						className="text-center py-1 px-2 text-primary-950 bg-primary-50">
						{simulation.subjectMatter}
					</Marquee>
				)}
			</div>
			<div className="w-full h-40 relative rounded-t-md flex-grow">
				<img
					onMouseEnter={() => setHovered(true)}
					src={simulation.imageThumbnailUrl || ComingSoonImage}
					alt={simulation.name}
					css="object-fit: cover; width: 100%; height: 100%;"
				/>
				{!simulationPermissions.userCanRun ? (
					<div
						onMouseEnter={() => setHovered(true)}
						className="absolute inset-0 opacity-60 bg-gray-a p-1 text-4xl">
						<MdLock />
					</div>
				) : simulationPermissions.userCanOnlyRunDueToPromotion ? (
					<div onMouseEnter={() => setHovered(true)} className="absolute inset-0 text-xl">
						<div className="text-star bg-deep-blue/50 w-min rounded-br-xl p-0.5">
							<FaClock aria-label="Promotion" />
						</div>
					</div>
				) : null}
				{banner && (
					<div
						className={classnames(
							'absolute -left-[10%] w-[120%] text-center -rotate-[20deg] italic pointer-events-none text-white',
							banner?.longText ? 'top-[35%] text-2xl' : 'top-[30%] text-4xl'
						)}
						style={{ backgroundColor: banner.color }}>
						{banner.text}
					</div>
				)}
				{simulation.status === AUTOMATED_SIMULATION_STATUSES.NEW && (
					<NewMissionBadge className="absolute bottom-2 left-2 opacity-90 max-w-[40%] max-h-[40%] pointer-events-none" />
				)}
				{gradeRanges.length === 1 && (
					<GradeIndicator className="min-w-10 h-10">{gradeRanges[0]}</GradeIndicator>
				)}
				{userStandards?.length ? (
					<BubbleWrap>
						<StyledStandardBubble background={userStandards[0].standardSet.color}>
							{userStandards[0].standardSet.abbreviation} {userStandards[0].name}
						</StyledStandardBubble>
						{userStandards.length > 1 ? (
							<StyledStandardBubble background={userStandards[0].standardSet.color}>
								+{userStandards.length - 1}
							</StyledStandardBubble>
						) : null}
					</BubbleWrap>
				) : simulation.standards.length ? (
					<BubbleWrap>
						<StyledStandardBubble background={theme.accent}>Bonus</StyledStandardBubble>
					</BubbleWrap>
				) : null}
			</div>
			{hovered && (
				<div className="absolute inset-0 opacity-90 p-2 bg-primary-750 pointer-events-none">
					<div className="h-full flex items-center">
						<Marquee
							scrollDirection="vertical"
							autoplay
							leadingTime={3000}
							bufferForNoScroll={5}>
							{!simulationPermissions.userCanRun ? (
								<WhyLocked>
									<MdLock className="inline -mt-1" />{' '}
									{simulationPermissions.unlockMethods.length
										? simulationPermissions.unlockMethods.includes(
												'PAID_LICENSE'
										  )
											? 'Upgrade to a paid license to run this mission'
											: 'Your license does not allow running this mission'
										: 'This mission is not currently available'}
								</WhyLocked>
							) : null}
							<Markdown className="prose-invert text-white text-center">
								{simulation.summary}
							</Markdown>
						</Marquee>
					</div>
				</div>
			)}
		</StyledLink>
	)
}

function StyledStandardBubble({ background, ...props }: { background: string }) {
	return (
		<StandardBubble
			{...props}
			style={{ backgroundColor: background }}
			className="text-white m-1 text-xs mb-0"
		/>
	)
}

const GradeIndicator = styled.div.attrs({ className: 'text-xl font-medium' })`
	text-align: center;
	position: absolute;
	right: 0;
	bottom: 0;
	pointer-events: none;
	${({ theme }: { theme: ThemeType }) => `
		background-color: ${theme.primary};
		color: ${theme.white};
		max-width: calc(var(--spacing1x-dont-use) * 10);
		border-top-left-radius: calc(var(--spacing5x-dont-use) - var(--spacing-half-dont-use));
		padding-top: var(--spacing1x-dont-use);
		padding-left:  var(--spacing2x-dont-use);
		padding-right: var(--spacing1x-dont-use);
	`}
`

const StyledLink = styled(Link)`
	display: inline-flex;
	flex-direction: column;
	${({ theme }) => `
		margin: var(--spacing1x-dont-use);
		
		// Mobile First: 2 columns
		width: calc(50% - var(--spacing1x-dont-use) * 2);
		
		// Tablet: 3 columns
		@media (min-width: ${screenSizes_dontUse.tabletDevice}px) {
			width: calc(33.3% - var(--spacing1x-dont-use) * 2);
		
		}
		
		//Desktop: 4 columns
		@media (min-width: ${screenSizes_dontUse.desktopDevice}px) { 
			width: calc(25% - var(--spacing1x-dont-use) * 2);
			
		}
	`}

	transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
	position: relative;
	overflow: hidden;
	box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
	&:hover {
		box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
	}

	color: inherit;
`

const WhyLocked = styled.div`
	color: ${({ theme }) => theme.warning};
	text-align: center;
`

const BubbleWrap = styled.div`
	position: absolute;
	top: 0;
	right: 0;
	left: 0;
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	opacity: 0.9;
	pointer-events: none;
`
