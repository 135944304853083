import React from 'react'
import styled from 'styled-components/macro'
import { HEADER_TO_CATEGORY, NA, scoreFormatEnum } from '../constants'
import { isFinite } from 'lodash'
import FaCheck from 'react-icons/lib/fa/check'
import FaClose from 'react-icons/lib/fa/close'
import FaQuestion from 'react-icons/lib/fa/question'
import FaExclamationTriangle from 'react-icons/lib/fa/exclamation-triangle'
import FaRight from 'react-icons/lib/fa/chevron-right'
import FaEllipsis from 'react-icons/lib/fa/ellipsis-h'
import { variantTypes, type Score } from '@mission.io/question-toolkit'
import type { AnalyticsQuestionResponse } from '@mission.io/mission-toolkit'
import type { DBQuestion } from '../../../models/Question'
import type { SelScores } from '../../../models/Analytics'
import { ColorCoin } from './ProficiencyBarGraph/sharedComponents'
import { QUESTION_ATTEMPTS_TO_SHOW_ON_TABLE } from '../../../constants'
import { dropQuestionMarkdownPrefix } from './hooks'
import type { ScoreFormatType } from '../constants'
import { translateScoreToProficiencyLevel } from './ProficiencyBarGraph/constants'

export const QUESTION_RESPONSE_TOOLTIP_ID = 'question-response-table-tooltip-id'

/**
 * The component to render inside a table cell that is a number score.
 * @returns {React$Node}
 */
export function RenderNumberScore({ getValue }: { getValue: () => ?number }): React$Node {
	return <RenderScore type="NUMBER" value={getValue()} />
}

/**
 * Render a proficiency level view of a student analytics percentage score.
 * @param {?number} props.getValue the prop react-table passes in when the Cell is rendered
 * @returns {React$node}
 */
export function RenderLevelScore({ getValue }: { getValue: () => ?number }): React$Node {
	const percentScore = getValue()
	if (!isFinite(percentScore) || percentScore == null) {
		return <Na>{NA}</Na>
	}
	const proficiencyLevel = translateScoreToProficiencyLevel(percentScore)
	return (
		<ColorCoin css="margin: auto" level={proficiencyLevel}>
			{proficiencyLevel}
		</ColorCoin>
	)
}

/**
 * The component to render inside a table cell that is a percent score.
 * @returns {React$Node}
 */
export function RenderPercentScore({ getValue }: { getValue: () => ?number }): React$Node {
	return <RenderScore type="PERCENT" value={getValue()} />
}

function RenderScore({ value: scoreVal, type }: { value: ?number, type?: 'PERCENT' | 'NUMBER' }) {
	const score = isFinite(scoreVal) && scoreVal != null ? scoreVal : null
	return score !== null ? (
		<div>
			{Math.round(score).toLocaleString('en-US')}
			{type === 'PERCENT' && '%'}
		</div>
	) : (
		<Na>{NA}</Na>
	)
}

export function getStudentScore(studentSelScores: ?SelScores, header: string): ?number {
	return studentSelScores?.[HEADER_TO_CATEGORY[header]][header]
}

type GetAccessorAndCellReturnType<T> = {|
	accessorFn: (element: T) => any,
	cell: ({ getValue: () => ?number }) => React$Node,
	meta?: { csvAccessor: (element: T) => mixed },
|}

/**
 * Get the `accessorFn` and `cell` renderer for a column that represents one of the proficiency categories. The returned accessors/renderer are dependent on
 * the given `scoreFormat`.
 *
 * @param scoreFormat - the format of the score to be selected and rendered
 * @param percentageElementAccessor - a function which will get the percentage value for the category from the given element
 *
 * @return {Object} an object containing the column accessor and Cell
 */
export function getAccessorAndCellFunctions<T>(
	scoreFormat: ScoreFormatType,
	percentageElementAccessor: (element: T) => ?number
): GetAccessorAndCellReturnType<T> {
	const result: GetAccessorAndCellReturnType<T> = {
		accessorFn: percentageElementAccessor,
		cell:
			scoreFormat === scoreFormatEnum.PROFICIENCY_LEVEL
				? RenderLevelScore
				: RenderNumberScore,
	}

	if (scoreFormat === scoreFormatEnum.PROFICIENCY_LEVEL) {
		result.meta = {
			csvAccessor: (element: T) => {
				const value = percentageElementAccessor(element)
				if (value == null) {
					return NA
				}
				return translateScoreToProficiencyLevel(value)
			},
		}
	}
	return result
}

/**
 * renderResponse - render the icon for the given response
 *
 * @param  {?Score} score - the score to render the icon for
 * @param  {?mixed} responseUnknownType? - a string variation of the response shown in a tooltip
 * @param  {string} responseId - the id of the response
 * @param  {?number} answerNumber - the attempt number of the given response
 * @param  {() => void} onClick? - a function to call when the icon for the response is clicked
 *
 * @returns React$Node
 */
function renderResponse(
	score: ?Score,
	responseUnknownType?: ?mixed,
	responseId: string,
	answerNumber: ?number,
	onClick?: () => void,
	key: string
): React$Node {
	if (!score) {
		return <FaExclamationTriangle key={key} data-answer-number={answerNumber} />
	}
	const response: string =
		typeof responseUnknownType === 'string' ? responseUnknownType : 'Click for details'

	if (score.score === null) {
		return (
			<IconWrapper
				key={key}
				data-tooltip-id={QUESTION_RESPONSE_TOOLTIP_ID}
				data-tooltip-content={response}
				onClick={onClick}>
				<FaQuestion className="inline" />
			</IconWrapper>
		)
	}
	if (score.score >= score.perfectScore) {
		return (
			<IconWrapper
				key={key}
				data-tooltip-id={QUESTION_RESPONSE_TOOLTIP_ID}
				data-tooltip-content={response}
				onClick={onClick}>
				<GreenCheck className="inline" />
			</IconWrapper>
		)
	}
	if (score.score < score.perfectScore) {
		return (
			<IconWrapper
				key={key}
				data-tooltip-id={QUESTION_RESPONSE_TOOLTIP_ID}
				data-tooltip-content={response}
				onClick={onClick}>
				<Wrong className="inline" />
			</IconWrapper>
		)
	}
	return null
}

export function getQuestionResponseData(
	studentResponse: ?AnalyticsQuestionResponse,
	question: ?DBQuestion
): ?{ score: ?Score, response: ?mixed } {
	if (studentResponse && question) {
		let response: mixed = ''
		if (
			studentResponse.answer.type === variantTypes.MULTIPLE_CHOICE &&
			question.type === variantTypes.MULTIPLE_CHOICE
		) {
			const responseIndex = question.choices.findIndex(
				choice => choice._id === studentResponse.answer.value
			)
			if (responseIndex === -1) {
				return {
					score: studentResponse.score,
					response: `unknown choice`,
				}
			}
			response = dropQuestionMarkdownPrefix(question.choices[responseIndex].text)
		} else {
			response = studentResponse.answer.value
		}
		return {
			score: studentResponse.score,
			response,
		}
	}
}

// Data for a single question response. The response is formatted this way to be used in the questions and culminating moments tables
export type ResponseData = {
	questionResponse: ?{ score: ?Score, response: ?mixed },
	studentResponse: AnalyticsQuestionResponse,
	question: DBQuestion,
}

export function RenderQuestionResponses({
	getValue,
}: {
	getValue: () => {
		responses?: ?Array<ResponseData>,
		id: string,
		onAttemptClick: (answerNumber: ?number) => {},
	},
}): React$Node {
	const { responses, id, onAttemptClick } = getValue()
	if (!responses?.length) {
		return null
	}

	function getScoreIcon(
		{
			question,
			studentResponse,
			questionResponse,
		}: {
			question: DBQuestion,
			studentResponse: AnalyticsQuestionResponse,
			questionResponse: ?{ score: ?Score, response: ?mixed },
		},
		index
	) {
		const showAttemptModal = () => {
			onAttemptClick(studentResponse.answerNumber)
		}
		if (!question) {
			return (
				<IconWrapper
					data-tooltip-id={QUESTION_RESPONSE_TOOLTIP_ID}
					onClick={showAttemptModal}
					data-tooltip-content={'Loading Question ...'}
					key={index}>
					<Transparent>Loading...</Transparent>
				</IconWrapper>
			)
		}
		if (questionResponse) {
			return renderResponse(
				questionResponse.score,
				questionResponse.response,
				id,
				studentResponse.answerNumber,
				showAttemptModal,
				`response-${index}`
			)
		}
		return (
			<IconWrapper
				data-tooltip-id={QUESTION_RESPONSE_TOOLTIP_ID}
				data-tooltip-content={'No Response'}
				onClick={showAttemptModal}
				key={index}>
				<Transparent>{NA}</Transparent>
			</IconWrapper>
		)
	}

	let segments: React$Node[] = []
	if (responses.length <= QUESTION_ATTEMPTS_TO_SHOW_ON_TABLE) {
		// shows up to three response icons with a separator between them
		segments = responses.map(getScoreIcon).map((node, index) => {
			if (index !== responses.length - 1) {
				return (
					<>
						{node}
						<SplitIcon key={`splitter-${index}`} />
					</>
				)
			}
			return node
		})
	} else {
		// if more than three responses exist, show the first and last icon with an ellipse in place of the second attempt
		segments = [
			getScoreIcon(responses[0], 0),
			<SplitIcon key={`splitter-${0}`} />,
			<IconWrapper
				onClick={() => onAttemptClick(responses[1].studentResponse.answerNumber)}
				key="ellipses">
				<FaEllipsis />
			</IconWrapper>,
			<SplitIcon key={`splitter-${1}`} />,
			getScoreIcon(responses[responses.length - 1], responses.length - 1),
		]
	}
	return <IconList>{segments}</IconList>
}

const SplitIcon = styled(FaRight)`
	font-size: 1.5em;
	padding-top: 0.1em;
`

const IconList = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
`

const GreenCheck = styled(FaCheck)`
	${({ theme }) => `
		color: ${theme.success};
	`}
`

const Wrong = styled(FaClose)`
	${({ theme }) => `
		color: ${theme.error};
	`}
`
const IconWrapper = styled.div`
	width: 100%;
	height: 100%;
	:hover {
		cursor: pointer;
	}
`

const Transparent = styled.div`
	color: transparent;
`

const Na = styled.div`
	font-weight: 200;
	${({ theme }) => `
		color: ${theme.neutralDark};
	`}
`
