import React, { useState, type ComponentType } from 'react'
import { useDispatch } from 'react-redux'

import styled from 'styled-components'
import { CopiableLink } from '../../../components/helpers'
import { StartMissionButton } from '../../../components/StartMissionButton'
import moment from 'moment'
import FaTrashO from 'react-icons/lib/fa/trash-o'
import FaPencil from 'react-icons/lib/fa/pencil'
import DuplicateIcon from 'react-icons/lib/fa/clone'
import { Button } from '@mission.io/styles'
import { PAUSED, type MissionType } from '../constants'
import DeleteMissionModal from './DeleteMissionModal'
import { IconCircleWrapper } from '../../../styles/sharedComponents'
import { useSimulationPermissions } from '../../../services/hooks/permissions'
import MdLock from 'react-icons/lib/md/lock'
import FaClock from 'react-icons/lib/fa/clock-o'
import UnlockDirectionModal from '../prep/UnlockDirectionModal'
import Loading from '../../../components/loading/Loading'

import { addPrepMission } from '../../../store/missionPrep'
import { useClass } from '../../../services/hooks/classes'
import { missionCategoriesEnum } from '../../../store/types'
import PrepMissionModal from '../prep/PrepMissionModal'
import { START_MISSION } from '../../../constants'

import type { Mission } from '../../../models'
import type { Event, Team } from '../../../models/Mission'
import { ErrorLinkButton, ErrorOccurred } from '../../../components/Error'
import { config } from '../../../config'
import classnames from 'classnames'

export default function MissionReviewTile({
	mission,
	teacherName,
	type,
}: {
	mission: Mission,
	teacherName: string,
	type: MissionType,
}): React$Node {
	const dispatch = useDispatch()
	const {
		data: missionClass,
		isLoading: isLoadingClass,
		isError: isErrorLoadingClass,
		refetch: refetchClasses,
	} = useClass(mission.crew?.classId)
	const [showDeleteModal, setShowDeleteModal] = useState(false)
	const [showPrepMissionModal, setShowPrepMissionModal] = useState(false)
	const [showUnlockModal, setShowUnlockModal] = useState(false)
	const simulationPermissions = useSimulationPermissions(mission.simulationId)

	const canUserRunMission = simulationPermissions?.userCanRun || false

	const nameOfClass = missionClass?.name ?? mission.crew?.className
	const startMissionText = `${type === PAUSED ? 'Resume' : 'Start'} Mission`

	return (
		<div className="rounded-lg shadow">
			<div className="p-2 flex flex-col justify-between min-h-28 bg-primary-600 rounded-t-lg ">
				<div className="flex justify-between items-center text-sm">
					<SimulationName>
						{mission.name || 'Unknown Mission'} -{' '}
						<span className="text-base">{mission.code}</span>{' '}
					</SimulationName>
					<div>{MissionCategoryToReadableCategoryMap[mission.missionCategory]}</div>
				</div>
				<div className="flex justify-between items-center text-sm">
					<div>
						{teacherName}: {nameOfClass}
					</div>
					<div className="text-sm">
						{getDayMissionWasStartedOrPrepped(mission.events)}
					</div>
				</div>
				<div className="flex justify-between items-center text-sm">
					<div>{mission.subjectMatter}</div>
					<CopiableLink
						link={config.controlsUrl + '/' + mission.code}
						tooltip="Copy link for students"
					/>
				</div>
			</div>
			{simulationPermissions && !isLoadingClass ? (
				isErrorLoadingClass ? (
					<Footer>
						<ErrorOccurred>
							Failed to load class
							<ErrorLinkButton onClick={() => refetchClasses()}>
								Retry
							</ErrorLinkButton>
						</ErrorOccurred>
					</Footer>
				) : !missionClass ? (
					<Footer>
						<ErrorOccurred>
							You do not have access to the class associated with this mission
						</ErrorOccurred>
						{
							<Button variant="secondary" onClick={() => setShowDeleteModal(true)}>
								End Mission
							</Button>
						}
					</Footer>
				) : (
					<Footer>
						<ActionButtons paused={type === PAUSED}>
							{type === PAUSED ? (
								<Button
									outline
									onClick={() => setShowDeleteModal(true)}
									variant="secondary">
									End Mission
								</Button>
							) : (
								<IconWrapper onClick={() => setShowDeleteModal(true)}>
									<FaTrashO />
								</IconWrapper>
							)}
							{type !== PAUSED && (
								<>
									<LockableIconWrapper
										onUnlockClick={() => {
											dispatch(
												addPrepMission({
													_id: mission._id,
													crew: {
														students: missionClass.students ?? [],
														classId: mission.crew.classId,
													},
													simulationId: mission.simulationId,
													missionCategory: mission.missionCategory,
													questions: mission.questions,
													useJrPlus: mission.useJrPlus,
													juniorTeams: getTeamsFromMission(mission),
													beginPrepTimestamp: mission.events[0]?.timestamp
														? new Date(mission.events[0].timestamp)
														: new Date(),
												})
											)
											setShowPrepMissionModal(true)
										}}
										isLocked={!canUserRunMission}
										isUnlockedDueToPromotion={Boolean(
											simulationPermissions.userCanOnlyRunDueToPromotion
										)}
										onLockClick={() => setShowUnlockModal(true)}
										Icon={FaPencil}
									/>
									<LockableIconWrapper
										onUnlockClick={() => {
											dispatch(
												addPrepMission({
													crew: {
														students: missionClass
															? missionClass.students
															: [],
														classId: mission.crew.classId,
													},
													simulationId: mission.simulationId,
													missionCategory: mission.missionCategory,
													questions: mission.questions,
													useJrPlus: mission.useJrPlus,
													juniorTeams: getTeamsFromMission(mission),
													beginPrepTimestamp: new Date(),
												})
											)
											setShowPrepMissionModal(true)
										}}
										isLocked={!canUserRunMission}
										isUnlockedDueToPromotion={Boolean(
											simulationPermissions.userCanOnlyRunDueToPromotion
										)}
										onLockClick={() => setShowUnlockModal(true)}
										Icon={DuplicateIcon}
									/>
								</>
							)}
						</ActionButtons>

						<StartMissionButton variant="secondary" mission={mission}>
							{startMissionText}
						</StartMissionButton>
					</Footer>
				)
			) : (
				<Footer className="justify-start items-center">
					<Loading style={{ width: '2em' }} />
					Loading options
				</Footer>
			)}

			<DeleteMissionModal
				isOpen={showDeleteModal}
				onClose={() => setShowDeleteModal(false)}
				missionStatus={type}
				missionOwner={mission.owner}
				missionId={mission._id}
			/>

			{showUnlockModal && (
				<UnlockDirectionModal
					simulationId={mission.simulationId}
					onRequestClose={() => setShowUnlockModal(false)}
				/>
			)}

			{showPrepMissionModal && (
				<PrepMissionModal onClose={() => setShowPrepMissionModal(false)} />
			)}
		</div>
	)
}

/**
 * We want to get the day a mission was started by a teacher, but if there is none,
 * (meaning it is a prepped mission), we instead get the first event, which is the
 * Begin_Prep Date.
 * Defaults to show nothing (null).
 */
function getDayMissionWasStartedOrPrepped(events: Event[]): ?string {
	const eventToShow = events.find(event => event.type === START_MISSION) || events[0]
	if (!eventToShow || !eventToShow.timestamp) {
		return null
	}
	return moment(eventToShow.timestamp).format('MM/DD/YY')
}

/**
 * Function to get the teams from a mission.
 * Get the fullState teams, unless there is no fullState, then use the initialState teams.
 * defaults to null
 */
function getTeamsFromMission(mission: Mission): ?(Team[]) {
	return mission.teams
}

const SimulationName = styled.div`
	font-size: 18px;
`

const ActionButtons = styled.div`
	display: flex;
	flex: 1;
	flex-direction: row;
	${({ paused }) =>
		paused &&
		`
        justify-content: flex-end;
		
    `}
`

/**
 * LockableIconWrapper - a wrapper which allows icon bubbles to be locked and greyed out
 *
 * @param {boolean} isLocked - true to display a lock on the bottom right of the icon
 * @param {boolean} isUnlockedDueToPromotion - true if the action is only unlocked due to a promotion
 * @param {() => void} onLockClick - a callback for when the icon is clicked when `isLocked` is `true`
 * @param {() => void} onUnlockClick - a callback for when the icon is clicked when `isLocked` is `false`
 * @param {ComponentType<*>} Icon - the icon to display
 * @param {boolean} greyOut - mock disabled styles but still allow clicking
 */
function LockableIconWrapper({
	isLocked,
	isUnlockedDueToPromotion,
	onLockClick,
	onUnlockClick,
	Icon,
}: {
	isLocked: ?boolean,
	isUnlockedDueToPromotion: boolean,
	onUnlockClick: () => void,
	onLockClick: () => void,
	Icon: ComponentType<*>,
}) {
	if (isLocked === null) {
		return null
	}
	return (
		<IconWrapper
			onClick={() => {
				if (!isLocked) {
					onUnlockClick()
					return
				}
				onLockClick()
			}}
			$greyOut={isLocked}>
			<Icon />
			{isLocked ? (
				<MdLock className="absolute text-base -right-1 -bottom-1 text-error" />
			) : isUnlockedDueToPromotion ? (
				<FaClock className="absolute text-base -right-1 -bottom-1 text-sun bg-deep-blue/75 rounded-xl" />
			) : null}
		</IconWrapper>
	)
}

const IconWrapper = styled(IconCircleWrapper)`
	position: relative;
	${({ theme }) => `
        padding: var(--spacing1x-dont-use);
        margin-right: var(--spacing1x-dont-use);
    `}
`

const MissionCategoryToReadableCategoryMap = {
	[missionCategoriesEnum.ON_SITE]: `In Class`,
	[missionCategoriesEnum.WHOLE_CLASS_REMOTE]: `Remote`,
	[missionCategoriesEnum.SQUADRON_REMOTE]: `Group Remote`,
	[missionCategoriesEnum.SQUADRON_HOMEWORK]: `Group Homework`,
}

/**
 * A footer for the MissionReviewTile
 */
function Footer({ children, className }: { children: React$Node, className?: string }) {
	return (
		<div className={classnames(`flex p-2 bg-primary-700 rounded-b-lg gap-2`, className)}>
			{children}
		</div>
	)
}
