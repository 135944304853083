import React, { useContext } from 'react'
import { Line } from 'rc-progress'
import { ThemeContext } from 'styled-components'

type Props = {|
	percent: number,
	width: number,
	'aria-labelledby'?: string,
|}

export default function ProgressBar({ percent, width, ...otherProps }: Props): React$Node {
	const themeContext = useContext(ThemeContext)

	return (
		<Line
			role="progressbar"
			aria-valuemin={0}
			aria-valuemax={100}
			aria-valuenow={percent}
			className="ProgressBar"
			percent={percent}
			strokeWidth={width}
			trailWidth={width}
			strokeColor={percent >= 100 ? themeContext.success : themeContext.primary}
			{...otherProps}
		/>
	)
}
