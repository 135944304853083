import React from 'react'
import styled from 'styled-components'
import type { PreviewProps } from './types'

/**
 * Audio - a component used to view an audio file
 *
 * @param {PreviewProps} props - the preview props from the FileViewer component
 *
 * @return {React$Node}
 */
export function Audio({ file }: PreviewProps): React$Node {
	return (
		<StyledAudio
			src={file}
			autoPlay={true}
			controls
			aria-label="audio"
			onClick={e => {
				e.stopPropagation()
			}}
		/>
	)
}

const StyledAudio = styled.audio`
	max-width: 80vw;
	max-height: 80vh;
	pointer-events: all;
`
