import React, { useState } from 'react'
import FileLoader from '../../components/helpers/FileLoader'
import UploadedClass from './UploadedClass'
import { Button } from '@mission.io/styles'
import { toast } from 'react-toastify'

import type { NewStudent } from '../../models/Student'

const CSVInstructions = `In order to upload correctly, please see the format example by clicking the 'Download Example' button below. 
	In your spreadsheet, box A1 should be the word First, and B1 should be Last.  The first name, and last name of 
	your first student should then be in A2, and B2, respectively, and so on with the rest of your class. Make sure 
	your file is in CSV format before uploading. If you are having trouble uploading your own file, try downloading 
	the example csv then replace the example class data with your own and upload. If the file won't download, try allowing popups from this site.`

type Props = {
	onSave: (students: NewStudent[]) => mixed,
}

export default function UploadClassCSV({ onSave }: Props): React$Node {
	const [students, setStudents] = useState([])

	return students.length > 0 ? (
		<>
			<UploadedClass students={students} />
			<Button type="submit" onClick={() => onSave(students)}>
				Use Class
			</Button>
		</>
	) : (
		<>
			<h3 className="text-2xl mb-2">Upload class info in .csv format</h3>
			<p className="mb-3">{CSVInstructions}</p>
			<FileLoader
				wantedFields={WANTED_CSV_FIELDS}
				onLoad={importedStudents => {
					if (importedStudents.length < 1) {
						toast.error('Class must have at least 1 student!')
					} else {
						const students = importedStudents.map(student => ({
							firstName: student.first,
							lastName: student.last,
							linkedAccounts: [],
						}))
						setStudents(students)
					}
				}}
				onError={(error: any) => {
					toast.error('Error uploading .csv! Please ensure it is formatted correctly.', {
						autoClose: 8000,
					})
					console.error('CSV uploader error: ', error)
				}}
				exampleData={EXAMPLE_DATA}
			/>
		</>
	)
}

const WANTED_CSV_FIELDS = [
	{
		field: 'first',
		aliases: [
			'firstName',
			'first name',
			'given',
			'given name',
			'givenName',
			'username',
			'user',
			'user name',
			'name',
		],
	},
	{
		field: 'last',
		aliases: ['lastName', 'last name', 'family', 'family name', 'familyName', 'surname'],
	},
]

const EXAMPLE_DATA = [
	['First', 'Last'],
	['Raed', 'Labes'],
	['Ahmed', 'Tomi'],
	['Luci', 'Gonzales'],
	['Katy', 'Clawson'],
	['Colter', 'Thompson'],
	['Tim', 'Clement'],
	['Jessica', 'Stratford'],
	['Joshua', 'Nichols'],
]
