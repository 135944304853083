import styled from 'styled-components'
import type { StyledType } from 'global-types'

export const LoaderWrapper: StyledType<> = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	margin-left: var(--spacing3x-dont-use);

	& svg {
		margin-right: 4px;
		width: 20px;
	}
`
