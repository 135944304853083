import React from 'react'
import styled from 'styled-components'
import { useLocation } from 'react-router-dom'
import CustomModal, { ExitX, ModalHeader, ModalBody, ModalFooter } from '../modal/Modal'
import { Button } from '@mission.io/styles'
import HighFive from '../../assets/HighFive.png'

type Props = {
	isOpen: boolean,
	onCloseCallback: (value: string | void) => void,
}

/**
 * Custom onboarding modal window component, based on the selected onboarding item
 * @param { string } currentModal modal window that should be shown
 * @callback onCloseCallback used here to invoke callback on modal closing
 */
export default function OnboardingModal({ isOpen, onCloseCallback }: Props): React$Node {
	const location = useLocation()
	const isOnAnalyticsPage = location.pathname.startsWith('/analytics')
	return (
		<CustomModal
			isOpen={isOpen}
			onRequestClose={onCloseCallback}
			style={{
				content: {
					maxWidth: '880px',
					width: '55%',
					top: '50%',
					left: '50%',
					transform: 'translate(-50%, -50%)',
				},
			}}>
			<ModalHeader>
				Congratulations!
				<ExitX onClick={onCloseCallback} />
			</ModalHeader>
			<ModalBody className="p-10">
				<div className="text-center font-semibold mb-4">
					Nice job on completing your first mission!
				</div>

				<div className="text-center text-sm mb-4">
					You can now review mission analytics to gain valuable insights into your
					students’ performance.
				</div>
				<div className="w-full text-center text-sm mb-4">
					<Image src={HighFive} alt="congrats-image" />
				</div>
			</ModalBody>
			<StyledModalFooter>
				{!isOnAnalyticsPage && (
					<Button outline onClick={onCloseCallback}>
						Maybe Later
					</Button>
				)}
				<Button
					onClick={() => {
						if (!isOnAnalyticsPage) {
							onCloseCallback('redirect')
						} else {
							onCloseCallback()
						}
					}}>
					See Analytics
				</Button>
			</StyledModalFooter>
		</CustomModal>
	)
}

const Image = styled.img`
	max-width: 50%;
`

const StyledModalFooter = styled(ModalFooter)`
	${({ theme }) => `
		border-top: 2px solid ${theme.neutralLight};
		background-color: ${theme.white};
		button:first-child {
			margin-right: 15px;
		}
  `}
`
