import styled from 'styled-components'
import type { StyledType } from 'global-types'

const ImageWrapper: StyledType<> = styled.div`
	img {
		height: 3em;
		padding: 0px 24px;
	}
`
const Row: StyledType<> = styled.div`
	display: flex;
	:not(:first-child) {
		margin-top: var(--spacing2x-dont-use);
	}
	p {
		margin: 0;
		flex: 1;
	}
	${ImageWrapper} {
		flex-grow: 0;
	}

	button {
		white-space: normal;
		height: fit-content;
		margin: 0px 24px;
	}
`

export { ImageWrapper, Row }
