// @flow
import React, { useState, useEffect } from 'react'
import { useQueryClient, useMutation } from 'react-query'
import axios from 'axios'
import { Button } from '@mission.io/styles'
import Checkbox from '../../components/inputs/Checkbox'
import config from '../../config'
import Loading from '../../components/loading/Loading'
import useUser, { useEmailPreference } from '../../services/hooks/user'
import { toast } from 'react-toastify'
import { LoaderWrapper } from './sharedProfileComponents'

/**
 * EmailPreferences - A react component which handles user email preferences
 *
 * @returns {React$Node} - a react node
 */
export default function EmailPreferences(): React$Node {
	return (
		<div className="layout-row main-info">
			<div className="layout-column">
				Allow Emails For:
				<InternalEmailPreferences />
			</div>
		</div>
	)
}

/**
 * InternalEmailPreferences - A component responsible for the logic of the email preferences
 *
 * @returns {React$Node} - a react node
 */
function InternalEmailPreferences(): React$Node {
	const { user } = useUser()

	const queryClient = useQueryClient()
	const { data: emailPreferences, error } = useEmailPreference()
	const emailPreferenceMutation = useMutation(
		(data: {| id: string, subscribed: boolean |}[]) =>
			axios.patch(`${config.loginServer}/api/email-preferences`, data, {
				withCredentials: true,
			}),
		{
			onSuccess: () => {
				queryClient.invalidateQueries('user-email-preference')
			},
			onError: () => {
				toast.error('An error occurred while updating your email preferences.')
			},
		}
	)

	const [updates, setUpdates] = useState<{ [id: string]: boolean }>({})

	useEffect(() => {
		if (!emailPreferences?.pendingUpdates) {
			setUpdates({})
		}
	}, [emailPreferences])

	if (!user) {
		return (
			<LoaderWrapper>
				<Loading />
				Loading User Data
			</LoaderWrapper>
		)
	}

	if (!user?.email_verification.confirmed) {
		return <div>You must confirm your email to update your email preferences.</div>
	}

	if (!emailPreferences) {
		if (error) {
			return (
				<LoaderWrapper>
					<Loading />
					An error occurred while loading your email preferences, retrying.
				</LoaderWrapper>
			)
		}
		return (
			<LoaderWrapper>
				<Loading />
				Loading Email Preferences
			</LoaderWrapper>
		)
	}

	return (
		<>
			{emailPreferences.preferences.map(({ id, name, description, subscribed }) => (
				<Checkbox
					checked={updates[id] ?? subscribed}
					disabled={emailPreferences.pendingUpdates}
					onChange={event =>
						setUpdates(currentUpdates => {
							if (event.target.checked === subscribed) {
								const newUpdates = { ...currentUpdates }
								delete newUpdates[id]
								return newUpdates
							}
							return {
								...currentUpdates,
								[id]: event.target.checked,
							}
						})
					}
					label={`${name}: ${description}`}
					key={id}
				/>
			))}

			<Button
				disabled={!Object.keys(updates).length || emailPreferences?.pendingUpdates}
				onClick={() => {
					const updatedCategories = Object.keys(updates)
					if (updatedCategories.length) {
						emailPreferenceMutation.mutate(
							updatedCategories.map(id => ({
								id: id,
								subscribed: updates[id],
							}))
						)
					}
				}}>
				Update
			</Button>
			{emailPreferences.pendingUpdates ? (
				<LoaderWrapper>
					<Loading />
					Updating Email Preferences
				</LoaderWrapper>
			) : null}
		</>
	)
}
