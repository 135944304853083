import React, { useRef } from 'react'
import { toast } from 'react-toastify'
import { useLocation, useHistory } from 'react-router-dom'

/**
 * A popup toast that will display based on the url query parameter 'license-joined' being present.
 * This toast will display the value of license-joined which is expected to be the name of the license.
 */
export default function useLicenseJoinedPopup() {
	const location = useLocation()
	const toastShown = useRef()
	const query = new URLSearchParams(location.search)
	const licenseJoined = query.get('license-joined')
	const history = useHistory()
	const onClose = () => {
		history.replace('/')
	}
	if (licenseJoined && !toastShown.current) {
		toast.success(<div>Wahoo! You have been added to the {licenseJoined} license.</div>, {
			icon: '🎉',
			position: toast.POSITION.TOP_RIGHT,
			autoClose: false,
			onClose,
			onOpen: () => {
				toastShown.current = true
			},
		})
	}
}
