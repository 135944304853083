// @flow
import React, { useMemo } from 'react'
import styled from 'styled-components/macro'
import { clamp } from 'lodash'
import {
	translateScoreToProficiencyLevel,
	PERCENTAGE_SCORES,
} from '../../AnalyticsCharts/ProficiencyBarGraph/constants'
import { type Category, CATEGORIES } from '../constants'
import {
	LineChart,
	Line,
	XAxis,
	YAxis,
	CartesianGrid,
	Legend,
	ReferenceLine,
	ResponsiveContainer,
} from 'recharts'
import moment from 'moment'
import { useScores, useViewType, dataViewEnum } from '../../DataViewContext'
import { useQueryParam, DelimitedArrayParam, withDefault } from 'use-query-params'
import classnames from 'classnames'

/**
 * A line graph to display a student's scores over time in each category.
 */
export default function LineGraph({ categories }: { categories: Category[] }): React$Node {
	const pastMissionScores = useScores()
	const viewType = useViewType()

	const { data, yearFormatter } = useMemo(() => {
		const currentYear = new Date().getFullYear()
		let labelNeedsYear = false
		const data = [...pastMissionScores].map(({ scores, missionDate }) => {
			const dataPoint = {
				time: missionDate.getTime(),
			}

			if (missionDate.getFullYear() !== currentYear) {
				labelNeedsYear = true
			}
			if (!scores) {
				return dataPoint
			}
			categories.forEach(category => {
				const score = category.accessor(scores)
				dataPoint[category.key] = score != null ? clamp(score, 0, 100) : null
			})
			return dataPoint
		})

		const yearFormatter = labelNeedsYear ? ' YYYY' : ''
		return { data, yearFormatter }
	}, [pastMissionScores, categories])

	const { hiddenCategories, toggleCategory } = useHiddenCategories()

	return (
		<Grid className="[&_.recharts-legend-item-text]:!text-primary-50 [--stroke-color:theme(colors.primary.50)] [--text-color:theme(colors.primary.50)]">
			<ResponsiveContainer height={360} width="100%">
				<LineChart
					data={data}
					margin={{
						top: 20,
						right: 20,
						bottom: 20,
						left: 20,
					}}>
					<CartesianGrid
						strokeDasharray="5 5"
						horizontal={false}
						stroke="var(--stroke-color)"
					/>

					<XAxis
						label={{
							value:
								viewType === dataViewEnum.AVERAGED_OVER_MONTHS
									? 'Months'
									: viewType === dataViewEnum.AVERAGED_OVER_WEEKS
									? 'Weeks'
									: 'Mission Dates',
							offset: -16,
							position: 'insideBottom',
							stroke: 'var(--text-color)',
						}}
						type="category"
						dataKey="time"
						className="text-sm"
						padding={{ left: 30, right: 30 }}
						domain={['dataMin', 'dataMax']}
						tickFormatter={timestamp =>
							moment(timestamp).format(
								viewType === dataViewEnum.AVERAGED_OVER_MONTHS
									? `MMMM${yearFormatter}`
									: `MMM. D${yearFormatter}`
							)
						}
						stroke="var(--stroke-color)"
					/>
					{/* Custom Label for Y axis to handle spacing away from the graph */}
					<text
						offset="5"
						x="40"
						y="180"
						fill="var(--text-color)"
						transform="rotate(-90, 40, 180)"
						textAnchor="middle">
						<tspan x="50" dy="0.355em">
							Student Proficiency
						</tspan>
					</text>
					<YAxis
						domain={[0, 100]}
						ticks={PERCENTAGE_SCORES}
						tick={TickYAxis}
						tickSize={20}
						interval={0}
						stroke="var(--stroke-color)"
					/>
					{PERCENTAGE_SCORES.map(tick => (
						<ReferenceLine y={tick} key={tick} stroke="var(--stroke-color)" />
					))}
					{/* Custom Line that boxes in the y axis ticks labels */}
					<line
						className="recharts-cartesian-axis-tick-line"
						x="20"
						y="20"
						stroke="var(--stroke-color)"
						fill="none"
						x1="60"
						y1="20"
						x2="60"
						y2="310"
					/>

					{categories.map(category => {
						const color = hiddenCategories.has(category.key)
							? 'transparent'
							: category.color
						return (
							<Line
								connectNulls
								key={category.key}
								stroke={color}
								dot={{ fill: color }}
								strokeWidth={3}
								isAnimationActive={false}
								dataKey={category.key}
							/>
						)
					})}
					<Legend
						align="right"
						layout="vertical"
						verticalAlign="middle"
						wrapperStyle={{ right: 0 }}
						content={({ payload }: { payload: Array<{ value: string }> }) => {
							return (
								<div className="flex flex-col gap-2">
									{payload.map(categoryInfo => {
										const category = CATEGORIES[categoryInfo.value]
										const isHidden = hiddenCategories.has(category.key)

										return (
											<button
												key={category.key}
												onClick={() => toggleCategory(category.key)}
												style={
													isHidden
														? undefined
														: {
																backgroundColor: category.color,
														  }
												}
												className={classnames(
													'rounded-md px-2 flex justify-between items-center gap-2',
													isHidden && 'bg-neutral-dark'
												)}>
												{category.title}
												<input
													className="accent-white"
													type="checkbox"
													checked={!isHidden}
												/>
											</button>
										)
									})}
								</div>
							)
						}}
					/>
				</LineChart>
			</ResponsiveContainer>
		</Grid>
	)
}

const DelimitedArrayParamWithDefaults = withDefault(DelimitedArrayParam, [])

/**
 * A hook that manages the hidden categories state. The state is stored in the query params of the url
 *
 * @returns obj.hiddenCategories a Set of the hidden categories
 * @returns obj.toggleCategory a function to toggle a category
 */
function useHiddenCategories(): { hiddenCategories: Set<string>, toggleCategory: string => void } {
	const [hiddenCategories, setHiddenCategories]: [string[], (string[]) => void] = useQueryParam(
		'hiddenCategories',
		DelimitedArrayParamWithDefaults,
		{
			removeDefaultsFromUrl: true,
			updateType: 'replaceIn',
		}
	)

	const hiddenCategoriesSet = new Set(hiddenCategories)

	const toggleCategory = (category: string) => {
		if (hiddenCategoriesSet.has(category)) {
			setHiddenCategories(hiddenCategories.filter(c => c !== category))
		} else {
			setHiddenCategories([...hiddenCategories, category])
		}
	}

	return { hiddenCategories: hiddenCategoriesSet, toggleCategory }
}

const Grid = styled.div`
	display: flex;
	width: 100%;
	height: 348px;
	--questions-category-color: #d4155b;
	--application-category-color: #0472bd;
	--initiative-category-color: #942c91;
	--collaboration-category-color: #8dc53f;
	--criticalThinking-category-color: #f7931d;
	--grit-category-color: #4ab6ff;
`

/**
 * Renders the custom tick on the Y Axis
 * @param {{x: number, payload: {value: number}}} props Props provided by recharts
 * @returns {React$Node}
 */
function TickYAxis(props: { x: number, payload: { value: number } }) {
	const { x, payload } = props
	// using the value of 100 would make 4 appear twice
	if (payload.value === 100) {
		return null
	}
	const score = translateScoreToProficiencyLevel(payload.value)
	if (score == null) {
		return null
	}
	const y = yAxisTickSvgCoordinates[score].y
	return (
		<g>
			<g transform={`translate(${x + 8},${y})`}>
				<text x={0} y={0} textAnchor="start" fill="var(--text-color)">
					{score}
				</text>
			</g>
		</g>
	)
}

// Coordinates to display the tick title in the middle of the ticks. This makes for a clearer UX.
const yAxisTickSvgCoordinates = {
	'1': { y: 240 },
	'2': { y: 124 },
	'3': { y: 60 },
	'4': { y: 33 },
}
