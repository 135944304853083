import classnames from 'classnames'
import React, { Component } from 'react'

type Props = {
	className?: string,
	size?: 'small',
	style?: {},
	'aria-live'?: 'passive' | 'aggressive',
	'aria-label'?: string,
}

export default class Loading extends Component<Props> {
	render(): React$Node {
		const { className, size, ...props } = this.props
		return (
			<div
				aria-live="polite"
				aria-label="loading"
				{...props}
				role="progressbar"
				className={classnames(size === 'small' && 'h-10', className)}
				aria-busy={true}
				data-testid="loading">
				<svg
					xmlns="http://www.w3.org/2000/svg"
					viewBox="0 0 24 8"
					className="fill-primary-500 h-full w-full">
					<circle transform="translate(8 0)" cx="-4" cy="4" r="0.683697">
						<animate
							attributeName="r"
							values="0; 4; 0; 0"
							dur="1.6s"
							repeatCount="indefinite"
							begin="0"
							keyTimes="0;0.2;0.7;1"
							keySplines="0.2 0.2 0.4 0.8;0.2 0.6 0.4 0.8;0.2 0.6 0.4 0.8"
							calcMode="spline"
						/>
					</circle>
					<circle transform="translate(16 0)" cx="-4" cy="4" r="3.01636">
						<animate
							attributeName="r"
							values="0; 4; 0; 0"
							dur="1.6s"
							repeatCount="indefinite"
							begin="0.3"
							keyTimes="0;0.2;0.7;1"
							keySplines="0.2 0.2 0.4 0.8;0.2 0.6 0.4 0.8;0.2 0.6 0.4 0.8"
							calcMode="spline"
						/>
					</circle>
					<circle transform="translate(24 0)" cx="-4" cy="4" r="0">
						<animate
							attributeName="r"
							values="0; 4; 0; 0"
							dur="1.6s"
							repeatCount="indefinite"
							begin="0.6"
							keyTimes="0;0.2;0.7;1"
							keySplines="0.2 0.2 0.4 0.8;0.2 0.6 0.4 0.8;0.2 0.6 0.4 0.8"
							calcMode="spline"
						/>
					</circle>
				</svg>
			</div>
		)
	}
}
