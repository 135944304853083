import { getFileExtensionFromSrc } from '../../utility/fileFunctions'
import { FILE_EXTENSION_TO_FILE_ICON } from '../FileViewer/FileTypeMap'

import React from 'react'

import FaFile from 'react-icons/lib/fa/file'
import FaFolder from 'react-icons/lib/fa/folder'
import FaChevronRight from 'react-icons/lib/fa/chevron-right'
import type { File as FileType } from '../../utility/FileHelpers'
import classnames from 'classnames'

export type OnFolderClick = (newBreadCrumbs: string) => void
export type OnFileClick = (file: FileType) => void

/**
 * BreadCrumbs - a list of the directories in the directory path
 *
 * @param {Object} props - the react props
 * @param {string} props.directory - the directory to show bread crumbs for (Should be in form `/parent/subfolder/currentFolder/`)
 * @param {OnFolderClick} props.onFolderClick - a callback for when an element in the breadcrumbs is clicked
 *
 * @return React$Node
 */
export function BreadCrumbs({
	directory,
	onFolderClick,
}: {
	directory: string,
	onFolderClick: OnFolderClick,
}): React$Node {
	let directoryWithoutStartingSlash = directory.startsWith('/') ? directory.slice(1) : directory
	let directoryWithoutEndingSlash = directoryWithoutStartingSlash.endsWith('/')
		? directoryWithoutStartingSlash.slice(0, directoryWithoutStartingSlash.length - 1)
		: directoryWithoutStartingSlash

	const crumbs = directoryWithoutEndingSlash.split('/')
	let jsxCrumbs = []
	crumbs.forEach((crumb, index) => {
		jsxCrumbs.push(
			<button
				key={index}
				className="hover:bg-primary-400 px-2 rounded-lg cursor-pointer"
				onClick={() => onFolderClick(`/${crumbs.slice(0, index + 1).join('/')}/`)}>
				{crumb}
			</button>
		)
		jsxCrumbs.push(<FaChevronRight key={`crumb-${index}`} />)
	})
	jsxCrumbs.pop()

	return <div className="flex flex-row items-center gap-2 text-lg">{jsxCrumbs}</div>
}

/**
 * FileSelector - shows a grid of the files
 *
 * @param {Object} props - the react props
 * @param {Array<File>} props.files - the files/folders to show in the grid
 * @param {OnFileClick} props.onFileClick - a callback for when a file is clicked
 * @param {OnFolderClick} props.onFolderClick - a callback for when a folder is clicked
 * @param {string} props.className? - a class name which styles the grip wrapper
 *
 * @return React$Node
 */
export function FileGrid({
	files,
	onFileClick,
	onFolderClick,
	className,
}: {
	files: FileType[],
	onFileClick: OnFileClick,
	onFolderClick: OnFolderClick,
	className?: string,
}): React$Node {
	return (
		<div
			className={classnames('flex flex-row flex-wrap gap-2 flex-1 overflow-auto', className)}>
			{files.map(file => (
				<File
					file={file}
					key={file.id}
					onFileClick={onFileClick}
					onFolderClick={onFolderClick}
				/>
			))}
		</div>
	)
}

/**
 * FileThumbnail - shows a thumbnail for the file
 *
 * @param {Object} props - the react props
 * @param {File} props.file - the file to show the thumbnail for (may also be a folder)
 *
 * @return React$Node
 */
export function FileThumbnail({ file }: { file: FileType }): React$Node {
	if (file.isDir) {
		return <FaFolder className="text-7xl" />
	}
	const previewUrl = file.filePreviewSrc ?? file.thumbnailUrl
	if (previewUrl) {
		return <img src={previewUrl} className="max-w-full max-h-full" alt={file.name} />
	}

	const fileExtension = getFileExtensionFromSrc(file.actualName ?? file.name)

	const FileIcon = (fileExtension ? FILE_EXTENSION_TO_FILE_ICON[fileExtension] : null) ?? FaFile

	return <FileIcon className="text-7xl" file={file.name} />
}

/**
 * File - a file entry (shows the file thumbnail and the file name), can also be a folder
 *
 * @param {Object} props - the react props
 * @param {File} props.file - the file to represent (may also be a folder)
 * @param {OnFileClick} porps.onFileClick - a callback for if the file is clicked (will call onFolderClick if `file` is a folder)
 * @param {OnFolderClick} porps.onFolderClick - a callback for if the folder is clicked (will call onFileClick if `file` is a file)
 *
 * @return React$Node
 */
function File({
	file,
	onFileClick,
	onFolderClick,
}: {
	file: FileType,
	onFileClick: OnFileClick,
	onFolderClick: OnFolderClick,
}): React$Node {
	return (
		<button
			className="w-40 max-w-40 min-w-40 flex flex-col gap-2 items-center hover:bg-primary-400 hover:text-primary-50 p-2 rounded-lg cursor-pointer"
			onClick={() => {
				if (file.isDir) {
					onFolderClick(file.id)
					return
				}
				onFileClick(file)
			}}>
			<div className="max-w-full h-20 max-h-20 min-h-20 text-sky-blue">
				<FileThumbnail file={file} />
			</div>
			<div>{file.name ?? file.actualName}</div>
		</button>
	)
}
