/**
 * getFileNameFromSrc - get the file name from am URL source string
 *
 * @param {string} fileSrc - the file source
 *
 * @return {string} - the file name
 */
export function getFileNameFromSrc(fileSrc: string): string {
	const segments = fileSrc.split('/')
	return segments[segments.length - 1]
}

/**
 * getFileExtensionFromSrc - get the file extension from am URL source string
 *
 * @param {string} fileSrc - the file source
 *
 * @return {string} - the file extension
 */
export function getFileExtensionFromSrc(fileSrc: string): string {
	const segments = fileSrc.split('.')
	return segments[segments.length - 1]
}
