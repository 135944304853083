import React, { useState } from 'react'

import { useUser } from '../../services/hooks'
import RequireSignInModal from '../RequireSignInModal'

/**
 * A wrapper component that is useful when you don't want to allow a non-signed in user to
 * access some functionality.  When the child component is clicked, the RequireSignInModal is opened and the
 * event propagation is stopped.
 */
export default function RequireSignIn({ children }: { children: React$Node }): React$Node {
	const { user } = useUser()
	const [modalOpen, setModalOpen] = useState(false)
	return (
		<>
			<div
				onClickCapture={e => {
					if (!user) {
						setModalOpen(true)
						e.stopPropagation()
					}
				}}>
				{children}
			</div>
			<RequireSignInModal isOpen={modalOpen} onRequestClose={() => setModalOpen(false)} />
		</>
	)
}
