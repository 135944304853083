import React, { useMemo } from 'react'
import { MdChevronRight } from 'react-icons/lib/md'
import { useQueryParam, StringParam } from 'use-query-params'
import { mean } from 'lodash'
import { reduceObject } from '../../../utility/functions'
import EmptyAnalyticsImage from '../../../assets/NoData.png'
import { PageTitleHeader } from '../../../styles/sharedComponents'
import type { AnalyticsObject, ProficiencyTableEntry, SelScores } from '../../../models/Analytics'
import Loading from '../../../components/loading'
import { ButtonLink } from '../../../components/helpers'
import { getSelScoresForStudent, averageSelScores } from '../AnalyticsCharts/dataHelpers'
import StudentSummary from './StudentSummary'
import { getCategoriesForControlSet, type StudentMissionScoreData } from './constants'
import { NAME, SCORE_FORMAT_DISPLAY } from '../constants'
import { ProficiencyTable } from '../AnalyticsCharts/StudentsTableManger'
import { RenderNumberScore } from '../AnalyticsCharts/TableHelper'
import moment from 'moment'
import type { Category } from './constants'

/**
 * Returns a function for the given student id
 * that will get the total mission points achieved by
 * that student for a single mission analytics object
 * @param {string} studentId
 * @returns {(AnalyticsObject) => ?number}
 */
export const getTotalPoints = (studentId: string): ((analytics: AnalyticsObject) => ?number) => {
	return (analytics: AnalyticsObject): ?number => {
		const { studentPoints } = analytics?.analytics?.studentAnalytics?.[studentId] || {}
		return studentPoints
			? reduceObject(
					studentPoints,
					(total, studentPointValue) => total + studentPointValue,
					0
			  )
			: null
	}
}

/**
 * A page designed to display a single students SEL scores and progress to their teacher
 * @param {{studentId: string, analytics: AnalyticsObject[], isLoading: boolean}} props
 * @returns {React$Node} a react component that will fill the width of its parent
 */
export default function StudentPage({
	studentId,
	analytics,
	isLoading,
	name: studentName,
}: {
	studentId: string,
	analytics: AnalyticsObject[],
	isLoading: boolean,
	name: ?string,
}): React$Node {
	const [, setStudentQuery] = useQueryParam('student', StringParam)
	const [categories, analyticsForStudent] = useMemo(() => {
		const controlSets = new Set()
		const analyticsForStudent = analytics
			.filter(analyticsForMission => {
				const shouldUse = Boolean(
					analyticsForMission.analytics?.studentAnalytics?.[studentId]
				)
				if (shouldUse && analyticsForMission.controlSet) {
					controlSets.add(analyticsForMission.controlSet)
				}
				return shouldUse
			})
			.sort((a, b) => new Date(a.createdTime).getTime() - new Date(b.createdTime).getTime())
		return [getCategoriesForControlSet(controlSets), analyticsForStudent]
	}, [studentId, analytics])
	const mostRecent = analyticsForStudent[analyticsForStudent.length - 1]
	const student = useMemo(() => {
		if (mostRecent?.analytics?.studentAnalytics?.[studentId]) {
			const allMissionsWithSelScoresForStudent: Array<StudentMissionScoreData> = []
			analyticsForStudent.forEach(data => {
				if (!data.analytics || !data.simulationId) {
					return
				}
				const analytics = data.analytics
				allMissionsWithSelScoresForStudent.push({
					missionId: data.missionId,
					missionName: data.missionName || 'Unknown',
					missionDate: new Date(data.createdTime),
					studentPoints: getTotalPoints(studentId)(data),
					scores: getSelScoresForStudent(analytics, studentId),
				})
			})
			const selObjectsFormat = allMissionsWithSelScoresForStudent.map(data => ({
				[studentId]: data.scores,
			}))
			const averages = averageSelScores(selObjectsFormat)[studentId]
			const pointTotals = allMissionsWithSelScoresForStudent
				.map(data => data.studentPoints)
				// Filter out null/undefined values
				.filter(point => point != null)

			const student: {
				totalPoints: ?number,
				averagePoints: number,
				averages: SelScores,
				lastMission: StudentMissionScoreData,
				pastMissions: StudentMissionScoreData[],
				allMissions: StudentMissionScoreData[],
			} = {
				totalPoints: pointTotals[0],
				averagePoints: Math.round(mean(pointTotals)),
				averages,
				lastMission: allMissionsWithSelScoresForStudent[0],
				pastMissions: allMissionsWithSelScoresForStudent.slice(1),
				allMissions: allMissionsWithSelScoresForStudent,
			}

			return student
		}
	}, [mostRecent, studentId, analyticsForStudent])
	const backToAnalytics = () => setStudentQuery(undefined)

	if (studentName == null && !isLoading) {
		return (
			<div>
				<div>
					<ButtonLink onClick={backToAnalytics}>Analytics</ButtonLink>{' '}
				</div>
				<p>Unable to fetch student’s data. Please go back and try again</p>
			</div>
		)
	}
	return (
		<div css="max-width: 890px; margin: 0 auto;">
			<div>
				<ButtonLink onClick={backToAnalytics}>Analytics</ButtonLink>{' '}
				<MdChevronRight className="inline mx-2" size={20} /> {studentName}
			</div>
			<PageTitleHeader css="margin-bottom: 0px;">{studentName}</PageTitleHeader>
			<hr className="my-3" />
			{isLoading ? (
				<Loading style={{ width: 'auto', height: '56px', margin: '44px auto' }} />
			) : !mostRecent || !student ? (
				<div className="flex flex-col items-center">
					<img src={EmptyAnalyticsImage} alt="No Analytics" className="max-w-96" />
					<p className="text-2xl">Looks like {studentName} doesn’t have any data yet!</p>
				</div>
			) : (
				<>
					<StudentSummary student={student} categories={categories} />

					<StudentMissionsTable
						missions={student.allMissions}
						categories={categories}
						studentName={studentName}
						version={mostRecent.version}
					/>
				</>
			)}
		</div>
	)
}

type StudentTableRow = {|
	missionDate: Date,
	missionScore: ?number,
	...ProficiencyTableEntry,
|}

/**
 * Renders a table to display all the student mission scores
 *
 * @param {Array<StudentMissionScoreData>} props.pastMissions an array of all the past missions a student participated in.
 * @param { Array<Category>} props.categories - the categories to display on the table
 * @param {string} props.studentName - the name of the student to show analytics for
 *
 * @returns {React$Node}
 */
function StudentMissionsTable({
	missions,
	categories,
	studentName,
	version,
}: {|
	missions: Array<StudentMissionScoreData>,
	categories: Array<Category>,
	studentName: ?string,
	version: number,
|}): React$Node {
	const data: Array<StudentTableRow> = useMemo(() => {
		return missions.map(scoreData => {
			const selScore = scoreData.scores
			return {
				type: 'MISSION',
				id: scoreData.missionId,
				name: scoreData.missionName,
				missionDate: scoreData.missionDate,
				missionScore: scoreData.studentPoints,
				grit: selScore.disposition.grit,
				initiative: selScore.disposition.initiative,
				application: selScore.knowledge.application,
				questions: selScore.knowledge.questions,
				collaboration: selScore.skills.collaboration,
				criticalThinking: selScore.skills.criticalThinking,
			}
		})
	}, [missions])

	const initialColumns = useMemo(() => {
		return [
			{
				header: 'Mission',
				id: NAME,
				// Use missionDate as the "value" so that sorting works correctly.
				accessorKey: 'missionDate',
				// Renders the date the mission was flown and the name of the mission simulation.
				cell: ({
					getValue,
					row,
				}: {
					getValue: () => string,
					row: { original: StudentTableRow },
				}) => {
					return (
						<div className="font-normal text-left">
							<div>{moment(row.original.missionDate).format('l')}</div>
							<div>{row.original.name}</div>
						</div>
					)
				},
				sortingFn: 'datetime',
				minSize: 100,
				meta: {
					csvAccessor: (element: StudentTableRow) =>
						`${moment(element.missionDate).format('l')} ${element.name}`,
				},
			},
			{
				header: 'Mission Score',
				id: 'missionScore',
				accessorKey: 'missionScore',
				cell: RenderNumberScore,
				minSize: 75,
			},
		]
	}, [])

	return (
		<div className="mt-12">
			<ProficiencyTable
				{...{
					data,
					categories: categories.map(category => category.key),
					getCsvName: displayType =>
						`${studentName ??
							'unknown student'} Mission Scores - ${SCORE_FORMAT_DISPLAY[
							displayType
						] ?? 'Unknown Data Type'}`,
					allowDownloadAll: false,
					initialColumns,
					version,
					initialState: { sorting: [{ id: NAME, desc: true }] },
				}}
			/>
		</div>
	)
}
