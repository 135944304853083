import React from 'react'
import styled from 'styled-components/macro'
import { ProficiencyLegend } from '../AnalyticsCharts/ProficiencyBarGraph/Legend'
import LineGraph from './LineGraph'
import { translateScoreToProficiencyLevel } from '../AnalyticsCharts/ProficiencyBarGraph/constants'
import { ColorCoin } from '../AnalyticsCharts/ProficiencyBarGraph/sharedComponents'
import type { Category, Student } from './constants'
import type { SelScores } from '../../../models/Analytics'
import DataViewContext from '../DataViewContext'

type Props = {
	student: Student,
	categories: Category[],
}

type SummaryProps = {
	entity: { totalMissions: number, averages: SelScores, averagePoints?: number },
	categories: Category[],
}

export function Summary({ entity, categories }: SummaryProps): React$Node {
	return (
		<div className="bg-primary-750 p-6 rounded-lg">
			{entity.averagePoints != null ? (
				<div className="mb-4 ml-6">
					Average Mission Score: {entity.averagePoints.toLocaleString('en-US')}
				</div>
			) : null}
			<div className="flex">
				<ProficiencyLegend />
				<div className="border-l my-2 mx-6" />
				<MedallionAverages
					average={entity.totalMissions === 0 ? undefined : entity.averages}
					categories={categories}
				/>
			</div>
		</div>
	)
}

export default function StudentSummary(props: Props): React$Node {
	return (
		<>
			<h3 className="text-center text-2xl mb-2">Overall Performance this School Year</h3>
			<Summary
				entity={{
					totalMissions: props.student.allMissions.length,
					averages: props.student.averages,
					averagePoints: props.student.averagePoints,
				}}
				categories={props.categories}
			/>
			{props.student.allMissions.length > 0 ? (
				// $FlowFixMe[prop-missing] student.allMissions does not need the prop studentScores.
				// $FlowFixMe[incompatible-type] allMissions[].scores can be null after going through a sort by month/week
				<DataViewContext scores={props.student.allMissions}>
					<h3 className="mt-6 text-center text-2xl mb-2">
						Student Proficiency over Time (by Skill)
					</h3>
					<LineGraph categories={props.categories} />
				</DataViewContext>
			) : null}
		</>
	)
}

/**
 * A component that displays a list of students average scores.
 * @param {Category[]} categories categories student will be graded
 * @param {?SelScores} average average sel scores earned by a student.
 * @returns {React$Node}
 */
function MedallionAverages({
	categories,
	average,
	...props
}: {
	categories: Category[],
	average: ?SelScores,
}): React$Node {
	return (
		<MedallionAveragesStyle {...props}>
			{categories.map(category => {
				const proficiencyLevel = translateScoreToProficiencyLevel(
					average ? category.accessor(average) : null
				)
				return (
					<div key={category.key} css="flex: 1 1 0; width: 0; text-align: center; ">
						<div css="padding: 0 var(--spacing2x-dont-use)">
							<ColorCoin level={proficiencyLevel}>
								<span className="text-2xl">{proficiencyLevel || 'NA'}</span>
							</ColorCoin>
						</div>
						{category.title}
					</div>
				)
			})}
		</MedallionAveragesStyle>
	)
}

const MedallionAveragesStyle = styled.div`
	display: flex;
	flex: 1;
	${ColorCoin} {
		width: 100%;
		min-width: 2em;
		max-width: 6em;
		display: flex;
		justify-content: center;
		align-items: center;
		margin: auto;
	}
`
