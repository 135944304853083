import type { SelScores } from '../../../../store/types'

export const SCORES = [
	{ level: 4, description: 'Beyond' },
	{ level: 3, description: 'Proficient' },
	{ level: 2, description: 'Progressing' },
	{ level: 1, description: 'Beginning' },
]
export const CATEGORIES: Array<{ title: string, lookup: SelScores => ?number, id: string }> = [
	{
		title: 'Knowledge',
		id: 'questions',
		lookup: (score: SelScores) => score.knowledge.questions,
	},
	{
		title: 'Application',
		id: 'application',
		lookup: (score: SelScores) => score.knowledge.application,
	},
	{
		title: 'Initiative',
		id: 'initiative',
		lookup: (score: SelScores) => score.disposition.initiative,
	},
	{
		title: 'Collaboration',
		id: 'collaboration',
		lookup: (score: SelScores) => score.skills.collaboration,
	},
	{
		title: 'Critical Thinking',
		id: 'criticalThinking',
		lookup: (score: SelScores) => score.skills.criticalThinking,
	},
	{ title: 'Resilience', id: 'grit', lookup: (score: SelScores) => score.disposition.grit },
]
// Keep this array in sync with the values in SCORE_TO_LEVELS in src/resources/analyticsScoreThresholds.js in missions-api
export const PERCENTAGE_SCORES = [0, 50, 80, 95, 100]

/**
 * Translates a percentage score to a proficiency level using `PERCENTAGE_SCORES` as the thresholds for the proficiency levels.
 *
 * If `options.getDecimals` is false, the number returned will be an integer in the range [1, 4] representing the proficiency level.
 * If `options.getDecimals` is true, the number returned will be a float in the range [1, 4.999...] where the integer part represents the proficiency level and the decimal part represents
 * how close the student is to the next proficiency level. For example, a score of 2.5 means the student is halfway between a 2 and a 3.
 *
 * @param percentageScoreToTranslate The percentage score to translate to a proficiency level.
 * @param options.getDecimals If true, the returned number will have decimal places. If false, the returned number will be an integer.
 */
export function translateScoreToProficiencyLevel(
	percentageScoreToTranslate: ?number,
	{ getDecimals = false }: { getDecimals?: boolean } = {}
): number | null {
	if (percentageScoreToTranslate == null) return null

	const proficiencyScores = [1, 2, 3, 4]
	const percentageScores = [...PERCENTAGE_SCORES]
	const MAX_SCORE = percentageScores.pop()
	const index = percentageScores.findIndex((_, i, list) => {
		return (
			percentageScoreToTranslate >= list[i] &&
			(i + 1 === list.length || percentageScoreToTranslate < list[i + 1])
		)
	})

	if (index < 0) {
		return null
	}

	// 1, 2, 3, or 4
	const proficiencyLevel = proficiencyScores[index]
	if (!getDecimals) {
		return proficiencyLevel
	}

	const percentageBaseForLevel = percentageScores[index]
	const percentageBaseForNextLevel = percentageScores[index + 1] ?? MAX_SCORE + 0.0001 // Add 0.0001 to make it impossible to get a 5
	const baselinePercentageDiff = percentageBaseForNextLevel - percentageBaseForLevel
	const scorePercentageDiff =
		Math.min(percentageScoreToTranslate, MAX_SCORE) - percentageBaseForLevel
	return proficiencyLevel + scorePercentageDiff / baselinePercentageDiff
}
