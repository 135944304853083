import React, { lazy, Suspense } from 'react'
import { useParams } from 'react-router-dom'
import { useQuery, useQueryClient } from 'react-query'
import { getQuestions } from '../../models/Question'
import styled from 'styled-components'
import type { AnalyticsObject } from '../../models/Analytics'

import { Loading } from '../../components'
import { useAnalyticsForMissionId } from '../analytics/CategoryInfoPage/components/EndOfMissionResultsBlurb'
import type { AnalyticsQuestionResponse } from '@mission.io/mission-toolkit'
import { useUser } from '../../services/hooks'

const ConsolidatedDataView = lazy(() => import('./MissionResultsForLazyLoad'))

const Loader = () => {
	return <Loading style={{ width: '20vw', height: '20vw', margin: '20vh auto' }} />
}

// End of mission results view - lazy loads a component that imports mission player's end-of-mission view.
export default function EndOfMissionResults(): React$Node {
	const params = useParams()
	const missionId = params.id
	const { analytics, isLoading: isLoadingAnalytics, error } = useAnalyticsForMissionId(missionId)
	const { data: questions, isLoading: isLoadingQuestions } = useQuestions(analytics)

	if (isLoadingAnalytics || isLoadingQuestions) {
		return (
			<Positioner>
				<Loader />
			</Positioner>
		)
	}

	if (error || !analytics) {
		return (
			<Positioner>
				<div css="margin: 20vh auto; padding: 10vw;">
					<h1>
						Failed to generate end of mission results for mission. Please return to{' '}
						<a href="/">main page.</a>
					</h1>
				</div>
			</Positioner>
		)
	}

	return (
		<Positioner>
			<Suspense fallback={<Loader />}>
				<ConsolidatedDataView analytics={analytics} questions={questions} />
			</Suspense>
		</Positioner>
	)
}

const Positioner = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 101;
	background-color: white;
`

// Hook to get questions from analytics object.
function useQuestions(analytics: ?AnalyticsObject) {
	const { user } = useUser()
	const studentAnalytics = analytics?.analytics?.studentAnalytics || {}
	const neededQuestions: { [questionId: string]: Set<number> } = {}
	Object.keys(studentAnalytics).forEach(studentId => {
		const studentAnswers = studentAnalytics[studentId].questionAnswers
		if (!studentAnswers) {
			return
		}
		studentAnswers.forEach((answer: AnalyticsQuestionResponse) => {
			if (!neededQuestions[answer.questionId]) {
				neededQuestions[answer.questionId] = new Set()
			}
			neededQuestions[answer.questionId].add(answer.questionVersion)
		})
	})
	const questionsQuery = useQuery(getQuestions(neededQuestions, useQueryClient(), Boolean(user)))
	return questionsQuery
}
