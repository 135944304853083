import React, { useState } from 'react'
import styled from 'styled-components/macro'

import DeleteTeacherModal from './modals/DeleteTeacherModal'
import { IconCircleWrapper } from '../../styles/sharedComponents'

import GoKey from 'react-icons/lib/go/key'
import FaTrash from 'react-icons/lib/io/trash-b'
import FaPencil from 'react-icons/lib/fa/pencil'
import FaRocket from 'react-icons/lib/fa/rocket'
import MdBusiness from 'react-icons/lib/md/business'
import MdHome from 'react-icons/lib/md/home'
import MdEmail from 'react-icons/lib/md/email'

import type { UserOnLicense } from './types'
import { rolesEnum } from '../../store/types'
import { ROLE_ORDERING } from './helpers'
import { EmailInvitationModal } from './modals/InviteTeacherModal'

type Props = {
	user: UserOnLicense,
	disabled?: boolean,
	notOnLicense?: boolean,
	onClickEdit?: () => mixed,
}

const ROLE_ICONS = {
	[rolesEnum.LICENSE_ADMIN]: { component: <GoKey />, title: 'License Manager' },
	[rolesEnum.DISTRICT_ADMIN]: { component: <MdBusiness />, title: 'District Administrator' },
	[rolesEnum.DEPRECATED_SCHOOL_ADMIN]: {
		component: <FaRocket />,
		title: 'School Flight Director',
	},
	[rolesEnum.SCHOOL_ADMIN]: { component: <MdHome />, title: 'School Administrator' },
}

/**
 * @param props
 * @param props.onClickEdit A function to be called when the edit button is clicked
 */
export default function UserLicenseRow({
	user,
	disabled,
	notOnLicense,
	onClickEdit,
}: Props): React$Node {
	const [showDeleteModal, setShowDeleteModal] = useState(false)
	const [showInvitationModal, setShowInvitationModal] = useState(false)

	const showableRoles = user.roles
		.filter(
			({ verified, role }) =>
				verified && ROLE_ICONS.hasOwnProperty(role) && ROLE_ORDERING.hasOwnProperty(role)
		)
		.sort((a, b) => ROLE_ORDERING[a.role] - ROLE_ORDERING[b.role])

	return (
		<>
			<LicenseUser>
				<Info>
					{user.name}
					{showableRoles.length ? (
						<IconPositioner>
							{' '}
							{showableRoles.map(({ role }, index) => (
								<IconWrapper
									as="div"
									key={`${role}-${index}`}
									className={role}
									title={ROLE_ICONS[role].title}>
									{ROLE_ICONS[role].component}
								</IconWrapper>
							))}{' '}
						</IconPositioner>
					) : null}
				</Info>
				<i css="flex: 1; padding-left: 8px;">{user.email}</i>
				<div className="gap-2 flex shrink">
					{notOnLicense ? (
						<IconWrapper
							disabled={disabled}
							onClick={() => setShowInvitationModal(true)}
							title="Invite to License">
							<MdEmail />
						</IconWrapper>
					) : (
						<>
							<IconWrapper
								disabled={disabled}
								onClick={() => setShowDeleteModal(true)}>
								<FaTrash
									title={'Remove User from License'}
									aria-label={'Remove User from License'}
								/>
							</IconWrapper>
							<IconWrapper
								disabled={disabled}
								onClick={() => onClickEdit?.()}
								id={`popover-target-${user.id}`}>
								<FaPencil title={'Edit User'} aria-label={'Edit User'} />
							</IconWrapper>
						</>
					)}
				</div>
			</LicenseUser>

			{/* Modals */}
			<DeleteTeacherModal
				isOpen={showDeleteModal}
				user={user}
				onRequestClose={() => setShowDeleteModal(false)}
			/>
			<EmailInvitationModal
				isOpen={showInvitationModal}
				emails={[user.email]}
				onRequestClose={() => setShowInvitationModal(false)}>
				<p css="margin-bottom: 0;">
					Are you sure you want to invite {user.name} (<code>{user.email}</code>) to your
					license? You should only invite someone to your license if you know they belong
					to your organization. If they accept, they will have the same permissions as
					other users on your license.
				</p>
			</EmailInvitationModal>
		</>
	)
}

const LicenseUser = styled.div`
	position: relative;
	display: flex;
	justify-content: space-between;
	align-items: center;

	${({ theme }) => `
		padding-top: var(--spacing1x-dont-use);
		&:not(:last-child) {
			border-bottom: 2px solid ${theme.neutral};
			padding-bottom: var(--spacing1x-dont-use);
		}
	`}
`

const Info = styled.div`
	display: flex;
	width: 30%;
	align-items: center;
`

const IconWrapper = styled(IconCircleWrapper).attrs({ className: 'flex gap-2' })``

const IconPositioner = styled.div`
	margin-left: var(--spacing1x-dont-use);
	margin-right: var(--spacing1x-dont-use);
	display: flex;
	flex-direction: row;
`
