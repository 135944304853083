import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { GridContainer } from './basics'
import QuestionGroupView from './QuestionGroupCard'
import { Button } from '@mission.io/styles'
import { useDebounce } from '../../../utility/hooks'
import { NetworkCommunicator } from '../../../services'
import config from '../../../config'
import { toast } from 'react-toastify'
import styled from 'styled-components/macro'
import type { QuestionLibraryProps } from './QuestionGroups'
import type { ReduxQuestionGroup } from '../../../models/QuestionGroup'
import { GRADE_ARRAY } from '../../../store/missionPrep'
import { useInfiniteQuery } from 'react-query'
import { ONE_MINUTE } from '../../../constants'
import { Loading } from '../../../components'
import { convertQuestionGroup } from '../../../services/hooks/questionGroup'
import { useFavoriteQuestionGroups } from '../../../services/hooks/user'
import { getQuestionCategories } from '../../../store/categories'
import { toIdMap } from '../../../utility/functions'

type BrowserTabProps = $Diff<QuestionLibraryProps, { clearFilters?: * }>

type Params = {|
	grades?: Array<string>,
	search: string,
	subjects: Array<string>,
|}

const QUESTION_GROUP_BROWSE_TAB = 'questionGroupsBrowse'
/**
 * This component is the browser tab in the Library page, where you can
 * query any public question group.
 * @param { BrowserTabProps } props
 * @returns React$Node
 */
export function BrowseTab(props: BrowserTabProps): React$Node {
	const { filtersSection, filters, onSelect } = props

	const filtersDebounced = useDebounce(filters, 1000)
	const categoryLookup = toIdMap(useSelector(getQuestionCategories) ?? [])

	const params: Params = useMemo(() => {
		const { grades, search, subjects } = filtersDebounced
		let _params = {
			search,
			subjects,
		}
		if (grades.length !== GRADE_ARRAY.length) _params = { ..._params, grades }

		return _params
	}, [filtersDebounced])

	const {
		isLoading,
		isError,
		error,
		data,
		fetchNextPage,
		isFetchingNextPage,
		hasNextPage,
	} = useInfiniteQuery({
		queryKey: ([QUESTION_GROUP_BROWSE_TAB, params]: [string, Params]),
		queryFn: ({ pageParam, queryKey }) => {
			// $FlowIgnore[incompatible-type]
			const params: Params = queryKey[1]
			return getQuestionGroupBrowser({ ...params, page: pageParam })
		},
		getNextPageParam: (lastPage, allPages) => {
			if (lastPage.questionGroups.length === 0) return undefined
			return allPages.length + 1
		},
		refetchOnWindowFocus: false,
		staleTime: 5 * ONE_MINUTE,
	})

	const favoriteQuestionGroups = useFavoriteQuestionGroups()

	return (
		<>
			{filtersSection}
			{isLoading ? (
				<Loading css="width: 100px; height: auto; margin: auto" />
			) : isError ? (
				<h2>{error}</h2>
			) : (
				<>
					<GridContainer>
						{data?.pages.map(
							({ questionGroups }: { questionGroups: ReduxQuestionGroup[] }) => {
								return questionGroups.map(_questionGroup => {
									const questionGroup = {
										...convertQuestionGroup(_questionGroup, categoryLookup),
										isFavorite: Boolean(
											favoriteQuestionGroups?.some(
												q => q._id === _questionGroup._id
											)
										),
									}

									return (
										<QuestionGroupView
											key={questionGroup._id}
											questionGroup={questionGroup}
											onClick={() => onSelect(questionGroup._id)}
										/>
									)
								})
							}
						)}
					</GridContainer>
					<div css="margin-top:var(--spacing1x-dont-use);">
						{isFetchingNextPage ? (
							<Loading css="width: 100px; height: auto; margin: auto" />
						) : hasNextPage ? (
							<LinkViewMore onClick={() => fetchNextPage()} outline small>
								View More
							</LinkViewMore>
						) : (
							<span css="color:var(--primary); justify-content: center; display: flex;">
								<i>There are no more question groups</i>
							</span>
						)}
					</div>
				</>
			)}
		</>
	)
}

/**
 * This function is used to fetch the question groups that match the filter passed
 * in the Browse tab.
 * @param {object} params
 * @returns Promise<{ questionGroups: ReduxQuestionGroup[] }>
 */
function getQuestionGroupBrowser(params: {
	[key: string]: mixed,
}): Promise<{ questionGroups: ReduxQuestionGroup[] }> {
	// $FlowIgnore[incompatible-call] We want to pass mixed values as params
	const searchParams = new URLSearchParams(params).toString()
	return NetworkCommunicator.GET(`/questionGroups${searchParams ? '/?' + searchParams : ''}`, {
		host: `${config.missionsAPIURL}/api`,
	})
		.then(({ questionGroups }: { questionGroups: ReduxQuestionGroup[] }) => ({
			questionGroups,
		}))
		.catch(err => {
			toast.error(`Failed to get question groups because ${err.message}`)
			return { questionGroups: [] }
		})
}

const LinkViewMore = styled(Button)`
	display: flex;
	border: none !important;
	margin: auto;
`
