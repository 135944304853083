// @flow
import React, { useRef, useState, useEffect, useCallback } from 'react'
import { useSteps, generateStepsFromConditions } from './useSteps'
import Steps from './steps'
import ReactJoyride, {
	type StoreHelpers,
	type CallBackProps,
	ACTIONS,
	LIFECYCLE,
	EVENTS,
} from 'react-joyride'
import { Popup } from './customComponents'
import { useInteractionContext } from './interactions'
import HelpMenu from '../HelpMenu'
import { useUser } from '../../services/hooks'
import { useSelector } from 'react-redux'
import type { ReduxStore } from '../../store/rootReducer'
import { useTrainingCategoryId } from '../../store/categories'
import { useLocation } from 'react-router-dom'

const DOM_WAIT_TIME = 300
const MAX_NUM_WAIT_INTERVALS = 10
const CLOSING_STEPS = [Steps.BEFORE_WE_CLOSE]
/**
 * A component that manages the joyride walk-through for our users.
 * @returns {React$Node} react component
 */
export default function UserGuide(): React$Node {
	const [showClosingStep, setShowClosingStep] = useState()
	const [noUserSteps, setNoUserSteps] = useState([])
	const _steps = useSteps()
	const steps = showClosingStep ? CLOSING_STEPS : noUserSteps.length ? noUserSteps : _steps
	const helpers = useRef<?StoreHelpers>()
	// Listeners for click events during the user guide
	const [listeners, setListeners] = useState<Array<{ selector: string, fn: () => void }>>([])
	const { user } = useUser()
	const simulations = useSelector((state: ReduxStore) => state.simulations.automatedSimulations)
	const trainingCategoryId = useTrainingCategoryId()
	const location = useLocation()

	// Allows access to joyride mechanics (next~cb, prev~cb) by setting the joyride store helpers
	const setHelpers = (storeHelpers: StoreHelpers) => {
		helpers.current = storeHelpers
	}

	// References an interval which is triggered when a programmatic switch to the next step is made.
	const intervalSearchForNextTarget = useRef()

	// Checks to make sure the next target exists before proceeding, default behavior is to go to the next index in the joyride
	const goWithLookahead = useCallback(
		(_index?: number) => {
			if (!helpers.current || !steps) return
			const joyrideCallbacks = helpers.current
			const index = _index ?? joyrideCallbacks.info().index + 1
			if (index >= steps.length) {
				joyrideCallbacks?.next()
				return
			}
			const target = steps[index].target
			const foundNextElement =
				typeof target === 'string' ? Boolean(document.querySelector(target)) : null
			const intervalSearch = intervalSearchForNextTarget.current
			if (
				foundNextElement ||
				(intervalSearch && intervalSearch.count >= MAX_NUM_WAIT_INTERVALS)
			) {
				if (intervalSearch) {
					clearInterval(intervalSearch.id)
					intervalSearchForNextTarget.current = null
				}
				if (foundNextElement) {
					joyrideCallbacks.go(index)
				} else {
					goWithLookahead(index + 1)
				}
			} else {
				if (!intervalSearch) {
					// If the next element is not in the DOM, then we set up an interval that will wait for the element to become available
					intervalSearchForNextTarget.current = {
						id: setInterval(() => goWithLookahead(index), DOM_WAIT_TIME),
						count: 0,
					}
				} else {
					intervalSearch.count++
				}
			}
		},
		[steps]
	)

	// Updates html event listeners when the listeners array is updated.
	useEffect(() => {
		if (listeners.length) {
			const elementData = []
			listeners.forEach(({ selector, fn }: { selector: string, fn: () => void }) => {
				document.querySelectorAll(selector).forEach(el => {
					elementData.push({ element: el, callback: fn })
				})
			})

			if (elementData.length) {
				elementData.forEach(({ element, callback }) => {
					element.addEventListener('click', callback)
				})
				return () => {
					elementData.forEach(({ element, callback }) => {
						element.removeEventListener('click', callback)
					})
				}
			}
		}
	}, [listeners])

	const [isRunning, setIsRunning] = useState(true)
	const { sendClickInteraction, lookups } = useInteractionContext()

	// When joyride advances to a new step, updates our custom event listeners.
	const setUpSpotlightListener = useCallback(
		({ type, action, ...state }: CallBackProps) => {
			if (action === ACTIONS.CLOSE && type !== EVENTS.TOUR_END) {
				if (!showClosingStep) {
					setShowClosingStep(true)
					setNoUserSteps([])
					helpers.current?.go(0)
				}
			}
			if (type === EVENTS.TOUR_END) {
				// $FlowFixMe[incompatible-use] sendsFinishTourInteraction is a custom property defined by us
				const sendsFinishTourInteraction: ?boolean = state.step?.sendsFinishTourInteraction
				if (sendsFinishTourInteraction) {
					sendClickInteraction(lookups.clickIds.FINISH_TOUR)
				}
				setNoUserSteps([])
				setShowClosingStep(false)
			}

			const currentStep = steps[state.index]

			const delay: ?number = currentStep?.delayTooltip

			if (type === EVENTS.STEP_BEFORE && [ACTIONS.NEXT].includes(action)) {
				if (currentStep?.runBefore) currentStep.runBefore()
				if (delay) {
					setIsRunning(false)
					setTimeout(() => {
						setIsRunning(true)
					}, delay)
				}
			}
			if (state.lifecycle !== LIFECYCLE.TOOLTIP) {
				return
			}

			let newListeners = []
			const joyrideCallbacks = helpers.current
			if (!joyrideCallbacks) {
				return
			}
			if (currentStep?.onClickTargetActions) {
				currentStep.onClickTargetActions.forEach(action => {
					if (action.type === 'NEXT') {
						newListeners.push({
							selector: action.target,
							fn: () => goWithLookahead(),
						})
					} else if (action.type === 'BACK') {
						newListeners.push({
							selector: action.target,
							fn: joyrideCallbacks.prev,
						})
					} else if (action.type === 'GO_TO') {
						const index = steps.findIndex(step => step.id === action.stepId)
						if (index >= 0) {
							newListeners.push({
								selector: action.target,
								fn: () => {
									goWithLookahead(index)
								},
							})
						}
					}
				})
			}
			if (newListeners.length) {
				setListeners(newListeners)
			} else {
				setListeners([])
			}
		},
		[
			goWithLookahead,
			steps,
			showClosingStep,
			sendClickInteraction,
			lookups.clickIds.FINISH_TOUR,
		]
	)

	return (
		<>
			{steps.length > 0 && (
				<ReactJoyride
					continuous
					run={isRunning}
					steps={steps}
					disableOverlayClose
					scrollOffset={200}
					spotlightPadding={0}
					tooltipComponent={Popup}
					floaterProps={{ offset: 5 }}
					getHelpers={setHelpers}
					callback={setUpSpotlightListener}
					styles={{
						options: {
							zIndex: 1001,
							arrowColor: 'var(--primary)',
							backgroundColor: 'var(--primary)',
							textColor: 'var(--app-almost-white)',
							primaryColor: 'var(--secondary)',
						},
					}}
				/>
			)}
			<HelpMenu
				startTourNoUser={() => {
					const createdSteps = generateStepsFromConditions({
						user: user,
						finishedMissions: {},
						interactions: {},
						simulations,
						trainingCategoryId,
						pathname: location.pathname,
					})
					setNoUserSteps(createdSteps)
				}}
			/>
		</>
	)
}
