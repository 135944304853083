import type { Student } from '../../../../../models'
import type { ClassType } from '../../../../../models/ClassType'
import type { Student_New } from '../../../../../models/Student'
import type { JuniorTeam } from '../../../../../store/types'
import NetworkCommunicator from '../../../../../services/NetworkCommunicator'

export type Team = {|
	displayName: string,
	id: string,
	students: Student_New[],
	type: string,
	minStudents: number,
	maxStudents: number,
	lead: ?Student_New,
	icon: string,
	isTeacherTeam: boolean,
	isRequired: boolean,
|}

/**
 * Creates and returns a set of the studentId of every student that has been assigned to a team
 */
export function getAssignedStudents(teams: ?(JuniorTeam[]) | ?(Team[])): Set<string> {
	let assignedStudents = new Set()
	if (!teams) {
		return assignedStudents
	}
	for (let i = 0; i < teams.length; i++) {
		const team = teams[i]
		team.students.forEach(studentItem => {
			if (typeof studentItem === 'string') {
				assignedStudents.add(studentItem)
			} else if (studentItem?._id) {
				const student = ((studentItem: any): Student)
				assignedStudents.add(student._id)
			}
		})
		if (team.lead) {
			if (typeof team.lead === 'string') {
				assignedStudents.add(team.lead)
			} else {
				assignedStudents.add(team.lead._id)
			}
		}
	}
	return assignedStudents
}

/**
 * This function checks to see if the number of students assigned to teams is equal to
 * the number of students in the class and if the teams are acceptable. If both of those are true,
 * true is returned. Otherwise false is returned.
 */
export function checkStudentAssignments(
	teams: ?(JuniorTeam[] | Team[]),
	currentClass: ClassType
): boolean {
	if (teams && getAssignedStudents(teams).size === currentClass.students.length) {
		return true
	} else {
		return false
	}
}

/**
 * getRequiredStudentCountForTeams - get the minimum amount of students required to satisfy the required teams minStudents
 *
 * @param  {?(JuniorTeam[]|Team[]} teams - the teams to get the required student count for
 *
 * @returns number
 */
export function getRequiredStudentCountForTeams(teams: ?(JuniorTeam[] | Team[])): number {
	if (!teams) {
		return 0
	}
	return teams.reduce((requiredStudentCount, team) => {
		if (!team.isRequired) {
			return requiredStudentCount
		}
		return requiredStudentCount + team.minStudents
	}, 0)
}

/**
 * This function takes a list of Teams and transforms them into type JuniorTeam.
 * This is done by simply changing any property on the object
 * of type Student and replaces it with the student's id.
 */
export function normalizeTeams(teams: Team[]): JuniorTeam[] {
	return teams.map(team => {
		return {
			displayName: team.displayName,
			type: team.type,
			id: team.id,
			minStudents: team.minStudents,
			maxStudents: team.maxStudents,
			lead: team.lead && team.lead._id,
			students: team.students.map(student => student._id),
			icon: team.icon,
			isTeacherTeam: team.isTeacherTeam,
			isRequired: team.isRequired,
		}
	})
}

/**
 * This function checks if there is a team lead and if the number of students assigned to the team
 * is greater than or equal to the minimum number of required students, and less then or equal to the
 * maximum number of students. Returns true of those requirements are met, otherwise returns false.
 */
export function isTeamAcceptable(team: Team | JuniorTeam): boolean {
	let memberCount = getMemberCount(team)
	return Boolean(team.lead) && memberCount >= team.minStudents && memberCount <= team.maxStudents
}

/**
 * Checks if every team in a list of teams has a team lead and if the number of assigned students
 * to that team is not less than the minimum or greater than the maximum number of required students for that team.
 * It returns false if any team does not meet the requirements.
 */
export function areTeamsAcceptable(teams: ?(JuniorTeam[]) | ?(Team[])): boolean {
	if (!teams) {
		return false
	}
	for (let team of teams) {
		if (!isTeamAcceptable(team)) {
			return false
		}
	}
	return true
}

/**
 * Gets the number of students assigned to a team including the team lead.
 */
export function getMemberCount(team: JuniorTeam | Team): number {
	return team.students.length + ((team.lead && 1) || 0)
}

export function getRandomAssignmentsFromServer(
	students: string[],
	teams: JuniorTeam[],
	simulationId: string,
	callBack: ({ randomizedTeams?: JuniorTeam[], error?: string }) => void
) {
	let request = `/juniorteams/populate/${simulationId}`
	NetworkCommunicator.POST(request, { body: { students, teams } })
		.then((res: { teams: JuniorTeam[] }) => {
			callBack({ randomizedTeams: res.teams })
		})
		.catch(err => {
			console.log('ERROR:', err)
			callBack({ error: err })
		})
}

/**
 * Converts a list of type JuniorTeam to type Team. It returns a list of Teams sorted by the display name.
 * The class is required in this function to get the students associated with the studentIds in the JuniorTeam objects.
 */
export function juniorTeamsToTeams(_class: ClassType, teams: JuniorTeam[]): Team[] {
	let students: { [string]: Student_New } = {}
	_class.students.forEach(student => {
		students[student._id] = student
	})
	return teams
		.sort((a: JuniorTeam, b: JuniorTeam) => {
			if (a.displayName > b.displayName) {
				return 1
			}
			if (a.displayName < b.displayName) {
				return -1
			}
			return 0
		})
		.map((team: JuniorTeam) => {
			return {
				...team,
				lead: team.lead ? students[team.lead] : null,
				students: team.students.map(studentId => students[studentId]),
			}
		})
}
