import React from 'react'
import styled from 'styled-components'
import type { StyledType } from 'global-types'
import classnames from 'classnames'

export function Line({
	className,
	dotted,
	...props
}: {
	className?: string,
	dotted?: boolean,
}): React$Node {
	return (
		<div
			{...props}
			className={classnames(
				className,
				'border-l-[3px] border-l-primary-50 h-[24px] w-0',
				dotted && 'border-dotted'
			)}></div>
	)
}
export const ColorLevel: StyledType<> = styled.div.attrs(() => ({
	className: classnames('ColorLevel text-primary-50'),
}))`
	background: ${({ level }) => (level ? `var(--proficiency-level-${level})` : `var(--app-gray)`)};
`

export const ColorCoin: StyledType<> = styled(ColorLevel)`
	border-radius: 50%;
	aspect-ratio: 1 / 1;
	width: 24px;
	margin-right: var(--spacing1x-dont-use);
`

export const ListItem: StyledType<> = styled.div`
	display: flex;
	align-items: center;
	:not(:last-child) {
		margin-bottom: var(--spacing1x-dont-use);
	}
`
