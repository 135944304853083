/**
 * A file containing helper functions for the user guide. This file should be a leaf node in the dependency tree.
 */
import { SHOW_NEW_ONBOARDING } from './flag'
import { useQuery, type UseQueryResult } from 'react-query'
import { NetworkCommunicator } from '../../services'
import config from '../../config'
import { mapValues } from 'lodash'
import type { User } from '../../store/types'
import type { FinishedMission } from '../../queryHooks/mission'
import type { AutomatedSimulation } from '../../models/Simulation'
import { useUser } from '../../services/hooks'

export const CLICK_IDS = {
	START_TOUR: 'START_TOUR',
	RESTART_TOUR: 'RESTART_TOUR',
	CLOSE_TOUR: 'CLOSE_TOUR',
	FINISH_TOUR: 'FINISH_TOUR',
	SAVE_MISSION: 'SAVE_MISSION',
	CANCEL_MISSION_PREPPED_DIALOG: 'CANCEL_MISSION_PREPPED_DIALOG',
}

const SERVICE_ENUM = {
	DASHBOARD: 'DASHBOARD',
	MISSION_CONTROLLER: 'MISSION_CONTROLLER',
}

type ServiceEnum = $Keys<typeof SERVICE_ENUM>

type Services<root = Date> = {
	[service: ServiceEnum]: ?root,
}

export type Interactions<root = Date> = {
	[interactionId: string]: Services<root>,
}

export type GenerateStepsArg = {
	user: ?User,
	finishedMissions: ?IdMap<FinishedMission>,
	interactions: Interactions<>,
	simulations: ?Array<AutomatedSimulation>,
	trainingCategoryId: ?string,
	pathname: ?string,
}

export const INTERACTION_QUERY_KEY = 'user-interaction'
const LAST_LOGIN_TIME = 'LAST_LOGIN_TIME'

const getInteractionQueryKey = (isUser: boolean) => {
	return [INTERACTION_QUERY_KEY, isUser.toString()]
}

/**
 * fetchUserInteractions - fetch data regarding where a user has clicked or url paths that user has visited.
 * if the server is tracking the click id provided it will return a timestamp for each clicked id (or null) if the user has
 * never clicked the element before and same for urls. If
 * @returns {Promise<?{ [string]: Date }>} - the resulting query data is a map of click ids or url paths to the most recent
 * date they were interacted with by the user.
 */
export function useUserInteractions(): UseQueryResult<?Interactions<>, Error> {
	const { user } = useUser()
	return useQuery(
		getInteractionQueryKey(Boolean(user)),
		() => {
			if (!user) return null
			return NetworkCommunicator.GET(`/api/user-interactions/by-type?${queryParams || ''}`, {
				host: config.loginServer,
			})
				.then(
					(result: {
						clicked?: Interactions<string>,
						urlPaths?: Interactions<string>,
						logins: { [loginType: string]: string },
					}) => {
						const lastLogin = Object.keys(result.logins || {}).reduce(
							(latestLoginTime, key) => {
								if (key !== 'ACCOUNT_CREATION') {
									const dateToCompare = new Date(result.logins[key])
									if (!latestLoginTime) {
										return dateToCompare
									}
									if (dateToCompare > latestLoginTime) {
										return dateToCompare
									}
								}
								return latestLoginTime
							},
							null
						)

						return {
							...convertInteractionsToDates(result.clicked || {}),
							...convertInteractionsToDates(result.urlPaths || {}),
							[LAST_LOGIN_TIME]: lastLogin,
						}
					}
				)
				.catch(error => {
					console.error(`Failed to fetch user interactions from login server: ${error}`)
					return null
				})
		},
		{
			refetchOnWindowFocus: false,
			staleTime: 1000 * 60 * 5,
		}
	)
}

/**
 * Converts timestamps in a map of interactions from string to date. Used to convert the data returned from the server
 */
function convertInteractionsToDates(interactions: Interactions<string>): Interactions<Date> {
	return mapValues(interactions, services => {
		return mapValues(services, timestamp => (timestamp ? new Date(timestamp) : null))
	})
}

/**
 * Determines whether a user is in the middle of the welcome tour. This is determined by whether the user has started the tour
 * and has not finished it.
 * @param {boolean} onMissionController - When true, this function will specifically determine whether the welcome tour should be on in the mission controller
 * @returns { boolean }
 */
export function useIsDoingWelcomeTour({
	onMissionController = false,
}: {
	onMissionController?: boolean,
} = {}): boolean {
	const { data: interactions } = useUserInteractions()
	if (!SHOW_NEW_ONBOARDING) return false
	if (!interactions) {
		return false
	}
	const startTour =
		getLatestInteractionDate(interactions[CLICK_IDS.START_TOUR]) ||
		getLatestInteractionDate(interactions[CLICK_IDS.RESTART_TOUR])
	const endTour =
		getLatestInteractionDate(
			interactions[CLICK_IDS.FINISH_TOUR],
			onMissionController ? SERVICE_ENUM.MISSION_CONTROLLER : null
		) || getLatestInteractionDate(interactions[CLICK_IDS.CLOSE_TOUR])
	return startTour ? !endTour || startTour > endTour : false
}

/**
 * Given a `Services` object, i.e. dates that interactions occurred on each service, return the latest date.
 * If a service is specified, do not consider the date for any other service. In other words, return the date for that service.
 */
export function getLatestInteractionDate(services: Services<>, service: ?ServiceEnum): ?Date {
	if (!services) {
		return null
	}
	if (service) {
		return services[service]
	}

	return Object.keys(services).reduce((latest, service) => {
		const date = services[service]
		if (!date) {
			return latest
		}
		if (!latest) {
			return date
		}
		return date > latest ? date : latest
	}, null)
}

type Predicate = 'HAS_CLICKED' | 'HAS_VISITED_PAGE' | 'HAS_LOGGED_IN'
type Condition =
	| 'NEVER' // the user has never done the predicate.
	| 'AT_LEAST_ONCE' // the user has done the predicate at least once
	| 'TODAY' // the user has done the predicate today
	| { type: 'BEFORE_CLICK', clickId: string, service?: ServiceEnum } // the user has done the predicate before interacting with a clickId. Also passes if the BEFORE clickId has never been clicked.
	| { type: 'AFTER_CLICK', clickId: string, service?: ServiceEnum } // the user has done the predicate after interacting with a clickId. Only passes if the AFTER clickId has been clicked.
export type Conditional =
	| {
			type: 'USER_INTERACTION',
			when: Condition,
			predicate: Predicate,
			service?: ServiceEnum,
			value: string,
	  }
	| {
			type: 'CALLBACK',
			fn: GenerateStepsArg => boolean,
			// The ids of click interactions that should be fetched from the server for this conditional.
			ids?: string[],
	  }
	| {
			type: 'OR',
			conditions: Conditional[],
	  }
	| { type: 'AND', conditions: Conditional[] }

const SHOW_TOUR_CHECKERS = [
	{
		type: 'USER_INTERACTION',
		when: 'NEVER',
		predicate: 'HAS_CLICKED',
		value: CLICK_IDS.CLOSE_TOUR,
	},
	{
		type: 'USER_INTERACTION',
		when: 'NEVER',
		predicate: 'HAS_CLICKED',
		value: CLICK_IDS.FINISH_TOUR,
	},
]

export const CONDITIONALS: Array<{
	checkers: Array<Conditional>,
	step: string,
}> = [
	{
		/**
		 * If the user has never logged in before now, show the welcome message for new users.
		 */

		checkers: [
			{
				type: 'OR',
				conditions: [
					{
						type: 'AND',
						conditions: [
							...SHOW_TOUR_CHECKERS,
							{
								type: 'USER_INTERACTION',
								when: 'NEVER',
								predicate: 'HAS_LOGGED_IN',
								value: LAST_LOGIN_TIME,
							},
						],
					},
					{
						type: 'AND',
						conditions: [
							...SHOW_TOUR_CHECKERS,
							{
								type: 'USER_INTERACTION',
								when: 'AT_LEAST_ONCE',
								predicate: 'HAS_LOGGED_IN',
								value: LAST_LOGIN_TIME,
							},
						],
					},
				],
			},
		],
		step: 'WELCOME',
	},
	/**
	 * Display the tour when the latest restart click has occurred after the latest close/finish tour clicks. Also will display
	 * if close/finish clicks have never been clicked, but the restart click has been clicked.
	 */
	{
		checkers: [
			{
				type: 'CALLBACK',
				/**
				 * Function that checks if the user has clicked the restart tour button, and if so, if it was later than any of the
				 * interactions that would close the tour, or the cancel mission prepped dialog.
				 */
				fn: ({ interactions }) => {
					const restartTourDate = getLatestInteractionDate(
						interactions[CLICK_IDS.RESTART_TOUR]
					)
					const dates = [
						restartTourDate,
						getLatestInteractionDate(interactions[CLICK_IDS.CLOSE_TOUR]),
						getLatestInteractionDate(interactions[CLICK_IDS.FINISH_TOUR]),
					]
					let latestDate = null
					dates.forEach(date => {
						if (date && (!latestDate || date > latestDate)) {
							latestDate = date
						}
					})
					return !!restartTourDate && latestDate === restartTourDate
				},
				ids: [CLICK_IDS.CLOSE_TOUR, CLICK_IDS.FINISH_TOUR, CLICK_IDS.RESTART_TOUR],
			},
		],
		step: 'WELCOME',
	},
	/**
	 * checks if the user has finished one of the training missions and no other missions.
	 */
	{
		checkers: [
			{
				type: 'CALLBACK',
				fn: ({ finishedMissions, simulations, trainingCategoryId }) => {
					if (!finishedMissions || !simulations || !trainingCategoryId) {
						return false
					}
					const finishedMissionIds = Object.keys(finishedMissions)
					if (finishedMissionIds.length !== 1) {
						return false
					}
					const finishedMission = finishedMissions[finishedMissionIds[0]]
					const simulation = simulations.find(
						simulation => simulation._id === finishedMission.simulationId
					)
					return !!simulation && simulation.categories.includes(trainingCategoryId)
				},
			},
		],
		step: 'COMPLETED_TRAINING_MISSION',
	},
]

/**
 * Predicates are used to determine steps in the walkthrough. Here we map the predicate to the query param key needed
 * to fetch a users interactions. We also keep track of the unique set of values for each predicate
 * so that we do not send the same query values multiple times.
 */
const PREDICATE_TO_QUERY_PARAM = {
	HAS_CLICKED: { key: 'clicked', uniqueValues: new Set() },
	HAS_VISITED_PAGE: { key: 'urlPaths', uniqueValues: new Set() },
	HAS_LOGGED_IN: { key: 'loggedIn', uniqueValues: new Set() },
}

/* Build parameter defining the user interaction data to pull from server based off of 
what we need to know from the conditionals definitions
 */
let queryParams = null
let checkLogins = false
const buildParams = condition => {
	if (condition.type === 'USER_INTERACTION') {
		const { key, uniqueValues } = PREDICATE_TO_QUERY_PARAM[condition.predicate]
		let thisQuery
		if (uniqueValues.has(condition.value)) {
			return
		}
		if (key === PREDICATE_TO_QUERY_PARAM.HAS_LOGGED_IN.key && !checkLogins) {
			thisQuery = 'getLogins=true'
		} else {
			thisQuery = `${key}=${condition.value}`
		}
		uniqueValues.add(condition.value)
		if (
			typeof condition.when === 'object' &&
			['BEFORE_CLICK', 'AFTER_CLICK'].includes(condition.when.type)
		) {
			buildParams({
				type: 'USER_INTERACTION',
				predicate: 'HAS_CLICKED',
				value: condition.when.clickId,
			})
		}

		if (!queryParams) {
			queryParams = thisQuery
		} else {
			queryParams = `${queryParams}&${thisQuery}`
		}
	} else if (condition.type === 'OR' || condition.type === 'AND') {
		condition.conditions.forEach(buildParams)
	} else if (condition.type === 'CALLBACK') {
		condition.ids?.forEach(id => {
			buildParams({
				type: 'USER_INTERACTION',
				predicate: 'HAS_CLICKED',
				value: id,
			})
		})
	}
}
CONDITIONALS.forEach(conditional => {
	conditional.checkers.forEach(buildParams)
})
