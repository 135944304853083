import React, { useState } from 'react'
import { toast } from 'react-toastify'
import styled from 'styled-components/macro'
import FaTrashO from 'react-icons/lib/fa/trash-o'
import FaPencil from 'react-icons/lib/fa/pencil'
import { useClass, useDeleteClass } from '../../services/hooks/classes'
import { SimpleConfirmation, Dropdown, ACTIONABLE_TOAST_PROPERTIES } from '../../components'
import EditClass from './EditClass'
import TooltipItem from '../../components/helpers/TooltipItem'
import thirdPartyIcons from '../../assets/thirdPartyIcons'
import { gradeLabelMap } from './constants'
import { IconCircleWrapper } from '../../styles/sharedComponents'

import type { Student_New } from '../../models/Student'
import type { GradeEnum } from '../../store/missionPrep'
import Loading from '../../components/loading/Loading'
import { ErrorLinkButton, ErrorOccurred } from '../../components/Error'
import { useUser } from '../../services/hooks'
import RequireSignIn from '../../components/helpers/RequireSignIn'
import { EXAMPLE_USER_ID } from '../../constants/exampleData'
import { config } from '../../config'

type Props = {
	classId: string,
}

/**
 * Takes all the grades of a class and converts them to a
 * nice looking string.
 */
function getStringFromArray(grades: GradeEnum[]): string {
	let returnString = ''
	grades.forEach((grade, index) => {
		if (index !== 0) {
			returnString += ', '
		}
		returnString += gradeLabelMap[grade]
	})
	return returnString
}

/**
 * Displays a list of students.
 */
export function Students({ students }: { students: Student_New[] }): React$Node {
	return (
		<div className="grid justify-between items-center grid-cols-3 py-2 px-3 lg:grid-cols-4">
			{students.length === 0
				? 'No Students'
				: students.map(student => {
						return (
							<div key={student._id} className="inline-block py-1 px-0 font-light">
								{student.firstName} {student.lastName}
							</div>
						)
				  })}
		</div>
	)
}

export default function SingleClass({ classId }: Props): React$Node {
	const [editMode, setEditMode] = useState(false)
	const [deleteToastOpen, setDeleteToastOpen] = useState(false)
	const [dropdownOpen, setDropdownOpen] = useState(false)

	const deleteClassMutation = useDeleteClass()

	const { data: _class, isLoading, isError, refetch } = useClass(classId)

	const { user: currentUser } = useUser()

	const showConfirmDelete = _class => {
		if (deleteToastOpen) {
			return
		}
		const toastId = toast.info(
			<SimpleConfirmation
				message={
					_class.name
						? `Are you sure you want to remove the class "${_class.name}"?`
						: 'Are you sure you want to remove this class?'
				}
				confirmText={'Delete Class'}
				onConfirm={() => {
					toast.dismiss(toastId)
					if (!_class._id) {
						toast.error('Our bad! Error deleting class')
					} else {
						deleteClassMutation.mutate(_class._id, {
							onSuccess: () => {
								toast.success('Class Deleted!')
							},
							onError: () => {
								toast.error('Our bad! Error deleting class')
							},
						})
						setDeleteToastOpen(false)
					}
				}}
				onCancel={() => {
					toast.dismiss(toastId)
					setDeleteToastOpen(false)
				}}
			/>,
			{
				...ACTIONABLE_TOAST_PROPERTIES,
				position: toast.POSITION.BOTTOM_CENTER,
			}
		)
	}

	const dropdownClass = 'mb-4'

	if (isError) {
		return (
			<Dropdown
				onChange={(isOpen: boolean) => {}}
				className={dropdownClass}
				header={
					<ErrorOccurred>
						An error occurred while loading this class.{' '}
						<ErrorLinkButton
							onClick={e => {
								e.stopPropagation()
								refetch()
							}}>
							Retry
						</ErrorLinkButton>
					</ErrorOccurred>
				}
			/>
		)
	}

	if (isLoading || !_class) {
		return (
			<Dropdown
				className={dropdownClass}
				onChange={(isOpen: boolean) => {}}
				header={
					<div className="flex gap-2 text-primary-50">
						<StyledLoading />
						Loading Class
					</div>
				}
			/>
		)
	}
	const userId = currentUser?._id ?? EXAMPLE_USER_ID

	const canDeleteClass = _class.owners.some(({ _id }) => _id === userId)
	const thirdPartyProviders: string[] = Object.keys(
		(_class.externalReferences ?? []).reduce((providerMap, { provider }) => {
			providerMap[provider] = true
			return providerMap
		}, {})
	)
	return (
		<Dropdown
			onChange={(isOpen: boolean) => setDropdownOpen(isOpen)}
			className={dropdownClass}
			header={
				<div className="w-full flex items-center">
					<ClassSpan>
						{'Class: ' +
							(_class.name || '') +
							(_class.type === 'DEMO' ? ' (Demo)' : '')}
					</ClassSpan>
					<GradeSpan>{' Grade: ' + getStringFromArray(_class.grades)}</GradeSpan>
					<TotalStudentsSpan>{`Total Students: ${_class.students.length}`}</TotalStudentsSpan>
					<IconDivWrapper>
						{(!thirdPartyProviders.length ||
							thirdPartyProviders.some(party => party === 'google')) && (
							<RequireSignIn>
								<IconCircleWrapper
									className="mr-2"
									onClick={e => {
										e.stopPropagation()
										setDeleteToastOpen(true)
										showConfirmDelete(_class)
									}}
									disabled={!canDeleteClass}>
									<FaTrashO />
								</IconCircleWrapper>
							</RequireSignIn>
						)}
						{!thirdPartyProviders.length && (
							<RequireSignIn>
								<IconCircleWrapper
									className="mr-2"
									onClick={e => {
										if (editMode) {
											setEditMode(false)
										} else {
											setEditMode(true)
										}
										if (dropdownOpen) {
											e.stopPropagation()
										}
									}}>
									<FaPencil />
								</IconCircleWrapper>
							</RequireSignIn>
						)}
					</IconDivWrapper>
					{thirdPartyProviders.length > 0 && (
						<ThirdPartyIcon
							thirdPartyProviders={thirdPartyProviders}
							classId={classId}
						/>
					)}
				</div>
			}
			styles={{ content: { padding: '0' } }}
			content={
				editMode ? (
					<EditClass
						editingClass={_class}
						onComplete={() => setEditMode(false)}
						onCancel={() => setEditMode(false)}
					/>
				) : (
					<Students students={_class.students} />
				)
			}
		/>
	)
}

/**
 * Displays a third party icon if the class is connected to a third party.
 * @param {ClassType} props._class
 * @returns {React$Node}
 */
const ThirdPartyIcon = ({
	thirdPartyProviders,
	classId,
}: {
	thirdPartyProviders: string[],
	classId: string,
}) => {
	return thirdPartyProviders.map(provider => {
		return (
			<div key={provider} className="size-[30px] mr-2">
				<TooltipItem
					place="top"
					id={`${provider}-${classId}`}
					content={
						<div className="max-w-52">
							This class automatically updates with your class roster in {provider}{' '}
							every time you log&nbsp;in to {config.companyName.base}
						</div>
					}
					text={
						<img
							src={thirdPartyIcons[provider]}
							alt={provider}
							aria-hidden
							className="size-[30px]"
						/>
					}
				/>
			</div>
		)
	})
}

const ClassSpan = styled.div`
	width: 45%;
	display: flex;
`

const GradeSpan = styled.span`
	width: 20%;
`
const TotalStudentsSpan = styled.span`
	flex: 1;
`

const IconDivWrapper = styled.div`
	display: flex;
	flex-shrink: 1;
`

const StyledLoading = styled(Loading)`
	max-width: 5em;
`
