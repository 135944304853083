import React, { useState, useEffect, useMemo } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components/macro'
import { toast } from 'react-toastify'
import { Button } from '@mission.io/styles'
import LoadingSpinner from '../../../styles/LoadingSpinner'
import { ModalFooter, ModalBody } from '../../../components/modal/Modal'
import { useInteractionLookups } from '../../../components/UserGuide/interactions'
import { ClassSelector } from './../../../components/ClassSelector'
import { useUserClasses } from '../../../services/hooks/classes'
import {
	getJuniorPlusTeams,
	createAndAssignTeams,
	isUsingJuniorPlusControls,
	getMissionPrepData,
	getSimulationId,
	setClass,
	setJuniorTeams,
	isMissionBeingEdited,
	getQuestions,
	getPreppedMissionClassId,
} from '../../../store/missionPrep'
import { getAutomatedSimulationFromId } from '../../../store/simulation'
import {
	checkStudentAssignments,
	getRequiredStudentCountForTeams,
} from './DetailComponents/AssignRoles/RolesHelpers'
import AddClassModal from '../../../routes/classes/AddClassModal'
import PrepModalQuestionsContent from './PrepModalQuestionsContent'
import PrepModalRolesContent from './PrepModalRolesContent'
import { useEditMission, useSaveMission } from '../../../queryHooks/mission'
import type { ClassType } from '../../../models/ClassType'
import type { AddPanel } from '../../../utility/hooks'
import RequireSignIn from '../../../components/helpers/RequireSignIn'
import { useUser } from '../../../services/hooks'

export default function PrepModalContent({
	close,
	add,
}: {
	close: () => void,
	add: AddPanel,
}): React$Node {
	const dispatch = useDispatch()
	const prepMissionData = useSelector(getMissionPrepData)
	// error handling for this network call is handled in a sub-component
	const classes = useUserClasses()?.data ?? []
	const teams = useSelector(getJuniorPlusTeams)
	const isJuniorPlus = useSelector(isUsingJuniorPlusControls)
	const missionIsBeingEdited = useSelector(isMissionBeingEdited)
	const prepMissionQuestions = useSelector(getQuestions) || []
	const history = useHistory()
	const classId = useSelector(getPreppedMissionClassId)
	const saveMission = useSaveMission()
	const editMission = useEditMission()

	const [loadingRoles, setLoadingRoles] = useState(false)
	const [showLoadingButton, setShowLoadingButton] = useState(false)
	const simulationId = useSelector(getSimulationId)
	const simulation = useSelector(getAutomatedSimulationFromId(simulationId))
	const [showErrors, setShowErrors] = useState(false)

	const currentClass =
		classes.length && classId ? classes.find(_class => _class._id === classId) : null

	const teamsAcceptable =
		isJuniorPlus && currentClass && checkStudentAssignments(teams, currentClass)

	useEffect(() => {
		if (isJuniorPlus && currentClass && simulationId && !teams) {
			setLoadingRoles(true)
			dispatch(
				createAndAssignTeams(
					currentClass.students.map(stud => stud._id),
					() => {
						setLoadingRoles(false)
					},
					simulationId
				)
			)
		}
	}, [currentClass, isJuniorPlus, teams, dispatch, simulationId])

	const requiredStudentCount = useMemo(() => getRequiredStudentCountForTeams(teams), [teams])

	const userInteractionLookup = useInteractionLookups()

	return (
		<>
			<ModalBody>
				You can customize the following sections if you want. If not, you are all ready to
				go!
				<ClassSection currentClass={currentClass} />
				{showErrors && !currentClass && <Red>Please select a class.</Red>}
				{currentClass?.students && currentClass.students.length < requiredStudentCount ? (
					<Red>
						This mission is optimized for classes with at least {requiredStudentCount}{' '}
						student
						{requiredStudentCount !== 1 ? 's' : null}. You can run the mission with
						fewer students, but the experience may be negatively affected.
					</Red>
				) : null}
				<hr className="my-3" />
				{isJuniorPlus && (
					<>
						<div className="text-xl mb-2">Roles</div>
						<GridSection>
							<div>
								Classes are broken up into teams with a team lead on each team.
								These roles have been randomly assigned. You can edit the role and
								team assignments if you like.
								{classId && !loadingRoles && !teamsAcceptable && (
									<Red>
										There was an issue randomly assigning teams. Please click
										Edit Roles to resolve this issue.
									</Red>
								)}
								{showErrors && loadingRoles && (
									<Red>Please wait for teams to load.</Red>
								)}
							</div>

							<ButtonWrapper>
								<Button
									outline
									disabled={!classId}
									onClick={() =>
										classId &&
										add({
											title: 'Edit Roles',
											component: PrepModalRolesContent,
											props: { classId, simulationId },
										})
									}>
									Edit Roles
								</Button>
							</ButtonWrapper>
						</GridSection>
						<hr className="my-3" />
					</>
				)}
				<div className="text-xl mb-2">
					Questions (We recommend you create your own questions)
				</div>
				<GridSection>
					<div>
						These are default questions based on the mission curriculum that are ready
						to use. We recommend that you create your own questions since you understand
						your students{"'"} needs better than we do.
						{simulation && prepMissionQuestions.length > simulation.maxQuestions && (
							<Red>
								You have too many questions chosen. Please click Edit Questions to
								resolve this.
							</Red>
						)}
					</div>
					<ButtonWrapper>
						<Button
							data-walkthrough="edit-mission-questions"
							onClick={() =>
								add({
									title: 'Edit Questions',
									component: PrepModalQuestionsContent,
								})
							}>
							Edit Questions
						</Button>
					</ButtonWrapper>
				</GridSection>
			</ModalBody>
			<ModalFooter>
				<Button outline variant="secondary" onClick={close} data-walkthrough="maybe-later">
					Maybe Later
				</Button>
				<RequireSignIn>
					<SaveMissionButton
						data-walkthrough="save-mission"
						data-user-interaction-id={userInteractionLookup.clickIds.SAVE_MISSION}
						$loading={showLoadingButton}
						onClick={() => {
							if (
								!classId ||
								loadingRoles ||
								(isJuniorPlus && !teamsAcceptable) ||
								!simulation ||
								prepMissionQuestions.length > simulation?.maxQuestions
							) {
								setShowErrors(true)
								return
							}

							if (prepMissionData && !showLoadingButton) {
								setShowLoadingButton(true)
								if (missionIsBeingEdited) {
									const editMissionId = prepMissionData._id
									if (editMissionId) {
										editMission(editMissionId, prepMissionData)
											.then(() => {
												toast.success('Mission updated!')
												close()
											})
											.catch(error => {
												console.error('Error updating mission', error)
												toast.error('Mission update failed.')
											})
											.then(() => setShowLoadingButton(false))
									} else {
										toast.error('Could not find mission id to update.')
									}
								} else {
									saveMission(prepMissionData).then(savedMissionId => {
										setShowLoadingButton(false)
										if (savedMissionId) {
											toast.success('Mission created!')
											close()
											history.push('/missionLibrary', {
												preppedMissionId: savedMissionId,
											})
										} else {
											toast.error('Mission creation failed.')
										}
									})
								}
							}
						}}>
						<LoadingSpinner
							shouldShowSpinner={showLoadingButton}
							beforeActionText={'Save Mission'}
						/>
					</SaveMissionButton>
				</RequireSignIn>
			</ModalFooter>
		</>
	)
}

const GridSection = styled.div`
	display: grid;
	grid-template-columns: 70% 1fr;
	${({ theme }) => `
		grid-column-gap: var(--spacing2x-dont-use);
	`}
`

const ButtonWrapper = styled.div`
	margin: auto 0 auto auto;
`

const Red = styled.div`
	${({ theme }) => `
		color: ${theme.error};
	`}
`

const SaveMissionButton = styled(Button)`
	${({ theme, $loading }) =>
		$loading &&
		`
		background-color: ${theme.neutralLight};
		display: flex;
		justify-content: center;
	`}
`

/**
 * The class section of the PrepModalContent. Allows picking or creating a class.
 */
function ClassSection({ currentClass }: { currentClass: ?ClassType }) {
	const user = useUser()
	const [isCreatingNewClass, setIsCreatingNewClass] = useState(false)
	const dispatch = useDispatch()
	const { isLoading, isError } = useUserClasses()

	/**
	 * Sets the current prep mission class to `_class`
	 */
	const setPrepMissionClass = (_class: ClassType) => {
		dispatch(setClass({ students: _class.students, classId: _class._id }))
		if (currentClass && _class._id !== currentClass._id) {
			dispatch(setJuniorTeams(null))
		}
	}

	return (
		<div
			css={`
				display: grid;
				gap: var(--spacing1x-dont-use);
				grid-template-areas:
					'header button'
					'select select';

				& #create-class-button {
					justify-self: end;
					align-self: end;
				}

				@media screen and (max-width: 700px) {
					grid-template-areas:
						'header'
						'select'
						'button';

					& #create-class-button {
						justify-self: start;
					}
				}
			`}>
			<div css="grid-area: header;">
				<div className="text-xl mb-2 mt-3">Class</div>
				Select the class that will go on the mission
			</div>
			<div id="create-class-button" css="grid-area: button">
				<RequireSignIn>
					<Button
						onClick={() => setIsCreatingNewClass(true)}
						outline
						disabled={user && (isLoading || isError)}>
						Create new class
					</Button>
				</RequireSignIn>
			</div>
			<div css="grid-area: select;">
				<ClassSelector
					selectedClass={currentClass}
					onSelect={_class => {
						setPrepMissionClass(_class)
					}}
					id="data-walkthrough-select-class"
					classNames={{ option: () => 'data-walkthrough-select-class-option' }}
				/>
			</div>
			<AddClassModal
				isOpen={isCreatingNewClass}
				onCreateClass={(_class: ClassType) => {
					setPrepMissionClass(_class)
					setIsCreatingNewClass(false)
				}}
				cancelNewClassCreation={() => setIsCreatingNewClass(false)}
			/>
		</div>
	)
}
