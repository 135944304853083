import type { UseQueryResult } from 'react-query'

import { config } from '../../config'
import { ONE_MINUTE } from '../../constants'
import NetworkCommunicator from '../NetworkCommunicator'

import axios from 'axios'
import { useQuery } from 'react-query'

export type District = {
	ncesId: string,
	name: string,
}

/**
 * Fetches a district by its id
 */
export function useDistrict(districtId: ?string): UseQueryResult<District | null> {
	return useQuery({
		queryKey: ['district', districtId],
		queryFn: () =>
			districtId == null
				? Promise.resolve(null)
				: NetworkCommunicator.GET(`/api/districts/${districtId}`, {
						host: config.loginServer,
				  }).then(response => response.district),
	})
}

type BasicSchoolData = {
	_id: string,
	name: string,
	city: string,
	state: string,
}

const SCHOOL_TYPES = {
	PUBLIC: 'public',
	CUSTOM: 'custom',
}

/**
 * useSchool - get data about the school with the given Id
 *
 * @param {string} schoolId - the id of the school to get data about
 *
 * @return {UseQueryResult<BasicSchoolData>}
 */
export function useSchool(schoolId: ?string): UseQueryResult<?BasicSchoolData> {
	return useQuery(
		['school', schoolId],
		() => {
			if (schoolId == null) {
				return Promise.resolve(null)
			}
			return axios
				.get(`${config.loginServer}/api/schools/${schoolId}`, {
					withCredentials: true,
				})
				.then(({ data: { school } }) => {
					if (school.schoolType === SCHOOL_TYPES.PUBLIC) {
						return {
							_id: school._id,
							name: school.name,
							city: school.locationCity,
							state: school.locationState,
						}
					}
					return {
						_id: school._id,
						name: school.name,
						city: school.city,
						state: school.state,
					}
				})
		},
		{
			staleTime: 5 * ONE_MINUTE,
		}
	)
}
