import React, { Component } from 'react'

type Props = {
	students: $ReadOnlyArray<$ReadOnly<{ firstName: string, lastName: string }>>,
}

export default class UploadedClass extends Component<Props> {
	render(): React$Node {
		return (
			<div className="mb-2">
				<div className="h-8 text-lg mb-1 border-b border-b-gray-c">
					<div className="inline-block w-1/3 font-semibold">First Name</div>
					<div className="inline-block w-1/3 font-semibold">Last Name</div>
				</div>
				<div>
					{this.props.students.map((student, index) => {
						return (
							<div key={index}>
								<div className="inline-block w-1/3 py-1">{student.firstName}</div>
								<div className="inline-block w-1/3 py-1">{student.lastName}</div>
							</div>
						)
					})}
				</div>
			</div>
		)
	}
}
