import type { TimeSelectOption } from '../hooks'

import { round } from '../../../utility/functions'

const CSV_DECIMAL_PLACES = 2

/**
 * getCsvUrl - get a url for a csv file generated from the data with the given columns
 *
 * @param {any[]} data - the data to generate csv files from
 * @param {any[]} columns - the columns for the csv file
 *
 * @return {?string} - the url for the csv file, null/undefined if the csv did not generate or failed to generate
 */
export function getCsvUrl(data: any[], columns: any[]): ?string {
	const file = generateCSV(data, columns)
	if (!file) {
		return
	}
	return URL.createObjectURL(
		new Blob([file], {
			type: 'text/plain',
		})
	)
}

/**
 * generateCSV - generate a csv from from the data with the given columns
 *
 * @param {any[]} data - the data for all rows in the table
 * @param {any[]} columns - the columns with selectors for getting elements from the data
 *
 * @return {?string} a csv file
 */
export function generateCSV(data: any[], columns: any[]): ?string {
	try {
		let csvRows: string[] = [
			columns
				.map(column => {
					if (typeof column.meta?.csvHeader === 'string') {
						return generateCsvEntry(column.meta.csvHeader)
					}
					if (typeof column.header === 'string') {
						return generateCsvEntry(column.header)
					}
					return generateCsvEntry(column.id ?? 'Unknown')
				})
				.join(','),
		]
		data.forEach(element => {
			csvRows.push(
				columns
					.map(column => {
						let value = null
						if (column.meta?.csvAccessor) {
							value = column.meta.csvAccessor(element)
						} else if (column.accessorKey != null) {
							value = element[column.accessorKey]
						} else if (column.accessorFn) {
							value = column.accessorFn(element)
						}
						return generateCsvEntry(value)
					})
					.join(',')
			)
		})

		return csvRows.join('\n')
	} catch (error) {
		console.error(
			`The following error occurred while trying to generate a CSV in Analytics`,
			error
		)
	}
}

/**
 * generateCsvEntry - generate a csv entry (cell) for the given value
 *
 * @param {mixed} value - the value to generate an entry for
 *
 * @return {string} - the entry to put into the csv
 */
function generateCsvEntry(value: mixed): string {
	if (value == null) {
		return ''
	}
	if (typeof value === 'function') {
		return ''
	}
	if (typeof value === 'number') {
		return String(round(value, CSV_DECIMAL_PLACES))
	}
	if (typeof value === 'string') {
		return createCSVStringEntry(value)
	}
	if (typeof value === 'object') {
		return createCSVStringEntry(JSON.stringify(value))
	}
	return ''
}

/**
 * createCSVStringEntry - create csv string entry
 *
 * @param {string} str - the string to convert to a csvString element
 *
 * @return {string} - an entry to put into a csv (wil be wrapped in double quotes)
 */
function createCSVStringEntry(str: string): string {
	return `"${str
		.split('')
		.map(char => {
			if (char !== '"') {
				return char
			}
			return `"${char}`
		})
		.join('')}"`
}

// csv date helper functions

/**
 * getDateByMonthsAgo - get the current date minus the number of months described by `monthsAgo`.
 *
 * @param {number} monthsAgo - how many months to go back
 *
 * @return {Date}
 */
export function getDateByMonthsAgo(monthsAgo: number): Date {
	const date = new Date()
	date.setMonth(date.getMonth() - monthsAgo)
	return date
}

/**
 * getCSVDateRangeString - get data range string for the filename of a csv with amount of months of analytics being included in the csv file
 *
 * @param {TimeSelectOption} timeOption - the time option used to filter the analytics which will be saved to this csv
 *
 * @return {string}
 */
export function getCSVDateRangeString(timeOption: TimeSelectOption): string {
	let startingDate = new Date(timeOption.rangeTimestamps.startTime)
	let endingData = new Date(timeOption.rangeTimestamps.endTime)

	return `${formatDateForCSV(startingDate)} - ${formatDateForCSV(endingData)}`
}

/**
 * formatDateForCSV - format a date to be put into a filename
 *
 * @param {Date} date - the date to format
 *
 * @return {string}
 */
export function formatDateForCSV(date: Date): string {
	return `${date.getMonth()}-${date.getDate()}-${date.getFullYear()}`
}
