// @flow
import React from 'react'
import axios from 'axios'
import { useQuery, type UseQueryResult } from 'react-query'
import { Button } from '@mission.io/styles'
import 'styled-components/macro'
import LoadingSpinner from '../../styles/LoadingSpinner'
import config from '../../config'

type CanvasStudent = {
	givenName: string,
	familyName: string,
	canvasId: string,
	email: ?string,
}

type ServerCanvasCourseInfo = {
	canvasCourseId: string,
	canvasCourseTitle: string,
	students: CanvasStudent[],
}

type CanvasCourseInfo = {
	id: string,
	title: string,
	students: CanvasStudent[],
}

/**
 * Gets the Canvas course from the server
 * @return {UseQueryResult} The result of calling `useQuery`, with an extra property `result.course` that matches `result.data`
 */
function useCanvasCourse(): {
	...UseQueryResult<CanvasCourseInfo>,
	course: ?CanvasCourseInfo,
} {
	const useQueryResult = useQuery('get-canvas-course', async () => {
		const result = await axios
			.get<ServerCanvasCourseInfo, ServerCanvasCourseInfo>(
				`${config.loginServer}/lms/lti/roster`,
				{ withCredentials: true }
			)
			.catch(e => {
				console.log(e)
				throw e.response?.data || e
			})
		const courseInfo: ServerCanvasCourseInfo = result.data
		return {
			id: courseInfo.canvasCourseId,
			title: courseInfo.canvasCourseTitle,
			students: courseInfo.students,
		}
	})

	return { ...useQueryResult, course: useQueryResult.data }
}

export type OnSelectArg = {
	className: string,
	canvasCourseId: string,
	students: Array<{| firstName: string, lastName: string, canvasId: string |}>,
}

/**
 * A panel in the Add Class modal that loads the canvas course for the current user.
 */
export default function LoadCanvasCourse({
	onSelect,
}: {
	onSelect: OnSelectArg => mixed,
}): React$Node {
	const { course, isLoading, error } = useCanvasCourse()

	if (isLoading) {
		return (
			<>
				<h3 className="text-2xl">Canvas Class</h3>
				<LoadingSpinner shouldShowSpinner css="width: 40px; height: 40px;" />
			</>
		)
	}

	if (error || !course) {
		let errorMessage: string = 'There was an error loading the Canvas course'
		if (typeof error === 'object' && typeof error?.clientMessage === 'string') {
			errorMessage = error.clientMessage
		}

		return <p>{errorMessage}</p>
	}

	return (
		<>
			<h3>{course.title}</h3>
			{course.students.length === 0 ? (
				<p>There are no students in this course</p>
			) : (
				<div css="column-count: 3; margin-bottom: var(--spacing1x-dont-use);">
					{course.students
						.sort((student1, student2) => {
							const lastNameComparison = student1.familyName.localeCompare(
								student2.familyName,
								'en',
								{ sensitivity: 'base' }
							)
							if (lastNameComparison !== 0) {
								return lastNameComparison
							}

							return student1.givenName.localeCompare(student2.givenName, 'en', {
								sensitivity: 'base',
							})
						})
						.map(({ givenName, familyName, canvasId }) => (
							<div key={canvasId}>
								{familyName}, {givenName}
							</div>
						))}
				</div>
			)}
			<Button
				onClick={() => {
					onSelect({
						className: course.title,
						canvasCourseId: course.id,
						students: course.students.map(student => ({
							firstName: student.givenName,
							lastName: student.familyName,
							canvasId: student.canvasId,
						})),
					})
				}}>
				Continue
			</Button>
		</>
	)
}
