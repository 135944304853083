import { rolesEnum } from '../../store/types'

// REGEX gotten from stack overflow
// eslint complains about using control characters on the nest line, but we are ok with that
// eslint-disable-next-line no-control-regex
const FIND_EMAILS_IN_STRING_REGEX = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/gi

/**
 * Function that can finds all emails in a string and parses the valid
 * emails into an array.
 * Returns a tuple of 2 values:
 * The first being an array of emails that were successfully parsed,
 * the second being a "leftover" string of all values in the string that did not pass the email matcher.
 *
 * For example: With input "badEmail@com, goodEmail@test.com, whassup"
 * The function will return:
 * [["goodEmail@test.com"], "badEmail@com, whassup"]
 */
export function getEmailsFromString(emailString: string): [?(string[]), ?string] {
	const emails = emailString.match(FIND_EMAILS_IN_STRING_REGEX)
	if (!emails) {
		return [null, emailString]
	}
	const leftoverStr = emails.reduce((str, email) => {
		// Removes emails and any delimiters that exist right after email (whitespace, commas, periods)
		const regex = new RegExp(`${email}(\\s*[,.]*\\s*)`, 'g')
		return str.replace(regex, '')
	}, emailString)
	return [emails, leftoverStr.trim()]
}

/**
 * ID we use to refresh the react query call to get all users on a license.
 */
export const ADMIN_PAGE_USE_QUERY_ID = 'fetch-users-on-license'

export const ROLE_ORDERING: { [$Keys<typeof rolesEnum>]: number } = (() => {
	const ordering = [
		rolesEnum.LICENSE_ADMIN,
		rolesEnum.DISTRICT_ADMIN,
		rolesEnum.SCHOOL_ADMIN,
		rolesEnum.DEPRECATED_SCHOOL_ADMIN,
	]
	const orderingMap = {}
	ordering.forEach((role, index) => (orderingMap[role] = index))
	return orderingMap
})()
