import React, { useState } from 'react'
import styled from 'styled-components/macro'
import { CLASS_NAME, GRADE } from './constants'
import { Button } from '@mission.io/styles'
import AddClassModal from './AddClassModal'
import Select from 'react-select'

import type { SortingOption } from './constants'
import type { OwnerType } from '../../models/ClassType'
import { getClassOwnerName } from '../../services/hooks/classes'
import RequireSignIn from '../../components/helpers/RequireSignIn'
import { AppReactSelectClassNames, AppReactSelectTheme } from '../../components/inputs/styles'

type Props = {
	classSortType: SortingOption,
	setClassSortType: SortingOption => void,
	selectedOwner: ?string,
	classOwners: OwnerType[],
	setClassOwner: (ownerId: string) => void,
}

export default function ClassModuleHeader({
	classSortType,
	setClassSortType,
	classOwners,
	selectedOwner: selectedOwnerId,
	setClassOwner,
}: Props): React$Node {
	const [addClassModalIsOpen, setAddClassModalIsOpen] = useState(false)
	const selectedOwner = classOwners.find(({ _id }) => _id === selectedOwnerId)

	return (
		<>
			<ClassPageHeader>
				<div css="display: flex; flex-wrap: wrap; gap: var(--spacing1x-dont-use);">
					<Button
						disabled={classSortType === CLASS_NAME}
						onClick={() => setClassSortType(CLASS_NAME)}>
						Sort by Class Name
					</Button>
					<Button
						disabled={classSortType === GRADE}
						onClick={() => setClassSortType(GRADE)}>
						Sort by Grade
					</Button>
				</div>
				<Row>
					{classOwners.length ? (
						<UserSelectorWrapper>
							<label className="text-sm mb-0">Show Classes For</label>
							<Select
								theme={AppReactSelectTheme}
								classNames={AppReactSelectClassNames}
								placeholder="Choose a user"
								value={
									selectedOwner
										? {
												value: selectedOwner._id,
												label: getClassOwnerName(selectedOwner),
										  }
										: null
								}
								onChange={({ value }) => {
									setClassOwner(value)
								}}
								options={
									classOwners
										.map(owner => ({
											label: getClassOwnerName(owner),
											value: owner._id,
										}))
										.sort((a, b) => a.label.localeCompare(b.label)) || []
								}
							/>
						</UserSelectorWrapper>
					) : null}
					<RequireSignIn>
						<Button
							data-walkthrough="add-class-btn"
							disabled={addClassModalIsOpen}
							onClick={() => {
								setAddClassModalIsOpen(true)
							}}>
							Add Class
						</Button>
					</RequireSignIn>
				</Row>
			</ClassPageHeader>
			<AddClassModal
				isOpen={addClassModalIsOpen}
				onCreateClass={() => setAddClassModalIsOpen(false)}
				cancelNewClassCreation={() => setAddClassModalIsOpen(false)}
			/>
		</>
	)
}

const ClassPageHeader = styled.div`
	display: flex;
	justify-content: space-between;
	gap: var(--spacing1x-dont-use);
	align-items: flex-end;
`

const Row = styled.div`
	display: flex;
	flex-wrap: wrap;
	justify-content: flex-end;
	align-items: flex-end;
	gap: var(--spacing1x-dont-use);

	// When the screen is smaller, stack the elements and put Add Class on top
	@media (max-width: 1060px) {
		flex-direction: column-reverse;
		gap: 0;
	}
`

const UserSelectorWrapper = styled.div`
	display: flex;
	flex-direction: column;
	width: 20em;
	${({ theme }) => `
		margin-right: var(--spacing1x-dont-use);
	`}
`
