import React, { useEffect, useMemo, useState } from 'react'
import { useShowFileInFileViewer } from '../FileViewer/FileViewerContext'
import { useFileBrowserFolderMap, useShowFolderMapInFileBrowser } from './FileBrowserContext'
import BasicFileBrowser from './BasicFileBrowser'
import type { File } from '../../utility/FileHelpers'
import { getFilesInCurrentFolder } from '../../utility/FileHelpers'
import Modal from '../modal/Modal'

/**
 * FileBrowser - a component which will show the user a file browser. Controlled by the fileBrowser context.
 *
 * @return {React$Node}
 */
export default function FileBrowser(): React$Node {
	const folderMapData = useFileBrowserFolderMap()
	const setFileToView = useShowFileInFileViewer()
	const setCurrentFolderMap = useShowFolderMapInFileBrowser()

	const [currentPath, setCurrentPath] = useState(null)

	const folderMap = folderMapData?.folderMap
	const filesInCurrentPath = useMemo(() => {
		if (!folderMap || !currentPath) {
			return null
		}

		return getFilesInCurrentFolder(folderMap, currentPath)
	}, [folderMap, currentPath])

	const startingFolder = folderMapData?.startingFolder
	useEffect(() => {
		setCurrentPath(startingFolder)
	}, [startingFolder])

	if (!filesInCurrentPath || !currentPath) {
		return null
	}

	const onRequestClose = () => setCurrentFolderMap(null)

	return (
		<Modal
			isOpen={true}
			onRequestClose={onRequestClose}
			style={{
				content: { display: 'flex', flexDirection: 'column' },
				overlay: { zIndex: 100 },
			}}>
			<BasicFileBrowser
				files={filesInCurrentPath}
				onFileClick={(file: File) => {
					setFileToView({
						src: file.id,
						preview: file.filePreviewSrc,
						name: file.actualName ?? file.name,
					})
				}}
				onFolderClick={(directory: string) => {
					setCurrentPath(directory)
				}}
				directory={currentPath}
				onRequestClose={onRequestClose}
			/>
		</Modal>
	)
}
