import React, { useRef } from 'react'

type Props = {
	onEvent: ({
		type: string,
		videoCurrentTime: ?number,
	}) => mixed,
	src: string,
	className?: string,
	controls: boolean,
	style?: { [string]: string },
}

/**
 * A video component that calls its `onEvent` prop for seek, pause, play, and end events.
 */
export default function VideoTracker({ onEvent, ...props }: Props): React$Node {
	const seeking = useRef(false)
	const videoRef = useRef()

	const handleVideoEvent = (event: SyntheticEvent<>) => {
		if (event.type === 'seeking') {
			if (seeking.current) {
				return
			}

			seeking.current = true
		}
		if (event.type === 'seeked') {
			seeking.current = false
		}
		if (!videoRef.current) {
			return
		}

		onEvent({
			type: event.type,
			videoCurrentTime: videoRef.current.currentTime,
		})
	}

	return (
		<video
			className="shadow-md"
			{...props}
			onSeeked={handleVideoEvent}
			onSeeking={handleVideoEvent}
			onEnded={handleVideoEvent}
			onPlay={handleVideoEvent}
			onPause={handleVideoEvent}
			ref={videoRef}
		/>
	)
}
