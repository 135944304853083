import React from 'react'
import CustomModal, {
	ModalHeader,
	ModalBody,
	ModalFooter,
	ExitX,
} from '../../../components/modal/Modal'
import { Button } from '@mission.io/styles'
import { toast } from 'react-toastify'
import { ADMIN_PAGE_USE_QUERY_ID } from '../helpers'
import { editUserOnLicense } from '../LicenseNetworkRoutes'
import { useQueryClient } from 'react-query'

import type { UserOnLicense } from '../types'

const NON_LICENSE_ADMIN = 'NON_LICENSE_ADMIN'

type Props = { isOpen: boolean, user: UserOnLicense, onRequestClose: () => void }

/**
 * This modal confirms the decision to make a license admin a teacher.
 * After it makes the call, it closes the modal.
 * If the call was successful, it refreshes the react-query that gets the users.
 */
export default function RevokeAdminModal({ isOpen, user, onRequestClose }: Props): React$Node {
	// React Query V2 cache- allows us to refetch the users on license after we delete one
	const queryCache = useQueryClient()

	const showToast = (isSuccess: boolean) => {
		if (isSuccess) {
			toast.success('Revoked ' + user.name + "'s admin privileges.", {
				position: toast.POSITION.TOP_RIGHT,
			})
		} else {
			toast.error('Failed to revoke ' + user.name + "'s admin privileges.", {
				position: toast.POSITION.TOP_RIGHT,
			})
		}
	}
	return (
		<CustomModal
			style={{ content: { width: '30%', top: '20%' } }}
			isOpen={isOpen}
			shouldCloseOnOverlayClick
			onRequestClose={onRequestClose}>
			<ModalHeader>
				Revoke Admin Privilege Confirmation
				<ExitX
					role={'button'}
					aria-label={'Close Revoke Admin Dialog'}
					title="Close"
					onClick={onRequestClose}
				/>
			</ModalHeader>
			<ModalBody>
				Are you sure you want to make {user.name} a teacher and remove their admin
				privileges?
			</ModalBody>
			<ModalFooter>
				<Button
					outline
					onClick={() => {
						editUserOnLicense(
							user.id,
							NON_LICENSE_ADMIN,
							// onSuccess callback
							() => {
								showToast(true)
								queryCache.invalidateQueries(ADMIN_PAGE_USE_QUERY_ID, {
									exact: true,
								})
							},
							// onError callback
							() => showToast(false)
						)
						onRequestClose()
					}}>
					Revoke Admin Privileges
				</Button>
				<Button className="ml-2" onClick={onRequestClose}>
					Cancel
				</Button>
			</ModalFooter>
		</CustomModal>
	)
}
