// @flow
import moment from 'moment'
import { ALL_OPTION, ALL } from './constants'

import type { AnalyticsObject } from '../../models/Analytics'
import type { NameLookup } from '../../download/questions'
import { getFullName } from '../../utility/helpers'

export type SelectOption = { label: string, value: string }

/**
 * The array sort function, implemented for SelectOptions with strings
 */
function genericSort(a: SelectOption, b: SelectOption): number {
	return a.label.localeCompare(b.label)
}

/**
 * The array sort function, implemented for SelectOptions with grades
 */
function gradeSort(a: SelectOption, b: SelectOption): number {
	if (a.label === 'K') return -1
	if (b.label === 'K') return 1
	if (a.label === 'other') return 1
	if (b.label === 'other') return -1

	return Number(a.label) - Number(b.label)
}

/**
 * Given the list of available analytics and the current selected values in the analytics filters, returns
 * the options that should be available in those analytics filters.
 * @param analytics - The list of available analytics
 * @param currentSchoolValue - The currently selected school value
 * @param currentGradeValue - The currently selected grade value
 * @param currentTeacherValue - The currently selected teacher value
 * @param nameLookup - A lookup table for teacher names
 */
export function getSortedOptions(
	analytics: AnalyticsObject[],
	currentSchoolValue: string,
	currentGradeValue: string,
	currentTeacherValue: string,
	nameLookup: NameLookup
): {
	schoolOptions: SelectOption[],
	gradeOptions: SelectOption[],
	teacherOptions: SelectOption[],
	classOptions: SelectOption[],
} {
	const schoolOptions = []
	const gradeOptions = []
	const teacherOptions = []
	const classOptions = []

	const schoolIdToName = new Map()
	const gradeSet = new Set()
	const teacherSet = new Set()
	const classSet = new Set()

	analytics.forEach(analyticsObject => {
		let schoolId = null
		let teacherId = null
		const analyticsMatchesChosenGrade = analyticsObject.grades.includes(currentGradeValue)

		// if the analytics object has a schoolName, then its a valid school to choose from
		// we always want to show all school options
		if (analyticsObject.schoolId) {
			schoolId = analyticsObject.schoolId
			schoolIdToName.set(
				analyticsObject.schoolId,
				analyticsObject.schoolName || 'Unknown School Name'
			)
		}

		// if we are looking at all schools, or the chosen school matches the school for the current `analyticsObject`,
		// we want to add these grades as SelectOptions
		if (currentSchoolValue === ALL || currentSchoolValue === schoolId) {
			analyticsObject.grades.forEach(grade => gradeSet.add(grade))
		} else {
			// the school doesn't match, so don't bother showing
			// teacher and class names for analytics that don't match
			return
		}

		// if the analytics have a teacher name, and the analytics' grades match the chosen grade option
		// add as SelectOptions
		if (
			analyticsObject.teacherId &&
			(currentGradeValue === ALL || analyticsMatchesChosenGrade)
		) {
			teacherId = analyticsObject.teacherId
			teacherSet.add(teacherId)
		}

		// if the analytics have a class name AND  the analytics' grades match the chosen grade option
		// AND they match the current teacher name, then add as SelectOptions
		if (
			analyticsObject.className &&
			(currentTeacherValue === ALL || currentTeacherValue === teacherId) &&
			(currentGradeValue === ALL || analyticsMatchesChosenGrade)
		) {
			classSet.add(analyticsObject.className)
		}
	})

	// create SelectOptions
	for (const [schoolId, schoolName] of schoolIdToName.entries()) {
		schoolOptions.push({ label: schoolName, value: schoolId })
	}
	gradeSet.forEach(item => gradeOptions.push({ label: item, value: item }))
	teacherSet.forEach(item =>
		teacherOptions.push({ label: getFullName(nameLookup[item]) || 'Unknown', value: item })
	)
	classSet.forEach(item => classOptions.push({ label: item, value: item }))

	// sort alphabetically or by grade
	schoolOptions.sort(genericSort)
	gradeOptions.sort(gradeSort)
	teacherOptions.sort(genericSort)
	classOptions.sort(genericSort)

	// Append the ALL SelectOption
	schoolOptions.unshift(ALL_OPTION)
	gradeOptions.unshift(ALL_OPTION)
	teacherOptions.unshift(ALL_OPTION)
	classOptions.unshift(ALL_OPTION)

	return { schoolOptions, gradeOptions, teacherOptions, classOptions }
}

/**
 * Converts the list of analytics objects into a list of SelectOptions for the mission dropdown.
 */
export function createMissionOptions(analytics: AnalyticsObject[]): Array<SelectOption> {
	return analytics.map(({ createdTime, missionName, missionId }) => {
		let simulationName = missionName || 'Unknown Mission'
		const formattedDate = moment(new Date(createdTime)).format('MM/DD/YY h:mma')
		return { label: simulationName + ' ' + formattedDate, value: missionId }
	})
}

export type AnalyticsFilters = {
	className?: string,
	schoolId?: string,
	grade?: string,
	teacherId?: string,
	time: $ReadOnly<{ startTime: number, endTime?: number }>,
}

/**
 * Filters analytics objects out of `analytics` that don't match the provided `filters`.
 */
export function filterAnalytics(
	analytics: AnalyticsObject[],
	filters: AnalyticsFilters
): AnalyticsObject[] {
	// Remove all analytics that do not match the selected school
	if (filters.schoolId) {
		analytics = analytics.filter(analyticsObject => {
			return filters.schoolId === analyticsObject.schoolId
		})
	}

	// Remove all analytics that do not match the selected grade
	if (filters.grade) {
		analytics = analytics.filter(analyticsObject => {
			const grades = analyticsObject.grades
			if (!grades) {
				return false
			}
			return grades.includes(filters.grade)
		})
	}

	// Remove all analytics that do not match the selected teacher
	if (filters.teacherId) {
		analytics = analytics.filter(analyticsObject => {
			const teacherId = analyticsObject.teacherId
			if (!teacherId) {
				return false
			}
			return filters.teacherId === teacherId
		})
	}

	// Remove all analytics that do not match the selected class
	if (filters.className) {
		analytics = analytics.filter(analyticsObject => {
			const className = analyticsObject.className
			if (!className) {
				return false
			}
			return filters.className === className
		})
	}

	// Remove all analytics that do not match the selected time
	if (filters.time) {
		const startTime = filters.time.startTime
		const endTime = filters.time.endTime ?? new Date()

		analytics = analytics.filter(analyticsObject => {
			const createdTime = new Date(analyticsObject.createdTime)
			return startTime <= createdTime && createdTime <= endTime
		})
	}

	return analytics
}

/**
 * Gets the total number of students that were active in the analytics objects.
 */
export function getTotalStudentCount(analytics: Array<AnalyticsObject>): number {
	const uniqueStudentIds = new Set<string>()
	let unknownStudentCount = 0

	analytics.forEach(analyticsObject => {
		if (analyticsObject.analytics?.studentAnalytics) {
			Object.keys(analyticsObject.analytics?.studentAnalytics).forEach(studentId => {
				uniqueStudentIds.add(studentId)
			})
		} else {
			// Could not get unique students from the analytics object. Add `activeStudents` count, even though this could be recounting some students.
			unknownStudentCount += analyticsObject.analytics?.activeStudents || 0
		}
	})

	return uniqueStudentIds.size + unknownStudentCount
}
