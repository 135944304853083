import React from 'react'

import type { Node } from 'react'
import { Tooltip } from 'react-tooltip'

type Props = {
	content: string | React$Node,
	place: string,
	text: string | Node,
	id: string,
}

export default function TooltipItem(props: Props): React$Node {
	const tooltipId = 'Tooltip-' + props.id

	return (
		<React.Fragment>
			<div data-tooltip-id={tooltipId}>{props.text}</div>
			<Tooltip id={tooltipId} place={props.place}>
				{props.content}
			</Tooltip>
		</React.Fragment>
	)
}
