// @flow
import React, { forwardRef, useState, type AbstractComponent } from 'react'
import { Announcements } from './../Announcements'
import NavLinks, { AccountNavButton } from './NavLinks'
import { useUser } from '../../services/hooks'
import { config } from '../../config'
import { Link } from 'react-router-dom'
import MdClose from 'react-icons/lib/md/close'
import FaBars from 'react-icons/lib/fa/bars'
import classnames from 'classnames'
import { Banners } from '../Banners'

/**
 * The header for the app which displays the navigation links. Also includes the "HelpMenu" near the bottom of the page
 */
export const Header: AbstractComponent<{}> = forwardRef(function Header(props, ref): React$Node {
	const { user } = useUser()
	const signedIn = Boolean(user)
	const [expanded, setExpanded] = useState(false)

	return (
		<div className="sticky top-0 z-50" ref={ref}>
			<nav className="bg-gradient-to-br from-primary-500 to-primary-400">
				<div className="p-2 flex items-center justify-between mx-auto md:w-4/5 xl:w-3/4">
					<Link to="/">
						<img src={config.logos.main} alt="logo" width="200" />
					</Link>

					<div className="flex items-center">
						<div
							className={classnames(
								'fixed lg:static top-0 right-0 bottom-0 w-96 lg:w-fit z-50 bg-primary-750 lg:bg-transparent transition-transform lg:translate-x-0',
								!expanded && 'translate-x-full'
							)}
							tabIndex="-1"
							id="nav-bar-links">
							<div className="p-4 flex justify-between lg:hidden">
								<h5>
									<img src={config.logos.main} alt="logo" className="max-h-8" />
								</h5>
								<button
									className="text-primary-50"
									onClick={() => setExpanded(false)}
									aria-label="Close">
									<MdClose size="30" />
								</button>
							</div>
							<div className="px-4 lg:!px-0">
								<ul className="flex flex-col lg:flex-row lg:items-center">
									<NavLinks onSelect={() => setExpanded(false)} />
								</ul>
							</div>
						</div>

						{signedIn && (
							<>
								<div>
									<AccountNavButton />
								</div>
								<div className="pe-2 lg:p-0">
									<Announcements />
								</div>
							</>
						)}

						<button
							className="lg:hidden border p-2 py-1 rounded mr-2 hover:brightness-90 active:brightness-75"
							onClick={() => setExpanded(!expanded)}
							aria-controls="nav-bar-links">
							<FaBars size={20} />
						</button>
					</div>
				</div>
			</nav>
			<Banners />
		</div>
	)
})
