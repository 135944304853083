import React from 'react'
import Select from 'react-select'
import states from '../constants/states'
import { AppReactSelectClassNames, AppReactSelectTheme } from './inputs/styles'

/**
 * A controlled component that lets the user select a state from `states.js`. The `value` and `onChange` are the
 * state's abbreviation.
 */
export function StatePicker({
	value,
	onChange,
	...rest
}: {
	value: ?string,
	onChange: string => void,
}): React$Node {
	return (
		<Select
			{...rest}
			name="state"
			value={states.find(state => state.abbreviation === value)}
			onChange={state => onChange(state.abbreviation)}
			options={states}
			filterOption={(state, input) => {
				if (!input) {
					return true
				}
				const lowerCaseInput = input.toLowerCase()
				return (
					state.label.toLowerCase().startsWith(lowerCaseInput) ||
					state.data.name.toLowerCase().startsWith(lowerCaseInput)
				)
			}}
			getOptionLabel={state => state.abbreviation}
			getOptionValue={state => state.abbreviation}
			styles={{ control: currentStyle => ({ ...currentStyle, width: '80px' }) }}
			isSearchable
			theme={AppReactSelectTheme}
			classNames={AppReactSelectClassNames}
		/>
	)
}
