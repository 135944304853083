// @flow
import produce from 'immer'
import { OWNER_TYPES } from '../../models/QuestionGroup'
import type { User } from '../../store/types'
import type { QuestionGroup, SubjectType } from '../../models/QuestionGroup'
import type { ClientQuestion as Question } from '@mission.io/question-toolkit'

export const ACTIONS = {
	ADD_QUESTION: 'ADD_QUESTION',
	DELETE_QUESTION: 'DELETE_QUESTION',
	UPDATE_DETAIL: 'UPDATE_DETAIL',
	SET_PUBLIC: 'SET_PUBLIC',
	REPLACE_QUESTION: 'REPLACE_QUESTION',
	SET_OWNER: 'SET_OWNER',
}

type AddQuestion = {
	type: 'ADD_QUESTION',
	payload: Question,
}
type DeleteQuestion = {
	type: 'DELETE_QUESTION',
	payload: { index: number },
}
type ReplaceQuestion = {
	type: 'REPLACE_QUESTION',
	payload: { index: number, question: Question },
}

export type UpdateDetailPayload =
	| { type: 'subjects', value: SubjectType[] }
	| { type: 'grades', value: string[] }
	| { type: 'name', value: string }

type UpdateDetail = {
	type: 'UPDATE_DETAIL',
	payload: UpdateDetailPayload,
}
type SetPublic = {
	type: 'SET_PUBLIC',
	payload: boolean,
}
type SetOwner = {
	type: 'SET_OWNER',
	payload: { type: $Keys<typeof OWNER_TYPES>, user: User, isUserDistrictAdmin: boolean },
}

export type Action =
	| AddQuestion
	| DeleteQuestion
	| UpdateDetail
	| SetPublic
	| ReplaceQuestion
	| SetOwner

export default function reducer(state: QuestionGroup, action: Action): QuestionGroup {
	switch (action.type) {
		case ACTIONS.ADD_QUESTION: {
			return produce(state, draft => {
				const question = action.payload
				draft.questions.push(question)
			})
		}
		case ACTIONS.DELETE_QUESTION: {
			return produce(state, draft => {
				const index = action.payload.index
				if (index < draft.questions.length && index > -1) {
					draft.questions.splice(index, 1)
				}
			})
		}
		case ACTIONS.UPDATE_DETAIL: {
			return produce(state, draft => {
				const { type, value } = action.payload
				draft[type] = value
			})
		}
		case ACTIONS.SET_PUBLIC: {
			return produce(state, draft => {
				draft.public = action.payload
			})
		}
		case ACTIONS.REPLACE_QUESTION: {
			return produce(state, draft => {
				const index = action.payload.index
				const question = action.payload.question

				if (index < draft.questions.length && index > -1) {
					draft.questions[index] = { ...question, updated: true }
				}
			})
		}
		case ACTIONS.SET_OWNER: {
			return produce(state, draft => {
				const { type, user, isUserDistrictAdmin } = action.payload
				if (type === OWNER_TYPES.USER) {
					draft.owner = {
						type: OWNER_TYPES.USER,
						id: user._id,
					}
					return
				}
				if (isUserDistrictAdmin && type === OWNER_TYPES.DISTRICT) {
					draft.owner = {
						type: OWNER_TYPES.DISTRICT,
						id: 'THIS_ID_DOES_NOT_MATTER_IT_IS_HANDLED_BY_THE_SERVER',
					}
					return
				}
			})
		}

		default:
			throw new Error()
	}
}
