import React from 'react'
import { SCORES } from './constants'
import { ListItem, Line, ColorCoin } from './sharedComponents'
import classnames from 'classnames'

/**
 * A component that displays a legend to explain each level of proficiency a student can receive for analytics.
 * @returns {React$Node}
 */
export default function Legend({ className }: { className?: string }): React$Node {
	return (
		<div className={classnames('flex flex-col justify-between', className)}>
			<ProficiencyLegend className="mb-2" />
			<div className="bg-primary-800 p-2 rounded-lg">
				{/* TODO: Support National Averages
				<ListItem className="description">
					<Line dotted css="margin-right: var(--spacing1x-dont-use)" /> - National Average
				</ListItem> */}
				<ListItem>
					<Line className="mr-2" /> - Class Average
				</ListItem>
			</div>
		</div>
	)
}

/**
 * A legend describing each proficiency level.
 * @returns {React$Node}
 */
export function ProficiencyLegend(props: { className?: string }): React$Node {
	return (
		<div {...props}>
			{SCORES.map(({ level, description }) => (
				<ListItem key={level}>
					<ColorCoin level={level} /> {level} - {description}
				</ListItem>
			))}
		</div>
	)
}
