import { useMemo } from 'react'
import { useQuery, useQueryClient } from 'react-query'
import { getQuestionKey } from '../../../utility/helpers'
import { getQuestions, type DBQuestion } from '../../../models/Question'
import type { AnalyticsQuestionResponse, ClassStudentAnalytics } from '@mission.io/mission-toolkit'
import { useSpecificMissionContext, useSelectedMissionAnalytics } from '../SpecificMissionContext'
import { getAllDemoAnalyticsObjects } from '../demoAnalytics'
import { variantTypes } from '@mission.io/question-toolkit'
import { convertToMarkdownString, getMarkdownString } from '@mission.io/question-views'
import { useUser } from '../../../services/hooks'

type StudentAnswerLookup = { [studentId: string]: AnalyticsQuestionResponse[] }
export type QuestionColumn = {
	questionId: string,
	questionVersion: number,
	studentAnswerLookup: StudentAnswerLookup,
}

/**
 * useAnalyticsQuestionColumns - get the columns to display the questions in the analytics in the table, also
 *  returns the react query keys for getting the questions from the server.
 *
 * Must be used in a component that is a child of the SpecificMissionContext
 *
 * Note: answers which share the same requestId were asked at the same time. Such as question slots in `jr-controls`.
 *
 * Columns are created by the following rules:
 *  - answers with the same requestId, questionId, and question versions, are placed in the same column. (such as `jr-control`'s question slots)
 *  - any answer not placed by the above rule is placed into a column with other answers sharing the same questionId, and questionVersion, but not by the same student.
 *         If a student has answered the same question multiple times, each answer will be placed in a different column with the same questionId and questionVersion
 *         as the answer. If no such column exists, a new column is created. (this places boost questions for `jr-plus`)
 *  - all columns created by the above rules are then ordered such that the same questions will be grouped together.
 *
 *
 * @return {[QuestionColumn[], {[questionKey: string]: DBQuestion}, areQuestionsLoaded} -
 *             [
 *	              the question columns,
 *                a map of the questions from the server,
 *				  true if questions are fully loaded from the server - false otherwise
 *			   ]
 */
export function useAnalyticsQuestionColumns(): [
	QuestionColumn[],
	{ [questionKey: string]: DBQuestion },
	boolean
] {
	const { isDemo } = useSpecificMissionContext()
	const analytics = useSelectedMissionAnalytics()
	const { user } = useUser()
	const [questionColumns, neededQuestions] = useMemo(() => {
		if (!analytics) {
			return [[], {}]
		}

		const columns: Array<QuestionColumn> = []
		const referenceAnswers: {
			[referenceKey: string]: StudentAnswerLookup,
		} = {}
		const referenceIdRouter: {
			[referenceKey: string]: QuestionColumn,
		} = {}
		const neededQuestions: { [questionId: string]: Set<number> } = {}
		const studentAnalytics = analytics.studentAnalytics || {}
		Object.keys(studentAnalytics).forEach(studentId => {
			const studentAnswers = studentAnalytics[studentId].questionAnswers
			if (!studentAnswers) {
				return
			}
			studentAnswers.forEach((answer: AnalyticsQuestionResponse) => {
				neededQuestions[answer.questionId] ??= new Set()
				neededQuestions[answer.questionId].add(answer.questionVersion)
				const referenceKey = getReferenceKey(answer.questionId, answer.questionVersion)

				referenceAnswers[referenceKey] ??= {}
				referenceAnswers[referenceKey][studentId] ??= []
				referenceAnswers[referenceKey][studentId].push(answer)

				referenceIdRouter[referenceKey] ??= newQuestionColumn(
					answer.questionId,
					answer.questionVersion,
					referenceAnswers[referenceKey]
				)
			})
		})

		// place all columns with the same question and versions next to each other
		Object.keys(referenceIdRouter).forEach((referenceKey: string) => {
			columns.push(referenceIdRouter[referenceKey])
		})

		return [columns, neededQuestions]
	}, [analytics])

	const questionsQuery = useQuery(
		getQuestions(isDemo ? {} : neededQuestions, useQueryClient(), Boolean(user))
	)

	const questionMap: { [questionKey: string]: DBQuestion } = useMemo(() => {
		if (isDemo) {
			return getAllDemoAnalyticsObjects().demoQuestionMap
		}
		if (!questionsQuery.isSuccess || !questionsQuery.data) {
			return {}
		}
		const questionMap = {}
		questionsQuery.data.forEach(question => {
			questionMap[getQuestionKey(question._id, question.version)] = question
		})
		return questionMap
	}, [questionsQuery, isDemo])

	return [questionColumns, questionMap, questionsQuery.isLoading]
}
/**
 * useAnalyticsCulminatingMomentColumns - get the culminating moment question columns and culminating moment question map for the currently selected mission analytics
 *
 * @return {[QuestionColumn[], {[questionKey: string]: DBQuestion}, areQuestionsLoaded} -
 *             [
 *	              the question columns,
 *                a map of the questions,
 *			   ]
 */
export function useAnalyticsCulminatingMomentColumns(): [
	QuestionColumn[],
	{ [questionKey: string]: DBQuestion }
] {
	const analytics = useSelectedMissionAnalytics()

	return useMemo(() => {
		const culminatingMomentMap = {}
		const columnMap: { [culminatingMomentReferenceKey: string]: QuestionColumn } = {}
		const columns: QuestionColumn[] = []

		if (!analytics?.culminatingMomentQuestions) {
			return [columns, culminatingMomentMap]
		}

		analytics.culminatingMomentQuestions.forEach(question => {
			culminatingMomentMap[getQuestionKey(question.id, question.version)] = {
				...question,
				_id: question.id,
				type: variantTypes.MULTIPLE_CHOICE,
				phrase: convertToMarkdownString(question.phrase),
				choices: question.choices.map(choice => ({
					...choice,
					_id: choice.id,
					answer: choice.correct,
					text: convertToMarkdownString(choice.text),
				})),
			}
		})

		Object.keys(analytics.studentAnalytics ?? {}).forEach(studentId => {
			const culminatingMomentAnswers =
				analytics.studentAnalytics?.[studentId]?.culminatingMomentAnswers ?? []

			culminatingMomentAnswers.forEach(answer => {
				const columnKey = getReferenceKey(answer.questionId, answer.questionVersion)
				columnMap[columnKey] ??= newQuestionColumn(
					answer.questionId,
					answer.questionVersion
				)
				columnMap[columnKey].studentAnswerLookup[studentId] ??= []
				columnMap[columnKey].studentAnswerLookup[studentId].push(answer)
			})
		})

		Object.keys(columnMap).forEach(key => {
			columns.push(columnMap[key])
		})

		columns.forEach(column => {
			Object.keys(column.studentAnswerLookup).forEach(studentId => {
				column.studentAnswerLookup[studentId].sort(
					(a, b) => (a.answerNumber ?? Infinity) - (b.answerNumber ?? Infinity)
				)
			})
		})

		return [columns, culminatingMomentMap]
	}, [analytics])
}

/**
 * dropQuestionMarkdownPrefix - a helper function for removing the markdown prefix from a question string
 *
 * @param  {string} possiblyMarkdown - a string which may have the markdown prefix
 *
 * @returns string
 */
export function dropQuestionMarkdownPrefix(possiblyMarkdown: string): string {
	return getMarkdownString(possiblyMarkdown) ?? possiblyMarkdown
}

/**
 * useGameScores - get the point score overview [finalScore, averageStudentScore] from analytics
 *
 * @param {?ClassStudentAnalytics} classAnalytics - the analytics to get the scores from
 *
 * @return {[number, ?number]} - [
 *    finalScore, - the total amount of points gathered during the mission
 *    averageStudentScore, - the average number of points each student got during the mission, is `null` if can not be calculated
 *  ]
 */
export function useGameScores(classAnalytics: ?ClassStudentAnalytics): [number, ?number] {
	return useMemo(() => {
		if (!classAnalytics) {
			return [0, null]
		}

		let finalScore = sumPointObject(classAnalytics.extraCrewPoints)
		let studentsParticipated: number = 0
		const studentAnalytics = classAnalytics.studentAnalytics || {}
		Object.keys(studentAnalytics).forEach((studentId: string) => {
			const studentPoints = studentAnalytics[studentId].studentPoints

			if (studentPoints) {
				studentsParticipated += 1
			}

			finalScore += sumPointObject(studentPoints)
		})

		return [finalScore, studentsParticipated === 0 ? null : finalScore / studentsParticipated]
	}, [classAnalytics])
}

/**
 * newQuestionColumn - generates a question column tied to the given questionId and questionVersion
 *
 * @param {string} questionId - the id of the question the column uses
 * @param {number} questionVersion - the version of the question the column uses
 *
 * @return {QuestionColumn} - a new column
 */
function newQuestionColumn(
	questionId: string,
	questionVersion: number,
	studentAnswerLookup: StudentAnswerLookup = {}
): QuestionColumn {
	return {
		questionId,
		questionVersion,
		studentAnswerLookup,
	}
}

/**
 * getReferenceKey - generates a string unique to the questionId, questionVersion, and requestId
 *
 * @param {string} questionId - the id of the question
 * @param {string|number} questionVersion - the version of the question
 * @param {string} requestId - the requestId of the answer
 *
 * @return {string} - a string encoding the questionId, questionVersion, and requestId
 */
export function getReferenceKey(
	questionId: string,
	questionVersion: string | number,
	requestId: ?string
): string {
	return `${questionId}-${questionVersion}-${requestId || 'unavailable'}`
}

/**
 * sumPointObject - get the total number of points represented by a points object in the analytics
 *
 * @param {?{[category:string]:number}} pointsObject? - the point object to sum
 *
 * @return {number} - the total number of points in the points object, 0 if null or undefined
 */
function sumPointObject(pointsObject?: ?{ [category: string]: number }): number {
	if (!pointsObject) {
		return 0
	}

	let count = 0
	Object.keys(pointsObject).forEach((category: string) => {
		count += pointsObject[category]
	})

	return count
}
