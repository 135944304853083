import React from 'react'
import 'styled-components/macro'

import TrainingStage from './TrainingStage'
import { useQueryParams, StringParam } from 'use-query-params'

import type { LeafStageWithIsComplete } from '../../services/hooks/training'

type Props = {
	stages: $ReadOnlyArray<LeafStageWithIsComplete>,
	className?: string,
	trainingId: string,
}

/**
 * A list of all of the given `stages`. `stages` must only be the leaf nodes of the training graph.
 */
export default function StagesList({ stages, trainingId, className }: Props): React$Node {
	const [{ trainingStage, training }, setQuery] = useQueryParams({
		trainingStage: StringParam,
		training: StringParam,
	})

	return (
		<div className={className}>
			{stages.map(stage => {
				const isStageSelected = training === trainingId && trainingStage === stage.id

				return (
					<React.Fragment key={stage.id}>
						<TrainingStage
							stage={stage}
							expanded={isStageSelected}
							onClickExpander={() => {
								if (isStageSelected) {
									setQuery({ trainingStage: '', training: '' })
								} else {
									setQuery({ trainingStage: stage.id, training: trainingId })
								}
							}}
						/>
						<hr className="my-2" />
					</React.Fragment>
				)
			})}
		</div>
	)
}
