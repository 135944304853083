import React from 'react'
import { Helmet } from 'react-helmet'
import { useState } from 'react'
import styled from 'styled-components'
import Downloads from './Downloads'

// $FlowFixMe flow is not recognizing this file
import teacherIntroCaptions from '../../assets/captions/teacher_intro.vtt'

import Play from 'react-icons/lib/fa/play'
import { useShowFileInFileViewer } from '../../components/FileViewer/FileViewerContext'
import { PageTitleHeader } from '../../styles/sharedComponents'
import classnames from 'classnames'
import { config } from '../../config'

type VideoData = {
	src: string,
	poster: string,
	name: string,
	captions?: ?any,
}

const VIDEOS: VideoData[] = [
	{
		src:
			'https://resources-cdn.mission.io/public/teacher-resources/videos/Student+Kickoff+Video+-+Infini-D+Lab.mp4',
		poster:
			'https://resources-cdn.mission.io/public/teacher-resources/videos/Student+Kickoff+Thumbnail.png',
		name: 'Student Kickoff',
	},
	{
		src:
			'https://resources-cdn.mission.io/public/teacher-resources/videos/Teacher+Kickoff+Video+-+Infini-D+Lab.mp4',
		poster:
			'https://resources-cdn.mission.io/public/teacher-resources/videos/Teacher+Kickoff+Thumbnail.png',
		captions: teacherIntroCaptions,
		name: 'Teacher Kickoff',
	},
	{
		src:
			'https://resources-cdn.mission.io/public/teacher-resources/videos/Infini-D+Lab+Training+Video+(4th-12th+Grade).m4v',
		poster:
			'https://resources-cdn.mission.io/public/teacher-resources/videos/Infini-D+Lab+Training+Video+(4th-12th+grade)+Thumbnail.png',
		name: 'Controls Training',
	},
	{
		src:
			'https://resources-cdn.mission.io/public/teacher-resources/videos/Intro+to+the+Infini-D+Universe.mp4',
		poster:
			'https://resources-cdn.mission.io/public/teacher-resources/videos/Intro+to+the+Infini-D+Universe+Thumbnail.jpg',
		name: `${config.companyName.base} Universe`,
	},
]

const MAIN_VIDEO = VIDEOS[0]
const SECONDARY_VIDEOS = VIDEOS.slice(1, VIDEOS.length)

export default function Resources(): React$Node {
	const setFileToView = useShowFileInFileViewer()

	const playVideo = (videoData: VideoData) => {
		setFileToView({
			src: videoData.src,
			captions: videoData.captions,
			name: videoData.name,
			disallowDownload: true,
		})
	}
	const metaDescription = `From kickoff videos to printable nameplates, find everything you need to make your ${config.companyName.base} missions unforgettable.`

	return (
		<ResourcesWrapper className="Resources">
			<Helmet>
				<title>Teacher Resources: Enhance Your {config.companyName.base} Experience</title>
				<meta name="description" content={metaDescription} />
				<meta name="og:description" content={metaDescription} />
			</Helmet>
			<PageTitleHeader data-testid="resources-page">Resources</PageTitleHeader>
			<VideoContainer>
				<VideoOption
					videoData={MAIN_VIDEO}
					videoClassName="h-[350px]"
					onClick={() => playVideo(MAIN_VIDEO)}>
					<h3 className="w-full text-center text-3xl">{MAIN_VIDEO.name}</h3>
				</VideoOption>
			</VideoContainer>
			<VideoContainer>
				{SECONDARY_VIDEOS.map((videoData, index) => (
					<VideoOption
						key={videoData.src}
						className="mb-3"
						videoData={videoData}
						onClick={() => playVideo(SECONDARY_VIDEOS[index])}>
						<h5 className="text-center w-full pointer-events-none text-xl">
							{videoData.name}
						</h5>
					</VideoOption>
				))}
			</VideoContainer>
			<Downloads />
		</ResourcesWrapper>
	)
}

/**
 * VideoOption - a react component for a selectable video option
 *
 * @param  {Object} props - the react props
 * @param  {React$Node} props.children - the children to place withing the video container
 * @param  {boolean} props.selected - true if video is selected, false otherwise
 * @param  {Object} ...props - all other props are passed as props to the video html element
 *
 * @return {React$Node} - the react component
 */
function VideoOption({
	children,
	onClick,
	videoData,
	height,
	videoClassName,
	className,
}: {
	children?: React$Node,
	onClick: () => void,
	videoData: VideoData,
	height?: string,
	videoClassName?: string,
	className?: string,
}) {
	const [duration, setDuration] = useState(null)

	return (
		<div className={classnames('mb-8', className)}>
			<div className="relative">
				<SelectableVideo
					height={height || '175px'}
					src={videoData.src}
					poster={videoData.poster}
					preload="meta"
					onDurationChange={event => setDuration(formatDuration(event.target.duration))}
					className={classnames('h-[175px]', videoClassName)}
				/>
				{duration != null ? (
					<div className="absolute text-white right-2 bottom-2 bg-black/80 py-1 px-2 rounded text-sm pointer-events-none">
						{duration}
					</div>
				) : null}

				<div
					onClick={onClick}
					className="absolute text-2xl inset-0 flex justify-center items-center cursor-pointer text-[#000000a0] hover:text-[#000000f0]">
					<Play />
				</div>
			</div>
			{children}
		</div>
	)
}

/**
 * formatDuration - format the duration into a more human readable format `m:ss`
 *
 * @param  {number} duration - the duration in seconds
 *
 * @returns string - the formatted duration string
 */
function formatDuration(duration: number): string {
	let secondsString = String(Math.floor(duration % 60))
	if (secondsString.length < 2) {
		secondsString = `0${secondsString}`
	}
	return `${Math.floor(duration / 60)}:${secondsString}`
}

function VideoContainer(props) {
	return <div {...props} className="flex flex-wrap justify-around" />
}

const ResourcesWrapper = styled.div`
	${({ theme }) => `
		padding: 0 var(--spacing2x-dont-use);
	`}
`

const SelectableVideo = styled.video`
	border: 2px solid ${({ theme, selected }) => (selected ? theme.secondary : 'Transparent')};
	cursor: pointer;
`
