import OnboardingPopup from './OnboardingPopup'
import React from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { useUserClasses } from '../../services/hooks/classes'
import { useUnfinishedMissions } from '../../queryHooks/mission'
import type { User } from '../../store/types'
import type { ReduxStore } from '../../store/rootReducer'

export default function OnboardingPopupWrapper({ user }: { user: User }): React$Node {
	const classes = useUserClasses()
	const { data: unfinishedMissions } = useUnfinishedMissions()
	const shouldShowOnboardingPopup: boolean =
		useSelector(
			(state: ReduxStore) => Boolean(classes) && !user.onboarding.isOnboardingFinished
		) && Boolean(unfinishedMissions)
	return shouldShowOnboardingPopup ? (
		<OnboardingPopupDiv>
			<OnboardingPopup didUserStartMission={!!user.onboarding.didUserStartMission} />
		</OnboardingPopupDiv>
	) : null
}

const OnboardingPopupDiv = styled.div`
	position: fixed;
	z-index: 1;
	${({ theme }) => `
		right: var(--spacing3x-dont-use);
		bottom: var(--spacing4x-dont-use);
	`}
`
