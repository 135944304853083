// @flow
import React from 'react'
import { Prompt } from 'react-router-dom'
import { Button } from '@mission.io/styles'
import { useBeforeunload } from 'react-beforeunload'
import Filters from '../../../components/helpers/Filters/Filters'
import styled from 'styled-components/macro'

import type { UpdateDetailPayload } from '../questionGroupReducer'
import type { SubjectType } from '../../../models/QuestionGroup'
import type { SelectOption } from '../../analytics/analyticsHelpers'
import { getQuestionCategories } from '../../../store/categories'
import { useSelector } from 'react-redux'
import { Input } from '../../../components/inputs/Input'

export default function EditQuestionGroupDetails({
	onSave,
	updateValue,
	onDelete,
	values,
	creating,
	isQuestionFormDirty,
	className,
}: {
	onSave: () => void,
	updateValue: UpdateDetailPayload => void,
	onDelete?: () => void,
	values: { name: string, grades: string[], subjects: ?(SubjectType[]) },
	creating?: boolean,
	isQuestionFormDirty?: boolean,
	className?: string,
}): React$Node {
	const saveButtonEnabled = isQuestionFormDirty

	const blockNavigationMessage =
		'You have unsaved changes! Are you sure? Your unsaved changes will be lost.'
	// Blocks refreshing the page or pressing the back button when it will exit simulation creator, i.e. when the page would "unload"
	useBeforeunload(() => (saveButtonEnabled ? blockNavigationMessage : null))

	const { subjects, grades, name } = values
	const setGrades = (val: string[]) => {
		updateValue({ type: 'grades', value: val })
	}
	const setSubjects = (val: SubjectType[]) => {
		updateValue({ type: 'subjects', value: val })
	}
	const setName = (val: string) => {
		updateValue({ type: 'name', value: val })
	}

	const questionCategories = useSelector(getQuestionCategories)

	const categoriesValue = subjects ? subjects.map(s => ({ value: s.id, label: s.name })) : []
	const gradesValue = grades ? grades.map(g => ({ value: g, label: g })) : []

	return (
		<>
			<Prompt when={saveButtonEnabled} message={blockNavigationMessage} />
			<FormWrapper className={className}>
				<InputWrapper>
					<label htmlFor="name" className="text-sm mb-0">
						Name
					</label>

					<Input
						id="name"
						name="name"
						value={name}
						onChange={event => setName(event.target.value)}
					/>
				</InputWrapper>
				<Filters
					allowMultiCategory
					portalToBody
					filters={{
						grades: {
							label: 'Grades',
							id: 'create-grades',
							values: gradesValue,
							set: valueSetter => {
								let values: SelectOption[] =
									typeof valueSetter === 'function'
										? valueSetter(gradesValue)
										: valueSetter

								if (Array.isArray(values)) {
									const valuesArray: SelectOption[] = values
									setGrades(valuesArray ? valuesArray.map(v => v.value) : [])
								}
							},
						},
						category: {
							label: 'Subjects',
							id: 'create-category',
							values: categoriesValue,
							set: valueSetter => {
								let values: SelectOption[] =
									typeof valueSetter === 'function'
										? valueSetter(categoriesValue)
										: valueSetter
								if (Array.isArray(values)) {
									const valuesArray: SelectOption[] = values
									setSubjects(
										valuesArray?.length > 0
											? valuesArray.map((v: SelectOption) => ({
													id: v.value,
													name: v.label,
											  }))
											: []
									)
								}
							},
						},
					}}
					creating={creating || false}
					categories={questionCategories || []}
				/>
				<div css="grad-area: button-area;" className="justify-self-end">
					{onDelete && (
						<Button variant="danger" outline onClick={onDelete} css="margin-right: 8px">
							Delete
						</Button>
					)}
					<Button onClick={onSave}>{creating ? 'Create Question Group' : 'Save'}</Button>
				</div>
			</FormWrapper>
		</>
	)
}

const InputWrapper = styled.div`
	display: flex;
	flex-direction: column;
	grid-area: input-area;
`

const FormWrapper = styled.div`
	.category-wrapper {
		grid-area: category-area;
	}
	.grade-wrapper {
		grid-area: grade-area;
	}

	display: grid;
	gap: var(--spacing1x-dont-use);
	align-items: end;
	grid-template-areas: 'category-area grade-area input-area button-area';
	gap: var(--spacing1x-dont-use);
	width: 100%;

	@media (max-width: 1024px) {
		grid-template-areas: 'category-area' 'grade-area' 'input-area' 'button-area';
	}
`
