import React, { Component } from 'react'
import styled from 'styled-components/macro'
import { toast } from 'react-toastify'
import { Button } from '@mission.io/styles'
import TeamBox from './TeamBox'
import TeamLeadBox from './TeamLeadBox'
import { DragDropContext } from 'react-dnd'
import HTML5Backend from 'react-dnd-html5-backend'
import {
	normalizeTeams,
	checkStudentAssignments,
	getAssignedStudents,
	getRandomAssignmentsFromServer,
	juniorTeamsToTeams,
	areTeamsAcceptable,
} from '../RolesHelpers'
import StudentBox from './StudentsBox'

import type { Student_New } from '../../../../../../models/Student'
import type { ClassType } from '../../../../../../models/ClassType'
import type { Team } from '../RolesHelpers'
import type { JuniorTeam } from '../../../../../../store/types'
import type { StyledType } from 'global-types'

type Props = {
	_class: ClassType,
	teams: Team[],
	setTeamsAcceptable: (acceptable: boolean) => void,
	setTeams: (teams: ?(Team[])) => void,
	simulationId: string,
}

type State = {
	assigningRandomFromServer: boolean,
}

class ChooseStudentTeams extends Component<Props, State> {
	constructor(props: Props) {
		super(props)
		this.handleTeamSelect = this.handleTeamSelect.bind(this)
		this.moveStudent = this.moveStudent.bind(this)

		this.state = {
			assigningRandomFromServer: false,
		}
	}

	componentDidMount() {
		this.props.setTeamsAcceptable(checkStudentAssignments(this.props.teams, this.props._class))
	}

	componentDidUpdate(prevProps: Props) {
		if (
			getAssignedStudents(prevProps.teams).size !==
				getAssignedStudents(this.props.teams).size ||
			areTeamsAcceptable(prevProps.teams) !== areTeamsAcceptable(this.props.teams)
		) {
			this.props.setTeamsAcceptable(
				checkStudentAssignments(this.props.teams, this.props._class)
			)
		}
	}

	render(): React$Node {
		if (!this.props._class) return null
		const assignedStudents = getAssignedStudents(this.props.teams)

		const unassignedStudents = this.props._class.students.filter(
			(obj: Student_New) => !assignedStudents.has(obj._id)
		)

		return this.props.teams ? (
			<TeamContainer>
				<div className="text-2xl Header">Students</div>
				<StudentBox
					students={unassignedStudents}
					moveStudent={(student: Student_New) =>
						this.props.setTeams(this.removeStudentFromTeam(student))
					}
					css="grid-area: unassigned-students;"
					className="max-h-full overflow-auto"
				/>
				<TeamWrapper className="layout-row space-between">
					<div className="text-2xl">Teams</div>
					<Wrapper>
						<FunctionButton outline onClick={this.clear}>
							Clear
						</FunctionButton>
						<FunctionButton onClick={this.randomize}>Assign Random</FunctionButton>
					</Wrapper>
				</TeamWrapper>
				<div css="grid-area: teams;" className="max-h-full overflow-auto">
					{this.getTeamHtml()}
				</div>
			</TeamContainer>
		) : (
			<h3>Waiting for server response...</h3>
		)
	}

	getTeamHtml: () => React$Node[] = () => {
		const teams = this.props.teams
		let teamRows = []
		teams.forEach(team => {
			teamRows.push(
				<Container key={team.id}>
					<StyledHeader>
						<StyledImage src={team.icon} />
						<TeamHeader>
							{team.displayName}
							{team.isRequired ? ' (Required)' : ''}
						</TeamHeader>
					</StyledHeader>
					<Hr />
					<TeamGrid>
						<TeamLeadBox team={team} onSelect={this.handleTeamLeadSelect} />
						<TeamBox team={team} onSelect={this.handleTeamSelect} />
					</TeamGrid>
				</Container>
			)
		})
		return teamRows
	}

	clear: () => void = () => {
		this.props.setTeams(
			this.props.teams
				? this.props.teams.map((team: Team) => {
						return {
							...team,
							students: [],
							lead: null,
						}
				  })
				: []
		)
	}

	randomize: () => void = () => {
		const teams = this.props.teams
		const allStudentsInClass = this.props._class.students.map(student => student._id)
		const studentsNotCurrentlyAssigned = new Set(allStudentsInClass)

		getAssignedStudents(this.props.teams).forEach(studentId =>
			studentsNotCurrentlyAssigned.delete(studentId)
		)
		if (studentsNotCurrentlyAssigned.size === 0) {
			return
		}
		this.setState({ assigningRandomFromServer: true })
		getRandomAssignmentsFromServer(
			allStudentsInClass,
			normalizeTeams(teams),
			this.props.simulationId,
			(res: { randomizedTeams?: JuniorTeam[], error?: string }) => {
				if (res.error) {
					toast.error(
						'There was an error randomly assigning students. Please contact us if this problem persists.'
					)
				} else if (res.randomizedTeams) {
					this.props.setTeams(juniorTeamsToTeams(this.props._class, res.randomizedTeams))
				}
				this.setState({
					assigningRandomFromServer: false,
				})
			}
		)
	}

	moveStudent: (team: Team, student: Student_New) => Team[] = (
		team: Team,
		student: Student_New
	): Team[] => {
		const teams = this.props.teams
		let newTeam = {
			...team,
			students: team.students.filter(listStudent => listStudent._id !== student._id),
		}
		let teamIndex = teams.findIndex(listTeam => listTeam.id === team.id)
		let newTeams = [...teams]
		newTeams[teamIndex] = newTeam
		return newTeams
	}

	moveTeamLead: (team: Team, student: Student_New) => Team[] = (
		team: Team,
		student: Student_New
	): Team[] => {
		const teams = this.props.teams
		let newTeam = {
			...team,
			lead: null,
		}
		let teamIndex = teams.findIndex(listTeam => listTeam.id === team.id)
		let newTeams = [...teams]
		newTeams[teamIndex] = newTeam
		return newTeams
	}

	findStudentsTeamIndex: (studentId: string) => number = (studentId: string): number => {
		for (let index = 0; index < this.props.teams.length; index++) {
			const team = this.props.teams[index]
			if (team.lead && team.lead._id === studentId) {
				return index
			}
			const foundStudent = team.students.find(teamStudent => teamStudent._id === studentId)
			if (foundStudent) {
				return index
			}
		}
		return -1
	}

	removeStudentFromTeam: (student: Student_New) => Team[] = (student: Student_New): Team[] => {
		const teamIndex = this.findStudentsTeamIndex(student._id)
		if (teamIndex > -1) {
			const prevTeam = this.props.teams[teamIndex]
			if (prevTeam.lead && prevTeam.lead._id === student._id) {
				return this.moveTeamLead(prevTeam, student)
			} else {
				return this.moveStudent(prevTeam, student)
			}
		} else {
			return this.props.teams
		}
	}

	handleTeamLeadSelect: (team: Team, student: Student_New) => void = (
		team: Team,
		student: Student_New
	): void => {
		// remove student from previous team
		let teams = this.removeStudentFromTeam(student)

		// add student to team where dropped
		let teamIndex = teams.findIndex(listTeam => listTeam.id === team.id)
		let removedStudentTeam = teams[teamIndex]
		let newTeam = {
			...removedStudentTeam,
			lead: student,
		}
		let newTeams = [...teams]
		newTeams[teamIndex] = newTeam
		this.props.setTeams(newTeams)
	}

	handleTeamSelect: (team: Team, student: Student_New) => void = (
		team: Team,
		student: Student_New
	): void => {
		// remove student from previous team
		let teams = this.removeStudentFromTeam(student)

		// add student to team where dropped
		let teamIndex = teams.findIndex(listTeam => listTeam.id === team.id)
		let removedStudentTeam = teams[teamIndex]
		let newTeam = {
			...removedStudentTeam,
			students: [...removedStudentTeam.students, student],
		}
		let newTeams = [...teams]
		newTeams[teamIndex] = newTeam
		this.props.setTeams(newTeams)
	}
}

export default (DragDropContext(HTML5Backend)(ChooseStudentTeams): typeof ChooseStudentTeams)

const Wrapper = styled.div`
	width: 50%;
	display: flex;
	justify-content: flex-end;
	align-items: center;
`

const FunctionButton = styled(Button)`
	${({ theme }) => `
		margin: 0 var(--spacing1x-dont-use);
	`}
`

export const Container: StyledType<> = styled.div.attrs({
	className: 'bg-primary-750 text-primary-50 rounded-lg',
})`
	${({ theme }) => `
		padding: var(--spacing2x-dont-use);
		&:not(:last-child) {
			margin-bottom: var(--spacing1x-dont-use);
		}
	`}
`

const TeamContainer = styled.div.attrs({ className: 'gap-2' })`
	display: grid;
	grid-template:
		'head-left head-right' auto
		'unassigned-students teams' 1fr
		/ 30% 1fr;
	height: 100%;
	max-height: 70vh; /* can not use % due to being in a flex box */
	min-height: 100%;
	& > .Header {
		grid-area: head-left;
	}
`

const TeamHeader = styled.div`
	font-size: 1.15rem;
`

const TeamGrid = styled.div`
	display: grid;
	grid-template-columns: 25% 1fr;
	${({ theme }) => `
		grid-column-gap: var(--spacing1x-dont-use);
	`}
`

const Hr = styled.hr.attrs(() => ({
	className: 'border-primary-50 border-opacity-50 mt-2',
}))``

const TeamWrapper = styled.div`
	grid-area: head-right;
	z-index: 15;
`

const StyledImage = styled.img`
	height: 2em;
	width: 2em;
`

const StyledHeader = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: var(--spacing1x-dont-use);
`
