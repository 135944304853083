import React from 'react'
import { PAUSED, type MissionType } from './constants'
import { useUserName } from '../../services/hooks/user'
import MissionReviewTile from './MissionReviewTile'

import type { User } from '../../store/types'
import type { Mission } from '../../models'

type Props = {
	type: MissionType,
	missions: Mission[],
}

/**
 * Takes the owner on a mission and gets their name.
 */
function getMissionOwnerName(owner: User): ?string {
	const firstName = owner.firstName || ''
	const lastName = owner.lastName || ''
	if (!owner.firstName && !owner.lastName) {
		return null
	} else if (owner.firstName && owner.lastName) {
		return firstName + ' ' + lastName
	} else {
		return firstName + lastName
	}
}

export default function MissionReviewBox({ type, missions }: Props): React$Node {
	const userName = useUserName() || 'No Name'
	if (missions.length === 0) {
		return null
	}
	return (
		<div>
			<h4 className="text-2xl mb-2">{type === PAUSED ? 'Paused' : 'Prepped'} Missions</h4>
			<div className="flex gap-6 flex-col">
				{missions.map(mission => (
					<MissionReviewTile
						key={mission._id}
						mission={mission}
						teacherName={getMissionOwnerName(mission.owner) || userName}
						type={type}
					/>
				))}
			</div>
		</div>
	)
}
