import React from 'react'
import styled from 'styled-components/macro'

export const PRIMARY_LIGHTNESS_BRIGHT: number = 47
const PRIMARY_LIGHTNESS_DEFAULT = 37
export const PRIMARY_LIGHTNESS_DULL: number = 20
export const primaryColor = `hsl(179, 97%, var(--primary-lightness, ${PRIMARY_LIGHTNESS_DEFAULT}%))`

const StyledButton = styled.button`
	display: inline-flex;
	align-items: center;
	justify-content: center;
	background-color: ${primaryColor};
	color: white;
	border-radius: 4px;
	border: none;
	padding: var(--spacing-half-dont-use) var(--spacing4x-dont-use);
	transition: all 200ms;
	box-shadow: rgba(0, 0, 0, 0.05) 0 1px 3px 0;
	&:hover,
	&:focus {
		--primary-lightness: ${PRIMARY_LIGHTNESS_DEFAULT - 3}%;
		box-shadow: rgba(0, 0, 0, 0.3) 0 4px 12px;
		color: white;
	}
	&:active {
		--primary-lightness: ${PRIMARY_LIGHTNESS_DEFAULT - 5}%;
		box-shadow: rgba(0, 0, 0, 0.1) 0 2px 4px;
	}
`

/**
 * A button component to use in the automated demo
 */
export function Button(props: {}): React$Node {
	return (
		<StyledButton
			{...props}
			// Don't let the button take focus when it's clicked on
			onMouseDown={e => e.preventDefault()}
		/>
	)
}
