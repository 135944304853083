type ThemeColorName =
	| 'primary'
	| 'primary75'
	| 'primary50'
	| 'primary25'
	| 'danger'
	| 'dangerLight'
	| 'neutral0'
	| 'neutral5'
	| 'neutral10'
	| 'neutral20'
	| 'neutral30'
	| 'neutral40'
	| 'neutral50'
	| 'neutral60'
	| 'neutral70'
	| 'neutral80'
	| 'neutral90'

type InnerComponentName =
	| 'clearIndicator'
	| 'container'
	| 'control'
	| 'dropdownIndicator'
	| 'group'
	| 'groupHeading'
	| 'indicatorsContainer'
	| 'indicatorSeparator'
	| 'input'
	| 'loadingIndicator'
	| 'loadingMessage'
	| 'menu'
	| 'menuList'
	| 'menuPortal'
	| 'multiValue'
	| 'multiValueLabel'
	| 'multiValueRemove'
	| 'noOptionsMessage'
	| 'option'
	| 'placeholder'
	| 'singleValue'
	| 'valueContainer'

type ReactSelectThemeType = {
	colors: {
		[ThemeColorName]: string,
	},
}

type ReactSelectClassNamesType = {
	[InnerComponentName]: () => string,
}

/**
 * A constant used to override the default theme for the react-select component.
 * See https://react-select.com/styles#overriding-the-theme for more info on overriding  the theme.
 * Should be used in a Select component like so: <Select theme={AppReactSelectTheme} ... />
 */
export const AppReactSelectTheme: ReactSelectThemeType => ReactSelectThemeType = theme => ({
	...theme,
	colors: {
		...theme.colors,
		primary: 'var(--color-primary-500)',
		primary75: 'var(--color-primary-400)',
		primary50: 'var(--color-primary-300)',
		primary25: 'var(--color-primary-200)',
		danger: 'var(--danger)',
		neutral0: 'var(--color-primary-100)',
		neutral90: 'var(--color-primary-950)',
		neutral80: 'var(--color-primary-900)',
	},
})

/**
 * A constant used to override sub-component classnames for the react-select component.
 * See https://react-select.com/styles#inner-components for a list of all inner components that can be styled.
 * Should be used in a Select component like so: <Select classNames={AppReactSelectClassNames} ... />
 */
export const AppReactSelectClassNames: ReactSelectClassNamesType = {
	menu: () => 'text-primary-950',
	multiValue: () => '!rounded-md !bg-primary-500',
	multiValueLabel: () => '!text-primary-50',
	multiValueRemove: () =>
		'hover:cursor-pointer !rounded-r-md hover:brightness-90 active:brightness-75 hover:!bg-primary-500 hover:!text-white',
	control: () => '!min-h-8 text-sm !rounded-lg',
	valueContainer: () => '!py-0',
	indicatorsContainer: () => '!p-0',
	clearIndicator: () => '!py-0',
	dropdownIndicator: () => '!py-0 !px-1',
	input: () => '!py-0 !my-0',
}
