import React from 'react'
import { createRoot } from 'react-dom/client'

import App from './App'
import { unregister } from './registerServiceWorker'
import * as Sentry from '@sentry/browser'
import config from './config'

import './index.css'

if ((config.isProd || config.isStaging) && config.sentryUrl) {
	Sentry.init({
		dsn: config.sentryUrl,
		ignoreErrors: [
			'ResizeObserver loop limit exceeded', // ignore this error because it's benign
		],
	})
}

;(async () => {
	if (!config.isProd) {
		// only start the msw worker in dev
		const { setupMswWorker } = await import('./mocks/browser')
		await setupMswWorker().catch(console.error)
	}

	const element = document.getElementById('root')
	if (!element) {
		throw new Error('Could not find React root')
	}

	createRoot(element).render(<App />)
	if (config.isProd) {
		// Don't unregister service workers in dev because it will unregister the msw worker as well
		unregister()
	}
})()
