import React, { useState, useMemo } from 'react'
import 'styled-components/macro'
import { Tooltip } from 'react-tooltip'
import UserLicenseRow from '../UserLicenseRow'
// $FlowFixMe ReactComponent is not defined in the module namespace
import { ReactComponent as EmptyTeachersImage } from '../../../assets/EmptyTeachers.svg'
import useUser from './../../../services/hooks/user'
import { ROLE_ORDERING } from '../helpers'
import { rolesEnum } from '../../../store/types'
import MakeAdminModal from '../modals/MakeAdminModal'
import RevokeAdminModal from '../modals/RevokeAdminModal'

import type { UserOnLicense } from '../types'
import Selector from '../../../components/dashboardModules/LeaderBoardAnalytics/Selector'
import { Spinner, SpinnerHolder } from '../../../styles/sharedComponents'
import { ErrorLinkButton, ErrorOccurred } from '../../../components/Error'

/**
 * If an admin has no teachers, we want to show them a default picture and text.
 * This function gets it for us, and since it is outside the functional component,
 * it is not re-rendered all the time.
 */
function NoTeacherImage() {
	return (
		<div className="flex flex-col items-center mt-8">
			<EmptyTeachersImage title="Empty Teachers" />
			<p className="mb-0 text-2xl">Looks like you don’t have any teachers! </p>
			<p className="mb-0 text-2xl">Hit Invite Teachers to get started.</p>
		</div>
	)
}
/**
 * sortUsersByRole - sort the users by their roles (and sort by name secondary)
 *
 * @param  {UserOnLicense[]} users - the users to sort
 *
 * @returns UserOnLicense - the sorted users
 */
function sortUsersByRole(users: UserOnLicense[]): UserOnLicense[] {
	const quickLookup: { [userId: string]: number } = {}
	users.forEach((user: UserOnLicense) => {
		quickLookup[user.id] = Infinity
		user.roles.forEach(({ role, verified }) => {
			if (!verified) {
				return
			}
			quickLookup[user.id] = Math.min(quickLookup[user.id], ROLE_ORDERING[role] ?? Infinity)
		})
	})
	return users.sort((a: UserOnLicense, b: UserOnLicense) => {
		if (quickLookup[a.id] !== quickLookup[b.id]) {
			return quickLookup[a.id] - quickLookup[b.id]
		}
		return a.name.localeCompare(b.name)
	})
}

const NO_SCHOOL_ID = 'NO_SCHOOL_ID'

const AVAILABLE_ROLES = { ADMIN: 'ADMIN', TEACHER: 'TEACHER' }
const AVAILABLE_ROLES_LIST = Object.keys(AVAILABLE_ROLES)

/**
 * Shows all the users on a license.  If there are none,
 * show a No Teachers Image.
 */
export default function MembersPage({
	licenseUsers,
	otherUsersInOrganization,
}: {
	licenseUsers: UserOnLicense[],
	otherUsersInOrganization: UserOnLicense[],
}): React$Node {
	const {
		user,
		isLoading: isLoadingCurrentUser,
		isError: errorLoadingCurrentUser,
		refetch: refetchCurrentUser,
	} = useUser()
	const schools = getSchools(licenseUsers, otherUsersInOrganization)

	const userLookup = useMemo(() => {
		const lookup = {}
		licenseUsers.forEach(user => (lookup[user.id] = user))
		otherUsersInOrganization.forEach(user => (lookup[user.id] = user))
		return lookup
	}, [licenseUsers, otherUsersInOrganization])

	const [showMakeAdminModal, setShowMakeAdminModal] = useState(false)
	const [showMakeTeacherModal, setShowMakeTeacherModal] = useState(false)
	const [popoverUserId, setPopoverUserId] = useState(null)
	const popoverUser = popoverUserId ? userLookup[popoverUserId] : null
	const popoverUserIsLicenseAdmin = popoverUser?.roles.some(
		({ role, verified }) => verified && role === rolesEnum.LICENSE_ADMIN
	)

	const closeTooltipFormForUserId = (userId: ?string) => {
		if (userId !== popoverUserId) {
			return
		}
		setPopoverUserId(null)
		setShowMakeTeacherModal(false)
		setShowMakeAdminModal(false)
	}

	if (isLoadingCurrentUser) {
		return (
			<SpinnerHolder>
				<Spinner className="spinner-border" role="status">
					<span className="only-visible-to-screen-readers">Loading...</span>
				</Spinner>
			</SpinnerHolder>
		)
	}

	if (errorLoadingCurrentUser) {
		return (
			<ErrorOccurred>
				Failed to load users
				<ErrorLinkButton
					onClick={() => {
						refetchCurrentUser()
					}}>
					Retry
				</ErrorLinkButton>
			</ErrorOccurred>
		)
	}

	return (
		<>
			{licenseUsers.length === 0 ? (
				<NoTeacherImage />
			) : (
				schools.map(school => {
					return (
						<div
							key={school.id}
							css={`
								box-shadow: 0 2px 4px rgba(0, 0, 0, 0.4);
								margin-top: var(--spacing2x-dont-use);
								padding: var(--spacing2x-dont-use);
							`}
							className="bg-primary-750 rounded-lg">
							<h3 className="text-3xl">{school.name}</h3>
							<div>
								{sortUsersByRole(school.users).map(licenseUser => (
									<UserLicenseRow
										key={licenseUser.id}
										user={licenseUser}
										disabled={licenseUser.id === user?._id}
										onClickEdit={() => {
											if (popoverUserId === licenseUser.id) {
												closeTooltipFormForUserId(licenseUser.id)
												return
											}
											setPopoverUserId(licenseUser.id)
										}}
									/>
								))}
							</div>
							{school.nonLicenseUsers.length > 0 && (
								<>
									<h4 css="padding-top: var(--spacing1x-dont-use);">
										Not on License
									</h4>
									<div>
										{sortUsersByRole(school.nonLicenseUsers).map(user => (
											<UserLicenseRow
												key={user.id}
												user={user}
												notOnLicense
											/>
										))}
									</div>
								</>
							)}
						</div>
					)
				})
			)}
			{popoverUser && (
				<>
					<Tooltip
						place="left"
						isOpen={!!popoverUserId}
						anchorSelect={`#popover-target-${String(popoverUserId)}`}
						style={{
							pointerEvents: 'all', // have to use style for this to work with the testing library
						}}>
						<Selector
							selected={AVAILABLE_ROLES_LIST.findIndex(
								element =>
									element ===
									(popoverUserIsLicenseAdmin
										? AVAILABLE_ROLES.ADMIN
										: AVAILABLE_ROLES.TEACHER)
							)}
							onSelect={index => {
								let selectedRole = AVAILABLE_ROLES_LIST[index]

								if (
									selectedRole === AVAILABLE_ROLES.ADMIN &&
									!popoverUserIsLicenseAdmin
								) {
									// switching to admin
									setShowMakeAdminModal(true)
									return
								}

								if (
									selectedRole === AVAILABLE_ROLES.TEACHER &&
									popoverUserIsLicenseAdmin
								) {
									// switching to teacher
									setShowMakeTeacherModal(true)
									return
								}

								// no change
								closeTooltipFormForUserId(popoverUserId)
							}}
							options={AVAILABLE_ROLES_LIST}
						/>
					</Tooltip>
					<MakeAdminModal
						isOpen={showMakeAdminModal}
						user={popoverUser}
						onRequestClose={() => {
							setShowMakeAdminModal(false)
							closeTooltipFormForUserId(popoverUserId)
						}}
					/>
					<RevokeAdminModal
						isOpen={showMakeTeacherModal}
						user={popoverUser}
						onRequestClose={() => {
							setShowMakeTeacherModal(false)
							closeTooltipFormForUserId(popoverUserId)
						}}
					/>
				</>
			)}
		</>
	)
}

/**
 * Groups the users by their school and returns an array of those schools sorted by name. Any users with no school
 * are added to a "No School" group school which will be at the beginning of the list
 */
function getSchools(
	users: UserOnLicense[],
	otherUsersInOrganization: UserOnLicense[]
): Array<{ id: string, name: string, users: UserOnLicense[], nonLicenseUsers: UserOnLicense[] }> {
	const schools = {}
	/**
	 * populate `schools` with the users in `userList`.
	 * @param userList The users to add to `schools`
	 * @param onLicense Whether the users in `userList` are on the license or not
	 */
	function addUsers(userList: UserOnLicense[], onLicense: boolean) {
		userList.forEach(user => {
			const { id: schoolId, name: schoolName } = user.school || { id: NO_SCHOOL_ID }
			if (!schools[schoolId]) {
				schools[schoolId] = {
					id: schoolId,
					name: schoolId === NO_SCHOOL_ID ? 'No School' : schoolName,
					users: [],
					nonLicenseUsers: [],
				}
			}
			if (onLicense) {
				schools[schoolId].users.push(user)
			} else {
				schools[schoolId].nonLicenseUsers.push(user)
			}
		})
	}
	addUsers(users, true)
	addUsers(otherUsersInOrganization, false)
	return Object.keys(schools)
		.sort((a, b) =>
			a === NO_SCHOOL_ID
				? 1
				: b === NO_SCHOOL_ID
				? -1
				: schools[a].name.localeCompare(schools[b].name)
		)
		.map(schoolId => schools[schoolId])
}
