// @flow
import React from 'react'
import { Input } from '../../inputs/Input'
import classnames from 'classnames'

/**
 * It is a search input styled based off of our custom Input.
 * @param {string} setNameFilter
 * @param {(string) => mixed} nameFilter
 * @param {string} className
 * @returns
 */
export function InputSearch({
	setNameFilter,
	nameFilter,
	className = '',
}: {
	setNameFilter: string => mixed,
	nameFilter: string,
	className?: string,
}): React$Node {
	return (
		<div className={classnames(className, 'flex flex-col flex-1')}>
			<label htmlFor="search-input" className="text-sm m-0">
				Search
			</label>
			<Input
				id="search-input"
				type="search"
				onChange={e => setNameFilter(e.target.value)}
				placeholder="Search..."
				value={nameFilter}
			/>
		</div>
	)
}
