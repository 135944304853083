import React, { useCallback, useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import FaAngleDown from 'react-icons/lib/fa/angle-down'
import OnboardingItem from './OnboardingItem'
import OnboardingCollapsedPopup from './OnboardingCollapsedPopup'
import OnboardingModal from './OnboardingModal'
// $FlowFixMe ReactComponent is not defined in the module namespace
import { ReactComponent as PlayButton } from '../../assets/PlayButton.svg'
import { useUserClasses } from '../../services/hooks/classes'
import NetworkCommunicator from '../../services/NetworkCommunicator'
import {
	SUITABLE_ONBOARDING_ROUTES,
	ONBOARDING_ITEMS,
	ONBOARDING_FINISHED_MODAL,
} from '../../constants'
import config from '../../config'
import { useUnfinishedMissions } from '../../queryHooks/mission'
import classnames from 'classnames'

const onboardingItems = [
	{
		finished: false,
		label: ONBOARDING_ITEMS.CLASS,
	},
	{
		finished: false,
		label: ONBOARDING_ITEMS.PREP_MISSION,
	},
	{
		finished: false,
		label: ONBOARDING_ITEMS.RUN_MISSION,
	},
]

type Props = {
	didUserStartMission?: boolean,
}

/**
 * A popup menu component. Can be in two different views: expanded and collapsed, based on `isCollapsed` variable
 * When 'currentModal` variable !== `null` shows us a modal window with appropriate onboarding advice
 * @param { string } userId uses for setting onboarding complete status
 */
export default function OnboardingPopup({ didUserStartMission }: Props): React$Node {
	const history = useHistory()
	const [isCollapsed, setIsCollapsed] = useState(false)
	const [congratModal, setCongratModal] = useState(ONBOARDING_FINISHED_MODAL)
	const [onboardingSteps, setOnboardingSteps] = useState(onboardingItems)
	const { data: classes } = useUserClasses()
	const totalClasses: number = classes?.length ?? 0
	const { data: unfinishedMissions } = useUnfinishedMissions()

	/**
	 * useEffect below runs @function updateItemFinishedMark every time classes changed
	 * Sets `classes` checkmarks to `true` when classes.length more than 0, otherwise - `false
	 */
	useEffect(() => {
		if (totalClasses > 0) updateItemFinishedMark(ONBOARDING_ITEMS.CLASS, true)
		else updateItemFinishedMark(ONBOARDING_ITEMS.CLASS, false)
	}, [totalClasses])

	/**
	 * useEffect below runs @function updateItemFinishedMark every time unfinishedMissionIsPrepped or didUserStartMission changes
	 * Sets `Prep a mission` check mark to `true` when an unfinished mission is prepped
	 * or the user has started a mission, otherwise sets the check mark to false
	 */
	useEffect(() => {
		if (!unfinishedMissions) {
			return
		}
		if (Object.keys(unfinishedMissions).length || didUserStartMission) {
			updateItemFinishedMark(ONBOARDING_ITEMS.PREP_MISSION, true)
		} else {
			updateItemFinishedMark(ONBOARDING_ITEMS.PREP_MISSION, false)
		}
	}, [unfinishedMissions, didUserStartMission])

	/**
	 * useEffect below runs @function updateItemFinishedMark every time didUserStartMission changes
	 * Sets `run mission` check mark equal to `didUserStartMission`
	 */
	useEffect(() => {
		if (didUserStartMission) {
			updateItemFinishedMark(ONBOARDING_ITEMS.RUN_MISSION, true)
		} else {
			updateItemFinishedMark(ONBOARDING_ITEMS.RUN_MISSION, false)
		}
	}, [didUserStartMission])

	/**
	 * @function updateItemFinishedMark used for update popup item finished mark
	 * @param { string } itemLabel label of the onboarding item which should be updated
	 * @param { boolean } isFinished boolean variable showing whether finished current onboarding item
	 */
	const updateItemFinishedMark = (itemLabel: string, isFinished: boolean) => {
		setOnboardingSteps(state => {
			const onboardingStepsCopy = [
				...state.map(step => {
					return { ...step }
				}),
			]
			const itemIndex = onboardingStepsCopy.map(item => item.label).indexOf(itemLabel)

			onboardingStepsCopy[itemIndex].finished = isFinished

			return onboardingStepsCopy
		})
	}

	/**
	 * @function togglePopupView used for collapsing/expanding popup menu
	 */
	const togglePopupView = useCallback(() => {
		setIsCollapsed(!isCollapsed)
	}, [isCollapsed])

	/**
	 * @function handlePopupItemClick used to switch user to the appropriate page and then show modal window
	 * Shows modal window with advice only when the selected item hasn't finished
	 * @param { string } label label of the selected item. Helps us to find an appropriate modal window
	 */
	const handlePopupItemClick = useCallback(
		(label: string) => {
			const redirectTo = SUITABLE_ONBOARDING_ROUTES[label]
			if (!redirectTo) {
				return
			}
			history.push(redirectTo)
		},
		[history]
	)

	/**
	 * Shows congratulations onboarding modal when all items have already finished
	 */
	if (onboardingSteps.every(item => item.finished)) {
		const handleCloseFinishModal = onClose => {
			NetworkCommunicator.PUT(`/api/users/set-onboarding-item`, {
				host: config.missionsAPIURL,
				body: { onboardingItem: 'isComplete' },
			}).then(({ user }) => {
				if (onClose === 'redirect') history.push('/analytics')
				setCongratModal(null)
			})
		}

		return (
			<>
				{congratModal ? (
					<OnboardingModal isOpen={true} onCloseCallback={handleCloseFinishModal} />
				) : null}
			</>
		)
	}

	return (
		<div
			className={classnames(
				'rounded overflow-hidden bg-white min-w-[292px] shadow transition-[height] ease-out duration-200',
				isCollapsed ? 'h-[82px]' : 'h-[292px]'
			)}>
			{!isCollapsed ? (
				<>
					<OnboardingPopupHeader>
						<OnboardingPopupTitleWrapper>
							<span className="font-semibold">
								Welcome to {config.companyName.base}
							</span>
							<span>Lets get started with the following</span>
						</OnboardingPopupTitleWrapper>
						<OnboardingControlWrapper onClick={togglePopupView}>
							<FaAngleDown title="arrow-down" className="text-2xl" />
						</OnboardingControlWrapper>
					</OnboardingPopupHeader>
					<div>
						<OnboardingPopupWatchIntro
							onClick={() => handlePopupItemClick(ONBOARDING_ITEMS.INTRO)}>
							<span className="text-sm font-semibold">{ONBOARDING_ITEMS.INTRO}</span>
							<PlayButton />
						</OnboardingPopupWatchIntro>
						<OnboardingItemsContainer>
							{onboardingSteps.map(item => (
								<OnboardingItem
									key={item.label}
									onboardingItem={item}
									onClick={handlePopupItemClick}
								/>
							))}
						</OnboardingItemsContainer>
					</div>
				</>
			) : (
				<OnboardingCollapsedPopup
					togglePopupView={togglePopupView}
					onboardingItems={onboardingSteps}
				/>
			)}
		</div>
	)
}

const OnboardingPopupHeader = styled.div`
	display: flex;
	${({ theme }) => `
    padding: var(--spacing2x-dont-use)
	`}
`

const OnboardingPopupTitleWrapper = styled.span`
	display: flex;
	flex-direction: column;
`

const OnboardingControlWrapper = styled.div`
	> svg {
		${({ theme }) => `
      color: ${theme.neutral};
      &:hover {
        color: ${theme.neutralDark};
        cursor: pointer;
      }
    `}
	}
`

const OnboardingPopupWatchIntro = styled.div`
	display: flex;
	justify-content: space-between;
	${({ theme }) => `
    padding: var(--spacing1x-dont-use) var(--spacing2x-dont-use);
    background-color: ${theme.neutralLight};
    > svg {
      width: var(--spacing3x-dont-use);
      height: var(--spacing3x-dont-use);
    }
    &:hover {
      background-color: ${theme.neutralLight};
      cursor: pointer;
    }
  `}
`

const OnboardingItemsContainer = styled.ul`
	${({ theme }) => `
    padding: var(--spacing1x-dont-use) var(--spacing2x-dont-use) var(--spacing2x-dont-use);
  `}
`
