import React, { useState } from 'react'
import { useSimulationPermissions } from '../services/hooks/permissions'
import { LockableButton } from './helpers/Lock'
import UnlockDirectionModal from '../routes/mission/prep/UnlockDirectionModal'
import { useIsDoingWelcomeTour } from './UserGuide/userGuideHelpers'
import config from '../config'

import type { Mission } from '../models'

/**
 * A button that will start the provided mission. Can open other modals to get more user input if
 * necessary. Provides all functionality for disallowing a mission to be run if the underlying
 * simulation is not allowed to be run by the user.
 * @param {Mission} mission The mission to start
 * @param {string} children The contents of the button
 */
export function StartMissionButton({
	mission,
	children,
	...passThroughProps
}: {|
	mission: Mission,
	children: string,
	variant?: 'primary' | 'secondary',
	outline?: boolean,
|}): React$Node {
	const [showUnlockModal, setShowUnlockModal] = useState(false)
	const simulationPermissions = useSimulationPermissions(mission.simulationId)
	const withTour = useIsDoingWelcomeTour({ onMissionController: true })

	const canUserRunMission = simulationPermissions?.userCanRun || false

	return (
		<>
			<LockableButton
				onUnlockClick={`${config.missionControllerUrl}/control/${mission.code}${
					withTour ? '?trigger-tour=WELCOME' : ''
				}`}
				onLockClick={() => setShowUnlockModal(true)}
				isLocked={!canUserRunMission}
				greyOut={!canUserRunMission}
				isUnlockedDueToPromotion={
					Boolean(simulationPermissions?.userCanOnlyRunDueToPromotion) ?? false
				}
				{...passThroughProps}>
				{children}
			</LockableButton>
			{showUnlockModal && (
				<UnlockDirectionModal
					simulationId={mission.simulationId}
					onRequestClose={() => setShowUnlockModal(false)}
				/>
			)}
		</>
	)
}
