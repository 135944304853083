import type { QuestionGroup } from '../models/QuestionGroup'
import type { ClassType } from '../models/ClassType'
import type { Student_New } from '../models/Student'
import { OWNER_TYPES } from '../models/QuestionGroup'
import type { AnalyticsObject } from '../models/Analytics'
import type { PermissionsObject, User } from '../store/types'

import type { ClientQuestion } from '@mission.io/question-toolkit'
import type { ClassStudentAnalytics } from '@mission.io/mission-toolkit'
import {
	STUDENT_ANALYTICS_0,
	STUDENT_ANALYTICS_1,
	STUDENT_ANALYTICS_2,
	STUDENT_ANALYTICS_3,
	STUDENT_ANALYTICS_4,
	STUDENT_ANALYTICS_5,
	STUDENT_ANALYTICS_6,
	STUDENT_ANALYTICS_7,
	STUDENT_ANALYTICS_8,
	STUDENT_ANALYTICS_9,
	STUDENT_ANALYTICS_10,
	STUDENT_ANALYTICS_11,
	STUDENT_ANALYTICS_12,
	STUDENT_ANALYTICS_13,
	STUDENT_ANALYTICS_14,
	STUDENT_ANALYTICS_15,
	STUDENT_ANALYTICS_16,
	STUDENT_ANALYTICS_17,
	STUDENT_ANALYTICS_18,
	STUDENT_ANALYTICS_19,
	STUDENT_ANALYTICS_20,
} from './exampleStudentAnalytics'
import type { LeaderBoardAnalytics } from '../components/dashboardModules/LeaderBoardAnalytics/types'

const PAID_LICENSE_SIMULATION_GROUP_ID = '620add0d7ffe3d9ca5368875'
export const EXAMPLE_PERMISSIONS: PermissionsObject = {
	canRunOnSiteMission: true,
	isFreeAccount: false,
	usingGracePeriod: false,
	simulationGroups: [PAID_LICENSE_SIMULATION_GROUP_ID],
	baseSimulationGroups: [PAID_LICENSE_SIMULATION_GROUP_ID],
	promotionSimulationGroups: [],
	expiredPromotionSimulationGroups: [],
	promotions: [],
}

export const EXAMPLE_FAVORITE_QUESTION_GROUP_ID = '650c70380e0c73001ddafc76'

export const EXAMPLE_USER_ID = 'EXAMPLE_USER_ID'
const EXAMPLE_FIRST_NAME = 'Mission.io'
const EXAMPLE_LAST_NAME = 'Teacher'

const EXAMPLE_STUDENT_0 = 'EXAMPLE_STUDENT_0'
const EXAMPLE_STUDENT_1 = 'EXAMPLE_STUDENT_1'
const EXAMPLE_STUDENT_2 = 'EXAMPLE_STUDENT_2'
const EXAMPLE_STUDENT_3 = 'EXAMPLE_STUDENT_3'
const EXAMPLE_STUDENT_4 = 'EXAMPLE_STUDENT_4'
const EXAMPLE_STUDENT_5 = 'EXAMPLE_STUDENT_5'
const EXAMPLE_STUDENT_6 = 'EXAMPLE_STUDENT_6'
const EXAMPLE_STUDENT_7 = 'EXAMPLE_STUDENT_7'
const EXAMPLE_STUDENT_8 = 'EXAMPLE_STUDENT_8'
const EXAMPLE_STUDENT_9 = 'EXAMPLE_STUDENT_9'
const EXAMPLE_STUDENT_10 = 'EXAMPLE_STUDENT_10'
const EXAMPLE_STUDENT_11 = 'EXAMPLE_STUDENT_11'
const EXAMPLE_STUDENT_12 = 'EXAMPLE_STUDENT_12'
const EXAMPLE_STUDENT_13 = 'EXAMPLE_STUDENT_13'
const EXAMPLE_STUDENT_14 = 'EXAMPLE_STUDENT_14'
const EXAMPLE_STUDENT_15 = 'EXAMPLE_STUDENT_15'
const EXAMPLE_STUDENT_16 = 'EXAMPLE_STUDENT_16'
const EXAMPLE_STUDENT_17 = 'EXAMPLE_STUDENT_17'
const EXAMPLE_STUDENT_18 = 'EXAMPLE_STUDENT_18'
const EXAMPLE_STUDENT_19 = 'EXAMPLE_STUDENT_19'
const EXAMPLE_STUDENT_20 = 'EXAMPLE_STUDENT_20'
const EXAMPLE_STUDENT_21 = 'EXAMPLE_STUDENT_21'
const EXAMPLE_STUDENT_22 = 'EXAMPLE_STUDENT_22'
const EXAMPLE_STUDENT_23 = 'EXAMPLE_STUDENT_23'
const EXAMPLE_STUDENT_24 = 'EXAMPLE_STUDENT_24'
const EXAMPLE_STUDENT_25 = 'EXAMPLE_STUDENT_25'
const EXAMPLE_STUDENT_26 = 'EXAMPLE_STUDENT_26'

export const ID_TO_NAME_MAP = {
	EXAMPLE_STUDENT_0: { firstName: 'Luke', lastName: 'Skywalker' },
	EXAMPLE_STUDENT_1: { firstName: 'Leia', lastName: 'Organa' },
	EXAMPLE_STUDENT_2: { firstName: 'Han', lastName: 'Solo' },
	EXAMPLE_STUDENT_3: { firstName: 'James', lastName: 'Kirk' },
	EXAMPLE_STUDENT_4: { firstName: 'Spock', lastName: 'Vulcan' },
	EXAMPLE_STUDENT_5: { firstName: 'Jean-Luc', lastName: 'Picard' },
	EXAMPLE_STUDENT_6: { firstName: 'Darth', lastName: 'Vader' },
	EXAMPLE_STUDENT_7: { firstName: 'Ender', lastName: 'Wiggin' },
	EXAMPLE_STUDENT_8: { firstName: 'Buzz', lastName: 'Lightyear' },
	EXAMPLE_STUDENT_9: { firstName: 'Neil', lastName: 'Armstrong' },
	EXAMPLE_STUDENT_10: { firstName: 'Buzz', lastName: 'Aldrin' },
	EXAMPLE_STUDENT_11: { firstName: 'Yuri', lastName: 'Gagarin' },
	EXAMPLE_STUDENT_12: { firstName: 'Katherine', lastName: 'Johnson' },
	EXAMPLE_STUDENT_13: { firstName: 'Dorothy', lastName: 'Vaughan' },
	EXAMPLE_STUDENT_14: { firstName: 'Mary', lastName: 'Jackson' },
	EXAMPLE_STUDENT_15: { firstName: 'Alan', lastName: 'Shepard' },
	EXAMPLE_STUDENT_16: { firstName: 'John', lastName: 'Glenn' },
	EXAMPLE_STUDENT_17: { firstName: 'Gus', lastName: 'Grissom' },
	EXAMPLE_STUDENT_18: { firstName: 'Sally', lastName: 'Ride' },
	EXAMPLE_STUDENT_19: { firstName: 'Mae', lastName: 'Jemison' },
	EXAMPLE_STUDENT_20: { firstName: 'Rey', lastName: 'Palpatine' },
	EXAMPLE_STUDENT_21: { firstName: 'Leonard', lastName: 'McCoy' },
	EXAMPLE_STUDENT_22: { firstName: 'Kathryn', lastName: 'Janeway' },
	EXAMPLE_STUDENT_23: { firstName: 'Mazer', lastName: 'Rackham' },
	EXAMPLE_STUDENT_24: { firstName: 'Wall', lastName: 'E' },
	EXAMPLE_STUDENT_25: { firstName: 'Peter', lastName: 'Quill' },
	EXAMPLE_STUDENT_26: { firstName: 'Petra', lastName: 'Arkanian' },
}

const EXAMPLE_CLASS_STUDENTS: Student_New[] = [
	{
		_id: EXAMPLE_STUDENT_0,
		firstName: ID_TO_NAME_MAP.EXAMPLE_STUDENT_0.firstName,
		lastName: ID_TO_NAME_MAP.EXAMPLE_STUDENT_0.lastName,
		linkedAccounts: [],
	},
	{
		_id: EXAMPLE_STUDENT_1,
		firstName: ID_TO_NAME_MAP.EXAMPLE_STUDENT_1.firstName,
		lastName: ID_TO_NAME_MAP.EXAMPLE_STUDENT_1.lastName,
		linkedAccounts: [],
	},
	{
		_id: EXAMPLE_STUDENT_2,
		firstName: ID_TO_NAME_MAP.EXAMPLE_STUDENT_2.firstName,
		lastName: ID_TO_NAME_MAP.EXAMPLE_STUDENT_2.lastName,
		linkedAccounts: [],
	},
	{
		_id: EXAMPLE_STUDENT_3,
		firstName: ID_TO_NAME_MAP.EXAMPLE_STUDENT_3.firstName,
		lastName: ID_TO_NAME_MAP.EXAMPLE_STUDENT_3.lastName,
		linkedAccounts: [],
	},
	{
		_id: EXAMPLE_STUDENT_4,
		firstName: ID_TO_NAME_MAP.EXAMPLE_STUDENT_4.firstName,
		lastName: ID_TO_NAME_MAP.EXAMPLE_STUDENT_4.lastName,
		linkedAccounts: [],
	},
	{
		_id: EXAMPLE_STUDENT_5,
		firstName: ID_TO_NAME_MAP.EXAMPLE_STUDENT_5.firstName,
		lastName: ID_TO_NAME_MAP.EXAMPLE_STUDENT_5.lastName,
		linkedAccounts: [],
	},
	{
		_id: EXAMPLE_STUDENT_6,
		firstName: ID_TO_NAME_MAP.EXAMPLE_STUDENT_6.firstName,
		lastName: ID_TO_NAME_MAP.EXAMPLE_STUDENT_6.lastName,
		linkedAccounts: [],
	},
	{
		_id: EXAMPLE_STUDENT_7,
		firstName: ID_TO_NAME_MAP.EXAMPLE_STUDENT_7.firstName,
		lastName: ID_TO_NAME_MAP.EXAMPLE_STUDENT_7.lastName,
		linkedAccounts: [],
	},
	{
		_id: EXAMPLE_STUDENT_8,
		firstName: ID_TO_NAME_MAP.EXAMPLE_STUDENT_8.firstName,
		lastName: ID_TO_NAME_MAP.EXAMPLE_STUDENT_8.lastName,
		linkedAccounts: [],
	},
	{
		_id: EXAMPLE_STUDENT_9,
		firstName: ID_TO_NAME_MAP.EXAMPLE_STUDENT_9.firstName,
		lastName: ID_TO_NAME_MAP.EXAMPLE_STUDENT_9.lastName,
		linkedAccounts: [],
	},
	{
		_id: EXAMPLE_STUDENT_10,
		firstName: ID_TO_NAME_MAP.EXAMPLE_STUDENT_10.firstName,
		lastName: ID_TO_NAME_MAP.EXAMPLE_STUDENT_10.lastName,
		linkedAccounts: [],
	},
	{
		_id: EXAMPLE_STUDENT_11,
		firstName: ID_TO_NAME_MAP.EXAMPLE_STUDENT_11.firstName,
		lastName: ID_TO_NAME_MAP.EXAMPLE_STUDENT_11.lastName,
		linkedAccounts: [],
	},
	{
		_id: EXAMPLE_STUDENT_12,
		firstName: ID_TO_NAME_MAP.EXAMPLE_STUDENT_12.firstName,
		lastName: ID_TO_NAME_MAP.EXAMPLE_STUDENT_12.lastName,
		linkedAccounts: [],
	},
	{
		_id: EXAMPLE_STUDENT_13,
		firstName: ID_TO_NAME_MAP.EXAMPLE_STUDENT_13.firstName,
		lastName: ID_TO_NAME_MAP.EXAMPLE_STUDENT_13.lastName,
		linkedAccounts: [],
	},
	{
		_id: EXAMPLE_STUDENT_14,
		firstName: ID_TO_NAME_MAP.EXAMPLE_STUDENT_14.firstName,
		lastName: ID_TO_NAME_MAP.EXAMPLE_STUDENT_14.lastName,
		linkedAccounts: [],
	},
	{
		_id: EXAMPLE_STUDENT_15,
		firstName: ID_TO_NAME_MAP.EXAMPLE_STUDENT_15.firstName,
		lastName: ID_TO_NAME_MAP.EXAMPLE_STUDENT_15.lastName,
		linkedAccounts: [],
	},
	{
		_id: EXAMPLE_STUDENT_16,
		firstName: ID_TO_NAME_MAP.EXAMPLE_STUDENT_16.firstName,
		lastName: ID_TO_NAME_MAP.EXAMPLE_STUDENT_16.lastName,
		linkedAccounts: [],
	},
	{
		_id: EXAMPLE_STUDENT_17,
		firstName: ID_TO_NAME_MAP.EXAMPLE_STUDENT_17.firstName,
		lastName: ID_TO_NAME_MAP.EXAMPLE_STUDENT_17.lastName,
		linkedAccounts: [],
	},
	{
		_id: EXAMPLE_STUDENT_18,
		firstName: ID_TO_NAME_MAP.EXAMPLE_STUDENT_18.firstName,
		lastName: ID_TO_NAME_MAP.EXAMPLE_STUDENT_18.lastName,
		linkedAccounts: [],
	},
	{
		_id: EXAMPLE_STUDENT_19,
		firstName: ID_TO_NAME_MAP.EXAMPLE_STUDENT_19.firstName,
		lastName: ID_TO_NAME_MAP.EXAMPLE_STUDENT_19.lastName,
		linkedAccounts: [],
	},
	{
		_id: EXAMPLE_STUDENT_20,
		firstName: ID_TO_NAME_MAP.EXAMPLE_STUDENT_20.firstName,
		lastName: ID_TO_NAME_MAP.EXAMPLE_STUDENT_20.lastName,
		linkedAccounts: [],
	},
	{
		_id: EXAMPLE_STUDENT_21,
		firstName: ID_TO_NAME_MAP.EXAMPLE_STUDENT_21.firstName,
		lastName: ID_TO_NAME_MAP.EXAMPLE_STUDENT_21.lastName,
		linkedAccounts: [],
	},
	{
		_id: EXAMPLE_STUDENT_22,
		firstName: ID_TO_NAME_MAP.EXAMPLE_STUDENT_22.firstName,
		lastName: ID_TO_NAME_MAP.EXAMPLE_STUDENT_22.lastName,
		linkedAccounts: [],
	},
	{
		_id: EXAMPLE_STUDENT_23,
		firstName: ID_TO_NAME_MAP.EXAMPLE_STUDENT_23.firstName,
		lastName: ID_TO_NAME_MAP.EXAMPLE_STUDENT_23.lastName,
		linkedAccounts: [],
	},
	{
		_id: EXAMPLE_STUDENT_24,
		firstName: ID_TO_NAME_MAP.EXAMPLE_STUDENT_24.firstName,
		lastName: ID_TO_NAME_MAP.EXAMPLE_STUDENT_24.lastName,
		linkedAccounts: [],
	},
	{
		_id: EXAMPLE_STUDENT_25,
		firstName: ID_TO_NAME_MAP.EXAMPLE_STUDENT_25.firstName,
		lastName: ID_TO_NAME_MAP.EXAMPLE_STUDENT_25.lastName,
		linkedAccounts: [],
	},
	{
		_id: EXAMPLE_STUDENT_26,
		firstName: ID_TO_NAME_MAP.EXAMPLE_STUDENT_26.firstName,
		lastName: ID_TO_NAME_MAP.EXAMPLE_STUDENT_26.lastName,
		linkedAccounts: [],
	},
]

export const EXAMPLE_CLASSES: ClassType[] = [
	{
		_id: 'EXAMPLE_CLASS_0',
		name: 'Epic Sample Class',
		grades: ['5'],
		students: EXAMPLE_CLASS_STUDENTS,
		schoolId: 'FAKE_SCHOOL_ID',
		owners: [
			{
				_id: EXAMPLE_USER_ID,
				firstName: EXAMPLE_FIRST_NAME,
				lastName: EXAMPLE_LAST_NAME,
			},
		],
		externalReferences: [],
	},
]

export const EXAMPLE_USER: User = {
	_id: EXAMPLE_USER_ID,
	email: 'string',
	firstName: EXAMPLE_FIRST_NAME,
	lastName: EXAMPLE_LAST_NAME,
	state: 'UT',
	schoolName: 'Mission.io Academy',
	schoolId: 'string',
	districtId: 'string',
	roles: [],
	training: {
		stageActions: [],
	},
	email_verification: {
		confirmed: true,
	},
	onboarding: {
		isOnboardingFinished: true,
		didUserStartMission: false,
	},
	classes: EXAMPLE_CLASSES,
	linkedAccounts: [],
}

const QUESTIONS: ClientQuestion[] = [
	{
		choices: [
			{ _id: 'CHOICE_0_ID', text: 'quadrilateral', answer: true, media: [] },
			{ _id: 'CHOICE_1_ID', text: 'rectangle', answer: true, media: [] },
			{ _id: 'CHOICE_2_ID', text: 'rhombus', answer: true, media: [] },
			{ _id: 'CHOICE_3_ID', text: 'parallelogram', answer: true, media: [] },
		],
		createdBy: EXAMPLE_USER,
		default: false,
		media: [],
		phrase: 'A square is a _________.',
		scoreValue: 1,
		type: 'MULTIPLE_SELECT',
		version: 1,
		_id: 'QUESTION_0_ID',
	},
	{
		choices: [
			{ _id: 'CHOICE_4_ID', text: 'Liver', answer: false, media: [] },
			{ _id: 'CHOICE_5_ID', text: 'Skin', answer: true, media: [] },
			{ _id: 'CHOICE_6_ID', text: 'Heart', answer: false, media: [] },
			{ _id: 'CHOICE_7_ID', text: 'Brain', answer: false, media: [] },
		],
		createdBy: EXAMPLE_USER,
		default: false,
		media: [],
		phrase: 'What is the heaviest organ in the human body?',
		scoreValue: 1,
		type: 'MULTIPLE_CHOICE',
		version: 1,
		_id: 'QUESTION_1_ID',
	},
	{
		choices: [
			{ _id: 'CHOICE_8_ID', text: 'Coca Cola', answer: false, media: [] },
			{ _id: 'CHOICE_9_ID', text: 'Pepsi', answer: false, media: [] },
			{ _id: 'CHOICE_10_ID', text: 'Dr. Pepper', answer: true, media: [] },
			{ _id: 'CHOICE_11_ID', text: "Barq's", answer: false, media: [] },
		],
		createdBy: EXAMPLE_USER,
		default: false,
		media: [],
		phrase: 'What is the oldest soda brand in the United States?',
		scoreValue: 1,
		type: 'MULTIPLE_CHOICE',
		version: 1,
		_id: 'QUESTION_2_ID',
	},
	{
		choices: [
			{
				_id: 'CHOICE_12_ID',
				text: 'This is about something that tastes bad.',
				answer: true,
				media: [],
			},
			{
				_id: 'CHOICE_13_ID',
				text: 'This is about someone who is cool.',
				answer: false,
				media: [],
			},
			{
				_id: 'CHOICE_14_ID',
				text: 'This is about something that tastes good.',
				answer: false,
				media: [],
			},
			{
				_id: 'CHOICE_15_ID',
				text: 'This is about someone who is not cool.',
				answer: false,
				media: [],
			},
		],
		createdBy: EXAMPLE_USER,
		default: false,
		media: [],
		phrase:
			"Translate this sentence: ngl boba is frfr cheugy and the texture is low key sus. no cap, it's an absolute clapback to your tastebuds. not. bussin. periodt",
		scoreValue: 1,
		type: 'MULTIPLE_CHOICE',
		version: 1,
		_id: 'QUESTION_3_ID',
	},
	{
		dropdowns: [
			{
				choices: [
					{
						_id: 'CHOICE_16_ID',
						text: "they're",
						answer: false,
						media: Array(0),
					},
					{
						_id: 'CHOICE_17_ID',
						text: 'their',
						answer: true,
						media: Array(0),
					},
					{
						_id: 'CHOICE_18_ID',
						text: 'there',
						answer: false,
						media: Array(0),
					},
				],
				templateResponseKey: '1',
			},
			{
				choices: [
					{
						_id: 'CHOICE_19_ID',
						text: "they're",
						answer: true,
						media: Array(0),
					},
					{
						_id: 'CHOICE_20_ID',
						text: 'their',
						answer: false,
						media: Array(0),
					},
					{
						_id: 'CHOICE_21_ID',
						text: 'there',
						answer: false,
						media: Array(0),
					},
				],
				templateResponseKey: '2',
			},
			{
				choices: [
					{
						_id: 'CHOICE_22_ID',
						text: "they're",
						answer: false,
						media: Array(0),
					},
					{
						_id: 'CHOICE_23_ID',
						text: 'their',
						answer: false,
						media: Array(0),
					},
					{
						_id: 'CHOICE_24_ID',
						text: 'there',
						answer: true,
						media: Array(0),
					},
				],
				templateResponseKey: '3',
			},
		],
		media: [],
		scoreValue: 1,
		template:
			'In {{response[1]}} space exploration, RALF, Will, and Mela encounter a mysterious planet. {{response[2]}}  excited to uncover its secrets and explore the unique landscapes .{{response[3]}} ',
		type: 'CLOZE_DROPDOWN',
		version: 1,
		createdBy: EXAMPLE_USER,
		default: false,
		_id: 'QUESTION_4_ID',
	},
	{
		dropdowns: [
			{
				x: 330.1056457703927,
				y: 475.2498259052925,
				choices: [
					{ _id: 'CHOICE_25_ID', text: 'Robert E. Lee', answer: false },
					{ _id: 'CHOICE_26_ID', text: 'Stonewall Jackson', answer: false },
					{ _id: 'CHOICE_27_ID', text: 'Ulysses S. Grant', answer: true },
				],
			},
			{
				x: 851.1962802114804,
				y: 469.2331128133705,
				choices: [
					{ _id: 'CHOICE_28_ID', text: 'Ambrose Burnside', answer: true },
					{ _id: 'CHOICE_29_ID', text: 'William Sherman', answer: false },
					{ _id: 'CHOICE_30_ID', text: 'James Longstreet', answer: false },
				],
			},
			{
				x: 318.0573074018127,
				y: 980.653725626741,
				choices: [
					{ _id: 'CHOICE_31_ID', text: 'Nathanael Greene', answer: false },
					{ _id: 'CHOICE_32_ID', text: 'George Washington', answer: false },
					{ _id: 'CHOICE_33_ID', text: 'Benedict Arnold', answer: true },
				],
			},
			{
				x: 839.1479418429003,
				y: 971.628655988858,
				choices: [
					{ _id: 'CHOICE_34_ID', text: 'Douglas MacArthur', answer: false },
					{ _id: 'CHOICE_35_ID', text: 'Dwight D. Eisenhower', answer: true },
					{ _id: 'CHOICE_36_ID', text: 'George S. Patton', answer: false },
				],
			},
		],
		imageProperties: { height: 1080, width: 997 },
		media: [
			{
				type: 'IMAGE',
				url:
					'https://resources-cdn.mission.io/question-media/62cc5d95e2dcfe001d8b0ccf/images/00a25491-f85b-49e8-9eb9-7918fad95516',
			},
		],
		phrase: 'Please select the correct options on the image below',
		type: 'IMAGE_LABELING',
		updated: true,
		version: 1,
		createdBy: EXAMPLE_USER,
		default: false,
		_id: 'QUESTION_5_ID',
	},
]

export const QUESTION_GROUP_0: QuestionGroup = {
	_id: 'QUESTION_GROUP_0_ID',
	name: 'Example Questions',
	createdBy: EXAMPLE_USER,
	createdAt: '2023-09-22T14:09:26',
	public: true,
	questions: QUESTIONS,
	subjects: [],
	grades: ['5'],
	owner: {
		type: OWNER_TYPES.USER,
		id: EXAMPLE_USER._id,
	},
}

export const EXAMPLE_QUESTION_GROUPS: QuestionGroup[] = [QUESTION_GROUP_0]

const CLASS_STUDENT_ANALYTICS_0: ClassStudentAnalytics = {
	_id: 'CLASS_STUDENT_ANALYTICS_0_ID',
	studentAnalytics: {
		[EXAMPLE_STUDENT_0]: STUDENT_ANALYTICS_0,
		[EXAMPLE_STUDENT_1]: STUDENT_ANALYTICS_1,
		[EXAMPLE_STUDENT_2]: STUDENT_ANALYTICS_2,
		[EXAMPLE_STUDENT_3]: STUDENT_ANALYTICS_3,
		[EXAMPLE_STUDENT_4]: STUDENT_ANALYTICS_4,
		[EXAMPLE_STUDENT_5]: STUDENT_ANALYTICS_5,
		[EXAMPLE_STUDENT_6]: STUDENT_ANALYTICS_6,
		[EXAMPLE_STUDENT_7]: STUDENT_ANALYTICS_7,
		[EXAMPLE_STUDENT_8]: STUDENT_ANALYTICS_8,
		[EXAMPLE_STUDENT_9]: STUDENT_ANALYTICS_9,
		[EXAMPLE_STUDENT_10]: STUDENT_ANALYTICS_10,
		[EXAMPLE_STUDENT_11]: STUDENT_ANALYTICS_11,
		[EXAMPLE_STUDENT_12]: STUDENT_ANALYTICS_12,
		[EXAMPLE_STUDENT_13]: STUDENT_ANALYTICS_13,
		[EXAMPLE_STUDENT_14]: STUDENT_ANALYTICS_14,
		[EXAMPLE_STUDENT_15]: STUDENT_ANALYTICS_15,
		[EXAMPLE_STUDENT_16]: STUDENT_ANALYTICS_16,
		[EXAMPLE_STUDENT_17]: STUDENT_ANALYTICS_17,
		[EXAMPLE_STUDENT_18]: STUDENT_ANALYTICS_18,
		[EXAMPLE_STUDENT_19]: STUDENT_ANALYTICS_19,
		[EXAMPLE_STUDENT_20]: STUDENT_ANALYTICS_20,
	},
	culminatingMomentQuestions: [
		{
			id: '63ecacfe3242ae001e233b85',
			version: 1,
			phrase: 'What planet is the stranded ship located by?',
			choices: [
				{
					id: '63ecacfe3242ae001e233b86',
					text: 'Exanis',
					correct: false,
				},
				{
					id: '63ecacfe3242ae001e233b87',
					text: 'Viper 1',
					correct: false,
				},
				{
					id: '63ecacfe3242ae001e233b88',
					text: 'Vespus',
					correct: true,
				},
				{
					id: '63ecacfe3242ae001e233b89',
					text: 'Skellus',
					correct: false,
				},
			],
			media: [],
		},
	],
}

/**
 * Gets a date that is a certain number of days ago
 */
function getDate({ daysAgo }: { daysAgo: number }): Date {
	const date = new Date()
	date.setDate(date.getDate() - daysAgo)
	return date
}

export const ANALYTICS_OBJECT_0: AnalyticsObject = {
	missionId: 'EXAMPLE_ANALYTICS_MISSION_ID',
	version: 0,
	analytics: CLASS_STUDENT_ANALYTICS_0,
	classId: EXAMPLE_CLASSES[0]._id,
	createdTime: String(getDate({ daysAgo: 4 })),
	missionName: 'Example Analytics',
	grades: ['5'],
}

export const EXAMPLE_ANALYTICS_ARRAY = [ANALYTICS_OBJECT_0]

export const EXAMPLE_LEADER_BOARD_ANALYTICS: LeaderBoardAnalytics = {
	leaderboard: {
		categories: {
			'4+': {
				description: `${new Date().toLocaleString('default', {
					month: 'long',
				})} ${new Date().getFullYear()}`,
				schoolsLeaderBoard: {
					explanation: 'Top schools',
					scores: [
						{ name: 'Hogwarts', score: 8023, id: 'test_school_1' },
						{ name: 'Jedi Academy', score: 6736, id: 'test_school_2' },
						{ name: 'Starfleet Academy', score: 5897, id: 'test_school_3' },
						{ name: 'Bayside High School', score: 4581, id: 'test_school_4' },
						{
							name: "Xavier's School for Gifted Youngsters",
							score: 4998,
							id: 'test_school_5',
						},
					],
				},
				districtsLeaderBoard: {
					explanation: 'Top Districts',
					scores: [
						{ name: 'Wizarding World District', score: 8753, id: 'test_district_1' },
						{
							name: 'Galactic Learning Federation',
							score: 6736,
							id: 'test_district_2',
						},
						{
							name: 'United Star Systems Educational Zone',
							score: 5897,
							id: 'test_district_3',
						},
						{ name: 'Saved by the Bell Union', score: 4581, id: 'test_district_4' },
						{ name: 'Mutant and Meta-Human ISD', score: 4003, id: 'test_district_5' },
					],
				},
			},
		},
	},
	type: 'TEACHER',
	results: {
		classOfRequestingTeacher: {
			teacherId: '',
			teacherName: '',
			score: 4000,
			time: 50,
			deaths: 3,
			problemsSolved: 7,
			studentResults: [
				{
					studentId: EXAMPLE_CLASS_STUDENTS[8]._id,
					averageStudentSELScore: 85,
					averageStudentSELChange: 5,
					deaths: 1,
					totalHealthTasks: 40,
					questionsAnsweredCorrectly: 25,
					avgGameScore: 6200,
				},
				{
					studentId: EXAMPLE_CLASS_STUDENTS[18]._id,
					averageStudentSELScore: 62,
					averageStudentSELChange: 15,
					deaths: 1,
					totalHealthTasks: 40,
					questionsAnsweredCorrectly: 25,
					avgGameScore: 2700,
				},
				{
					studentId: EXAMPLE_CLASS_STUDENTS[7]._id,
					averageStudentSELScore: 43,
					averageStudentSELChange: 14,
					deaths: 1,
					totalHealthTasks: 40,
					questionsAnsweredCorrectly: 25,
					avgGameScore: 5176,
				},
				{
					studentId: EXAMPLE_CLASS_STUDENTS[17]._id,
					averageStudentSELScore: 68,
					averageStudentSELChange: 16,
					deaths: 1,
					totalHealthTasks: 40,
					questionsAnsweredCorrectly: 25,
					avgGameScore: 3816,
				},
				{
					studentId: EXAMPLE_CLASS_STUDENTS[2]._id,
					averageStudentSELScore: 72,
					averageStudentSELChange: 5,
					deaths: 1,
					totalHealthTasks: 40,
					questionsAnsweredCorrectly: 25,
					avgGameScore: 6200,
				},
				{
					studentId: EXAMPLE_CLASS_STUDENTS[12]._id,
					averageStudentSELScore: 62,
					averageStudentSELChange: 38,
					deaths: 1,
					totalHealthTasks: 40,
					questionsAnsweredCorrectly: 25,
					avgGameScore: 2700,
				},
				{
					studentId: EXAMPLE_CLASS_STUDENTS[3]._id,
					averageStudentSELScore: 43,
					averageStudentSELChange: 1,
					deaths: 1,
					totalHealthTasks: 40,
					questionsAnsweredCorrectly: 25,
					avgGameScore: 5176,
				},
				{
					studentId: EXAMPLE_CLASS_STUDENTS[13]._id,
					averageStudentSELScore: 68,
					averageStudentSELChange: 17,
					deaths: 1,
					totalHealthTasks: 40,
					questionsAnsweredCorrectly: 25,
					avgGameScore: 3816,
				},
				{
					studentId: EXAMPLE_CLASS_STUDENTS[4]._id,
					averageStudentSELScore: 61,
					averageStudentSELChange: 2,
					deaths: 1,
					totalHealthTasks: 40,
					questionsAnsweredCorrectly: 25,
					avgGameScore: 6200,
				},
				{
					studentId: EXAMPLE_CLASS_STUDENTS[14]._id,
					averageStudentSELScore: 27,
					averageStudentSELChange: 15,
					deaths: 1,
					totalHealthTasks: 40,
					questionsAnsweredCorrectly: 25,
					avgGameScore: 2700,
				},
				{
					studentId: EXAMPLE_CLASS_STUDENTS[5]._id,
					averageStudentSELScore: 94,
					averageStudentSELChange: 14,
					deaths: 1,
					totalHealthTasks: 40,
					questionsAnsweredCorrectly: 25,
					avgGameScore: 5176,
				},
				{
					studentId: EXAMPLE_CLASS_STUDENTS[15]._id,
					averageStudentSELScore: 74,
					averageStudentSELChange: 28,
					deaths: 1,
					totalHealthTasks: 40,
					questionsAnsweredCorrectly: 25,
					avgGameScore: 3816,
				},
			],
		},
		comparisonScores: [
			{
				teacherName: `${EXAMPLE_CLASS_STUDENTS[3].firstName} ${EXAMPLE_CLASS_STUDENTS[3].lastName}`,
				score: 5003,
			},
			{
				teacherName: `${EXAMPLE_CLASS_STUDENTS[9].firstName} ${EXAMPLE_CLASS_STUDENTS[9].lastName}`,
				score: 5953,
			},
			{
				teacherName: `${EXAMPLE_CLASS_STUDENTS[1].firstName} ${EXAMPLE_CLASS_STUDENTS[1].lastName}`,
				score: 7320,
			},
			{
				teacherName: `${EXAMPLE_CLASS_STUDENTS[19].firstName} ${EXAMPLE_CLASS_STUDENTS[19].lastName}`,
				score: 4860,
			},
			{
				teacherName: `${EXAMPLE_CLASS_STUDENTS[21].firstName} ${EXAMPLE_CLASS_STUDENTS[21].lastName}`,
				score: 4865,
			},
		],
	},
}
