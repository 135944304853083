import classnames from 'classnames'
import React from 'react'
import { forwardRef } from 'react'

type BaseProps = {
	className?: string,
}
/**
 * A standard input field, styled to match the Mission.io brand guide.
 * TODO: move this to @mission.io/styles
 */
export const Input: React$AbstractComponent<BaseProps, HTMLInputElement> = forwardRef(
	function BaseInput({ className, ...props }: BaseProps, ref): React$Node {
		return (
			<input
				{...props}
				ref={ref}
				className={classnames(
					className,
					'py-1 px-2 rounded-lg text-primary-950 bg-primary-100'
				)}
			/>
		)
	}
)
