import React, { useState, useEffect, useMemo } from 'react'
import axios from 'axios'
import 'styled-components/macro'
import { useQuery, type UseQueryResult } from 'react-query'
import { Tooltip } from 'react-tooltip'
import Markdown from 'markdown-to-jsx'
import FaBell from 'react-icons/lib/fa/bell'
import GoDot from 'react-icons/lib/go/primitive-dot'
import LoadingSpinner from '../styles/LoadingSpinner'
import config from '../config'
import { useUser } from '../services/hooks'
import classnames from 'classnames'
import { Button } from '@mission.io/styles'

export type AnnouncementType = {|
	id: string,
	title: string,
	details: string,
	url: string,
	date: string,
|}

/**
 *  Fetches the list of announcements
 * @return {Promise<AnnouncementType[]>} The array of announcements
 */
async function fetchAnnouncements(): Promise<AnnouncementType[]> {
	const result = await axios.get(`${config.missionsAPIURL}/api/announcements`)
	return result.data.announcements
}

const NEW_INDICATOR_WIDTH = '24px'
const THIRTY_MINUTES = 1000 * 60 * 30

/**
 * A bell icon that will display a list of announcements in a large tooltip when clicked.
 */
export function Announcements(): React$Node {
	const { data: announcements, isLoading, error }: UseQueryResult<AnnouncementType[]> = useQuery(
		'announcements',
		fetchAnnouncements,
		{
			staleTime: THIRTY_MINUTES,
		}
	)
	const { user } = useUser()

	const announcementIds: ?Array<string> = useMemo(
		() => announcements?.map(announcement => announcement.id),
		[announcements]
	)

	useRemoveUnusedAnnouncementLocalStorage(announcementIds)

	const hasUnseenAnnouncement = announcements?.some(
		announcement => !hasViewedAnnouncement(announcement.id)
	)

	const tooltipId = 'tooltip-announcements-id'

	return (
		<>
			<button
				id={tooltipId}
				className={classnames(
					user ? 'self-start' : 'self-center',
					'relative flex hover:brightness-90 active:brightness-75'
				)}>
				<Bell
					className="pointer-events-none"
					status={hasUnseenAnnouncement ? 'alert' : null}
					aria-label="announcements"
					title="announcements"
				/>
			</button>
			<Tooltip
				openOnClick
				closeEvents={{ click: true }}
				opacity={1}
				anchorSelect={`#${tooltipId}`}
				place="bottom-start"
				className="[&&]:bg-primary-400 text-white rounded [&&]:pointer-events-all max-w-md">
				<div
					className="max-h-[min(525px,80vh)] my-2 px-1 overflow-y-auto text-white overscroll-contain"
					aria-busy={isLoading}>
					<h3
						className="text-3xl mb-2"
						css={`
							padding-left: ${NEW_INDICATOR_WIDTH};
						`}>
						Updates
					</h3>
					{announcements ? (
						announcements.map((announcement, i) => {
							return (
								<React.Fragment key={announcement.id}>
									<Announcement announcement={announcement} />
									{i !== announcements.length - 1 && (
										<hr
											className="my-3"
											css={`
												margin-left: ${NEW_INDICATOR_WIDTH};
											`}
										/>
									)}
								</React.Fragment>
							)
						})
					) : isLoading ? (
						<LoadingSpinner
							shouldShowSpinner
							css="width: 30px; height: 30px; margin: var(--spacing1x-dont-use);"
						/>
					) : (
						<p
							css={`
								padding-left: ${NEW_INDICATOR_WIDTH};
							`}>
							{error
								? 'There was an error while loading announcements'
								: 'Could not get announcements'}
						</p>
					)}
				</div>
			</Tooltip>
		</>
	)
}

/**
 * A bell icon that can optionally be in an "alert" status, which gives it a red notification dot.
 */
function Bell({ status, ...props }: { status: ?'alert', size?: number }) {
	return (
		<>
			<FaBell size={20} {...props} />
			{status === 'alert' && (
				<GoDot
					color="var(--danger)"
					css="position: absolute; top: -18%; left: 38%;"
					className="pointer-events-none"
				/>
			)}
		</>
	)
}

/**
 * A single announcement
 */
function Announcement({ announcement }: { announcement: AnnouncementType }) {
	const id = announcement.id
	const [hasBeenViewed] = useState(() => hasViewedAnnouncement(id))
	useEffect(() => {
		if (!hasBeenViewed) {
			setHasViewedAnnouncement(id)
		}
	}, [id, hasBeenViewed])

	return (
		<div
			css={`
				display: flex;

				&:last-child {
					margin-bottom: var(--spacing2x-dont-use);
				}

				div:nth-child(1) {
					width: ${NEW_INDICATOR_WIDTH};
				}
				div:nth-child(2) {
					flex: 1;
				}
			`}>
			<div>{!hasBeenViewed && <GoDot color="var(--danger)" />}</div>
			<div>
				<h5 className="text-lg font-bold">{announcement.title}</h5>
				<Markdown className="!text-white">{announcement.details}</Markdown>
				<Button
					as="a"
					small
					variant="secondary"
					className="ml-2 float-right"
					href={announcement.url}>
					Read&nbsp;More!
				</Button>
			</div>
		</div>
	)
}

/**
 * A hook that removes all unused announcement data from localstorage. Has no effect if `currentAnnouncementIds` is
 * falsy.
 * @param {?Array<string>} currentAnnouncementIds ids for all current announcements
 */
function useRemoveUnusedAnnouncementLocalStorage(currentAnnouncementIds: ?Array<string>) {
	const currentAnnouncementLocalStorageKeys: ?Set<string> = useMemo(
		() =>
			currentAnnouncementIds
				? new Set(currentAnnouncementIds.map(id => announcementIdToLocalStorageKey(id)))
				: null,
		[currentAnnouncementIds]
	)
	useEffect(() => {
		if (!currentAnnouncementLocalStorageKeys) {
			return
		}
		Object.keys(localStorage).forEach(localStorageKey => {
			const isOldMissionIoAnnouncementKey =
				localStorageKey.startsWith(announcementLocalStoragePrefix) &&
				!currentAnnouncementLocalStorageKeys.has(localStorageKey)

			if (isOldMissionIoAnnouncementKey) {
				localStorage.removeItem(localStorageKey)
			}
		})
	}, [currentAnnouncementLocalStorageKeys])
}

/**
 * Gets the local storage id for the given announcement id
 * @param {string} id The announcement id
 */
const announcementLocalStoragePrefix = 'did_view_mission.io_announcement:'
const announcementIdToLocalStorageKey = (id: string) => announcementLocalStoragePrefix + id
const VIEWED_ANNOUNCEMENT_VALUE = 'true'

/**
 * Tells whether the announcement with the given id has been viewed before
 * @param {string} id The announcement id
 */
function hasViewedAnnouncement(id: string): boolean {
	return localStorage.getItem(announcementIdToLocalStorageKey(id)) === VIEWED_ANNOUNCEMENT_VALUE
}

/**
 * Sets the announcement with the given id as 'viewed'.
 * @param {string} id The announcement id
 */
function setHasViewedAnnouncement(id: string): void {
	localStorage.setItem(announcementIdToLocalStorageKey(id), VIEWED_ANNOUNCEMENT_VALUE)
}
