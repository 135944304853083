// @flow
import React from 'react'
import { Button } from '@mission.io/styles'
import styled from 'styled-components/macro'
import { ExitX } from './modal/Modal'
import CustomModal from './modal'
import config from '../config'
import InfiniDClass from '../assets/InfiniDClass.png'
import type { ThemeType } from '@mission.io/styles'

/**
 * RequireSignInModal - a modal used to prompt a user to login or create an account
 * @param {boolean} isOpen If the modal is open or not
 * @param {() => mixed} onRequestClose what should happen when the modal closes
 */
export default function RequireSignInModal({
	isOpen,
	onRequestClose,
}: {
	isOpen: boolean,
	onRequestClose: () => mixed,
}): React$Node {
	return (
		<CustomModal
			isOpen={isOpen}
			onRequestClose={() => {
				onRequestClose()
			}}
			style={{ content: { width: '62%' } }}>
			<Body>
				<Img src={InfiniDClass} alt="Teacher and Students" />
				<ModalContent>
					<Exit onClick={onRequestClose} />
					<div className="font-bold text-5xl">Join the learning revolution!</div>
					<Bullets>
						<div>🧩 Real-world problem solving</div>
						<div>🤝 Full-class experiential learning</div>
						<div>🎓 Standards-based storylines</div>
						<div>📈 Game-changing knowledge & skills metrics</div>
					</Bullets>
					<Text>
						<div>Dive into 100+ missions and be the teacher</div>
						<div>who won&apos;t settle for &ldquo;business as usual.&rdquo;</div>
					</Text>

					<Button
						onClick={() =>
							window.location.replace(
								`${
									config.loginClientUrl
								}/sign-up?sendto=${window.location.toString()}`
							)
						}
						className="text-xl">
						JOIN FOR FREE
					</Button>

					<Row>
						Already have an account?{' '}
						<Link
							href={`${config.loginClientUrl}?sendto=${window.location.toString()}`}>
							SIGN IN
						</Link>
					</Row>
				</ModalContent>
			</Body>
		</CustomModal>
	)
}

const Exit = styled(ExitX)`
	${({ theme }) => `
        color: ${theme.neutralLight};
        right: var(--spacing1x-dont-use);
        top: var(--spacing1x-dont-use);
     `}
	position: absolute;
`

const Body = styled.div`
	display: flex;
	flex-direction: row;
`

const Bullets = styled.div`
	display: flex;
	flex-direction: column;
	${({ theme }) => `
        gap: var(--spacing1x-dont-use);
    `}
`

const ModalContent = styled.div.attrs({ className: 'p-2 text-xl' })`
	${({ theme }: { theme: ThemeType }) => `
        background-image: linear-gradient(${theme.primaryDark}, #1B4161);
        color: ${theme.neutralLight};
        gap: var(--spacing4x-dont-use);
    `}
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 72%;
	justify-content: center;
`

const Row = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	${({ theme }) => `
        gap: var(--spacing-half-dont-use);
    `}
`

const Text = styled.div``

const Img = styled.img`
	width: 28%;
`
const Link = styled.a`
	color: #4a8bff;
`
