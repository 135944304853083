// @flow
import React, { useReducer, useMemo, useRef } from 'react'
import { MdInfoOutline } from 'react-icons/lib/md'
import { useDispatch } from 'react-redux'
import EmptyQuestions from '../../../assets/NoData.png'
import styled from 'styled-components/macro'
import questionReducer, { ACTIONS } from '../questionGroupReducer'
import { OWNER_TYPES } from '../../../models/QuestionGroup'
import Checkbox from '../../../components/inputs/Checkbox'
import { deleteQuestionGroup } from '../../../store/question'
import { FlexContainer } from './basics'
import EditQuestionGroupDetails from './EditQuestionGroupDetails'
import { SimpleConfirmation, ACTIONABLE_TOAST_PROPERTIES } from '../../../components'
import { toast } from 'react-toastify'
import useUser, { useIsDistrictAdmin } from '../../../services/hooks/user'
import { EditQuestions } from '@mission.io/question-views'
import { validate, type ClientQuestion } from '@mission.io/question-toolkit'
import { isEqual, keys, has, get, entries, isObjectLike } from 'lodash'

import type { QuestionGroup } from '../../../models/QuestionGroup'
import { useUpdateQuestionGroup } from '../../../services/hooks/questionGroup'

/**
 * Checks if all questions are valid.
 * A. Returns null if all questions are valid
 * B. Returns a string if any question is not valid
 * The string will contain a specific error message for each question that is invalid.
 * @param {ClientQuestion[]} questions
 * @returns {?string}
 */
function validateQuestions(questions: ClientQuestion[]): ?string {
	let message = []
	questions.forEach((question, index) => {
		const questionError = validate(question)
		if (questionError) {
			message.push(`Question ${index + 1} is invalid: ${questionError.error}`)
		}
	})
	if (message.length > 0) {
		return message.join('\n')
	}
}

export default function EditQuestionGroup({
	questionGroup,
	headerWrapper,
	goBack,
}: {
	questionGroup: QuestionGroup,
	headerWrapper: React$Node,
	goBack: () => void,
}): React$Node {
	const [localQuestionGroup, dispatch] = useReducer(questionReducer, questionGroup)

	const { mutate: updateQuestionGroup } = useUpdateQuestionGroup({
		onSuccess: data => {
			toast.success('Updated question group!')
		},
		onError: err => {
			toast.error(`Failed to update question group because ${err.message}`)
			console.error(err)
		},
	})

	const remoteDispatch = useDispatch()
	const { user } = useUser()
	const isUserDistrictAdmin = useIsDistrictAdmin()
	const deleteQuestionToastId = useRef()
	const onSave = () => {
		const error = validateQuestions(localQuestionGroup.questions)
		if (!error) {
			updateQuestionGroup({
				...localQuestionGroup,
				subjects: localQuestionGroup.subjects
					? localQuestionGroup.subjects.map(qg => qg.id)
					: [],
			})
		}
		toast.error(error, { autoClose: false })
	}
	const onDelete = () => {
		remoteDispatch(deleteQuestionGroup(localQuestionGroup._id))
	}
	const confirmDelete = () => {
		const toastId = toast.info(
			<SimpleConfirmation
				message={`Are you sure you want to delete the question group "${localQuestionGroup.name}"?`}
				confirmText={'Delete Question Group'}
				onConfirm={() => {
					toast.dismiss(toastId)
					onDelete()
					goBack()
				}}
				onCancel={() => {
					toast.dismiss(toastId)
				}}
			/>,
			{
				...ACTIONABLE_TOAST_PROPERTIES,
				position: toast.POSITION.BOTTOM_CENTER,
			}
		)
	}
	const addQuestion = question => {
		dispatch({ type: ACTIONS.ADD_QUESTION, payload: question })
	}
	const togglePublic = () => {
		dispatch({ type: ACTIONS.SET_PUBLIC, payload: !localQuestionGroup.public })
	}
	const updateQuestion = (question, index) => {
		dispatch({ type: ACTIONS.REPLACE_QUESTION, payload: { index, question } })
	}
	const confirmDeleteQuestion = (index: number) => {
		if (deleteQuestionToastId.current) {
			toast.dismiss(deleteQuestionToastId.current)
		}

		deleteQuestionToastId.current = toast.info(
			<SimpleConfirmation
				message={`Are you sure you want to delete question ${index + 1}?`}
				confirmText={`Delete Question ${index + 1}`}
				onConfirm={() => {
					dispatch({ type: ACTIONS.DELETE_QUESTION, payload: { index } })
					toast.dismiss(deleteQuestionToastId.current)
				}}
				onCancel={() => {
					toast.dismiss(deleteQuestionToastId.current)
				}}
			/>,
			{
				...ACTIONABLE_TOAST_PROPERTIES,
				position: toast.POSITION.BOTTOM_CENTER,
			}
		)
	}

	const updateDetail = detail => {
		dispatch({ type: ACTIONS.UPDATE_DETAIL, payload: detail })
	}
	const updateQuestionGroupOwner = (owner: $Keys<typeof OWNER_TYPES>) => {
		if (!user) {
			return
		}
		dispatch({ type: ACTIONS.SET_OWNER, payload: { type: owner, user, isUserDistrictAdmin } })
	}
	const isPrivate = !localQuestionGroup.public

	const isQuestionFormDirty = useMemo(() => {
		return questionGroupsAreDifferent(questionGroup, localQuestionGroup)
	}, [questionGroup, localQuestionGroup])

	return (
		<EditQuestionWrapper>
			<div className="flex items-center justify-between p-2">
				{headerWrapper}
				<div className="flex gap-4 items-center">
					{isUserDistrictAdmin ? (
						<CheckboxWithInfo
							info={'Makes the Question Group visible to everyone in your district'}
							checked={localQuestionGroup.owner.type === OWNER_TYPES.DISTRICT}
							onChange={() => {
								updateQuestionGroupOwner(
									localQuestionGroup.owner.type === OWNER_TYPES.DISTRICT
										? OWNER_TYPES.USER
										: OWNER_TYPES.DISTRICT
								)
							}}
							label="District Question Group"
						/>
					) : null}
					<CheckboxWithInfo
						info={`Hides Question Group from people outside your ${
							localQuestionGroup.owner.type === OWNER_TYPES.DISTRICT
								? 'district'
								: 'school'
						}`}
						checked={isPrivate}
						onChange={togglePublic}
						label="Private"
					/>
				</div>
			</div>

			<EditQuestionGroupDetails
				onSave={onSave}
				updateValue={payload => updateDetail(payload)}
				onDelete={confirmDelete}
				values={localQuestionGroup}
				isQuestionFormDirty={isQuestionFormDirty}
			/>
			<hr className="my-6" />
			<EditQuestions
				add={addQuestion}
				update={updateQuestion}
				remove={confirmDeleteQuestion}
				questions={localQuestionGroup.questions}
			/>
			{localQuestionGroup.questions.length === 0 && (
				<FlexContainer>
					<img
						src={EmptyQuestions}
						alt=""
						css="height: 30vh; margin: 0 auto var(--spacing1x-dont-use) auto;"
					/>
					<p className="text-2xl">Add a question to get started!</p>
				</FlexContainer>
			)}
		</EditQuestionWrapper>
	)
}

/**
 * Deep diff between two object-likes. Copied from https://gist.github.com/Yimiprod/7ee176597fef230d1451?permalink_comment_id=3415430#gistcomment-3415430
 * @param  {Object} fromObject the original object
 * @param  {Object} toObject   the updated object
 * @return {Object}            An object where keys are the path to the change, and values are the old and new values at that key
 */
function deepDiff(fromObject, toObject) {
	const changes = {}

	const buildPath = (path, obj, key) => (path == null ? key : `${path}.${key}`)

	const walk = (fromObject, toObject, path) => {
		for (const key of keys(fromObject)) {
			const currentPath = buildPath(path, fromObject, key)
			if (!has(toObject, key)) {
				changes[currentPath] = { from: get(fromObject, key) }
			}
		}

		for (const [key, to] of entries(toObject)) {
			const currentPath = buildPath(path, toObject, key)
			if (!has(fromObject, key)) {
				changes[currentPath] = { to }
			} else {
				const from = get(fromObject, key)
				if (!isEqual(from, to)) {
					if (isObjectLike(to) && isObjectLike(from)) {
						walk(from, to, currentPath)
					} else {
						changes[currentPath] = { from, to }
					}
				}
			}
		}
	}

	walk(fromObject, toObject)

	return changes
}

/**
 * Checks if two question groups are different. The question groups do not have to be exactly equal because there are some properties that can
 * be different and not be considered a change for the user.
 */
function questionGroupsAreDifferent(questionGroup1, questionGroup2) {
	const diff = deepDiff(questionGroup1, questionGroup2)

	// A list of keys that can be different and not be considered a change for the user.
	// NOTE: These are keys that may exist at any level of the questionGroup object.
	const keysThatCanChange = new Set([
		// question group keys
		'__v',
		// question keys
		'updated',
		'version',
		'createdBy',
		'default',
		'scoreValue',
		// keys for question choices
		'_id',
	])
	return Object.keys(diff).some(key => {
		const finalKeyInPath = key.split('.').pop()

		return !keysThatCanChange.has(finalKeyInPath)
	})
}

const EditQuestionWrapper = styled.div`
	${({ theme }) => `
		padding: 0 var(--spacing2x-dont-use);
	`}
`

const InfoButton = styled.div`
	${({ theme }) => `
		margin-right: var(--spacing1x-dont-use);
	`}
	cursor:pointer;
	position: relative;
	top: -2px;
`

/**
 * Checkbox with an info button that shows a tooltip when hovered over.
 */
function CheckboxWithInfo({
	info,
	checked,
	onChange,
	label,
}: {
	info: string,
	checked: boolean,
	onChange: () => void,
	label: string,
}) {
	return (
		<div className="flex items-center min-w-28">
			<InfoButton title={info}>
				<MdInfoOutline height={'1.4em'} width={'1.4em'} />
			</InfoButton>
			<Checkbox checked={checked} onChange={onChange} label={label} />
		</div>
	)
}
