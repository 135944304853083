import React from 'react'
import styled from 'styled-components'
import type { PreviewProps } from './types'

/**
 * Video - a component used to view a video file
 *
 * @param {PreviewProps} props - the preview props from the FileViewer component
 *
 * @return {React$Node}
 */
export function Video({ file, captions }: PreviewProps): React$Node {
	return (
		<StyledVideo
			src={file}
			autoPlay={true}
			controls
			onClick={e => {
				e.stopPropagation()
			}}
			role="media">
			{captions ? (
				<track kind="captions" label="English" srcLang="en-us" src={captions} />
			) : null}
		</StyledVideo>
	)
}

const StyledVideo = styled.video`
	max-width: 80vw;
	max-height: 80vh;
	pointer-events: all;
`
