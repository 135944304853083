import axios from 'axios'
import NetworkCommunicator from './NetworkCommunicator'
import * as Sentry from '@sentry/browser'
import config from '../config'

import type { UpdateTrainingPayload } from './hooks/user'
import type { User, PermissionsObject } from '../store/types'
import { setUserLoggedInLocalStorage } from '../components/popups/SessionEndedPopup'

/**
 * fetchUser - gets the current user if there is one.
 * If there is not a user (401 error), null is returned and no error is thrown.
 */
export function fetchUser(): Promise<?User> {
	return NetworkCommunicator.GET(`/api/users/me`, { host: config.loginServer })
		.then(user => {
			Sentry.configureScope(scope => {
				scope.setUser({
					id: `${user._id}`,
					email: `${user.email}`,
				})
			})
			if (user) setUserLoggedInLocalStorage(true)
			return user
		})
		.catch(error => {
			if (error.status === 401) return null
			else throw error
		})
}

/**
 * fetchDistrictFromSchool - get the districtId (ncesId) of the school
 *
 * @param {?string} schoolId - the school to get the district for
 *
 * @return {() => Promise<?string>} - a callback to get the district (string) or null/undefined if no district can be found for the school
 */
export function fetchDistrictFromSchool(schoolId: ?string): () => Promise<?string> {
	return () => {
		if (!schoolId) {
			return Promise.resolve(null)
		}
		return NetworkCommunicator.GET(`/api/schools/${schoolId}`, { host: config.loginServer })
			.then(schoolData => schoolData?.school?.ncesId)
			.catch(error => {
				console.error(`Error getting user district from school ${schoolId}`)
				console.error(error)
			})
	}
}

/**
 * Network call to loginServer to get the User's permissions.
 */
export function fetchPermissions(): Promise<PermissionsObject> {
	return NetworkCommunicator.GET(`/api/permissions`, {
		host: config.loginServer,
		withCredentials: true,
	}).then(res => {
		const accountType = res?.ownerObject?.type
		const promotionSimulationGroups = new Set()
		const expiredPromotionSimulationGroups = new Set()

		const currentDate = Date.now()
		res?.promotions?.forEach(({ permissions, expirationDate, ...rest }) => {
			let addToSet = expiredPromotionSimulationGroups
			if (currentDate < expirationDate) {
				addToSet = promotionSimulationGroups
			}

			if (permissions?.simulationGroups) {
				for (let simulationGroup of permissions.simulationGroups) {
					addToSet.add(simulationGroup)
				}
			}
		})
		return {
			canRunOnSiteMission: Boolean(res?.userPermissions?.physicalClassroom),
			// if there is no account type, default to true, else compare to FREE
			isFreeAccount: !accountType || accountType === 'FREE',
			usingGracePeriod: Boolean(res?.licenseDescription === 'GRACE PERIOD'),
			simulationGroups: res?.userPermissions?.simulationGroups ?? [],
			baseSimulationGroups: res?.baseUserPermissions?.simulationGroups ?? [],
			promotionSimulationGroups: Array.from(promotionSimulationGroups),
			expiredPromotionSimulationGroups: Array.from(expiredPromotionSimulationGroups),
			promotions: res?.promotions?.map(promotion => {
				// round expiration date down to the hour of the expiration
				const roundedExpirationDate = new Date(Number(promotion.expirationDate))
				roundedExpirationDate.setSeconds(0)
				roundedExpirationDate.setMinutes(0)
				return { ...promotion, expirationDate: roundedExpirationDate }
			}),
		}
	})
}

/**
 * Network call to mark a user training action
 * @param {UpdateTrainingPayload} payload
 * @returns Promise<*>
 */
export function handleMarkTrainingStatus(payload: UpdateTrainingPayload): Promise<void> {
	return NetworkCommunicator.POST('/api/trainings/actions', {
		host: config.loginServer,
		body: {
			stageAction: payload,
		},
	})
}

/**
 * Sends the passed in email to the login-server send verification email endpoint.
 * If the GET is successful, we call ths onSuccess callback, if we get an error
 * we call the onError callback.
 */
export function sendVerificationEmail(
	email: string,
	onSuccess: string => void,
	onError: string => void
): Promise<void> {
	return NetworkCommunicator.POST('/send-verification-email?email=' + email, {
		host: config.loginServer,
		withCredentials: true,
	})
		.then(response => {
			onSuccess(response.message)
		})
		.catch(error => {
			onError(error.message)
		})
}

/**
 * Gets a login token from the login server
 */
export async function getLoginToken(): Promise<?string> {
	const response = await axios.get(config.loginServer + '/login-token', { withCredentials: true })

	return response.data?.token
}
