// @flow
import { combineReducers, type CombinedReducer } from 'redux'

import simulationReducer from './simulation'
import missionPrepReducer from './missionPrep'
import questionReducer from './question'
import categoryReducer from './categories'

import type {
	MissionPrepStore,
	SimulationsStore,
	QuestionsStore,
	CategoryStore,
	Action,
} from './types'

export type ReduxStore = {|
	simulations: SimulationsStore,
	missionPrep: MissionPrepStore,
	questions: QuestionsStore,
	categories: CategoryStore,
|}

export type GetState = () => ReduxStore

export default function createRootReducer(): CombinedReducer<ReduxStore, Action> {
	return combineReducers<*, Action>({
		simulations: simulationReducer,
		missionPrep: missionPrepReducer,
		questions: questionReducer,
		categories: categoryReducer,
	})
}
