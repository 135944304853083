import React from 'react'
import { LightTheme as theme } from '../styles/theme'
import { Table } from './basics'
import { View, Text } from '@react-pdf/renderer'
import styles from './sharedStyles'
import {
	getColorForProficiencyLevel,
	getTextColorForProficiencyLevelBackground,
} from './sharedStyles'
import {
	getCategoriesForControlSet,
	type Category,
} from '../routes/analytics/StudentPage/constants'

import { isFinite } from 'lodash'
import { getFullName } from '../utility/helpers'
import type { NameLookup } from './questions'
import type { ClassStudentAnalytics } from '@mission.io/mission-toolkit'
import type { SelScoresByEntityId } from '../models/Analytics'
import { scoreFormatEnum, type ScoreFormatType } from '../routes/analytics/constants'
import { translateScoreToProficiencyLevel } from '../routes/analytics/AnalyticsCharts/ProficiencyBarGraph/constants'
import { useGameScores } from '../routes/analytics/AnalyticsCharts/hooks.js'

/**
 * Gets rows for the sel score table: each row representing a student and their sel scores
 */
function getRows(individualSelScores: SelScoresByEntityId, getName: string => string) {
	const individuals = Object.keys(individualSelScores)
	const rows = individuals.map(nameId => {
		const selScore = individualSelScores[nameId]
		return {
			...selScore,
			nameId,
			key: nameId,
			name: getName(nameId),
		}
	})
	rows.sort((a, b) => (a.name < b.name ? -1 : 1))
	return rows
}
const NAStyle = {
	color: theme.neutralDark,
	fontWeight: '200',
}

/**
 * A component to render an sel score, if the score doesn't exist it renders a grayed out 'NA' string
 */
const renderSelScorePercent = (score: ?number) => {
	return (
		<Text
			style={{
				...(!isFinite(score) && NAStyle),
			}}>
			{!isFinite(score) ? 'NA' : score}
		</Text>
	)
}

const renderProficiencyLevel = (score: ?number) => {
	const level = translateScoreToProficiencyLevel(score)
	if (level) {
		return (
			<View
				style={{
					backgroundColor: getColorForProficiencyLevel(level),
					borderRadius: '7.5pt',
					width: '15pt',
					height: '15pt',
					margin: 'auto',
				}}>
				<Text
					style={{
						color: getTextColorForProficiencyLevelBackground(level),
						fontWeight: '400',
						marginTop: 2,
					}}>
					{level}
				</Text>
			</View>
		)
	} else {
		return <Text style={NAStyle}>NA</Text>
	}
}

/**
 * Gets columns for the sel score table: each column representing a score category which a student can be rated by.
 */
const getColumns = (
	individualSelScores: SelScoresByEntityId,
	getName: string => string,
	scoreFormat: ScoreFormatType,
	categories: Category[]
) => {
	const columns = []
	const renderSelScore =
		scoreFormat === scoreFormatEnum.PERCENT ? renderSelScorePercent : renderProficiencyLevel
	columns.push({
		// eslint-disable-next-line react/display-name
		Header: () => (
			<Text style={styles.td}>
				<Text style={{ paddingLeft: '8px' }}>Name</Text>
			</Text>
		),
		accessor: element => {
			const name = getName(element.nameId)
			return <Text>{name}</Text>
		},
		id: 'name',
	})
	categories.forEach(category => {
		columns.push({
			// eslint-disable-next-line react/display-name
			Header: style => {
				const backgroundColor = theme.primary
				return (
					<Text
						style={[
							styles.td,
							{
								backgroundColor,
								color: theme.white,
							},
						]}>
						{category.title}
					</Text>
				)
			},
			id: category.key,
			accessor: element => renderSelScore(category.accessor(element)),
		})
	})
	return columns
}

/**
 * A component which renders all the students and their sel scores in the PDF.
 */
export const IndividualSELPerformance = ({
	individualSelScores,
	nameLookup,
	scoreFormat,
	controlSet,
}: {
	individualSelScores: SelScoresByEntityId,
	nameLookup: NameLookup,
	scoreFormat: ScoreFormatType,
	controlSet: ?string,
}): React$Node => {
	const getName = (nameId: string) => {
		return getFullName(nameLookup[nameId]) || 'Unknown'
	}
	const categories = getCategoriesForControlSet(controlSet)
	const columns = getColumns(individualSelScores, getName, scoreFormat, categories)
	const rows = getRows(individualSelScores, getName)
	return (
		<View>
			<Text style={[styles.pageH5]}>Individual Student SEL Performance</Text>
			<Table columns={columns} rows={rows} />
		</View>
	)
}

const gameScoreCardCellStyle = {
	padding: '8px',
	flex: 1,
	textAlign: 'center',
	borderRight: `1pt solid ${theme.neutral}`,
}
const gameScoreValueStyle = {
	fontSize: '12pt',
}

/**
 * Displays mission scores in the PDF
 */
export function GameScoreCard({
	classAnalytics,
}: {
	classAnalytics: ?ClassStudentAnalytics,
}): React$Node {
	const [finalScore, averageStudentPoints] = useGameScores(classAnalytics)
	const shipDeaths = 2 // classAnalytics?.shipDeaths
	const scoreToText = (score: ?number): string => {
		if (score == null) {
			return 'NA'
		}
		return Math.round(score).toLocaleString('en-US')
	}
	return (
		<View style={[styles.grid, styles.card]}>
			<Text style={[styles.h1, { textAlign: 'center', width: '100%' }]}>Game Score</Text>
			<View style={gameScoreCardCellStyle}>
				<Text>Total Mission Score:</Text>
				<Text style={gameScoreValueStyle}>{scoreToText(finalScore)}</Text>
			</View>
			<View style={gameScoreCardCellStyle}>
				<Text>Average Student Score:</Text>
				<Text style={gameScoreValueStyle}>{scoreToText(averageStudentPoints)}</Text>
			</View>
			{shipDeaths && (
				<View style={gameScoreCardCellStyle}>
					<Text>Total Revivals:</Text>
					<Text style={gameScoreValueStyle}>{scoreToText(shipDeaths)}</Text>
				</View>
			)}
		</View>
	)
}
