import React from 'react'

import ReactSelect from 'react-select'
import { AppReactSelectClassNames, AppReactSelectTheme } from './styles'

/**
 * A light wrapper around react-select to make it match the Mission.io brand guide.
 * TODO: Move this component to the @mission.io/styles package
 */
export function Select({ className, ...props }: { className?: string }): React$Node {
	return (
		<ReactSelect
			{...props}
			className={className}
			theme={AppReactSelectTheme}
			classNames={AppReactSelectClassNames}
		/>
	)
}
