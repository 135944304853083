import { ALPHABET } from '../constants'

import type { StateKey } from '../types'
import { STATE_KEYS } from '../constants'
import { HIDDEN_DOWNLOAD_LINK_CLASS_NAME } from '../styles/globalStyle'
type Key = string

/**
 * The array reduce function implemented for object
 * @param  {Object}   object   The object
 * @param  {Function} callback The callback function
 */
export function reduceObject<U, T>(
	object: { [Key]: T },
	callback: (previousValue: U, currentValue: T, Key) => U,
	initialValue: U
): U {
	let previousValue = initialValue

	for (let key in object) {
		if (object.hasOwnProperty(key)) {
			previousValue = callback(previousValue, object[key], key)
		}
	}

	return previousValue
}

/**
 * capitalizes every word in a string
 * @param  {String}   str   the string to capitalize
 * @param  {boolean} lower  convert the string to lower case the string before capitalizing
 */
export function capitalizeEveryWord(str: string, lower: boolean = false): string {
	return (lower ? str.toLowerCase() : str).replace(/(?:^|\s)\S/g, function(a) {
		return a.toUpperCase()
	})
}

/**
 * Shuffles array in place. ES6 version
 * @param {Array} a items An array containing the items.
 */
export function shuffle<T>(a: T[]): T[] {
	var j, x, i
	for (i = a.length - 1; i > 0; i--) {
		j = Math.floor(Math.random() * (i + 1))
		x = a[i]
		a[i] = a[j]
		a[j] = x
	}
	return a
}

/**
 * round a number to the given decimal place
 * @param  {number} num          the number to round
 * @param  {number} decimalPlace the decimal place to round to
 * @return {number}              the rounded number
 */
export function round(num: number, decimalPlace: number): number {
	let rounded = Math.round(num * 10 ** decimalPlace) / 10 ** decimalPlace
	return rounded
}

/**
 * change a proportion/probability (0 ≤ number ≤ 1) into a percentage
 * @param  {number} proportion   the proportion to change into a percentage
 * @param  {number} decimalPlace the decimal place to round to
 * @return {number}              percentage
 */
export function toPercent(proportion: number, decimalPlace: number = 0): number {
	return round(proportion * 100, decimalPlace)
}

/**
 * Given a hex string and an opacity percentage (number between 0 and 1), will convert to rgba value for that string.
 * Helpful for lightening constant colors.
 * @param {string} hex
 * @param {number} percent between 0 and 1
 */
export const rgba = (hex: string, percent: number): string => {
	const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
	if (result) {
		const { r, g, b } = {
			r: parseInt(result[1], 16),
			g: parseInt(result[2], 16),
			b: parseInt(result[3], 16),
		}
		return `rgba(${r}, ${g}, ${b}, ${percent})`
	}
	return ''
}

/**
 * Run a callback on each own key value pair in object
 * @param  {Object}   object   The object
 * @param  {Function} callback The callback function
 */
export function forEachEntry<T, K: string>(
	object: $ReadOnly<{ [K]: T }>,
	callback: (T, K) => void
): void {
	for (let key in object) {
		if (object.hasOwnProperty(key)) {
			// $FlowFixMe key will be of type K
			callback(object[key], key)
		}
	}
}

/**
 * Iterates over each value in an object and runs a callback on that value.
 * @param {Object} object The object whose values are to be iterated
 * @param {Function} callback The callback to be called on each value in `object`.
 *                              For each call, it is passed one argument which is
 *                              one of the values in `object`.
 */
export function forEachValue<S>(object: { [Key]: S }, callback: S => any) {
	for (let key in object) {
		if (object.hasOwnProperty(key)) {
			callback(object[key])
		}
	}
}

/**
 * The array filter function implemented for object. The resulting object from
 * this function will have all entries from the original object that pass the test
 * provided by callback.
 * @param  {Object}   object   The object
 * @param  {Function} callback The callback function
 */
export function filterObjectToArray<T>(
	object: { [Key]: T },
	callback: (currentValue: T) => boolean
): Array<T> {
	let result: T[] = []
	for (let key in object) {
		if (object.hasOwnProperty(key)) {
			if (callback(object[key])) {
				result.push(object[key])
			}
		}
	}

	return result
}

/**
 * The array filter function implemented for object. The resulting object from
 * this function will have all entries from the original object that pass the test
 * provided by callback.
 * @param  {Object}   object   The object
 * @param  {Function} callback The callback function
 */
export function filterObject<T>(
	object: { [Key]: T },
	callback: (currentValue: T, Key) => boolean
): { [Key]: T } {
	const result = {}
	for (let key in object) {
		if (object.hasOwnProperty(key)) {
			if (callback(object[key], key)) {
				result[key] = object[key]
			}
		}
	}
	return result
}

/**
 * Finds the first entry that passes the test implemented by the provided function
 * @param {Object} object The object containing the entries to be tested
 * @param {Function} callback The callback function
 */
export function findEntry<T>(object: { [Key]: T }, callback: (T, Key) => boolean): ?T {
	for (let key in object) {
		if (object.hasOwnProperty(key)) {
			if (callback(object[key], key)) return object[key]
		}
	}
	return null
}

/**
 * Object.values function, but with the flow type maintained.
 */
export function values<T>(object: { [Key]: T }): T[] {
	const values = []

	for (let key in object) {
		if (object.hasOwnProperty(key)) {
			values.push(object[key])
		}
	}

	return values
}

/**
 * A basic debounce function found at https://gist.github.com/peduarte/7ee475dd0fae1940f857582ecbb9dc5f
 * @param  {Function} func The function to debounce
 * @param {number}    wait How long to wait after the last call before running the debounced function
 * @return {Function}      The debounced function
 */
export function debounce<T: Array<mixed>>(
	func: (...args: T) => void,
	wait?: number = 100
): (...args: T) => void {
	let timeout
	return function(...args: T) {
		clearTimeout(timeout)
		timeout = setTimeout(() => {
			func.apply(this, args)
		}, wait)
	}
}

/**
 * Takes the question index, and returns the correct preceding letter,
 * modding it if necessary
 * @param {number}    -question Index
 * @return {string}  - get the letter associate with number passed
 */
export function getQuestionOptionIndex(index: number): string {
	const alphabetArrayLength = ALPHABET.length
	return ALPHABET[index % alphabetArrayLength]
}

export function getLocationStateFor(id: string, key: StateKey): { [StateKey]: string } {
	// $FlowFixMe[invalid-computed-prop] Key is a string
	return { [key]: id }
}

/**
 * Gets a value from the given `state` object at the given `key`. Only allows keys that are in `STATE_KEYS`.
 */
export function getValueFromState(state: mixed, key: StateKey): ?string {
	if (state && typeof state === 'object') {
		return typeof state[key] === 'string' && STATE_KEYS[key] ? state[key] : null
	}
}

/**
 * Effect that scrolls the page all the way to the top
 * when we change tabs on dashboard.
 * We set scrollRestoration to manual so that the browser
 * does not try to restore the scroll to where it was on a page change.
 */
export function scrollToTop(): void {
	window.scrollTo(0, 0)
}

/**
 * toIdMap - convert an array of elements to a lookup by the `_id` value
 *
 * @param  {T[]} data - the data to convert to a lookup
 *
 * @returns IdMap<T> - the lookup
 */
export function toIdMap<T: { _id: string }>(data: T[]): IdMap<T> {
	const idMap = {}
	data.forEach(dat => (idMap[dat._id] = dat))
	return idMap
}

/**
 * downloadFile - download a file to the users computer
 *
 * @param {string} fileUrl - the url of the file to download
 * @param {string} fileName - the name of the file to download
 */
export function downloadFile(fileUrl: string, fileName: string): void {
	const anchorTag = document.createElement('a')
	anchorTag.href = fileUrl
	anchorTag.download = fileName
	anchorTag.className = HIDDEN_DOWNLOAD_LINK_CLASS_NAME

	document.body?.append(anchorTag)
	anchorTag.click()
	document.body?.removeChild(anchorTag)
}
