import React from 'react'
import { useMutation, useQueryClient } from 'react-query'

import axios from 'axios'
import { Button, type ButtonProps } from '@mission.io/styles'
import { toast } from 'react-toastify'
import 'styled-components/macro'
import config from '../../config'
import { USER_QUERY_KEY } from './../../services/hooks/user'

import type { User } from '../../store/types'
import { useQueryNotifications } from '../../utility/hooks'

const thirdPartyLogins = [
	{
		name: 'Google',
		logo: 'https://resources-cdn.mission.io/public/logos/icons/google--color.svg',
	},
	{
		name: 'Clever',
		logo: 'https://resources-cdn.mission.io/public/logos/icons/clever--color.svg',
	},
]

/**
 * Displays an error corresponding to the "linkError" query param in the url. Removes the query param
 * if its value is in `LINK_ERROR_DESCRIPTIONS`
 */

/**
 * A set of buttons for linking/unlinking third party login providers.
 */
export function ServiceSignIn({ user }: { user: User }): React$Node {
	useQueryNotifications('linkError')
	return (
		<div css="font-weight: 500;">
			Connected Accounts
			<p className="mb-2">Select a service to sign in with.</p>
			<div css="display: flex; gap: var(--spacing1x-dont-use);">
				{thirdPartyLogins.map(({ name, logo }) => {
					const isConnected = user.linkedAccounts.some(
						account => account.type === name.toLowerCase()
					)
					return (
						<ThirdPartyButton
							key={name}
							imageSrc={logo}
							imageAlt={`${name}`}
							service={name.toLowerCase()}
							isConnected={isConnected}>
							{isConnected ? `Disconnect ${name}` : `Connect ${name}`}
						</ThirdPartyButton>
					)
				})}
			</div>
		</div>
	)
}

/**
 * Button to connect/disconnect a third party login provider. When `props.isConnected` is true, it will
 * disconnect the service account. When `props.isConnected` is false, it will attempt to link the service account.
 */
function ThirdPartyButton({
	service,
	imageSrc,
	imageAlt,
	isConnected,
	children,
}: {
	service: string,
	imageSrc: string,
	imageAlt: string,
	isConnected: boolean,
	children: React$Node,
}): React$Node {
	const queryClient = useQueryClient()
	const { mutate: unlinkAccount, isLoading: unlinkAccountIsLoading } = useMutation(
		() => {
			return axios
				.post(`${config.loginServer}/account/unlink?provider=${service}`, null, {
					withCredentials: true,
				})
				.then(res => res.data?.preferences)
		},
		{
			onSuccess: () => queryClient.invalidateQueries(USER_QUERY_KEY),
			onError: () => {
				toast.error(`Failed to disconnect ${service}`)
			},
		}
	)
	const extraProps: ButtonProps = {}
	if (isConnected) {
		extraProps.onClick = () => {
			unlinkAccount()
		}
	} else {
		// $FlowFixMe[incompatible-type] flow wants 'as' to be 'button' but 'a' is acceptable.
		extraProps.as = 'a'
		extraProps.href =
			config.loginServer +
			'/auth/' +
			service.toLowerCase() +
			'?link=true&sendto=' +
			window.location.href
	}
	return (
		<Button
			{...extraProps}
			disabled={unlinkAccountIsLoading}
			outline
			variant="secondary"
			css="line-height: normal; height: 36px; display: inline-flex; align-items: center;">
			<img
				src={imageSrc}
				alt={imageAlt}
				css="width: 20px; height: 20px; margin-right: 8px;"
			/>
			{children}
		</Button>
	)
}
