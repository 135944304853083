import React from 'react'
import { Dropdown } from '../../../components'
import type { AutomatedSimulation } from '../../../models'
import Markdown from '../../../components/Markdown'
import { useUserStandardState } from './../../../services/hooks/user'
import { getMostRelevantStandards } from './../../../utility/standards'
import { Leaderboard, useLeaderboard } from './Leaderboard'
import type { StandardSet } from '../../../utility/standards'
import { useStandardSets } from '../../../services/hooks/standards'
import { LEADERBOARD_STATUS, type SimulationLeaderboard } from '@mission.io/mission-toolkit'

type MissionTabsProps = {
	simulation: AutomatedSimulation,
}

/**
 * A set of tabs to display information about a mission on the mission page.
 */
export default function MissionTabs({ simulation }: MissionTabsProps): React$Node {
	const standardState = useUserStandardState()
	const { data: leaderboard } = useLeaderboard(simulation?._id)
	const { data: standardSets } = useStandardSets()
	const tabs = getDropdowns(simulation, standardState, leaderboard, standardSets || [])

	return (
		<div className="flex flex-col mt-3 ">
			{tabs.map(tab => (
				<Dropdown
					className="mb-3"
					headerClassName="font-bold text-xl"
					header={tab.tabTitle}
					content={tab.contentComponent}
					key={tab.key}
					startsOpen={tab.startsOpen}
				/>
			))}
		</div>
	)
}

/**
 * getDropdowns - get a list of dropdowns to display
 *
 * @param  {AutomatedSimulation} simulation - the simulation to get the dropdowns for
 * @param  {?string} standardState - the state for standards to show
 * @param  {?SimulationLeaderboard} leaderboard - the leaderboard for the simulation
 */
function getDropdowns(
	simulation: AutomatedSimulation,
	standardState: ?string,
	leaderboard: ?SimulationLeaderboard,
	standardSets: StandardSet[]
) {
	const dropdowns = [
		{
			// we have been asked to call narration Storyline
			contentComponent: <Markdown className="p-4">{simulation.narration}</Markdown>,
			key: '1',
			tabTitle: 'Storyline',
			startsOpen: true,
		},
	]

	const relevantStandards = getMostRelevantStandards({
		stateAbbreviation: standardState,
		standards: simulation.standards,
		fallback: true,
		standardSets,
	})
	if (relevantStandards.length > 0) {
		dropdowns.push({
			contentComponent: (
				<div className="p-4 prose prose-invert max-w-none">
					{relevantStandards.map(standard => (
						<p key={standard.name}>
							<Markdown>{standard.full}</Markdown>
						</p>
					))}
				</div>
			),
			key: '2',
			tabTitle: 'Targeted Standards',
			startsOpen: false,
		})
	}
	if (
		simulation.science3D.phenomenon ||
		simulation.science3D.scienceAndEngineeringPractices.length > 0 ||
		simulation.science3D.crosscuttingConcepts.length > 0 ||
		simulation.science3D.disciplinaryCoreIdeas.length > 0
	) {
		dropdowns.push({
			contentComponent: <Science3DContent science3D={simulation.science3D} />,
			key: '3D_Science',
			tabTitle: '3-Dimensional Science',
		})
	}

	if (simulation.crossCurricularConnections) {
		dropdowns.push({
			contentComponent: (
				<Markdown className="p-4">{simulation.crossCurricularConnections}</Markdown>
			),
			key: '3',
			tabTitle: 'Cross-Curricular Connections',
			startsOpen: false,
		})
	}

	if (
		leaderboard &&
		(leaderboard.order.length || leaderboard.status !== LEADERBOARD_STATUS.AVAILABLE)
	) {
		dropdowns.push({
			contentComponent: <Leaderboard leaderboard={leaderboard} />,
			key: '4',
			tabTitle: 'Leaderboard',
			startsOpen: false,
		})
	}

	return dropdowns
}

/**
 * The content to show in the 3D science tab
 */
function Science3DContent({
	science3D,
}: {
	science3D: $PropertyType<AutomatedSimulation, 'science3D'>,
}) {
	return (
		<div className="p-4 prose prose-invert max-w-none">
			<div>
				<h3 className="mt-0">Phenomenon</h3>
				{science3D.phenomenon ? (
					<Markdown>{science3D.phenomenon}</Markdown>
				) : (
					<p>No phenomenon is associated with this mission.</p>
				)}
			</div>
			<div>
				<h3>Science and Engineering Practices</h3>
				{science3D.scienceAndEngineeringPractices.length === 0 && (
					<p>No science and engineering practices are associated with this mission.</p>
				)}
				{science3D.scienceAndEngineeringPractices.map(practice => (
					<>
						<h4>{practice.practiceText}</h4>
						<Markdown>{practice.description}</Markdown>
					</>
				))}
			</div>
			<div>
				<h3>Crosscutting Concepts</h3>
				{science3D.crosscuttingConcepts.length === 0 && (
					<p>No crosscutting concepts are associated with this mission.</p>
				)}
				{science3D.crosscuttingConcepts.map(concept => (
					<>
						<h4>{concept.conceptText}</h4>
						<Markdown>{concept.description}</Markdown>
					</>
				))}
			</div>
			<div>
				<h3>Disciplinary Core Ideas</h3>
				{science3D.disciplinaryCoreIdeas.length === 0 && (
					<p>No disciplinary core ideas are associated with this mission.</p>
				)}
				{science3D.disciplinaryCoreIdeas.map(idea => (
					<>
						<h4>{idea.ideaText}</h4>
						<Markdown>{idea.description}</Markdown>
					</>
				))}
			</div>
		</div>
	)
}
