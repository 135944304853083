// @flow
import React from 'react'
import { useEffect, useState } from 'react'
import FaClose from 'react-icons/lib/fa/close'
import FaClock from 'react-icons/lib/fa/clock-o'
import classnames from 'classnames'
import { sendVerificationEmail } from '../services/networkCalls'
import { useUserClasses } from '../services/hooks/classes'
import useUser, { useHasUnverifiedRole, useIsUserVerified } from '../services/hooks/user'
import { Button } from '@mission.io/styles'
import { toast } from 'react-toastify'
import type { User } from '../store/types'
import { Link, useLocation } from 'react-router-dom'
import { config } from '../config'
import Modal, { ModalBody, ModalFooter, ModalHeader } from './modal/Modal'
import { useIsFreeAccount } from '../services/hooks/permissions'
import { sendEmail } from '../services/emailService'
import { usePermissions } from '../services/hooks'
import { PROMOTION_TYPES } from '../utility/types'

type BannerData = {
	name: string, // Used as the React key
	type: 'notify' | 'warn' | 'danger' | 'halloween',
	content: React$Node,
}

const initialBanners: Array<BannerData> = []

/**
 * Displays all app banners that should be shown to the user.
 */
export function Banners(): React$Node {
	const { user } = useUser()
	const [banners, setBanners] = useState<Array<BannerData>>(initialBanners)

	return (
		<>
			{user ? <LoggedInBanners user={user} /> : <SignInBanner />}
			{banners.map(({ name, type, content }) => (
				<Banner
					key={name}
					className={classnames(
						type === 'halloween' && 'bg-[orange]',
						type === 'notify' && 'bg-sky-blue text-primary-950',
						type === 'warn' && 'bg-warning text-primary-950',
						type === 'danger' && 'bg-error'
					)}
					onClose={() => {
						setBanners(banners => banners.filter(banner => banner.name !== name))
					}}>
					{content}
				</Banner>
			))}
		</>
	)
}

/**
 * A banner that displays across the whole screen. The onClose function is called when the user clicks the X button.
 */
function Banner({
	children,
	onClose,
	className,
}: {
	children: React$Node,
	onClose: () => mixed,
	className?: string,
}): React$Node {
	return (
		<div className={className}>
			<div className="p-2 mx-auto md:w-4/5 xl:w-3/4 flex items-center">
				<button
					onClick={onClose}
					className="me-2 hover:scale-125 active:scale-110 transition-transform">
					<FaClose size="20" aria-label="close" />
				</button>
				{children}
			</div>
		</div>
	)
}

/**
 * The main page to show when the user is logged in. This component will not show until the user is available.
 * @param {User} props.user The user object
 */
function LoggedInBanners({ user }: { user: User }) {
	const isRolePending: boolean = useHasUnverifiedRole()
	const isAccountVerified: boolean = useIsUserVerified()
	// start loading the classes
	useUserClasses()

	const [showIsRolePendingMessage, setShowIsRolePendingMessage] = useState(isRolePending)
	const [showVerifyAccountMessage, setShowVerifyAccountMessage] = useState(!isAccountVerified)

	return (
		<>
			<FreeAccountBanner />
			{showIsRolePendingMessage && (
				<Banner
					className="bg-star text-space-blue"
					onClose={() => setShowIsRolePendingMessage(false)}>
					Your request for admin privileges is under review.
				</Banner>
			)}
			<PromotionsBanner />
			{showVerifyAccountMessage && (
				<Banner
					className="bg-primary-50 text-primary-950"
					onClose={() => setShowVerifyAccountMessage(false)}>
					Your account has not been verified. Click “Send Email” so we can send you a
					verification email.
					<Button
						className="ml-6"
						onClick={() => {
							const email = user?.email
							if (!email) {
								toast.error('You have no email linked to your account!', {
									position: toast.POSITION.TOP_RIGHT,
								})
								return
							}
							sendVerificationEmail(
								email,
								message => {
									toast.success(message, {
										position: toast.POSITION.TOP_RIGHT,
									})
									setShowVerifyAccountMessage(false)
								},
								errorMessage =>
									toast.error(errorMessage, {
										position: toast.POSITION.TOP_RIGHT,
									})
							)
						}}>
						Send Email
					</Button>
				</Banner>
			)}
		</>
	)
}

/**
 * A banner informing people that they are not signed in.
 */
function SignInBanner(): React$Node {
	const [showBanner, setShowBanner] = useState(true)

	const routerLocation = useLocation()

	return (
		showBanner && (
			<Banner onClose={() => setShowBanner(false)} className="bg-sun text-white">
				<span>
					You are not currently logged in.{' '}
					<a
						className="underline"
						href={`${config.loginClientUrl}?sendto=${window.location.origin +
							routerLocation.pathname}`}>
						Sign In
					</a>{' '}
					for the full experience!
				</span>
			</Banner>
		)
	)
}

/**
 * Component that creates a Banner informing people that they are using a free account.
 * It includes a modal so users can email Mission.io and ask for an upgrade.
 */
function FreeAccountBanner(): React$Node {
	const shouldShowBanner = useIsFreeAccount()
	const { user } = useUser()
	const email = user?.email
	const [showBanner, setShowBanner] = useState(shouldShowBanner)
	const [showModal, setShowModal] = useState(false)
	const [emailBody, setEmailBody] = useState('')
	const [school, setSchool] = useState(user?.schoolName)

	useEffect(() => {
		setShowBanner(shouldShowBanner)
	}, [shouldShowBanner])

	return (
		showBanner && (
			<>
				<Banner className="bg-deep-blue" onClose={() => setShowBanner(false)}>
					You are currently using {config.companyName.withIndefiniteArticle} trial
					account. Reach out to upgrade to the full experience!
					<Button className="ml-6" onClick={() => setShowModal(true)}>
						Contact Us
					</Button>
				</Banner>
				<Modal
					style={{ content: { width: '55%' } }}
					shouldCloseOnOverlayClick
					isOpen={showModal}
					onRequestClose={() => setShowModal(false)}>
					<ModalHeader>Let’s Chat!</ModalHeader>
					<ModalBody className="mx-auto w-4/5">
						What would you like to know about upgrading? We will send you an email.
						<textarea
							className="border border-space-blue mt-4 w-full outline-none"
							autoFocus
							required
							rows={7}
							maxLength={2500}
							onChange={e => setEmailBody(e.target.value)}
						/>
						{school && (
							<label className="flex mt-2">
								My School:
								<input
									className="flex-1 ml-2"
									type="text"
									value={school}
									onChange={e => setSchool(e.target.value)}
								/>
							</label>
						)}
					</ModalBody>
					<ModalFooter>
						<Button outline className="mr-4" onClick={() => setShowModal(false)}>
							Cancel
						</Button>
						<Button
							onClick={() => {
								if (!emailBody) {
									toast.warning('Please enter in text for the email', {
										position: toast.POSITION.TOP_RIGHT,
									})
									return
								}
								if (!email) {
									toast.error('Please correct your email address', {
										position: toast.POSITION.TOP_RIGHT,
									})
									return
								}
								sendEmail(
									{
										senderEmail: email,
										emailText:
											emailBody +
											`\n\nSchool input by User: ${String(school)}`,
										emailType: 'LICENSE_QUESTIONS',
									},
									() => {
										toast.success('Email Sent!', {
											position: toast.POSITION.TOP_RIGHT,
										})
										setShowBanner(false)
									},
									() => {
										toast.error('Email failed to send', {
											position: toast.POSITION.TOP_RIGHT,
										})
									},
									() => {
										toast.error(
											'Failed to send email. Too many emails have been sent in a short amount of time.',
											{
												position: toast.POSITION.TOP_RIGHT,
											}
										)
									}
								)

								setShowModal(false)
							}}>
							Send
						</Button>
					</ModalFooter>
				</Modal>
			</>
		)
	)
}

/**
 * PromotionsBanner - a banner detailing the promotions that the user has on their account
 */
function PromotionsBanner(): React$Node {
	const { permissions } = usePermissions()
	const [showBanner, setShowBanner] = useState<null | boolean>(null)

	const currentDate = new Date()
	const activePromotions = permissions?.promotions?.filter(
		({ expirationDate }) => expirationDate > currentDate
	)

	useEffect(() => {
		if (showBanner != null || activePromotions == null) {
			return
		}
		setShowBanner(Boolean(activePromotions.length))
	}, [activePromotions, showBanner])

	if (!activePromotions?.length || !showBanner) {
		return null
	}

	let accountCreationPromotion = null
	let nonAccountCreationPromotions = []

	for (let promotion of activePromotions) {
		if (promotion.type === PROMOTION_TYPES.FREE_ACCOUNT_CREATION_PROMOTION) {
			// find the longest lasting account creation promotion
			if (
				!accountCreationPromotion ||
				accountCreationPromotion.expirationDate < promotion.expirationDate
			) {
				accountCreationPromotion = promotion
			}
			continue
		}
		nonAccountCreationPromotions.push(promotion)
	}

	return (
		<Banner className="bg-star text-space-blue" onClose={() => setShowBanner(false)}>
			<FaClock className="mr-1" />
			{!accountCreationPromotion ? (
				<>
					You have&nbsp;
					<Link to="/profile" className="underline">
						{nonAccountCreationPromotions.length} active promotion
						{nonAccountCreationPromotions.length === 1 ? '' : 's'}
					</Link>
					! Enjoy your limited time expanded access.
				</>
			) : (
				<>
					Your full access trial will end on{' '}
					{new Date(accountCreationPromotion.expirationDate).toLocaleDateString()}.{' '}
					{nonAccountCreationPromotions.length ? (
						<>
							(
							<Link to="/profile" className="underline">
								{nonAccountCreationPromotions.length} other promotion
								{nonAccountCreationPromotions.length === 1 ? '' : 's'} active
							</Link>
							)
						</>
					) : null}
				</>
			)}
		</Banner>
	)
}
