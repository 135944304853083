import React from 'react'
import styled from 'styled-components/macro'
import classnames from 'classnames'
import { Tooltip } from 'react-tooltip'
import { primaryColor, Button, PRIMARY_LIGHTNESS_BRIGHT, PRIMARY_LIGHTNESS_DULL } from './shared'

const TOOLTIP_ID = 'auto_demo_step_progress_tooltip_id'

type Props = {
	steps: Array<{
		stepId: string,
		name: string,
	}>,
	onClickStep: (id: string) => mixed,
	currentPathSelectStepId: string,
}

/**
 * A component that shows all items in `steps` as points on a line. Clicking on a step will cause `onClickStep` to
 * be called with that step's id.
 * @param {Array} steps The steps to display in the StepProgress
 * @param {Function} onClickStep A callback that is called when one of the steps is clicked
 */
export const StepProgress: React$AbstractComponent<Props, HTMLElement> = React.forwardRef(
	function StepProgress({ steps, onClickStep, currentPathSelectStepId }: Props, ref): React$Node {
		const horizontalPadding = 'var(--spacing3x-dont-use)'
		return (
			<div
				css={`
					position: absolute;
					bottom: 0;
					width: 100%;
					padding: var(--spacing2x-dont-use) ${horizontalPadding};
					z-index: 1;
				`}
				ref={ref}>
				<StepButtonContainer $totalStepCount={steps.length}>
					{steps.map(step => (
						<StepButton
							key={step.stepId}
							data-tooltip-content={step.name}
							data-tooltip-id={TOOLTIP_ID}
							onClick={() => onClickStep(step.stepId)}
							aria-label={step.name}
							className={classnames({
								active: currentPathSelectStepId === step.stepId,
							})}
						/>
					))}
				</StepButtonContainer>
				<Tooltip id={TOOLTIP_ID} effect="solid" />
			</div>
		)
	}
)

const buttonSize = '1em'
const lineWidth = '3px'

/**
 * A button component with a line after it (as an ::after pseudo element) that connects to the next step
 */
const StepButton = styled(Button)`
	--primary-lightness: ${PRIMARY_LIGHTNESS_BRIGHT}%;
	width: ${buttonSize};
	height: ${buttonSize};
	padding: 0;
	border-radius: 50%;
	background-color: ${primaryColor};
	border: none;
	justify-content: start;

	&.active {
		// Give the active step a soft glow
		box-shadow: 0 0 10px 3px ${primaryColor};
	}

	/****
	 * Line after the button, except for the last button
	 ****/
	&:not(:last-child):after {
		content: '';
		position: absolute;
		top: calc((100% - ${lineWidth}) / 2);
		height: ${lineWidth};
		z-index: -1;
		// Start all lines with active styles
		background: ${primaryColor};
		// Duplicate --primary-lightness here so that line color doesn't change when its button is hovered
		--primary-lightness: ${PRIMARY_LIGHTNESS_BRIGHT}%;
	}

	// INACTIVE STYLES
	// The line after the active step
	&.active:after,
	// All steps after the active step
	&.active ~ &,
	// Lines after all steps that are after the active step
	&.active ~ &:not(:last-child):after {
		--primary-lightness: ${PRIMARY_LIGHTNESS_DULL}%;
	}
`

const StepButtonContainer = styled.div`
	position: relative;
	display: flex;
	justify-content: space-between;
	align-items: center;

	// Line width, aka the distance between buttons
	${StepButton}:not(:last-child):after {
		width: calc(100% / ${({ $totalStepCount }) => $totalStepCount - 1});
	}
`
