import { type Step as StepType } from 'react-joyride'
import React from 'react'
import { WelcomeModal } from './customComponents'
import {
	GestureBottomLeftClap,
	GestureDownThumbs,
	GestureLeft,
	GestureTopRight,
} from './customComponents/variationsOnRalf'
import { StationTrainingCompleteDialog } from './customComponents/StationTrainingCompleteDialog'
import HypeVideoModal from './customComponents/HypeVideoModal'
import { HIDE, SEE_MISSIONS_ID } from '../../routes/mission/MissionReview'
import type { User } from '../../store/types'
import { config } from '../../config'

type NextOptions = {
	locationPathname?: ?string,
	user?: ?User,
}

/**
 * Each step declares a step in the walkthrough. Unless otherwise specified the step will be rendered using ./customComponents/Popup.js,
 * In order to add a step there are a few requirements:
 *
 * React Tooltip:
 * -target: we use the data attribute 'data-walkthrough' on components throughout the app that we want walkthrough tooltips to point to.
 * -content: the text content for the step, or a react element (useful if text needs to be formatted).
 *
 * Custom:
 * -id: the id for the step. used to reference the step when determining order of steps and which steps to render (see useSteps.js)
 *
 * Optional Custom properties:
 * -next: the id of the next step. If not specified, the walkthrough will end after this step. Note: More steps can be added in the hook useSteps.js based on user interactions.
 * -image: an svg react element that will be rendered in the tooltip for decor.
 * -onClickTargetActions: sometimes we want to move the walkthrough along without clicking the "next" button, by clicking on specific elements within the targeted element of the step.
 * 		Rules for the onClickTargetActions:
 * 			- you must set step.spotlightClicks property to true in order for joyride to allow click events to occur within the target.
 *  		- targets specified in this array must either be the step.target or children of the step.target (react joyride only allows click events to occur within the target)
 * 			- to guide the user to click on the target, hide the next button of the tooltip by setting the property step.hideFooter to true.
 * 			- You can specify which step clicking a target will lead you to (back, forward, or to a determined step id).
 * - delayTooltip: If set, when we get to this step the tooltip will wait for the specified amount of time before displaying.
 * - sendsFinishTourInteraction: If set, when the user finishes this step, we will send an interaction to the server to indicate that the user has finished the tour.
 * - runBefore: a function that will be called to run before the step is displayed.
 * - getNext: overrides whatever the 'next' property contains. Useful when which step you display next is conditional upon some piece of inconsistent data
 * - hideNextButton: if you'd like to hide the next button in Popup
 * IE:
 * step = { id: 'FIRST,
 * 			next: 'SECOND',
 * 			target: '.parent-div',
 * 			onClickTargetActions: [{type: 'NEXT', target: '.cool-child-of-parent'}],
 * 			content: 'Click on the cool child to move to the next step.'
 * 			spotlightClicks: true,
 * 			hideFooter: true
 * }
 */
export type Step = {
	id: string,
	next?: string,
	onClickTargetActions?: Array<
		| { type: 'GO_TO', stepId: string, target: string }
		| { type: 'NEXT', target: string }
		| { type: 'BACK', target: string }
	>,
	image?: React$Element<any>,
	delayTooltip?: number,
	sendsFinishTourInteraction?: boolean,
	runBefore?: () => void,
	getNext?: (nextOptions: NextOptions) => string,
	hideNextButton?: boolean,
} & StepType

export const INTRODUCTORY_STEP_COUNT = 2

const PREPPED_MISSIONS_SHARED_STEP_INFO = {
	disableBeacon: true,
	image: (
		<GestureDownThumbs
			style={{
				transform: 'translate(-80px, -12px)',
				height: '175px',
			}}
		/>
	),
	content:
		'This is where you will find all of your prepped and saved missions. When your students are ready, just launch the mission.',
}

const Steps: { [string]: Step } = {
	BEFORE_WE_CLOSE: {
		id: 'BEFORE_WE_CLOSE',
		target: 'button[data-walkthrough="help-menu"]',
		content: 'Leaving early? If you ever want to do the walkthrough again, click right here! ',
		disableBeacon: true,
		hideBackButton: true,
		image: <div />,
		locale: { last: 'OK, thanks!' },
	},
	// First time logins
	WELCOME_NEWBIES: {
		id: 'WELCOME_NEWBIES',
		target: '#root',
		tooltipComponent: WelcomeModal,
		title: `Welcome to ${config.companyName.base}!`,
		content: `We are glad to have you on board! We know jumping into a spaceship can be intimidating, but we have you covered. Let us show you around and get you started.`,
		placement: 'center',
		locale: { next: 'Let‘s go' },
		styles: { tooltip: { width: 400 } },
		next: 'SELECT_CLASS_TAB',
	},
	WELCOME: {
		id: 'WELCOME',
		target: '#root',
		tooltipComponent: WelcomeModal,
		title: `Welcome to ${config.companyName.base}!`,
		content: `We're thrilled to have you join our community of growing revolutionary educators. We know you're eager to explore our program. Watch this quick video and get ready to experience the magic of ${config.companyName.base}!`,
		placement: 'center',
		next: 'WATCH_MOVIE',
	},
	WATCH_MOVIE: {
		id: 'WATCH_MOVIE',
		target: '#root',
		tooltipComponent: HypeVideoModal,
		title: '',
		placement: 'center',
		content: '',
		styles: { tooltip: { width: 400 } },
		next: 'MISSION_LIBRARY_TAB',
		getNext: ({ locationPathname }) => {
			if (locationPathname === '/missionLibrary' || locationPathname === '/')
				return 'MISSION_LIBRARY'
			return 'MISSION_LIBRARY_TAB'
		},
	},
	MISSION_LIBRARY_TAB: {
		id: 'MISSION_LIBRARY_TAB',
		target: 'a[data-walkthrough$="missionLibrary-tab"]',
		content: (
			<>
				Let‘s start by checking out
				<br />
				the <b>Mission Library.</b>
			</>
		),
		hideBackButton: true,
		hideNextButton: true,
		spotlightClicks: true,
		placement: 'bottom-end',
		styles: {
			tooltipContent: { marginLeft: 16 },
			tooltipFooter: { marginLeft: 48, marginTop: -8 },
		},
		image: <GestureLeft style={{ transform: 'translate(-75px, -38px)' }} />,
		onClickTargetActions: [
			{ type: 'NEXT', target: 'a[data-walkthrough$="missionLibrary-tab"]' },
		],
		next: 'MISSION_LIBRARY',
		showProgress: true,
	},
	MISSION_LIBRARY: {
		id: 'MISSION_LIBRARY',
		target: 'div[data-walkthrough*="Training"]',
		styles: {
			tooltipContent: { marginLeft: 8 },
			tooltip: { width: 440 },
			tooltipFooter: { marginLeft: 32 },
		},
		content: (
			<>
				Mission library is where your adventure begins. To get started, launch any of the
				station training missions that match your grade level.
				<br />
				<br />
				<i>
					<b>Warning</b>: Once you launch a mission, the students will beg for more.
				</i>
			</>
		),
		image: <GestureBottomLeftClap style={{ transform: 'translate(-72px, -28px)' }} />,
		next: 'SELECT_CLASS_TAB',
		showProgress: true,
	},
	SELECT_CLASS_TAB: {
		id: 'SELECT_CLASS_TAB',
		target: 'a[data-walkthrough$="classes-tab"]',
		content: <>The flexibility to add and manage your classes is always at your fingertips.</>,
		placement: 'bottom-end',
		disableScrolling: true,
		floaterProps: { hideArrow: true },
		image: <GestureTopRight style={{ transform: 'translate(-64px, -50px)' }} />,
		getNext: ({ user }) => {
			if (user) return 'PREPPED_MISSIONS'
			return ''
		},
		next: 'PREPPED_MISSIONS',
		showProgress: true,
		locale: { last: 'Finish' },
	},
	PREPPED_MISSIONS: {
		id: 'PREPPED_MISSIONS',
		target: 'div[data-walkthrough="prepped-missions"]',
		spotlightClicks: false,
		// this step either happens after an animation, or after a route change. This delay makes it less janky than letting react-joyride put the tooltip/spotlight in the wrong place
		delayTooltip: 750,
		...PREPPED_MISSIONS_SHARED_STEP_INFO,
		placement: 'left-start',
		hideBackButton: true,
		locale: { last: 'Finish' },
		sendsFinishTourInteraction: true,
		styles: {
			tooltipFooter: { marginLeft: 32 },
		},
		showProgress: true,
		runBefore: () => {
			if (
				document.getElementById(SEE_MISSIONS_ID)?.innerText?.toLowerCase() !==
				HIDE.toLowerCase()
			)
				document.getElementById(SEE_MISSIONS_ID)?.click()
		},
	},
	// Shown when the user has completed the training mission
	COMPLETED_TRAINING_MISSION: {
		id: 'COMPLETED_TRAINING_MISSION',
		target: '#root',
		tooltipComponent: StationTrainingCompleteDialog,
		title: 'Station Training Complete!',
		placement: 'center',
		content: 'replaced in StationTrainingCompleteDialog',
		hideFooter: true,
	},
}

export default Steps
