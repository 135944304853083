import styled from 'styled-components'
import React from 'react'
import Modal from '../../../components/modal'
import type { StyledType } from 'global-types'

const Full: StyledType<> = styled.div`
	width: 100% !important;
	height: 100% !important;
`

const ModalStyle: StyledType<> = styled(Modal)`
	width: ${props => (props.type === 'SCORE_INFO' ? '35% !important' : '50% !important')};
	height: fit-content !important;
	.overlay {
		background-color: rgba(220, 220, 220, 0.75);
	}
`

/**
 * A styled modal for showing extra info for analytics.
 * @returns {React$Node}
 */
export const StyledModal = ({ children, ...props }: { children: ?React$Node }): React$Node => {
	return (
		<ModalStyle {...props}>
			<Full className="p-0 m-0">{children}</Full>
		</ModalStyle>
	)
}
