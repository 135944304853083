import { createGlobalStyle } from 'styled-components'
import {
	CUSTOM_CLASS_NAME_ACTIONABLE_TOAST,
	CUSTOM_CLASS_NAME_ACTIONABLE_TOAST_BODY,
} from '../constants'
import type { ThemeType } from '@mission.io/styles'

export const HIDDEN_DOWNLOAD_LINK_CLASS_NAME = 'HIDDEN_DOWNLOAD_LINK_CLASS_NAME'

const globalStyle: React$ComponentType<mixed> = createGlobalStyle`

// New Constants can be defined here without defining a less variable
${({ theme }: { theme: ThemeType }) => `
	:root {
		--app-light-gray: ${theme.neutral};
		--app-lightest-gray: ${theme.neutralLight};
		--app-gray: ${theme.neutral};
		--app-dark-gray: ${theme.neutralDark};
		--app-almost-black: ${theme.darkBackground};

		--app-box-shadow-1:  0px -1px 14px #999;

		--light-text: #999;

		--app-color-combo-3: ${theme.accent};


		--primary: ${theme.primary};
		--primary-dark: ${theme.primaryDark};

		--secondary: ${theme.secondary};
		--secondary-light: ${theme.secondaryLight};
		--secondary-dark: ${theme.secondaryDark};

		--accent: ${theme.accent};
		--accent-light: ${theme.accentLight};
		--accent-dark: ${theme.accentDark};

		--success: ${theme.success};
		--warning: ${theme.warning};
		--danger: ${theme.error};

		// Use tailwind classes for spacing instead of these
		--spacing-half-dont-use: 4px;
		--spacing1x-dont-use: 8px;
		--spacing2x-dont-use: 16px;
		--spacing3x-dont-use: 24px;
		--spacing4x-dont-use: 32px;
		--spacing5x-dont-use: 40px;

		// Toastify overrides
		--toastify-icon-color-info: ${theme.info};
		--toastify-icon-color-success: ${theme.success};
		--toastify-icon-color-warning: ${theme.warning};
		--toastify-icon-color-error: ${theme.error};

		// // Sidebar
		--sidebar-width: 225px;
	}

	.${CUSTOM_CLASS_NAME_ACTIONABLE_TOAST} {
		border: none !important;
		padding: 0px !important;
		.Toastify__toast-icon {
			display: none;
		}
	}

	.${CUSTOM_CLASS_NAME_ACTIONABLE_TOAST_BODY} {
		padding: 0px !important;
	}

	body {
		margin: 0;
		padding: 0;
		min-height: 100vh;
		overflow-x: hidden;
		width: 100vw;
	}


	html {
		scrollbar-color: rgba(255,255,255,.5) #333;
	}


	// General app wide styles

	.bottom-margin {
		margin-bottom: var(--spacing2x-dont-use);
	}

	.nav-link:hover {
		cursor: pointer;
	}

	.page-title {
		margin-top: 0;
	}

	.layout-row {
		display: flex;
		flex-direction: row;
	}

	.layout-column {
		display: flex;
		flex-direction: column;
	}

	.flex-1 {
		flex: 1;
	}

	.space-between {
		display: flex;
		justify-content: space-between;
	}

	.flex-end {
		justify-content: flex-end;
	}

	.page-description {
		font-size: 14px;
		color: var(--light-text);
	}

	.note {
		color: var(--light-text);
		font-style: italic;
	}

	.margin-right-20 {
		margin-right: 20px;
	}

	.btn-primary,
	.btn-secondary {
		cursor: pointer;
		display: inline-block;
		padding: 6px 12px;
		margin-bottom: 0;
		font-size: 14px;
		font-weight: 400;
		text-align: center;
		white-space: nowrap;
		vertical-align: middle;
		background-image: none;
		border: 1px solid transparent;
		border-radius: 4px;
		margin-right: 5px;



		&.lg {
			padding: 8px 22px;
		}

		&.sm {
			padding: 4px 10px;
			font-size: 12px;
		}

		&.xsm {
			padding: 2px 8px;
			font-size: 10px;
		}

		&.row {
			margin-top: 10px;
			width: 100%;
			height: 50px;
		}
	}

	.btn-primary {
		background-color: ${theme.primary} !important;
		border-color: ${theme.primary} !important;
		color: ${theme.white} !important;

		&:hover {
			background-color: ${theme.primaryDark} !important;
			border-color: ${theme.primaryDark} !important; 
		}
	}

	.btn-secondary {
		background-color: ${theme.secondary} !important;
		border-color: ${theme.secondary} !important;
		color: ${theme.darkBackground} !important;

		&:hover {
			background-color: ${theme.secondaryLight} !important;
			border-color: ${theme.secondaryLight} !important; 
		}
	}

	.btn-primary[disabled] {
		opacity: 0.65;
	}

	.print {
		display: none;
	}

	@media print {
		.print {
			display: block;
			padding: 20px;
		}
		.page {
			transform: rotate(-90deg);
			-webkit-transform: rotate(-90deg);
			-moz-transform: rotate(-90deg);
			filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=3);
		}
	}

	.primary {
		color: ${theme.primary};
	}

	.green, .success {
		color: ${theme.success};
	}

	.red {
		color: ${theme.error};
	}

	.gray {
		color: var(--app-gray);
	}

	.${HIDDEN_DOWNLOAD_LINK_CLASS_NAME} {
		visibility: hidden;
	}
`}
`
export default globalStyle
