// @flow
import React, { useState, useEffect, useRef } from 'react'
import Modal from 'react-modal'
import useUser, { useIsLicenseAdmin, useUserPreference } from './services/hooks/user'
import { Route, Switch } from 'react-router-dom'
import styled, { keyframes } from 'styled-components'
import useSize from '@react-hook/size'
import { Header } from './components/header/Header'
import UserGuide from './components/UserGuide/UserGuide'
import {
	LeaderBoard,
	MissionLibraryMain,
	PrepMission,
	Profile,
	Classes,
	Resources,
	Questions,
	Trainings,
	Analytics,
	AdminPage,
	MissionResultsPage,
} from './routes'
import { AutoDemo } from './routes/autoDemo/AutoDemo'
import { AUTO_DEMO_ROUTE } from './constants'
import config from './config'

import type { User } from './store/types'
import FileViewer from './components/FileViewer/FileViewer'
import FileBrowser from './components/FileBrowser/FileBrowser'
import Standards from './routes/standards/Standards'
import { useSessionEndedPopup } from './components/popups'
import { AccountDataVerificationStatusModal } from './components/AccountDataVerificationModal/AccountDataVerificationModal'
import { useIsMobile } from './utility/hooks'
import FaError from 'react-icons/lib/fa/exclamation-circle'
import { useUserSimulationPermissions } from './services/hooks/permissions'
import { useStandardSets } from './services/hooks/standards'

const APP_WRAPPER_ID = 'app-wrapper'

export const SEEN_ENOUGH = 5000
/**
 * The main app. Displays a loading page if the user is not available yet, otherwise shows the rest of the site
 */
export default function Main(): React$Node {
	const [hasSeenVideo, setHasSeenVideo] = useState(false)
	const [hasFullyLoadedAtLeastOnce, setHasFullyLoadedAtLeastOnce] = useState(false) // do not block the screen or unmount components if the data we first loaded gets invalidated
	const isMobile = useIsMobile()

	// data to load before showing the page
	const { user, isLoading: isLoadingUser } = useUser()
	const { isLoading: isLoadingSimulationPermissions } = useUserSimulationPermissions()
	const { isLoading: isLoadingStandardSets } = useStandardSets()
	const { isLoading: isLoadingUserPreferences } = useUserPreference()

	useEffect(() => {
		if (config.featureFlags.showPageLoadingVideo) {
			setTimeout(() => {
				setHasSeenVideo(true)
			}, SEEN_ENOUGH)
		}
	}, [])

	useEffect(() => {
		if (
			!hasFullyLoadedAtLeastOnce &&
			!(
				isLoadingUser ||
				isLoadingSimulationPermissions ||
				isLoadingStandardSets ||
				isLoadingUserPreferences
			)
		) {
			setHasFullyLoadedAtLeastOnce(true)
		}
	}, [
		isLoadingUser,
		isLoadingSimulationPermissions,
		isLoadingStandardSets,
		hasFullyLoadedAtLeastOnce,
		isLoadingUserPreferences,
	])

	return (
		<Switch>
			<Route path={AUTO_DEMO_ROUTE}>
				<AutoDemo />
			</Route>
			<Route>
				{errorMessage ? (
					<CenterError>
						<StyledErrorMessageWrapper>
							<FaError /> {errorMessage}
						</StyledErrorMessageWrapper>
					</CenterError>
				) : (
					<>
						{(hasFullyLoadedAtLeastOnce && hasSeenVideo) ||
						!config.featureFlags.showPageLoadingVideo ? (
							<MainContent user={user} />
						) : (
							<>
								<LoadingVideo
									autoPlay={true}
									loop={true}
									onClick={e => {
										e.stopPropagation()
									}}
									muted={true}
									playsInline={true}>
									<source
										src={
											isMobile
												? 'https://resources-cdn.mission.io/public/videos/dashboard_loading_portrait.mp4'
												: 'https://resources-cdn.mission.io/public/videos/dashboard_loading_landscape.mp4'
										}
										type="video/mp4"
									/>
								</LoadingVideo>
								<LoadingTextWrapper>
									<div className="text-4xl z-10 font-medium uppercase italic text-center text-[--primary]">
										Loading Mission Dashboard<DotOne>.</DotOne>
										<DotTwo>.</DotTwo>
										<DotThree>.</DotThree>
									</div>
								</LoadingTextWrapper>
							</>
						)}
					</>
				)}
			</Route>
		</Switch>
	)
}

function MainContent({ user }: { user: ?User }) {
	const isAdminOnLicense = useIsLicenseAdmin()
	useSessionEndedPopup()

	useEffect(() => {
		Modal.setAppElement(`#${APP_WRAPPER_ID}`)
	}, [])

	const headerRef = useRef(null)
	// header height can be referenced as the css variable `--header-height` throughout the app
	const [, headerHeight] = useSize(headerRef)

	return (
		<div id={APP_WRAPPER_ID} style={{ '--header-height': String(headerHeight) + 'px' }}>
			<AccountDataVerificationStatusModal />
			<FileViewer />
			<FileBrowser />
			<Header ref={headerRef} />
			<UserGuide />

			<main className="w-full mx-auto md:w-4/5 xl:w-3/4">
				<Switch>
					<Route exact path="/" component={MissionLibraryMain} />
					<Route path="/missionLibrary" component={MissionLibraryMain} />
					<Route path="/prep/:id" component={PrepMission} />
					<Route path="/profile" component={Profile} />
					<Route path="/classes" component={Classes} />
					<Route path="/questionGroups/:id?" component={Questions} />
					<Route path="/resources" component={Resources} />
					<Route path="/training" component={Trainings} />
					<Route path="/standards" component={Standards} />
					<Route path="/analytics" component={Analytics} />
					<Route path="/leaderboards" component={LeaderBoard} />
					<Route path="/end-of-mission-results/:id?" component={MissionResultsPage} />
					{isAdminOnLicense && <Route path="/admin" component={AdminPage} />}
				</Switch>
			</main>
		</div>
	)
}

const LoadingVideo = styled.video`
	width: 100%;
	height: 100%;
	object-fit: cover;
	position: absolute;
	top: 0;
	left: 0;
`

const LoadingTextWrapper = styled.div`
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
	display: flex;
	align-items: center;
	justify-content: center;
`

const dot = keyframes`
      0% { opacity: 0; }
     50% { opacity: 0; }
    100% { opacity: 1; }
`

const DotOne = styled.span`
	opacity: 0;
	animation: ${dot} 2s infinite;
	animation-delay: 0s;
`

const DotTwo = styled.span`
	opacity: 0;
	animation: ${dot} 2s infinite;
	animation-delay: 0.3s;
`

const DotThree = styled.span`
	opacity: 0;
	animation: ${dot} 2s infinite;
	animation-delay: 0.6s;
`

const errorMessage: ?string = null

const CenterError = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
`
const StyledErrorMessageWrapper = styled.div`
	color: var(--danger);
	max-width: 40em;
`
