import React from 'react'

import FunFactViewer from '../../components/dashboardModules/LeaderBoardAnalytics/FunFactViewer'
import { useLeaderBoardAnalytics } from '../../components/dashboardModules/LeaderBoardAnalytics/LeaderBoardAnalyticsHooks'
import { ScoresList } from '../../components/dashboardModules/LeaderBoardAnalytics/ScoresList'
import { HIGHEST_USAGE } from '../../components/dashboardModules/LeaderBoardAnalytics/constants'
import { getAnalyticsDataByViewId } from '../../components/dashboardModules/LeaderBoardAnalytics/helpers'
import classnames from 'classnames'

/**
 * Displays the usage data on the analytics page. This includes any `funFacts` provided and the highest usage scores.
 *
 * @param funFacts An array of funFacts to display
 * @param schoolId The schoolId to use to get the highest usage scores. If not provided, the usage analytics request will be sent without a schoolId
 */
export function UsageData({
	funFacts,
	schoolId,
	showHighestUsageTable,
}: {|
	funFacts: Array<{ title: string, value: number }>,
	schoolId?: string,
	showHighestUsageTable: boolean,
|}): React$Node {
	const { data: leaderBoardAnalytics, isLoading } = useLeaderBoardAnalytics(schoolId)

	const renderedFunFacts = (
		<div
			className={classnames(
				'grid grid-cols-2 gap-2 md:gap-4',
				!showHighestUsageTable &&
					(funFacts.length === 2 ? 'md:flex md:justify-evenly' : 'md:grid-cols-4')
			)}>
			{funFacts.map(({ title, value }) => (
				<FunFactViewer key={title} value={value} title={title} />
			))}
		</div>
	)

	return showHighestUsageTable ? (
		<div className="grid grid-cols-1 gap-2 sm:grid-cols-2 md:gap-4 items-start mb-4">
			{renderedFunFacts}
			<ScoresList
				type={HIGHEST_USAGE}
				scores={getAnalyticsDataByViewId(leaderBoardAnalytics, HIGHEST_USAGE)}
				isLoading={isLoading}
			/>
		</div>
	) : (
		renderedFunFacts
	)
}
