import React from 'react'
import { View, Text, Image, Link } from '@react-pdf/renderer'
import { LightTheme as theme } from '../styles/theme'
import { Table } from './basics'
import styles from './sharedStyles'
import { type Score } from '@mission.io/question-toolkit'
import type { ClassStudentAnalytics, AnalyticsQuestionResponse } from '@mission.io/mission-toolkit'
import { type DBQuestion } from '../models/Question'
import { type QuestionColumn } from '../routes/analytics/AnalyticsCharts/hooks'
import { getFullName } from '../utility/helpers'
import { QUESTION_ATTEMPTS_TO_SHOW_ON_TABLE } from '../constants'

export type QuestionMap = { [questionKey: string]: DBQuestion }
export type NameLookup = { [studentId: string]: { firstName: string, lastName: string } }

export type DownloadQuestionsConfig = {|
	anchorPrefix: string,
	sectionHeader: string,
	questionTableHeader: string,
	individualResponseTableHeader: string,
	individualStudentResponsesHeader: string,
	noResponseText: string,
	questionPrefix: string,
|}
export const QUESTIONS_DOWNLOAD_DATA: DownloadQuestionsConfig = {
	anchorPrefix: 'question_anchor',
	sectionHeader: 'Curriculum Questions',
	questionTableHeader: 'Curriculum Questions - % Class Answered Correctly (First Attempts)',
	individualResponseTableHeader: 'Individual Student Curriculum Performance',
	individualStudentResponsesHeader: 'Individual Student Curriculum - Responses',
	noResponseText: 'No questions were answered',
	questionPrefix: 'Q',
}
export const CULMINATING_DOWNLOAD_DATA: DownloadQuestionsConfig = {
	anchorPrefix: 'culminating_moment_anchor',
	sectionHeader: 'Culminating Moment Questions',
	questionTableHeader: 'Culminating Moments - % Class Answered Correctly (First Attempts)',
	individualResponseTableHeader: 'Individual Student Culminating Moment Performance',
	individualStudentResponsesHeader: 'Individual Student Culminating Moment - Responses',
	noResponseText: 'No culminating moments were answered',
	questionPrefix: 'CM',
}

export const Check = (): React$Node => {
	return (
		<View
			style={{
				display: 'flex',
				flexDirection: 'row',
				justifyContent: 'center',
			}}>
			<Image src="icons/checkmark.png" style={{ height: '10pt', width: '10pt' }} />
		</View>
	)
}
export const Close = (): React$Node => {
	return <Text style={{ color: 'red', fontSize: '10pt', fontWeight: 'bold' }}>X</Text>
}
export const Unknown = (): React$Node => {
	return <Text style={{ fontSize: '10pt', fontWeight: 'bold', color: 'black' }}>?</Text>
}

/**
 *A component to display a student's response to a question.
 */
const QuestionStudentResponse = ({ score }: { score: ?Score }) => {
	return score && score.score === score.perfectScore ? (
		<Check />
	) : score && score.score !== null && score.score < score.perfectScore ? (
		<Close />
	) : (
		<Unknown />
	)
}
/**
 * AttemptJoiner - the separator that appears between score icons on the question table
 */
const AttemptJoiner = () => {
	return (
		<Text
			style={{
				fontSize: '10pt',
				fontWeight: 'bold',
				color: '#aaa',
			}}>
			{' > '}
		</Text>
	)
}
/**
 * QuestionResponseAttemptsIcons - shows a list of score icons for the attempts the students made on questions
 *
 * @param  {{responses:AnalyticsQuestionResponse[]}} responses  - the student responses for a question
 *
 * @return React$Node
 */
const QuestionResponseAttemptsIcons = ({
	responses,
}: {
	responses: AnalyticsQuestionResponse[],
}) => {
	if (!responses?.length) {
		return null
	}
	if (responses.length <= QUESTION_ATTEMPTS_TO_SHOW_ON_TABLE) {
		return (
			<View style={styles.questionTableCell}>
				{responses
					.map(({ score }, index) => (
						<QuestionStudentResponse score={score} key={index} />
					))
					.map((node, index) => {
						if (index !== responses.length - 1) {
							return (
								<>
									{node}
									<AttemptJoiner key={`${index}-joiner`} />
								</>
							)
						}
						return node
					})}
			</View>
		)
	}
	return (
		<View style={styles.questionTableCell}>
			<QuestionStudentResponse score={responses[0].score} />
			<AttemptJoiner />
			<Text
				style={{
					fontSize: '10pt',
					fontWeight: 'bold',
					color: 'black',
				}}>
				{'...'}
			</Text>
			<AttemptJoiner />
			<QuestionStudentResponse score={responses[responses.length - 1].score} />
		</View>
	)
}

/**
 * Gets all the rows (each row representing a student and their responses to questions on a mission) for the student question responses table
 */
const getRows = (
	classAnalytics: ClassStudentAnalytics,
	nameLookup: NameLookup,
	studentIds?: string[]
): {
	studentId: string,
	key: string,
	name: string,
}[] => {
	const rows = []
	const studentIdsToUse = studentIds || Object.keys(classAnalytics.studentAnalytics || {})
	studentIdsToUse.forEach(studentId => {
		rows.push({
			studentId: studentId,
			key: studentId,
			name: getFullName(nameLookup[studentId]) || 'Unknown',
		})
	})
	rows.sort((a, b) => (a.name < b.name ? -1 : 1))
	return rows
}

/**
 * Gets all the columns (each column representing a question asked on the mission to the group of students) for the student question responses table
 */
const getColumns = (
	questionColumns: QuestionColumn[],
	questionMap: QuestionMap,
	classAnalytics: ClassStudentAnalytics,
	config: DownloadQuestionsConfig
) => {
	if (!questionColumns || !questionMap) return []
	const columns = []
	columns.push({
		// eslint-disable-next-line react/display-name
		Header: () => {
			return <Text style={[styles.td]}>Name</Text>
		},
		// eslint-disable-next-line react/display-name
		accessor: element => <Text>{element.name}</Text>,
		id: `name`,
	})
	questionColumns.forEach((questionColumn: QuestionColumn, index: number) => {
		columns.push({
			// eslint-disable-next-line react/display-name
			Header: () => (
				<Link
					src={`#${config.anchorPrefix}${index}`}
					style={[
						styles.td,
						{
							backgroundColor: theme.primary,
							color: theme.white,
						},
					]}>
					<Text>{`${config.questionPrefix}${index + 1}`}</Text>
				</Link>
			),
			// eslint-disable-next-line react/display-name
			accessor: element => {
				return (
					<QuestionResponseAttemptsIcons
						responses={questionColumn.studentAnswerLookup[element.studentId] ?? []}
					/>
				)
			},
			id: `${index}`,
		})
	})
	columns.push({
		// eslint-disable-next-line react/display-name
		Header: () => {
			return <Text style={[styles.td]}>Totals</Text>
		},
		// eslint-disable-next-line react/display-name
		accessor: element => {
			let questionsAnsweredCorrectly = 0
			let gradedQuestions = 0
			questionColumns.forEach(questionColumn => {
				const firstQuestionAttempt =
					questionColumn.studentAnswerLookup[element.studentId]?.[0]
				if (firstQuestionAttempt?.score?.score == null) {
					return
				} else {
					gradedQuestions += 1
					questionsAnsweredCorrectly += firstQuestionAttempt.score.score
				}
			})
			return (
				<Text>
					{gradedQuestions
						? `${Math.ceil(
								// $FlowFixMe[unsafe-addition] we know that questionsAnsweredCorrectly is a number because isFinite() is true
								(questionsAnsweredCorrectly / gradedQuestions) * 100
						  )}%`
						: 'N/A'}
				</Text>
			)
		},
		id: `totals`,
	})
	return columns
}

/**
 * Displays all the student questions responses in a table in the PDF
 */
export const QuestionResponses = ({
	classAnalytics,
	questionColumns,
	questionMap,
	nameLookup,
	config,
}: {
	classAnalytics: ClassStudentAnalytics,
	questionColumns: QuestionColumn[],
	questionMap: QuestionMap,
	nameLookup: NameLookup,
	config: DownloadQuestionsConfig,
}): React$Node => {
	const studentsRespondedToQuestions = new Set()
	questionColumns.forEach((column: QuestionColumn) => {
		Object.keys(column.studentAnswerLookup).forEach((studentId: string) =>
			studentsRespondedToQuestions.add(studentId)
		)
	})
	const rows = getRows(classAnalytics, nameLookup, Array.from(studentsRespondedToQuestions))
	if (!rows.length) {
		return (
			<View>
				<Text style={[styles.h5]}>{config.noResponseText}</Text>
			</View>
		)
	}
	const columns = getColumns(questionColumns, questionMap, classAnalytics, config)

	return (
		<View>
			<Text style={[styles.h5]} fixed>
				{config.individualResponseTableHeader}
			</Text>
			<Table columns={columns} rows={rows} />
		</View>
	)
}

// Displays the percentage of the class that answered each question correctly.
export const QuestionTotalsTable = ({
	classQuestionScores,
	config,
}: {
	classQuestionScores: string[],
	config: DownloadQuestionsConfig,
}): React$Node => {
	const columns = classQuestionScores.map((score, index) => ({
		Header: () => (
			<Link
				src={`#${config.anchorPrefix}${index}`}
				style={[
					styles.td,
					{
						backgroundColor: theme.primary,
						color: theme.white,
					},
				]}>
				<Text>{`${config.questionPrefix}${index + 1}`}</Text>
			</Link>
		),
		accessor: (element: { name: string, key: string, scores: string[] }) => (
			<Text>{element.scores[index] ?? 'N/A'}</Text>
		),
		id: `${index}`,
	}))
	return (
		<View>
			<Text style={[styles.h5]} fixed>
				{config.questionTableHeader}
			</Text>
			<Table
				columns={columns}
				rows={[{ name: 'Total', key: 'totals', scores: classQuestionScores }]}
			/>
		</View>
	)
}
