// @flow
import React, { useMemo, useState, useEffect } from 'react'
import styled from 'styled-components'
import CustomModal, {
	ModalHeader,
	ExitX,
	ModalBody,
	ModalFooter,
} from '../../../components/modal/Modal'
import { useSimulationPermissions } from '../../../services/hooks/permissions'
import { sendEmail } from '../../../services/emailService'
import useUser from '../../../services/hooks/user'
import type { User } from '../../../store/types'
import { Button } from '@mission.io/styles'
import { toast } from 'react-toastify'
import { config } from '../../../config'
import type { SimulationPermissions } from '../../../services/hooks/permissions'

type PrepMissionModalProps = {
	simulationId: string,
	onRequestClose: () => void,
}

const SmallModal = styled(CustomModal)`
	max-width: max(60vw, min(700px, 100vw));
	min-width: min(700px, 100vw);
`
// A detailed description of how to unlock the a simulation based on the unlock type
const UNLOCK_MODAL = {
	PAID_LICENSE: function PaidLicenseModal({
		onRequestBack,
		onRequestClose,
		simulationPermissions,
	}: {
		onRequestClose: () => void,
		onRequestBack: () => void,
		simulationPermissions: ?SimulationPermissions,
	}) {
		const { user } = useUser()
		return (
			<SmallModal isOpen={true} onRequestClose={onRequestClose}>
				<ModalHeader>
					Upgrade {config.companyName.base} Account
					<ExitX
						role={'button'}
						title={'Close Select Mission Type Modal'}
						onClick={onRequestBack}
					/>
				</ModalHeader>
				<Body>
					<p>
						{simulationPermissions?.userCanOnlyRunDueToPromotion == null ? (
							<>
								In order to fly this mission you will need to upgrade your{' '}
								{config.companyName.base} Account.
							</>
						) : (
							<>
								You can currently run this mission due to a promotion. If you would
								like to run this mission after the promotion ends{' '}
								{simulationPermissions.userCanOnlyRunDueToPromotion.expires
									? `(${simulationPermissions.userCanOnlyRunDueToPromotion.expires.toLocaleString()})`
									: null}{' '}
								you will have to upgrade your account.
							</>
						)}{' '}
						Once you upgrade, you will have access to all of the currently published
						missions as well as any missions published in the future while your license
						is active.
					</p>
					<p>
						If you are interested in learning more about upgrading, please select
						contact us and we will start an email dialogue with you.
					</p>
				</Body>
				<ModalFooter>
					<TextButton onClick={() => onRequestBack()}>Cancel</TextButton>
					<Button
						onClick={() => {
							sendLicenseUpgradeInquiryEmail(user)
							onRequestClose()
						}}>
						Contact Us
					</Button>
				</ModalFooter>
			</SmallModal>
		)
	},
}

// a brief listable description of how to unlock a simulation based on the unlock type
const UNLOCK_LIST_COMPONENTS = {
	PAID_LICENSE: function PaidLicense({ openModal }: { openModal: (modalType: string) => void }) {
		return (
			<div className="relative flex flex-row justify-between items-center size-full shadow p-6 bg-white mt-2 hover:brightness-90">
				Upgrade to a paid license.{' '}
				<Button onClick={() => openModal('PAID_LICENSE')}>Upgrade License</Button>
			</div>
		)
	},
}

/**
 * UnlockDirectionsModel - show directions for unlocking a simulation
 *
 * @param {() => void} {onRequestClose} - a callback used to close the modal
 * @param {string} simulationId - the id of the simulation to show unlocking directions for
 *
 * @return {React$Node} - a react component
 */
export default function UnlockDirectionsModel({
	onRequestClose,
	simulationId,
}: PrepMissionModalProps): React$Node {
	const simulationPermissions = useSimulationPermissions(simulationId)
	const [modalKey, setModalKey] = useState<?string>()
	const { availableSelections } = useMemo(() => {
		if (!simulationPermissions) {
			return {
				availableSelections: null,
			}
		}
		return {
			availableSelections: simulationPermissions.unlockMethods.filter(key =>
				UNLOCK_LIST_COMPONENTS.hasOwnProperty(key)
			),
		}
	}, [simulationPermissions])

	// if there is only one way to unlock a simulation bypass the list screen and go straight to those directions
	useEffect(() => {
		if (
			availableSelections &&
			availableSelections.length === 1 &&
			UNLOCK_MODAL.hasOwnProperty(availableSelections[0])
		) {
			setModalKey(availableSelections[0])
		}
	}, [availableSelections])

	const OpenModal = modalKey && UNLOCK_MODAL[modalKey]

	if (!availableSelections) {
		return null
	}

	if (OpenModal) {
		return (
			<OpenModal
				simulationPermissions={simulationPermissions}
				onRequestClose={onRequestClose}
				onRequestBack={() => {
					if (availableSelections.length > 1) {
						setModalKey(null)
						return
					}
					onRequestClose()
				}}
			/>
		)
	}

	// there is no way to unlock the simulation
	if (!availableSelections.length) {
		return (
			<SmallModal isOpen={true} onRequestClose={onRequestClose}>
				<ModalHeader>
					Mission Unavailable
					<ExitX
						role={'button'}
						title={'Close Select Mission Type Modal'}
						onClick={onRequestClose}
					/>
				</ModalHeader>
				<Body>This mission is currently unavailable to all users.</Body>
				<ModalFooter>
					<Button onClick={onRequestClose}>Close</Button>
				</ModalFooter>
			</SmallModal>
		)
	}

	// there are multiple ways to unlock the simulation
	return (
		<CustomModal isOpen={true} onRequestClose={onRequestClose}>
			<ModalHeader>
				To Unlock This Mission:
				<ExitX
					role={'button'}
					title={'Close Select Mission Type Modal'}
					onClick={onRequestClose}
				/>
			</ModalHeader>
			<Body>
				<Cards>
					{availableSelections.map((type: string, index: number) => {
						const Component = UNLOCK_LIST_COMPONENTS[type]
						return (
							<>
								<Component key={index} openModal={setModalKey} />
								{index !== availableSelections.length - 1 ? (
									<div className="text-xl text-center">— or —</div>
								) : null}
							</>
						)
					})}
				</Cards>
			</Body>
		</CustomModal>
	)
}

const Body = styled(ModalBody)`
	${({ theme }) => `
        padding: var(--spacing3x-dont-use);
	`}
`

const Cards = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-evenly;
`

const TextButton = styled.button`
	background-color: Transparent;
	border-width: 0;
	color: ${({ theme }) => theme.primary};
	margin-right: var(--spacing2x-dont-use);
`

/**
 * sendLicenseUpgradeInquiryEmail - send a user upgrade inquiry email to the sales team
 *
 * @param {?User} user - the user inquiring about the license upgrade
 */
function sendLicenseUpgradeInquiryEmail(user: ?User) {
	if (!user) {
		toast.error('Could contact sales because we could not find the user.')
		return
	}
	sendEmail(
		{
			senderEmail: user.email,
			emailText: '',
			emailType: 'LICENSE_UPGRADE_INQUIRY',
		},
		() => {
			toast.success(
				<div>
					<h4>Inquiry Sent</h4>
					<p>
						Thank you for wanting to know more about upgrading! We will contact you by
						email shortly.
					</p>
				</div>,
				{
					position: toast.POSITION.TOP_RIGHT,
				}
			)
		},
		() => {
			toast.error('Failed to make inquiry', {
				position: toast.POSITION.TOP_RIGHT,
			})
		},
		() => {
			toast.error(
				'Failed to make inquiry. Too many emails have been sent in a short amount of time.',
				{
					position: toast.POSITION.TOP_RIGHT,
				}
			)
		}
	)
}
