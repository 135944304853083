// @flow
import React, { useState, useEffect, useCallback } from 'react'
import { useQuery } from 'react-query'
import { toast } from 'react-toastify'
import { getSchoolName, getClassValidationErrorMessage, getNonEmptyStudents } from './helpers'
import { ACTIONABLE_TOAST_PROPERTIES, CustomModal, SimpleConfirmation } from '../../components'
import SchoolPicker from './SchoolPicker'
import { gradeLabelMap, classGradeOptions } from './constants'
import { Button } from '@mission.io/styles'
import MultiStudentEditor from './MultiStudentEditor'
import {
	useEditClass,
	type UpdateClass,
	usePossibleOwners,
	getClassOwnerName,
} from '../../services/hooks/classes'

import type { ClassType } from '../../models/ClassType'
import type { Student_New, NewStudent as NewStudentType } from '../../models/Student'
import type { GradeEnum } from '../../store/missionPrep'
import type { GradeOption, School } from './types'

import { useUser } from '../../services/hooks'
import { ExitX, ModalBody, ModalFooter, ModalHeader } from '../../components/modal/Modal'
import { Input } from '../../components/inputs/Input'
import { Select } from '../../components/inputs/Select'

type Props = {
	onComplete: () => void,
	onCancel: () => void,
	editingClass: ClassType,
}

export default function EditClass({ onComplete, onCancel, editingClass }: Props): React$Node {
	const [className, setClassName] = useState(editingClass.name)
	const [classGrades, setClassGrades] = useState(mapClassGrades(editingClass.grades || []))
	const [students, setStudents] = useState<$ReadOnlyArray<Student_New | NewStudentType>>(
		editingClass.students
	)
	const [demoSchool, setDemoSchool] = useState<?School>(null)
	const { user: currentUser } = useUser()
	const [ownerId, setOwnerId] = useState(() => {
		if (editingClass.owners.find(({ _id }) => _id === currentUser?._id)) {
			return currentUser?._id
		}

		return editingClass.owners[0]?._id
	})
	let [ownerChangeConfirmationModalOpen, setOwnerChangeConfirmationModalOpen] = useState(false)

	const editClassMutation = useEditClass()

	const isSavingClass = editClassMutation.isLoading

	const { data: schoolName } = useQuery(
		`getSchoolName/${editingClass.schoolId || 'undefined'}`,
		() => getSchoolName(editingClass.schoolId),
		{ enabled: editingClass.type === 'DEMO' && !demoSchool?.schoolName }
	)

	const { data: possibleOwnersToTransferTo } = usePossibleOwners()
	const newOwner = possibleOwnersToTransferTo?.find(({ _id }) => ownerId === _id)
	let canTransfer =
		// there is an available user to transfer ownership to
		possibleOwnersToTransferTo?.some(({ _id }) => _id !== currentUser?._id) &&
		// the current user is an owner of the class
		editingClass?.owners.some(({ _id }) => _id === currentUser?._id)

	const closeOwnerConfirmationModal = () => setOwnerChangeConfirmationModalOpen(false)

	useEffect(() => {
		if (editingClass.type !== 'DEMO') return
		setDemoSchool({ schoolId: editingClass.schoolId, schoolName: schoolName || '' })
	}, [schoolName, editingClass])

	// function that stops the user from submitting a class if the class is missing info.
	const submitClass = useCallback(() => {
		const updatedClassData: UpdateClass = (function() {
			const classData: UpdateClass = {
				_id: editingClass._id,
				name: className,
				students: getNonEmptyStudents(students),
				grades: classGrades.map(gradeObject => gradeObject.value),
				externalReferences: editingClass.externalReferences,
				ownerId: canTransfer ? ownerId : null,
			}
			if (demoSchool) {
				classData.demoSchoolId = demoSchool.schoolId
			}

			return classData
		})()

		function processSaveClick() {
			editClassMutation.mutate(updatedClassData, {
				onSuccess: (_class: ?ClassType) => {
					toast.success('Class updated')
					onComplete()
				},
				onError: err => {
					let errorMessage =
						err.status === 400 ? err.message : 'Our bad! Error editing class'
					toast.error(errorMessage)
				},
			})
		}

		const validationError = getClassValidationErrorMessage(updatedClassData)
		if (validationError) {
			const toastId = toast.info(
				<SimpleConfirmation
					secondButton={false}
					message={validationError}
					title="Submit Class"
					confirmText={'OK'}
					onConfirm={() => {
						toast.dismiss(toastId)
					}}
				/>,
				{
					...ACTIONABLE_TOAST_PROPERTIES,
					position: toast.POSITION.BOTTOM_CENTER,
				}
			)
			return
		}

		setStudents(updatedClassData.students)
		processSaveClick()
	}, [
		className,
		onComplete,
		students,
		classGrades,
		editingClass,
		demoSchool,
		editClassMutation,
		ownerId,
		canTransfer,
	])

	return (
		<form>
			<div className="p-2">
				<div className="flex gap-3 *:flex *:flex-col *:flex-1 mb-2 *:text-sm *:m-0 *:*:text-base">
					<label>
						Class Name
						<Input
							required
							placeholder="Class Name"
							type="text"
							value={className}
							onChange={e => {
								setClassName(e.currentTarget.value)
							}}
						/>
					</label>
					<label>
						Grade
						<Select
							placeholder={''}
							isMulti
							styles={{
								control: base => ({
									...base,
									minHeight: 38,
								}),
								dropdownIndicator: base => ({
									...base,
									padding: 4,
								}),
								clearIndicator: base => ({
									...base,
									padding: 4,
								}),
								valueContainer: base => ({
									...base,
									padding: '0px 6px',
								}),
								input: base => ({
									...base,
									margin: 0,
									padding: 0,
								}),
							}}
							value={classGrades}
							onChange={(grades: GradeOption[]) => {
								const sortedGrades = grades.sort(function(a, b) {
									if (a.value === 'K') return -1
									if (b.value === 'K') return 1
									if (a.value === 'other') return 1
									if (b.value === 'other') return -1

									return Number(a.value) - Number(b.value)
								})
								setClassGrades(sortedGrades)
							}}
							options={classGradeOptions}
						/>
					</label>
					{canTransfer ? (
						<label>
							Owner
							<Select
								menuPortalTarget={document.body}
								value={
									newOwner && {
										value: newOwner._id,
										label: getClassOwnerName(newOwner),
									}
								}
								onChange={({ value }) => {
									setOwnerId(value)
								}}
								options={
									possibleOwnersToTransferTo?.map(owner => ({
										value: owner._id,
										label: getClassOwnerName(owner),
									})) ?? []
								}
							/>
						</label>
					) : null}
					{demoSchool && (
						<label>
							School
							<SchoolPicker
								value={demoSchool}
								onChange={setDemoSchool}
								placeholder="Type your school's name"
								districtId={currentUser?.districtId}
							/>
						</label>
					)}
				</div>
				<MultiStudentEditor students={students} onChange={setStudents} />
			</div>
			<div className="flex gap-2 justify-end p-2 bg-primary-750">
				<Button type="button" outline onClick={onCancel} variant="secondary">
					Discard Changes
				</Button>
				<Button
					onClick={() => {
						if (!canTransfer || !ownerId || ownerId === currentUser?._id) {
							submitClass()
							return
						}
						setOwnerChangeConfirmationModalOpen(true)
					}}
					type="button"
					disabled={isSavingClass}>
					{isSavingClass ? 'Saving' : `Save Changes`}
				</Button>
				{ownerChangeConfirmationModalOpen ? (
					<CustomModal
						style={{ content: { width: '30%', top: '20%' } }}
						shouldCloseOnOverlayClick
						isOpen={true}
						onRequestClose={closeOwnerConfirmationModal}>
						<ModalHeader>
							Confirm changing class &ldquo;{className}&rdquo; ownership
							<ExitX
								role={'button'}
								aria-label={'Close confirm class owner change dialog'}
								title="Close"
								onClick={closeOwnerConfirmationModal}
							/>
						</ModalHeader>
						<ModalBody>
							You are changing ownership of the class &ldquo;{className}&rdquo; to
							&ldquo;
							{newOwner ? getClassOwnerName(newOwner) : 'Unknown'}&rdquo;. You will no
							longer be an owner of this class, and &ldquo;
							{newOwner ? getClassOwnerName(newOwner) : 'Unknown'}&rdquo; will have
							full control over the class, along with access to all of its associated
							data. Are you sure you want to make this change?
						</ModalBody>
						<ModalFooter>
							<Button
								outline
								onClick={() => {
									closeOwnerConfirmationModal()
								}}>
								Cancel
							</Button>
							<Button
								className="ml-2"
								onClick={() => {
									closeOwnerConfirmationModal()
									submitClass()
								}}>
								Confirm
							</Button>
						</ModalFooter>
					</CustomModal>
				) : null}
			</div>
		</form>
	)
}

function mapClassGrades(classGrades: GradeEnum[]): GradeOption[] {
	return classGrades.map(grade => ({ value: grade, label: gradeLabelMap[grade] }))
}
