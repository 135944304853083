import React from 'react'
import styled from 'styled-components/macro'
import { Button } from '@mission.io/styles'
import type { TooltipRenderProps } from 'react-joyride'
import { RightConfetti, LeftConfetti, ModalIllustration } from '../../../assets/walkthrough'
import { useInteractionContext } from '../interactions'
import classnames from 'classnames'
import { config } from '../../../config'
import type { ThemeType } from '@mission.io/styles'
import { ExitX } from '../../modal/Modal'

/**
 * A custom welcome dialog for the react joyride walkthrough. Accepts the
 * react joyride TooltipRenderProps.
 * @param {TooltipRenderProps} props
 * @returns {React$Node}
 */
export default function WelcomeDialog({
	continuous,
	step,
	closeProps,
	primaryProps,
	tooltipProps,
	className,
	backgroundImageSrc = ModalIllustration,
}: {
	...TooltipRenderProps,
	backgroundImageSrc?: string,
	className?: string,
}): React$Node {
	const { lookups, sendClickInteraction } = useInteractionContext()

	return (
		<WelcomeModalStyle
			{...tooltipProps}
			// $FlowIgnore[prop-missing] tooltipProps is not well documented, so it's unclear if this object will include className. Either way, we want to merge the two classes.
			className={classnames(className, tooltipProps.className)}>
			<div className="welcome-modal__header">
				<ExitX
					className="text-xs"
					css="position: absolute; top: var(--spacing-half-dont-use); right: var(--spacing-half-dont-use); color: white;"
					onClick={e => {
						sendClickInteraction(lookups.clickIds.CLOSE_TOUR)
						closeProps.onClick(e)
					}}
				/>
				<img
					src={backgroundImageSrc}
					alt={`Endless Possibilities with ${config.companyName.base}`}
					className="max-w-none w-[118%] h-[129%] -mt-[14.5%] -ml-[9%]"
				/>
			</div>
			<div className="welcome-modal__body">
				<h3 className="welcome-modal__title">
					<img src={LeftConfetti} alt="" />
					<span>{step.title}</span>
					<img src={RightConfetti} alt="" />
				</h3>
				<div className="welcome-modal__content">{step.content}</div>
				{!step.hideFooter && (
					<div className="pt-6 text-lg">
						{continuous && (
							<Button
								{...primaryProps}
								data-user-interaction-id={lookups.clickIds.START_TOUR}>
								{primaryProps.title}
							</Button>
						)}
						{!continuous && <Button {...closeProps}>{closeProps.title}</Button>}
					</div>
				)}
			</div>
		</WelcomeModalStyle>
	)
}

const WelcomeModalStyle: React$AbstractComponent<{}, HTMLDivElement> = styled.div`
	max-width: 50vw;
	max-height: 90vh;
	display: flex;
	flex-direction: column;
	.welcome-modal__header {
		position: relative;
		border-radius: 8px 8px 0 0;
		background: linear-gradient(#1d1b5f, 75%, #4e5aa8);
	}
	.welcome-modal__title {
		white-space: nowrap;
		padding: 0.5em 0;
		margin-bottom: 0;
		display: flex;
		justify-content: center;
		align-items: center;
		line-height: 1.75em;
		font-weight: 800 !important;
        // Confetti decorations
        img {
            height:1.75em;
        }
	}
	.welcome-modal__content {
		text-align: left;
		font-size: 1.2em;
		margin: 0 28px;
	}
	.welcome-modal__body {
		overflow-y: auto;
		text-align: center;
		padding: 0px 24px 24px 24px;
		background-color: ${({ theme }: { theme: ThemeType }) => theme._deepBlue};
        border-radius 0px 0px 8px 8px;
	}
`
