import React, { Component } from 'react'
import classNames from 'classnames'
import classnames from 'classnames'
import FaAngleDoubleDown from 'react-icons/lib/fa/angle-double-down'
import FaAngleDoubleUp from 'react-icons/lib/fa/angle-double-up'
import type { ReactObjRef } from '../../types'
import Collapse from '../Collapse'

type Props = {
	header: React$Node,
	content?: React$Node,
	startsOpen?: boolean,
	className?: string,
	headerClassName?: string,
	contentClassName?: string,
	onChange?: (isOpen: boolean) => void,
	styles?: {
		container?: {},
		header?: {},
		content?: {},
		contentContainer?: {},
	},
	forwardRef?: (ref: ?ReactObjRef<'div'>) => void,
}

type State = {
	isOpen: boolean,
}

class Dropdown extends Component<Props, State> {
	constructor(props: Props) {
		super(props)
		this.state = { isOpen: props.startsOpen || false }
	}

	toggle: () => void = () => {
		this.setState({ isOpen: !this.state.isOpen })
		if (this.props.onChange) {
			this.props.onChange(!this.state.isOpen)
		}
	}

	render(): React$Node {
		const { container, header, contentContainer, content } = this.props.styles || {}
		return (
			<div
				className={classnames(this.props.className, 'rounded-lg overflow-hidden')}
				style={container}
				// $FlowFixMe flow is having a hard time casting
				ref={(ref: ?ReactObjRef<'div'>) =>
					this.props.forwardRef && this.props.forwardRef(ref)
				}>
				<div
					className={classNames(
						'flex justify-between py-2 px-3 font-medium cursor-pointer bg-primary-500 text-primary-50',
						this.props.headerClassName
					)}
					style={header}
					onClick={e => {
						e.stopPropagation()
						this.toggle()
					}}>
					{this.props.header}
					{this.state.isOpen ? (
						<FaAngleDoubleUp className="mr-1 text-xl mt-px ml-2 hover:brightness-90 self-center" />
					) : (
						<FaAngleDoubleDown className="mr-1 text-xl mt-px ml-2 hover:brightness-90 self-center" />
					)}
				</div>
				<Collapse expanded={this.state.isOpen} className="bg-primary-700 text-primary-50 ">
					<div
						className={classNames(this.props.contentClassName)}
						style={contentContainer}>
						<div style={content}>{this.props.content}</div>
					</div>
				</Collapse>
			</div>
		)
	}
}

export default Dropdown
