import { useQuery } from 'react-query'
import NetworkCommunicator from '../../services/NetworkCommunicator'
import config from '../../config'
import type { AnalyticsObject } from '../../models/Analytics'
import type { NameLookup } from '../../download/questions'
import { useUser } from '../../services/hooks'
import { EXAMPLE_ANALYTICS_ARRAY, ID_TO_NAME_MAP } from '../../constants/exampleData'
import { useUserRoles } from '../../services/hooks/user'
import { useMemo } from 'react'
import { getStartDateOfRecentSchoolYear } from '../../mocks/analytics'
import { HALF_YEAR, MONTH, QUARTER } from './constants'
import { rolesEnum } from '../../store/types'

/**
 * Makes a request to the `/api/analytics/all` endpoint on missions-api
 *
 * @param {?number} startTime - the start time of the time range to get analytics for (defaults to current school year start date on the server)
 * @param {?number} endTime - the end time of the time range to get analytics for(defaults to current date on the server)
 *
 * @return {Promise<obj>}
 * @return {AnalyticsObject[]} obj.analytics - An array of `AnalyticsObject` for the current user
 * @return {NameLookup} obj.idToNameMap - A map of ids to names for students and teachers in all of the analytics objects
 */
function getAllAnalyticsFromServer(
	startTime: ?number,
	endTime: ?number
): Promise<{
	validAnalytics: AnalyticsObject[],
	idToNameMap: NameLookup,
}> {
	const search = new URLSearchParams()
	if (startTime != null) {
		search.append('startTime', String(startTime))
	}
	if (endTime != null) {
		search.append('endTime', String(endTime))
	}
	return NetworkCommunicator.GET(`/api/analytics/all?${String(search)}`, {
		host: config.missionsAPIURL,
		withCredentials: true,
	})
}

const getAnalyticsQueryKey = (isUser: boolean, startTime: ?number, endTime: ?number) => {
	return ['allAnalytics', isUser, startTime, endTime]
}

/** A hook that fetches all analytics from missions api.
 *
 * @param {TimeRange} timeRange - the range of time to get analytics for (defaults to the current school year through the current date)
 */
export function useAllAnalytics(
	timeRange: ?$ReadOnly<{
		startTime: ?number,
		endTime: ?number,
	}>
): {
	data: ?{ validAnalytics: AnalyticsObject[], idToNameMap: NameLookup },
	error: ?Error,
	isLoading: boolean,
} {
	const { user } = useUser()

	const queryKey = getAnalyticsQueryKey(Boolean(user), timeRange?.startTime, timeRange?.endTime)
	const { data, isLoading, error } = useQuery(
		queryKey,
		() => {
			if (!user) {
				return { validAnalytics: EXAMPLE_ANALYTICS_ARRAY, idToNameMap: ID_TO_NAME_MAP }
			}
			return getAllAnalyticsFromServer(timeRange?.startTime, timeRange?.endTime)
		},
		{
			staleTime: Infinity,
		}
	)
	return { data, error, isLoading }
}

/**
 * A hook that returns a single analytics object for a mission
 * @param {string} missionId
 * @returns {?AnalyticsObject}
 */
export function useAnalyticsForMission(missionId: string): ?AnalyticsObject {
	const { data } = useAllAnalytics()
	if (!data) {
		return null
	}
	return data.validAnalytics.find(analytics => analytics.missionId === missionId)
}

export type TimeSelectOption = {
	id: string,
	label: string,
	// filter analytics on the client to only select analytics generated in this range
	rangeTimestamps: {
		startTime: number,
		endTime: number,
	},
	// get analytics generated in this range from the server. This can be used to limit number of requests needed when selecting different option.
	queryRangeTimestamps: {
		startTime: number,
		endTime: number,
	},
}

/**
 * getDateNMonthsAgoInCurrentSchoolYear - get a date `months` months ago in the current school year. If the number of `months` have not passed in the current
 * school year, it will return the start of the current school year.
 *
 * @param {number} months - the number of months to go back from the current date
 *
 * @return {number} - a timestamp
 */
function getDateNMonthsAgoInCurrentSchoolYear(months: number): number {
	let nMonthsAgo = new Date()
	nMonthsAgo.setMonth(nMonthsAgo.getMonth() - months)

	return Math.max(Number(nMonthsAgo), Number(getStartDateOfRecentSchoolYear()))
}

/**
 * useTimeOptions - get the options which the user is allowed to filter by time for. Different user roles can have different time ranges.
 *
 * @returns {{
 *	reactSelectOptions: Array<{| label: string, options: Array<TimeSelectOption> |}>, - an array of react select groups
 *	allOptions: Array<TimeSelectOption>, - all time range options
 *}}
 */
export function useTimeOptions(): {
	reactSelectOptions: Array<{| label: string, options: Array<TimeSelectOption> |}>,
	allOptions: Array<TimeSelectOption>,
} {
	const userRoles = useUserRoles()
	return useMemo(() => {
		const startOfCurrentSchoolYear = getStartDateOfRecentSchoolYear()

		const startOfPreviousSchoolYear = getStartDateOfRecentSchoolYear()
		startOfPreviousSchoolYear.setFullYear(startOfPreviousSchoolYear.getFullYear() - 1)

		const currentSchoolYearTimeRange = {
			startTime: Number(startOfCurrentSchoolYear),
			endTime: Date.now(),
		}

		const defaultOptions = [
			{
				id: 'current-school-year',
				label: 'Current School Year',
				rangeTimestamps: currentSchoolYearTimeRange,
				queryRangeTimestamps: currentSchoolYearTimeRange,
			},
			{
				id: 'last-30-days-in-current-school-year',
				label: 'Last 30 days',
				subLabel: 'Current School Year',
				rangeTimestamps: {
					startTime: Number(getDateNMonthsAgoInCurrentSchoolYear(MONTH)),
					endTime: Date.now(),
				},
				queryRangeTimestamps: currentSchoolYearTimeRange,
			},
			{
				id: 'last-3-months-in-current-school-year',
				label: 'Last 3 months',
				subLabel: 'Current School Year',
				rangeTimestamps: {
					startTime: Number(getDateNMonthsAgoInCurrentSchoolYear(QUARTER)),
					endTime: Date.now(),
				},
				queryRangeTimestamps: currentSchoolYearTimeRange,
			},
			{
				id: 'last-6-months-in-current-school-year',
				label: 'Last 6 months',
				subLabel: 'Current School Year',
				rangeTimestamps: {
					startTime: Number(getDateNMonthsAgoInCurrentSchoolYear(HALF_YEAR)),
					endTime: Date.now(),
				},
				queryRangeTimestamps: currentSchoolYearTimeRange,
			},
		]

		const options = {
			reactSelectOptions: [
				{
					label: 'Current School Year',
					options: defaultOptions,
				},
			],
			allOptions: [...defaultOptions],
		}

		const verifiedRoles = new Set(
			userRoles.filter(({ verified }) => verified).map(({ role }) => role)
		)
		if (
			verifiedRoles.has(rolesEnum.DEPRECATED_SCHOOL_ADMIN) ||
			verifiedRoles.has(rolesEnum.SCHOOL_ADMIN) ||
			verifiedRoles.has(rolesEnum.DISTRICT_ADMIN)
		) {
			const lastSchoolYearTimeRange = {
				startTime: Number(startOfPreviousSchoolYear),
				endTime: Number(startOfCurrentSchoolYear),
			}

			const adminOptions = [
				{
					id: 'last-school-year',
					label: 'Last School Year',
					rangeTimestamps: lastSchoolYearTimeRange,
					queryRangeTimestamps: lastSchoolYearTimeRange,
				},
			]
			options.allOptions.push(...adminOptions)
			options.reactSelectOptions.push({
				label: 'Previous School Years',
				options: adminOptions,
			})
		}

		return options
	}, [userRoles])
}
