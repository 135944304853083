import React from 'react'

import type { NameLookup } from '../../../download/questions'
import type { AnalyticsObject } from '../../../store/types'
import type { ScoreFormatType } from '../constants'

import { getName } from '../../../utility/helpers'
import { getTotalPoints } from '../StudentPage/StudentPage'
import { ALL_CATEGORIES } from '../StudentPage/constants'
import { getStudentColumns, getStudentData } from '../StudentPage/studentTableHelpers'
import { generateCSV } from './csvGenerator'
import { getSelScoresForStudent } from './dataHelpers'
import { downloadFile } from '../../../utility/functions'
import { toast } from 'react-toastify'

import { Button } from '@mission.io/styles'
import { FaDownload } from 'react-icons/lib/fa'

/**
 * LazyDownloadAllStudentDataButton - a button used to download a zip file of all student analytics in the list of filteredAnalytics
 *
 * @param {Object} props - the react props
 * @param {AnalyticsObject[]} props.filteredAnalytics - the analytics to get the student analytics from
 * @param {NameLookup} props.nameLookup - the id map to names for the students in the analytics
 * @param {ScoreFormatType} props.displayType - what data to display in the csv
 * @param {string} props.csvName - the base name of the csv
 *
 * @return {React$Node}
 */
export function DownloadAllStudentDataButton(props: {
	filteredAnalytics: AnalyticsObject[],
	nameLookup: NameLookup,
	displayType: ScoreFormatType,
	csvName: string,
}): React$Node {
	return (
		<Button
			onClick={() =>
				downloadStudentData(props).catch(error => {
					toast.error('Failed to generate student analytics zip file')
					console.error(
						'Failed to generate the student analytics zip file due to the following error',
						error
					)
				})
			}>
			<FaDownload className="inline" /> Download All Student CSVs
		</Button>
	)
}

const UNKNOWN_STUDENT = 'Unknown Student'

/**
 * downloadStudentData - download a zip file of all student analytics in the list of filteredAnalytics
 *
 * @param {Object} args - the arguments
 * @param {AnalyticsObject[]} args.filteredAnalytics - the analytics to get the student analytics from
 * @param {NameLookup} args.nameLookup - the id map to names for the students in the analytics
 * @param {ScoreFormatType} args.displayType - what data to display in the csv
 * @param {string} args.csvName - the base name of the csv
 *
 * @return {React$Node}
 */
async function downloadStudentData({
	filteredAnalytics,
	nameLookup,
	displayType,
	csvName,
}: {
	filteredAnalytics: AnalyticsObject[],
	nameLookup: NameLookup,
	displayType: ScoreFormatType,
	csvName: string,
}) {
	const studentsAnalyticsData: {
		[studentId: string]: { analytics: Array<any>, studentName: string },
	} = {}

	filteredAnalytics.forEach(analytics => {
		const classAnalytics = analytics?.analytics
		if (!classAnalytics) {
			return
		}
		Object.keys(classAnalytics.studentAnalytics ?? {}).forEach(studentId => {
			const studentNameComponents = nameLookup[studentId]
			studentsAnalyticsData[studentId] ??= {
				analytics: [],
				studentName:
					getName(studentNameComponents?.firstName, studentNameComponents?.lastName) ??
					UNKNOWN_STUDENT,
			}
			studentsAnalyticsData[studentId].analytics.push({
				missionName: analytics.missionName || 'Unknown',
				missionDate: new Date(analytics.createdTime),
				studentPoints: getTotalPoints(studentId)(analytics),
				scores: getSelScoresForStudent(classAnalytics, studentId),
			})
		})
	})

	const studentIds = Object.keys(studentsAnalyticsData)
	const studentNameCounts = {}
	studentIds.forEach(studentId => {
		const studentAnalyticsData = studentsAnalyticsData[studentId]
		studentNameCounts[studentAnalyticsData.studentName] ??= []
		studentNameCounts[studentAnalyticsData.studentName].push(studentId)
	})

	Object.keys(studentNameCounts).forEach(studentName => {
		studentNameCounts[studentName].sort()
	})

	const JsZip = (await import('jszip')).default
	const zip = new JsZip()
	const studentColumns = getStudentColumns({
		categories: ALL_CATEGORIES,
		displayType,
	})

	studentIds.forEach(studentId => {
		const studentAnalyticsData = studentsAnalyticsData[studentId]
		const studentsWithSameName = studentNameCounts[studentAnalyticsData.studentName]

		// figure out the name for the student
		let studentName = ''
		if (studentsWithSameName && studentsWithSameName.length > 1) {
			let indexOfStudent = studentsWithSameName.findIndex(id => id === studentId)
			if (indexOfStudent >= 0) {
				studentName = `${studentAnalyticsData.studentName} ${indexOfStudent + 1}`
			}
			if (
				// studentNameCounts was populated incorrectly
				indexOfStudent < 0 ||
				// adding a number to the end created another conflict
				studentsWithSameName[studentName]?.length
			) {
				studentName = `${studentAnalyticsData.studentName} (${studentId})`
			}
		} else {
			studentName = studentAnalyticsData.studentName
		}

		// create csv
		const csvFile = generateCSV(
			getStudentData({ missions: studentAnalyticsData.analytics }),
			studentColumns
		)

		if (!csvFile) {
			return
		}

		// add csv to zip file
		zip.file(`${studentName}.csv`, csvFile)
	})

	let fileBlob = await zip.generateAsync({ type: 'blob' })

	const url = URL.createObjectURL(fileBlob)
	downloadFile(url, `${csvName ?? 'unknown'} All Students.zip`)
	URL.revokeObjectURL(url)
}
