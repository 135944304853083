import { useMemo } from 'react'
import { useUserStandardState } from './user'
import { getMostRelevantStandards, type CurriculumStandard } from '../../utility/standards'
import type { AutomatedSimulation } from '../../models'
import { ONE_MINUTE } from '../../constants'
import axios from 'axios'
import { useQuery } from 'react-query'
import type { UseQueryResult } from 'react-query'
import type { SimpleCurriculumStandard, StandardSet } from '../../utility/standards'
import { config } from '../../config'

/**
 * Given a simulation returns a list of relevant standards for the simulation. If the standard sets are not loaded, return null
 * @param {AutomatedSimulation} simulation
 */
export function useRelevantStandardsForSimulation(
	simulation: AutomatedSimulation
): CurriculumStandard[] | null {
	const userState = useUserStandardState()
	const simulationStandards = simulation.standards
	const { data: standardSets } = useStandardSets()

	const userStandards = useMemo(
		() =>
			standardSets
				? getMostRelevantStandards({
						stateAbbreviation: userState,
						standards: simulationStandards,
						standardSets,
				  })
				: null,
		[userState, simulationStandards, standardSets]
	)
	return userStandards
}

/**
 * A hook for fetching all standard sets from the API.
 */
export function useStandardSets(): UseQueryResult<StandardSet[]> {
	return useQuery(
		'standardSets',
		async () => {
			const result = await axios.get(`${config.simulationsApiUrl}/api/standardSets`)

			return result.data.standardSets
		},
		{
			staleTime: 5 * ONE_MINUTE,
		}
	)
}

/**
 * A function that returns a string containing the standard set id query params
 */
const getStandardSetIdString = (standardSetIds: string[]): string => {
	if (standardSetIds.length < 1) return ''
	let standardSetString = '?'
	standardSetIds.forEach((standardSetId, index) => {
		standardSetString += `standardSetIds=${standardSetId}`
		if (index < standardSetIds.length - 1) standardSetString += '&'
	})
	return standardSetString
}

/**
 * A hook for fetching standards from the API.
 */
export function useStandards(standardSetIds: string[]): UseQueryResult<SimpleCurriculumStandard[]> {
	return useQuery(`standards${getStandardSetIdString(standardSetIds)}`, async () => {
		if (standardSetIds?.length < 1) return []
		const result = await axios.get(
			`${config.simulationsApiUrl}/api/standards${
				standardSetIds && standardSetIds.length > 0
					? getStandardSetIdString(standardSetIds)
					: ''
			}`
		)
		const res = result.data.standards.map(standard => ({
			...standard,
			standardSetId: standard.standardSet,
		}))
		return res
	})
}
