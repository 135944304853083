import React, { useCallback, useState, useMemo } from 'react'
import styled from 'styled-components/macro'
import { Tooltip } from 'react-tooltip'
import StudentsTable from './StudentsTable'
import ScoreInfo from './ScoreInfo'
import { ButtonLink } from '../../../components/helpers'
import { useSelectedMissionAnalytics } from '../SpecificMissionContext'
import { ORDERED_CATEGORIES, MISSION_SCORE, HEADER_TO_TITLE } from '../constants'
import {
	getQuestionResponseData,
	getStudentScore,
	RenderNumberScore,
	RenderPercentScore,
	RenderQuestionResponses,
	getAccessorAndCellFunctions,
	QUESTION_RESPONSE_TOOLTIP_ID,
} from './TableHelper'
import {
	dropQuestionMarkdownPrefix,
	useAnalyticsCulminatingMomentColumns,
	useAnalyticsQuestionColumns,
} from './hooks'
import { reduceObject } from '../../../utility/functions'
import { getQuestionKey } from '../../../utility/helpers'
import { scoreFormatEnum, QUESTIONS, NAME, CULMINATING_MOMENT } from '../constants'
import type { QuestionColumn } from './hooks'
import { useQueryParam, StringParam } from 'use-query-params'
import { CustomModal } from '../../../components'
import QuestionResponseAttempts from '../QuestionResponseAttempts'
import { StyledModal } from './sharedComponents'
import type { DBQuestion } from '../../../models/Question'
import { AnalyticsScoreTableToolbar } from './AnalyticsScoreTableToolbar'
import type { ScoreFormatType } from '../constants'
import type { AnalyticsObject } from '../../../store/types'
import type { ProficiencyTableEntry, SelScoresByEntityId } from '../../../models/Analytics'
import type { NameLookup } from '../../../download/questions'
import type { ResponseData } from './TableHelper'

export type QuestionTableData = {
	questionPrefix: string,
	scoreHeader: string,
	sectionHeader: string,
}
const QUESTION_TABLE_METRIC_TO_TABLE_DATA: { [headerType: string]: QuestionTableData } = {
	[QUESTIONS]: {
		questionPrefix: 'Q ',
		scoreHeader: 'Questions %',
		sectionHeader: 'Question',
	},
	[CULMINATING_MOMENT]: {
		questionPrefix: 'CM ',
		scoreHeader: 'Application %',
		sectionHeader: 'Application (Culminating Moment)',
	},
}

type ModalData = {
	modalComponent: React$Node,
}

type Row = { getValue: (columnId: string) => any }

/**
 * Custom sort for analytics scores, where a score is a number or the string 'NA'
 */
function sortScore(a: Row, b: Row, columnId: string) {
	const valueA = a.getValue(columnId)
	const valueB = b.getValue(columnId)
	if (valueA === valueB) {
		return 0
	} else if (valueA === 'NA') {
		return 1
	} else if (valueB === 'NA') {
		return -1
	} else {
		return valueA - valueB
	}
}

/**
 * Handles the rendering of tables displaying analytics data. Displays a table with all of the student sel scores, followed by
 * a questions table and a culminating moments table. This should be used to display analytics data for a single mission.
 * @param {{ [studentId: string]: SelScores }} props.individualSelScores map of student ids to their scores
 * @param {number} props.version version of analytics to describe score information
 * @param {string} props.csvGroupName - describes how the analytics are connected (used in csv file name generation)
 * @param {string} props.csvDateRange - describes the range of dates the analytics were collected for (used in csv file name generation)
 *
 * @returns {React$Node}
 */
export default function StudentsTableManager({
	individualSelScores,
	filteredAnalytics,
	nameLookup,
	csvGroupName,
	...proficiencyPassthroughProps
}: {|
	csvGroupName: string,
	filteredAnalytics: AnalyticsObject[],
	individualSelScores: SelScoresByEntityId,
	version: number,
	nameLookup: NameLookup,
|}): React$Node {
	const classAnalytics = useSelectedMissionAnalytics()

	// A function that returns a displayable name string for a given student id.
	const getName = useCallback(
		(entityId: string, sortedByLastName?: boolean = false) => {
			if (nameLookup[entityId]) {
				const firstName = nameLookup[entityId].firstName
				const lastName = nameLookup[entityId].lastName

				if (sortedByLastName) {
					return `${lastName}, ${firstName}`
				} else {
					return `${firstName} ${lastName}`
				}
			}

			return `Unknown${entityId ? ` (${entityId.slice(0, 3)}...${entityId.slice(-3)})` : ``}`
		},
		[nameLookup]
	)

	// Memoized rows for the table: each student and their scores.
	const data: Array<ProficiencyTableEntry> = useMemo(() => {
		return Object.keys(individualSelScores).map(entityId => {
			const selScores = individualSelScores[entityId]
			return {
				type: 'STUDENT',
				id: entityId,
				name: getName(entityId, true),
				grit: selScores.disposition.grit,
				initiative: selScores.disposition.initiative,
				application: selScores.knowledge.application,
				questions: selScores.knowledge.questions,
				collaboration: selScores.skills.collaboration,
				criticalThinking: selScores.skills.criticalThinking,
			}
		})
	}, [individualSelScores, getName])

	const initialColumns = useMemo(
		() => [
			getNameColumn({ namingType: 'Student' }),
			{
				header: 'Mission Score',
				accessorFn: (element: ProficiencyTableEntry) =>
					reduceObject(
						classAnalytics?.studentAnalytics?.[element.id]?.studentPoints || {},
						(total, studentPointValue) => total + studentPointValue,
						0
					),
				cell: RenderNumberScore,
				id: MISSION_SCORE,
				sortingFn: sortScore,
				minSize: 75,
			},
		],
		[classAnalytics?.studentAnalytics]
	)

	return (
		<>
			<ProficiencyTable
				{...{
					data,
					initialColumns,
					allowDownloadAll: true,
					nameLookup,
					filteredAnalytics,
					getCsvName: displayType =>
						`${csvGroupName} Student Performance - ${displayType}`,
					...proficiencyPassthroughProps,
				}}
			/>
			<Spacer />
			<QuestionTable getName={getName} individualSelScores={individualSelScores} />
			<CulminatingMomentTable getName={getName} individualSelScores={individualSelScores} />
			<Spacer />
		</>
	)
}

/**
 * Displays a table where each row is a `ProficiencyTableEntry` object. Shows proficiency scores for each entity represented in the data.
 * @param props.data - Should be memoized. the data to display in the table
 * @param props.initialColumns - Should be memoized. Column definitions that will be included at the front of the table. The first column
 *                      is expected to be a name column
 * @param props.categories - Should be memoized. The categories to display in the table. Defaults to `ORDERED_CATEGORIES`
 * @param props.version - the version of the analytics data being displayed
 * @param props.analyticsViewType - tells whether the data is for a single mission, teacher, school, or district
 * @param props.getCsvName - A function to create the name of the csv file to download. Accepts the `displayType`, aka the current score
 *                                format being displayed
 * @param props.nameLookup - a lookup table for student names
 * @param props.filteredAnalytics - the analytics data that was used to generate the proficiency scores
 * @param props.initialState - the initial state of the table. This will be passed directly to @tanstack/react-table
 */
export function ProficiencyTable<T: { ...ProficiencyTableEntry, ... }>({
	data,
	initialColumns,
	version,
	allowDownloadAll,
	getCsvName,
	categories = ORDERED_CATEGORIES,
	initialState,
	...analyticsScoreTableToolbarPassthroughProps
}: {|
	data: Array<T>,
	initialColumns: Array<mixed>,
	version: number,
	allowDownloadAll: boolean,
	getCsvName: (displayType: ScoreFormatType) => string,
	nameLookup?: NameLookup,
	filteredAnalytics?: Array<AnalyticsObject>,
	categories?: Array<string>,
	initialState?: { sorting?: Array<{ id: 'name', desc: boolean }> },
|}): React$Node {
	const [displayType, setDisplayType] = useState(scoreFormatEnum.PROFICIENCY_LEVEL)
	const [modalData, setModalData]: UseStateReturn<?ModalData> = useState(null)

	const csvName = getCsvName(displayType)

	// Displays a modal explaining what the score for a given category means.
	const showScoreInfoModal = useCallback(
		(element: ProficiencyTableEntry, header: string) => {
			setModalData({
				modalComponent: (
					<ScoreInfo
						header={header}
						proficiencyScores={element}
						name={element.name}
						onRequestClose={() => setModalData(null)}
						version={version}
					/>
				),
			})
		},
		[setModalData, version]
	)

	// Memoized columns for the student score table.
	const columns = useMemo(() => {
		const columns = [...initialColumns]

		categories.forEach((header: string) => {
			const { meta, ...rest } = getAccessorAndCellFunctions(
				displayType,
				(element: ProficiencyTableEntry) => element[header]
			)
			columns.push({
				header: HEADER_TO_TITLE[header],
				...rest,
				id: `${header}-${displayType}`,
				sortingFn: sortScore,
				minSize: 75,
				meta: {
					...meta,
					onClick: (e, element) => {
						e.stopPropagation()
						showScoreInfoModal(element, header)
					},
				},
			})
		})
		return columns
	}, [initialColumns, showScoreInfoModal, displayType, categories])

	return (
		<>
			<StyledModal
				type={modalData?.modalComponent}
				className="p-0"
				isOpen={Boolean(modalData && modalData.modalComponent)}
				onRequestClose={() => setModalData(null)}>
				{modalData?.modalComponent}
			</StyledModal>

			<AnalyticsScoreTableToolbar
				columns={columns}
				data={data}
				csvName={csvName}
				displayType={displayType}
				setDisplayType={setDisplayType}
				allowDownloadingAllStudentDataInAnalytics={allowDownloadAll}
				{...analyticsScoreTableToolbarPassthroughProps}
			/>

			<StudentsTable
				data={data}
				columns={columns}
				csvName={csvName}
				initialState={initialState}
				tableBodyKey={
					// remounting the table body when displayType changes is faster than trying to update the table one cell at a time
					displayType
				}
			/>
		</>
	)
}

const Spacer = styled.div`
	margin-top: var(--spacing2x-dont-use);
`

/**
 * A table to display student names and their scores on all the questions asked in the mission.
 *
 * @param {(entityId: string, sortedByLastName?: boolean) => string} getName get name to display based on sort value and id of student
 *
 * @returns React$Node
 */
function QuestionTable({
	getName,
	individualSelScores,
}: {
	getName: (entityId: string, sortedByLastName?: boolean) => string,
	individualSelScores: SelScoresByEntityId,
}) {
	const [questionColumns, questionMap] = useAnalyticsQuestionColumns()

	return (
		<QuestionTypeTable
			questionColumns={questionColumns}
			getName={getName}
			individualSelScores={individualSelScores}
			questionMap={questionMap}
			metric={QUESTIONS}
		/>
	)
}

/**
 * A table to display student names and their scores on all the culminating moments asked in the mission.
 *
 * @param {(entityId: string, sortedByLastName?: boolean) => string} getName get name to display based on sort value and id of student
 *
 * @returns React$Node
 */
function CulminatingMomentTable({
	getName,
	individualSelScores,
}: {
	getName: (entityId: string, sortedByLastName?: boolean) => string,
	individualSelScores: SelScoresByEntityId,
}) {
	const [questionColumns, questionMap] = useAnalyticsCulminatingMomentColumns()

	return (
		<QuestionTypeTable
			questionColumns={questionColumns}
			getName={getName}
			individualSelScores={individualSelScores}
			questionMap={questionMap}
			metric={CULMINATING_MOMENT}
		/>
	)
}

type QuestionTypeTableRow = {|
	id: string,
	name: string,
	questionResponseMap: {
		[questionId: string]: Array<ResponseData>,
	},
|}

/**
 * QuestionTypeTable - shows a table filled with the given question
 *
 * @param {Object} props - the react props
 * @param {QuestionColumn[]} props.questionColumns - a list of columns with their questions
 * @param {(entityId: string, sortedByLastName?: boolean) => string} props.getName get name to display based on sort value and id of student
 * @param { $Keys<typeof QUESTION_TABLE_METRIC_TO_TABLE_DATA>} props.metric - the metric being shown on the table
 *
 */
function QuestionTypeTable({
	questionColumns,
	getName,
	questionMap,
	metric,
	individualSelScores,
}: {
	questionColumns: QuestionColumn[],
	getName: (entityId: string, sortedByLastName?: boolean) => string,
	questionMap: { [questionKey: string]: DBQuestion },
	metric: $Keys<typeof QUESTION_TABLE_METRIC_TO_TABLE_DATA>,
	individualSelScores: SelScoresByEntityId,
}) {
	const tableData: QuestionTableData = useMemo(
		() =>
			QUESTION_TABLE_METRIC_TO_TABLE_DATA[metric] ?? {
				questionPrefix: '',
				scoreHeader: `Unknown Metric ${metric}`,
				sectionHeader: 'Unknown Section',
			},
		[metric]
	)
	const [modalData, setModalData] = useState(null)

	// Displays a modal to review a students answer to the selected question.
	const showSingleQuestionModal = useCallback(
		({ studentId, studentName, responses }, questionIndex: number, answerNumber: ?number) => {
			setModalData({
				modalComponent: (
					<QuestionResponseAttempts
						responses={responses}
						startingAttemptToView={answerNumber ? Number(answerNumber) : 1}
						studentId={studentId}
						name={studentName}
						onClose={() => setModalData(null)}
					/>
				),
			})
		},
		[setModalData]
	)

	// Memoized columns for the question table
	const columns = useMemo(() => {
		let columns = [
			getNameColumn({
				namingType: 'Student',
			}),
			{
				header: () => tableData.scoreHeader,
				accessorFn: (element: QuestionTypeTableRow) =>
					getStudentScore(individualSelScores[element.id], metric),
				cell: RenderPercentScore,
				minSize: 100,
				id: metric,
			},
		]
		questionColumns.forEach((questionColumn: QuestionColumn, index: number) => {
			columns.push({
				header: () => {
					const question =
						questionMap[
							getQuestionKey(
								questionColumn.questionId,
								questionColumn.questionVersion
							)
						]
					const questionTooltipId = `${metric}-question-tooltip-${index}`
					return (
						<div
							data-tooltip-id={questionTooltipId}
							data-tooltip-content={dropQuestionMarkdownPrefix(
								// $FlowIgnore[prop-missing] phrase some does not exist in some question types and template does not either, but they will be strings when they do exist
								question?.phrase ?? question?.template ?? ''
							)}>
							{`${tableData.questionPrefix}${index + 1}`}
							<Tooltip
								id={questionTooltipId}
								className="[&&]:bg-primary-400"
								positionStrategy="fixed"
							/>
						</div>
					)
				},
				size: 10,
				// accessor for each cell value. returns the student response object for the question, the question, and the student's name id.
				accessorFn: (element: QuestionTypeTableRow, columnIndex) => {
					return {
						responses: element.questionResponseMap[questionColumn.questionId] ?? [],
						nameId: element.id,
						id: `${questionColumn.questionId}${element.id}`,
						onAttemptClick: (answerNumber: ?number) => {
							showSingleQuestionModal(
								{
									studentId: element.id,
									studentName: element.name,
									responses:
										element.questionResponseMap[questionColumn.questionId] ??
										[],
								},
								index,
								answerNumber
							)
						},
					}
				},
				/* Custom sort method for a question column. Sorts by students who answered correctly/incorrectly. 
	All students with unavailable responses will sort last.*/
				sortingFn: (a: Row, b: Row, columnId: string) => {
					const studentA_Answer = a.getValue(columnId)?.responses?.[0]?.questionResponse
					const studentB_Answer = b.getValue(columnId)?.responses?.[0]?.questionResponse
					const scoreA = studentA_Answer?.score
					const scoreB = studentB_Answer?.score
					if (scoreA && scoreB) {
						const wrongDiffA =
							scoreA.score !== null ? scoreA.perfectScore - scoreA.score : null
						const wrongDiffB =
							scoreB.score !== null ? scoreB.perfectScore - scoreB.score : null
						if (wrongDiffA === wrongDiffB) {
							return 0
						} else if (wrongDiffA == null) {
							return 1
						} else if (wrongDiffB == null) {
							return -1
						} else {
							return wrongDiffB - wrongDiffA
						}
					} else if (scoreA == null) {
						return 1
					} else {
						return -1
					}
				},
				// The rendered question response cell.
				cell: RenderQuestionResponses,
				id: `questions-${index}`,
			})
		})
		return columns
	}, [
		individualSelScores,
		questionMap,
		questionColumns,
		showSingleQuestionModal,
		metric,
		tableData,
	])

	// Memoized row data for the table. Each student and a map of their question response data.
	const data: Array<QuestionTypeTableRow> = useMemo(
		() =>
			Object.keys(individualSelScores).map(studentId => {
				const questionResponseMap = {}
				questionColumns.forEach(questionColumn => {
					const studentResponses = questionColumn.studentAnswerLookup[studentId] ?? []
					const question =
						questionMap[
							getQuestionKey(
								questionColumn.questionId,
								questionColumn.questionVersion
							)
						]
					studentResponses.forEach(response => {
						const questionResponse =
							question && response
								? getQuestionResponseData(response, question)
								: null

						questionResponseMap[questionColumn.questionId] ??= []
						questionResponseMap[questionColumn.questionId].push({
							questionResponse,
							question,
							studentResponse: response,
						})
					})
				})

				Object.keys(questionId => {
					questionResponseMap[questionId].sort(
						(a, b) => (a.answerNumber ?? Infinity) - (b.answerNumber ?? Infinity)
					)
				})
				return { id: studentId, name: getName(studentId, true), questionResponseMap }
			}),
		[individualSelScores, questionColumns, questionMap, getName]
	)
	if (!questionColumns.length) {
		return null
	}
	return (
		<>
			<h3 className="m-4 !ml-0 text-2xl">{tableData.sectionHeader}</h3>
			<StudentsTable data={data} columns={columns} />
			<CustomModal
				type={modalData?.modalComponent}
				className="p-0"
				isOpen={Boolean(modalData?.modalComponent)}
				onRequestClose={() => setModalData(null)}
				style={{
					content: {
						background: '#0009',
						border: '0px solid transparent',
						boxShadow: 'none',
						left: 0,
						right: 0,
						width: '100%',
						transform: '',
						top: 0,
						bottom: 0,
						height: '100%',
						maxHeight: '100%',
					},
				}}>
				{modalData?.modalComponent}
			</CustomModal>
			<Tooltip id={QUESTION_RESPONSE_TOOLTIP_ID} className="z-10 [&&]:bg-primary-400" />
		</>
	)
}

/**
 * Gets the column to display the name column of the table. The name could be a student name, a class name, or a school name depending
 * on the analytics view type. If the analytics view type is not provided, a student name will be returned
 * @param {*} param0
 * @returns
 */
export function getNameColumn({
	namingType,
}: {
	namingType: 'Student' | 'Teacher' | 'School',
}): {
	header: () => React$Node,
	minSize: number,
	accessorKey: string,
	cell: (props: {
		getValue: () => string,
		row: { original: ProficiencyTableEntry | QuestionTypeTableRow },
		queryKey: string,
	}) => React$Node,

	id: string,
	meta: {
		csvHeader: string,
	},
} {
	return {
		header: props => {
			return <div className="flex">{namingType}</div>
		},
		minSize: 100,
		accessorKey: 'name',
		cell: RenderNameLink[namingType] || (({ getValue }) => String(getValue())),
		id: NAME,
		meta: { csvHeader: namingType },
	}
}

const RenderNameLink = {
	Teacher: props => {
		return <NameLink {...props} queryKey={'teacher'} />
	},
	Student: props => {
		return <NameLink {...props} queryKey={'student'} />
	},
	School: props => {
		return <NameLink {...props} queryKey={'school'} />
	},
}

/**
 * A component to render the cell for the name of a student, teacher, or school.
 * @param {string} props.getValue gets the value to be displayed (provided by react-table) which will be the name of the student
 * @param props.row also provided by react-table. Gives access to the entity id.
 * @param {string} queryKey custom prop that tells which query key should be updated when the link is clicked.
 * @returns { React$Node } the Cell component that will be used by react-table to render the student name table cell.
 */
const NameLink = ({
	getValue,
	row,
	queryKey,
}: {
	getValue: () => string,
	row: { original: ProficiencyTableEntry | QuestionTypeTableRow },
	queryKey: string,
}) => {
	const [, setQuery] = useQueryParam(queryKey, StringParam)

	return (
		<ButtonLink
			css={`
				position: sticky;
				display: block;
				text-align: left;
				left: 0;
				text-decoration: underline !important;
				font-weight: 500;
				cursor: pointer;
			`}
			onClick={() => setQuery(row.original.id)}>
			{getValue()}
		</ButtonLink>
	)
}
