// @flow
import React, { useContext, useState, useRef, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled, { ThemeContext } from 'styled-components/macro'
import { Button } from '@mission.io/styles'
import { GoBackButton } from '../../../styles/sharedComponents'
import { ModalBody, ModalFooter } from '../../../components/modal/Modal'
import { resetQuestions, getSimulationId } from '../../../store/missionPrep'
import { getAutomatedSimulationFromId } from '../../../store/simulation'
import QuestionLibrary from '../../questions/Library'
import BackMenu from './../../../components/helpers/BackMenu'
import FaPlus from 'react-icons/lib/fa/plus'
import FaMinus from 'react-icons/lib/fa/minus'
import { Question } from '@mission.io/question-views'
import { convertQuestionGroup, useQuestionGroup } from '../../../services/hooks/questionGroup'
import { Loading, QuestionButtonAction as ButtonAction } from '../../../components'
import { MdArrowBack, MdArrowForward } from 'react-icons/lib/md'
import { toast } from 'react-toastify'

import type { ClientQuestion as QuestionType } from '@mission.io/question-toolkit'
import { toIdMap } from '../../../utility/functions'
import { getSimulationCategories } from '../../../store/categories'
import RequireSignIn from '../../../components/helpers/RequireSignIn'
import classnames from 'classnames'

type Props = {
	goBack: (showAlert?: boolean) => mixed,
	questions: QuestionType[],
	setQuestions: (((QuestionType[]) => QuestionType[]) | QuestionType[]) => void,
}

export default function PrepModalQuestionContent({
	goBack,
	questions,
	setQuestions,
}: Props): React$Node {
	const { error } = useContext(ThemeContext)
	const dispatch = useDispatch()
	const simulation = useSelector(getAutomatedSimulationFromId(useSelector(getSimulationId)))

	const [questionGroup, setQuestionGroup] = useState(null)
	const questionLibraryRef = useRef()

	// When navigating in the question library, always start at the top of the component
	useEffect(() => {
		if (questionLibraryRef.current) {
			questionLibraryRef.current.scrollTop = 0
		}
	}, [questionGroup])

	const [showSelectedQuestions, setShowSelectedQuestions] = useState(true)

	const canAddQuestion = questionId =>
		!!simulation && !questions.some(question => question._id === questionId)

	const onAdd = (question: QuestionType) => setQuestions(questions => [...questions, question])
	const onRemove = (questionId: string) => {
		setQuestions(questions => {
			const newQuestions = [...questions].filter(q => q._id !== questionId)
			return newQuestions
		})
	}

	const alertMaxQuestion = questions && simulation && questions.length > simulation?.maxQuestions

	return (
		<>
			<StyledModalBody>
				<StyledSelector
					ref={questionLibraryRef}
					showSelectedQuestions={showSelectedQuestions}>
					{questionGroup ? (
						<ShowQuestions
							questionGroupId={questionGroup}
							canAddQuestion={canAddQuestion}
							onAdd={onAdd}
							onRemove={onRemove}
							onGoBack={() => setQuestionGroup(null)}
						/>
					) : (
						<QuestionLibrary
							className="!p-4"
							onQuestionGroupSelect={id => {
								setQuestionGroup(id)
							}}
							isSmallContainer={showSelectedQuestions}
							disableCreation
							defaultTab={{
								onRemove,
								canAddQuestion,
								onAdd,
							}}
							noQuestionGroupsExplanation={
								<span>
									Go to the{' '}
									<a href="/questionGroups" target="_blank">
										Question Library
									</a>{' '}
									to add a question group.
								</span>
							}
							title={<></>}
						/>
					)}
				</StyledSelector>
				<div
					showSelectedQuestions={showSelectedQuestions}
					className={classnames(
						'rounded shadow absolute p-0 duration-700 w-1/2 right-0 top-0 bottom-0',
						!showSelectedQuestions && '-mr-[50%]'
					)}>
					<Button
						className={classnames(
							'shadow gap-2 flex items-center justify-center rounded-none rounded-tl rounded-bl absolute top-0',
							showSelectedQuestions
								? '-left-[50px] w-[50px] min-w-[50px]'
								: '-left-[80px] w-[80px] min-w-[80px]'
						)}
						onClick={() => setShowSelectedQuestions(state => !state)}>
						{showSelectedQuestions ? (
							<MdArrowForward className="text-xl" />
						) : (
							<>
								<MdArrowBack className="text-xl" />
								{`${questions.length} / ${simulation?.maxQuestions || 0}`}
							</>
						)}
					</Button>

					<div className="bg-primary-900 overflow-y-auto h-full p-4">
						<div className="text-2xl">
							{simulation ? simulation.name : ' '}{' '}
							<QuestionSpan
								error={questions.length > (simulation?.maxQuestions || 0)}>
								Questions {questions.length} / {simulation?.maxQuestions || 0}
							</QuestionSpan>
						</div>
						{(simulation?.maxQuestions ?? 0) === 0 && (
							<div className="text-[--error] text-base mb-2">
								This mission is not quite ready for questions (but will be soon)
							</div>
						)}
						<div className="text-base mb-2">
							You can add up to {simulation?.maxQuestions ?? 0} questions in this
							mission
						</div>
						<div className="flex flex-col gap-2">
							{questions.map((question: QuestionType, index: number) => (
								<Question
									key={question._id || index}
									question={question}
									teacherQuestion={true}
									isCollapsibleQuestion={true}
									questionIndex={index}
									buttonAction={
										<RequireSignIn>
											<ButtonAction
												Icon={FaMinus}
												onClick={() => {
													setQuestions(questions => {
														const newQuestions = [...questions]
														newQuestions.splice(index, 1)
														return newQuestions
													})
												}}
												questionColor={error}
											/>
										</RequireSignIn>
									}
								/>
							))}
						</div>
					</div>
				</div>
			</StyledModalBody>
			<ModalFooter>
				<GoBackButton outline onClick={() => goBack()}>
					Go Back
				</GoBackButton>
				<Button
					disabled={!simulation}
					onClick={() => {
						if (alertMaxQuestion) {
							toast.error(
								`You have ${questions?.length ??
									0} questions selected. This mission only supports ${simulation?.maxQuestions ??
									'unknown number'} questions.`
							)
						} else {
							dispatch(resetQuestions(questions))
							goBack(false)
						}
					}}>
					Save Questions
				</Button>
			</ModalFooter>
		</>
	)
}

function ShowQuestions({
	questionGroupId,
	canAddQuestion,
	onAdd,
	onRemove,
	onGoBack,
}: {
	questionGroupId: string,
	canAddQuestion: (?string) => boolean,
	onAdd: (question: QuestionType) => void,
	onRemove: (questionId: string) => void,
	onGoBack: () => mixed,
}) {
	const { error, success } = useContext(ThemeContext)
	const { data: _questionGroup, isLoading } = useQuestionGroup(questionGroupId)
	const categoryLookup = toIdMap(useSelector(getSimulationCategories) ?? [])
	const questionGroup = _questionGroup
		? convertQuestionGroup(_questionGroup, categoryLookup)
		: null

	return (
		<>
			<BackMenu
				css="margin: 0; padding: var(--spacing2x-dont-use); position: sticky; top: 0; border-radius: 0;"
				className="bg-primary-900"
				onClick={() => {
					onGoBack()
				}}>
				Question Library
			</BackMenu>
			{isLoading ? (
				<Loading css="width: 100px; height: auto; margin: auto" />
			) : !questionGroup ? (
				<div>Could not load question group</div>
			) : (
				<div css="padding: var(--spacing2x-dont-use);">
					<div className="text-2xl">{questionGroup.name}</div>
					<QuestionGroupAuthor className="mb-1">
						Created By: {questionGroup.createdBy.firstName || ''}{' '}
						{questionGroup.createdBy.lastName || ''}
					</QuestionGroupAuthor>
					{questionGroup.questions.length === 0 ? (
						<div>This question group has no questions</div>
					) : (
						<div className="flex flex-col gap-2 text-primary-50">
							{questionGroup.questions.map(
								(question: QuestionType, index: number) => (
									<Question
										key={question._id || index}
										question={question}
										teacherQuestion
										isCollapsibleQuestion
										questionIndex={index}
										buttonAction={
											<RequireSignIn>
												<ButtonAction
													Icon={
														canAddQuestion(question._id)
															? FaPlus
															: FaMinus
													}
													onClick={
														canAddQuestion(question._id)
															? () => onAdd(question)
															: () => onRemove(question._id)
													}
													questionColor={
														canAddQuestion(question._id)
															? success
															: error
													}
												/>
											</RequireSignIn>
										}
									/>
								)
							)}
						</div>
					)}
				</div>
			)}
		</>
	)
}

// STYLED COMPONENTS

const StyledModalBody = styled(ModalBody)`
	flex-direction: row;
	display: flex;
	padding: 0;
	min-height: 50vh;
	overflow: hidden;
	position: relative;
`

const StyledSelector = styled.div`
	overflow-y: auto;
	transition-duration: 0.7s;
	${({ showSelectedQuestions }) => (showSelectedQuestions ? 'width: 50%' : 'width: 100%')};
`

const QuestionGroupAuthor = styled.div`
	justify-self: end;
	font-size: ${({ theme }) => theme.font};
`

const QuestionSpan = styled.span`
	${({ theme, error }) =>
		error &&
		`
        color: ${theme.error};
    `};
`
