// @flow
import React, { Component } from 'react'
import StudentComponent from './StudentComponent'
import { DropTarget } from 'react-dnd'
import { TeamBoxWrapper, Header, CenteredText } from './TeamBoxStyles'

import type { Student_New } from '../../../../../../models/Student'
import type { Team } from '../RolesHelpers'
import type { TargetDropProps } from '../../../../../../types'

type BaseProps = {
	team: Team,
	onSelect: (team: Team, student: Student_New) => void,
}
type Props = {
	...BaseProps,
	...TargetDropProps,
}

const stationTarget = {
	drop(props: Props, monitor) {
		props.onSelect(props.team, monitor.getItem())
	},
}

function collect(connect, monitor) {
	return {
		connectDropTarget: connect.dropTarget(),
		isOver: monitor.isOver(),
		canDrop: monitor.canDrop(),
	}
}

class TeamLeadBox extends Component<Props> {
	render() {
		const { isOver, canDrop, team, connectDropTarget } = this.props
		const needsLead = team.isRequired ? !team.lead : false
		return connectDropTarget(
			<div className="flex flex-col">
				<Header>Team Lead</Header>
				<TeamBoxWrapper
					className="flex-1"
					boxState={isOver && canDrop ? 'hovered' : needsLead ? 'error' : 'valid'}>
					{needsLead && (
						<CenteredText className="text-primary-50">
							Team must have a Lead
						</CenteredText>
					)}
					{team.lead && (
						<CenteredText>
							<StudentComponent
								student={team.lead}
								container="station-box"
								key={team.lead._id}
							/>
						</CenteredText>
					)}
				</TeamBoxWrapper>
			</div>
		)
	}
}

export default (DropTarget('student', stationTarget, collect)(TeamLeadBox): (
	props: BaseProps
) => React$Node)
