import React from 'react'
import Markdown from 'markdown-to-jsx'
import styled from 'styled-components'
import { MathComponent } from 'mathjax-react'
import type { ThemeType } from '@mission.io/styles'
import classnames from 'classnames'

// components that markdown is allowed to display
const DEFAULT_ALLOWED_COMPONENTS = new Set([
	'pre',
	'div',
	'p',
	'code',
	'li',
	'ol',
	'ul',
	'img',
	'strong',
	'em',
	'span',
	'a',
	'del',
	'u',
])

/**
 * MathLanguage - support rendering the math language (MathJax)
 *
 * @param {Object} props - react props
 * @param {string} props.className - a class name for the rendered component
 * @param {string} props.children - the code string to parse and render
 * @param {string} props.isBlock - true if this should be rendered as a code block, false otherwise
 *
 * @return {React$Node}
 */
function MathLanguage({
	className,
	children,
	isBlock,
}: {
	className?: ?string,
	children?: ?string,
	isBlock?: ?boolean,
}): React$Node {
	if (!children || typeof children !== 'string') {
		return null
	}
	return <MathComponent tex={children} display={isBlock} />
}

const LanguageRenderers = {
	math: MathLanguage,
	katex: MathLanguage,
	latex: MathLanguage,
}

const DEFAULT_OVERRIDES = {
	code: function Code({ className, children, ...rest }) {
		let languageString = className
		let codeSnippet = children
		const isBlock = className?.startsWith('lang-') ?? false
		if (!languageString && typeof children === 'string' && children.startsWith('lang-')) {
			const matches = children.match(/^lang-[^\s]*\s/)
			if (matches?.length) {
				languageString = matches[0].slice(0, matches[0].length - 1)
				codeSnippet = children.slice(languageString.length + 1, children.length)
			}
		}

		const language = languageString?.replace('lang-', '')
		const Component = LanguageRenderers[language] ?? 'code'
		return (
			<Component
				className={`${className || ''} ${language || ''}`}
				{...(typeof Component !== 'string' ? { isBlock } : {})}>
				{codeSnippet}
			</Component>
		)
	},
}

/**
 * NotAllowed - a component rendered in place of unallowed components
 */
function NotAllowed({ children }: { children: React$Node }) {
	return children || null
}

/**
 * MarkdownWithOverrides - display markdown with the default settings
 *
 * @param {Object} props - the react props
 * @param {string} props.children - the markdown to render
 * @param {?Array<string>} props.disabledComponents - the components to refuse to render
 */
export default function MarkdownWithOverrides({
	children,
	disabledComponents,
	className,
}: {
	children: string,
	disabledComponents?: string[],
	className?: string,
}): React$Node {
	const allowedComponents = new Set(DEFAULT_ALLOWED_COMPONENTS)
	const componentOverrides = { ...DEFAULT_OVERRIDES }
	if (disabledComponents) {
		disabledComponents.forEach(option => {
			allowedComponents.delete(option)
			delete componentOverrides[option]
		})
	}
	const overrides = new Proxy(
		{},
		{
			get: function(_, name, ...rest) {
				if (allowedComponents.has(name)) {
					return componentOverrides[name] ?? undefined
				}
				return {
					component: NotAllowed,
				}
			},
		}
	)
	return (
		<StyledMarkdown
			options={{ disableParsingRawHTML: false, overrides }}
			className={classnames('prose prose-invert max-w-none', className)}>
			{children}
		</StyledMarkdown>
	)
}

const StyledMarkdown = styled(Markdown)`
	${({ theme }: { theme: ThemeType }) => `
    & code {
		color: ${theme.primary};
	}
`}
`
