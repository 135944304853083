import type { PreviewProps } from './Previewers/types'
import faFileImage from 'react-icons/lib/fa/file-image-o'
import faFilm from 'react-icons/lib/fa/file-movie-o'
import faMusic from 'react-icons/lib/fa/file-audio-o'
import faFilePdf from 'react-icons/lib/fa/file-pdf-o'

import { Video } from './Previewers/Video'
import { Image } from './Previewers/Image'
import { PDF } from './Previewers/PDF'
import { Audio } from './Previewers/Audio'

const VIDEO_FILE_TYPES = new Set(['mp4', 'mov', 'wav', 'webm', 'm4v'])
export const IMAGE_FILE_TYPES: Set<string> = new Set([
	'jpeg',
	'jpg',
	'jfif',
	'pjpeg',
	'pjp',
	'webp',
	'svg',
	'png',
])
const PDF_FILE_TYPES = new Set(['pdf'])
const AUDIO_FILE_TYPES = new Set(['m4a', 'm4b', 'm4p', 'ogg', 'oga', 'mogg', 'wav', 'mp3'])

export const FILE_EXTENSION_TO_COMPONENT_MAP: {
	[fileExtension: string]: React$ComponentType<PreviewProps>,
} = (() => {
	const map = {}
	VIDEO_FILE_TYPES.forEach(fileType => {
		map[fileType] = Video
	})
	IMAGE_FILE_TYPES.forEach(fileType => {
		map[fileType] = Image
	})
	PDF_FILE_TYPES.forEach(fileType => {
		map[fileType] = PDF
	})
	AUDIO_FILE_TYPES.forEach(fileType => {
		map[fileType] = Audio
	})
	return map
})()

export const FILE_EXTENSION_TO_FILE_ICON: {
	[fileExtension: string]: React$ComponentType<PreviewProps>,
} = (() => {
	const map = {}
	VIDEO_FILE_TYPES.forEach(fileType => {
		map[fileType] = faFilm
	})
	IMAGE_FILE_TYPES.forEach(fileType => {
		map[fileType] = faFileImage
	})
	PDF_FILE_TYPES.forEach(fileType => {
		map[fileType] = faFilePdf
	})
	AUDIO_FILE_TYPES.forEach(fileType => {
		map[fileType] = faMusic
	})
	return map
})()
