import React, { useMemo, useState } from 'react'
import {
	useReactTable,
	getCoreRowModel,
	getSortedRowModel,
	flexRender,
} from '@tanstack/react-table'
import { Button } from '@mission.io/styles'
import { rgba } from '../../../utility/functions'
import styled from 'styled-components/macro'
import { NA, NAME, SEL_SCORE } from '../constants'
import { FaCaretUp, FaCaretDown, FaArrowUp, FaArrowDown } from 'react-icons/lib/fa'
import classnames from 'classnames'

type TableProps = {
	data: any[],
	columns: any[],
	initialState?: { sorting?: Array<{ id: 'name', desc: boolean }> },
	tableBodyKey?: string, // changing this key will cause the entire body of the table to remount
}
const INITIAL_STATE = { sorting: [{ id: NAME, desc: false }] }

/**
 * A table to display data for a group of students in mission analytics.
 * @param {any[]} props.data must be memoized. Each value in the array will be rendered as a row in the table.
 * @param {any[]} props.columns must be memoized. Each value in the array will be rendered as a column in the table.
 * @returns {React$Node} the table component.
 */
export default function StudentsTable({
	data,
	columns,
	initialState = INITIAL_STATE,
	tableBodyKey,
	...props
}: TableProps): React$Node {
	const defaultColumn = useMemo(
		() => ({
			// When using the useFlexLayout:
			minSize: 30, // minSize is only used as a limit for resizing
			size: 50, // size is used for both the flex-basis and flex-grow
			maxSize: 200, // maxSize is only used as a limit for resizing
		}),
		[]
	)
	const table = useReactTable({
		columns,
		data,
		initialState,
		defaultColumn,
		getCoreRowModel: getCoreRowModel(),
		getSortedRowModel: getSortedRowModel(),
	})
	const [extended, setExtended] = useState(false)
	const toggleExtendedTable = () => setExtended(state => !state)

	return (
		<>
			<div className={classnames('overflow-auto', extended ? 'max-h-fit' : 'max-h-[40vh]')}>
				<table className="rounded w-full bg-primary-800 shadow ">
					<thead className="sticky top-0 z-10 rounded-t overflow-hidden">
						{table.getHeaderGroups().map(headerGroup => (
							<tr key={headerGroup.id}>
								{headerGroup.headers.map(header => {
									const sortState:
										| false
										| 'asc'
										| 'desc' = header.column.getIsSorted()
									return (
										<th
											key={header.id}
											id={header.id}
											className={classnames(
												'relative p-2 overflow-hidden cursor-pointer whitespace-nowrap [&>.sort]:absolute [&>.sort]:right-1 [&>.sort]:top-2 first:rounded-tl-lg last:rounded-tr-lg',
												header.id === NAME
													? 'text-xl bg-primary-200 text-primary-950 text-left'
													: 'bg-primary-500 text-white text-center [&:nth-child(2)]:rounded-tl-lg last:rounded-tr-lg text-sm'
											)}
											colSpan={header.colSpan}
											onClick={header.column.getToggleSortingHandler()}>
											{flexRender(
												header.column.columnDef.header,
												header.getContext()
											)}
											{sortState === 'desc' ? (
												<ArrowDown className="sort" />
											) : sortState === 'asc' ? (
												<ArrowUp className="sort" />
											) : null}
										</th>
									)
								})}
							</tr>
						))}
					</thead>
					<tbody key={tableBodyKey}>
						{table.getRowModel().rows.map(row => {
							return (
								<tr key={row.id} className="odd:bg-primary-900">
									{row.getVisibleCells().map(cell => {
										return (
											<Cell
												className="	[&:not(:last-child)]:border-r border-primary-600"
												key={cell.id}
												onClick={e => {
													cell.column.columnDef.meta?.onClick?.(
														e,
														cell.row.original
													)
												}}
												columnId={cell.column.id}
												value={cell.getValue()}>
												{flexRender(
													cell.column.columnDef.cell,
													cell.getContext()
												)}
											</Cell>
										)
									})}
								</tr>
							)
						})}
					</tbody>
				</table>
			</div>
			{data.length > 10 && (
				<div css="display: flex; justify-content: center; width: 100%; ">
					<Button onClick={toggleExtendedTable} className="rounded-t-none">
						Click to {extended ? 'collapse' : 'expand'}{' '}
						{extended ? (
							<FaCaretUp className="mt-[-6px] inline" />
						) : (
							<FaCaretDown className="inline" />
						)}
					</Button>
				</div>
			)}
		</>
	)
}

const ArrowUp = styled(FaArrowUp)`
	margin-left: 3px;
`

const ArrowDown = styled(FaArrowDown)`
	margin-left: 3px;
`
const Cell = styled.td`
	text-align: center;
	padding: 7px 5px;

	${({ columnId: id, value }) => {
		return `
			background-color: ${rgba('var(--accent)', 0.3)};
			font-weight: ${value === NA ? '500' : '501'};
			cursor: ${id !== SEL_SCORE ? 'pointer' : ''};
		`
	}}
`
