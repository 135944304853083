// @flow
import React, { createContext, useContext, useEffect, useState, useMemo } from 'react'
import { clamp } from 'lodash'
import styled from 'styled-components/macro'
import { Button } from '@mission.io/styles'

import type { SelScores, SelScoresByEntityId } from '../../models/Analytics'
import { averageSelScores } from './AnalyticsCharts/dataHelpers'

type ScoresForDataView = $ReadOnlyArray<{
	missionDate: Date,
	scores: ?SelScores,
	studentScores?: SelScoresByEntityId,
}>
export const dataViewEnum = {
	ALL: 'ALL',
	AVERAGED_OVER_WEEKS: 'AVERAGED_OVER_WEEKS',
	AVERAGED_OVER_MONTHS: 'AVERAGED_OVER_MONTHS',
	SEGMENT: 'SEGMENT',
}

const ScoreContext: React$Context<{
	scores: ScoresForDataView,
	viewType: $Keys<typeof dataViewEnum>,
}> = createContext({
	scores: [],
	viewType: dataViewEnum.ALL,
})
export const useScores: () => ScoresForDataView = () => {
	return useContext(ScoreContext).scores
}
export const useViewType: () => $Keys<typeof dataViewEnum> = () => {
	return useContext(ScoreContext).viewType
}

const getFirstDayOf = {
	/* Gets the first day of the month, where the month since the date provided is offset by the offset provided.
	 month(feb3, 0) yields feb1
	 month(feb3, 1) yields march1 */
	month: (date: Date, offset: number): Date => {
		return new Date(date.getFullYear(), date.getMonth() + offset, 1)
	},
	/* Gets the first day of the week, where the week since the date provided is offset by the offset provided.
	 week(wednesdayThe3rd, 0) yields mondayThe1st
	 week(wednesdayThe3rd, 1) yields mondayThe8th */
	week: (date: Date, offset: number): Date => {
		const firstMondayOfWeek = new Date(date.getDate() - (date.getDay() || 7) + 1)
		return new Date(firstMondayOfWeek.getDate() + 7 * offset)
	},
}

/**
 * Given score data determines how the time should be split (by week or by month) also returns the start date and the end date of the scores.
 * @param {ScoresForDataView} data
 * @returns {{dataViewEnumForTime: 'AVERAGED_OVER_MONTHS' | 'AVERAGED_OVER_WEEKS', startDate: Date, endDate: Date}}
 */
const getTimeData = (
	data: ScoresForDataView
): {
	dataViewEnumForTime: 'AVERAGED_OVER_MONTHS' | 'AVERAGED_OVER_WEEKS',
	startDate: Date,
	endDate: Date,
} => {
	const sortedData = [...data].sort((a, b) => a.missionDate - b.missionDate)
	const [startDate, endDate] = [
		sortedData[0].missionDate,
		sortedData[sortedData.length - 1].missionDate,
	]
	const timeSegmentInMs = Math.ceil((endDate - startDate) / MAX_DATA_VIEW_SIZE)
	const dataViewEnumForTime =
		timeSegmentInMs > WEEK_MS
			? dataViewEnum.AVERAGED_OVER_MONTHS
			: dataViewEnum.AVERAGED_OVER_WEEKS
	return { dataViewEnumForTime, startDate, endDate }
}

const MAX_DATA_VIEW_SIZE = 12
const WEEK_MS = 7 * 60 * 60 * 24 * 1000

/**
 * Combine a large group of data into sections of time (months or weeks) and average the combined scores and individual scores during each section of time.
 * @param {ScoresForDataView} data
 * @returns {ScoresForDataView}}
 */
function getAveragesSplitByTime(data: ScoresForDataView): ScoresForDataView {
	const sortedData = [...data].sort((a, b) => a.missionDate - b.missionDate)

	const { dataViewEnumForTime, endDate } = getTimeData(sortedData)
	const timeInterval =
		dataViewEnumForTime === dataViewEnum.AVERAGED_OVER_MONTHS ? 'month' : 'week'

	const buckets = new Array(MAX_DATA_VIEW_SIZE).fill({}).map((_, index) => ({
		missionDate: getFirstDayOf[timeInterval](endDate, -index),
		list: [],
	}))

	buckets.reverse()

	// Insert the data into the correct bucket starting at the given index of the buckets array
	const insertIntoBucket = (data, index: number) => {
		// If we are in the last bucket or later, just add the data to the last bucket
		if (index >= buckets.length - 1) {
			buckets[buckets.length - 1].list.push(data)
			return index
		}
		const missionDate = data.missionDate.getTime()
		const bucketTime = buckets[index].missionDate.getTime()
		const nextBucketTime = buckets[index + 1].missionDate.getTime()

		if (missionDate < bucketTime) {
			// the entry should be added before the current bucket, ignore this entry
			// (as the entries should be sorted before being added, this should only occur for entries before the first bucket)
			return index
		}

		// If my current time is in the current bucket, add it to the current bucket
		if (missionDate < nextBucketTime) {
			buckets[index].list.push(data)
			return index
		}

		// Move to the next bucket
		return insertIntoBucket(data, index + 1)
	}

	let bucketIndex = 0
	sortedData.forEach(data => {
		bucketIndex = insertIntoBucket(data, bucketIndex)
	})
	while (buckets[0]?.list.length === 0) {
		buckets.shift()
	}

	return buckets.map(group => {
		const scoresGroup: Array<SelScoresByEntityId> = []
		group.list.forEach(({ scores }) => {
			scores &&
				scoresGroup.push({
					combined: scores,
				})
		})
		const studentScoresGroup = group.list.map(({ studentScores }) => studentScores || {})
		return {
			missionDate: group.missionDate,
			scores: scoresGroup ? averageSelScores(scoresGroup)?.combined : null,
			studentScores: averageSelScores(studentScoresGroup),
		}
	})
}

// Controls how we view holistic data if there are too many data points.
export default function DataViewContext({
	scores: _scores,
	children,
}: {
	scores: ScoresForDataView,
	children: React$Node,
}): React$Node {
	const [scores, setScores] = useState(() => _scores)
	const dataViewEnumForTime = useMemo(() => getTimeData(_scores).dataViewEnumForTime, [_scores])
	const [viewType, setViewType] = useState<$Keys<typeof dataViewEnum>>(dataViewEnum.ALL)
	const [segmentStartIndex, setSegmentStartIndex] = useState(
		() => scores.length - MAX_DATA_VIEW_SIZE
	)
	useEffect(() => {
		setViewType(_scores.length > MAX_DATA_VIEW_SIZE ? dataViewEnumForTime : dataViewEnum.ALL)
		setSegmentStartIndex(_scores.length - MAX_DATA_VIEW_SIZE)
		setScores(_scores)
	}, [_scores, dataViewEnumForTime])

	useEffect(() => {
		switch (viewType) {
			case dataViewEnum.SEGMENT: {
				setScores(_scores.slice(segmentStartIndex, segmentStartIndex + MAX_DATA_VIEW_SIZE))
				return
			}
			case dataViewEnum.AVERAGED_OVER_MONTHS:
			case dataViewEnum.AVERAGED_OVER_WEEKS: {
				setScores(getAveragesSplitByTime(_scores))
				return
			}
			default: {
				setScores(_scores)
			}
		}
	}, [viewType, _scores, segmentStartIndex])

	return (
		<ScoreContext.Provider value={{ scores, viewType }}>
			{_scores.length > MAX_DATA_VIEW_SIZE && (
				<div css="display: flex; justify-content: space-between; margin-top: var(--spacing2x-dont-use) ">
					{viewType === dataViewEnum.SEGMENT && (
						<div css="display: flex;">
							<SmallButton
								style={{ display: segmentStartIndex === 0 ? 'none' : 'inherit' }}
								onClick={() => {
									setSegmentStartIndex(start =>
										clamp(start - MAX_DATA_VIEW_SIZE, 0, _scores.length)
									)
								}}>
								See previous {MAX_DATA_VIEW_SIZE} missions
							</SmallButton>
							<SmallButton
								style={{
									display:
										segmentStartIndex >= _scores.length - MAX_DATA_VIEW_SIZE
											? 'none'
											: 'inherit',
								}}
								onClick={() => {
									setSegmentStartIndex(start =>
										clamp(start + MAX_DATA_VIEW_SIZE, 0, _scores.length)
									)
								}}>
								See next {MAX_DATA_VIEW_SIZE} missions
							</SmallButton>
						</div>
					)}

					<SmallButton
						onClick={() =>
							setViewType(type =>
								type === dataViewEnum.SEGMENT
									? dataViewEnumForTime
									: dataViewEnum.SEGMENT
							)
						}>
						{viewType === dataViewEnum.SEGMENT ? 'View over time' : 'View per mission'}
					</SmallButton>
				</div>
			)}
			{children && children}
		</ScoreContext.Provider>
	)
}

const SmallButton = styled(Button)`
	padding: 2px 4px;
	:nth-child(2) {
		margin-left: 8px;
	}
`
