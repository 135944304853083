import React, { useState, useCallback, useLayoutEffect, useEffect, useRef, useMemo } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Helmet } from 'react-helmet'
import styled, { keyframes } from 'styled-components/macro'
import { Button } from '@mission.io/styles'
import { useParams, useHistory, useLocation } from 'react-router-dom'
import { useQueryParam, BooleanParam, withDefault } from 'use-query-params'
import { Loading, BackMenu } from '../../../components'
import { getAutomatedSimulationFromId } from '../../../store/simulation'
import { getBannerInfo, getGradesString, getMetaGradeString } from '../../../utility/helpers'
import MissionTabs from './MissionTabs'
import PrepMissionModal from './PrepMissionModal'
import UnlockDirectionsModel from './UnlockDirectionModal'
import { addPrepMission, getMissionPrepData } from '../../../store/missionPrep'
import { getValueFromState } from '../../../utility/functions'
import { READABLE_CONTROL_SETS } from '../../../models/Simulation'
import MissionGuideModal from '../../../components/missionGuideModal'
import { useMissionGuide } from '../../../components/missionGuideModal'
import { useUserStandardState } from '../../../services/hooks/user'
import {
	getMostRelevantStandards,
	groupStandardsForState,
	type CurriculumStandard,
} from '../../../utility/standards'
import { validate as validateQuestion } from '@mission.io/question-toolkit'
import { Tooltip } from 'react-tooltip'

import { useSimulationPermissions } from '../../../services/hooks/permissions'
import { LockableButton } from '../../../components/helpers/Lock'
import MdLock from 'react-icons/lib/md/lock'
import FaClock from 'react-icons/lib/fa/clock-o'

import { missionCategoriesEnum } from '../../../store/types'
import type { AutomatedSimulation } from '../../../models'
// $FlowFixMe ReactComponent is not defined in the module namespace
import { ReactComponent as PlayButton } from '../../../assets/PlayButton.svg'
import { AUTOMATED_SIMULATION_STATUSES, STATE_KEYS } from '../../../constants'

import type { StyledType } from 'global-types'
import { useTheme } from 'styled-components'
import { useStandardSets } from '../../../services/hooks/standards'

/**
 * Represents items on the page that can be spotlighted
 */
export const SPOTLIGHTS = {
	MISSION_GUIDE: {
		id: 'MISSION_GUIDE',
		elementId: 'mission-guide-button',
	},
	BRIEFING_VIDEO: {
		id: 'BRIEFING_VIDEO',
		elementId: 'simulation-media',
	},
}
const DEFAULT_MISSION_CATEGORY = missionCategoriesEnum.ON_SITE

export default function PrepMission(): React$Node {
	const dispatch = useDispatch()
	const [showPrepMissionModal, setShowPrepMissionModal] = useQueryParam('prepping', BooleanParam)
	const [showUnlockModal, setShowUnlockModal] = useState(false)
	const { id } = useParams()
	const history = useHistory()
	const location = useLocation()
	// If mission library set the `restoreToTop` state, then we know the user came from the mission library
	const visitedFromMissionLibrary = !!getValueFromState(location.state, STATE_KEYS.restoreToTop)

	const simulation: ?AutomatedSimulation = useSelector(getAutomatedSimulationFromId(id))
	const userState = useUserStandardState()
	const missionPrep = useSelector(getMissionPrepData)

	const { data: missionGuide } = useMissionGuide(id)

	const spotlight = typeof location.state === 'object' ? location.state?.spotlight : null
	/**
	 * Function that makes the spotlight go away by removing it from the location state
	 */
	const removeSpotlight = useCallback(() => {
		history.replace({ ...history.location, state: undefined })
	}, [history])
	const simulationPermissions = useSimulationPermissions(id)

	const prepMission = useCallback(() => {
		if (!simulation || !id) {
			return
		}
		const questions = simulation.questions.filter(q => validateQuestion(q) == null)

		dispatch(
			addPrepMission({
				simulationId: id,
				missionCategory: DEFAULT_MISSION_CATEGORY,
				questions: questions,
				useJrPlus: simulation.controlSet === READABLE_CONTROL_SETS.JUNIOR_PLUS,
				juniorTeams: null,
				beginPrepTimestamp: new Date(),
			})
		)
		setShowPrepMissionModal(true, 'replaceIn')
	}, [dispatch, id, simulation, setShowPrepMissionModal])

	/* In case the web page is loaded with the query param "prepping" set (this will show the mission prep modal),
	 the prepped mission in the redux store needs to be loaded.
	 */
	useEffect(() => {
		if (showPrepMissionModal && !missionPrep) {
			prepMission()
		}
	}, [showPrepMissionModal, missionPrep, prepMission])

	const userCanRunThisMission = Boolean(simulationPermissions?.userCanRun)
	const isComingSoon = simulation?.status === AUTOMATED_SIMULATION_STATUSES.COMING_SOON

	if (simulation && simulationPermissions) {
		const metaTitle = `${
			simulation.subjectMatter ? simulation.subjectMatter + ':' : simulation.name
		} Epic ${getMetaGradeString(simulation.grades)}Classroom Mission`
		const metaDescription = `Lead your ${getMetaGradeString(
			simulation.grades
		)}class through a high-stakes mission${
			simulation.subjectMatter ? ' on ' + simulation.subjectMatter : ''
		}. Dive into 100+ immersive classroom adventures!`
		const backMenuProps = visitedFromMissionLibrary
			? { onClick: () => history.goBack() }
			: // If the user didn't come from the mission library, then we want to make sure this button takes them to the mission library
			  {
					link: {
						pathname: '/missionLibrary',
					},
			  }
		return (
			<>
				<Helmet>
					<title>{metaTitle}</title>
					<meta name="description" content={metaDescription} />
					<meta name="og:description" content={metaDescription} />
					{simulation.imageUrl && <meta name="og:image" content={simulation.imageUrl} />}
				</Helmet>
				<BackMenu {...backMenuProps}>Mission Library</BackMenu>
				<PrepMissionWrapper>
					<HeaderInfo>
						<div className="shrink">
							<h1 className="text-3xl flex gap-2">
								{!userCanRunThisMission ? <MdLock className="text-error" /> : null}
								{simulation.name}
							</h1>
							<div className="flex items-center">
								<div>{simulation.subjectMatter}</div>
								{simulation.grades && simulation.grades.length > 0 && (
									<div className="border-l border-[currentColor] ml-4 my-2 px-4">
										{getGradesString(simulation.grades)}
									</div>
								)}
							</div>
							{simulationPermissions.userCanOnlyRunDueToPromotion ? (
								<div
									onClick={() => setShowUnlockModal(true)}
									className="flex-none gap-1 text-sm items-center rounded-full inline-flex px-2 text-space-blue bg-success"
									title={
										simulationPermissions.userCanOnlyRunDueToPromotion.expires?.toLocaleString() ??
										''
									}>
									<FaClock />
									{simulationPermissions.userCanOnlyRunDueToPromotion?.expires ? (
										<div>
											Available Until
											{simulationPermissions.userCanOnlyRunDueToPromotion.expires.toLocaleDateString()}
										</div>
									) : null}
								</div>
							) : null}
						</div>
						<div className="shrink-[7]">
							<StandardUserCategories
								standards={simulation.standards}
								userState={userState}
							/>
						</div>
					</HeaderInfo>
					<hr className="mb-4" />

					<div
						className="w-full lg:w-4/5 xl:w-3/5 m-auto "
						data-walkthrough="simulation-info">
						<SimulationMedia simulation={simulation} isComingSoon={isComingSoon} />
						<div className="w-full p-3 bg-primary-700 flex justify-end items-center rounded-b-lg gap-2">
							{missionGuide && <MissionGuideButton simulationId={simulation._id} />}
							<LockableButton
								data-walkthrough="create-mission"
								onUnlockClick={prepMission}
								onLockClick={() => {
									if (isComingSoon) {
										return
									}
									setShowUnlockModal(true)
								}}
								isLocked={!userCanRunThisMission}
								greyOut={!userCanRunThisMission}
								isUnlockedDueToPromotion={Boolean(
									simulationPermissions.userCanOnlyRunDueToPromotion
								)}>
								{isComingSoon && !userCanRunThisMission
									? 'Coming Soon'
									: 'Create Mission'}
							</LockableButton>
						</div>
						<MissionTabs simulation={simulation} />
					</div>
					{showPrepMissionModal && (
						<PrepMissionModal
							onClose={() => setShowPrepMissionModal(undefined, 'replaceIn')}
						/>
					)}
					{showUnlockModal && (
						<UnlockDirectionsModel
							onRequestClose={() => setShowUnlockModal(false)}
							simulationId={simulation._id}
						/>
					)}
				</PrepMissionWrapper>
				{typeof spotlight === 'string' && SPOTLIGHTS.hasOwnProperty(spotlight) && (
					<Spotlight
						target={SPOTLIGHTS[spotlight].elementId}
						onClickTarget={removeSpotlight}
						targetIsReady={
							spotlight === SPOTLIGHTS.MISSION_GUIDE ? !!missionGuide : true
						}>
						{spotlight === SPOTLIGHTS.BRIEFING_VIDEO.id
							? 'Click to watch'
							: 'Click to open'}
					</Spotlight>
				)}
			</>
		)
	} else {
		return <Loading className="m-8" size="small" />
	}
}

const overlayZIndex = 100
/**
 * Component that will spotlight an element given it's ID. The "spotlight" effect includes adding an overlay to the
 * entire screen, and then increasing the z-index of the target element to be above the overlay. A tooltip will
 * point to the spotlighted element using `children` as the content.
 * @param {string} target The ID of the element that should be spotlighted
 * @param {React$Node} children The content to display inside of the tooltip
 * @param {Function} onClickTarget A function to call when the target element is clicked
 * @param {?boolean} [targetIsReady=true] Whether the target is ready to be found yet. This can be used to delay displaying
 *                                  the tooltip until the target element is ready, avoiding errors querying for the element.
 */
function Spotlight({
	target,
	children,
	onClickTarget,
	targetIsReady = true,
}: {
	target: string,
	children: React$Node,
	onClickTarget: () => mixed,
	targetIsReady?: boolean,
}) {
	const [spotlightTooltipIsOpen, setSpotlightTooltipIsOpen] = useState(false)
	useEffect(() => {
		if (!targetIsReady) {
			return
		}
		// The initial state of the tooltip cannot be open because it might render before the target element.
		// Open the tooltip here instead
		setSpotlightTooltipIsOpen(true)
	}, [targetIsReady])

	useLayoutEffect(() => {
		const targetElement = document.getElementById(target)
		if (!targetElement) {
			return
		}

		const oldZIndex = targetElement.style.zIndex
		targetElement.style.zIndex = String(overlayZIndex + 1)
		targetElement.addEventListener('click', onClickTarget)
		return () => {
			targetElement.style.zIndex = oldZIndex
			targetElement.removeEventListener('click', onClickTarget)
		}
	}, [target, onClickTarget])

	return (
		<>
			<div
				css={`
					position: fixed;
					top: 0;
					left: 0;
					height: 100vh;
					width: 100vw;
					background-color: var(--app-dark-gray);
					opacity: 0.7;
					z-index: ${overlayZIndex};
					pointer-events: none;
				`}
			/>
			<SpotlightTooltip placement="left" isOpen={spotlightTooltipIsOpen} target={target}>
				{children}
			</SpotlightTooltip>
		</>
	)
}

// A double bounce animation with a pause at the end
const horizontalDoubleBounce = keyframes`
	0% {
		left: 0;
	}

	20% {
		left: -5px;
	}

	40% {
		left: 0;
	}

	60% {
		left: -5px;
	}

	80% {
		left: 0;
	}

	100% {
		left: 0;
	}
`

const SpotlightTooltip = styled(Tooltip).attrs({
	innerClassName: 'spotlight-container',
	arrowClassName: 'spotlight-arrow',
	className: '[&_.spotlight-container]:text-base',
})`
	animation: ${horizontalDoubleBounce} 1000ms ease-in infinite;

	.spotlight-container {
		max-width: 300px;
		background-color: var(--secondary);
		padding: var(--spacing2x-dont-use);
		border-radius: 8px;
	}
	.spotlight-arrow::before {
		border-left-color: var(--secondary);
	}
`

/**
 * The media highlight for the given simulation. Starts out as an image using the simulation's `imageUrl`. If the simulation has an attached
 * briefing video, displays a play button over the image and plays the video when clicked.
 */
function SimulationMedia({
	simulation,
	isComingSoon,
}: {
	simulation: AutomatedSimulation,
	isComingSoon?: boolean,
}) {
	const [showBriefingPlayer, setShowBriefingPlayer] = useState(false)

	const briefingVideoRef = useRef(null)

	const handlePlayBriefingVideo = () => {
		if (briefingVideoRef.current) {
			briefingVideoRef.current.play()
		}
		setShowBriefingPlayer(true)
	}
	const banner = getBannerInfo(simulation?.status)

	return (
		<BriefingContainer
			className="rounded-t-lg"
			id={SPOTLIGHTS.BRIEFING_VIDEO.elementId}
			onClick={() =>
				showBriefingPlayer || !simulation.briefingVideoUrl
					? null
					: handlePlayBriefingVideo()
			}
			thumbnailUrl={
				simulation.imageUrl ||
				'https://causeofaction.org/wp-content/uploads/2013/09/Not-available.gif'
			}
			showBriefingPlayer={showBriefingPlayer}
			cursorPointer={!!simulation.briefingVideoUrl}>
			{simulation.briefingVideoUrl && !showBriefingPlayer && <StyledPlayButton />}
			{simulation.briefingVideoUrl && (
				<video ref={briefingVideoRef} src={simulation.briefingVideoUrl} controls />
			)}
			{banner && <Banner $color={banner.color}>{banner.text}</Banner>}
		</BriefingContainer>
	)
}

/**
 * A button that when clicked, opens the mission guide for the given simulation. If the given
 * simulation does not have a mission guide, returns `null`
 */
function MissionGuideButton({ simulationId }: { simulationId: string }) {
	const [isOpen, setIsOpen] = useQueryParam('guide', withDefault(BooleanParam, false), {
		updateType: 'replaceIn',
		removeDefaultsFromUrl: true,
	})

	return (
		<>
			<Button
				id={SPOTLIGHTS.MISSION_GUIDE.elementId}
				onClick={() => setIsOpen(true)}
				variant="secondary"
				outline>
				Mission Guide
			</Button>
			<MissionGuideModal
				simulationId={simulationId}
				isOpen={isOpen}
				onRequestClose={() => setIsOpen(false)}
			/>
		</>
	)
}

/**
 * A bubble that displays a standard. expects a `color` prop that is a valid css color
 */
export const StandardBubble: StyledType<> = styled.span`
	${({ theme, color, isLink }) => `
		border: 1px solid ${color};
		border-radius: var(--spacing5x-dont-use);
		color: ${color};
		padding: var(--spacing-half-dont-use) var(--spacing2x-dont-use);
		${
			isLink
				? `
		text-decoration: underline;

		&:hover {
			background-color: ${color.startsWith('#') ? color + '10' : color};
			cursor: pointer;
		}
		`
				: ``
		}
	`}
`

/**
 * StandardUserCategories - display the standards applicable to the user as well as an option to see all the other standards for the simulation.
 *
 * @param {Object} props - the react props
 * @param {?CurriculumStandard[]} props.standards - all the standards for the simulation
 * @param  {?string} props.userState - the state the user is a part of
 *
 * @returns React$Node
 */
export function StandardUserCategories({
	standards,
	userState,
}: {
	standards: CurriculumStandard[],
	userState: ?string,
}): React$Node {
	const [showingAll, setShowingAll] = useState(false)
	const { data: standardSets } = useStandardSets()

	const userStandards = useMemo(
		() =>
			standardSets
				? getMostRelevantStandards({
						stateAbbreviation: userState,
						standards,
						standardSets,
				  })
				: null,
		[userState, standards, standardSets]
	)
	const userStandardCategories = useMemo(
		() => (standardSets ? groupStandardsForState(userState, standards, standardSets) : null),
		[userState, standards, standardSets]
	)
	const theme = useTheme()

	const canShowMore = userStandards && userStandards.length < standards.length && !showingAll

	if (!showingAll) {
		return (
			<Standards
				standards={userStandards || []}
				afterChild={
					canShowMore && (
						<LinkBubble>
							<StandardBubble
								key="show-more"
								color={theme.primary100}
								isLink={true}
								onClick={() => setShowingAll(true)}>
								Other Standards
							</StandardBubble>
						</LinkBubble>
					)
				}
			/>
		)
	}

	return (
		<>
			<button onClick={() => setShowingAll(false)} className="mb-2">
				<StandardBubble key="show-more" color={theme.primary100} isLink={true}>
					Hide Other Standards
				</StandardBubble>
			</button>
			{userStandardCategories?.stateSpecific.length ? (
				<Standards
					standards={userStandardCategories.stateSpecific}
					beforeChild={<div>State Standards ({userState}):</div>}
				/>
			) : null}
			{userStandardCategories?.nationSpecific.length ? (
				<Standards
					standards={userStandardCategories.nationSpecific}
					beforeChild={<div>National Standards (USA):</div>}
				/>
			) : null}
		</>
	)
}

/**
 * Displays a list of colored bubbles, one for each standard given in `standards`.
 *
 * @param {{standards: CurriculumStandard[]}} {standards} - the standards to display
 * @param beforeChild a child to display as the first element in the list of standards
 * @param afterChild a child to display as the last element in the list of standards
 *
 * @return {React$Node}
 */
export function Standards({
	standards,
	beforeChild,
	afterChild,
}: {
	standards: CurriculumStandard[],
	beforeChild?: React$Node,
	afterChild?: React$Node,
}): React$Node {
	return (
		<div className="flex flex-wrap mb-2 gap-2 items-baseline">
			{beforeChild}
			{standards.map(({ standardSet, name, url }, index) => {
				const Wrapper = url ? LinkBubble : NoWrapSpan
				const props = url
					? {
							href: url,
							rel: 'noreferrer noopener',
					  }
					: {}
				return (
					<Wrapper {...props} key={index}>
						<StandardBubble key={index} color={standardSet.color} isLink={Boolean(url)}>
							{standardSet.abbreviation} {name}
						</StandardBubble>
					</Wrapper>
				)
			})}
			{afterChild}
		</div>
	)
}

const NoWrapSpan = styled.span`
	white-space: nowrap;
`

const LinkBubble = styled.a`
	height: 100%;
	color: inherit;

	&:hover {
		color: inherit;
	}
`

const PrepMissionWrapper = styled.div`
	${({ theme }) => `
		padding: 0 var(--spacing2x-dont-use);
	`}
`

const HeaderInfo = styled.div`
	display: flex;
	justify-content: space-between;
	${({ theme }) => `
		margin: var(--spacing1x-dont-use) var(--spacing1x-dont-use) var(--spacing1x-dont-use) 0;
	`}
`

// const PickButton = styled(Button)`
// 	background-color: inherit;
// 	display: block;
// 	align-items: center;
// 	display: flex;
// 	justify-content: center;
// 	${({ theme }) => `
// 		margin: 0 var(--spacing1x-dont-use) 0 var(--spacing1x-dont-use) !important;
// 	`}
// `

const BriefingContainer = styled.div`
	${({ theme, thumbnailUrl, showBriefingPlayer, cursorPointer }) => `
		position: relative;
		width: 100%;
		height: 30vw;
  	background:url(${thumbnailUrl}) 50% 50% no-repeat;
  	background-size:cover;
  	background-clip:content-box;
		cursor: ${cursorPointer ? 'pointer' : 'initial'};

		video {
			width: 100%;
			height: 30vw;
			background-color: ${theme.black};
			visibility: ${showBriefingPlayer ? 'inherit' : 'hidden'}
		}
	`}
	overflow: hidden;
`

const StyledPlayButton = styled(PlayButton)`
	position: absolute;
	width: 15%;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
`

const Banner = styled.div.attrs({
	className:
		'absolute w-full h-[10%] flex items-center justify-center -rotate-[35deg] bottom-[13%] left-[36%] italic origin-[50%_50%] text-2xl pointer-events-none',
})`
	${({ theme, $color }) => `
		background-color: ${$color};
		color: ${theme.white};
	`}
`
