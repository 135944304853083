import React, { type ComponentType } from 'react'
import { useContext } from 'react'
import styled, { ThemeContext } from 'styled-components'
import { IconCircleWrapper } from '../styles/sharedComponents'
import type { ThemeType } from '@mission.io/styles'

// This is a button used in a Collapsible Question
// that allows adding or removing a question in missions.
const QuestionButtonAction = ({
	Icon,
	onClick,
	questionColor,
}: {
	Icon?: ?ComponentType<{}>,
	onClick?: ?() => void,
	questionColor: ?string,
}): React$Node => {
	const { white }: ThemeType = useContext(ThemeContext)
	return Icon && onClick ? (
		<CircleWrapper>
			<IconWrapper onClick={onClick} color={questionColor}>
				<Icon fill={white} />
			</IconWrapper>
		</CircleWrapper>
	) : null
}

const CircleWrapper = styled.div`
	display: inline-block;
`

const IconWrapper = styled(IconCircleWrapper)`
	${({ theme }) => `
        padding: var(--spacing1x-dont-use);
        margin-right: var(--spacing1x-dont-use);
    `};
	background: ${({ color, theme }) => {
		if (color) {
			return color
		}
		return theme.neutralDark
	}};
`

export default QuestionButtonAction
