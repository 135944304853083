import type { UseQueryOptions, QueryClient } from 'react-query'
import axios from 'axios'
import config from '../config'
import type { ClientQuestion } from '@mission.io/question-toolkit'
import { QUESTION_GROUP_0 } from '../constants/exampleData'

export type DBQuestion = ClientQuestion
/**
 * getQuestionQueryKey - a query key that react-query uses to identify a question resource
 *
 * @param {string} questionId - the id of the question
 * @param {number} questionVersion - the version of the question
 * @param {string} hasUser - if there is a user signed in
 * @return {(string | number)[]} - the react-query question key
 */
function getQuestionQueryKey(
	questionId: string,
	questionVersion: number,
	hasUser: string
): (string | number)[] {
	return ['question', questionId, questionVersion, hasUser.toString()]
}

/**
 * getQuestion - generates a react-query object to to get the question from the server
 *
 * @param {string} questionId - the id of the question
 * @param {number} questionVersion - the version of the question
 *
 * @return {UseQueryOptions<ClientQuestion, Error, ClientQuestion>} - object for react-query
 */
export function getQuestion(
	questionId: string,
	questionVersion: number
): UseQueryOptions<ClientQuestion, Error, ClientQuestion> {
	return {
		queryKey: getQuestionQueryKey(questionId, questionVersion, 'n/a'),
		queryFn: () =>
			axios
				.get(
					`${config.missionsAPIURL}/api/questions?questionKeys[]=${questionId},${questionVersion}`,
					{
						withCredentials: true,
					}
				)
				.then(res => res.data?.[0]),
	}
}

/**
 * getQuestion - generates a react-query key object to to get the list of questions from the server
 *    If the query succeeds, it will also populate all the individual questions in the react-query cache
 *
 * @param {Array<{ questionId: string, questionVersion: number }>} questions - a list of the questionIds and versions to get
 * @param {QueryClient} queryClient - the react-query client to populate the individual questions in
 *
 * @return {UseQueryOptions<ClientQuestion[], Error, ClientQuestion[]>} - object for react-query
 */
export function getQuestions(
	questions: { [questionId: string]: Set<number> },
	queryClient: QueryClient,
	hasUser: boolean
): UseQueryOptions<ClientQuestion[], Error, ClientQuestion[]> {
	const questionKey: Array<string | number> = ['questions']
	let searchString: string = ``

	// Create query key from question data
	Object.keys(questions)
		.sort() // sort question ids
		.forEach((questionId: string) => {
			Array.from(questions[questionId])
				.sort() // sort versions
				.forEach((questionVersion: number) => {
					questionKey.push(questionId, questionVersion)
					searchString += `${
						searchString ? '&' : ''
					}questionKeys[]=${questionId},${questionVersion}`
				})
		})

	questionKey.push(hasUser.toString())
	return {
		queryKey: questionKey,
		queryFn: () => {
			if (!hasUser)
				return QUESTION_GROUP_0.questions.filter(question =>
					Object.keys(questions).includes(question._id)
				)
			return axios
				.get(`${config.missionsAPIURL}/api/questions?${searchString}`, {
					withCredentials: true,
				})
				.then(res => res.data)
		},
		onSuccess: (questions: ClientQuestion[]) => {
			if (questions) {
				questions.forEach(question => {
					queryClient.setQueryData(
						getQuestionQueryKey(question._id, question.version, hasUser.toString()),
						question
					)
				})
			}
		},
		enabled: Object.keys(questions).length > 0,
	}
}
