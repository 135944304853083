import React from 'react'
import config from '../config'
import { toast } from 'react-toastify'
import SimpleConfirmation, {
	ACTIONABLE_TOAST_PROPERTIES,
} from '../components/helpers/SimpleConfirmation'

type RequestOptions = {
	method?: 'GET' | 'PUT' | 'POST' | 'PATCH' | 'DELETE',
	body?: string | Object,
	headers?: { [key: string]: string },
	host?: string,
	retryUrl?: string,
	retryConfirmText?: string,
	credentials?: 'omit' | 'same-origin' | 'include',
	headers?: { [string]: string },
	redirect?: 'follow' | 'error' | 'manual',
}

const API_PREFIX = config.missionServerUrl + '/api'

export default class NetworkCommunicator {
	static GET(path: string, options?: RequestOptions): Promise<Object> {
		return this.request(path, { ...options, method: 'GET' })
	}

	static PUT(path: string, options?: RequestOptions): Promise<Object> {
		return this.request(path, { ...options, method: 'PUT' })
	}

	static POST(path: string, options?: RequestOptions): Promise<Object> {
		return this.request(path, { ...options, method: 'POST' })
	}

	static DELETE(path: string, options?: RequestOptions): Promise<Object> {
		return this.request(path, { ...options, method: 'DELETE' })
	}

	static PATCH(path: string, options?: RequestOptions): Promise<Object> {
		return this.request(path, { ...options, method: 'PATCH' })
	}

	static request(path: string, originalOptions: RequestOptions): Promise<Object> {
		let { retryUrl, retryConfirmText, host, ..._options } = originalOptions
		let options: RequestOptions = _options
		const isFormData = options.body instanceof FormData === true

		const url = host ? host + path : API_PREFIX + path

		options = {
			credentials: 'include',
			headers: ({
				'X-Mission-Origin': window.location.href,
			}: { [key: string]: string }),
			redirect: 'follow',
			...options,
		}

		if (!isFormData && options.body && typeof options.body !== 'string') {
			options.body = JSON.stringify(options.body)
			if (!options.headers) {
				options.headers = {}
			}
			options.headers.Accept = 'application/json'
			options.headers['Content-Type'] = 'application/json'
		}

		delete options.host

		let response: Response
		let responseOK

		return new Promise((resolve, reject) => {
			fetch(url, (options: any))
				.then((result: Response) => {
					response = result
					responseOK = result.ok

					const contentType = response.headers.get('content-type')
					if (contentType && contentType.includes('application/json')) {
						return result.json()
					}

					result.text().then(body => {
						console.error(body)
						throw new TypeError(
							`Did not receive JSON :( for url: ${url}, method: ${options.method ||
								'UNKNOWN'}`
						)
					})
				})
				.then(result => {
					if (responseOK) {
						resolve(result)
						return
					}
					const rejectionResponse = {
						...result,
						headers: response.headers,
						status: response.status,
					}
					if (result?.clientMessage) {
						if (!retryUrl) {
							toast.error(result.clientMessage)
							return reject(rejectionResponse)
						} else {
							toast.warning(
								({ closeToast }) => (
									<SimpleConfirmation
										message={result.clientMessage}
										confirmText={retryConfirmText || 'Retry'}
										onConfirm={() => {
											this.request(retryUrl, originalOptions)
												.then(result => {
													closeToast()
													resolve(result)
												})
												.catch(reject)
										}}
										onCancel={() => {
											closeToast()
											reject()
										}}
									/>
								),
								{
									...ACTIONABLE_TOAST_PROPERTIES,
									position: 'top-center',
									onClose: reject,
								}
							)
							return
						}
					}
					return reject(rejectionResponse)
				})
				.catch(reject)
		})
	}
}
