import React from 'react'
import EmptyQuestionLibrary from '../../../assets/NoData.png'
import { useSelector } from 'react-redux'
import styled, { ThemeContext } from 'styled-components/macro'
import { ControlledTabs, Loading, QuestionButtonAction as ButtonAction } from '../../../components'
import { useSortedQuestionGroups, canReadQuestionGroups } from '../../../store/question'
import { FlexContainer, GridContainer } from './basics'
import { Button } from '@mission.io/styles'
import { useHash } from '../../../utility/hooks'
import { BrowseTab } from './BrowseSection'

import { Question } from '@mission.io/question-views'
import QuestionGroupView from './QuestionGroupCard'
import type { Node } from 'react'
import type { SubjectType } from '../../../models/QuestionGroup'
import type { QuestionGroupWithFavorite } from '../../../models'
import type { ClientQuestion as QuestionType } from '@mission.io/question-toolkit'
import { getDefaultQuestionsForMission } from '../../../store/missionPrep'
import { FaMinus, FaPlus } from 'react-icons/lib/fa'
import { useContext } from 'react'
import RequireSignIn from '../../../components/helpers/RequireSignIn'

export type QuestionLibraryProps = {
	onSelect: (questionGroupId: string) => void,
	noQuestionGroupsExplanation?: ?Node,
	filters: {
		grades: string[],
		subjects: string[],
		search: string,
	},
	addQuestionGroup?: () => mixed,
	filtersSection: React$Node,
	clearFilters: () => mixed,
	defaultTab?: ?{
		canAddQuestion: string => boolean,
		onAdd: (question: QuestionType) => void,
		onRemove: (questionId: string) => void,
	},
}

export default function QuestionLibrary(props: QuestionLibraryProps): React$Node {
	const {
		myQuestionGroups,
		schoolQuestionGroups,
		districtQuestionGroups,
		favoriteQuestionGroups,
	} = useSortedQuestionGroups()
	const readQuestionGroups = useSelector(canReadQuestionGroups)
	const [hash, setHash] = useHash()
	const tab = hash.slice(1) || 'questions'
	const { success, error } = useContext(ThemeContext)
	const defaultQuestionGroup: QuestionType[] = useSelector(getDefaultQuestionsForMission) || []

	const applyFilter = (groups: QuestionGroupWithFavorite[]) => {
		const { grades, subjects, search: searchTerm } = props.filters

		let gradeFilter = (gradeFilter: string[]) => false
		if (grades.length !== 0) {
			const wantedGrades = new Set(grades)

			gradeFilter = (groupGrades: string[]) => groupGrades.some(gg => wantedGrades.has(gg))
		}

		let subjectFilter = (subject: ?(SubjectType[])) => true
		if (subjects.length !== 0) {
			const wantedSubjects = new Set(subjects)

			subjectFilter = (subjects: ?(SubjectType[])) => {
				const validateId = (subjectIds: string[]) =>
					subjectIds.some(subjectId => wantedSubjects.has(subjectId))

				const subjectIds = subjects?.map(s => s.id) ?? []
				return Boolean(subjects && validateId(subjectIds))
			}
		}

		let nameFilter = (name: string) => true
		if (searchTerm !== '') {
			nameFilter = (name: string) => name.toLowerCase().includes(searchTerm.toLowerCase())
		}

		return groups.filter(
			(group: QuestionGroupWithFavorite) =>
				gradeFilter(group.grades) && subjectFilter(group.subjects) && nameFilter(group.name)
		)
	}

	const { noQuestionGroupsExplanation, onSelect, filtersSection, addQuestionGroup } = props

	if (!readQuestionGroups) {
		return <Loading css="width: auto; height: 100px;" />
	}

	const tabs = []

	if (props.defaultTab) {
		tabs.push({
			key: 'default-questions',
			tabNode: 'Default Questions',
			content: (
				<div key="default-questions" name="Default Questions" className="text-primary-50">
					{defaultQuestionGroup.length === 0 ? (
						<FlexContainer>
							<img
								src={EmptyQuestionLibrary}
								alt="No Question Groups"
								css="height: 30vh; margin: 0 auto var(--spacing1x-dont-use) auto;"
							/>
							<p className="text-2xl">
								There are no default questions for this mission
							</p>
						</FlexContainer>
					) : (
						<div className="flex flex-col gap-2">
							{defaultQuestionGroup.map((question: QuestionType, index: number) => {
								const canAdd = props.defaultTab?.canAddQuestion(question._id)
								return (
									<Question
										key={question._id}
										question={question}
										teacherQuestion
										readOnly
										isCollapsibleQuestion
										questionIndex={index}
										buttonAction={
											<ButtonAction
												Icon={canAdd ? FaPlus : FaMinus}
												questionColor={canAdd ? success : error}
												onClick={() => {
													if (canAdd) {
														props.defaultTab?.onAdd(question)
													} else {
														props.defaultTab?.onRemove(question._id)
													}
												}}
											/>
										}
									/>
								)
							})}
						</div>
					)}
				</div>
			),
		})
	}

	tabs.push(
		{
			key: 'questions',
			tabNode: 'My Questions',
			content: (
				<div key="questions" name="My Questions">
					{filtersSection}
					{myQuestionGroups.length === 0 ? (
						<FlexContainer>
							<img
								src={EmptyQuestionLibrary}
								alt="No Question Groups"
								css="height: 30vh; margin: 0 auto var(--spacing1x-dont-use) auto;"
							/>
							<p className="text-2xl">
								Looks like you don’t have any question groups!
							</p>
							<p className="text-2xl">
								{noQuestionGroupsExplanation ||
									'Hit Add Question Group to create your own questions'}
							</p>
						</FlexContainer>
					) : (
						<GridContainer>
							{applyFilter(myQuestionGroups).map(q => {
								return (
									<QuestionGroupView
										key={q._id}
										questionGroup={q}
										onClick={() => onSelect(q._id)}
									/>
								)
							})}
						</GridContainer>
					)}
					<div css="margin-top: var(--spacing3x-dont-use);">
						<h4 className="font-semibold text-2xl mb-2">My Favorites</h4>
						<GridContainer>
							{favoriteQuestionGroups.length === 0 ? (
								<>You have not favorited any question groups</>
							) : (
								<>
									{applyFilter(favoriteQuestionGroups).map(q => {
										return (
											<QuestionGroupView
												key={q._id}
												questionGroup={q}
												onClick={() => onSelect(q._id)}
											/>
										)
									})}
								</>
							)}
						</GridContainer>
					</div>
				</div>
			),
		},
		{
			key: 'school',
			tabNode: 'My School',
			content: (
				<div key="school" name="My School">
					{filtersSection}
					<GridContainer>
						{applyFilter(schoolQuestionGroups).map(q => {
							return (
								<QuestionGroupView
									key={q._id}
									questionGroup={q}
									onClick={() => onSelect(q._id)}
								/>
							)
						})}
					</GridContainer>
				</div>
			),
		}
	)

	if (districtQuestionGroups.length) {
		tabs.push({
			key: 'district',
			tabNode: 'My District',
			content: (
				<div key="district" name="My District">
					{filtersSection}
					<GridContainer>
						{applyFilter(districtQuestionGroups).map(q => {
							return (
								<QuestionGroupView
									key={q._id}
									questionGroup={q}
									onClick={() => onSelect(q._id)}
								/>
							)
						})}
					</GridContainer>
				</div>
			),
		})
	}

	tabs.push({
		key: 'browse',
		tabNode: 'Browse',
		content: (
			<div key="browse" name="Browse">
				{tab === 'browse' ? (
					<BrowseTab
						onSelect={onSelect}
						filtersSection={filtersSection}
						filters={props.filters}
					/>
				) : null}
			</div>
		),
	})

	return (
		<div className="QuestionLibrary" css="position: relative;">
			<RequireSignIn>
				<ButtonWrapper>
					{addQuestionGroup && (
						<Button onClick={addQuestionGroup}>Add Question Group</Button>
					)}
				</ButtonWrapper>
			</RequireSignIn>
			<ControlledTabs
				tabsClassName={addQuestionGroup && 'pr-8'}
				startingTab={tab}
				onTabChange={key => {
					props.clearFilters()
					setHash(`#${key}`)
				}}
				tabs={tabs}
			/>
		</div>
	)
}

const ButtonWrapper = styled.div`
	position: absolute;
	right: 0px;
	top: var(--spacing1x-dont-use);
`
