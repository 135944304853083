import { filterObject } from '../../../utility/functions'
import type { SelScores } from '../../../models/Analytics'
import type { ControlSetType } from '../../../models/Simulation'
import { ORDERED_CATEGORIES } from '../constants'

export type Category = {
	title: string,
	key: string,
	accessor: SelScores => ?number,
	color: string,
	controlSets: ControlSetType[],
}

/**
 * A list of all the categories that we will display on the graph
 */
export const CATEGORIES: { [string]: Category } = {
	questions: {
		title: 'Knowledge',
		key: 'questions',
		controlSets: ['4+', 'K-3'],
		accessor: (score: SelScores) => score?.knowledge?.questions,
		color: 'var(--questions-category-color)',
	},
	application: {
		title: 'Application',
		key: 'application',
		controlSets: ['4+', 'K-3'],
		accessor: (score: SelScores) => score?.knowledge?.application,
		color: 'var(--application-category-color)',
	},
	collaboration: {
		title: 'Collaboration',
		key: 'collaboration',
		controlSets: ['4+'],
		accessor: (score: SelScores) => score?.skills?.collaboration,
		color: 'var(--collaboration-category-color)',
	},
	criticalThinking: {
		title: 'Critical Thinking',
		key: 'criticalThinking',
		controlSets: ['4+'],
		accessor: (score: SelScores) => score?.skills?.criticalThinking,
		color: 'var(--criticalThinking-category-color)',
	},
	grit: {
		title: 'Resilience',
		key: 'grit',
		controlSets: ['4+', 'K-3'],
		accessor: (score: SelScores) => score?.disposition?.grit,
		color: 'var(--grit-category-color)',
	},
	initiative: {
		title: 'Initiative',
		key: 'initiative',
		controlSets: ['4+', 'K-3'],
		accessor: (score: SelScores) => score?.disposition?.initiative,
		color: 'var(--initiative-category-color)',
	},
}

export const ALL_CATEGORIES: Category[] = ORDERED_CATEGORIES.map(category => CATEGORIES[category])

const k3Categories = filterObject(CATEGORIES, category => category.controlSets.includes('K-3'))

/**
 * Returns the proper sel categories to display for the given control set
 * @param {string} controlSet
 * @returns {Category[]}
 */

export function getCategoriesForControlSet(controlSet: Set<string> | ?string): Category[] {
	let categories = CATEGORIES
	if (controlSet instanceof Set ? !controlSet.has('4+') : controlSet === 'K-3') {
		categories = k3Categories
	}
	const returnArray = []
	ORDERED_CATEGORIES.forEach(key => {
		if (categories[key]) {
			returnArray.push(categories[key])
		}
	})
	return returnArray
}

export type StudentMissionScoreData = {
	scores: SelScores,
	missionId: string,
	missionName: string,
	missionDate: Date,
	studentPoints: ?number,
}

export type Student = {
	totalPoints: ?number,
	averagePoints: number,
	averages: SelScores,
	lastMission: StudentMissionScoreData,
	pastMissions: StudentMissionScoreData[],
	allMissions: StudentMissionScoreData[],
}
