import { theme } from '@mission.io/styles'

// media queries can be done more effectively using tailwind
export const screenSizes_dontUse = {
	mobileDevice: 576, // use 'sm' in tailwind
	tabletDevice: 768, // use 'md' in tailwind
	desktopDevice: 992, // use 'lg' in tailwind
	largeDesktopDevice: 1200, // use 'xl' in tailwind
}

export const LightTheme = theme
