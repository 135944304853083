// @flow
import update from 'immutability-helper'

import type { Dispatch } from 'redux'
import NetworkCommunicatorType from '../../services/NetworkCommunicator'
import type { ReduxStore, GetState } from '../rootReducer'
import type { MissionPrepStore, JuniorTeam, PreppedMission } from '../types'
import type { ClientQuestion as Question } from '@mission.io/question-toolkit'
import type { MissionCategory } from '@mission.io/mission-toolkit'
import type { Student_New } from '../../models/Student'

export const types = {
	ADD_PREP_MISSION: 'ADD_PREP_MISSION',
	REMOVE_PREP_MISSION: 'REMOVE_PREP_MISSION',
	RESET_QUESTIONS: 'RESET_QUESTIONS',
	SET_JUNIOR_TEAMS: 'SET_JUNIOR_TEAMS',
	SET_CLASS: 'SET_CLASS',
	SET_MISSION_CATEGORY: 'SET_MISSION_CATEGORY',
}

export type GradeEnum =
	| 'K'
	| '1'
	| '2'
	| '3'
	| '4'
	| '5'
	| '6'
	| '7'
	| '8'
	| '9'
	| '10'
	| '11'
	| '12'
	| 'other'

export const JR_GRADES = ['K', '1', '2', '3']
export const GRADE_ARRAY: GradeEnum[] = [
	...JR_GRADES,
	'4',
	'5',
	'6',
	'7',
	'8',
	'9',
	'10',
	'11',
	'12',
]

export const GRADES: { [grade: string]: string } = GRADE_ARRAY.reduce((mapping, current) => {
	mapping[current] = current
	return mapping
}, {})

// Action Types
type AddPrepMissionAction = {
	type: 'ADD_PREP_MISSION',
	payload: PreppedMission,
}

type RemovePrepMissionAction = {
	type: 'REMOVE_PREP_MISSION',
}

type ResetQuestionsAction = {
	type: 'RESET_QUESTIONS',
	payload: Question[],
}

type SetJuniorTeamsAction = {
	type: 'SET_JUNIOR_TEAMS',
	payload: ?(JuniorTeam[]),
}

type SetClassAction = {
	type: 'SET_CLASS',
	payload: { students: Student_New[], classId: string },
}

type SetMissionCategoryAction = {
	type: 'SET_MISSION_CATEGORY',
	payload: MissionCategory,
}

export type Actions =
	| AddPrepMissionAction
	| RemovePrepMissionAction
	| ResetQuestionsAction
	| SetJuniorTeamsAction
	| SetClassAction
	| SetMissionCategoryAction

// types Action Creators
export function addPrepMission(mission: PreppedMission): AddPrepMissionAction {
	return {
		type: types.ADD_PREP_MISSION,
		payload: mission,
	}
}

export function removePrepMission(): RemovePrepMissionAction {
	return {
		type: types.REMOVE_PREP_MISSION,
	}
}
export function resetQuestions(questions: Question[]): ResetQuestionsAction {
	return {
		type: types.RESET_QUESTIONS,
		payload: questions,
	}
}

export function setJuniorTeams(teams: ?(JuniorTeam[])): SetJuniorTeamsAction {
	return {
		type: types.SET_JUNIOR_TEAMS,
		payload: teams,
	}
}

export function setClass(payload: { students: Student_New[], classId: string }): SetClassAction {
	return {
		type: types.SET_CLASS,
		payload: payload,
	}
}

export function setMissionCategory(missionCategory: MissionCategory): SetMissionCategoryAction {
	return { type: types.SET_MISSION_CATEGORY, payload: missionCategory }
}

// Network Communicators
export function getJuniorTeams(
	simulationId: string,
	studentCount: number
): (
	dispatch: Dispatch<*>,
	getState: GetState,
	NetworkCommunicator: typeof NetworkCommunicatorType
) => void {
	return (
		dispatch: Dispatch<*>,
		getState: GetState,
		NetworkCommunicator: typeof NetworkCommunicatorType
	): void => {
		let request = `/juniorteams/create/${simulationId}/${studentCount}`
		NetworkCommunicator.POST(request)
			.then((res: { teams: JuniorTeam[] }) => {
				dispatch(setJuniorTeams(res.teams))
			})
			.catch(err => {
				console.log('ERROR:', err)
				return { error: err }
			})
	}
}

/**
 * This function calls the server to create junior plus teams, then makes
 * another call to the server to randomly assign students to those teams.
 * After students have been assigned to teams, it sets the teams in the store.
 * It finally calls the callback function passed as a parameter.
 */
export function createAndAssignTeams(
	studentIds: string[],
	callBack: () => void,
	simulationId: string
): (
	dispatch: Dispatch<*>,
	getState: GetState,
	NetworkCommunicator: typeof NetworkCommunicatorType
) => void {
	return (
		dispatch: Dispatch<*>,
		getState: GetState,
		NetworkCommunicator: typeof NetworkCommunicatorType
	): void => {
		let request = `/juniorteams/create/${simulationId}/${studentIds.length}`
		let populateRequest = `/juniorteams/populate/${simulationId}`
		NetworkCommunicator.POST(request)
			.then((res: { teams: JuniorTeam[] }) => {
				NetworkCommunicator.POST(populateRequest, {
					body: { students: studentIds, teams: res.teams },
				})
					.then((assignedRes: { teams: JuniorTeam[] }) => {
						dispatch(setJuniorTeams(assignedRes.teams))
					})
					.catch(err => {
						console.log('ERROR:', err)
						return { error: err }
					})
					.finally(() => {
						callBack()
					})
			})
			.catch(err => {
				console.log('ERROR:', err)
				return { error: err }
			})
	}
}

const initialState: MissionPrepStore = {
	mission: null,
	defaultQuestions: null,
}

// REDUCERS
export default function missionPrepReducer(
	state: MissionPrepStore = initialState,
	action: Actions
): MissionPrepStore {
	switch (action.type) {
		case types.ADD_PREP_MISSION: {
			const payload = action.payload
			return { ...state, mission: payload, defaultQuestions: payload.questions }
		}
		case types.REMOVE_PREP_MISSION: {
			return { ...state, mission: null }
		}
		case types.RESET_QUESTIONS: {
			const payload = action.payload
			if (!state.mission) {
				return state
			}
			return { ...state, mission: { ...state.mission, questions: payload } }
		}
		case types.SET_JUNIOR_TEAMS: {
			const teams = action.payload
			if (state && state.mission) {
				return { ...state, mission: { ...state.mission, juniorTeams: teams } }
			} else {
				return state
			}
		}
		case types.SET_CLASS: {
			const payload = action.payload
			if (state?.mission) {
				return update(state, {
					mission: {
						crew: { $set: { students: payload.students, classId: payload.classId } },
					},
				})
			}
			return state
		}
		case types.SET_MISSION_CATEGORY: {
			const missionCategory = action.payload
			if (state?.mission) {
				return update(state, {
					mission: {
						missionCategory: { $set: missionCategory },
					},
				})
			}
			return state
		}
		default:
			return state
	}
}

// Selectors
export function getJuniorPlusTeams(state: ReduxStore): ?(JuniorTeam[]) {
	if (state.missionPrep.mission) {
		return state.missionPrep.mission.juniorTeams
	}
	return null
}

export function getSimulationId(state: ReduxStore): ?string {
	if (state.missionPrep.mission) {
		return state.missionPrep.mission.simulationId
	}
	return null
}

export function getQuestions(state: ReduxStore): ?(Question[]) {
	if (state.missionPrep.mission) {
		return state.missionPrep.mission.questions
	}
	return null
}

export function getDefaultQuestionsForMission(state: ReduxStore): ?(Question[]) {
	return state.missionPrep?.defaultQuestions
}

export function isUsingJuniorPlusControls(state: ReduxStore): boolean {
	return Boolean(state.missionPrep.mission && state.missionPrep.mission.useJrPlus)
}

/**
 * Gets the preppedMissionData from the store.
 */
export function getMissionPrepData(state: ReduxStore): ?PreppedMission {
	return state.missionPrep.mission
}

/**
 * Gets the crew classId if it exists, else returns null.
 */
export function getPreppedMissionClassId(state: ReduxStore): ?string {
	const mission = state.missionPrep.mission
	if (!mission || !mission.crew) {
		return null
	}
	return mission.crew.classId
}

// check to see if _id is on the prepMission Body, meaning we are editing the mission.
export function isMissionBeingEdited(state: ReduxStore): boolean {
	const mission = state.missionPrep.mission
	if (!mission || !mission._id) {
		return false
	}
	return true
}
