import { useMutation, useQueryClient, type UseMutationResult, useQuery } from 'react-query'

import config from '../../config'
import NetworkCommunicator from '../../services/NetworkCommunicator'
import type { ClassType, OwnerType } from '../../models/ClassType'
import type { Student_New, NewStudent, ExternalReference } from '../../models/Student'
import type { GradeEnum } from '../../store/missionPrep'
import type { UseQueryResult } from 'react-query'
import axios from 'axios'
import { useMemo } from 'react'
import { toast } from 'react-toastify'
import { useUser } from '.'
import { EXAMPLE_CLASSES } from '../../constants/exampleData'

type FakeReactQueryResult<Data> = {
	isLoading: boolean,
	isError: boolean,
	data: ?Data,
	refetch: () => Promise<void>,
}

export const CLASS_STATUS = {
	ACTIVE: 'ACTIVE',
	ARCHIVED: 'ARCHIVED',
	PENDING: 'PENDING',
	WAS_ARCHIVED_NOW_PENDING: 'WAS_ARCHIVED_NOW_PENDING',
}

export const PENDING_CLASS_STATUS = [CLASS_STATUS.PENDING, CLASS_STATUS.WAS_ARCHIVED_NOW_PENDING]

type ClassStatus = $Keys<typeof CLASS_STATUS>

export type ServerClass = {|
	...ClassType,
	status: ClassStatus,
|}

/**
 * usePossibleOwners - get the users that the current user can transfer class ownership to
 *
 * @returns UseQueryResult<OwnerType[]>
 */
export function usePossibleOwners(): UseQueryResult<OwnerType[]> {
	return useQuery(['user', 'possible-owners'], () =>
		axios
			.get(`${config.loginServer}/api/users?availableClassOwners=true`, {
				withCredentials: true,
			})
			.then(res => res.data.users)
	)
}

const CLASSES_QUERY_KEYS = {
	classes(options: { statuses: Array<ClassStatus>, user: boolean }) {
		return ['user', 'classes', options]
	},
	class(classId: ?string, options: { statuses: Array<ClassStatus>, user: boolean }) {
		return ['user', 'classes', classId, options]
	},
}

/**
 * Gets the classes for the current user.
 */
export function useUserClasses({
	statuses = [CLASS_STATUS.ACTIVE],
}: { statuses: Array<ClassStatus> } = {}): UseQueryResult<Array<ClassType>> {
	const { user } = useUser()
	const queryClient = useQueryClient()
	return useQuery(CLASSES_QUERY_KEYS.classes({ statuses, user: Boolean(user) }), (): Promise<
		Array<ClassType>
	> => {
		if (!user) {
			return Promise.resolve(EXAMPLE_CLASSES)
		}
		return axios
			.get(`${config.loginServer}/api/classes`, { withCredentials: true })
			.then(res => {
				let allClassrooms: ServerClass[] = res.data.classrooms
				allClassrooms.forEach(classroom => {
					queryClient.setQueryData(
						CLASSES_QUERY_KEYS.class(classroom._id, {
							statuses,
							user: Boolean(user),
						}),
						classroom
					)
				})
				return allClassrooms
					.filter(({ status: classStatus }) => statuses.includes(classStatus))
					.map(({ status, ...classType }) => classType)
			})
	})
}

/**
 * useClass - Gets the user's class that is represented by the given `classId`. If the class is considered loaded
 *  but is `null/undefined` assume that the user does not have access to the class.
 *
 * @param {?string} classId The id of the class
 * @param {boolean} archived Whether or not to get archived classes
 * @return {?ClassType} The class
 */
export function useClass(
	classId: ?string,
	{ statuses = [CLASS_STATUS.ACTIVE] }: { statuses: Array<ClassStatus> } = {}
): UseQueryResult<?ClassType> {
	const queryClient = useQueryClient()
	const { user } = useUser()
	return useQuery(
		CLASSES_QUERY_KEYS.class(classId, { statuses, user: Boolean(user) }),
		async (): Promise<?ClassType> => {
			if (!classId) {
				return null
			}
			if (!user) {
				return EXAMPLE_CLASSES.find(classItem => classItem._id === classId)
			}
			const serverClass: ?ServerClass = await axios
				.get(`${config.loginServer}/api/classes/${classId}`, { withCredentials: true })
				.then(res => res.data.classroom)
				.catch(async error => {
					if (error?.response?.status === 404) {
						return null
					}
					throw error
				})
			let classroom
			if (serverClass) {
				const { status: serverClassStatus, ...class_ } = serverClass
				if (statuses.includes(serverClassStatus)) {
					classroom = class_
				}
			}

			// update the list of all user classes if it exists
			const allCurrentlyFetchedClasses: ?Array<ClassType> = queryClient.getQueryData(
				CLASSES_QUERY_KEYS.classes({ statuses, user: Boolean(user) })
			)
			if (allCurrentlyFetchedClasses) {
				const newClasses = allCurrentlyFetchedClasses.filter(({ _id }) => _id !== classId)
				if (classroom) {
					newClasses.push(classroom)
				}
				queryClient.setQueryData(
					CLASSES_QUERY_KEYS.classes({ statuses, user: Boolean(user) }),
					newClasses
				)
			}
			return classroom
		},
		{
			cacheTime: Infinity,
			staleTime: Infinity,
			enabled: !!classId,
		}
	)
}

/**
 * useStudentIdsToName - get a mapping of the studentIds of all students in all the user's classes to their names
 *
 * In order to show data properly on the loading page, we map all studentIds to their name,
 * so that all we need to do is pass in the studentId to get the correct name in analytics.
 */
export function useStudentIdsToName(): FakeReactQueryResult<{ [studentId: string]: string }> {
	const { data: classes, ...rest } = useUserClasses()
	const students = useMemo(() => {
		if (!classes) {
			return null
		}

		const studentIdsToName = {}
		classes.forEach(teachersClass => {
			teachersClass.students &&
				teachersClass.students.forEach(student => {
					if (!student.firstName && !student.lastName) {
						studentIdsToName[student._id] = 'Student name not provided'
						return
					}
					const firstName = student.firstName || ''
					const lastName = student.lastName || ''

					if (student.firstName && student.lastName) {
						studentIdsToName[student._id] = firstName + ' ' + lastName
					} else {
						studentIdsToName[student._id] = firstName + lastName
					}
				})
		})

		return studentIdsToName
	}, [classes])

	return {
		data: students,
		isLoading: rest.isLoading,
		isError: rest.isError,
		refetch: async () => {
			await rest.refetch()
		},
	}
}

export type UpdateClass = {
	_id?: string,
	demoSchoolId?: string,
	name: string,
	students: $ReadOnlyArray<Student_New | NewStudent>,
	grades: GradeEnum[],
	externalReferences: ExternalReference[],
	ownerId?: ?string,
}

/**
 * A hook for creating a class. Returns the UseMutationResult for the
 * create class mutation.
 */
export function useCreateClass(): UseMutationResult<
	ClassType,
	Error & { status?: number },
	UpdateClass
> {
	const queryClient = useQueryClient()

	return useMutation(
		(_class: UpdateClass): Promise<ClassType> => {
			return NetworkCommunicator.POST(`/api/classes`, {
				host: config.loginServer,
				body: _class,
			})
		},
		{
			onSuccess: (_class: ClassType) => {
				queryClient.invalidateQueries({
					queryKey: CLASSES_QUERY_KEYS.classes({
						statuses: [CLASS_STATUS.ACTIVE],
						user: true,
					}),
					exact: true,
				})
			},
		}
	)
}

/**
 * A hook for editing a class. Returns the UseMutationResult for the
 * edit class mutation.
 */
export function useEditClass(): UseMutationResult<
	?ClassType,
	Error & { status?: number },
	UpdateClass
> {
	const queryClient = useQueryClient()

	return useMutation(
		async (_class: UpdateClass): Promise<?ClassType> => {
			if (typeof _class._id !== 'string') {
				return
			}
			return await NetworkCommunicator.PUT(`/api/classes/${_class._id}`, {
				host: config.loginServer,
				body: _class,
			})
		},
		{
			onSuccess: (_class: ?ClassType) => {
				if (!_class) {
					return
				}
				queryClient.invalidateQueries(
					CLASSES_QUERY_KEYS.class(_class._id, {
						statuses: [CLASS_STATUS.ACTIVE],
						user: true,
					})
				)
			},
		}
	)
}

/**
 * A hook for deleting classes. Returns the UseMutationResult for the
 * delete class mutation.
 */
export function useDeleteClass(): UseMutationResult<string, Error & { status?: number }, string> {
	const queryClient = useQueryClient()

	return useMutation(
		async (classId: string): Promise<string> => {
			return await NetworkCommunicator.DELETE(`/api/classes/${classId}`, {
				host: config.loginServer,
			})
		},
		{
			onSuccess: (classId: string) => {
				queryClient.invalidateQueries(
					CLASSES_QUERY_KEYS.class(classId, {
						statuses: [CLASS_STATUS.ACTIVE],
						user: true,
					})
				)
				queryClient.invalidateQueries(
					CLASSES_QUERY_KEYS.class(classId, {
						statuses: [CLASS_STATUS.ARCHIVED],
						user: true,
					})
				)
				queryClient.invalidateQueries(
					CLASSES_QUERY_KEYS.class(classId, {
						statuses: [CLASS_STATUS.PENDING, CLASS_STATUS.WAS_ARCHIVED_NOW_PENDING],
						user: true,
					})
				)
			},
		}
	)
}

/**
 * splitClassesByOwner - groups the classes by similar owners, classes in the same group with include the same owner
 *
 * @param {ClassType[]} classes - the classes to group
 *
 * @returns {{ owner: OwnerType, classes: ClassType[] }[]} - the groups
 */
export function splitClassesByOwner(
	classes: ClassType[]
): { owner: OwnerType, classes: ClassType[] }[] {
	const groups: {
		[ownerId: string]: ClassType[],
	} = {}
	const owners: { [ownerId: string]: OwnerType } = {}

	classes.forEach(_class => {
		_class.owners.forEach(owner => {
			owners[owner._id] = owner
			if (!groups[owner._id]) {
				groups[owner._id] = []
			}
			groups[owner._id].push(_class)
		})
	})

	return Object.keys(groups).map(ownerId => ({
		owner: owners[ownerId],
		classes: groups[ownerId],
	}))
}

/**
 * getClassOwnerName - get the name of the owner to show in the UI
 *
 * @param  {OwnerType} owner - the owner to get the name for
 *
 * @returns string - the name to show in the UI
 */
export function getClassOwnerName(owner: OwnerType): string {
	if (owner.firstName && owner.lastName) {
		return `${owner.firstName} ${owner.lastName}`
	}
	return owner.firstName || owner.lastName || 'Unknown'
}

type ClassError = {
	status: number,
	message: string,
	classId?: string,
}

/**
 * Given a list of classId strings, returns a single query param classIds string
 */
const getClassIdsString = (classIds: string[]): string => {
	if (classIds.length < 1) return ''
	let searchParams = new URLSearchParams()
	classIds.forEach(classId => searchParams.append('classIds', classId))
	return `?${searchParams.toString()}`
}

/**
 * Removes all google classes with a 'PENDING' status.
 * If any errors occurred during the process, toasts are shown.
 */
export function useRemovePendingClasses(): UseMutationResult<
	{ removedClassIds: Array<string>, errors: Array<ClassError> },
	Error & { status?: number }
> {
	const queryClient = useQueryClient()

	return useMutation(
		async (): Promise<{ removedClassIds: Array<string>, errors: Array<ClassError> }> => {
			return await NetworkCommunicator.DELETE(`/api/classes/pending`, {
				host: config.loginServer,
			})
		},
		{
			onSuccess: ({
				removedClassIds,
				errors,
			}: {
				removedClassIds: Array<string>,
				errors: Array<ClassError>,
			}) => {
				removedClassIds.forEach(classId => {
					queryClient.invalidateQueries(
						CLASSES_QUERY_KEYS.class(classId, {
							statuses: [CLASS_STATUS.ACTIVE],
							user: true,
						})
					)
					queryClient.invalidateQueries(
						CLASSES_QUERY_KEYS.class(classId, {
							statuses: [CLASS_STATUS.ARCHIVED],
							user: true,
						})
					)
					queryClient.invalidateQueries(
						CLASSES_QUERY_KEYS.class(classId, {
							statuses: [CLASS_STATUS.PENDING, CLASS_STATUS.WAS_ARCHIVED_NOW_PENDING],
							user: true,
						})
					)
				})
				errors.forEach(err => {
					toast.error(err.message, {
						position: toast.POSITION.BOTTOM_RIGHT,
					})
				})
			},
		}
	)
}

/**
 * Activates the classes associated with the given classIds.
 * If any errors occurred during the process, toasts are shown.
 */
export function useActivateClasses(): UseMutationResult<
	{
		activatedClassIds: Array<string>,
		errors: Array<ClassError>,
	},
	Error & { status?: number },
	Array<string>
> {
	const queryClient = useQueryClient()

	return useMutation(
		async (
			classIds: string[]
		): Promise<{
			activatedClassIds: Array<string>,
			errors: Array<ClassError>,
		}> => {
			return await NetworkCommunicator.PUT(
				`/api/classes/activate${getClassIdsString(classIds)}`,
				{
					host: config.loginServer,
				}
			)
		},
		{
			onSuccess: ({
				activatedClassIds,
				errors,
			}: {
				activatedClassIds: Array<string>,
				errors: Array<ClassError>,
			}) => {
				activatedClassIds.forEach(classId => {
					queryClient.invalidateQueries(
						CLASSES_QUERY_KEYS.class(classId, {
							statuses: [CLASS_STATUS.ACTIVE],
							user: true,
						})
					)
					queryClient.invalidateQueries(
						CLASSES_QUERY_KEYS.class(classId, {
							statuses: [CLASS_STATUS.ARCHIVED],
							user: true,
						})
					)
					queryClient.invalidateQueries(
						CLASSES_QUERY_KEYS.class(classId, {
							statuses: [CLASS_STATUS.PENDING, CLASS_STATUS.WAS_ARCHIVED_NOW_PENDING],
							user: true,
						})
					)
				})
				errors.forEach(err => {
					toast.error(err.message, {
						position: toast.POSITION.BOTTOM_RIGHT,
					})
				})
			},
		}
	)
}
