import { variantTypes } from '@mission.io/question-toolkit'
import config from '../../config'
import { getQuestionKey } from '../../utility/helpers'
import type { AnalyticsObject } from '../../models/Analytics'
import type { DBQuestion } from '../../models/Question'
import { useUser } from '../../services/hooks'

// A Date object representing the most recent Thursday at 10 AM. If today
// is Thursday, returns the Thursday 7 days ago.
const previousThursdayAtTen: Date = (function getLastThursdayAtTen() {
	const THURSDAY = 4
	const date = new Date()
	const day = date.getDay()

	const previousThursdayDiff = day > THURSDAY ? day - THURSDAY : 7 - THURSDAY + day

	date.setDate(date.getDate() - previousThursdayDiff)
	date.setHours(10)

	return date
})()

export const TEACHER_ID = 'teacherId'

/**
 * Gets a single demo analytics object
 */
function getDemoAnalyticsObject(): AnalyticsObject {
	return {
		analytics: {
			studentAnalytics: {
				mvp1: {
					skills: {
						criticalThinking: { endOfMissionSurvey: 1, scannedData: 0 },
						collaboration: {
							endOfMissionSurvey: 1,
							socialHealthScore: 0.6,
							engineerPanelScore: { time: 312717.25, score: 0.7545 },
						},
					},
					disposition: {
						grit: {
							endOfMissionSurvey: 1,
							gritAfterNegativeEvents: {
								APMDetails: [
									{
										APMInDuration: 31,
										eventType: 'QUESTION_ANSWERED',
										actionTime: new Date('2021-05-11T19:10:59.439Z'),
										score: 2,
									},
									{
										APMInDuration: 29.5,
										eventType: 'QUESTION_ANSWERED',
										actionTime: new Date('2021-05-11T19:10:59.528Z'),
										score: 2,
									},
									{
										APMInDuration: 29.5,
										eventType: 'QUESTION_ANSWERED',
										actionTime: new Date('2021-05-11T19:11:05.343Z'),
										score: 2,
									},
									{
										APMInDuration: 28,
										eventType: 'QUESTION_ANSWERED',
										actionTime: new Date('2021-05-11T19:11:05.426Z'),
										score: 2,
									},
									{
										APMInDuration: 26.5,
										eventType: 'QUESTION_ANSWERED',
										actionTime: new Date('2021-05-11T19:11:05.431Z'),
										score: 2,
									},
									{
										APMInDuration: 25,
										eventType: 'QUESTION_ANSWERED',
										actionTime: new Date('2021-05-11T19:11:05.435Z'),
										score: 2,
									},
									{
										APMInDuration: 23.5,
										eventType: 'QUESTION_ANSWERED',
										actionTime: new Date('2021-05-11T19:11:05.438Z'),
										score: 2,
									},
									{
										APMInDuration: 22,
										eventType: 'QUESTION_ANSWERED',
										actionTime: new Date('2021-05-11T19:11:05.442Z'),
										score: 2,
									},
									{
										APMInDuration: 20.5,
										eventType: 'QUESTION_ANSWERED',
										actionTime: new Date('2021-05-11T19:11:05.530Z'),
										score: 2,
									},
									{
										APMInDuration: 20.5,
										eventType: 'QUESTION_ANSWERED',
										actionTime: new Date('2021-05-11T19:11:12.444Z'),
										score: 2,
									},
									{
										APMInDuration: 8.8,
										eventType: 'DEATH',
										actionTime: new Date('2021-05-11T19:11:56.127Z'),
										score: 1.7106668075117373,
									},
									{
										APMInDuration: 13,
										eventType: 'QUESTION_ANSWERED',
										actionTime: new Date('2021-05-11T19:12:17.474Z'),
										score: 2,
									},
									{
										APMInDuration: 6,
										eventType: 'QUESTION_ANSWERED',
										actionTime: new Date('2021-05-11T19:12:59.341Z'),
										score: 1.166363732394366,
									},
									{
										APMInDuration: 5,
										eventType: 'QUESTION_ANSWERED',
										actionTime: new Date('2021-05-11T19:13:04.542Z'),
										score: 0.9719697769953052,
									},
								],
								averageAPM: 5.144192873421157,
								score: 1.8463571654929576,
							},
						},
						initiative: {
							endOfMissionSurvey: 1,
							alertResponse: { time: 31219, score: 1.15125 },
							scannedDataResponse: { time: 1516571.7692307692, score: 0 },
							stationResponse: {
								time: 36812.230769230766,
								score: 0.8082307692307693,
							},
							mapParticipation: { percentActive: 0.52, score: 0.693 },
							boostParticipation: { percentActive: 0.959, score: 1.118 },
						},
					},
					studentGameScore: 18493,
					totalHealthTasks: 2,
					knowledge: { application: 0.6875, questions: 0.2857142857142857 },
					studentPoints: {
						DECKS: 58,
						COMMUNICATION: 283,
						TRANSPORTER: 82,
						THRUSTERS: 21,
						POWER: 128,
						DEFENSE: 21,
						TRACTOR_BEAM: 100,
						QUESTIONS: 1250,
						SENSOR: 57,
					},
					questionAnswers: [
						{
							questionId: '5c10b024ca7c6fbfaaf501d9',
							questionVersion: 1,
							requestId: '1',
							score: { score: 1, perfectScore: 1 },
							answer: {
								type: variantTypes.MULTIPLE_CHOICE,
								value: '5c10b024ca7c6fbfaaf501db',
							},
							timestamp: 1626874366342,
						},
						{
							questionId: '5c10b024ca7c6fbfaaf501e5',
							questionVersion: 1,
							requestId: '2',
							score: { score: 0, perfectScore: 1 },
							answer: {
								type: variantTypes.MULTIPLE_CHOICE,
								value: '5c10b024ca7c6fbfaaf501ea',
							},
							timestamp: 1626874530149,
						},
						{
							questionId: '5c10b024ca7c6fbfaaf50210',
							questionVersion: 1,
							requestId: '3',
							score: { score: 1, perfectScore: 1 },
							answer: {
								type: variantTypes.MULTIPLE_CHOICE,
								value: '5c10b024ca7c6fbfaaf50212',
							},
							timestamp: 1626876308434,
						},
						{
							questionId: '5c10b024ca7c6fbfaaf50229',
							questionVersion: 1,
							requestId: '4',
							score: { score: 0, perfectScore: 1 },
							answer: {
								type: variantTypes.MULTIPLE_CHOICE,
								value: '5c10b024ca7c6fbfaaf5022c',
							},
							timestamp: 1626874114897,
						},
						{
							questionId: '5c10b024ca7c6fbfaaf50215',
							questionVersion: 1,
							requestId: '5',
							score: { score: 0, perfectScore: 1 },
							answer: {
								type: variantTypes.MULTIPLE_CHOICE,
								value: '5c10b024ca7c6fbfaaf50218',
							},
							timestamp: 1626876932224,
						},
						{
							questionId: '5c10b024ca7c6fbfaaf50243',
							questionVersion: 1,
							requestId: '6',
							score: { score: 0, perfectScore: 1 },
							answer: {
								type: variantTypes.MULTIPLE_CHOICE,
								value: '5c10b024ca7c6fbfaaf50246',
							},
							timestamp: 1626875710859,
						},
						{
							questionId: '5c10b024ca7c6fbfaaf5025d',
							questionVersion: 1,
							requestId: '7',
							score: { score: 0, perfectScore: 1 },
							answer: {
								type: variantTypes.MULTIPLE_CHOICE,
								value: '5c10b024ca7c6fbfaaf50262',
							},
							timestamp: 1626876734875,
						},
					],
				},
				s2: {
					skills: {
						criticalThinking: { endOfMissionSurvey: 1, scannedData: 0 },
						collaboration: {
							endOfMissionSurvey: 1,
							socialHealthScore: 1,
							engineerPanelScore: { time: 82765.5, score: 0.8520000000000001 },
						},
					},
					disposition: {
						grit: {
							endOfMissionSurvey: 1,
							gritAfterNegativeEvents: {
								APMDetails: [
									{
										APMInDuration: 5.6,
										eventType: 'DEATH',
										actionTime: new Date('2021-05-11T19:11:56.127Z'),
										score: 1.2052107509881422,
									},
									{
										APMInDuration: 10.5,
										eventType: 'QUESTION_ANSWERED',
										actionTime: new Date('2021-05-11T19:14:36.347Z'),
										score: 2,
									},
									{
										APMInDuration: 8,
										eventType: 'QUESTION_ANSWERED',
										actionTime: new Date('2021-05-11T19:23:05.726Z'),
										score: 1.7217296442687746,
									},
									{
										APMInDuration: 1.5,
										eventType: 'QUESTION_ANSWERED',
										actionTime: new Date('2021-05-11T19:47:09.848Z'),
										score: 0.32282430830039527,
									},
								],
								averageAPM: 4.646490246961875,
								score: 1.3124411758893282,
							},
						},
						initiative: {
							endOfMissionSurvey: 1,
							alertResponse: { time: 34065.4, score: 1.109 },
							scannedDataResponse: { time: 1516571.7692307692, score: 0 },
							stationResponse: {
								time: 18898.083333333332,
								score: 0.9641666666666667,
							},
							mapParticipation: { percentActive: 0.59, score: 0.787 },
							boostParticipation: { percentActive: 0.421, score: 0.561 },
						},
					},
					studentGameScore: 14784,
					totalHealthTasks: 3,
					knowledge: { application: 1, questions: 0.2222222222222222 },
					studentPoints: {
						DECKS: 78,
						COMMUNICATION: 84,
						TRANSPORTER: 199,
						THRUSTERS: 12,
						POWER: 145,
						DEFENSE: 23,
						TRACTOR_BEAM: 139,
						QUESTIONS: 194,
						SENSOR: 229,
					},
					questionAnswers: [
						{
							questionId: '5c10b024ca7c6fbfaaf501d9',
							questionVersion: 1,
							requestId: '8',
							score: { score: 0, perfectScore: 1 },
							answer: {
								type: variantTypes.MULTIPLE_CHOICE,
								value: '5c10b024ca7c6fbfaaf501dc',
							},
							timestamp: 1626876908030,
						},
						{
							questionId: '5c10b024ca7c6fbfaaf501df',
							questionVersion: 1,
							requestId: '9',
							score: { score: 0, perfectScore: 1 },
							answer: {
								type: variantTypes.MULTIPLE_CHOICE,
								value: '5c10b024ca7c6fbfaaf501e1',
							},
							timestamp: 1626875681959,
						},
						{
							questionId: '5c10b024ca7c6fbfaaf501e5',
							questionVersion: 1,
							requestId: '10',
							score: { score: 0, perfectScore: 1 },
							answer: {
								type: variantTypes.MULTIPLE_CHOICE,
								value: '5c10b024ca7c6fbfaaf501e8',
							},
							timestamp: 1626876382278,
						},
						{
							questionId: '5c10b024ca7c6fbfaaf501f1',
							questionVersion: 1,
							requestId: '11',
							score: { score: 0, perfectScore: 1 },
							answer: {
								type: variantTypes.MULTIPLE_CHOICE,
								value: '5c10b024ca7c6fbfaaf501f5',
							},
							timestamp: 1626874805935,
						},
						{
							questionId: '5c10b024ca7c6fbfaaf50210',
							questionVersion: 1,
							requestId: '12',
							score: { score: 0, perfectScore: 1 },
							answer: {
								type: variantTypes.MULTIPLE_CHOICE,
								value: '5c10b024ca7c6fbfaaf50213',
							},
							timestamp: 1626875298618,
						},
						{
							questionId: '5c10b024ca7c6fbfaaf50229',
							questionVersion: 1,
							requestId: '13',
							score: { score: 0, perfectScore: 1 },
							answer: {
								type: variantTypes.MULTIPLE_CHOICE,
								value: '5c10b024ca7c6fbfaaf5022c',
							},
							timestamp: 1626876063863,
						},
						{
							questionId: '5c10b024ca7c6fbfaaf50215',
							questionVersion: 1,
							requestId: '14',
							score: { score: 0, perfectScore: 1 },
							answer: {
								type: variantTypes.MULTIPLE_CHOICE,
								value: '5c10b024ca7c6fbfaaf50217',
							},
							timestamp: 1626874174470,
						},
						{
							questionId: '5c10b024ca7c6fbfaaf50243',
							questionVersion: 1,
							requestId: '15',
							score: { score: 1, perfectScore: 1 },
							answer: {
								type: variantTypes.MULTIPLE_CHOICE,
								value: '5c10b024ca7c6fbfaaf50245',
							},
							timestamp: 1626874065986,
						},
						{
							questionId: '5c10b024ca7c6fbfaaf5025d',
							questionVersion: 1,
							requestId: '16',
							score: { score: 1, perfectScore: 1 },
							answer: {
								type: variantTypes.MULTIPLE_CHOICE,
								value: '5c10b024ca7c6fbfaaf5025f',
							},
							timestamp: 1626874670126,
						},
					],
				},
				s3: {
					skills: {
						criticalThinking: { endOfMissionSurvey: 1, scannedData: 0 },
						collaboration: {
							endOfMissionSurvey: 1,
							socialHealthScore: 1,
							engineerPanelScore: { time: 367858.25, score: 0.5095 },
						},
					},
					disposition: {
						grit: {
							endOfMissionSurvey: 1,
							gritAfterNegativeEvents: {
								APMDetails: [
									{
										APMInDuration: 3.6,
										eventType: 'DEATH',
										actionTime: new Date('2021-05-11T19:11:56.127Z'),
										score: 0.9461829807692308,
									},
									{
										APMInDuration: 3,
										eventType: 'QUESTION_ANSWERED',
										actionTime: new Date('2021-05-11T19:16:53.251Z'),
										score: 0.7884858173076923,
									},
									{
										APMInDuration: 1.5,
										eventType: 'QUESTION_ANSWERED',
										actionTime: new Date('2021-05-11T19:25:12.227Z'),
										score: 0.39424290865384615,
									},
									{
										APMInDuration: 6.5,
										eventType: 'QUESTION_ANSWERED',
										actionTime: new Date('2021-05-11T19:34:44.743Z'),
										score: 1.7083859375,
									},
									{
										APMInDuration: 7.5,
										eventType: 'QUESTION_ANSWERED',
										actionTime: new Date('2021-05-11T19:42:33.046Z'),
										score: 1.9712145432692307,
									},
								],
								averageAPM: 3.8047608899847902,
								score: 1.1617024375,
							},
						},
						initiative: {
							endOfMissionSurvey: 1,
							alertResponse: { time: 143587.4, score: 0.718 },
							scannedDataResponse: { time: 1503214.3846153845, score: 0 },
							stationResponse: { time: 40735, score: 0.7569230769230769 },
							mapParticipation: { percentActive: 0.23, score: 0.307 },
							boostParticipation: { percentActive: 0.139, score: 0.185 },
						},
					},
					studentGameScore: 10214,
					totalHealthTasks: 3,
					knowledge: { application: 1, questions: 0.2857142857142857 },
					studentPoints: {
						DECKS: 241,
						COMMUNICATION: 64,
						TRANSPORTER: 92,
						THRUSTERS: 195,
						POWER: 106,
						DEFENSE: 120,
						TRACTOR_BEAM: 206,
						QUESTIONS: 61,
						SENSOR: 144,
					},
					questionAnswers: [
						{
							questionId: '5c10b024ca7c6fbfaaf501d3',
							questionVersion: 1,
							requestId: '17',
							score: { score: 0, perfectScore: 1 },
							answer: {
								type: variantTypes.MULTIPLE_CHOICE,
								value: '5c10b024ca7c6fbfaaf501d5',
							},
							timestamp: 1626877114983,
						},
						{
							questionId: '5c10b024ca7c6fbfaaf501d9',
							questionVersion: 1,
							requestId: '18',
							score: { score: 0, perfectScore: 1 },
							answer: {
								type: variantTypes.MULTIPLE_CHOICE,
								value: '5c10b024ca7c6fbfaaf501dc',
							},
							timestamp: 1626875271393,
						},
						{
							questionId: '5c10b024ca7c6fbfaaf501e5',
							questionVersion: 1,
							requestId: '19',
							score: { score: 0, perfectScore: 1 },
							answer: {
								type: variantTypes.MULTIPLE_CHOICE,
								value: '5c10b024ca7c6fbfaaf501e8',
							},
							timestamp: 1626875005099,
						},
						{
							questionId: '5c10b024ca7c6fbfaaf50210',
							questionVersion: 1,
							requestId: '20',
							score: { score: 0, perfectScore: 1 },
							answer: {
								type: variantTypes.MULTIPLE_CHOICE,
								value: '5c10b024ca7c6fbfaaf50214',
							},
							timestamp: 1626875844594,
						},
						{
							questionId: '5c10b024ca7c6fbfaaf50229',
							questionVersion: 1,
							requestId: '21',
							score: { score: 1, perfectScore: 1 },
							answer: {
								type: variantTypes.MULTIPLE_CHOICE,
								value: '5c10b024ca7c6fbfaaf5022b',
							},
							timestamp: 1626876949690,
						},
						{
							questionId: '5c10b024ca7c6fbfaaf50215',
							questionVersion: 1,
							requestId: '22',
							score: { score: 0, perfectScore: 1 },
							answer: {
								type: variantTypes.MULTIPLE_CHOICE,
								value: '5c10b024ca7c6fbfaaf50217',
							},
							timestamp: 1626876769783,
						},
						{
							questionId: '5c10b024ca7c6fbfaaf50243',
							questionVersion: 1,
							requestId: '23',
							score: { score: 1, perfectScore: 1 },
							answer: {
								type: variantTypes.MULTIPLE_CHOICE,
								value: '5c10b024ca7c6fbfaaf50245',
							},
							timestamp: 1626876746452,
						},
					],
				},
				s4: {
					skills: {
						criticalThinking: {
							endOfMissionSurvey: 0.6666666666666666,
							scannedData: 0,
						},
						collaboration: {
							endOfMissionSurvey: 0.6666666666666666,
							socialHealthScore: 1,
							engineerPanelScore: { time: 137394.25, score: 0.71175 },
						},
					},
					disposition: {
						grit: {
							endOfMissionSurvey: 1,
							gritAfterNegativeEvents: {
								APMDetails: [
									{
										APMInDuration: 2.8,
										eventType: 'DEATH',
										actionTime: new Date('2021-05-11T19:11:56.127Z'),
										score: 0.8909271705426356,
									},
									{
										APMInDuration: 2.5,
										eventType: 'QUESTION_ANSWERED',
										actionTime: new Date('2021-05-11T19:17:51.284Z'),
										score: 0.7954706879844962,
									},
									{
										APMInDuration: 5.5,
										eventType: 'QUESTION_ANSWERED',
										actionTime: new Date('2021-05-11T19:23:21.642Z'),
										score: 1.7500355135658914,
									},
									{
										APMInDuration: 7,
										eventType: 'QUESTION_ANSWERED',
										actionTime: new Date('2021-05-11T19:30:11.555Z'),
										score: 2,
									},
								],
								averageAPM: 3.1427933646861486,
								score: 1.3591083430232558,
							},
						},
						initiative: {
							endOfMissionSurvey: 0.6666666666666666,
							alertResponse: { time: 383615.2, score: 0.6998 },
							scannedDataResponse: { time: 1516571.7692307692, score: 0 },
							stationResponse: { time: 23120.69230769231, score: 0.942076923076923 },
							mapParticipation: { percentActive: 0.15, score: 0.2 },
							boostParticipation: { percentActive: 0.758, score: 1 },
						},
					},
					studentGameScore: 13092,
					totalHealthTasks: 2,
					knowledge: { application: 0.9166666666666666, questions: 0.42857142857142855 },
					studentPoints: {
						DECKS: 53,
						COMMUNICATION: 189,
						TRANSPORTER: 197,
						THRUSTERS: 77,
						POWER: 72,
						DEFENSE: 1,
						TRACTOR_BEAM: 56,
						QUESTIONS: 73,
						SENSOR: 97,
					},
					questionAnswers: [
						{
							questionId: '5c10b024ca7c6fbfaaf501d9',
							questionVersion: 1,
							requestId: '24',
							score: { score: 1, perfectScore: 1 },
							answer: {
								type: variantTypes.MULTIPLE_CHOICE,
								value: '5c10b024ca7c6fbfaaf501db',
							},
							timestamp: 1626876470569,
						},
						{
							questionId: '5c10b024ca7c6fbfaaf501df',
							questionVersion: 1,
							requestId: '25',
							score: { score: 1, perfectScore: 1 },
							answer: {
								type: variantTypes.MULTIPLE_CHOICE,
								value: '5c10b024ca7c6fbfaaf501e2',
							},
							timestamp: 1626876390578,
						},
						{
							questionId: '5c10b024ca7c6fbfaaf501e5',
							questionVersion: 1,
							requestId: '26',
							score: { score: 0, perfectScore: 1 },
							answer: {
								type: variantTypes.MULTIPLE_CHOICE,
								value: '5c10b024ca7c6fbfaaf501e7',
							},
							timestamp: 1626876410953,
						},
						{
							questionId: '5c10b024ca7c6fbfaaf50210',
							questionVersion: 1,
							requestId: '27',
							score: { score: 0, perfectScore: 1 },
							answer: {
								type: variantTypes.MULTIPLE_CHOICE,
								value: '5c10b024ca7c6fbfaaf50213',
							},
							timestamp: 1626875308675,
						},
						{
							questionId: '5c10b024ca7c6fbfaaf50229',
							questionVersion: 1,
							requestId: '28',
							score: { score: 0, perfectScore: 1 },
							answer: {
								type: variantTypes.MULTIPLE_CHOICE,
								value: '5c10b024ca7c6fbfaaf5022e',
							},
							timestamp: 1626875594023,
						},
						{
							questionId: '5c10b024ca7c6fbfaaf50215',
							questionVersion: 1,
							requestId: '29',
							score: { score: 0, perfectScore: 1 },
							answer: {
								type: variantTypes.MULTIPLE_CHOICE,
								value: '5c10b024ca7c6fbfaaf50218',
							},
							timestamp: 1626877017119,
						},
						{
							questionId: '5c10b024ca7c6fbfaaf50243',
							questionVersion: 1,
							requestId: '30',
							score: { score: 1, perfectScore: 1 },
							answer: {
								type: variantTypes.MULTIPLE_CHOICE,
								value: '5c10b024ca7c6fbfaaf50245',
							},
							timestamp: 1626876543786,
						},
					],
				},
				s5: {
					skills: {
						criticalThinking: { endOfMissionSurvey: 1, scannedData: 0 },
						collaboration: {
							endOfMissionSurvey: 1,
							socialHealthScore: 1,
							engineerPanelScore: { time: 233272, score: 0.41419999999999996 },
						},
					},
					disposition: {
						grit: {
							endOfMissionSurvey: 1,
							gritAfterNegativeEvents: {
								APMDetails: [
									{
										APMInDuration: 4.4,
										eventType: 'DEATH',
										actionTime: new Date('2021-05-11T19:11:56.127Z'),
										score: 0.9693704533333335,
									},
									{
										APMInDuration: 6.5,
										eventType: 'QUESTION_ANSWERED',
										actionTime: new Date('2021-05-11T19:41:03.625Z'),
										score: 1.4320245333333335,
									},
								],
								averageAPM: 4.5390283816366646,
								score: 1.2006974933333334,
							},
						},
						initiative: {
							endOfMissionSurvey: 1,
							alertResponse: { time: 78897.2, score: 0.7352000000000001 },
							scannedDataResponse: { time: 1516571.7692307692, score: 0 },
							stationResponse: { time: 28356.30769230769, score: 0.8841538461538462 },
							mapParticipation: { percentActive: 0.26, score: 0.347 },
							boostParticipation: { percentActive: 0.327, score: 0.436 },
						},
					},
					studentGameScore: 13383,
					totalHealthTasks: 2,
					knowledge: { application: 1, questions: 0.14285714285714285 },
					studentPoints: {
						DECKS: 249,
						COMMUNICATION: 95,
						TRANSPORTER: 183,
						THRUSTERS: 136,
						POWER: 128,
						DEFENSE: 96,
						TRACTOR_BEAM: 243,
						QUESTIONS: 107,
						SENSOR: 171,
					},
					questionAnswers: [
						{
							questionId: '5c10b024ca7c6fbfaaf501df',
							questionVersion: 1,
							requestId: '31',
							score: { score: 0, perfectScore: 1 },
							answer: {
								type: variantTypes.MULTIPLE_CHOICE,
								value: '5c10b024ca7c6fbfaaf501e1',
							},
							timestamp: 1626874046124,
						},
						{
							questionId: '5c10b024ca7c6fbfaaf501f1',
							questionVersion: 1,
							requestId: '32',
							score: { score: 0, perfectScore: 1 },
							answer: {
								type: variantTypes.MULTIPLE_CHOICE,
								value: '5c10b024ca7c6fbfaaf501dc',
							},
							timestamp: 1626875685245,
						},
						{
							questionId: '5c10b024ca7c6fbfaaf50210',
							questionVersion: 1,
							requestId: '33',
							score: { score: 0, perfectScore: 1 },
							answer: {
								type: variantTypes.MULTIPLE_CHOICE,
								value: '5c10b024ca7c6fbfaaf50213',
							},
							timestamp: 1626875740419,
						},
						{
							questionId: '5c10b024ca7c6fbfaaf50229',
							questionVersion: 1,
							requestId: '34',
							score: { score: 1, perfectScore: 1 },
							answer: {
								type: variantTypes.MULTIPLE_CHOICE,
								value: '5c10b024ca7c6fbfaaf5022b',
							},
							timestamp: 1626875577472,
						},
						{
							questionId: '5c10b024ca7c6fbfaaf50215',
							questionVersion: 1,
							requestId: '35',
							score: { score: 0, perfectScore: 1 },
							answer: {
								type: variantTypes.MULTIPLE_CHOICE,
								value: '5c10b024ca7c6fbfaaf50218',
							},
							timestamp: 1626875610564,
						},
						{
							questionId: '5c10b024ca7c6fbfaaf50243',
							questionVersion: 1,
							requestId: '36',
							score: { score: 0, perfectScore: 1 },
							answer: {
								type: variantTypes.MULTIPLE_CHOICE,
								value: '5c10b024ca7c6fbfaaf50246',
							},
							timestamp: 1626875188117,
						},
						{
							questionId: '5c10b024ca7c6fbfaaf5025d',
							questionVersion: 1,
							requestId: '37',
							score: { score: 0, perfectScore: 1 },
							answer: {
								type: variantTypes.MULTIPLE_CHOICE,
								value: '5c10b024ca7c6fbfaaf50260',
							},
							timestamp: 1626873673628,
						},
					],
				},
				mvp2: {
					skills: {
						criticalThinking: { endOfMissionSurvey: 1, scannedData: 0 },
						collaboration: {
							endOfMissionSurvey: 1,
							socialHealthScore: 1,
							engineerPanelScore: { time: 55714.5, score: 0.9512499999999999 },
						},
					},
					disposition: {
						grit: {
							endOfMissionSurvey: 1,
							gritAfterNegativeEvents: {
								APMDetails: [
									{
										APMInDuration: 4.2,
										eventType: 'DEATH',
										actionTime: new Date('2021-05-11T19:11:56.127Z'),
										score: 1.0948464454976303,
									},
									{
										APMInDuration: 3.5,
										eventType: 'QUESTION_ANSWERED',
										actionTime: new Date('2021-05-11T19:12:04.846Z'),
										score: 0.9123720379146919,
									},
									{
										APMInDuration: 4,
										eventType: 'QUESTION_ANSWERED',
										actionTime: new Date('2021-05-11T19:16:12.447Z'),
										score: 1.0427109004739337,
									},
									{
										APMInDuration: 8.5,
										eventType: 'QUESTION_ANSWERED',
										actionTime: new Date('2021-05-11T19:18:20.475Z'),
										score: 2,
									},
									{
										APMInDuration: 6.5,
										eventType: 'QUESTION_ANSWERED',
										actionTime: new Date('2021-05-11T19:19:09.657Z'),
										score: 1.6944052132701422,
									},
								],
								averageAPM: 3.836154391578641,
								score: 1.3488669194312795,
							},
						},
						initiative: {
							endOfMissionSurvey: 1,
							alertResponse: { time: 36625.5, score: 1.05275 },
							scannedDataResponse: {
								time: 1303925.7692307692,
								score: 0.08673076923076922,
							},
							stationResponse: { time: 17185.53846153846, score: 0.9961538461538464 },
							mapParticipation: { percentActive: 0.59, score: 0.787 },
							boostParticipation: { percentActive: 0.845, score: 1 },
						},
					},
					studentGameScore: 19476,
					totalHealthTasks: 5,
					knowledge: { application: 0.75, questions: 0.375 },
					studentPoints: {
						DECKS: 65,
						COMMUNICATION: 240,
						TRANSPORTER: 182,
						THRUSTERS: 121,
						POWER: 16,
						DEFENSE: 217,
						TRACTOR_BEAM: 224,
						QUESTIONS: 19,
						SENSOR: 112,
					},
					questionAnswers: [
						{
							questionId: '5c10b024ca7c6fbfaaf501d3',
							questionVersion: 1,
							requestId: '38',
							score: { score: 0, perfectScore: 1 },
							answer: {
								type: variantTypes.MULTIPLE_CHOICE,
								value: '5c10b024ca7c6fbfaaf501d7',
							},
							timestamp: 1626875582921,
						},
						{
							questionId: '5c10b024ca7c6fbfaaf501d9',
							questionVersion: 1,
							requestId: '39',
							score: { score: 0, perfectScore: 1 },
							answer: {
								type: variantTypes.MULTIPLE_CHOICE,
								value: '5c10b024ca7c6fbfaaf501de',
							},
							timestamp: 1626874059832,
						},
						{
							questionId: '5c10b024ca7c6fbfaaf501e5',
							questionVersion: 1,
							requestId: '40',
							score: { score: 1, perfectScore: 1 },
							answer: {
								type: variantTypes.MULTIPLE_CHOICE,
								value: '5c10b024ca7c6fbfaaf501e9',
							},
							timestamp: 1626875233416,
						},
						{
							questionId: '5c10b024ca7c6fbfaaf501f1',
							questionVersion: 1,
							requestId: '41',
							score: { score: 0, perfectScore: 1 },
							answer: {
								type: variantTypes.MULTIPLE_CHOICE,
								value: '5c10b024ca7c6fbfaaf501f6',
							},
							timestamp: 1626876005617,
						},
						{
							questionId: '5c10b024ca7c6fbfaaf50229',
							questionVersion: 1,
							requestId: '42',
							score: { score: 1, perfectScore: 1 },
							answer: {
								type: variantTypes.MULTIPLE_CHOICE,
								value: '5c10b024ca7c6fbfaaf5022b',
							},
							timestamp: 1626874455340,
						},
						{
							questionId: '5c10b024ca7c6fbfaaf50215',
							questionVersion: 1,
							requestId: '43',
							score: { score: 1, perfectScore: 1 },
							answer: {
								type: variantTypes.MULTIPLE_CHOICE,
								value: '5c10b024ca7c6fbfaaf50219',
							},
							timestamp: 1626874603919,
						},
						{
							questionId: '5c10b024ca7c6fbfaaf50243',
							questionVersion: 1,
							requestId: '44',
							score: { score: 0, perfectScore: 1 },
							answer: {
								type: variantTypes.MULTIPLE_CHOICE,
								value: '5c10b024ca7c6fbfaaf50247',
							},
							timestamp: 1626876233031,
						},
						{
							questionId: '5c10b024ca7c6fbfaaf5025d',
							questionVersion: 1,
							requestId: '45',
							score: { score: 0, perfectScore: 1 },
							answer: {
								type: variantTypes.MULTIPLE_CHOICE,
								value: '5c10b024ca7c6fbfaaf50260',
							},
							timestamp: 1626873837048,
						},
					],
				},
				s6: {
					skills: {
						criticalThinking: { endOfMissionSurvey: 1, scannedData: 0 },
						collaboration: {
							endOfMissionSurvey: 1,
							socialHealthScore: 1,
							engineerPanelScore: { time: 419630.75, score: 0.302 },
						},
					},
					disposition: {
						grit: {
							endOfMissionSurvey: 1,
							gritAfterNegativeEvents: {
								APMDetails: [
									{
										APMInDuration: 2.8,
										eventType: 'DEATH',
										actionTime: new Date('2021-05-11T19:11:56.127Z'),
										score: 0.9686238993710692,
									},
									{
										APMInDuration: 5,
										eventType: 'QUESTION_ANSWERED',
										actionTime: new Date('2021-05-11T19:15:01.527Z'),
										score: 1.729685534591195,
									},
									{
										APMInDuration: 5.5,
										eventType: 'QUESTION_ANSWERED',
										actionTime: new Date('2021-05-11T19:30:11.241Z'),
										score: 1.9026540880503144,
									},
									{
										APMInDuration: 3.5,
										eventType: 'QUESTION_ANSWERED',
										actionTime: new Date('2021-05-11T19:41:31.141Z'),
										score: 1.2107798742138365,
									},
								],
								averageAPM: 2.8906988582648534,
								score: 1.4529358490566038,
							},
						},
						initiative: {
							endOfMissionSurvey: 1,
							alertResponse: { time: 189523.25, score: 0.325 },
							scannedDataResponse: { time: 1516571.7692307692, score: 0 },
							stationResponse: { time: 38722.61538461538, score: 0.7845384615384616 },
							mapParticipation: { percentActive: 0.12, score: 0.16 },
							boostParticipation: { percentActive: 0.153, score: 0.204 },
						},
					},
					studentGameScore: 7530,
					totalHealthTasks: 3,
					knowledge: { application: 1, questions: 0.7 },
					studentPoints: {
						DECKS: 94,
						COMMUNICATION: 122,
						TRANSPORTER: 225,
						THRUSTERS: 177,
						POWER: 112,
						DEFENSE: 48,
						TRACTOR_BEAM: 47,
						QUESTIONS: 233,
						SENSOR: 9,
					},
				},
				s7: {
					skills: {
						criticalThinking: { endOfMissionSurvey: 1, scannedData: 0 },
						collaboration: {
							endOfMissionSurvey: 1,
							socialHealthScore: 0.9333333333333332,
							engineerPanelScore: { time: 129711, score: 0.668 },
						},
					},
					disposition: {
						grit: {
							endOfMissionSurvey: 1,
							gritAfterNegativeEvents: {
								APMDetails: [
									{
										APMInDuration: 4.5,
										eventType: 'QUESTION_ANSWERED',
										actionTime: new Date('2021-05-11T19:11:44.631Z'),
										score: 1.3405217741935485,
									},
									{
										APMInDuration: 4.6,
										eventType: 'DEATH',
										actionTime: new Date('2021-05-11T19:11:56.127Z'),
										score: 1.3703111469534048,
									},
									{
										APMInDuration: 5.5,
										eventType: 'QUESTION_ANSWERED',
										actionTime: new Date('2021-05-11T19:22:15.556Z'),
										score: 1.6384155017921147,
									},
								],
								averageAPM: 3.356901832278837,
								score: 1.4497494743130226,
							},
						},
						initiative: {
							endOfMissionSurvey: 1,
							alertResponse: { time: 46033.5, score: 1.03375 },
							scannedDataResponse: { time: 1516571.7692307692, score: 0 },
							stationResponse: {
								time: 37108.692307692305,
								score: 0.7975384615384615,
							},
							mapParticipation: { percentActive: 0.1, score: 0.133 },
							boostParticipation: { percentActive: 0.531, score: 0.708 },
						},
					},
					studentGameScore: 11950,
					totalHealthTasks: 3,
					knowledge: { application: 0.625, questions: 0.125 },
					studentPoints: {
						DECKS: 58,
						COMMUNICATION: 244,
						TRANSPORTER: 55,
						THRUSTERS: 0,
						POWER: 222,
						DEFENSE: 204,
						TRACTOR_BEAM: 42,
						QUESTIONS: 68,
						SENSOR: 39,
					},
					questionAnswers: [
						{
							questionId: '5c10b024ca7c6fbfaaf501d3',
							questionVersion: 1,
							requestId: '46',
							score: { score: 0, perfectScore: 1 },
							answer: {
								type: variantTypes.MULTIPLE_CHOICE,
								value: '5c10b024ca7c6fbfaaf501d5',
							},
							timestamp: 1626875651469,
						},
						{
							questionId: '5c10b024ca7c6fbfaaf501df',
							questionVersion: 1,
							requestId: '47',
							score: { score: 0, perfectScore: 1 },
							answer: {
								type: variantTypes.MULTIPLE_CHOICE,
								value: '5c10b024ca7c6fbfaaf501e1',
							},
							timestamp: 1626875583634,
						},
						{
							questionId: '5c10b024ca7c6fbfaaf501e5',
							questionVersion: 1,
							requestId: '48',
							score: { score: 1, perfectScore: 1 },
							answer: {
								type: variantTypes.MULTIPLE_CHOICE,
								value: '5c10b024ca7c6fbfaaf501e9',
							},
							timestamp: 1626874356868,
						},
						{
							questionId: '5c10b024ca7c6fbfaaf50210',
							questionVersion: 1,
							requestId: '49',
							score: { score: 0, perfectScore: 1 },
							answer: {
								type: variantTypes.MULTIPLE_CHOICE,
								value: '5c10b024ca7c6fbfaaf50213',
							},
							timestamp: 1626876552405,
						},
						{
							questionId: '5c10b024ca7c6fbfaaf50229',
							questionVersion: 1,
							requestId: '50',
							score: { score: 0, perfectScore: 1 },
							answer: {
								type: variantTypes.MULTIPLE_CHOICE,
								value: '5c10b024ca7c6fbfaaf5022c',
							},
							timestamp: 1626875836833,
						},
						{
							questionId: '5c10b024ca7c6fbfaaf50215',
							questionVersion: 1,
							requestId: '51',
							score: { score: 0, perfectScore: 1 },
							answer: {
								type: variantTypes.MULTIPLE_CHOICE,
								value: '5c10b024ca7c6fbfaaf50217',
							},
							timestamp: 1626876097166,
						},
						{
							questionId: '5c10b024ca7c6fbfaaf50243',
							questionVersion: 1,
							requestId: '52',
							score: { score: 0, perfectScore: 1 },
							answer: {
								type: variantTypes.MULTIPLE_CHOICE,
								value: '5c10b024ca7c6fbfaaf50246',
							},
							timestamp: 1626877017220,
						},
						{
							questionId: '5c10b024ca7c6fbfaaf5025d',
							questionVersion: 1,
							requestId: '53',
							score: { score: 0, perfectScore: 1 },
							answer: {
								type: variantTypes.MULTIPLE_CHOICE,
								value: '5c10b024ca7c6fbfaaf50260',
							},
							timestamp: 1626874161511,
						},
					],
				},
				s8: {
					skills: {
						criticalThinking: { endOfMissionSurvey: 1, scannedData: 0 },
						collaboration: {
							endOfMissionSurvey: 1,
							socialHealthScore: 1,
							engineerPanelScore: { time: 81554, score: 0.867 },
						},
					},
					disposition: {
						grit: {
							endOfMissionSurvey: 1,
							gritAfterNegativeEvents: {
								APMDetails: [
									{
										APMInDuration: 2.2,
										eventType: 'DEATH',
										actionTime: new Date('2021-05-11T19:11:56.127Z'),
										score: 0.5868220975609757,
									},
									{
										APMInDuration: 2,
										eventType: 'QUESTION_ANSWERED',
										actionTime: new Date('2021-05-11T19:12:13.324Z'),
										score: 0.5334746341463414,
									},
									{
										APMInDuration: 6.5,
										eventType: 'QUESTION_ANSWERED',
										actionTime: new Date('2021-05-11T19:22:06.234Z'),
										score: 1.7337925609756097,
									},
									{
										APMInDuration: 9,
										eventType: 'QUESTION_ANSWERED',
										actionTime: new Date('2021-05-11T19:30:06.260Z'),
										score: 2,
									},
									{
										APMInDuration: 7,
										eventType: 'QUESTION_ANSWERED',
										actionTime: new Date('2021-05-11T19:50:42.625Z'),
										score: 1.8671612195121952,
									},
									{
										APMInDuration: 3,
										eventType: 'QUESTION_ANSWERED',
										actionTime: new Date('2021-05-11T19:56:17.737Z'),
										score: 0.8002119512195123,
									},
								],
								averageAPM: 3.749006741811392,
								score: 1.2535770772357724,
							},
						},
						initiative: {
							endOfMissionSurvey: 1,
							alertResponse: { time: 56714.75, score: 0.88625 },
							scannedDataResponse: {
								time: 1197912.9615384615,
								score: 0.0040384615384615385,
							},
							stationResponse: { time: 28025.46153846154, score: 0.8823076923076923 },
							mapParticipation: { percentActive: 0.46, score: 0.613 },
							boostParticipation: { percentActive: 0.163, score: 0.217 },
						},
					},
					studentGameScore: 10233,
					totalHealthTasks: 4,
					knowledge: { application: 0.9375, questions: 0 },
					studentPoints: {
						DECKS: 196,
						COMMUNICATION: 135,
						TRANSPORTER: 150,
						THRUSTERS: 153,
						POWER: 216,
						DEFENSE: 61,
						TRACTOR_BEAM: 217,
						QUESTIONS: 152,
						SENSOR: 153,
					},
					questionAnswers: [
						{
							questionId: '5c10b024ca7c6fbfaaf501d3',
							questionVersion: 1,
							requestId: '54',
							score: { score: 0, perfectScore: 1 },
							answer: {
								type: variantTypes.MULTIPLE_CHOICE,
								value: '5c10b024ca7c6fbfaaf501d7',
							},
							timestamp: 1626876487156,
						},
						{
							questionId: '5c10b024ca7c6fbfaaf501d9',
							questionVersion: 1,
							requestId: '55',
							score: { score: 0, perfectScore: 1 },
							answer: {
								type: variantTypes.MULTIPLE_CHOICE,
								value: '5c10b024ca7c6fbfaaf501dc',
							},
							timestamp: 1626874462509,
						},
						{
							questionId: '5c10b024ca7c6fbfaaf501df',
							questionVersion: 1,
							requestId: '56',
							score: { score: 0, perfectScore: 1 },
							answer: {
								type: variantTypes.MULTIPLE_CHOICE,
								value: '5c10b024ca7c6fbfaaf501e1',
							},
							timestamp: 1626874764660,
						},
						{
							questionId: '5c10b024ca7c6fbfaaf501e5',
							questionVersion: 1,
							requestId: '57',
							score: { score: 0, perfectScore: 1 },
							answer: {
								type: variantTypes.MULTIPLE_CHOICE,
								value: '5c10b024ca7c6fbfaaf501e7',
							},
							timestamp: 1626874791570,
						},
						{
							questionId: '5c10b024ca7c6fbfaaf50210',
							questionVersion: 1,
							requestId: '58',
							score: { score: 0, perfectScore: 1 },
							answer: {
								type: variantTypes.MULTIPLE_CHOICE,
								value: '5c10b024ca7c6fbfaaf50214',
							},
							timestamp: 1626874834544,
						},
						{
							questionId: '5c10b024ca7c6fbfaaf50229',
							questionVersion: 1,
							requestId: '59',
							score: { score: 0, perfectScore: 1 },
							answer: {
								type: variantTypes.MULTIPLE_CHOICE,
								value: '5c10b024ca7c6fbfaaf5022e',
							},
							timestamp: 1626875273246,
						},
						{
							questionId: '5c10b024ca7c6fbfaaf50243',
							questionVersion: 1,
							requestId: '60',
							score: { score: 0, perfectScore: 1 },
							answer: {
								type: variantTypes.MULTIPLE_CHOICE,
								value: '5c10b024ca7c6fbfaaf50246',
							},
							timestamp: 1626874774706,
						},
						{
							questionId: '5c10b024ca7c6fbfaaf5025d',
							questionVersion: 1,
							requestId: '61',
							score: { score: 0, perfectScore: 1 },
							answer: {
								type: variantTypes.MULTIPLE_CHOICE,
								value: '5c10b024ca7c6fbfaaf50260',
							},
							timestamp: 1626874705715,
						},
					],
				},
				s9: {
					skills: {
						criticalThinking: { endOfMissionSurvey: 1, scannedData: 0 },
						collaboration: {
							endOfMissionSurvey: 1,
							socialHealthScore: 1,
							engineerPanelScore: { time: 228734.2, score: 0.6235999999999999 },
						},
					},
					disposition: {
						grit: {
							endOfMissionSurvey: 1,
							gritAfterNegativeEvents: {
								APMDetails: [
									{
										APMInDuration: 8.2,
										eventType: 'DEATH',
										actionTime: new Date('2021-05-11T19:11:56.127Z'),
										score: 1.957306724637681,
									},
									{
										APMInDuration: 4.5,
										eventType: 'QUESTION_ANSWERED',
										actionTime: new Date('2021-05-11T19:12:39.246Z'),
										score: 1.0741317391304348,
									},
									{
										APMInDuration: 2.5,
										eventType: 'QUESTION_ANSWERED',
										actionTime: new Date('2021-05-11T19:25:11.242Z'),
										score: 0.5967398550724637,
									},
								],
								averageAPM: 4.189430249629327,
								score: 1.2093927729468599,
							},
						},
						initiative: {
							endOfMissionSurvey: 1,
							alertResponse: { time: 70384.66666666667, score: 0.8013333333333333 },
							scannedDataResponse: { time: 1516571.7692307692, score: 0 },
							stationResponse: {
								time: 32837.230769230766,
								score: 0.8415384615384618,
							},
							mapParticipation: { percentActive: 0.64, score: 0.853 },
							boostParticipation: { percentActive: 0.857, score: 1 },
						},
					},
					studentGameScore: 20157,
					totalHealthTasks: 2,
					knowledge: { application: 1, questions: 0.125 },
					studentPoints: {
						DECKS: 157,
						COMMUNICATION: 37,
						TRANSPORTER: 240,
						THRUSTERS: 17,
						POWER: 64,
						DEFENSE: 226,
						TRACTOR_BEAM: 129,
						QUESTIONS: 188,
						SENSOR: 11,
					},
					questionAnswers: [
						{
							questionId: '5c10b024ca7c6fbfaaf501d3',
							questionVersion: 1,
							requestId: '62',
							score: { score: 0, perfectScore: 1 },
							answer: {
								type: variantTypes.MULTIPLE_CHOICE,
								value: '5c10b024ca7c6fbfaaf501d7',
							},
							timestamp: 1626876622946,
						},
						{
							questionId: '5c10b024ca7c6fbfaaf501d9',
							questionVersion: 1,
							requestId: '63',
							score: { score: 1, perfectScore: 1 },
							answer: {
								type: variantTypes.MULTIPLE_CHOICE,
								value: '5c10b024ca7c6fbfaaf501db',
							},
							timestamp: 1626873728672,
						},
						{
							questionId: '5c10b024ca7c6fbfaaf501e5',
							questionVersion: 1,
							requestId: '64',
							score: { score: 0, perfectScore: 1 },
							answer: {
								type: variantTypes.MULTIPLE_CHOICE,
								value: '5c10b024ca7c6fbfaaf501e7',
							},
							timestamp: 1626874166600,
						},
						{
							questionId: '5c10b024ca7c6fbfaaf501f1',
							questionVersion: 1,
							requestId: '65',
							score: { score: 0, perfectScore: 1 },
							answer: {
								type: variantTypes.MULTIPLE_CHOICE,
								value: '5c10b024ca7c6fbfaaf501f5',
							},
							timestamp: 1626875155312,
						},
						{
							questionId: '5c10b024ca7c6fbfaaf50210',
							questionVersion: 1,
							requestId: '66',
							score: { score: 0, perfectScore: 1 },
							answer: {
								type: variantTypes.MULTIPLE_CHOICE,
								value: '5c10b024ca7c6fbfaaf50213',
							},
							timestamp: 1626876134302,
						},
						{
							questionId: '5c10b024ca7c6fbfaaf50229',
							questionVersion: 1,
							requestId: '67',
							score: { score: 0, perfectScore: 1 },
							answer: {
								type: variantTypes.MULTIPLE_CHOICE,
								value: '5c10b024ca7c6fbfaaf5022e',
							},
							timestamp: 1626874597984,
						},
						{
							questionId: '5c10b024ca7c6fbfaaf50215',
							questionVersion: 1,
							requestId: '68',
							score: { score: 0, perfectScore: 1 },
							answer: {
								type: variantTypes.MULTIPLE_CHOICE,
								value: '5c10b024ca7c6fbfaaf50217',
							},
							timestamp: 1626875308676,
						},
						{
							questionId: '5c10b024ca7c6fbfaaf5025d',
							questionVersion: 1,
							requestId: '69',
							score: { score: 0, perfectScore: 1 },
							answer: {
								type: variantTypes.MULTIPLE_CHOICE,
								value: '5c10b024ca7c6fbfaaf50262',
							},
							timestamp: 1626875302451,
						},
					],
				},
				s10: {
					skills: {
						criticalThinking: { endOfMissionSurvey: 1, scannedData: 0 },
						collaboration: {
							endOfMissionSurvey: 1,
							socialHealthScore: 1,
							engineerPanelScore: { time: 423535.25, score: 0.6775 },
						},
					},
					disposition: {
						grit: {
							endOfMissionSurvey: 1,
							gritAfterNegativeEvents: {
								APMDetails: [
									{
										APMInDuration: 5,
										eventType: 'DEATH',
										actionTime: new Date('2021-05-11T19:11:56.127Z'),
										score: 1.3752504432624113,
									},
									{
										APMInDuration: 4,
										eventType: 'QUESTION_ANSWERED',
										actionTime: new Date('2021-05-11T19:14:37.548Z'),
										score: 1.100200354609929,
									},
									{
										APMInDuration: 8,
										eventType: 'QUESTION_ANSWERED',
										actionTime: new Date('2021-05-11T19:23:52.648Z'),
										score: 2,
									},
									{
										APMInDuration: 2.5,
										eventType: 'QUESTION_ANSWERED',
										actionTime: new Date('2021-05-11T19:25:09.140Z'),
										score: 0.6876252216312057,
									},
								],
								averageAPM: 3.6357014276896695,
								score: 1.2907690048758864,
							},
						},
						initiative: {
							endOfMissionSurvey: 1,
							alertResponse: { time: 21750.5, score: 1.263 },
							scannedDataResponse: { time: 1447545.1923076923, score: 0 },
							stationResponse: { time: 33235.07692307692, score: 0.832076923076923 },
							mapParticipation: { percentActive: 0.04, score: 0.053 },
							boostParticipation: { percentActive: 0.88, score: 1 },
						},
					},
					studentGameScore: 10495,
					totalHealthTasks: 2,
					knowledge: { application: 0.5, questions: 0.2222222222222222 },
					studentPoints: {
						DECKS: 67,
						COMMUNICATION: 193,
						TRANSPORTER: 234,
						THRUSTERS: 138,
						POWER: 189,
						DEFENSE: 228,
						TRACTOR_BEAM: 207,
						QUESTIONS: 229,
						SENSOR: 81,
					},
					questionAnswers: [
						{
							questionId: '5c10b024ca7c6fbfaaf501d3',
							questionVersion: 1,
							requestId: '70',
							score: { score: 0, perfectScore: 1 },
							answer: {
								type: variantTypes.MULTIPLE_CHOICE,
								value: '5c10b024ca7c6fbfaaf501d7',
							},
							timestamp: 1626876445993,
						},
						{
							questionId: '5c10b024ca7c6fbfaaf501d9',
							questionVersion: 1,
							requestId: '71',
							score: { score: 0, perfectScore: 1 },
							answer: {
								type: variantTypes.MULTIPLE_CHOICE,
								value: '5c10b024ca7c6fbfaaf501de',
							},
							timestamp: 1626874863141,
						},
						{
							questionId: '5c10b024ca7c6fbfaaf501df',
							questionVersion: 1,
							requestId: '72',
							score: { score: 1, perfectScore: 1 },
							answer: {
								type: variantTypes.MULTIPLE_CHOICE,
								value: '5c10b024ca7c6fbfaaf501e2',
							},
							timestamp: 1626876775878,
						},
						{
							questionId: '5c10b024ca7c6fbfaaf501e5',
							questionVersion: 1,
							requestId: '73',
							score: { score: 0, perfectScore: 1 },
							answer: {
								type: variantTypes.MULTIPLE_CHOICE,
								value: '5c10b024ca7c6fbfaaf501e8',
							},
							timestamp: 1626873787457,
						},
						{
							questionId: '5c10b024ca7c6fbfaaf50210',
							questionVersion: 1,
							requestId: '74',
							score: { score: 0, perfectScore: 1 },
							answer: {
								type: variantTypes.MULTIPLE_CHOICE,
								value: '5c10b024ca7c6fbfaaf50213',
							},
							timestamp: 1626873615398,
						},
						{
							questionId: '5c10b024ca7c6fbfaaf50229',
							questionVersion: 1,
							requestId: '75',
							score: { score: 0, perfectScore: 1 },
							answer: {
								type: variantTypes.MULTIPLE_CHOICE,
								value: '5c10b024ca7c6fbfaaf5022e',
							},
							timestamp: 1626876419022,
						},
						{
							questionId: '5c10b024ca7c6fbfaaf50215',
							questionVersion: 1,
							requestId: '76',
							score: { score: 1, perfectScore: 1 },
							answer: {
								type: variantTypes.MULTIPLE_CHOICE,
								value: '5c10b024ca7c6fbfaaf50219',
							},
							timestamp: 1626875679219,
						},
						{
							questionId: '5c10b024ca7c6fbfaaf50243',
							questionVersion: 1,
							requestId: '77',
							score: { score: 0, perfectScore: 1 },
							answer: {
								type: variantTypes.MULTIPLE_CHOICE,
								value: '5c10b024ca7c6fbfaaf50247',
							},
							timestamp: 1626876841404,
						},
						{
							questionId: '5c10b024ca7c6fbfaaf5025d',
							questionVersion: 1,
							requestId: '78',
							score: { score: 0, perfectScore: 1 },
							answer: {
								type: variantTypes.MULTIPLE_CHOICE,
								value: '5c10b024ca7c6fbfaaf50261',
							},
							timestamp: 1626875459848,
						},
					],
				},
				s11: {
					skills: {
						criticalThinking: {
							endOfMissionSurvey: 0.6666666666666666,
							scannedData: 1,
						},
						collaboration: {
							endOfMissionSurvey: 0.6666666666666666,
							socialHealthScore: 1,
							engineerPanelScore: { time: 196571.75, score: 0.65425 },
						},
					},
					disposition: {
						grit: {
							endOfMissionSurvey: 0.6666666666666666,
							gritAfterNegativeEvents: {
								APMDetails: [
									{
										APMInDuration: 3.4,
										eventType: 'DEATH',
										actionTime: new Date('2021-05-11T19:11:56.127Z'),
										score: 0.8590666666666666,
									},
									{
										APMInDuration: 5,
										eventType: 'QUESTION_ANSWERED',
										actionTime: new Date('2021-05-11T19:12:00.550Z'),
										score: 1.2633333333333334,
									},
									{
										APMInDuration: 4,
										eventType: 'QUESTION_ANSWERED',
										actionTime: new Date('2021-05-11T19:12:44.138Z'),
										score: 1.0106666666666666,
									},
									{
										APMInDuration: 3,
										eventType: 'QUESTION_ANSWERED',
										actionTime: new Date('2021-05-11T19:13:22.647Z'),
										score: 0.758,
									},
									{
										APMInDuration: 8,
										eventType: 'QUESTION_ANSWERED',
										actionTime: new Date('2021-05-11T19:33:53.933Z'),
										score: 2,
									},
								],
								averageAPM: 3.9577836411609497,
								score: 1.1782133333333333,
							},
						},
						initiative: {
							endOfMissionSurvey: 0.6666666666666666,
							alertResponse: { time: 42096.4, score: 0.9714 },
							scannedDataResponse: { time: 357106.5, score: 0.5348076923076922 },
							stationResponse: { time: 35181.38461538462, score: 0.802923076923077 },
							mapParticipation: { percentActive: 0.45, score: 0.6 },
							boostParticipation: { percentActive: 0.635, score: 0.847 },
						},
					},
					studentGameScore: 12421,
					totalHealthTasks: 3,
					knowledge: { application: 0.6875, questions: 0.3333333333333333 },
					studentPoints: {
						DECKS: 250,
						COMMUNICATION: 34,
						TRANSPORTER: 99,
						THRUSTERS: 177,
						POWER: 217,
						DEFENSE: 130,
						TRACTOR_BEAM: 167,
						QUESTIONS: 150,
						SENSOR: 117,
					},
					questionAnswers: [
						{
							questionId: '5c10b024ca7c6fbfaaf501d3',
							questionVersion: 1,
							requestId: '79',
							score: { score: 0, perfectScore: 1 },
							answer: {
								type: variantTypes.MULTIPLE_CHOICE,
								value: '5c10b024ca7c6fbfaaf501d7',
							},
							timestamp: 1626875038659,
						},
						{
							questionId: '5c10b024ca7c6fbfaaf501d9',
							questionVersion: 1,
							requestId: '80',
							score: { score: 1, perfectScore: 1 },
							answer: {
								type: variantTypes.MULTIPLE_CHOICE,
								value: '5c10b024ca7c6fbfaaf501db',
							},
							timestamp: 1626874821718,
						},
						{
							questionId: '5c10b024ca7c6fbfaaf501df',
							questionVersion: 1,
							requestId: '81',
							score: { score: 0, perfectScore: 1 },
							answer: {
								type: variantTypes.MULTIPLE_CHOICE,
								value: '5c10b024ca7c6fbfaaf501e4',
							},
							timestamp: 1626876768090,
						},
						{
							questionId: '5c10b024ca7c6fbfaaf501e5',
							questionVersion: 1,
							requestId: '82',
							score: { score: 1, perfectScore: 1 },
							answer: {
								type: variantTypes.MULTIPLE_CHOICE,
								value: '5c10b024ca7c6fbfaaf501e9',
							},
							timestamp: 1626874493831,
						},
						{
							questionId: '5c10b024ca7c6fbfaaf50210',
							questionVersion: 1,
							requestId: '83',
							score: { score: 0, perfectScore: 1 },
							answer: {
								type: variantTypes.MULTIPLE_CHOICE,
								value: '5c10b024ca7c6fbfaaf50213',
							},
							timestamp: 1626874512911,
						},
						{
							questionId: '5c10b024ca7c6fbfaaf50229',
							questionVersion: 1,
							requestId: '84',
							score: { score: 0, perfectScore: 1 },
							answer: {
								type: variantTypes.MULTIPLE_CHOICE,
								value: '5c10b024ca7c6fbfaaf5022d',
							},
							timestamp: 1626874336920,
						},
						{
							questionId: '5c10b024ca7c6fbfaaf50215',
							questionVersion: 1,
							requestId: '85',
							score: { score: 0, perfectScore: 1 },
							answer: {
								type: variantTypes.MULTIPLE_CHOICE,
								value: '5c10b024ca7c6fbfaaf50217',
							},
							timestamp: 1626873729077,
						},
						{
							questionId: '5c10b024ca7c6fbfaaf50243',
							questionVersion: 1,
							requestId: '86',
							score: { score: 1, perfectScore: 1 },
							answer: {
								type: variantTypes.MULTIPLE_CHOICE,
								value: '5c10b024ca7c6fbfaaf50245',
							},
							timestamp: 1626876936934,
						},
						{
							questionId: '5c10b024ca7c6fbfaaf5025d',
							questionVersion: 1,
							requestId: '87',
							score: { score: 0, perfectScore: 1 },
							answer: {
								type: variantTypes.MULTIPLE_CHOICE,
								value: '5c10b024ca7c6fbfaaf50260',
							},
							timestamp: 1626875169484,
						},
					],
				},
				s12: {
					skills: {
						criticalThinking: { endOfMissionSurvey: 1, scannedData: 0.5 },
						collaboration: {
							endOfMissionSurvey: 1,
							socialHealthScore: 1,
							engineerPanelScore: { time: 99472.25, score: 0.7805 },
						},
					},
					disposition: {
						grit: {
							endOfMissionSurvey: 1,
							gritAfterNegativeEvents: {
								APMDetails: [
									{
										APMInDuration: 2.8,
										eventType: 'DEATH',
										actionTime: new Date('2021-05-11T19:11:56.127Z'),
										score: 0.8408476923076922,
									},
									{
										APMInDuration: 9.5,
										eventType: 'QUESTION_ANSWERED',
										actionTime: new Date('2021-05-11T19:22:38.540Z'),
										score: 2,
									},
								],
								averageAPM: 3.329972866210107,
								score: 1.420423846153846,
							},
						},
						initiative: {
							endOfMissionSurvey: 1,
							alertResponse: { time: 52200, score: 0.98925 },
							scannedDataResponse: {
								time: 238092.23076923078,
								score: 0.47146153846153843,
							},
							stationResponse: {
								time: 46466.846153846156,
								score: 0.7114615384615384,
							},
							mapParticipation: { percentActive: 0.07, score: 0.093 },
							boostParticipation: { percentActive: 0.77, score: 1 },
						},
					},
					studentGameScore: 13223,
					totalHealthTasks: 2,
					knowledge: { application: 0.9166666666666666, questions: 0.375 },
					studentPoints: {
						DECKS: 254,
						COMMUNICATION: 82,
						TRANSPORTER: 27,
						THRUSTERS: 199,
						POWER: 218,
						DEFENSE: 71,
						TRACTOR_BEAM: 32,
						QUESTIONS: 121,
						SENSOR: 205,
					},
					questionAnswers: [
						{
							questionId: '5c10b024ca7c6fbfaaf501d3',
							questionVersion: 1,
							requestId: '88',
							score: { score: 0, perfectScore: 1 },
							answer: {
								type: variantTypes.MULTIPLE_CHOICE,
								value: '5c10b024ca7c6fbfaaf501d7',
							},
							timestamp: 1626874284372,
						},
						{
							questionId: '5c10b024ca7c6fbfaaf501df',
							questionVersion: 1,
							requestId: '89',
							score: { score: 1, perfectScore: 1 },
							answer: {
								type: variantTypes.MULTIPLE_CHOICE,
								value: '5c10b024ca7c6fbfaaf501e2',
							},
							timestamp: 1626875511868,
						},
						{
							questionId: '5c10b024ca7c6fbfaaf501e5',
							questionVersion: 1,
							requestId: '90',
							score: { score: 1, perfectScore: 1 },
							answer: {
								type: variantTypes.MULTIPLE_CHOICE,
								value: '5c10b024ca7c6fbfaaf501e9',
							},
							timestamp: 1626875637048,
						},
						{
							questionId: '5c10b024ca7c6fbfaaf501f1',
							questionVersion: 1,
							requestId: '91',
							score: { score: 0, perfectScore: 1 },
							answer: {
								type: variantTypes.MULTIPLE_CHOICE,
								value: '5c10b024ca7c6fbfaaf501f6',
							},
							timestamp: 1626876201035,
						},
						{
							questionId: '5c10b024ca7c6fbfaaf50229',
							questionVersion: 1,
							requestId: '92',
							score: { score: 0, perfectScore: 1 },
							answer: {
								type: variantTypes.MULTIPLE_CHOICE,
								value: '5c10b024ca7c6fbfaaf5022d',
							},
							timestamp: 1626876076573,
						},
						{
							questionId: '5c10b024ca7c6fbfaaf50215',
							questionVersion: 1,
							requestId: '93',
							score: { score: 1, perfectScore: 1 },
							answer: {
								type: variantTypes.MULTIPLE_CHOICE,
								value: '5c10b024ca7c6fbfaaf50219',
							},
							timestamp: 1626875852134,
						},
						{
							questionId: '5c10b024ca7c6fbfaaf50243',
							questionVersion: 1,
							requestId: '94',
							score: { score: 0, perfectScore: 1 },
							answer: {
								type: variantTypes.MULTIPLE_CHOICE,
								value: '5c10b024ca7c6fbfaaf50246',
							},
							timestamp: 1626873683035,
						},
						{
							questionId: '5c10b024ca7c6fbfaaf5025d',
							questionVersion: 1,
							requestId: '95',
							score: { score: 0, perfectScore: 1 },
							answer: {
								type: variantTypes.MULTIPLE_CHOICE,
								value: '5c10b024ca7c6fbfaaf50260',
							},
							timestamp: 1626876526949,
						},
					],
				},
				s13: {
					skills: {
						criticalThinking: { endOfMissionSurvey: 1, scannedData: 0.5 },
						collaboration: {
							endOfMissionSurvey: 1,
							socialHealthScore: 1,
							engineerPanelScore: { time: 43769.5, score: 1.00025 },
						},
					},
					disposition: {
						grit: {
							endOfMissionSurvey: 1,
							gritAfterNegativeEvents: {
								APMDetails: [
									{
										APMInDuration: 4.2,
										eventType: 'DEATH',
										actionTime: new Date('2021-05-11T19:11:56.127Z'),
										score: 1.0861810849056606,
									},
									{
										APMInDuration: 11,
										eventType: 'QUESTION_ANSWERED',
										actionTime: new Date('2021-05-11T19:18:46.047Z'),
										score: 2,
									},
									{
										APMInDuration: 9.5,
										eventType: 'QUESTION_ANSWERED',
										actionTime: new Date('2021-05-11T19:19:06.946Z'),
										score: 2,
									},
								],
								averageAPM: 3.866758552847372,
								score: 1.6953936949685537,
							},
						},
						initiative: {
							endOfMissionSurvey: 1,
							alertResponse: { time: 26061, score: 1.1256 },
							scannedDataResponse: { time: 164346.96153846153, score: 0.638 },
							stationResponse: { time: 46165.92307692308, score: 0.7174615384615385 },
							mapParticipation: { percentActive: 0.53, score: 0.707 },
							boostParticipation: { percentActive: 0.835, score: 1 },
						},
					},
					studentGameScore: 12298,
					totalHealthTasks: 2,
					knowledge: { application: 0.6875, questions: 0.75 },
					studentPoints: {
						DECKS: 238,
						COMMUNICATION: 117,
						TRANSPORTER: 150,
						THRUSTERS: 203,
						POWER: 82,
						DEFENSE: 213,
						TRACTOR_BEAM: 111,
						QUESTIONS: 161,
						SENSOR: 119,
					},
					questionAnswers: [
						{
							questionId: '5c10b024ca7c6fbfaaf501d3',
							questionVersion: 1,
							requestId: '96',
							score: { score: 1, perfectScore: 1 },
							answer: {
								type: variantTypes.MULTIPLE_CHOICE,
								value: '5c10b024ca7c6fbfaaf501d8',
							},
							timestamp: 1626875219156,
						},
						{
							questionId: '5c10b024ca7c6fbfaaf501f1',
							questionVersion: 1,
							requestId: '97',
							score: { score: 1, perfectScore: 1 },
							answer: {
								type: variantTypes.MULTIPLE_CHOICE,
								value: '5c10b024ca7c6fbfaaf501f3',
							},
							timestamp: 1626875396973,
						},
						{
							questionId: '5c10b024ca7c6fbfaaf50210',
							questionVersion: 1,
							requestId: '98',
							score: { score: 0, perfectScore: 1 },
							answer: {
								type: variantTypes.MULTIPLE_CHOICE,
								value: '5c10b024ca7c6fbfaaf50213',
							},
							timestamp: 1626873970168,
						},
						{
							questionId: '5c10b024ca7c6fbfaaf50229',
							questionVersion: 1,
							requestId: '99',
							score: { score: 1, perfectScore: 1 },
							answer: {
								type: variantTypes.MULTIPLE_CHOICE,
								value: '5c10b024ca7c6fbfaaf5022b',
							},
							timestamp: 1626874987455,
						},
					],
				},
				s14: {
					skills: {
						criticalThinking: { endOfMissionSurvey: 1, scannedData: 0 },
						collaboration: {
							endOfMissionSurvey: 1,
							socialHealthScore: 1,
							engineerPanelScore: { time: 588720.25, score: 0.66225 },
						},
					},
					disposition: {
						grit: {
							endOfMissionSurvey: 1,
							gritAfterNegativeEvents: {
								APMDetails: [
									{
										APMInDuration: 8.4,
										eventType: 'DEATH',
										actionTime: new Date('2021-05-11T19:11:56.127Z'),
										score: 1.395218957055215,
									},
									{
										APMInDuration: 11.5,
										eventType: 'QUESTION_ANSWERED',
										actionTime: new Date('2021-05-11T19:12:13.553Z'),
										score: 1.9101211912065441,
									},
									{
										APMInDuration: 11.5,
										eventType: 'QUESTION_ANSWERED',
										actionTime: new Date('2021-05-11T19:13:02.835Z'),
										score: 1.9101211912065441,
									},
									{
										APMInDuration: 8,
										eventType: 'QUESTION_ANSWERED',
										actionTime: new Date('2021-05-11T19:14:12.839Z'),
										score: 1.3287799591002045,
									},
									{
										APMInDuration: 8,
										eventType: 'QUESTION_ANSWERED',
										actionTime: new Date('2021-05-11T19:30:54.957Z'),
										score: 1.3287799591002045,
									},
									{
										APMInDuration: 7.5,
										eventType: 'QUESTION_ANSWERED',
										actionTime: new Date('2021-05-11T19:31:05.146Z'),
										score: 1.2457312116564419,
									},
									{
										APMInDuration: 11.5,
										eventType: 'QUESTION_ANSWERED',
										actionTime: new Date('2021-05-11T19:35:08.229Z'),
										score: 1.9101211912065441,
									},
								],
								averageAPM: 6.02056039844044,
								score: 1.5755533800759571,
							},
						},
						initiative: {
							endOfMissionSurvey: 1,
							alertResponse: { time: 17173.2, score: 1.2146000000000001 },
							scannedDataResponse: {
								time: 68644.26923076923,
								score: 0.899153846153846,
							},
							stationResponse: {
								time: 29772.076923076922,
								score: 0.8718461538461539,
							},
							mapParticipation: { percentActive: 0.21, score: 0.28 },
							boostParticipation: { percentActive: 0.889, score: 1 },
						},
					},
					studentGameScore: 11946,
					totalHealthTasks: 3,
					knowledge: { application: 0.75, questions: 0.4 },
					studentPoints: {
						DECKS: 59,
						COMMUNICATION: 219,
						TRANSPORTER: 41,
						THRUSTERS: 172,
						POWER: 35,
						DEFENSE: 125,
						TRACTOR_BEAM: 102,
						QUESTIONS: 162,
						SENSOR: 95,
					},
				},
				s15: {
					skills: {
						criticalThinking: {
							endOfMissionSurvey: 0.6666666666666666,
							scannedData: 0.5,
						},
						collaboration: {
							endOfMissionSurvey: 0.6666666666666666,
							socialHealthScore: 0.4,
							engineerPanelScore: { time: 412992.5, score: 0.4855 },
						},
					},
					disposition: {
						grit: {
							endOfMissionSurvey: 0.6666666666666666,
							gritAfterNegativeEvents: {
								APMDetails: [
									{
										APMInDuration: 10,
										eventType: 'QUESTION_ANSWERED',
										actionTime: new Date('2021-05-11T19:30:43.836Z'),
										score: 2,
									},
									{
										APMInDuration: 2.5,
										eventType: 'QUESTION_ANSWERED',
										actionTime: new Date('2021-05-11T19:34:37.032Z'),
										score: 0.597532258064516,
									},
									{
										APMInDuration: 2,
										eventType: 'QUESTION_ANSWERED',
										actionTime: new Date('2021-05-11T19:44:10.448Z'),
										score: 0.47802580645161286,
									},
									{
										APMInDuration: 7,
										eventType: 'QUESTION_ANSWERED',
										actionTime: new Date('2021-05-11T19:50:40.544Z'),
										score: 1.673090322580645,
									},
								],
								averageAPM: 4.183874537749346,
								score: 1.1871620967741934,
							},
						},
						initiative: {
							endOfMissionSurvey: 0.6666666666666666,
							alertResponse: { time: 143336.5, score: 0.655 },
							scannedDataResponse: {
								time: 491594.76923076925,
								score: 0.12749999999999997,
							},
							stationResponse: { time: 57663.75, score: 0.609 },
							mapParticipation: { percentActive: 0.02, score: 0.027 },
						},
					},
					studentGameScore: 3212,
					totalHealthTasks: 1,
					knowledge: { application: 0.5, questions: 0.5 },
					studentPoints: {
						DECKS: 140,
						COMMUNICATION: 93,
						TRANSPORTER: 96,
						THRUSTERS: 224,
						POWER: 217,
						DEFENSE: 147,
						TRACTOR_BEAM: 216,
						QUESTIONS: 168,
						SENSOR: 120,
					},
					questionAnswers: [
						{
							questionId: '5c10b024ca7c6fbfaaf501d3',
							questionVersion: 1,
							requestId: '100',
							score: { score: 1, perfectScore: 1 },
							answer: {
								type: variantTypes.MULTIPLE_CHOICE,
								value: '5c10b024ca7c6fbfaaf501d8',
							},
							timestamp: 1626875668321,
						},
						{
							questionId: '5c10b024ca7c6fbfaaf501d9',
							questionVersion: 1,
							requestId: '101',
							score: { score: 1, perfectScore: 1 },
							answer: {
								type: variantTypes.MULTIPLE_CHOICE,
								value: '5c10b024ca7c6fbfaaf501db',
							},
							timestamp: 1626876167617,
						},
						{
							questionId: '5c10b024ca7c6fbfaaf501df',
							questionVersion: 1,
							requestId: '102',
							score: { score: 0, perfectScore: 1 },
							answer: {
								type: variantTypes.MULTIPLE_CHOICE,
								value: '5c10b024ca7c6fbfaaf501e3',
							},
							timestamp: 1626876293507,
						},
						{
							questionId: '5c10b024ca7c6fbfaaf501f1',
							questionVersion: 1,
							requestId: '103',
							score: { score: 0, perfectScore: 1 },
							answer: {
								type: variantTypes.MULTIPLE_CHOICE,
								value: '5c10b024ca7c6fbfaaf501f6',
							},
							timestamp: 1626874456696,
						},
						{
							questionId: '5c10b024ca7c6fbfaaf50210',
							questionVersion: 1,
							requestId: '104',
							score: { score: 1, perfectScore: 1 },
							answer: {
								type: variantTypes.MULTIPLE_CHOICE,
								value: '5c10b024ca7c6fbfaaf50212',
							},
							timestamp: 1626875336169,
						},
						{
							questionId: '5c10b024ca7c6fbfaaf50229',
							questionVersion: 1,
							requestId: '105',
							score: { score: 0, perfectScore: 1 },
							answer: {
								type: variantTypes.MULTIPLE_CHOICE,
								value: '5c10b024ca7c6fbfaaf5022e',
							},
							timestamp: 1626875812422,
						},
						{
							questionId: '5c10b024ca7c6fbfaaf50215',
							questionVersion: 1,
							requestId: '106',
							score: { score: 0, perfectScore: 1 },
							answer: {
								type: variantTypes.MULTIPLE_CHOICE,
								value: '5c10b024ca7c6fbfaaf50217',
							},
							timestamp: 1626874029493,
						},
						{
							questionId: '5c10b024ca7c6fbfaaf50243',
							questionVersion: 1,
							requestId: '107',
							score: { score: 1, perfectScore: 1 },
							answer: {
								type: variantTypes.MULTIPLE_CHOICE,
								value: '5c10b024ca7c6fbfaaf50245',
							},
							timestamp: 1626875780916,
						},
					],
				},
				s16: {
					skills: {
						criticalThinking: { endOfMissionSurvey: 1, scannedData: 0.5 },
						collaboration: {
							endOfMissionSurvey: 1,
							socialHealthScore: 1,
							engineerPanelScore: { time: 115828.4, score: 0.7272 },
						},
					},
					disposition: {
						grit: {
							endOfMissionSurvey: 1,
							gritAfterNegativeEvents: {
								APMDetails: [
									{
										APMInDuration: 4.8,
										eventType: 'DEATH',
										actionTime: new Date('2021-05-11T19:11:56.127Z'),
										score: 0.9883127547169811,
									},
									{
										APMInDuration: 3,
										eventType: 'QUESTION_ANSWERED',
										actionTime: new Date('2021-05-11T19:12:06.124Z'),
										score: 0.6176954716981132,
									},
									{
										APMInDuration: 9,
										eventType: 'QUESTION_ANSWERED',
										actionTime: new Date('2021-05-11T19:16:28.550Z'),
										score: 1.8530864150943398,
									},
									{
										APMInDuration: 11.5,
										eventType: 'QUESTION_ANSWERED',
										actionTime: new Date('2021-05-11T19:17:19.738Z'),
										score: 2,
									},
									{
										APMInDuration: 7,
										eventType: 'QUESTION_ANSWERED',
										actionTime: new Date('2021-05-11T19:19:02.455Z'),
										score: 1.4412894339622642,
									},
									{
										APMInDuration: 6,
										eventType: 'QUESTION_ANSWERED',
										actionTime: new Date('2021-05-11T19:19:14.454Z'),
										score: 1.2353909433962265,
									},
								],
								averageAPM: 4.856762170771089,
								score: 1.355962503144654,
							},
						},
						initiative: {
							endOfMissionSurvey: 1,
							alertResponse: { time: 39633.166666666664, score: 1.0438333333333332 },
							scannedDataResponse: {
								time: 178377.84615384616,
								score: 0.9024230769230768,
							},
							stationResponse: { time: 32044.923076923078, score: 0.863923076923077 },
							mapParticipation: { percentActive: 0.17, score: 0.227 },
							boostParticipation: { percentActive: 0.863, score: 1 },
						},
					},
					studentGameScore: 13999,
					totalHealthTasks: 8,
					knowledge: { application: 0.6875, questions: 0.375 },
					studentPoints: {
						DECKS: 37,
						COMMUNICATION: 10,
						TRANSPORTER: 152,
						THRUSTERS: 180,
						POWER: 78,
						DEFENSE: 96,
						TRACTOR_BEAM: 225,
						QUESTIONS: 253,
						SENSOR: 47,
					},
					questionAnswers: [
						{
							questionId: '5c10b024ca7c6fbfaaf501d3',
							questionVersion: 1,
							requestId: '108',
							score: { score: 0, perfectScore: 1 },
							answer: {
								type: variantTypes.MULTIPLE_CHOICE,
								value: '5c10b024ca7c6fbfaaf501d5',
							},
							timestamp: 1626874231238,
						},
						{
							questionId: '5c10b024ca7c6fbfaaf501d9',
							questionVersion: 1,
							requestId: '109',
							score: { score: 0, perfectScore: 1 },
							answer: {
								type: variantTypes.MULTIPLE_CHOICE,
								value: '5c10b024ca7c6fbfaaf501dd',
							},
							timestamp: 1626873906470,
						},
						{
							questionId: '5c10b024ca7c6fbfaaf501df',
							questionVersion: 1,
							requestId: '110',
							score: { score: 1, perfectScore: 1 },
							answer: {
								type: variantTypes.MULTIPLE_CHOICE,
								value: '5c10b024ca7c6fbfaaf501e2',
							},
							timestamp: 1626873829594,
						},
						{
							questionId: '5c10b024ca7c6fbfaaf501f1',
							questionVersion: 1,
							requestId: '111',
							score: { score: 0, perfectScore: 1 },
							answer: {
								type: variantTypes.MULTIPLE_CHOICE,
								value: '5c10b024ca7c6fbfaaf501f5',
							},
							timestamp: 1626874160447,
						},
						{
							questionId: '5c10b024ca7c6fbfaaf50229',
							questionVersion: 1,
							requestId: '112',
							score: { score: 1, perfectScore: 1 },
							answer: {
								type: variantTypes.MULTIPLE_CHOICE,
								value: '5c10b024ca7c6fbfaaf5022b',
							},
							timestamp: 1626874142104,
						},
						{
							questionId: '5c10b024ca7c6fbfaaf50215',
							questionVersion: 1,
							requestId: '113',
							score: { score: 1, perfectScore: 1 },
							answer: {
								type: variantTypes.MULTIPLE_CHOICE,
								value: '5c10b024ca7c6fbfaaf50219',
							},
							timestamp: 1626876020651,
						},
						{
							questionId: '5c10b024ca7c6fbfaaf50243',
							questionVersion: 1,
							requestId: '114',
							score: { score: 0, perfectScore: 1 },
							answer: {
								type: variantTypes.MULTIPLE_CHOICE,
								value: '5c10b024ca7c6fbfaaf50247',
							},
							timestamp: 1626874196797,
						},
						{
							questionId: '5c10b024ca7c6fbfaaf5025d',
							questionVersion: 1,
							requestId: '115',
							score: { score: 0, perfectScore: 1 },
							answer: {
								type: variantTypes.MULTIPLE_CHOICE,
								value: '5c10b024ca7c6fbfaaf50261',
							},
							timestamp: 1626875267261,
						},
					],
				},
				s17: {
					skills: {
						criticalThinking: { endOfMissionSurvey: 1, scannedData: 0.5 },
						collaboration: {
							endOfMissionSurvey: 1,
							socialHealthScore: 1,
							engineerPanelScore: { time: 88452.5, score: 0.82975 },
						},
					},
					disposition: {
						grit: {
							endOfMissionSurvey: 1,
							gritAfterNegativeEvents: {
								APMDetails: [
									{
										APMInDuration: 2.6,
										eventType: 'DEATH',
										actionTime: new Date('2021-05-11T19:11:56.127Z'),
										score: 0.7197024288107202,
									},
									{
										APMInDuration: 5,
										eventType: 'QUESTION_ANSWERED',
										actionTime: new Date('2021-05-11T19:13:55.837Z'),
										score: 1.384043132328308,
									},
									{
										APMInDuration: 9,
										eventType: 'QUESTION_ANSWERED',
										actionTime: new Date('2021-05-11T19:17:46.779Z'),
										score: 2,
									},
									{
										APMInDuration: 11,
										eventType: 'QUESTION_ANSWERED',
										actionTime: new Date('2021-05-11T19:21:27.364Z'),
										score: 2,
									},
									{
										APMInDuration: 9,
										eventType: 'QUESTION_ANSWERED',
										actionTime: new Date('2021-05-11T19:22:13.448Z'),
										score: 2,
									},
								],
								averageAPM: 3.612604176279351,
								score: 1.6207491122278057,
							},
						},
						initiative: {
							endOfMissionSurvey: 1,
							alertResponse: { time: 83238, score: 0.7891999999999999 },
							scannedDataResponse: {
								time: 252836.07692307694,
								score: 0.5309615384615385,
							},
							stationResponse: { time: 9354.384615384615, score: 1.0570769230769232 },
							mapParticipation: { percentActive: 0.43, score: 0.573 },
							boostParticipation: { percentActive: 0.801, score: 1 },
						},
					},
					studentGameScore: 15411,
					totalHealthTasks: 2,
					knowledge: { application: 0.75, questions: 0.1111111111111111 },
					studentPoints: {
						DECKS: 56,
						COMMUNICATION: 51,
						TRANSPORTER: 86,
						THRUSTERS: 77,
						POWER: 117,
						DEFENSE: 195,
						TRACTOR_BEAM: 207,
						QUESTIONS: 155,
						SENSOR: 134,
					},
					questionAnswers: [
						{
							questionId: '5c10b024ca7c6fbfaaf501d9',
							questionVersion: 1,
							requestId: '116',
							score: { score: 0, perfectScore: 1 },
							answer: {
								type: variantTypes.MULTIPLE_CHOICE,
								value: '5c10b024ca7c6fbfaaf501de',
							},
							timestamp: 1626873566087,
						},
						{
							questionId: '5c10b024ca7c6fbfaaf501df',
							questionVersion: 1,
							requestId: '117',
							score: { score: 1, perfectScore: 1 },
							answer: {
								type: variantTypes.MULTIPLE_CHOICE,
								value: '5c10b024ca7c6fbfaaf501e2',
							},
							timestamp: 1626876597907,
						},
						{
							questionId: '5c10b024ca7c6fbfaaf501e5',
							questionVersion: 1,
							requestId: '118',
							score: { score: 0, perfectScore: 1 },
							answer: {
								type: variantTypes.MULTIPLE_CHOICE,
								value: '5c10b024ca7c6fbfaaf501ea',
							},
							timestamp: 1626876515138,
						},
						{
							questionId: '5c10b024ca7c6fbfaaf501f1',
							questionVersion: 1,
							requestId: '119',
							score: { score: 0, perfectScore: 1 },
							answer: {
								type: variantTypes.MULTIPLE_CHOICE,
								value: '5c10b024ca7c6fbfaaf501f5',
							},
							timestamp: 1626874976740,
						},
						{
							questionId: '5c10b024ca7c6fbfaaf50210',
							questionVersion: 1,
							requestId: '120',
							score: { score: 0, perfectScore: 1 },
							answer: {
								type: variantTypes.MULTIPLE_CHOICE,
								value: '5c10b024ca7c6fbfaaf50214',
							},
							timestamp: 1626875673518,
						},
						{
							questionId: '5c10b024ca7c6fbfaaf50229',
							questionVersion: 1,
							requestId: '121',
							score: { score: 0, perfectScore: 1 },
							answer: {
								type: variantTypes.MULTIPLE_CHOICE,
								value: '5c10b024ca7c6fbfaaf5022d',
							},
							timestamp: 1626874439903,
						},
						{
							questionId: '5c10b024ca7c6fbfaaf50215',
							questionVersion: 1,
							requestId: '122',
							score: { score: 0, perfectScore: 1 },
							answer: {
								type: variantTypes.MULTIPLE_CHOICE,
								value: '5c10b024ca7c6fbfaaf50217',
							},
							timestamp: 1626874931074,
						},
						{
							questionId: '5c10b024ca7c6fbfaaf50243',
							questionVersion: 1,
							requestId: '123',
							score: { score: 0, perfectScore: 1 },
							answer: {
								type: variantTypes.MULTIPLE_CHOICE,
								value: '5c10b024ca7c6fbfaaf50247',
							},
							timestamp: 1626875022084,
						},
						{
							questionId: '5c10b024ca7c6fbfaaf5025d',
							questionVersion: 1,
							requestId: '124',
							score: { score: 0, perfectScore: 1 },
							answer: {
								type: variantTypes.MULTIPLE_CHOICE,
								value: '5c10b024ca7c6fbfaaf50261',
							},
							timestamp: 1626873662431,
						},
					],
				},
				s18: {
					skills: {
						criticalThinking: { endOfMissionSurvey: 1, scannedData: 0 },
						collaboration: {
							endOfMissionSurvey: 1,
							socialHealthScore: 1,
							engineerPanelScore: { time: 406122.2, score: 0.6394 },
						},
					},
					disposition: {
						grit: {
							endOfMissionSurvey: 1,
							gritAfterNegativeEvents: {
								APMDetails: [
									{
										APMInDuration: 4.4,
										eventType: 'DEATH',
										actionTime: new Date('2021-05-11T19:11:56.127Z'),
										score: 1.1953206896551725,
									},
									{
										APMInDuration: 3,
										eventType: 'QUESTION_ANSWERED',
										actionTime: new Date('2021-05-11T19:12:00.153Z'),
										score: 0.8149913793103448,
									},
									{
										APMInDuration: 4,
										eventType: 'QUESTION_ANSWERED',
										actionTime: new Date('2021-05-11T19:50:50.024Z'),
										score: 1.0866551724137932,
									},
								],
								averageAPM: 3.681020531209342,
								score: 1.0323224137931035,
							},
						},
						initiative: {
							endOfMissionSurvey: 1,
							alertResponse: { time: 33265.8, score: 1.1138 },
							scannedDataResponse: { time: 1516571.7692307692, score: 0 },
							stationResponse: { time: 28112.76923076923, score: 0.8867692307692306 },
							mapParticipation: { percentActive: 0.46, score: 0.613 },
							boostParticipation: { percentActive: 0.289, score: 0.385 },
						},
					},
					studentGameScore: 12084,
					totalHealthTasks: 1,
					knowledge: { application: 0.6875, questions: 0.5555555555555556 },
					studentPoints: {
						DECKS: 121,
						COMMUNICATION: 15,
						TRANSPORTER: 229,
						THRUSTERS: 144,
						POWER: 231,
						DEFENSE: 121,
						TRACTOR_BEAM: 213,
						QUESTIONS: 92,
						SENSOR: 191,
					},
					questionAnswers: [
						{
							questionId: '5c10b024ca7c6fbfaaf501d3',
							questionVersion: 1,
							requestId: '125',
							score: { score: 1, perfectScore: 1 },
							answer: {
								type: variantTypes.MULTIPLE_CHOICE,
								value: '5c10b024ca7c6fbfaaf501d8',
							},
							timestamp: 1626875275876,
						},
						{
							questionId: '5c10b024ca7c6fbfaaf501d9',
							questionVersion: 1,
							requestId: '126',
							score: { score: 1, perfectScore: 1 },
							answer: {
								type: variantTypes.MULTIPLE_CHOICE,
								value: '5c10b024ca7c6fbfaaf501db',
							},
							timestamp: 1626875253857,
						},
						{
							questionId: '5c10b024ca7c6fbfaaf501df',
							questionVersion: 1,
							requestId: '127',
							score: { score: 1, perfectScore: 1 },
							answer: {
								type: variantTypes.MULTIPLE_CHOICE,
								value: '5c10b024ca7c6fbfaaf501e2',
							},
							timestamp: 1626876912734,
						},
						{
							questionId: '5c10b024ca7c6fbfaaf501e5',
							questionVersion: 1,
							requestId: '128',
							score: { score: 1, perfectScore: 1 },
							answer: {
								type: variantTypes.MULTIPLE_CHOICE,
								value: '5c10b024ca7c6fbfaaf501e9',
							},
							timestamp: 1626877152282,
						},
						{
							questionId: '5c10b024ca7c6fbfaaf501f1',
							questionVersion: 1,
							requestId: '129',
							score: { score: 0, perfectScore: 1 },
							answer: {
								type: variantTypes.MULTIPLE_CHOICE,
								value: '5c10b024ca7c6fbfaaf501f6',
							},
							timestamp: 1626876006306,
						},
						{
							questionId: '5c10b024ca7c6fbfaaf50229',
							questionVersion: 1,
							requestId: '130',
							score: { score: 0, perfectScore: 1 },
							answer: {
								type: variantTypes.MULTIPLE_CHOICE,
								value: '5c10b024ca7c6fbfaaf5022e',
							},
							timestamp: 1626876832225,
						},
						{
							questionId: '5c10b024ca7c6fbfaaf50215',
							questionVersion: 1,
							requestId: '131',
							score: { score: 0, perfectScore: 1 },
							answer: {
								type: variantTypes.MULTIPLE_CHOICE,
								value: '5c10b024ca7c6fbfaaf50217',
							},
							timestamp: 1626874302663,
						},
						{
							questionId: '5c10b024ca7c6fbfaaf50243',
							questionVersion: 1,
							requestId: '132',
							score: { score: 0, perfectScore: 1 },
							answer: {
								type: variantTypes.MULTIPLE_CHOICE,
								value: '5c10b024ca7c6fbfaaf50247',
							},
							timestamp: 1626875625162,
						},
						{
							questionId: '5c10b024ca7c6fbfaaf5025d',
							questionVersion: 1,
							requestId: '133',
							score: { score: 1, perfectScore: 1 },
							answer: {
								type: variantTypes.MULTIPLE_CHOICE,
								value: '5c10b024ca7c6fbfaaf5025f',
							},
							timestamp: 1626875318408,
						},
					],
				},
				mvp3: {
					skills: {
						criticalThinking: { endOfMissionSurvey: 1, scannedData: 0 },
						collaboration: {
							endOfMissionSurvey: 1,
							socialHealthScore: 0.96,
							engineerPanelScore: { time: 82524.5, score: 0.83675 },
						},
					},
					disposition: {
						grit: {
							endOfMissionSurvey: 1,
							gritAfterNegativeEvents: {
								APMDetails: [
									{
										APMInDuration: 5.4,
										eventType: 'DEATH',
										actionTime: new Date('2021-05-11T19:11:56.127Z'),
										score: 1.3104728,
									},
									{
										APMInDuration: 7.5,
										eventType: 'QUESTION_ANSWERED',
										actionTime: new Date('2021-05-11T19:14:39.664Z'),
										score: 1.8201011111111112,
									},
									{
										APMInDuration: 6.5,
										eventType: 'QUESTION_ANSWERED',
										actionTime: new Date('2021-05-11T19:18:03.945Z'),
										score: 1.577420962962963,
									},
									{
										APMInDuration: 9.5,
										eventType: 'QUESTION_ANSWERED',
										actionTime: new Date('2021-05-11T19:21:11.078Z'),
										score: 2,
									},
									{
										APMInDuration: 8.5,
										eventType: 'QUESTION_ANSWERED',
										actionTime: new Date('2021-05-11T19:31:23.938Z'),
										score: 2,
									},
									{
										APMInDuration: 9,
										eventType: 'QUESTION_ANSWERED',
										actionTime: new Date('2021-05-11T19:31:40.345Z'),
										score: 2,
									},
									{
										APMInDuration: 8,
										eventType: 'QUESTION_ANSWERED',
										actionTime: new Date('2021-05-11T19:42:29.235Z'),
										score: 1.9414411851851852,
									},
								],
								averageAPM: 4.120650195868239,
								score: 1.8070622941798944,
							},
						},
						initiative: {
							endOfMissionSurvey: 1,
							alertResponse: { time: 33920.5, score: 1.06025 },
							scannedDataResponse: { time: 1516571.7692307692, score: 0 },
							stationResponse: {
								time: 7242.2307692307695,
								score: 1.0831538461538461,
							},
							mapParticipation: { percentActive: 0.59, score: 0.787 },
							boostParticipation: { percentActive: 0.782, score: 1 },
						},
					},
					studentGameScore: 17118,
					totalHealthTasks: 5,
					knowledge: { application: 1, questions: 0.375 },
					studentPoints: {
						DECKS: 146,
						COMMUNICATION: 16,
						TRANSPORTER: 90,
						THRUSTERS: 211,
						POWER: 4,
						DEFENSE: 28,
						TRACTOR_BEAM: 198,
						QUESTIONS: 112,
						SENSOR: 146,
					},
					questionAnswers: [
						{
							questionId: '5c10b024ca7c6fbfaaf501d3',
							questionVersion: 1,
							requestId: '134',
							score: { score: 0, perfectScore: 1 },
							answer: {
								type: variantTypes.MULTIPLE_CHOICE,
								value: '5c10b024ca7c6fbfaaf501d5',
							},
							timestamp: 1626873775970,
						},
						{
							questionId: '5c10b024ca7c6fbfaaf501df',
							questionVersion: 1,
							requestId: '135',
							score: { score: 1, perfectScore: 1 },
							answer: {
								type: variantTypes.MULTIPLE_CHOICE,
								value: '5c10b024ca7c6fbfaaf501e2',
							},
							timestamp: 1626876672582,
						},
						{
							questionId: '5c10b024ca7c6fbfaaf501f1',
							questionVersion: 1,
							requestId: '136',
							score: { score: 1, perfectScore: 1 },
							answer: {
								type: variantTypes.MULTIPLE_CHOICE,
								value: '5c10b024ca7c6fbfaaf501f3',
							},
							timestamp: 1626873580690,
						},
						{
							questionId: '5c10b024ca7c6fbfaaf50210',
							questionVersion: 1,
							requestId: '137',
							score: { score: 0, perfectScore: 1 },
							answer: {
								type: variantTypes.MULTIPLE_CHOICE,
								value: '5c10b024ca7c6fbfaaf50214',
							},
							timestamp: 1626873814951,
						},
						{
							questionId: '5c10b024ca7c6fbfaaf50229',
							questionVersion: 1,
							requestId: '138',
							score: { score: 1, perfectScore: 1 },
							answer: {
								type: variantTypes.MULTIPLE_CHOICE,
								value: '5c10b024ca7c6fbfaaf5022b',
							},
							timestamp: 1626876058430,
						},
						{
							questionId: '5c10b024ca7c6fbfaaf50215',
							questionVersion: 1,
							requestId: '139',
							score: { score: 0, perfectScore: 1 },
							answer: {
								type: variantTypes.MULTIPLE_CHOICE,
								value: '5c10b024ca7c6fbfaaf50218',
							},
							timestamp: 1626876952868,
						},
						{
							questionId: '5c10b024ca7c6fbfaaf50243',
							questionVersion: 1,
							requestId: '140',
							score: { score: 0, perfectScore: 1 },
							answer: {
								type: variantTypes.MULTIPLE_CHOICE,
								value: '5c10b024ca7c6fbfaaf50246',
							},
							timestamp: 1626873876802,
						},
						{
							questionId: '5c10b024ca7c6fbfaaf5025d',
							questionVersion: 1,
							requestId: '141',
							score: { score: 0, perfectScore: 1 },
							answer: {
								type: variantTypes.MULTIPLE_CHOICE,
								value: '5c10b024ca7c6fbfaaf50261',
							},
							timestamp: 1626874058556,
						},
					],
				},
				s19: {
					skills: {
						criticalThinking: { endOfMissionSurvey: 1, scannedData: 0 },
						collaboration: {
							endOfMissionSurvey: 1,
							socialHealthScore: 1,
							engineerPanelScore: { time: 41981.6, score: 1.0139999999999998 },
						},
					},
					disposition: {
						grit: {
							endOfMissionSurvey: 1,
							gritAfterNegativeEvents: {
								APMDetails: [
									{
										APMInDuration: 7,
										eventType: 'DEATH',
										actionTime: new Date('2021-05-11T19:11:56.127Z'),
										score: 1.847634370015949,
									},
									{
										APMInDuration: 9.5,
										eventType: 'QUESTION_ANSWERED',
										actionTime: new Date('2021-05-11T19:14:17.648Z'),
										score: 2,
									},
									{
										APMInDuration: 7.5,
										eventType: 'QUESTION_ANSWERED',
										actionTime: new Date('2021-05-11T19:18:57.558Z'),
										score: 1.9796082535885167,
									},
								],
								averageAPM: 3.788628374530387,
								score: 1.9424142078681552,
							},
						},
						initiative: {
							endOfMissionSurvey: 1,
							alertResponse: { time: 52515.2, score: 0.9570000000000001 },
							scannedDataResponse: { time: 772950.3461538461, score: 0.3335 },
							stationResponse: { time: 18227.76923076923, score: 0.9760769230769231 },
							mapParticipation: { percentActive: 0.06, score: 0.08 },
							boostParticipation: { percentActive: 0.936, score: 1.072 },
						},
					},
					studentGameScore: 15527,
					totalHealthTasks: 4,
					knowledge: { application: 0.25, questions: 0.125 },
					studentPoints: {
						DECKS: 134,
						COMMUNICATION: 71,
						TRANSPORTER: 184,
						THRUSTERS: 45,
						POWER: 169,
						DEFENSE: 52,
						TRACTOR_BEAM: 240,
						QUESTIONS: 33,
						SENSOR: 105,
					},
					questionAnswers: [
						{
							questionId: '5c10b024ca7c6fbfaaf501d3',
							questionVersion: 1,
							requestId: '142',
							score: { score: 1, perfectScore: 1 },
							answer: {
								type: variantTypes.MULTIPLE_CHOICE,
								value: '5c10b024ca7c6fbfaaf501d8',
							},
							timestamp: 1626876788091,
						},
						{
							questionId: '5c10b024ca7c6fbfaaf501d9',
							questionVersion: 1,
							requestId: '143',
							score: { score: 0, perfectScore: 1 },
							answer: {
								type: variantTypes.MULTIPLE_CHOICE,
								value: '5c10b024ca7c6fbfaaf501dd',
							},
							timestamp: 1626874274941,
						},
						{
							questionId: '5c10b024ca7c6fbfaaf501df',
							questionVersion: 1,
							requestId: '144',
							score: { score: 0, perfectScore: 1 },
							answer: {
								type: variantTypes.MULTIPLE_CHOICE,
								value: '5c10b024ca7c6fbfaaf501e4',
							},
							timestamp: 1626876475941,
						},
						{
							questionId: '5c10b024ca7c6fbfaaf501e5',
							questionVersion: 1,
							requestId: '145',
							score: { score: 0, perfectScore: 1 },
							answer: {
								type: variantTypes.MULTIPLE_CHOICE,
								value: '5c10b024ca7c6fbfaaf501e7',
							},
							timestamp: 1626876452417,
						},
						{
							questionId: '5c10b024ca7c6fbfaaf501f1',
							questionVersion: 1,
							requestId: '146',
							score: { score: 0, perfectScore: 1 },
							answer: {
								type: variantTypes.MULTIPLE_CHOICE,
								value: '5c10b024ca7c6fbfaaf501f6',
							},
							timestamp: 1626875328997,
						},
						{
							questionId: '5c10b024ca7c6fbfaaf50210',
							questionVersion: 1,
							requestId: '147',
							score: { score: 0, perfectScore: 1 },
							answer: {
								type: variantTypes.MULTIPLE_CHOICE,
								value: '5c10b024ca7c6fbfaaf50214',
							},
							timestamp: 1626875004898,
						},
						{
							questionId: '5c10b024ca7c6fbfaaf50215',
							questionVersion: 1,
							requestId: '148',
							score: { score: 0, perfectScore: 1 },
							answer: {
								type: variantTypes.MULTIPLE_CHOICE,
								value: '5c10b024ca7c6fbfaaf50217',
							},
							timestamp: 1626873629825,
						},
						{
							questionId: '5c10b024ca7c6fbfaaf50243',
							questionVersion: 1,
							requestId: '149',
							score: { score: 0, perfectScore: 1 },
							answer: {
								type: variantTypes.MULTIPLE_CHOICE,
								value: '5c10b024ca7c6fbfaaf50247',
							},
							timestamp: 1626875843283,
						},
					],
				},
				s20: {
					skills: {
						criticalThinking: {
							endOfMissionSurvey: 0.6666666666666666,
							scannedData: 0,
						},
						collaboration: {
							endOfMissionSurvey: 0.6666666666666666,
							socialHealthScore: 1,
							engineerPanelScore: { time: 72477.6, score: 0.8779999999999999 },
						},
					},
					disposition: {
						grit: {
							endOfMissionSurvey: 0.6666666666666666,
							gritAfterNegativeEvents: {
								APMDetails: [
									{
										APMInDuration: 3,
										eventType: 'DEATH',
										actionTime: new Date('2021-05-11T19:11:56.127Z'),
										score: 0.9101848901098901,
									},
									{
										APMInDuration: 3.5,
										eventType: 'QUESTION_ANSWERED',
										actionTime: new Date('2021-05-11T19:17:16.959Z'),
										score: 1.0618823717948718,
									},
									{
										APMInDuration: 7.5,
										eventType: 'QUESTION_ANSWERED',
										actionTime: new Date('2021-05-11T19:19:34.750Z'),
										score: 2,
									},
									{
										APMInDuration: 11,
										eventType: 'QUESTION_ANSWERED',
										actionTime: new Date('2021-05-11T19:22:15.856Z'),
										score: 2,
									},
									{
										APMInDuration: 10,
										eventType: 'QUESTION_ANSWERED',
										actionTime: new Date('2021-05-11T19:22:26.834Z'),
										score: 2,
									},
								],
								averageAPM: 3.2960336219576205,
								score: 1.5944134523809523,
							},
						},
						initiative: {
							endOfMissionSurvey: 0.6666666666666666,
							alertResponse: { time: 38551.5, score: 1.0573333333333335 },
							scannedDataResponse: {
								time: 1431214.423076923,
								score: 0.04123076923076923,
							},
							stationResponse: { time: 36826.92307692308, score: 0.7957692307692308 },
							mapParticipation: { percentActive: 0.48, score: 0.64 },
							boostParticipation: { percentActive: 0.793, score: 1 },
						},
					},
					studentGameScore: 14654,
					totalHealthTasks: 4,
					knowledge: { application: 0.875, questions: 0.14285714285714285 },
					studentPoints: {
						DECKS: 131,
						COMMUNICATION: 42,
						TRANSPORTER: 13,
						THRUSTERS: 38,
						POWER: 44,
						DEFENSE: 200,
						TRACTOR_BEAM: 85,
						QUESTIONS: 195,
						SENSOR: 114,
					},
					questionAnswers: [
						{
							questionId: '5c10b024ca7c6fbfaaf501d9',
							questionVersion: 1,
							requestId: '150',
							score: { score: 0, perfectScore: 1 },
							answer: {
								type: variantTypes.MULTIPLE_CHOICE,
								value: '5c10b024ca7c6fbfaaf501de',
							},
							timestamp: 1626875258601,
						},
						{
							questionId: '5c10b024ca7c6fbfaaf501df',
							questionVersion: 1,
							requestId: '151',
							score: { score: 0, perfectScore: 1 },
							answer: {
								type: variantTypes.MULTIPLE_CHOICE,
								value: '5c10b024ca7c6fbfaaf501e3',
							},
							timestamp: 1626874641578,
						},
						{
							questionId: '5c10b024ca7c6fbfaaf501f1',
							questionVersion: 1,
							requestId: '152',
							score: { score: 0, perfectScore: 1 },
							answer: {
								type: variantTypes.MULTIPLE_CHOICE,
								value: '5c10b024ca7c6fbfaaf501f5',
							},
							timestamp: 1626876050300,
						},
						{
							questionId: '5c10b024ca7c6fbfaaf50229',
							questionVersion: 1,
							requestId: '153',
							score: { score: 0, perfectScore: 1 },
							answer: {
								type: variantTypes.MULTIPLE_CHOICE,
								value: '5c10b024ca7c6fbfaaf5022d',
							},
							timestamp: 1626876536914,
						},
						{
							questionId: '5c10b024ca7c6fbfaaf50215',
							questionVersion: 1,
							requestId: '154',
							score: { score: 1, perfectScore: 1 },
							answer: {
								type: variantTypes.MULTIPLE_CHOICE,
								value: '5c10b024ca7c6fbfaaf50219',
							},
							timestamp: 1626873928169,
						},
						{
							questionId: '5c10b024ca7c6fbfaaf50243',
							questionVersion: 1,
							requestId: '155',
							score: { score: 0, perfectScore: 1 },
							answer: {
								type: variantTypes.MULTIPLE_CHOICE,
								value: '5c10b024ca7c6fbfaaf50247',
							},
							timestamp: 1626877142570,
						},
						{
							questionId: '5c10b024ca7c6fbfaaf5025d',
							questionVersion: 1,
							requestId: '156',
							score: { score: 0, perfectScore: 1 },
							answer: {
								type: variantTypes.MULTIPLE_CHOICE,
								value: '5c10b024ca7c6fbfaaf50261',
							},
							timestamp: 1626877003545,
						},
					],
				},
				s21: {
					skills: {
						criticalThinking: { endOfMissionSurvey: 1, scannedData: 0 },
						collaboration: {
							endOfMissionSurvey: 1,
							socialHealthScore: 0.8333333333333334,
							engineerPanelScore: { time: 80080.6, score: 0.8654 },
						},
					},
					disposition: {
						grit: {
							endOfMissionSurvey: 1,
							gritAfterNegativeEvents: {
								APMDetails: [
									{
										APMInDuration: 3.6,
										eventType: 'DEATH',
										actionTime: new Date('2021-05-11T19:11:56.127Z'),
										score: 1.0207941538461538,
									},
									{
										APMInDuration: 1.5,
										eventType: 'QUESTION_ANSWERED',
										actionTime: new Date('2021-05-11T19:12:05.447Z'),
										score: 0.4253308974358974,
									},
									{
										APMInDuration: 12,
										eventType: 'QUESTION_ANSWERED',
										actionTime: new Date('2021-05-11T19:32:28.933Z'),
										score: 2,
									},
									{
										APMInDuration: 7.5,
										eventType: 'QUESTION_ANSWERED',
										actionTime: new Date('2021-05-11T19:35:40.355Z'),
										score: 2,
									},
								],
								averageAPM: 3.5266659653524663,
								score: 1.3615312628205127,
							},
						},
						initiative: {
							endOfMissionSurvey: 1,
							alertResponse: { time: 43378, score: 0.9813333333333333 },
							scannedDataResponse: { time: 1420654.8461538462, score: 0.0315 },
							stationResponse: { time: 22810.23076923077, score: 0.9324615384615385 },
							mapParticipation: { percentActive: 0, score: 0 },
							boostParticipation: { percentActive: 0.797, score: 1 },
						},
					},
					studentGameScore: 11809,
					totalHealthTasks: 6,
					knowledge: { application: 0.5, questions: 0.125 },
					studentPoints: {
						DECKS: 95,
						COMMUNICATION: 48,
						TRANSPORTER: 201,
						THRUSTERS: 224,
						POWER: 183,
						DEFENSE: 101,
						TRACTOR_BEAM: 167,
						QUESTIONS: 204,
						SENSOR: 145,
					},
					questionAnswers: [
						{
							questionId: '5c10b024ca7c6fbfaaf501d3',
							questionVersion: 1,
							requestId: '157',
							score: { score: 1, perfectScore: 1 },
							answer: {
								type: variantTypes.MULTIPLE_CHOICE,
								value: '5c10b024ca7c6fbfaaf501d8',
							},
							timestamp: 1626874520251,
						},
						{
							questionId: '5c10b024ca7c6fbfaaf501d9',
							questionVersion: 1,
							requestId: '158',
							score: { score: 0, perfectScore: 1 },
							answer: {
								type: variantTypes.MULTIPLE_CHOICE,
								value: '5c10b024ca7c6fbfaaf501de',
							},
							timestamp: 1626874630370,
						},
						{
							questionId: '5c10b024ca7c6fbfaaf501df',
							questionVersion: 1,
							requestId: '159',
							score: { score: 0, perfectScore: 1 },
							answer: {
								type: variantTypes.MULTIPLE_CHOICE,
								value: '5c10b024ca7c6fbfaaf501e1',
							},
							timestamp: 1626874436207,
						},
						{
							questionId: '5c10b024ca7c6fbfaaf501e5',
							questionVersion: 1,
							requestId: '160',
							score: { score: 0, perfectScore: 1 },
							answer: {
								type: variantTypes.MULTIPLE_CHOICE,
								value: '5c10b024ca7c6fbfaaf501ea',
							},
							timestamp: 1626875027488,
						},
						{
							questionId: '5c10b024ca7c6fbfaaf50210',
							questionVersion: 1,
							requestId: '161',
							score: { score: 0, perfectScore: 1 },
							answer: {
								type: variantTypes.MULTIPLE_CHOICE,
								value: '5c10b024ca7c6fbfaaf50214',
							},
							timestamp: 1626875151363,
						},
						{
							questionId: '5c10b024ca7c6fbfaaf50229',
							questionVersion: 1,
							requestId: '162',
							score: { score: 0, perfectScore: 1 },
							answer: {
								type: variantTypes.MULTIPLE_CHOICE,
								value: '5c10b024ca7c6fbfaaf5022c',
							},
							timestamp: 1626875859074,
						},
						{
							questionId: '5c10b024ca7c6fbfaaf50215',
							questionVersion: 1,
							requestId: '163',
							score: { score: 0, perfectScore: 1 },
							answer: {
								type: variantTypes.MULTIPLE_CHOICE,
								value: '5c10b024ca7c6fbfaaf50218',
							},
							timestamp: 1626873839469,
						},
						{
							questionId: '5c10b024ca7c6fbfaaf50243',
							questionVersion: 1,
							requestId: '164',
							score: { score: 0, perfectScore: 1 },
							answer: {
								type: variantTypes.MULTIPLE_CHOICE,
								value: '5c10b024ca7c6fbfaaf50247',
							},
							timestamp: 1626874495380,
						},
					],
				},
				s22: {
					skills: {
						criticalThinking: { endOfMissionSurvey: 1, scannedData: 0 },
						collaboration: {
							endOfMissionSurvey: 1,
							socialHealthScore: 1,
							engineerPanelScore: { time: 96716.75, score: 0.8115 },
						},
					},
					disposition: {
						grit: {
							endOfMissionSurvey: 1,
							gritAfterNegativeEvents: {
								APMDetails: [
									{
										APMInDuration: 4.8,
										eventType: 'DEATH',
										actionTime: new Date('2021-05-11T19:11:56.127Z'),
										score: 1.6676573333333333,
									},
									{
										APMInDuration: 6.5,
										eventType: 'QUESTION_ANSWERED',
										actionTime: new Date('2021-05-11T19:14:16.145Z'),
										score: 2,
									},
									{
										APMInDuration: 5,
										eventType: 'QUESTION_ANSWERED',
										actionTime: new Date('2021-05-11T19:14:32.747Z'),
										score: 1.7371430555555556,
									},
									{
										APMInDuration: 5,
										eventType: 'QUESTION_ANSWERED',
										actionTime: new Date('2021-05-11T19:34:46.040Z'),
										score: 1.7371430555555556,
									},
								],
								averageAPM: 2.878289144932252,
								score: 1.785485861111111,
							},
						},
						initiative: {
							endOfMissionSurvey: 1,
							alertResponse: { time: 260173.2, score: 0.7196 },
							scannedDataResponse: { time: 1516571.7692307692, score: 0 },
							stationResponse: { time: 93677.09090909091, score: 0.2608181818181818 },
							mapParticipation: { percentActive: 0, score: 0 },
							boostParticipation: { percentActive: 0.44, score: 0.587 },
						},
					},
					studentGameScore: 5467,
					totalHealthTasks: 4,
					knowledge: { application: 1, questions: 0.3 },
					studentPoints: {
						DECKS: 197,
						COMMUNICATION: 191,
						TRANSPORTER: 66,
						THRUSTERS: 82,
						POWER: 222,
						DEFENSE: 185,
						TRACTOR_BEAM: 109,
						QUESTIONS: 179,
						SENSOR: 126,
					},
					questionAnswers: [
						{
							questionId: '5c10b024ca7c6fbfaaf501d3',
							questionVersion: 1,
							requestId: '165',
							score: { score: 0, perfectScore: 1 },
							answer: {
								type: variantTypes.MULTIPLE_CHOICE,
								value: '5c10b024ca7c6fbfaaf501d5',
							},
							timestamp: 1626874776712,
						},
						{
							questionId: '5c10b024ca7c6fbfaaf501d9',
							questionVersion: 1,
							requestId: '166',
							score: { score: 0, perfectScore: 1 },
							answer: {
								type: variantTypes.MULTIPLE_CHOICE,
								value: '5c10b024ca7c6fbfaaf501de',
							},
							timestamp: 1626876745414,
						},
						{
							questionId: '5c10b024ca7c6fbfaaf501df',
							questionVersion: 1,
							requestId: '167',
							score: { score: 1, perfectScore: 1 },
							answer: {
								type: variantTypes.MULTIPLE_CHOICE,
								value: '5c10b024ca7c6fbfaaf501e2',
							},
							timestamp: 1626874888758,
						},
						{
							questionId: '5c10b024ca7c6fbfaaf501e5',
							questionVersion: 1,
							requestId: '168',
							score: { score: 0, perfectScore: 1 },
							answer: {
								type: variantTypes.MULTIPLE_CHOICE,
								value: '5c10b024ca7c6fbfaaf501ea',
							},
							timestamp: 1626875846721,
						},
						{
							questionId: '5c10b024ca7c6fbfaaf501f1',
							questionVersion: 1,
							requestId: '169',
							score: { score: 0, perfectScore: 1 },
							answer: {
								type: variantTypes.MULTIPLE_CHOICE,
								value: '5c10b024ca7c6fbfaaf501f6',
							},
							timestamp: 1626876556846,
						},
						{
							questionId: '5c10b024ca7c6fbfaaf50210',
							questionVersion: 1,
							requestId: '170',
							score: { score: 1, perfectScore: 1 },
							answer: {
								type: variantTypes.MULTIPLE_CHOICE,
								value: '5c10b024ca7c6fbfaaf50212',
							},
							timestamp: 1626873943203,
						},
						{
							questionId: '5c10b024ca7c6fbfaaf50229',
							questionVersion: 1,
							requestId: '171',
							score: { score: 0, perfectScore: 1 },
							answer: {
								type: variantTypes.MULTIPLE_CHOICE,
								value: '5c10b024ca7c6fbfaaf5022e',
							},
							timestamp: 1626875087944,
						},
						{
							questionId: '5c10b024ca7c6fbfaaf50215',
							questionVersion: 1,
							requestId: '172',
							score: { score: 1, perfectScore: 1 },
							answer: {
								type: variantTypes.MULTIPLE_CHOICE,
								value: '5c10b024ca7c6fbfaaf50219',
							},
							timestamp: 1626876315701,
						},
						{
							questionId: '5c10b024ca7c6fbfaaf50243',
							questionVersion: 1,
							requestId: '173',
							score: { score: 0, perfectScore: 1 },
							answer: {
								type: variantTypes.MULTIPLE_CHOICE,
								value: '5c10b024ca7c6fbfaaf50247',
							},
							timestamp: 1626875500316,
						},
						{
							questionId: '5c10b024ca7c6fbfaaf5025d',
							questionVersion: 1,
							requestId: '174',
							score: { score: 0, perfectScore: 1 },
							answer: {
								type: variantTypes.MULTIPLE_CHOICE,
								value: '5c10b024ca7c6fbfaaf50262',
							},
							timestamp: 1626877038539,
						},
					],
				},
				s23: {
					skills: {
						criticalThinking: { endOfMissionSurvey: 1, scannedData: 0.5 },
						collaboration: {
							endOfMissionSurvey: 1,
							socialHealthScore: 1,
							engineerPanelScore: { time: 225286.6, score: 0.5628 },
						},
					},
					disposition: {
						grit: {
							endOfMissionSurvey: 1,
							gritAfterNegativeEvents: {
								APMDetails: [
									{
										APMInDuration: 5,
										eventType: 'DEATH',
										actionTime: new Date('2021-05-11T19:11:56.127Z'),
										score: 1.4473517025089606,
									},
									{
										APMInDuration: 3.5,
										eventType: 'QUESTION_ANSWERED',
										actionTime: new Date('2021-05-11T19:12:01.840Z'),
										score: 1.0131461917562723,
									},
									{
										APMInDuration: 6.5,
										eventType: 'QUESTION_ANSWERED',
										actionTime: new Date('2021-05-11T19:15:56.551Z'),
										score: 1.8815572132616487,
									},
									{
										APMInDuration: 5.5,
										eventType: 'QUESTION_ANSWERED',
										actionTime: new Date('2021-05-11T19:16:18.162Z'),
										score: 1.5920868727598567,
									},
									{
										APMInDuration: 6.5,
										eventType: 'QUESTION_ANSWERED',
										actionTime: new Date('2021-05-11T19:22:34.953Z'),
										score: 1.8815572132616487,
									},
									{
										APMInDuration: 12.5,
										eventType: 'QUESTION_ANSWERED',
										actionTime: new Date('2021-05-11T19:31:14.456Z'),
										score: 2,
									},
									{
										APMInDuration: 7.5,
										eventType: 'QUESTION_ANSWERED',
										actionTime: new Date('2021-05-11T19:44:38.140Z'),
										score: 2,
									},
								],
								averageAPM: 3.4545853584395427,
								score: 1.6879570276497695,
							},
						},
						initiative: {
							endOfMissionSurvey: 1,
							alertResponse: { time: 32741.5, score: 1.0758333333333334 },
							scannedDataResponse: {
								time: 120806.65384615384,
								score: 0.5963461538461539,
							},
							stationResponse: { time: 26222.30769230769, score: 0.9033076923076924 },
							mapParticipation: { percentActive: 0, score: 0 },
							boostParticipation: { percentActive: 0.781, score: 1 },
						},
					},
					studentGameScore: 8163,
					totalHealthTasks: 3,
					knowledge: { application: 0.625, questions: 0.125 },
					studentPoints: {
						DECKS: 207,
						COMMUNICATION: 162,
						TRANSPORTER: 82,
						THRUSTERS: 159,
						POWER: 70,
						DEFENSE: 171,
						TRACTOR_BEAM: 39,
						QUESTIONS: 226,
						SENSOR: 98,
					},
					questionAnswers: [
						{
							questionId: '5c10b024ca7c6fbfaaf501d3',
							questionVersion: 1,
							requestId: '175',
							score: { score: 0, perfectScore: 1 },
							answer: {
								type: variantTypes.MULTIPLE_CHOICE,
								value: '5c10b024ca7c6fbfaaf501d6',
							},
							timestamp: 1626875067637,
						},
						{
							questionId: '5c10b024ca7c6fbfaaf501d9',
							questionVersion: 1,
							requestId: '176',
							score: { score: 0, perfectScore: 1 },
							answer: {
								type: variantTypes.MULTIPLE_CHOICE,
								value: '5c10b024ca7c6fbfaaf501dd',
							},
							timestamp: 1626876162809,
						},
						{
							questionId: '5c10b024ca7c6fbfaaf501df',
							questionVersion: 1,
							requestId: '177',
							score: { score: 0, perfectScore: 1 },
							answer: {
								type: variantTypes.MULTIPLE_CHOICE,
								value: '5c10b024ca7c6fbfaaf501e1',
							},
							timestamp: 1626875392062,
						},
						{
							questionId: '5c10b024ca7c6fbfaaf501e5',
							questionVersion: 1,
							requestId: '178',
							score: { score: 0, perfectScore: 1 },
							answer: {
								type: variantTypes.MULTIPLE_CHOICE,
								value: '5c10b024ca7c6fbfaaf501ea',
							},
							timestamp: 1626875085040,
						},
						{
							questionId: '5c10b024ca7c6fbfaaf50210',
							questionVersion: 1,
							requestId: '179',
							score: { score: 1, perfectScore: 1 },
							answer: {
								type: variantTypes.MULTIPLE_CHOICE,
								value: '5c10b024ca7c6fbfaaf50212',
							},
							timestamp: 1626874097108,
						},
						{
							questionId: '5c10b024ca7c6fbfaaf50229',
							questionVersion: 1,
							requestId: '180',
							score: { score: 0, perfectScore: 1 },
							answer: {
								type: variantTypes.MULTIPLE_CHOICE,
								value: '5c10b024ca7c6fbfaaf5022c',
							},
							timestamp: 1626873703906,
						},
						{
							questionId: '5c10b024ca7c6fbfaaf50215',
							questionVersion: 1,
							requestId: '181',
							score: { score: 0, perfectScore: 1 },
							answer: {
								type: variantTypes.MULTIPLE_CHOICE,
								value: '5c10b024ca7c6fbfaaf50217',
							},
							timestamp: 1626877079013,
						},
						{
							questionId: '5c10b024ca7c6fbfaaf50243',
							questionVersion: 1,
							requestId: '182',
							score: { score: 0, perfectScore: 1 },
							answer: {
								type: variantTypes.MULTIPLE_CHOICE,
								value: '5c10b024ca7c6fbfaaf50246',
							},
							timestamp: 1626876687360,
						},
					],
				},
				s24: {
					skills: {
						criticalThinking: {
							endOfMissionSurvey: 0.6666666666666666,
							scannedData: 0,
						},
						collaboration: {
							endOfMissionSurvey: 0.6666666666666666,
							socialHealthScore: 1,
							engineerPanelScore: { time: 181182.8, score: 0.574 },
						},
					},
					disposition: {
						grit: {
							endOfMissionSurvey: 0.6666666666666666,
							gritAfterNegativeEvents: {
								APMDetails: [
									{
										APMInDuration: 4.4,
										eventType: 'DEATH',
										actionTime: new Date('2021-05-11T19:11:56.127Z'),
										score: 0.9933769251700683,
									},
									{
										APMInDuration: 6,
										eventType: 'QUESTION_ANSWERED',
										actionTime: new Date('2021-05-11T19:17:07.643Z'),
										score: 1.3546048979591838,
									},
									{
										APMInDuration: 11.5,
										eventType: 'QUESTION_ANSWERED',
										actionTime: new Date('2021-05-11T19:21:10.184Z'),
										score: 2,
									},
									{
										APMInDuration: 3.5,
										eventType: 'QUESTION_ANSWERED',
										actionTime: new Date('2021-05-11T19:30:39.031Z'),
										score: 0.7901861904761905,
									},
									{
										APMInDuration: 3.5,
										eventType: 'QUESTION_ANSWERED',
										actionTime: new Date('2021-05-11T19:37:18.961Z'),
										score: 0.7901861904761905,
									},
									{
										APMInDuration: 7,
										eventType: 'QUESTION_ANSWERED',
										actionTime: new Date('2021-05-11T19:40:56.245Z'),
										score: 1.580372380952381,
									},
									{
										APMInDuration: 3,
										eventType: 'QUESTION_ANSWERED',
										actionTime: new Date('2021-05-11T19:45:38.849Z'),
										score: 0.6773024489795919,
									},
								],
								averageAPM: 4.4293358225999775,
								score: 1.169432719144801,
							},
						},
						initiative: {
							endOfMissionSurvey: 0.3333333333333333,
							alertResponse: { time: 15557.166666666666, score: 1.2673333333333334 },
							scannedDataResponse: { time: 1435780.1538461538, score: 0.003 },
							stationResponse: { time: 13929.76923076923, score: 1.0207692307692307 },
							mapParticipation: { percentActive: 0.54, score: 0.72 },
							boostParticipation: { percentActive: 0.503, score: 0.671 },
						},
					},
					studentGameScore: 14169,
					totalHealthTasks: 4,
					knowledge: { application: 0.875, questions: 0.2222222222222222 },
					studentPoints: {
						DECKS: 25,
						COMMUNICATION: 18,
						TRANSPORTER: 60,
						THRUSTERS: 144,
						POWER: 57,
						DEFENSE: 90,
						TRACTOR_BEAM: 226,
						QUESTIONS: 25,
						SENSOR: 81,
					},
					questionAnswers: [
						{
							questionId: '5c10b024ca7c6fbfaaf501d3',
							questionVersion: 1,
							requestId: '183',
							score: { score: 0, perfectScore: 1 },
							answer: {
								type: variantTypes.MULTIPLE_CHOICE,
								value: '5c10b024ca7c6fbfaaf501d6',
							},
							timestamp: 1626876687957,
						},
						{
							questionId: '5c10b024ca7c6fbfaaf501d9',
							questionVersion: 1,
							requestId: '184',
							score: { score: 0, perfectScore: 1 },
							answer: {
								type: variantTypes.MULTIPLE_CHOICE,
								value: '5c10b024ca7c6fbfaaf501dd',
							},
							timestamp: 1626874186893,
						},
						{
							questionId: '5c10b024ca7c6fbfaaf501df',
							questionVersion: 1,
							requestId: '185',
							score: { score: 0, perfectScore: 1 },
							answer: {
								type: variantTypes.MULTIPLE_CHOICE,
								value: '5c10b024ca7c6fbfaaf501e3',
							},
							timestamp: 1626876508564,
						},
						{
							questionId: '5c10b024ca7c6fbfaaf501e5',
							questionVersion: 1,
							requestId: '186',
							score: { score: 0, perfectScore: 1 },
							answer: {
								type: variantTypes.MULTIPLE_CHOICE,
								value: '5c10b024ca7c6fbfaaf501e8',
							},
							timestamp: 1626874448314,
						},
						{
							questionId: '5c10b024ca7c6fbfaaf501f1',
							questionVersion: 1,
							requestId: '187',
							score: { score: 0, perfectScore: 1 },
							answer: {
								type: variantTypes.MULTIPLE_CHOICE,
								value: '5c10b024ca7c6fbfaaf501f6',
							},
							timestamp: 1626876520299,
						},
						{
							questionId: '5c10b024ca7c6fbfaaf50210',
							questionVersion: 1,
							requestId: '188',
							score: { score: 1, perfectScore: 1 },
							answer: {
								type: variantTypes.MULTIPLE_CHOICE,
								value: '5c10b024ca7c6fbfaaf50212',
							},
							timestamp: 1626876673070,
						},
						{
							questionId: '5c10b024ca7c6fbfaaf50215',
							questionVersion: 1,
							requestId: '189',
							score: { score: 0, perfectScore: 1 },
							answer: {
								type: variantTypes.MULTIPLE_CHOICE,
								value: '5c10b024ca7c6fbfaaf50218',
							},
							timestamp: 1626874187547,
						},
						{
							questionId: '5c10b024ca7c6fbfaaf50243',
							questionVersion: 1,
							requestId: '190',
							score: { score: 1, perfectScore: 1 },
							answer: {
								type: variantTypes.MULTIPLE_CHOICE,
								value: '5c10b024ca7c6fbfaaf50245',
							},
							timestamp: 1626875694596,
						},
						{
							questionId: '5c10b024ca7c6fbfaaf5025d',
							questionVersion: 1,
							requestId: '191',
							score: { score: 0, perfectScore: 1 },
							answer: {
								type: variantTypes.MULTIPLE_CHOICE,
								value: '5c10b024ca7c6fbfaaf50260',
							},
							timestamp: 1626874843077,
						},
					],
				},
				s25: {
					skills: {
						criticalThinking: { endOfMissionSurvey: 1, scannedData: 0 },
						collaboration: {
							endOfMissionSurvey: 1,
							socialHealthScore: 1,
							engineerPanelScore: {
								time: 60209.666666666664,
								score: 0.9588333333333333,
							},
						},
					},
					disposition: {
						grit: {
							endOfMissionSurvey: 1,
							gritAfterNegativeEvents: {
								APMDetails: [
									{
										APMInDuration: 3.8,
										eventType: 'DEATH',
										actionTime: new Date('2021-05-11T19:11:56.127Z'),
										score: 1.1761092509363296,
									},
								],
								averageAPM: 3.230992356343364,
								score: 1.1761092509363296,
							},
						},
						initiative: {
							endOfMissionSurvey: 1,
							alertResponse: { time: 63099, score: 0.9845 },
							scannedDataResponse: { time: 1516571.7692307692, score: 0 },
							stationResponse: {
								time: 14692.846153846154,
								score: 0.9993076923076925,
							},
							mapParticipation: { percentActive: 0.41, score: 0.547 },
							boostParticipation: { percentActive: 0.756, score: 1 },
						},
					},
					studentGameScore: 14350,
					totalHealthTasks: 4,
					knowledge: { application: 1, questions: 0.125 },
					studentPoints: {
						DECKS: 232,
						COMMUNICATION: 145,
						TRANSPORTER: 169,
						THRUSTERS: 112,
						POWER: 56,
						DEFENSE: 131,
						TRACTOR_BEAM: 169,
						QUESTIONS: 151,
						SENSOR: 53,
					},
					questionAnswers: [
						{
							questionId: '5c10b024ca7c6fbfaaf501d3',
							questionVersion: 1,
							requestId: '192',
							score: { score: 0, perfectScore: 1 },
							answer: {
								type: variantTypes.MULTIPLE_CHOICE,
								value: '5c10b024ca7c6fbfaaf501d5',
							},
							timestamp: 1626876646541,
						},
						{
							questionId: '5c10b024ca7c6fbfaaf501e5',
							questionVersion: 1,
							requestId: '193',
							score: { score: 0, perfectScore: 1 },
							answer: {
								type: variantTypes.MULTIPLE_CHOICE,
								value: '5c10b024ca7c6fbfaaf501ea',
							},
							timestamp: 1626874724920,
						},
						{
							questionId: '5c10b024ca7c6fbfaaf501f1',
							questionVersion: 1,
							requestId: '194',
							score: { score: 0, perfectScore: 1 },
							answer: {
								type: variantTypes.MULTIPLE_CHOICE,
								value: '5c10b024ca7c6fbfaaf501f5',
							},
							timestamp: 1626873738226,
						},
						{
							questionId: '5c10b024ca7c6fbfaaf50210',
							questionVersion: 1,
							requestId: '195',
							score: { score: 0, perfectScore: 1 },
							answer: {
								type: variantTypes.MULTIPLE_CHOICE,
								value: '5c10b024ca7c6fbfaaf50214',
							},
							timestamp: 1626876185492,
						},
						{
							questionId: '5c10b024ca7c6fbfaaf50229',
							questionVersion: 1,
							requestId: '196',
							score: { score: 0, perfectScore: 1 },
							answer: {
								type: variantTypes.MULTIPLE_CHOICE,
								value: '5c10b024ca7c6fbfaaf5022e',
							},
							timestamp: 1626874431880,
						},
						{
							questionId: '5c10b024ca7c6fbfaaf50215',
							questionVersion: 1,
							requestId: '197',
							score: { score: 0, perfectScore: 1 },
							answer: {
								type: variantTypes.MULTIPLE_CHOICE,
								value: '5c10b024ca7c6fbfaaf50218',
							},
							timestamp: 1626874767041,
						},
						{
							questionId: '5c10b024ca7c6fbfaaf50243',
							questionVersion: 1,
							requestId: '198',
							score: { score: 0, perfectScore: 1 },
							answer: {
								type: variantTypes.MULTIPLE_CHOICE,
								value: '5c10b024ca7c6fbfaaf50247',
							},
							timestamp: 1626876873697,
						},
						{
							questionId: '5c10b024ca7c6fbfaaf5025d',
							questionVersion: 1,
							requestId: '199',
							score: { score: 1, perfectScore: 1 },
							answer: {
								type: variantTypes.MULTIPLE_CHOICE,
								value: '5c10b024ca7c6fbfaaf5025f',
							},
							timestamp: 1626874579415,
						},
					],
				},
				s26: {
					skills: {
						criticalThinking: { endOfMissionSurvey: 1, scannedData: 0 },
						collaboration: {
							endOfMissionSurvey: 1,
							socialHealthScore: 1,
							engineerPanelScore: { time: 2459225, score: 0 },
						},
					},
					disposition: {
						grit: {
							endOfMissionSurvey: 1,
							gritAfterNegativeEvents: {
								APMDetails: [
									{
										APMInDuration: 3.4,
										eventType: 'DEATH',
										actionTime: new Date('2021-05-11T19:11:56.127Z'),
										score: 0.9529162074829932,
									},
									{
										APMInDuration: 6.5,
										eventType: 'QUESTION_ANSWERED',
										actionTime: new Date('2021-05-11T19:17:05.834Z'),
										score: 1.8217515731292517,
									},
									{
										APMInDuration: 6,
										eventType: 'QUESTION_ANSWERED',
										actionTime: new Date('2021-05-11T19:17:18.632Z'),
										score: 1.681616836734694,
									},
									{
										APMInDuration: 5,
										eventType: 'QUESTION_ANSWERED',
										actionTime: new Date('2021-05-11T19:23:01.043Z'),
										score: 1.4013473639455782,
									},
								],
								averageAPM: 3.567994723251341,
								score: 1.4644079953231293,
							},
						},
						initiative: {
							endOfMissionSurvey: 1,
							alertResponse: { time: 45193, score: 1.0136666666666667 },
							scannedDataResponse: { time: 1516571.7692307692, score: 0 },
							stationResponse: {
								time: 11025.333333333334,
								score: 1.0354166666666667,
							},
							mapParticipation: { percentActive: 0.46, score: 0.613 },
							boostParticipation: { percentActive: 0.728, score: 0.971 },
						},
					},
					studentGameScore: 15742,
					totalHealthTasks: 2,
					knowledge: { application: 0.75, questions: 0.7 },
					studentPoints: {
						DECKS: 36,
						COMMUNICATION: 61,
						TRANSPORTER: 187,
						THRUSTERS: 152,
						POWER: 63,
						DEFENSE: 17,
						TRACTOR_BEAM: 161,
						QUESTIONS: 58,
						SENSOR: 116,
					},
				},
				s27: {
					skills: {
						criticalThinking: { endOfMissionSurvey: 1, scannedData: 0 },
						collaboration: {
							endOfMissionSurvey: 1,
							socialHealthScore: 1,
							engineerPanelScore: { time: 121061, score: 0.6942 },
						},
					},
					disposition: {
						grit: {
							endOfMissionSurvey: 1,
							gritAfterNegativeEvents: {
								APMDetails: [
									{
										APMInDuration: 4,
										eventType: 'DEATH',
										actionTime: new Date('2021-05-11T19:11:56.127Z'),
										score: 1.6691038167938932,
									},
								],
								averageAPM: 2.3964956282248644,
								score: 1.6691038167938932,
							},
						},
						initiative: {
							endOfMissionSurvey: 1,
							alertResponse: { time: 47509.833333333336, score: 0.9853333333333333 },
							scannedDataResponse: { time: 1516571.7692307692, score: 0 },
							stationResponse: {
								time: 73788.69230769231,
								score: 0.43515384615384617,
							},
							mapParticipation: { percentActive: 0.02, score: 0.027 },
							boostParticipation: { percentActive: 0.711, score: 0.948 },
						},
					},
					studentGameScore: 9669,
					totalHealthTasks: 2,
					knowledge: { application: 0.9375, questions: 0.2 },
					studentPoints: {
						DECKS: 253,
						COMMUNICATION: 239,
						TRANSPORTER: 138,
						THRUSTERS: 142,
						POWER: 206,
						DEFENSE: 81,
						TRACTOR_BEAM: 20,
						QUESTIONS: 58,
						SENSOR: 119,
					},
					questionAnswers: [
						{
							questionId: '5c10b024ca7c6fbfaaf501d3',
							questionVersion: 1,
							requestId: '200',
							score: { score: 0, perfectScore: 1 },
							answer: {
								type: variantTypes.MULTIPLE_CHOICE,
								value: '5c10b024ca7c6fbfaaf501d7',
							},
							timestamp: 1626873818508,
						},
						{
							questionId: '5c10b024ca7c6fbfaaf501d9',
							questionVersion: 1,
							requestId: '201',
							score: { score: 1, perfectScore: 1 },
							answer: {
								type: variantTypes.MULTIPLE_CHOICE,
								value: '5c10b024ca7c6fbfaaf501db',
							},
							timestamp: 1626875245600,
						},
						{
							questionId: '5c10b024ca7c6fbfaaf501df',
							questionVersion: 1,
							requestId: '202',
							score: { score: 0, perfectScore: 1 },
							answer: {
								type: variantTypes.MULTIPLE_CHOICE,
								value: '5c10b024ca7c6fbfaaf501e3',
							},
							timestamp: 1626876510624,
						},
						{
							questionId: '5c10b024ca7c6fbfaaf50229',
							questionVersion: 1,
							requestId: '203',
							score: { score: 0, perfectScore: 1 },
							answer: {
								type: variantTypes.MULTIPLE_CHOICE,
								value: '5c10b024ca7c6fbfaaf5022d',
							},
							timestamp: 1626874022850,
						},
						{
							questionId: '5c10b024ca7c6fbfaaf50243',
							questionVersion: 1,
							requestId: '204',
							score: { score: 0, perfectScore: 1 },
							answer: {
								type: variantTypes.MULTIPLE_CHOICE,
								value: '5c10b024ca7c6fbfaaf50246',
							},
							timestamp: 1626874111502,
						},
					],
				},
				s28: {
					skills: {
						criticalThinking: { endOfMissionSurvey: 1, scannedData: 0 },
						collaboration: {
							endOfMissionSurvey: 0.6666666666666666,
							socialHealthScore: 0.8,
							engineerPanelScore: { time: 209492.4, score: 0.5067999999999999 },
						},
					},
					disposition: {
						grit: {
							endOfMissionSurvey: 1,
							gritAfterNegativeEvents: {
								APMDetails: [
									{
										APMInDuration: 3.6,
										eventType: 'DEATH',
										actionTime: new Date('2021-05-11T19:11:56.127Z'),
										score: 1.1382802285714286,
									},
									{
										APMInDuration: 5,
										eventType: 'QUESTION_ANSWERED',
										actionTime: new Date('2021-05-11T19:18:14.467Z'),
										score: 1.580944761904762,
									},
									{
										APMInDuration: 8,
										eventType: 'QUESTION_ANSWERED',
										actionTime: new Date('2021-05-11T19:23:55.050Z'),
										score: 2,
									},
									{
										APMInDuration: 6,
										eventType: 'QUESTION_ANSWERED',
										actionTime: new Date('2021-05-11T19:24:11.755Z'),
										score: 1.8971337142857143,
									},
								],
								averageAPM: 3.162665844172743,
								score: 1.6540896761904762,
							},
						},
						initiative: {
							endOfMissionSurvey: 1,
							alertResponse: { time: 30264.833333333332, score: 1.1655 },
							scannedDataResponse: { time: 1516571.7692307692, score: 0 },
							stationResponse: {
								time: 18978.076923076922,
								score: 0.9775384615384616,
							},
							mapParticipation: { percentActive: 0.1, score: 0.133 },
							boostParticipation: { percentActive: 0.878, score: 1 },
						},
					},
					studentGameScore: 12293,
					totalHealthTasks: 4,
					knowledge: { application: 1, questions: 0.3333333333333333 },
					studentPoints: {
						DECKS: 76,
						COMMUNICATION: 150,
						TRANSPORTER: 212,
						THRUSTERS: 167,
						POWER: 116,
						DEFENSE: 195,
						TRACTOR_BEAM: 143,
						QUESTIONS: 198,
						SENSOR: 53,
					},
					questionAnswers: [
						{
							questionId: '5c10b024ca7c6fbfaaf501d9',
							questionVersion: 1,
							requestId: '205',
							score: { score: 0, perfectScore: 1 },
							answer: {
								type: variantTypes.MULTIPLE_CHOICE,
								value: '5c10b024ca7c6fbfaaf501de',
							},
							timestamp: 1626874738388,
						},
						{
							questionId: '5c10b024ca7c6fbfaaf501df',
							questionVersion: 1,
							requestId: '206',
							score: { score: 0, perfectScore: 1 },
							answer: {
								type: variantTypes.MULTIPLE_CHOICE,
								value: '5c10b024ca7c6fbfaaf501e4',
							},
							timestamp: 1626874834985,
						},
						{
							questionId: '5c10b024ca7c6fbfaaf501e5',
							questionVersion: 1,
							requestId: '207',
							score: { score: 0, perfectScore: 1 },
							answer: {
								type: variantTypes.MULTIPLE_CHOICE,
								value: '5c10b024ca7c6fbfaaf501ea',
							},
							timestamp: 1626875383967,
						},
						{
							questionId: '5c10b024ca7c6fbfaaf501f1',
							questionVersion: 1,
							requestId: '208',
							score: { score: 1, perfectScore: 1 },
							answer: {
								type: variantTypes.MULTIPLE_CHOICE,
								value: '5c10b024ca7c6fbfaaf501f3',
							},
							timestamp: 1626875359890,
						},
						{
							questionId: '5c10b024ca7c6fbfaaf50210',
							questionVersion: 1,
							requestId: '209',
							score: { score: 1, perfectScore: 1 },
							answer: {
								type: variantTypes.MULTIPLE_CHOICE,
								value: '5c10b024ca7c6fbfaaf50212',
							},
							timestamp: 1626876714007,
						},
						{
							questionId: '5c10b024ca7c6fbfaaf50229',
							questionVersion: 1,
							requestId: '210',
							score: { score: 0, perfectScore: 1 },
							answer: {
								type: variantTypes.MULTIPLE_CHOICE,
								value: '5c10b024ca7c6fbfaaf5022c',
							},
							timestamp: 1626874640527,
						},
						{
							questionId: '5c10b024ca7c6fbfaaf50215',
							questionVersion: 1,
							requestId: '211',
							score: { score: 1, perfectScore: 1 },
							answer: {
								type: variantTypes.MULTIPLE_CHOICE,
								value: '5c10b024ca7c6fbfaaf50219',
							},
							timestamp: 1626876360203,
						},
						{
							questionId: '5c10b024ca7c6fbfaaf50243',
							questionVersion: 1,
							requestId: '212',
							score: { score: 0, perfectScore: 1 },
							answer: {
								type: variantTypes.MULTIPLE_CHOICE,
								value: '5c10b024ca7c6fbfaaf50247',
							},
							timestamp: 1626873875721,
						},
						{
							questionId: '5c10b024ca7c6fbfaaf5025d',
							questionVersion: 1,
							requestId: '213',
							score: { score: 0, perfectScore: 1 },
							answer: {
								type: variantTypes.MULTIPLE_CHOICE,
								value: '5c10b024ca7c6fbfaaf50261',
							},
							timestamp: 1626875720404,
						},
					],
				},
				s29: {
					skills: {
						criticalThinking: { endOfMissionSurvey: 1, scannedData: 0 },
						collaboration: {
							endOfMissionSurvey: 1,
							socialHealthScore: 1,
							engineerPanelScore: { time: 80321.2, score: 0.8577999999999999 },
						},
					},
					disposition: {
						grit: {
							endOfMissionSurvey: 1,
							gritAfterNegativeEvents: {
								APMDetails: [
									{
										APMInDuration: 3.8,
										eventType: 'DEATH',
										actionTime: new Date('2021-05-11T19:11:56.127Z'),
										score: 1.1894479472693031,
									},
								],
								averageAPM: 3.1947593912990637,
								score: 1.1894479472693031,
							},
						},
						initiative: {
							endOfMissionSurvey: 1,
							alertResponse: { time: 25468.166666666668, score: 1.1945000000000001 },
							scannedDataResponse: {
								time: 1331445.8461538462,
								score: 0.038461538461538464,
							},
							stationResponse: { time: 15027, score: 0.9974615384615385 },
							mapParticipation: { percentActive: 0.47, score: 0.627 },
							boostParticipation: { percentActive: 0.905, score: 1.01 },
						},
					},
					studentGameScore: 18333,
					totalHealthTasks: 2,
					knowledge: { application: 1, questions: 0.25 },
					studentPoints: {
						DECKS: 139,
						COMMUNICATION: 246,
						TRANSPORTER: 178,
						THRUSTERS: 228,
						POWER: 172,
						DEFENSE: 158,
						TRACTOR_BEAM: 56,
						QUESTIONS: 177,
						SENSOR: 205,
					},
					questionAnswers: [
						{
							questionId: '5c10b024ca7c6fbfaaf501d3',
							questionVersion: 1,
							requestId: '214',
							score: { score: 0, perfectScore: 1 },
							answer: {
								type: variantTypes.MULTIPLE_CHOICE,
								value: '5c10b024ca7c6fbfaaf501d7',
							},
							timestamp: 1626874341073,
						},
						{
							questionId: '5c10b024ca7c6fbfaaf501df',
							questionVersion: 1,
							requestId: '215',
							score: { score: 0, perfectScore: 1 },
							answer: {
								type: variantTypes.MULTIPLE_CHOICE,
								value: '5c10b024ca7c6fbfaaf501e3',
							},
							timestamp: 1626875902087,
						},
						{
							questionId: '5c10b024ca7c6fbfaaf501e5',
							questionVersion: 1,
							requestId: '216',
							score: { score: 0, perfectScore: 1 },
							answer: {
								type: variantTypes.MULTIPLE_CHOICE,
								value: '5c10b024ca7c6fbfaaf501ea',
							},
							timestamp: 1626874798565,
						},
						{
							questionId: '5c10b024ca7c6fbfaaf501f1',
							questionVersion: 1,
							requestId: '217',
							score: { score: 0, perfectScore: 1 },
							answer: {
								type: variantTypes.MULTIPLE_CHOICE,
								value: '5c10b024ca7c6fbfaaf501f6',
							},
							timestamp: 1626875352313,
						},
						{
							questionId: '5c10b024ca7c6fbfaaf50210',
							questionVersion: 1,
							requestId: '218',
							score: { score: 0, perfectScore: 1 },
							answer: {
								type: variantTypes.MULTIPLE_CHOICE,
								value: '5c10b024ca7c6fbfaaf50214',
							},
							timestamp: 1626876137869,
						},
						{
							questionId: '5c10b024ca7c6fbfaaf50215',
							questionVersion: 1,
							requestId: '219',
							score: { score: 1, perfectScore: 1 },
							answer: {
								type: variantTypes.MULTIPLE_CHOICE,
								value: '5c10b024ca7c6fbfaaf50219',
							},
							timestamp: 1626876389051,
						},
						{
							questionId: '5c10b024ca7c6fbfaaf50243',
							questionVersion: 1,
							requestId: '220',
							score: { score: 1, perfectScore: 1 },
							answer: {
								type: variantTypes.MULTIPLE_CHOICE,
								value: '5c10b024ca7c6fbfaaf50245',
							},
							timestamp: 1626874621042,
						},
						{
							questionId: '5c10b024ca7c6fbfaaf5025d',
							questionVersion: 1,
							requestId: '221',
							score: { score: 0, perfectScore: 1 },
							answer: {
								type: variantTypes.MULTIPLE_CHOICE,
								value: '5c10b024ca7c6fbfaaf50260',
							},
							timestamp: 1626875648695,
						},
					],
				},
				s30: {
					skills: {
						criticalThinking: { endOfMissionSurvey: 1, scannedData: 1 },
						collaboration: {
							endOfMissionSurvey: 1,
							socialHealthScore: 1,
							engineerPanelScore: { time: 155872.83333333334, score: 0.622 },
						},
					},
					disposition: {
						grit: {
							endOfMissionSurvey: 1,
							gritAfterNegativeEvents: {
								APMDetails: [
									{
										APMInDuration: 3,
										eventType: 'DEATH',
										actionTime: new Date('2021-05-11T19:11:56.127Z'),
										score: 0.516238870967742,
									},
									{
										APMInDuration: 13.5,
										eventType: 'QUESTION_ANSWERED',
										actionTime: new Date('2021-05-11T19:34:39.139Z'),
										score: 2,
									},
									{
										APMInDuration: 3.5,
										eventType: 'QUESTION_ANSWERED',
										actionTime: new Date('2021-05-11T19:40:58.834Z'),
										score: 0.602278682795699,
									},
									{
										APMInDuration: 4,
										eventType: 'QUESTION_ANSWERED',
										actionTime: new Date('2021-05-11T19:51:15.752Z'),
										score: 0.6883184946236559,
									},
								],
								averageAPM: 5.811263290530984,
								score: 0.9517090120967743,
							},
						},
						initiative: {
							endOfMissionSurvey: 1,
							alertResponse: { time: 159505.66666666666, score: 0.5861666666666667 },
							scannedDataResponse: {
								time: 339663.8076923077,
								score: 0.5570384615384616,
							},
							stationResponse: { time: 58860.07692307692, score: 0.5876153846153845 },
							mapParticipation: { percentActive: 0.01, score: 0.013 },
							boostParticipation: { percentActive: 0.126, score: 0.168 },
						},
					},
					studentGameScore: 8019,
					totalHealthTasks: 4,
					knowledge: { application: 0.875, questions: 0.25 },
					studentPoints: {
						DECKS: 12,
						COMMUNICATION: 64,
						TRANSPORTER: 91,
						THRUSTERS: 122,
						POWER: 100,
						DEFENSE: 165,
						TRACTOR_BEAM: 109,
						QUESTIONS: 221,
						SENSOR: 239,
					},
					questionAnswers: [
						{
							questionId: '5c10b024ca7c6fbfaaf501d9',
							questionVersion: 1,
							requestId: '222',
							score: { score: 0, perfectScore: 1 },
							answer: {
								type: variantTypes.MULTIPLE_CHOICE,
								value: '5c10b024ca7c6fbfaaf501de',
							},
							timestamp: 1626874442277,
						},
						{
							questionId: '5c10b024ca7c6fbfaaf501df',
							questionVersion: 1,
							requestId: '223',
							score: { score: 0, perfectScore: 1 },
							answer: {
								type: variantTypes.MULTIPLE_CHOICE,
								value: '5c10b024ca7c6fbfaaf501e1',
							},
							timestamp: 1626875166009,
						},
						{
							questionId: '5c10b024ca7c6fbfaaf501e5',
							questionVersion: 1,
							requestId: '224',
							score: { score: 0, perfectScore: 1 },
							answer: {
								type: variantTypes.MULTIPLE_CHOICE,
								value: '5c10b024ca7c6fbfaaf501e8',
							},
							timestamp: 1626875381450,
						},
						{
							questionId: '5c10b024ca7c6fbfaaf50210',
							questionVersion: 1,
							requestId: '225',
							score: { score: 1, perfectScore: 1 },
							answer: {
								type: variantTypes.MULTIPLE_CHOICE,
								value: '5c10b024ca7c6fbfaaf50212',
							},
							timestamp: 1626875722149,
						},
						{
							questionId: '5c10b024ca7c6fbfaaf50229',
							questionVersion: 1,
							requestId: '226',
							score: { score: 0, perfectScore: 1 },
							answer: {
								type: variantTypes.MULTIPLE_CHOICE,
								value: '5c10b024ca7c6fbfaaf5022c',
							},
							timestamp: 1626874999969,
						},
						{
							questionId: '5c10b024ca7c6fbfaaf50215',
							questionVersion: 1,
							requestId: '227',
							score: { score: 1, perfectScore: 1 },
							answer: {
								type: variantTypes.MULTIPLE_CHOICE,
								value: '5c10b024ca7c6fbfaaf50219',
							},
							timestamp: 1626875980087,
						},
						{
							questionId: '5c10b024ca7c6fbfaaf50243',
							questionVersion: 1,
							requestId: '228',
							score: { score: 0, perfectScore: 1 },
							answer: {
								type: variantTypes.MULTIPLE_CHOICE,
								value: '5c10b024ca7c6fbfaaf50247',
							},
							timestamp: 1626874143358,
						},
						{
							questionId: '5c10b024ca7c6fbfaaf5025d',
							questionVersion: 1,
							requestId: '229',
							score: { score: 0, perfectScore: 1 },
							answer: {
								type: variantTypes.MULTIPLE_CHOICE,
								value: '5c10b024ca7c6fbfaaf50261',
							},
							timestamp: 1626874265529,
						},
					],
				},
			},
			weights: {
				collaboration: {
					endOfMissionSurvey: 1,
					socialHealthScore: 1,
					engineerPanelScore: 1,
				},
				criticalThinking: { endOfMissionSurvey: 1, scannedData: 1 },
				initiative: {
					endOfMissionSurvey: 1,
					alertResponse: 1,
					scannedDataResponse: 1,
					stationResponse: 1,
					mapParticipation: 1,
					boostParticipation: 1,
				},
				grit: { endOfMissionSurvey: 1, gritAfterNegativeEvents: 1 },
			},
			missionLength: 3114495,
			activeStudents: 32,
			shipDeaths: 1,
			totalCorrectCulminatingMoments: 4,
			extraCrewPoints: { GENERAL: 7750 },
			commendations: {},
			MVPs: ['mvp1', 'mvp2', 'mvp3'],
			usedHackerMode: false,
		},
		classId: 'demo-analytics-class-id',
		className: 'Second Hour',
		createdTime: String(previousThursdayAtTen),
		grades: ['4'],
		missionId: 'demo-analytics-mission-id',
		schoolName: 'Demo School',
		simulationId: '600afcbd9f8e00001b9eb1f5',
		teacherId: TEACHER_ID,
		version: 3,
	}
}

const demoQuestions: DBQuestion[] = [
	{
		_id: '5c10b024ca7c6fbfaaf501d3',
		phrase: 'Molecules are composed of',
		type: 'MULTIPLE_CHOICE',
		default: true,
		createdFor: '5afb076c5a109679238615cc',
		version: 1,
		choices: [
			{ _id: '5c10b024ca7c6fbfaaf501d8', text: 'atoms', answer: true },
			{ _id: '5c10b024ca7c6fbfaaf501d7', text: 'electron clouds', answer: false },
			{
				_id: '5c10b024ca7c6fbfaaf501d6',
				text: 'carbon, hydrogen, oxygen, and nitrogen',
				answer: false,
			},
			{ _id: '5c10b024ca7c6fbfaaf501d5', text: 'proteins', answer: false },
		],
	},
	{
		_id: '5c10b024ca7c6fbfaaf501d9',
		phrase: 'The DNA Molecule is represented by',
		type: 'MULTIPLE_CHOICE',
		default: true,
		createdFor: '5afb076c5a109679238615cc',
		version: 1,
		choices: [
			{ _id: '5c10b024ca7c6fbfaaf501de', text: 'a ball and stick model', answer: false },
			{ _id: '5c10b024ca7c6fbfaaf501dd', text: 'a space filling model', answer: false },
			{ _id: '5c10b024ca7c6fbfaaf501dc', text: 'a line and angle model', answer: false },
			{ _id: '5c10b024ca7c6fbfaaf501db', text: 'a ribbon model', answer: true },
		],
	},
	{
		_id: '5c10b024ca7c6fbfaaf501df',
		phrase: "DNA is housed in a cell's ",
		type: 'MULTIPLE_CHOICE',
		default: true,
		createdFor: '5afb076c5a109679238615cc',
		version: 1,
		choices: [
			{ _id: '5c10b024ca7c6fbfaaf501e4', text: 'ribosomes', answer: false },
			{ _id: '5c10b024ca7c6fbfaaf501e3', text: 'organelles', answer: false },
			{ _id: '5c10b024ca7c6fbfaaf501e2', text: 'nucleus', answer: true },
			{ _id: '5c10b024ca7c6fbfaaf501e1', text: 'mitochondria', answer: false },
		],
	},
	{
		_id: '5c10b024ca7c6fbfaaf501e5',
		phrase: 'DNA is essentially a code that is made up of chemical bases.  Those bases are:',
		type: 'MULTIPLE_CHOICE',
		default: true,
		createdFor: '5afb076c5a109679238615cc',
		version: 1,
		choices: [
			{ _id: '5c10b024ca7c6fbfaaf501ea', text: 'sugar and phosphate', answer: false },
			{
				_id: '5c10b024ca7c6fbfaaf501e9',
				text: 'adenine, guanine, cytosine, and thymine',
				answer: true,
			},
			{
				_id: '5c10b024ca7c6fbfaaf501e8',
				text: 'adenine, guanine, cytosine, and uracil',
				answer: false,
			},
			{ _id: '5c10b024ca7c6fbfaaf501e7', text: ' nucleic acids', answer: false },
		],
	},
	{
		_id: '5c10b024ca7c6fbfaaf501f1',
		phrase:
			'A DNA molecule consists of two nucleotide chains, running in____________ direction and coiled into a ________ helix',
		type: 'MULTIPLE_CHOICE',
		default: true,
		createdFor: '5afb076c5a109679238615cc',
		version: 1,
		choices: [
			{ _id: '5c10b024ca7c6fbfaaf501f6', text: 'the same, double', answer: false },
			{ _id: '5c10b024ca7c6fbfaaf501f5', text: 'opposite, single', answer: false },
			{ _id: '5c10b024ca7c6fbfaaf501f4', text: 'parallel, double', answer: false },
			{ _id: '5c10b024ca7c6fbfaaf501f3', text: 'opposite, double', answer: true },
		],
	},
	{
		_id: '5c10b024ca7c6fbfaaf50210',
		phrase: 'What is the color of the earth as seen from space?',
		type: 'MULTIPLE_CHOICE',
		default: true,
		createdFor: '5afb076d5a1096792386235b',
		version: 1,
		choices: [
			{ _id: '5c10b024ca7c6fbfaaf50214', text: 'White and black', answer: false },
			{ _id: '5c10b024ca7c6fbfaaf50213', text: 'White and pink', answer: false },
			{ _id: '5c10b024ca7c6fbfaaf50212', text: 'White and blue', answer: true },
		],
	},
	{
		_id: '5c10b024ca7c6fbfaaf50229',
		phrase: 'When you gaze at the moon in the night sky, you might see all of these except for',
		type: 'MULTIPLE_CHOICE',
		default: true,
		createdFor: '5afb076d5a1096792386235b',
		version: 1,
		choices: [
			{ _id: '5c10b024ca7c6fbfaaf5022e', text: 'less than half of the moon', answer: false },
			{ _id: '5c10b024ca7c6fbfaaf5022d', text: 'half of the moon', answer: false },
			{ _id: '5c10b024ca7c6fbfaaf5022c', text: 'a full moon', answer: false },
			{ _id: '5c10b024ca7c6fbfaaf5022b', text: 'more than a full moon', answer: true },
		],
	},
	{
		_id: '5c10b024ca7c6fbfaaf50215',
		phrase: 'When we say that the earth rotates on its axis, this means that the earth',
		type: 'MULTIPLE_CHOICE',
		default: true,
		createdFor: '5afb076d5a1096792386235b',
		version: 1,
		choices: [
			{ _id: '5c10b024ca7c6fbfaaf50219', text: 'spins', answer: true },
			{ _id: '5c10b024ca7c6fbfaaf50218', text: 'moves back and forth', answer: false },
			{ _id: '5c10b024ca7c6fbfaaf50217', text: 'moves up and down', answer: false },
		],
	},
	{
		_id: '5c10b024ca7c6fbfaaf50243',
		phrase: 'Which of these does not happen every month',
		type: 'MULTIPLE_CHOICE',
		default: true,
		createdFor: '5afb076d5a1096792386235b',
		version: 1,
		choices: [
			{ _id: '5c10b024ca7c6fbfaaf50247', text: 'half moon', answer: false },
			{ _id: '5c10b024ca7c6fbfaaf50246', text: 'full moon', answer: false },
			{ _id: '5c10b024ca7c6fbfaaf50245', text: 'eclipse', answer: true },
		],
	},
	{
		_id: '5c10b024ca7c6fbfaaf5025d',
		phrase: 'What statement best describes the earth, the moon, and the sun?',
		type: 'MULTIPLE_CHOICE',
		default: true,
		createdFor: '5afb076d5a1096792386235b',
		version: 1,
		choices: [
			{
				_id: '5c10b024ca7c6fbfaaf50262',
				text: 'The moon travels around the earth and the earth travels around the sun',
				answer: false,
			},
			{
				_id: '5c10b024ca7c6fbfaaf50261',
				text: 'The moon is smaller than the earth and the earth is smaller than the sun',
				answer: false,
			},
			{
				_id: '5c10b024ca7c6fbfaaf50260',
				text:
					'The earth is kept in its orbit by the gravity of the sun and the moon is kept in its orbit by the gravity of earth',
				answer: false,
			},
			{
				_id: '5c10b024ca7c6fbfaaf5025f',
				text: 'All of these statements are correct',
				answer: true,
			},
		],
	},
]

const demoIdToNameMap: { [string]: { firstName: string, lastName: string } } = {
	[TEACHER_ID]: { firstName: 'Mrs.', lastName: 'Johnson' },
	mvp1: { firstName: 'Jimmie', lastName: 'Santos' },
	s2: { firstName: 'Laverne', lastName: 'Fields' },
	s3: { firstName: 'Josephine', lastName: 'Rhodes' },
	s4: { firstName: 'Timmy', lastName: 'Quinn' },
	s5: { firstName: 'Jana', lastName: 'Figueroa' },
	mvp2: { firstName: 'Wanda', lastName: 'Jimenez' },
	s6: { firstName: 'Milton', lastName: 'Malone' },
	s7: { firstName: 'Maurice', lastName: 'Vargas' },
	s8: { firstName: 'Sadie', lastName: 'Holmes' },
	s9: { firstName: 'Nettie', lastName: 'Becker' },
	s10: { firstName: 'Ashley', lastName: 'Greer' },
	s11: { firstName: 'Francis', lastName: 'Nunez' },
	s12: { firstName: 'Ernestine', lastName: 'Boyd' },
	s13: { firstName: 'Mable', lastName: 'Morris' },
	s14: { firstName: 'Maggie', lastName: 'Peterson' },
	s15: { firstName: 'Antoinette', lastName: 'Marsh' },
	s16: { firstName: 'Ruben', lastName: 'Lynch' },
	s17: { firstName: 'Gladys', lastName: 'Delgado' },
	s18: { firstName: 'Anna', lastName: 'Harrison' },
	mvp3: { firstName: 'Bernard', lastName: 'Parker' },
	s19: { firstName: 'Jermaine', lastName: 'Burgess' },
	s20: { firstName: 'Jackie', lastName: 'Santiago' },
	s21: { firstName: 'Rogelio', lastName: 'Ford' },
	s22: { firstName: 'Kay', lastName: 'Lamb' },
	s23: { firstName: 'Brandy', lastName: 'Knight' },
	s24: { firstName: 'Muriel', lastName: 'Stanley' },
	s25: { firstName: 'Simon', lastName: 'Soto' },
	s26: { firstName: 'Ervin', lastName: 'Gray' },
	s27: { firstName: 'Adam', lastName: 'Hogan' },
	s28: { firstName: 'Hope', lastName: 'Reid' },
	s29: { firstName: 'Erma', lastName: 'Cummings' },
	s30: { firstName: 'Ernesto', lastName: 'Munoz' },
}

/**
 * Gets a set of demo analytics objects, along with a map of student and teacher ids to their names
 */
export function getAllDemoAnalyticsObjects(): {
	demoAnalytics: AnalyticsObject[],
	demoIdToNameMap: { [string]: { firstName: string, lastName: string } },
	demoQuestionMap: { [questionId: string]: DBQuestion },
} {
	const demoAnalytics = [getDemoAnalyticsObject()]

	const demoQuestionMap = {}
	demoQuestions.forEach((question: DBQuestion) => {
		demoQuestionMap[getQuestionKey(question._id, question.version)] = question
	})

	return { demoAnalytics, demoIdToNameMap, demoQuestionMap }
}

/**
 * A hook that returns whether the current user can use demo data.
 */
export function useCanUseDemo(): boolean {
	const { user } = useUser()
	if (!config.isProd) {
		return true
	}

	if (!user) {
		return false
	}

	const emailsThatCanUseDemos = [
		'danny@infinidlearning.com',
		'dannyharding10@gmail.com',
		'alex@infinidlearning.com',
		'orsi@infinidlearning.com',
		'jason@infinidlearning.com',
		'skyler@infinidlearning.com',
		'brooks@infinidlearning.com',
		'casey@infinidlearning.com',
		'danny@mission.io',
		'alex@mission.io',
		'orsi@mission.io',
		'skyler@mission.io',
		'brooks@mission.io',
		'casey@mission.io',
		'cameron@mission.io',
	]

	return emailsThatCanUseDemos.includes(user.email)
}
