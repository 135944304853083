import React from 'react'
import CustomModal, {
	ModalHeader,
	ModalBody,
	ModalFooter,
	ExitX,
} from '../../../components/modal/Modal'
import { Button } from '@mission.io/styles'
import { toast } from 'react-toastify'
import { removePendingInvite } from '../LicenseNetworkRoutes'
import { ADMIN_PAGE_USE_QUERY_ID } from '../helpers'
import { useQueryClient } from 'react-query'

type Props = { isOpen: boolean, email: string, onRequestClose: () => void }

/**
 * This modal confirms the decision to remove a license invite to an email.
 * After it makes the call, it closes the modal.
 * If the call was successful, it refreshes the react-query that gets the pending invites.
 */
export default function RemovePendingInviteModal({
	isOpen,
	email,
	onRequestClose,
}: Props): React$Node {
	// React Query V2 cache- allows us to refetch the pending invitations after we remove one
	const queryCache = useQueryClient()

	const showSuccessToast = (isSuccess: boolean) => {
		if (isSuccess) {
			toast.success('Removed invitation', {
				position: toast.POSITION.TOP_RIGHT,
			})
		} else {
			toast.error('Failed to remove invite sent to ' + email, {
				position: toast.POSITION.TOP_RIGHT,
			})
		}
	}

	return (
		<CustomModal
			aria={{
				labelledby: 'RemovePendingInviteModal',
			}}
			style={{ content: { width: '40%', top: '20%' } }}
			isOpen={isOpen}
			shouldCloseOnOverlayClick
			onRequestClose={onRequestClose}>
			<ModalHeader id="RemovePendingInviteModal">
				Remove Invitation
				<ExitX
					role={'button'}
					title="Close"
					aria-label={'Close Remove Pending Invite Dialog'}
					onClick={onRequestClose}
				/>
			</ModalHeader>
			<ModalBody>
				Are you sure you want to remove the invitation sent to {email}? If you want to
				invite them again, you will have to send them another invitation link.
			</ModalBody>
			<ModalFooter>
				<Button
					outline
					onClick={() => {
						removePendingInvite(
							email,
							// onSuccess callback
							() => {
								showSuccessToast(true)
								queryCache.invalidateQueries(ADMIN_PAGE_USE_QUERY_ID, {
									exact: true,
								})
							},
							// onError callback
							() => showSuccessToast(false)
						)
						onRequestClose()
					}}>
					Remove Invitation
				</Button>
				<Button className="ml-2" onClick={onRequestClose}>
					Cancel
				</Button>
			</ModalFooter>
		</CustomModal>
	)
}
