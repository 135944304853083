export type VideoString = 'VIDEO'
export type PathSelectString = 'PATH_SELECT'
export const VIDEO: VideoString = 'VIDEO'
export const PATH_SELECT: PathSelectString = 'PATH_SELECT'

export type AutoDemoStepType = VideoString | PathSelectString

type AutoDemoStepShared = {|
	_id: string,
	name: string,
|}

type PathSelectOption = {|
	buttonText: string,
	nextStepId?: string | void,
|}

export type VideoAutoDemoStep = {|
	...AutoDemoStepShared,
	type: VideoString,
	video: {
		url: string,
		captionsUrl?: string,
		muxPlaybackId?: string,
	},
	nextStepId?: string | void,
|}

export type PathSelectAutoDemoStep = {|
	...AutoDemoStepShared,
	type: PathSelectString,
	backgroundVideo: {
		url: string,
		muxPlaybackId?: string,
	},
	promptText: string,
	options: PathSelectOption[],
	optionLayout?: {
		top?: string,
		bottom?: string,
		right?: string,
		left?: string,
		columns?: number,
	},
|}

export type AutoDemoStep = VideoAutoDemoStep | PathSelectAutoDemoStep

export type AutoDemo = {
	initialStep: string,
	steps: { [string]: AutoDemoStep },
}
