import axios from 'axios'
import config from '../../config'
import { useQuery, type UseQueryResult } from 'react-query'
import { VIDEO, type AutoDemo, type PathSelectAutoDemoStep, type AutoDemoStep } from './types'

/**
 * Fetches data for the auto demo
 * @return {Promise<?AutoDemo>} The auto demo data
 */
async function fetchAutoDemo(): Promise<?AutoDemo> {
	const result = await axios.get(`${config.missionsAPIURL}/api/appConfig/autoDemo`)
	return result.data.autoDemo
}

/**
 * A wrapper around useQuery that gets the auto demo data.
 * @return {UseQueryResult<AutoDemo>}
 */
export function useAutoDemo(): UseQueryResult<AutoDemo> {
	return useQuery('auto-demo', fetchAutoDemo, { staleTime: Infinity })
}

/**
 * Gets a list of each of an AutoDemo's PathSelect steps in order. All PathSelect steps that are reachable from the initial step will be in the returned
 * array, and none of the steps will be in the array multiple times.
 * @param {AutoDemo} autoDemo The auto demo definition
 * @return {PathSelectautoDemoStep[]} All PathSelect steps that are reachable from the initial step in the provided `autoDemo`
 */
export function getOrderedPathSelectSteps(autoDemo: AutoDemo): PathSelectAutoDemoStep[] {
	return recursiveGetOrderedPathSelectSteps(autoDemo.steps, autoDemo.initialStep, new Set())
}

/**
 * A recursive helper function used to achieve the functionality of `getOrderedPathSelectSteps`.
 * @param {{ [string]: AutoDemoStep }} steps All steps in the auto demo
 * @param {?string} currentStepId The step id of the step to handle in the current iteration of the recursive function
 * @param {Set<string>} visitedStepIds A set of all step ids that have been visited during the recursion. Used to prevent cycling
 * @return {PathSelectAutoDemoStep[]} A list of all path select steps from `currentStepId` down
 */
function recursiveGetOrderedPathSelectSteps(
	steps: { [string]: AutoDemoStep },
	currentStepId: ?string,
	visitedStepIds: Set<string>
): PathSelectAutoDemoStep[] {
	if (!currentStepId) {
		return []
	}

	if (visitedStepIds.has(currentStepId)) {
		return []
	}
	visitedStepIds.add(currentStepId)

	const step = steps[currentStepId]
	if (!step) {
		return []
	}

	if (step.type === VIDEO) {
		return recursiveGetOrderedPathSelectSteps(steps, step.nextStepId, visitedStepIds)
	} else {
		return [
			step,
			...step.options.flatMap(option =>
				recursiveGetOrderedPathSelectSteps(steps, option.nextStepId, visitedStepIds)
			),
		]
	}
}
