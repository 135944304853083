import React, { useState, useMemo } from 'react'
import { useQueryClient } from 'react-query'
import { useLocationParameters } from '../utility/hooks'
import {
	useImpression,
	respondToImpression,
	type MissionImpressions,
} from '../queryHooks/impressions'
import { useBasicMission } from '../queryHooks/basicMission'
import { toast } from 'react-toastify'
import { Button } from '@mission.io/styles'
import LoadingSpinner from '../styles/LoadingSpinner'
import FaStar from 'react-icons/lib/fa/star'
import FaStarOutline from 'react-icons/lib/fa/star-o'
import CloseCircle from 'react-icons/lib/md/close'
import Modal, {
	ModalHeader,
	ModalFooter,
	ModalBody,
	MODAL_Z_INDEX,
} from '../components/modal/Modal'
import styled from 'styled-components/macro'
import type { $AxiosError } from 'axios'

const MAX_RATING = 5

export function SurveyModal({
	missionId,
	requestClose,
}: {
	missionId: string,
	requestClose: () => void,
}): React$Node {
	const isComingBackFromMissionController = useLocationParameters().survey === missionId
	const [submitting, setSubmitting] = useState(false)
	const [rating, setRating] = useState(-1)
	const [feedback, setFeedback] = useState('')
	const [error, setError] = useState(null)
	useImpression(missionId, {
		onError: (error: $AxiosError<mixed>) => {
			if (error.response?.status === 404) {
				setError('Could not find the survey data for the requested mission')
				return
			} else if (error.response?.status === 401) {
				setError('You do not have permission to access this survey.')
				return
			}
			setError('An unexpected error ocurred while preparing the survey.')
			console.error(
				`The following unexpected error ocurred while fetching the survey for mission ${missionId}`
			)
			console.error(error)
		},
		onSuccess: (impression: ?MissionImpressions) => {
			if (impression?.teacher) {
				setError('You have already completed the survey for this mission.')
			}
		},
	})
	const queryClient = useQueryClient()
	const { mission, isLoading } = useBasicMission(missionId, {
		refetchOnWindowFocus: false,
		retry: false,
	})

	const stars = useMemo(() => {
		const stars = []

		for (let i = 0; i < MAX_RATING; i++) {
			const Star = i < rating ? FaStar : FaStarOutline

			stars.push(
				<span onClick={() => setRating(i + 1)} key={i}>
					<Star />
				</span>
			)
		}

		return stars
	}, [rating])

	return (
		<StyledModal
			isOpen={true}
			onRequestClose={requestClose}
			// This modal should be above other modals because it's the first thing a user should see when they get back to Dashboard
			style={{ overlay: { zIndex: MODAL_Z_INDEX * 2 } }}>
			<ModalHeader>
				<div className="font-semibold">
					{isComingBackFromMissionController ? 'Welcome Back' : 'Mission Survey'}
				</div>
				<Circle onClick={requestClose}>
					<StyledCloseCircle />
				</Circle>
			</ModalHeader>
			{isLoading ? (
				<ModalBody>
					<LoadingSpinner
						shouldShowSpinner
						style={{ margin: '0 auto', width: '40px', height: '40px' }}
					/>
				</ModalBody>
			) : (
				<>
					<ModalBody>
						<div className="text-2xl font-semibold">
							You completed{' '}
							{!mission ? (
								'your mission'
							) : (
								<span className="font-bold">
									{mission.simulationName} ({mission.code})
								</span>
							)}
							!
						</div>
						<Section>
							<div>
								Students thrive off feedback and so do we! We continually improve
								our missions based on your feedback.
							</div>
						</Section>
						<Section>
							<div className="font-semibold">
								How would you rate this mission? (Required)
							</div>
							<Stars>{stars}</Stars>
						</Section>
						<Section>
							<div className="font-semibold">
								Any feedback or comments about the mission? (likes, dislikes,
								technical difficulties, etc.)
							</div>
							<TextArea
								placeholder="You can leave this blank if you have no feedback."
								onChange={event => setFeedback(event.target.value)}
								value={feedback}
								rows={3}
							/>
						</Section>
						{error && (
							<div className="bg-[--app-gray] text-xl absolute inset-0 flex justify-center items-center backdrop-blur-sm">
								<div>{error}</div>
							</div>
						)}
					</ModalBody>
					<ModalFooter>
						{!error ? (
							<>
								<Button outline onClick={requestClose}>
									Maybe Later
								</Button>
								<Button
									onClick={() => {
										if (rating < 0) {
											toast.warn(
												'Please rate the mission before submitting feedback.'
											)
											return
										}
										setSubmitting(true)
										respondToImpression(
											missionId,
											rating,
											feedback,
											queryClient
										)
											.then(() => requestClose())
											.catch(error => {
												console.error(
													'Error while saving teacher impression',
													error
												)
												toast.error('Failed to save survey')
												setSubmitting(false)
											})
									}}>
									<LoadingSpinner
										beforeActionText={'Submit'}
										shouldShowSpinner={submitting}
									/>
								</Button>
							</>
						) : (
							<Button onClick={requestClose}>Close Survey</Button>
						)}
					</ModalFooter>
				</>
			)}
		</StyledModal>
	)
}

const TextArea = styled.textarea`
	width: 100%;
	${({ theme }) => `
		padding var(--spacing1x-dont-use);
	`}
`

const Stars = styled.div.attrs({
	className:
		'flex flex-row justify-center text-3xl p-4 [&>*]:py-0 [&>*]:px-2 [&>*]:hover:cursor-pointer',
})`
	${({ theme }) => `
		color: ${theme.primary};
		background-color: ${theme.primary}10;
	`}
`

const StyledModal = styled(Modal)``

const Section = styled.div`
	margin-top: var(--spacing2x-dont-use);
`

const Circle = styled.div.attrs({
	className:
		'rounded-full w-9 h-9 text-2xl flex justify-center items-center text-center hover:cursor-pointer',
})`
	background-color: ${({ theme }) => `${theme.primary}20;`};
`

const StyledCloseCircle = styled(CloseCircle)`
	color: ${({ theme }) => theme.primary};
`
