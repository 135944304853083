import styled from 'styled-components'
import type { StyledType } from 'global-types'

export const TeamBoxWrapper: StyledType<> = styled.div.attrs(({ boxState }) => ({
	className: `text-danger rounded p-4 ${
		boxState === 'valid'
			? 'bg-primary-600'
			: boxState === 'hovered'
			? 'bg-primary-500'
			: 'bg-error'
	}`,
}))`
	min-height: 80px;
	transition: background 0.4s;
`

export const Header: StyledType<> = styled.div`
	margin: 2px;
`

export const CenteredText: StyledType<> = styled.div`
	font-size: 0.8rem;
	margin: auto;
	text-align: center;
`
