import type { StudentAnalytics } from '@mission.io/mission-toolkit'

export const STUDENT_ANALYTICS_0: StudentAnalytics = {
	studentPoints: {
		DECKS: 0,
		COMMUNICATION: 0,
		TRANSPORTER: 0,
		THRUSTERS: 151,
		POWER: 0,
		DEFENSE: 0,
		TRACTOR_BEAM: 0,
		QUESTIONS: 500,
		SENSOR: 900,
		CREATIVE_CANVAS: 0,
	},
	skills: {
		criticalThinking: {
			scannedData: 1,
			scanOrder: 0.4,
		},
		collaboration: {
			socialHealthScore: 0.6,
		},
	},
	numTimesHealthHitZero: 1,
	disposition: {
		initiative: {
			alertResponse: {
				time: 8410,
				score: 1.26,
			},
			scannedDataResponse: {
				time: 74773.33333333333,
				score: 0.7793333333333333,
			},
			stationResponse: {
				time: 1498,
				score: 1.17,
			},
			mapParticipation: {
				percentActive: 0.07,
				score: 0.093,
			},
			mapVotePercentage: {
				percentage: 1,
				outOf: 3,
				score: 1,
			},
		},
		grit: {
			gritAfterNegativeEvents: {
				APMDetails: [
					{
						APMInDuration: 2.5,
						eventType: 'QUESTION_ANSWERED',
						actionTime: new Date('2023-09-01T16:18:57.026Z'),
						score: 1.0749428104575163,
					},
					{
						APMInDuration: 2.5,
						eventType: 'QUESTION_ANSWERED',
						actionTime: new Date('2023-09-01T16:19:34.738Z'),
						score: 1.0749428104575163,
					},
				],
				averageAPM: 2.3257051218715086,
				score: 1.0749428104575163,
			},
		},
	},
	culminatingMomentAnswers: [
		{
			answer: {
				type: 'MULTIPLE_CHOICE',
				value: '63ecacfe3242ae001e233b88',
			},
			questionId: '63ecacfe3242ae001e233b85',
			questionVersion: 1,
			requestId: '1',
			answerNumber: 1,
			score: {
				score: 1,
				perfectScore: 1,
			},
			timestamp: 1693585033333,
		},
	],
	knowledge: {
		application: 1,
		questions: 0.3333333333333333,
	},
	questionAnswers: [
		{
			answer: {
				type: 'MULTIPLE_CHOICE',
				value: 'CHOICE_6_ID',
			},
			questionId: 'QUESTION_1_ID',
			questionVersion: 1,
			timestamp: 1693585137026,
			score: {
				score: 0,
				perfectScore: 1,
			},
			requestId: '3de31b26-e727-4faf-a06a-e1837f5c7610',
			answerNumber: 1,
		},
		{
			answer: {
				type: 'MULTIPLE_CHOICE',
				value: 'CHOICE_8_ID',
			},
			questionId: 'QUESTION_2_ID',
			questionVersion: 1,
			timestamp: 1693585174738,
			score: {
				score: 0,
				perfectScore: 1,
			},
			requestId: '4db10b50-001f-45d9-8fe3-a49e6f3e445c',
			answerNumber: 1,
		},
		{
			answer: {
				type: 'MULTIPLE_SELECT',
				value: ['CHOICE_0_ID', 'CHOICE_1_ID', 'CHOICE_2_ID', 'CHOICE_3_ID'],
			},
			questionId: 'QUESTION_0_ID',
			questionVersion: 1,
			timestamp: 1693585197854,
			score: {
				score: 1,
				perfectScore: 1,
			},
			requestId: '67d98224-422d-4737-957a-08fe899511a2',
			answerNumber: 1,
		},
	],
	averageHealth: 84.44460070835203,
}

export const STUDENT_ANALYTICS_1: StudentAnalytics = {
	studentPoints: {
		DECKS: 0,
		COMMUNICATION: 0,
		TRANSPORTER: 0,
		THRUSTERS: 117,
		POWER: 0,
		DEFENSE: 150,
		TRACTOR_BEAM: 0,
		QUESTIONS: 500,
		SENSOR: 900,
		CREATIVE_CANVAS: 0,
	},
	skills: {
		criticalThinking: {
			scannedData: 1,
			scanOrder: 0.2,
		},
		collaboration: {
			socialHealthScore: 0.74,
		},
	},
	numTimesHealthHitZero: 1,
	disposition: {
		initiative: {
			alertResponse: {
				time: 13212,
				score: 1.18,
			},
			scannedDataResponse: {
				time: 23217.666666666668,
				score: 1.125,
			},
			stationResponse: {
				time: 4502,
				score: 1.11,
			},
			mapParticipation: {
				percentActive: 0.07,
				score: 0.093,
			},
			mapVotePercentage: {
				percentage: 1,
				outOf: 3,
				score: 1,
			},
		},
		grit: {
			gritAfterNegativeEvents: {
				APMDetails: [
					{
						APMInDuration: 3,
						eventType: 'QUESTION_ANSWERED',
						actionTime: new Date('2023-09-01T16:19:16.112Z'),
						score: 0.6513514851485148,
					},
					{
						APMInDuration: 4.5,
						eventType: 'QUESTION_ANSWERED',
						actionTime: new Date('2023-09-01T16:19:51.853Z'),
						score: 0.9770272277227723,
					},
				],
				averageAPM: 4.6058081825298505,
				score: 0.8141893564356435,
			},
		},
	},
	culminatingMomentAnswers: [
		{
			answer: {
				type: 'MULTIPLE_CHOICE',
				value: '63ecacfe3242ae001e233b88',
			},
			questionId: '63ecacfe3242ae001e233b85',
			questionVersion: 1,
			requestId: '1',
			answerNumber: 1,
			score: {
				score: 1,
				perfectScore: 1,
			},
			timestamp: 1693585033333,
		},
	],
	knowledge: {
		application: 1,
		questions: 0.4166666666,
	},
	questionAnswers: [
		{
			answer: {
				type: 'MULTIPLE_SELECT',
				value: ['CHOICE_2_ID'],
			},
			questionId: 'QUESTION_0_ID',
			questionVersion: 1,
			timestamp: 1693585156112,
			score: {
				score: 0.25,
				perfectScore: 1,
			},
			requestId: '733e1815-e31d-49f1-8501-5d297963e7d1',
			answerNumber: 1,
		},
		{
			answer: {
				type: 'MULTIPLE_CHOICE',
				value: 'CHOICE_6_ID',
			},
			questionId: 'QUESTION_1_ID',
			questionVersion: 1,
			timestamp: 1693585191853,
			score: {
				score: 0,
				perfectScore: 1,
			},
			requestId: '0d35bf64-3509-4724-aa32-38ab62fccd77',
			answerNumber: 1,
		},
		{
			answer: {
				type: 'MULTIPLE_CHOICE',
				value: 'CHOICE_10_ID',
			},
			questionId: 'QUESTION_2_ID',
			questionVersion: 1,
			timestamp: 1693585224847,
			score: {
				score: 1,
				perfectScore: 1,
			},
			requestId: '6e816791-6b45-43ce-bfce-713c35f2bdc8',
			answerNumber: 1,
		},
	],
	averageHealth: 84.71974074468167,
}

export const STUDENT_ANALYTICS_2: StudentAnalytics = {
	studentPoints: {
		DECKS: 0,
		COMMUNICATION: 0,
		TRANSPORTER: 0,
		THRUSTERS: 101,
		POWER: 0,
		DEFENSE: 0,
		TRACTOR_BEAM: 0,
		QUESTIONS: 500,
		SENSOR: 400,
		CREATIVE_CANVAS: 0,
	},
	skills: {
		criticalThinking: {
			scannedData: 1,
			scanOrder: 0.6,
		},
		collaboration: {
			socialHealthScore: 0.67,
		},
	},
	numTimesHealthHitZero: 1,
	disposition: {
		initiative: {
			alertResponse: {
				time: 124825,
				score: 0.30899999999999994,
			},
			scannedDataResponse: {
				time: 293832,
				score: 0.25,
			},
			stationResponse: {
				time: 4802,
				score: 1.104,
			},
			mapParticipation: {
				percentActive: 0.03,
				score: 0.04,
			},
			mapVotePercentage: {
				percentage: 1,
				outOf: 3,
				score: 1,
			},
		},
		grit: {
			gritAfterNegativeEvents: {
				APMDetails: [
					{
						APMInDuration: 3,
						eventType: 'QUESTION_ANSWERED',
						actionTime: new Date('2023-09-01T16:19:14.610Z'),
						score: 0.9501630434782609,
					},
					{
						APMInDuration: 4.5,
						eventType: 'QUESTION_ANSWERED',
						actionTime: new Date('2023-09-01T16:20:20.938Z'),
						score: 1.4252445652173913,
					},
				],
				averageAPM: 3.157352857061145,
				score: 1.187703804347826,
			},
		},
	},
	culminatingMomentAnswers: [
		{
			answer: {
				type: 'MULTIPLE_CHOICE',
				value: '63ecacfe3242ae001e233b88',
			},
			questionId: '63ecacfe3242ae001e233b85',
			questionVersion: 1,
			requestId: '1',
			answerNumber: 1,
			score: {
				score: 1,
				perfectScore: 1,
			},
			timestamp: 1693585033333,
		},
	],
	knowledge: {
		application: 1,
		questions: 0.5,
	},
	questionAnswers: [
		{
			answer: {
				type: 'MULTIPLE_CHOICE',
				value: 'CHOICE_6_ID',
			},
			questionId: 'QUESTION_1_ID',
			questionVersion: 1,
			timestamp: 1693585154610,
			score: {
				score: 0,
				perfectScore: 1,
			},
			requestId: '061b85b0-0560-4e70-90a6-6f32889c91fc',
			answerNumber: 1,
		},
		{
			answer: {
				type: 'MULTIPLE_CHOICE',
				value: 'CHOICE_10_ID',
			},
			questionId: 'QUESTION_2_ID',
			questionVersion: 1,
			timestamp: 1693585190355,
			score: {
				score: 1,
				perfectScore: 1,
			},
			requestId: 'dbb9f46c-f90b-4ec6-a941-5e07a9adf802',
			answerNumber: 1,
		},
		{
			answer: {
				type: 'MULTIPLE_SELECT',
				value: ['CHOICE_1_ID', 'CHOICE_2_ID'],
			},
			questionId: 'QUESTION_0_ID',
			questionVersion: 1,
			timestamp: 1693585220938,
			score: {
				score: 0.5,
				perfectScore: 1,
			},
			requestId: '8a0bc98d-b6a1-4b02-9612-7b58b4ea81f6',
			answerNumber: 1,
		},
	],
	averageHealth: 84.94694430017732,
}

export const STUDENT_ANALYTICS_3: StudentAnalytics = {
	studentPoints: {
		DECKS: 0,
		COMMUNICATION: 0,
		TRANSPORTER: 0,
		THRUSTERS: 58,
		POWER: 0,
		DEFENSE: 0,
		TRACTOR_BEAM: 0,
		QUESTIONS: 500,
		SENSOR: 900,
		CREATIVE_CANVAS: 0,
	},
	skills: {
		criticalThinking: {
			scannedData: 1,
			scanOrder: 0.8,
		},
		collaboration: {
			socialHealthScore: 0.8,
		},
	},
	numTimesHealthHitZero: 1,
	disposition: {
		initiative: {
			alertResponse: {
				time: 8714,
				score: 1.255,
			},
			scannedDataResponse: {
				time: 165584.66666666666,
				score: 0.119,
			},
			stationResponse: {
				time: 3000,
				score: 1.14,
			},
			mapParticipation: {
				percentActive: 0.06,
				score: 0.08,
			},
			mapVotePercentage: {
				percentage: 1,
				outOf: 3,
				score: 1,
			},
		},
		grit: {
			gritAfterNegativeEvents: {
				APMDetails: [
					{
						APMInDuration: 3.5,
						eventType: 'QUESTION_ANSWERED',
						actionTime: new Date('2023-09-01T16:19:26.619Z'),
						score: 1.0659849537037038,
					},
					{
						APMInDuration: 3,
						eventType: 'QUESTION_ANSWERED',
						actionTime: new Date('2023-09-01T16:19:35.639Z'),
						score: 0.913701388888889,
					},
				],
				averageAPM: 3.283348407348012,
				score: 0.9898431712962964,
			},
		},
	},
	culminatingMomentAnswers: [
		{
			answer: {
				type: 'MULTIPLE_CHOICE',
				value: '63ecacfe3242ae001e233b88',
			},
			questionId: '63ecacfe3242ae001e233b85',
			questionVersion: 1,
			requestId: '1',
			answerNumber: 1,
			score: {
				score: 1,
				perfectScore: 1,
			},
			timestamp: 1693585033333,
		},
	],
	knowledge: {
		application: 1,
		questions: 0.4166666666,
	},
	questionAnswers: [
		{
			answer: {
				type: 'MULTIPLE_SELECT',
				value: ['CHOICE_1_ID'],
			},
			questionId: 'QUESTION_0_ID',
			questionVersion: 1,
			timestamp: 1693585166619,
			score: {
				score: 0.25,
				perfectScore: 1,
			},
			requestId: '7ffb4ccb-9cc8-4128-a936-75780571f634',
			answerNumber: 1,
		},
		{
			answer: {
				type: 'MULTIPLE_CHOICE',
				value: 'CHOICE_11_ID',
			},
			questionId: 'QUESTION_2_ID',
			questionVersion: 1,
			timestamp: 1693585175639,
			score: {
				score: 0,
				perfectScore: 1,
			},
			requestId: 'd093b188-e238-4b14-a5b5-5005e7dc0c54',
			answerNumber: 1,
		},
		{
			answer: {
				type: 'MULTIPLE_CHOICE',
				value: 'CHOICE_5_ID',
			},
			questionId: 'QUESTION_1_ID',
			questionVersion: 1,
			timestamp: 1693585186752,
			score: {
				score: 1,
				perfectScore: 1,
			},
			requestId: '7ba6743a-d8c5-4988-b48c-3634c2341976',
			answerNumber: 1,
		},
	],
	averageHealth: 84.67636558412441,
}

export const STUDENT_ANALYTICS_4: StudentAnalytics = {
	studentPoints: {
		DECKS: 0,
		COMMUNICATION: 0,
		TRANSPORTER: 0,
		THRUSTERS: 152,
		POWER: 0,
		DEFENSE: 150,
		TRACTOR_BEAM: 0,
		QUESTIONS: 1000,
		SENSOR: 500,
		CREATIVE_CANVAS: 0,
	},
	skills: {
		criticalThinking: {
			scannedData: 0.48,
			scanOrder: 0.9,
		},
		collaboration: {
			socialHealthScore: 0.89,
		},
	},
	numTimesHealthHitZero: 1,
	disposition: {
		initiative: {
			alertResponse: {
				time: 9612,
				score: 1.24,
			},
			scannedDataResponse: {
				time: 696495.3333333334,
				score: 0,
			},
			stationResponse: {
				time: 2399,
				score: 1.152,
			},
			mapParticipation: {
				percentActive: 0.06,
				score: 0.08,
			},
			mapVotePercentage: {
				percentage: 1,
				outOf: 3,
				score: 1,
			},
		},
		grit: {
			gritAfterNegativeEvents: {
				APMDetails: [
					{
						APMInDuration: 3,
						eventType: 'QUESTION_ANSWERED',
						actionTime: new Date('2023-09-01T16:19:27.821Z'),
						score: 1.3997127659574466,
					},
				],
				averageAPM: 2.1432968770188414,
				score: 1.3997127659574466,
			},
		},
	},
	culminatingMomentAnswers: [
		{
			answer: {
				type: 'MULTIPLE_CHOICE',
				value: '63ecacfe3242ae001e233b88',
			},
			questionId: '63ecacfe3242ae001e233b85',
			questionVersion: 1,
			requestId: '1',
			answerNumber: 1,
			score: {
				score: 1,
				perfectScore: 1,
			},
			timestamp: 1693585033333,
		},
	],
	knowledge: {
		application: 1,
		questions: 0.6666666666666666,
	},
	questionAnswers: [
		{
			answer: {
				type: 'MULTIPLE_CHOICE',
				value: 'CHOICE_5_ID',
			},
			questionId: 'QUESTION_1_ID',
			questionVersion: 1,
			timestamp: 1693585154910,
			score: {
				score: 1,
				perfectScore: 1,
			},
			requestId: 'dbd4b145-ecd5-4f76-9af5-b36b4b51e400',
			answerNumber: 1,
		},
		{
			answer: {
				type: 'MULTIPLE_SELECT',
				value: ['CHOICE_3_ID'],
			},
			questionId: 'QUESTION_0_ID',
			questionVersion: 1,
			timestamp: 1693585167821,
			score: {
				score: 0,
				perfectScore: 1,
			},
			requestId: '15784e10-cce1-4211-a819-ba9b1878bd56',
			answerNumber: 1,
		},
		{
			answer: {
				type: 'MULTIPLE_CHOICE',
				value: 'CHOICE_10_ID',
			},
			questionId: 'QUESTION_2_ID',
			questionVersion: 1,
			timestamp: 1693585186747,
			score: {
				score: 1,
				perfectScore: 1,
			},
			requestId: 'ffba0233-8dac-4771-a451-6db3a4bee820',
			answerNumber: 1,
		},
	],
	averageHealth: 86.58662072765689,
}

export const STUDENT_ANALYTICS_5: StudentAnalytics = {
	studentPoints: {
		DECKS: 0,
		COMMUNICATION: 0,
		TRANSPORTER: 0,
		THRUSTERS: 85,
		POWER: 0,
		DEFENSE: 0,
		TRACTOR_BEAM: 0,
		QUESTIONS: 1000,
		SENSOR: 650,
		CREATIVE_CANVAS: 0,
	},
	skills: {
		criticalThinking: {
			scannedData: 0.75,
			scanOrder: 0,
		},
		collaboration: {
			socialHealthScore: 0.56,
		},
	},
	numTimesHealthHitZero: 1,
	disposition: {
		initiative: {
			alertResponse: {
				time: 9615,
				score: 1.24,
			},
			scannedDataResponse: {
				time: 70166.66666666667,
				score: 0.8136666666666666,
			},
			stationResponse: {
				time: 2699,
				score: 1.146,
			},
			mapParticipation: {
				percentActive: 0.03,
				score: 0.04,
			},
			mapVotePercentage: {
				percentage: 1,
				outOf: 3,
				score: 1,
			},
		},
		grit: {
			gritAfterNegativeEvents: {
				APMDetails: [
					{
						APMInDuration: 4,
						eventType: 'QUESTION_ANSWERED',
						actionTime: new Date('2023-09-01T16:19:52.153Z'),
						score: 0.9967651515151514,
					},
				],
				averageAPM: 4.012981386758682,
				score: 0.9967651515151514,
			},
		},
	},
	culminatingMomentAnswers: [
		{
			answer: {
				type: 'MULTIPLE_CHOICE',
				value: '63ecacfe3242ae001e233b88',
			},
			questionId: '63ecacfe3242ae001e233b85',
			questionVersion: 1,
			requestId: '1',
			answerNumber: 1,
			score: {
				score: 1,
				perfectScore: 1,
			},
			timestamp: 1693585033333,
		},
	],
	knowledge: {
		application: 1,
		questions: 0.75,
	},
	questionAnswers: [
		{
			answer: {
				type: 'MULTIPLE_CHOICE',
				value: 'CHOICE_10_ID',
			},
			questionId: 'QUESTION_2_ID',
			questionVersion: 1,
			timestamp: 1693585154010,

			score: {
				score: 1,
				perfectScore: 1,
			},
			requestId: '48ae60f5-1d70-433e-9ece-e90d52ca6e42',
			answerNumber: 1,
		},
		{
			answer: {
				type: 'MULTIPLE_SELECT',
				value: ['CHOICE_3_ID'],
			},
			questionId: 'QUESTION_0_ID',
			questionVersion: 1,
			timestamp: 1693585192153,
			score: {
				score: 0.25,
				perfectScore: 1,
			},
			requestId: '5eec167b-66df-492f-832e-1be5c5bb08b9',
			answerNumber: 1,
		},
		{
			answer: {
				type: 'MULTIPLE_CHOICE',
				value: 'CHOICE_5_ID',
			},
			questionId: 'QUESTION_1_ID',
			questionVersion: 1,
			timestamp: 1693585218238,
			score: {
				score: 1,
				perfectScore: 1,
			},
			requestId: 'c2e2ae49-5862-411e-b153-fd220365ff30',
			answerNumber: 1,
		},
	],
	averageHealth: 86.77155228656336,
}
export const STUDENT_ANALYTICS_6: StudentAnalytics = {
	studentPoints: {
		DECKS: 0,
		COMMUNICATION: 0,
		TRANSPORTER: 0,
		THRUSTERS: 115,
		POWER: 0,
		DEFENSE: 0,
		TRACTOR_BEAM: 0,
		QUESTIONS: 0,
		SENSOR: 50,
		CREATIVE_CANVAS: 0,
	},
	skills: {
		criticalThinking: {
			scannedData: 0,
			scanOrder: 0.47,
		},
		collaboration: {
			socialHealthScore: 0.7,
		},
	},
	numTimesHealthHitZero: 1,
	disposition: {
		initiative: {
			alertResponse: {
				time: 9619,
				score: 1.24,
			},
			scannedDataResponse: {
				time: 461989.6666666667,
				score: 0.4466666666666667,
			},
			stationResponse: {
				time: 3602,
				score: 1.128,
			},
			mapParticipation: {
				percentActive: 0,
				score: 0,
			},
			mapVotePercentage: {
				percentage: 1,
				outOf: 3,
				score: 1,
			},
		},
		grit: {
			gritAfterNegativeEvents: {
				APMDetails: [
					{
						APMInDuration: 2.5,
						eventType: 'QUESTION_ANSWERED',
						actionTime: new Date('2023-09-01T16:19:17.914Z'),
						score: 2,
					},
					{
						APMInDuration: 2.5,
						eventType: 'QUESTION_ANSWERED',
						actionTime: new Date('2023-09-01T16:19:48.550Z'),
						score: 2,
					},
					{
						APMInDuration: 2,
						eventType: 'QUESTION_ANSWERED',
						actionTime: new Date('2023-09-01T16:19:56.655Z'),
						score: 1.6868333333333334,
					},
				],
				averageAPM: 1.1856535915423376,
				score: 1.8956111111111111,
			},
		},
	},
	culminatingMomentAnswers: [
		{
			answer: {
				type: 'MULTIPLE_CHOICE',
				value: '63ecacfe3242ae001e233b88',
			},
			questionId: '63ecacfe3242ae001e233b85',
			questionVersion: 1,
			requestId: '1',
			answerNumber: 1,
			score: {
				score: 1,
				perfectScore: 1,
			},
			timestamp: 1693585033333,
		},
	],
	knowledge: {
		application: 1,
		questions: 0.08333333,
	},
	questionAnswers: [
		{
			answer: {
				type: 'MULTIPLE_CHOICE',
				value: 'CHOICE_9_ID',
			},
			questionId: 'QUESTION_2_ID',
			questionVersion: 1,
			timestamp: 1693585157914,

			score: {
				score: 0,
				perfectScore: 1,
			},
			requestId: '9fd79e36-8284-4ef0-a55f-84f5bff84273',
			answerNumber: 1,
		},
		{
			answer: {
				type: 'MULTIPLE_SELECT',
				value: ['CHOICE_1_ID'],
			},
			questionId: 'QUESTION_0_ID',
			questionVersion: 1,
			timestamp: 1693585188550,

			score: {
				score: 0.25,
				perfectScore: 1,
			},
			requestId: 'b6c63a77-739d-4c81-b2ea-1c0da9ee7ed6',
			answerNumber: 1,
		},
		{
			answer: {
				type: 'MULTIPLE_CHOICE',
				value: 'CHOICE_6_ID',
			},
			questionId: 'QUESTION_1_ID',
			questionVersion: 1,
			timestamp: 1693585196655,

			score: {
				score: 0,
				perfectScore: 1,
			},
			requestId: '64f5d2c4-92e8-4a98-a608-c3b88673f0db',
			answerNumber: 1,
		},
	],
	averageHealth: 82.96694191817471,
}
export const STUDENT_ANALYTICS_7: StudentAnalytics = {
	studentPoints: {
		DECKS: 0,
		COMMUNICATION: 0,
		TRANSPORTER: 0,
		THRUSTERS: 141,
		POWER: 0,
		DEFENSE: 0,
		TRACTOR_BEAM: 0,
		QUESTIONS: 500,
		SENSOR: 250,
		CREATIVE_CANVAS: 0,
	},
	skills: {
		criticalThinking: {
			scannedData: 1,
			scanOrder: 1,
		},
		collaboration: {
			socialHealthScore: 0.92,
		},
	},
	numTimesHealthHitZero: 1,
	disposition: {
		initiative: {
			alertResponse: {
				time: 9610,
				score: 1.24,
			},
			scannedDataResponse: {
				time: 696495.3333333334,
				score: 0,
			},
			stationResponse: {
				time: 3301,
				score: 1.134,
			},
			mapParticipation: {
				percentActive: 0.07,
				score: 0.093,
			},
			mapVotePercentage: {
				percentage: 1,
				outOf: 3,
				score: 1,
			},
		},
		grit: {
			gritAfterNegativeEvents: {
				APMDetails: [
					{
						APMInDuration: 4,
						eventType: 'QUESTION_ANSWERED',
						actionTime: new Date('2023-09-01T16:19:26.020Z'),
						score: 2,
					},
					{
						APMInDuration: 5,
						eventType: 'QUESTION_ANSWERED',
						actionTime: new Date('2023-09-01T16:20:29.650Z'),
						score: 2,
					},
				],
				averageAPM: 1.641674203674006,
				score: 2,
			},
		},
	},
	culminatingMomentAnswers: [
		{
			answer: {
				type: 'MULTIPLE_CHOICE',
				value: '63ecacfe3242ae001e233b86',
			},
			questionId: '63ecacfe3242ae001e233b85',
			questionVersion: 1,
			requestId: '1',
			answerNumber: 1,
			score: {
				score: 0,
				perfectScore: 1,
			},
			timestamp: 1693585033333,
		},
	],
	knowledge: {
		application: 0,
		questions: 0.5,
	},
	questionAnswers: [
		{
			answer: {
				type: 'MULTIPLE_CHOICE',
				value: 'CHOICE_6_ID',
			},
			questionId: 'QUESTION_1_ID',
			questionVersion: 1,
			timestamp: 1693585166020,

			score: {
				score: 0,
				perfectScore: 1,
			},
			requestId: '42e6f8f1-c32e-4084-abec-6e8d19cdaa36',
			answerNumber: 1,
		},
		{
			answer: {
				type: 'MULTIPLE_CHOICE',
				value: 'CHOICE_10_ID',
			},
			questionId: 'QUESTION_2_ID',
			questionVersion: 1,
			timestamp: 1693585210124,

			score: {
				score: 1,
				perfectScore: 1,
			},
			requestId: 'd533f1f3-4566-48d5-bdd7-438a4b529485',
			answerNumber: 1,
		},
		{
			answer: {
				type: 'MULTIPLE_SELECT',
				value: ['CHOICE_3_ID', 'CHOICE_2_ID'],
			},
			questionId: 'QUESTION_0_ID',
			questionVersion: 1,
			timestamp: 1693585229650,
			score: {
				score: 0.5,
				perfectScore: 1,
			},
			requestId: '0182648a-66b7-4b02-9765-3ba21a7c484e',
			answerNumber: 1,
		},
	],
	averageHealth: 85.08231513304402,
}
export const STUDENT_ANALYTICS_8: StudentAnalytics = {
	studentPoints: {
		DECKS: 0,
		COMMUNICATION: 0,
		TRANSPORTER: 0,
		THRUSTERS: 177,
		POWER: 0,
		DEFENSE: 150,
		TRACTOR_BEAM: 0,
		QUESTIONS: 1000,
		SENSOR: 750,
		CREATIVE_CANVAS: 0,
	},
	skills: {
		criticalThinking: {
			scannedData: 1,
			scanOrder: 0.5,
		},
		collaboration: {
			socialHealthScore: 0.62,
		},
	},
	numTimesHealthHitZero: 1,
	disposition: {
		initiative: {
			alertResponse: {
				time: 9912,
				score: 1.2349999999999999,
			},
			scannedDataResponse: {
				time: 696495.3333333334,
				score: 0,
			},
			stationResponse: {
				time: 4505,
				score: 1.11,
			},
			mapParticipation: {
				percentActive: 0.04,
				score: 0.053,
			},
			mapVotePercentage: {
				percentage: 1,
				outOf: 3,
				score: 1,
			},
		},
		grit: {
			gritAfterNegativeEvents: {
				APMDetails: [
					{
						APMInDuration: 4,
						eventType: 'QUESTION_ANSWERED',
						actionTime: new Date('2023-09-01T16:19:30.828Z'),

						score: 2,
					},
				],
				averageAPM: 1.413663897608172,
				score: 2,
			},
		},
	},
	culminatingMomentAnswers: [
		{
			answer: {
				type: 'MULTIPLE_CHOICE',
				value: '63ecacfe3242ae001e233b86',
			},
			questionId: '63ecacfe3242ae001e233b85',
			questionVersion: 1,
			requestId: '1',
			answerNumber: 1,
			score: {
				score: 0,
				perfectScore: 1,
			},
			timestamp: 1693585033333,
		},
	],
	knowledge: {
		application: 0,
		questions: 0.6666666666666666,
	},
	questionAnswers: [
		{
			answer: {
				type: 'MULTIPLE_CHOICE',
				value: 'CHOICE_8_ID',
			},
			questionId: 'QUESTION_2_ID',
			questionVersion: 1,
			timestamp: 1693585170828,

			score: {
				score: 0,
				perfectScore: 1,
			},
			requestId: 'ba1fbe57-91c5-4121-8cf0-0a18d07e685c',
			answerNumber: 1,
		},
		{
			answer: {
				type: 'MULTIPLE_SELECT',
				value: ['CHOICE_0_ID', 'CHOICE_1_ID', 'CHOICE_2_ID', 'CHOICE_3_ID'],
			},
			questionId: 'QUESTION_0_ID',
			questionVersion: 1,
			timestamp: 1693585237457,

			score: {
				score: 1,
				perfectScore: 1,
			},
			requestId: '79191153-64bd-4bf5-a1b6-4388fe7c58e6',
			answerNumber: 1,
		},
		{
			answer: {
				type: 'MULTIPLE_CHOICE',
				value: 'CHOICE_5_ID',
			},
			questionId: 'QUESTION_1_ID',
			questionVersion: 1,
			timestamp: 1693585267055,

			score: {
				score: 1,
				perfectScore: 1,
			},
			requestId: '04a6f35d-3397-4351-93c2-f2379606df5e',
			answerNumber: 1,
		},
	],
	averageHealth: 86.60947496066822,
}
export const STUDENT_ANALYTICS_9: StudentAnalytics = {
	studentPoints: {
		DECKS: 0,
		COMMUNICATION: 0,
		TRANSPORTER: 0,
		THRUSTERS: 141,
		POWER: 0,
		DEFENSE: 0,
		TRACTOR_BEAM: 0,
		QUESTIONS: 500,
		SENSOR: 400,
		CREATIVE_CANVAS: 0,
	},
	skills: {
		criticalThinking: {
			scannedData: 1,
			scanOrder: 0.8,
		},
		collaboration: {
			socialHealthScore: 0.77,
		},
	},
	numTimesHealthHitZero: 1,
	disposition: {
		initiative: {
			alertResponse: {
				time: 5702,
				score: 1.305,
			},
			scannedDataResponse: {
				time: 55089,
				score: 0.9249999999999999,
			},
			stationResponse: {
				time: 898,
				score: 1.182,
			},
			mapParticipation: {
				percentActive: 0.01,
				score: 0.013,
			},
			mapVotePercentage: {
				percentage: 1,
				outOf: 3,
				score: 1,
			},
		},
		grit: {
			gritAfterNegativeEvents: {
				APMDetails: [
					{
						APMInDuration: 2.5,
						eventType: 'QUESTION_ANSWERED',
						actionTime: new Date('2023-09-01T16:19:04.098Z'),

						score: 0.4457079945799458,
					},
					{
						APMInDuration: 2.5,
						eventType: 'QUESTION_ANSWERED',
						actionTime: new Date('2023-09-01T16:19:37.740Z'),

						score: 0.4457079945799458,
					},
				],
				averageAPM: 5.609053529219521,
				score: 0.4457079945799458,
			},
		},
	},
	culminatingMomentAnswers: [
		{
			answer: {
				type: 'MULTIPLE_CHOICE',
				value: '63ecacfe3242ae001e233b88',
			},
			questionId: '63ecacfe3242ae001e233b85',
			questionVersion: 1,
			requestId: '1',
			answerNumber: 1,
			score: {
				score: 1,
				perfectScore: 1,
			},
			timestamp: 1693585033333,
		},
	],
	knowledge: {
		application: 1,
		questions: 0.4166666666,
	},
	questionAnswers: [
		{
			answer: {
				type: 'MULTIPLE_SELECT',
				value: ['CHOICE_2_ID'],
			},
			questionId: 'QUESTION_0_ID',
			questionVersion: 1,
			timestamp: 1693585144098,

			score: {
				score: 0.25,
				perfectScore: 1,
			},
			requestId: '8a6dd7d7-ec16-4170-9fd8-0910ec76d88e',
			answerNumber: 1,
		},
		{
			answer: {
				type: 'MULTIPLE_CHOICE',
				value: 'CHOICE_5_ID',
			},
			questionId: 'QUESTION_1_ID',
			questionVersion: 1,
			timestamp: 1693585154312,

			score: {
				score: 1,
				perfectScore: 1,
			},
			requestId: 'a0c622ec-d472-4300-abdf-4d3f3ea29fb0',
			answerNumber: 1,
		},
		{
			answer: {
				type: 'MULTIPLE_CHOICE',
				value: 'CHOICE_11_ID',
			},
			questionId: 'QUESTION_2_ID',
			questionVersion: 1,
			timestamp: 1693585177740,

			score: {
				score: 0,
				perfectScore: 1,
			},
			requestId: '731590ea-353e-4a9d-b7c0-78dbd7c344d2',
			answerNumber: 1,
		},
	],
	averageHealth: 84.52116656912894,
}
export const STUDENT_ANALYTICS_10: StudentAnalytics = {
	studentPoints: {
		DECKS: 0,
		COMMUNICATION: 0,
		TRANSPORTER: 0,
		THRUSTERS: 140,
		POWER: 0,
		DEFENSE: 150,
		TRACTOR_BEAM: 0,
		QUESTIONS: 500,
		SENSOR: 750,
		CREATIVE_CANVAS: 0,
	},
	skills: {
		criticalThinking: {
			scannedData: 1,
			scanOrder: 0.25,
		},
		collaboration: {
			socialHealthScore: 0.52,
		},
	},
	numTimesHealthHitZero: 1,
	disposition: {
		initiative: {
			alertResponse: {
				time: 12912,
				score: 1.1849999999999998,
			},
			scannedDataResponse: {
				time: 696495.3333333334,
				score: 0,
			},
			stationResponse: {
				time: 2400,
				score: 1.152,
			},
			mapParticipation: {
				percentActive: 0.06,
				score: 0.08,
			},
			mapVotePercentage: {
				percentage: 1,
				outOf: 3,
				score: 1,
			},
		},
		grit: {
			gritAfterNegativeEvents: {
				APMDetails: [
					{
						APMInDuration: 1.5,
						eventType: 'QUESTION_ANSWERED',
						actionTime: new Date('2023-09-01T16:19:15.511Z'),

						score: 1.0964416666666668,
					},
					{
						APMInDuration: 3,
						eventType: 'QUESTION_ANSWERED',
						actionTime: new Date('2023-09-01T16:19:51.553Z'),

						score: 2,
					},
				],
				averageAPM: 1.368061836395005,
				score: 1.5482208333333334,
			},
		},
	},
	culminatingMomentAnswers: [
		{
			answer: {
				type: 'MULTIPLE_CHOICE',
				value: '63ecacfe3242ae001e233b87',
			},
			questionId: '63ecacfe3242ae001e233b85',
			questionVersion: 1,
			requestId: '1',
			answerNumber: 1,
			score: {
				score: 0,
				perfectScore: 1,
			},
			timestamp: 1693585033333,
		},
	],
	knowledge: {
		application: 0,
		questions: 0.4166666666,
	},
	questionAnswers: [
		{
			answer: {
				type: 'MULTIPLE_CHOICE',
				value: 'CHOICE_10_ID',
			},
			questionId: 'QUESTION_2_ID',
			questionVersion: 1,
			timestamp: 1693585141696,

			score: {
				score: 1,
				perfectScore: 1,
			},
			requestId: 'f02160e0-38bc-4f9f-94b7-c6595a87e2cf',
			answerNumber: 1,
		},
		{
			answer: {
				type: 'MULTIPLE_CHOICE',
				value: 'CHOICE_6_ID',
			},
			questionId: 'QUESTION_1_ID',
			questionVersion: 1,
			timestamp: 1693585155511,

			score: {
				score: 0,
				perfectScore: 1,
			},
			requestId: 'b1da3279-f783-45b6-8185-74e042b60bdb',
			answerNumber: 1,
		},
		{
			answer: {
				type: 'MULTIPLE_SELECT',
				value: ['CHOICE_3_ID'],
			},
			questionId: 'QUESTION_0_ID',
			questionVersion: 1,
			timestamp: 1693585191553,

			score: {
				score: 0.25,
				perfectScore: 1,
			},
			requestId: '5c5ef2d0-9ac3-4b54-ae2a-dd8b1d3fdc6f',
			answerNumber: 1,
		},
	],
	averageHealth: 84.71289283515615,
}
export const STUDENT_ANALYTICS_11: StudentAnalytics = {
	studentPoints: {
		DECKS: 0,
		COMMUNICATION: 0,
		TRANSPORTER: 0,
		THRUSTERS: 152,
		POWER: 0,
		DEFENSE: 0,
		TRACTOR_BEAM: 0,
		QUESTIONS: 0,
		SENSOR: 900,
		CREATIVE_CANVAS: 0,
	},
	skills: {
		criticalThinking: {
			scannedData: 1,
			scanOrder: 0,
		},
		collaboration: {
			socialHealthScore: 0.87,
		},
	},
	numTimesHealthHitZero: 1,
	disposition: {
		initiative: {
			alertResponse: {
				time: 11711,
				score: 1.2049999999999998,
			},
			scannedDataResponse: {
				time: 69165.33333333333,
				score: 0.8206666666666665,
			},
			stationResponse: {
				time: 2705,
				score: 1.146,
			},
			mapParticipation: {
				percentActive: 0.03,
				score: 0.04,
			},
			mapVotePercentage: {
				percentage: 1,
				outOf: 3,
				score: 1,
			},
		},
		grit: {
			gritAfterNegativeEvents: {
				APMDetails: [
					{
						APMInDuration: 2.5,
						eventType: 'QUESTION_ANSWERED',
						actionTime: new Date('2023-09-01T16:19:16.412Z'),

						score: 1.0542708333333335,
					},
					{
						APMInDuration: 2,
						eventType: 'QUESTION_ANSWERED',
						actionTime: new Date('2023-09-01T16:19:40.745Z'),

						score: 0.8434166666666667,
					},
					{
						APMInDuration: 1.5,
						eventType: 'QUESTION_ANSWERED',
						actionTime: new Date('2023-09-01T16:20:04.042Z'),

						score: 0.6325625,
					},
				],
				averageAPM: 2.3713071830846753,
				score: 0.8434166666666667,
			},
		},
	},
	culminatingMomentAnswers: [
		{
			answer: {
				type: 'MULTIPLE_CHOICE',
				value: '63ecacfe3242ae001e233b88',
			},
			questionId: '63ecacfe3242ae001e233b85',
			questionVersion: 1,
			requestId: '1',
			answerNumber: 1,
			score: {
				score: 1,
				perfectScore: 1,
			},
			timestamp: 1693585033333,
		},
	],
	knowledge: {
		application: 1,
		questions: 0.1666666666,
	},
	questionAnswers: [
		{
			answer: {
				type: 'MULTIPLE_CHOICE',
				value: 'CHOICE_7_ID',
			},
			questionId: 'QUESTION_1_ID',
			questionVersion: 1,
			timestamp: 1693585156412,

			score: {
				score: 0,
				perfectScore: 1,
			},
			requestId: 'c35bacba-fc8e-4560-9684-45d0c2a60674',
			answerNumber: 1,
		},
		{
			answer: {
				type: 'MULTIPLE_SELECT',
				value: ['CHOICE_2_ID', 'CHOICE_1_ID'],
			},
			questionId: 'QUESTION_0_ID',
			questionVersion: 1,
			timestamp: 1693585180745,

			score: {
				score: 0.5,
				perfectScore: 1,
			},
			requestId: '8be915fa-4fb3-49be-879a-b4aa5ce17112',
			answerNumber: 1,
		},
		{
			answer: {
				type: 'MULTIPLE_CHOICE',
				value: 'CHOICE_8_ID',
			},
			questionId: 'QUESTION_2_ID',
			questionVersion: 1,
			timestamp: 1693585204041,

			score: {
				score: 0,
				perfectScore: 1,
			},
			requestId: '16b8bc87-b1f1-47c5-aaaf-43ece52b9a66',
			answerNumber: 1,
		},
	],
	averageHealth: 82.95234925858651,
}
export const STUDENT_ANALYTICS_12: StudentAnalytics = {
	studentPoints: {
		DECKS: 0,
		COMMUNICATION: 0,
		TRANSPORTER: 0,
		THRUSTERS: 119,
		POWER: 0,
		DEFENSE: 150,
		TRACTOR_BEAM: 0,
		QUESTIONS: 500,
		SENSOR: 900,
		CREATIVE_CANVAS: 0,
	},
	skills: {
		criticalThinking: {
			scannedData: 1,
			scanOrder: 0,
		},
		collaboration: {
			socialHealthScore: 0.58,
		},
	},
	numTimesHealthHitZero: 1,
	disposition: {
		initiative: {
			alertResponse: {
				time: 8710,
				score: 1.255,
			},
			scannedDataResponse: {
				time: 76073.66666666667,
				score: 0.77,
			},
			stationResponse: {
				time: 2099,
				score: 1.158,
			},
			mapParticipation: {
				percentActive: 0.06,
				score: 0.08,
			},
			mapVotePercentage: {
				percentage: 1,
				outOf: 3,
				score: 1,
			},
		},
		grit: {
			gritAfterNegativeEvents: {
				APMDetails: [
					{
						APMInDuration: 2.5,
						eventType: 'QUESTION_ANSWERED',
						actionTime: new Date('2023-09-01T16:19:00.498Z'),

						score: 0.9789657738095238,
					},
					{
						APMInDuration: 1.5,
						eventType: 'QUESTION_ANSWERED',
						actionTime: new Date('2023-09-01T16:19:25.119Z'),

						score: 0.5873794642857143,
					},
				],
				averageAPM: 2.553715427937343,
				score: 0.783172619047619,
			},
		},
	},
	culminatingMomentAnswers: [
		{
			answer: {
				type: 'MULTIPLE_CHOICE',
				value: '63ecacfe3242ae001e233b88',
			},
			questionId: '63ecacfe3242ae001e233b85',
			questionVersion: 1,
			requestId: '1',
			answerNumber: 1,
			score: {
				score: 1,
				perfectScore: 1,
			},
			timestamp: 1693585033333,
		},
	],
	knowledge: {
		application: 1,
		questions: 0.5833333333,
	},
	questionAnswers: [
		{
			answer: {
				type: 'MULTIPLE_CHOICE',
				value: 'CHOICE_6_ID',
			},
			questionId: 'QUESTION_1_ID',
			questionVersion: 1,
			timestamp: 1693585140498,

			score: {
				score: 0,
				perfectScore: 1,
			},
			requestId: '88b31779-c8c7-4c0f-98f9-f4caeb3d7bf3',
			answerNumber: 1,
		},
		{
			answer: {
				type: 'MULTIPLE_CHOICE',
				value: 'CHOICE_10_ID',
			},
			questionId: 'QUESTION_2_ID',
			questionVersion: 1,
			timestamp: 1693585151308,

			score: {
				score: 1,
				perfectScore: 1,
			},
			requestId: '171bcd76-e15f-428c-8f3d-61bb7c19963e',
			answerNumber: 1,
		},
		{
			answer: {
				type: 'MULTIPLE_SELECT',
				value: ['CHOICE_1_ID', 'CHOICE_0_ID', 'CHOICE_2_ID'],
			},
			questionId: 'QUESTION_0_ID',
			questionVersion: 1,
			timestamp: 1693585165119,

			score: {
				score: 0.75,
				perfectScore: 1,
			},
			requestId: 'b81e2bfd-4fbb-4761-885a-f964037b0a8e',
			answerNumber: 1,
		},
	],
	averageHealth: 84.39788139663914,
}
export const STUDENT_ANALYTICS_13: StudentAnalytics = {
	studentPoints: {
		DECKS: 0,
		COMMUNICATION: 0,
		TRANSPORTER: 0,
		THRUSTERS: 116,
		POWER: 0,
		DEFENSE: 150,
		TRACTOR_BEAM: 0,
		QUESTIONS: 0,
		SENSOR: 750,
		CREATIVE_CANVAS: 0,
	},
	skills: {
		criticalThinking: {
			scannedData: 0,
			scanOrder: 0,
		},
		collaboration: {
			socialHealthScore: 0.73,
		},
	},
	numTimesHealthHitZero: 1,
	disposition: {
		initiative: {
			alertResponse: {
				time: 14426,
				score: 1.16,
			},
			scannedDataResponse: {
				time: 696495.3333333334,
				score: 0,
			},
			stationResponse: {
				time: 8110,
				score: 1.038,
			},
			mapParticipation: {
				percentActive: 0.03,
				score: 0.04,
			},
			mapVotePercentage: {
				percentage: 1,
				outOf: 3,
				score: 1,
			},
		},
		grit: {
			gritAfterNegativeEvents: {
				APMDetails: [
					{
						APMInDuration: 2.5,
						eventType: 'QUESTION_ANSWERED',
						actionTime: new Date('2023-09-01T16:18:59.132Z'),

						score: 1.8904166666666669,
					},
					{
						APMInDuration: 1.5,
						eventType: 'QUESTION_ANSWERED',
						actionTime: new Date('2023-09-01T16:19:10.107Z'),

						score: 1.1342500000000002,
					},
					{
						APMInDuration: 1,
						eventType: 'QUESTION_ANSWERED',
						actionTime: new Date('2023-09-01T16:19:23.317Z'),

						score: 0.7561666666666668,
					},
				],
				averageAPM: 1.322459775181838,
				score: 1.2602777777777778,
			},
		},
	},
	culminatingMomentAnswers: [
		{
			answer: {
				type: 'MULTIPLE_CHOICE',
				value: '63ecacfe3242ae001e233b88',
			},
			questionId: '63ecacfe3242ae001e233b85',
			questionVersion: 1,
			requestId: '1',
			answerNumber: 1,
			score: {
				score: 1,
				perfectScore: 1,
			},
			timestamp: 1693585033333,
		},
	],
	knowledge: {
		application: 1,
		questions: 0.25,
	},
	questionAnswers: [
		{
			answer: {
				type: 'MULTIPLE_CHOICE',
				value: 'CHOICE_8_ID',
			},
			questionId: 'QUESTION_2_ID',
			questionVersion: 1,
			timestamp: 1693585139132,

			score: {
				score: 0,
				perfectScore: 1,
			},
			requestId: '92b596cc-ac2b-4880-af59-97db962f1169',
			answerNumber: 1,
		},
		{
			answer: {
				type: 'MULTIPLE_CHOICE',
				value: 'CHOICE_7_ID',
			},
			questionId: 'QUESTION_1_ID',
			questionVersion: 1,
			timestamp: 1693585150107,

			score: {
				score: 0,
				perfectScore: 1,
			},
			requestId: 'ba9c6a37-227f-4468-89dc-9c9c4513f9e9',
			answerNumber: 1,
		},
		{
			answer: {
				type: 'MULTIPLE_SELECT',
				value: ['CHOICE_1_ID', 'CHOICE_0_ID', 'CHOICE_2_ID'],
			},
			questionId: 'QUESTION_0_ID',
			questionVersion: 1,
			timestamp: 1693585163317,

			score: {
				score: 0.75,
				perfectScore: 1,
			},
			requestId: '71a6550a-97e9-4cfa-b2ce-7bb8b65a1637',
			answerNumber: 1,
		},
	],
	averageHealth: 82.27863200656671,
}
export const STUDENT_ANALYTICS_14: StudentAnalytics = {
	studentPoints: {
		DECKS: 0,
		COMMUNICATION: 0,
		TRANSPORTER: 0,
		THRUSTERS: 152,
		POWER: 0,
		DEFENSE: 0,
		TRACTOR_BEAM: 0,
		QUESTIONS: 0,
		SENSOR: 150,
		CREATIVE_CANVAS: 0,
	},
	skills: {
		criticalThinking: {
			scannedData: 1,
		},
		collaboration: {
			socialHealthScore: 0.9,
		},
	},
	numTimesHealthHitZero: 1,
	disposition: {
		initiative: {
			alertResponse: {
				time: 66981,
				score: 0.737,
			},
			scannedDataResponse: {
				time: 83381.33333333333,
				score: 0.7156666666666666,
			},
			stationResponse: {
				time: 6003,
				score: 1.08,
			},
			mapParticipation: {
				percentActive: 0,
				score: 0,
			},
			mapVotePercentage: {
				percentage: 1,
				outOf: 3,
				score: 1,
			},
		},
		grit: {
			gritAfterNegativeEvents: {
				APMDetails: [
					{
						APMInDuration: 2.5,
						eventType: 'QUESTION_ANSWERED',
						actionTime: new Date('2023-09-01T16:19:21.519Z'),

						score: 0.783172619047619,
					},
					{
						APMInDuration: 2,
						eventType: 'QUESTION_ANSWERED',
						actionTime: new Date('2023-09-01T16:19:40.747Z'),

						score: 0.6265380952380952,
					},
					{
						APMInDuration: 2,
						eventType: 'QUESTION_ANSWERED',
						actionTime: new Date('2023-09-01T16:19:50.350Z'),

						score: 0.6265380952380952,
					},
				],
				averageAPM: 3.1921442849216786,
				score: 0.6787496031746031,
			},
		},
	},
	culminatingMomentAnswers: [
		{
			answer: {
				type: 'MULTIPLE_CHOICE',
				value: '63ecacfe3242ae001e233b88',
			},
			questionId: '63ecacfe3242ae001e233b85',
			questionVersion: 1,
			requestId: '1',
			answerNumber: 1,
			score: {
				score: 1,
				perfectScore: 1,
			},
			timestamp: 1693585033333,
		},
	],
	knowledge: {
		application: 1,
		questions: 0.25,
	},
	questionAnswers: [
		{
			answer: {
				type: 'MULTIPLE_CHOICE',
				value: 'CHOICE_8_ID',
			},
			questionId: 'QUESTION_2_ID',
			questionVersion: 1,
			timestamp: 1693585161519,

			score: {
				score: 0,
				perfectScore: 1,
			},
			requestId: '46574d8b-472b-4b42-842f-944178a50435',
			answerNumber: 1,
		},
		{
			answer: {
				type: 'MULTIPLE_SELECT',
				value: ['CHOICE_3_ID', 'CHOICE_0_ID', 'CHOICE_1_ID'],
			},
			questionId: 'QUESTION_0_ID',
			questionVersion: 1,
			timestamp: 1693585180747,

			score: {
				score: 0.75,
				perfectScore: 1,
			},
			requestId: 'eea7c55c-bf1c-42a1-a1a2-5a77251d4d50',
			answerNumber: 1,
		},
		{
			answer: {
				type: 'MULTIPLE_CHOICE',
				value: 'CHOICE_7_ID',
			},
			questionId: 'QUESTION_1_ID',
			questionVersion: 1,
			timestamp: 1693585190350,

			score: {
				score: 0,
				perfectScore: 1,
			},
			requestId: 'b761aa32-97d3-4b1a-82fa-55aac285ced1',
			answerNumber: 1,
		},
	],
	averageHealth: 82.88711551002106,
}
export const STUDENT_ANALYTICS_15: StudentAnalytics = {
	studentPoints: {
		DECKS: 0,
		COMMUNICATION: 0,
		TRANSPORTER: 0,
		THRUSTERS: 157,
		POWER: 0,
		DEFENSE: 0,
		TRACTOR_BEAM: 0,
		QUESTIONS: 500,
		SENSOR: 900,
		CREATIVE_CANVAS: 0,
	},
	skills: {
		criticalThinking: {
			scannedData: 1,
			scanOrder: 0,
		},
		collaboration: {
			socialHealthScore: 0.57,
		},
	},
	numTimesHealthHitZero: 1,
	disposition: {
		initiative: {
			alertResponse: {
				time: 8413,
				score: 1.26,
			},
			scannedDataResponse: {
				time: 79777.33333333333,
				score: 0.7423333333333333,
			},
			stationResponse: {
				time: 1197,
				score: 1.176,
			},
			mapParticipation: {
				percentActive: 0.07,
				score: 0.093,
			},
			mapVotePercentage: {
				percentage: 1,
				outOf: 3,
				score: 1,
			},
		},
		grit: {
			gritAfterNegativeEvents: {
				APMDetails: [
					{
						APMInDuration: 2.5,
						eventType: 'QUESTION_ANSWERED',
						actionTime: new Date('2023-09-01T16:18:58.528Z'),

						score: 1.1188180272108845,
					},
					{
						APMInDuration: 2,
						eventType: 'QUESTION_ANSWERED',
						actionTime: new Date('2023-09-01T16:19:20.316Z'),

						score: 0.8950544217687075,
					},
				],
				averageAPM: 2.2345009994451748,
				score: 1.0069362244897961,
			},
		},
	},
	culminatingMomentAnswers: [
		{
			answer: {
				type: 'MULTIPLE_CHOICE',
				value: '63ecacfe3242ae001e233b88',
			},
			questionId: '63ecacfe3242ae001e233b85',
			questionVersion: 1,
			requestId: '1',
			answerNumber: 1,
			score: {
				score: 1,
				perfectScore: 1,
			},
			timestamp: 1693585033333,
		},
	],
	knowledge: {
		application: 1,
		questions: 0.3333333333333333,
	},
	questionAnswers: [
		{
			answer: {
				type: 'MULTIPLE_CHOICE',
				value: 'CHOICE_6_ID',
			},
			questionId: 'QUESTION_1_ID',
			questionVersion: 1,
			timestamp: 1693585138528,

			score: {
				score: 0,
				perfectScore: 1,
			},
			requestId: 'd0c0ba3c-498d-4244-bfa9-dd907e8401ae',
			answerNumber: 1,
		},
		{
			answer: {
				type: 'MULTIPLE_CHOICE',
				value: 'CHOICE_9_ID',
			},
			questionId: 'QUESTION_2_ID',
			questionVersion: 1,
			timestamp: 1693585160316,

			score: {
				score: 0,
				perfectScore: 1,
			},
			requestId: '0ca9bed9-8c60-422b-be25-5f4ca886ca3f',
			answerNumber: 1,
		},
		{
			answer: {
				type: 'MULTIPLE_SELECT',
				value: ['CHOICE_0_ID', 'CHOICE_1_ID', 'CHOICE_2_ID', 'CHOICE_3_ID'],
			},
			questionId: 'QUESTION_0_ID',
			questionVersion: 1,
			timestamp: 1693585178944,

			score: {
				score: 1,
				perfectScore: 1,
			},
			requestId: '80b4c076-0888-42c8-b371-04e125becb06',
			answerNumber: 1,
		},
	],
	averageHealth: 84.346404269873,
}
export const STUDENT_ANALYTICS_16: StudentAnalytics = {
	studentPoints: {
		DECKS: 0,
		COMMUNICATION: 0,
		TRANSPORTER: 0,
		THRUSTERS: 57,
		POWER: 0,
		DEFENSE: 150,
		TRACTOR_BEAM: 0,
		QUESTIONS: 0,
		SENSOR: 900,
		CREATIVE_CANVAS: 0,
	},
	skills: {
		criticalThinking: {
			scannedData: 1,
			scanOrder: 0.6666666666666667,
		},
		collaboration: {
			socialHealthScore: 0.83,
		},
	},
	numTimesHealthHitZero: 1,
	disposition: {
		initiative: {
			alertResponse: {
				time: 16216,
				score: 1.13,
			},
			scannedDataResponse: {
				time: 16109,
				score: 1.2316666666666667,
			},
			stationResponse: {
				time: 3002,
				score: 1.14,
			},
			mapParticipation: {
				percentActive: 0.06,
				score: 0.08,
			},
			mapVotePercentage: {
				percentage: 1,
				outOf: 3,
				score: 1,
			},
		},
		grit: {
			gritAfterNegativeEvents: {
				APMDetails: [
					{
						APMInDuration: 3.5,
						eventType: 'QUESTION_ANSWERED',
						actionTime: new Date('2023-09-01T16:19:24.821Z'),

						score: 1.5049199346405229,
					},
					{
						APMInDuration: 2.5,
						eventType: 'QUESTION_ANSWERED',
						actionTime: new Date('2023-09-01T16:19:33.838Z'),

						score: 1.0749428104575163,
					},
					{
						APMInDuration: 2.5,
						eventType: 'QUESTION_ANSWERED',
						actionTime: new Date('2023-09-01T16:20:04.037Z'),

						score: 1.0749428104575163,
					},
				],
				averageAPM: 2.3257051218715086,
				score: 1.2182685185185185,
			},
		},
	},
	culminatingMomentAnswers: [
		{
			answer: {
				type: 'MULTIPLE_CHOICE',
				value: '63ecacfe3242ae001e233b88',
			},
			questionId: '63ecacfe3242ae001e233b85',
			questionVersion: 1,
			requestId: '1',
			answerNumber: 1,
			score: {
				score: 1,
				perfectScore: 1,
			},
			timestamp: 1693585033333,
		},
	],
	knowledge: {
		application: 1,
		questions: 0.16666666666,
	},
	questionAnswers: [
		{
			answer: {
				type: 'MULTIPLE_SELECT',
				value: ['CHOICE_1_ID', 'CHOICE_0_ID'],
			},
			questionId: 'QUESTION_0_ID',
			questionVersion: 1,
			timestamp: 1693585164821,

			score: {
				score: 0.5,
				perfectScore: 1,
			},
			requestId: '3c81ea2e-a98d-4bcd-974b-23e31aa22bf9',
			answerNumber: 1,
		},
		{
			answer: {
				type: 'MULTIPLE_CHOICE',
				value: 'CHOICE_9_ID',
			},
			questionId: 'QUESTION_2_ID',
			questionVersion: 1,
			timestamp: 1693585173838,

			score: {
				score: 0,
				perfectScore: 1,
			},
			requestId: '0ed7bc08-503f-4613-9d75-31f8f5e492b9',
			answerNumber: 1,
		},
		{
			answer: {
				type: 'MULTIPLE_CHOICE',
				value: 'CHOICE_6_ID',
			},
			questionId: 'QUESTION_1_ID',
			questionVersion: 1,
			timestamp: 1693585204037,

			score: {
				score: 0,
				perfectScore: 1,
			},
			requestId: '480e4bb8-6cf2-4fb6-af8f-cd746c12dd0d',
			answerNumber: 1,
		},
	],
	averageHealth: 82.96372697285919,
}
export const STUDENT_ANALYTICS_17: StudentAnalytics = {
	studentPoints: {
		DECKS: 250,
		COMMUNICATION: 0,
		TRANSPORTER: 0,
		THRUSTERS: 138,
		POWER: 0,
		DEFENSE: 0,
		TRACTOR_BEAM: 0,
		QUESTIONS: 500,
		SENSOR: 150,
		CREATIVE_CANVAS: 0,
	},
	skills: {
		criticalThinking: {
			scannedData: 1,
		},
		collaboration: {
			engineerPanelScore: {
				time: 108314,
				score: 0.752,
			},
		},
	},
	numTimesHealthHitZero: 1,
	disposition: {
		initiative: {
			alertResponse: {
				time: 10810,
				score: 1.22,
			},
			scannedDataResponse: {
				time: 58888.666666666664,
				score: 0.864,
			},
			stationResponse: {
				time: 4503,
				score: 1.11,
			},
			mapParticipation: {
				percentActive: 0,
				score: 0,
			},
			mapVotePercentage: {
				percentage: 1,
				outOf: 3,
				score: 1,
			},
		},
		grit: {
			gritAfterNegativeEvents: {
				APMDetails: [
					{
						APMInDuration: 3,
						eventType: 'QUESTION_ANSWERED',
						actionTime: new Date('2023-09-01T16:19:18.515Z'),

						score: 1.0784672131147541,
					},
					{
						APMInDuration: 3,
						eventType: 'QUESTION_ANSWERED',
						actionTime: new Date('2023-09-01T16:19:32.630Z'),

						score: 1.0784672131147541,
					},
				],
				averageAPM: 2.781725734003177,
				score: 1.0784672131147541,
			},
		},
	},
	culminatingMomentAnswers: [
		{
			answer: {
				type: 'MULTIPLE_CHOICE',
				value: '63ecacfe3242ae001e233b88',
			},
			questionId: '63ecacfe3242ae001e233b85',
			questionVersion: 1,
			requestId: '1',
			answerNumber: 1,
			score: {
				score: 1,
				perfectScore: 1,
			},
			timestamp: 1693585033333,
		},
	],
	knowledge: {
		application: 1,
		questions: 0.5,
	},
	questionAnswers: [
		{
			answer: {
				type: 'MULTIPLE_SELECT',
				value: ['CHOICE_2_ID', 'CHOICE_0_ID'],
			},
			questionId: 'QUESTION_0_ID',
			questionVersion: 1,
			timestamp: 1693585158515,

			score: {
				score: 0.5,
				perfectScore: 1,
			},
			requestId: '042da1a4-5abb-45f8-9940-e69133526f27',
			answerNumber: 1,
		},
		{
			answer: {
				type: 'MULTIPLE_CHOICE',
				value: 'CHOICE_11_ID',
			},
			questionId: 'QUESTION_2_ID',
			questionVersion: 1,
			timestamp: 1693585172630,

			score: {
				score: 0,
				perfectScore: 1,
			},
			requestId: '65182510-ea89-435f-9fd5-7248da6878fa',
			answerNumber: 1,
		},
		{
			answer: {
				type: 'MULTIPLE_CHOICE',
				value: 'CHOICE_5_ID',
			},
			questionId: 'QUESTION_1_ID',
			questionVersion: 1,
			timestamp: 1693585182846,

			score: {
				score: 1,
				perfectScore: 1,
			},
			requestId: '507f576f-4b48-4d7d-b135-1e49c354a4a1',
			answerNumber: 1,
		},
	],
	averageHealth: 84.59190296641408,
}
export const STUDENT_ANALYTICS_18: StudentAnalytics = {
	studentPoints: {
		DECKS: 0,
		COMMUNICATION: 0,
		TRANSPORTER: 0,
		THRUSTERS: 139,
		POWER: 0,
		DEFENSE: 0,
		TRACTOR_BEAM: 0,
		QUESTIONS: 500,
		SENSOR: 150,
		CREATIVE_CANVAS: 0,
	},
	skills: {
		criticalThinking: {
			scannedData: 1,
		},
		collaboration: {
			socialHealthScore: 0.6,
		},
	},
	numTimesHealthHitZero: 1,
	disposition: {
		initiative: {
			alertResponse: {
				time: 19818,
				score: 1.0699999999999998,
			},
			scannedDataResponse: {
				time: 91389,
				score: 0.6563333333333333,
			},
			stationResponse: {
				time: 12915,
				score: 1,
			},
			mapParticipation: {
				percentActive: 0,
				score: 0,
			},
			mapVotePercentage: {
				percentage: 1,
				outOf: 3,
				score: 1,
			},
		},
		grit: {
			gritAfterNegativeEvents: {
				APMDetails: [
					{
						APMInDuration: 2.5,
						eventType: 'QUESTION_ANSWERED',
						actionTime: new Date('2023-09-01T16:19:12.509Z'),

						score: 1.481677927927928,
					},
					{
						APMInDuration: 1.5,
						eventType: 'QUESTION_ANSWERED',
						actionTime: new Date('2023-09-01T16:19:29.022Z'),

						score: 0.8890067567567568,
					},
				],
				averageAPM: 1.6872762648871729,
				score: 1.1853423423423424,
			},
		},
	},
	totalHealthTasks: 1,
	culminatingMomentAnswers: [
		{
			answer: {
				type: 'MULTIPLE_CHOICE',
				value: '63ecacfe3242ae001e233b88',
			},
			questionId: '63ecacfe3242ae001e233b85',
			questionVersion: 1,
			requestId: '1',
			answerNumber: 1,
			score: {
				score: 1,
				perfectScore: 1,
			},
			timestamp: 1693585033333,
		},
	],
	knowledge: {
		application: 1,
		questions: 0.3333333333333333,
	},
	questionAnswers: [
		{
			answer: {
				type: 'MULTIPLE_CHOICE',
				value: 'CHOICE_11_ID',
			},
			questionId: 'QUESTION_2_ID',
			questionVersion: 1,
			timestamp: 1693585152509,

			score: {
				score: 0,
				perfectScore: 1,
			},
			requestId: '7f4886bd-2a22-4c35-9081-d072192e67d7',
			answerNumber: 1,
		},
		{
			answer: {
				type: 'MULTIPLE_CHOICE',
				value: 'CHOICE_6_ID',
			},
			questionId: 'QUESTION_1_ID',
			questionVersion: 1,
			timestamp: 1693585169022,

			score: {
				score: 0,
				perfectScore: 1,
			},
			requestId: '69b4ef03-5309-4294-a1d8-e5cb1b1855df',
			answerNumber: 1,
		},
		{
			answer: {
				type: 'MULTIPLE_SELECT',
				value: ['CHOICE_0_ID', 'CHOICE_2_ID', 'CHOICE_3_ID', 'CHOICE_1_ID'],
			},
			questionId: 'QUESTION_0_ID',
			questionVersion: 1,
			timestamp: 1693585186756,

			score: {
				score: 1,
				perfectScore: 1,
			},
			requestId: '363d656f-9531-44d3-bb4e-063d0074a83b',
			answerNumber: 1,
		},
	],
	averageHealth: 90.88933533475713,
}
export const STUDENT_ANALYTICS_19: StudentAnalytics = {
	studentPoints: {
		DECKS: 0,
		COMMUNICATION: 0,
		TRANSPORTER: 0,
		THRUSTERS: 19,
		POWER: 0,
		DEFENSE: 0,
		TRACTOR_BEAM: 0,
		QUESTIONS: 500,
		SENSOR: 150,
		CREATIVE_CANVAS: 0,
	},
	skills: {
		criticalThinking: {
			scannedData: 1,
		},
		collaboration: {
			socialHealthScore: 0.83,
		},
	},
	numTimesHealthHitZero: 1,
	disposition: {
		initiative: {
			alertResponse: {
				time: 62774,
				score: 0.768,
			},
			scannedDataResponse: {
				time: 97496,
				score: 0.6110000000000001,
			},
			stationResponse: {
				time: 6908,
				score: 1.062,
			},
			mapParticipation: {
				percentActive: 0,
				score: 0,
			},
			mapVotePercentage: {
				percentage: 1,
				outOf: 3,
				score: 1,
			},
		},
		grit: {
			gritAfterNegativeEvents: {
				APMDetails: [
					{
						APMInDuration: 3,
						eventType: 'QUESTION_ANSWERED',
						actionTime: new Date('2023-09-01T16:19:31.431Z'),

						score: 1.7312236842105262,
					},
					{
						APMInDuration: 2.5,
						eventType: 'QUESTION_ANSWERED',
						actionTime: new Date('2023-09-01T16:19:41.645Z'),

						score: 1.4426864035087719,
					},
				],
				averageAPM: 1.7328783261003398,
				score: 1.586955043859649,
			},
		},
	},
	culminatingMomentAnswers: [
		{
			answer: {
				type: 'MULTIPLE_CHOICE',
				value: '63ecacfe3242ae001e233b88',
			},
			questionId: '63ecacfe3242ae001e233b85',
			questionVersion: 1,
			requestId: '1',
			answerNumber: 1,
			score: {
				score: 1,
				perfectScore: 1,
			},
			timestamp: 1693585033333,
		},
	],
	knowledge: {
		application: 1,
		questions: 0.3333333333333333,
	},
	questionAnswers: [
		{
			answer: {
				type: 'MULTIPLE_CHOICE',
				value: 'CHOICE_6_ID',
			},
			questionId: 'QUESTION_1_ID',
			questionVersion: 1,
			timestamp: 1693585171431,

			score: {
				score: 0,
				perfectScore: 1,
			},
			requestId: 'aa35bf51-14cf-452b-bc98-87a6b81b961a',
			answerNumber: 1,
		},
		{
			answer: {
				type: 'MULTIPLE_CHOICE',
				value: 'CHOICE_9_ID',
			},
			questionId: 'QUESTION_2_ID',
			questionVersion: 1,
			timestamp: 1693585181645,

			score: {
				score: 0,
				perfectScore: 1,
			},
			requestId: '7acb7486-7d86-48cc-8570-0bfb8666694f',
			answerNumber: 1,
		},
		{
			answer: {
				type: 'MULTIPLE_SELECT',
				value: ['CHOICE_0_ID', 'CHOICE_1_ID', 'CHOICE_2_ID', 'CHOICE_3_ID'],
			},
			questionId: 'QUESTION_0_ID',
			questionVersion: 1,
			timestamp: 1693585195753,

			score: {
				score: 1,
				perfectScore: 1,
			},
			requestId: 'e5af3403-9651-4c29-8972-305d7049dc5f',
			answerNumber: 1,
		},
	],
	averageHealth: 84.75858610049175,
}
export const STUDENT_ANALYTICS_20: StudentAnalytics = {
	studentPoints: {
		DECKS: 0,
		COMMUNICATION: 0,
		TRANSPORTER: 0,
		THRUSTERS: 159,
		POWER: 0,
		DEFENSE: 0,
		TRACTOR_BEAM: 0,
		QUESTIONS: 1000,
		SENSOR: 400,
		CREATIVE_CANVAS: 0,
	},
	skills: {
		criticalThinking: {
			scannedData: 1,
			scanOrder: 1,
		},
		collaboration: {
			socialHealthScore: 0.61,
		},
	},
	numTimesHealthHitZero: 1,
	disposition: {
		initiative: {
			alertResponse: {
				time: 9910,
				score: 1.2349999999999999,
			},
			scannedDataResponse: {
				time: 55488.333333333336,
				score: 0.8993333333333333,
			},
			stationResponse: {
				time: 897,
				score: 1.182,
			},
			mapParticipation: {
				percentActive: 0.04,
				score: 0.053,
			},
			mapVotePercentage: {
				percentage: 1,
				outOf: 3,
				score: 1,
			},
		},
		grit: {
			gritAfterNegativeEvents: {
				APMDetails: [
					{
						APMInDuration: 4.5,
						eventType: 'QUESTION_ANSWERED',
						actionTime: new Date('2023-09-01T16:19:23.319Z'),

						score: 2,
					},
				],
				averageAPM: 2.1432968770188414,
				score: 2,
			},
		},
	},
	culminatingMomentAnswers: [
		{
			answer: {
				type: 'MULTIPLE_CHOICE',
				value: '63ecacfe3242ae001e233b88',
			},
			questionId: '63ecacfe3242ae001e233b85',
			questionVersion: 1,
			requestId: '1',
			answerNumber: 1,
			score: {
				score: 1,
				perfectScore: 1,
			},
			timestamp: 1693585033333,
		},
	],
	knowledge: {
		application: 1,
		questions: 0.833333333,
	},
	questionAnswers: [
		{
			answer: {
				type: 'MULTIPLE_SELECT',
				value: ['CHOICE_1_ID', 'CHOICE_0_ID'],
			},
			questionId: 'QUESTION_0_ID',
			questionVersion: 1,
			timestamp: 1693585163319,

			score: {
				score: 0.5,
				perfectScore: 1,
			},
			requestId: 'b3cb3544-57c4-4e2f-9b96-9193cfea7ff1',
			answerNumber: 1,
		},
		{
			answer: {
				type: 'MULTIPLE_CHOICE',
				value: 'CHOICE_10_ID',
			},
			questionId: 'QUESTION_2_ID',
			questionVersion: 1,
			timestamp: 1693585195453,

			score: {
				score: 1,
				perfectScore: 1,
			},
			requestId: '57d9ffab-ee08-42b8-a659-afcf3a53fa62',
			answerNumber: 1,
		},
		{
			answer: {
				type: 'MULTIPLE_CHOICE',
				value: 'CHOICE_5_ID',
			},
			questionId: 'QUESTION_1_ID',
			questionVersion: 1,
			timestamp: 1693585208021,

			score: {
				score: 1,
				perfectScore: 1,
			},
			requestId: '96d3348e-e19d-4903-8281-25ad6d8e07f6',
			answerNumber: 1,
		},
	],
	averageHealth: 86.55240398105995,
}
