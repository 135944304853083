type LicenseUser = {
	_id: string,
	firstName?: string,
	lastName?: string,
	schoolId?: string,
	districtId?: string,
	...
}

export type Permissions = {
	physicalClassroom?: boolean,
	simulationGroups?: Array<string>,
}

export const PROMOTION_TYPES = {
	MANUAL: 'MANUAL',
	FREE_ACCOUNT_CREATION_PROMOTION: 'FREE_ACCOUNT_CREATION_PROMOTION',
}

export type Promotion = {|
	clientMessage: string,
	expirationDate: number,
	permissions: Permissions,
	type: $Keys<typeof PROMOTION_TYPES>,
|}

export type LicenseOwner =
	| {
			type: 'DISTRICT',
			district: {},
	  }
	| { type: 'SCHOOL', school: {} }
	| { type: 'SINGLE_OWNER', user: LicenseUser }
	| { type: 'FREE', user: LicenseUser }

export type UserLicensePermissions = {|
	userPermissions: Permissions,
	baseUserPermissions: Permissions,
	ownerObject: LicenseOwner,
	expirationDate: Date,
	licenseId: string,
	licenseDescription: string,
	promotions: Array<Promotion>,
|}

export type IdMap<T> = { [id: string]: T }
