import type { GradeOption } from './types'
import NetworkCommunicator from '../../services/NetworkCommunicator'
import config from '../../config'
import type { UpdateClass } from '../../services/hooks/classes'
import type { Student_New, NewStudent } from '../../models/Student'

/**
 * Takes in the GradeOption array from a class, and sorts them by grade.
 * The if statements are the hard coded exceptions.
 */
export function sortClassGrades(array: GradeOption[]): GradeOption[] {
	let newArray = array
	newArray = newArray.sort(function(a, b) {
		if (a.value === 'K') return -1
		if (b.value === 'K') return 1
		if (a.value === 'other') return 1
		if (b.value === 'other') return -1

		return Number(a.value) - Number(b.value)
	})
	return newArray
}

/**
 * Takes in a schoolId and returns a promise which will result in the name of that school.
 * @param {string} schoolId
 * @returns {Promise<?string>}
 */
export function getSchoolName(schoolId?: string): ?Promise<?string> {
	if (!schoolId) {
		return null
	}
	return NetworkCommunicator.GET(`/api/schools/${schoolId}`, { host: config.loginServer }).then(
		result => {
			return result.school?.name
		}
	)
}

/**
 * Gets a validation error message for the provided `_class`. If there is no validation
 * error, returns null.
 * @param {UpdateClass} _class The class to save
 * @return {string | null} null if the class is valid. Otherwise, an error message to display to the user.
 */
export function getClassValidationErrorMessage(_class: UpdateClass): string | null {
	if (!classGradesAreValid(_class.grades)) {
		return 'Please add a class grade.'
	}

	if (!classNameIsValid(_class.name)) {
		return 'Please add a class name.'
	}

	if (_class.students.length === 0) {
		return 'There must be at least one student with a name in your class.'
	}

	if (_class.students.some(student => !student.firstName)) {
		return 'All students must have a first name.'
	}

	return null
}

/**
 * Returns whether the given grades array is valid for a class.
 * @param {GradeEnum[]} grades The grades to validate
 * @return {boolean} true if the grades array is valid, otherwise false
 */
export function classGradesAreValid(grades: $ReadOnlyArray<mixed>): boolean {
	return grades.length > 0
}

/**
 * Returns whether the given class name is valid for a class.
 * @param {string} className The class name to validate
 * @return {boolean} true if the class name is valid, otherwise false
 */
export function classNameIsValid(className: string): boolean {
	return !!className
}

/**
 * Returns an array that contains all non empty students from the provided `students` array.
 * Any student with neither a first nor last name will be removed
 * @param {Array<Student_New | NewStudent>} students The students array
 * @return {Array<Student_New | NewStudent>} The students array with no empty students
 */
export function getNonEmptyStudents(
	students: $ReadOnlyArray<Student_New | NewStudent>
): $ReadOnlyArray<Student_New | NewStudent> {
	return students.filter(student => student.firstName || student.lastName)
}
