// @flow
import React, { useCallback, useMemo } from 'react'
import styled from 'styled-components'
import config from '../../config'
import { useQuery, useQueryClient } from 'react-query'
import { useShowFileInFileViewer } from '../../components/FileViewer/FileViewerContext'
import { awsDataToFolderMap, getFilesInCurrentFolder } from '../../utility/FileHelpers'
import LoadingSpinner from '../../styles/LoadingSpinner'
import FaCloudDownload from 'react-icons/lib/fa/cloud-download'
import type { UseQueryResult } from 'react-query'
import { useShowFolderMapInFileBrowser } from '../../components/FileBrowser/FileBrowserContext'
import NetworkCommunicator from '../../services/NetworkCommunicator'
import { USER_QUERY_KEY } from '../../services/hooks/user'
import type { File } from '../../utility/FileHelpers'
import { FileGrid } from '../../components/FileBrowser/Components'
import axios from 'axios'

const TEACHER_RESOURCES_ROUTE_PREFIX = 'teacher resources/'

export default function Downloads(): React$Node {
	const setFileToView = useShowFileInFileViewer()
	const showFileBrowser = useShowFolderMapInFileBrowser()
	const { data: files, isLoading, error } = useTeacherResources()

	const folderMap = useMemo(() => {
		if (!files) {
			return null
		}
		return awsDataToFolderMap(files, TEACHER_RESOURCES_ROUTE_PREFIX)
	}, [files])

	const onFolderClick = (directory: string) => {
		if (folderMap) {
			showFileBrowser({
				folderMap,
				startingFolder: directory,
			})
		}
	}

	const onFileClick = (file: File) => {
		setFileToView({
			src: file.id,
			preview: file.filePreviewSrc,
			name: file.actualName ?? file.name,
		})
	}

	const filesInCurrentPath = useMemo(() => {
		if (!folderMap) {
			return null
		}

		return getFilesInCurrentFolder(folderMap, '/' + TEACHER_RESOURCES_ROUTE_PREFIX) // get the root
	}, [folderMap])

	return (
		<div className="py-4">
			<h2 className="text-3xl">Downloads</h2>
			<hr className="my-3" />
			{error ? (
				<StyledError>
					An error occurred while loading downloadable resources: {error.message}
				</StyledError>
			) : isLoading ? (
				<LoaderWrapper>
					<LoadingSpinner shouldShowSpinner={true} /> Loading downloadable resources
				</LoaderWrapper>
			) : !filesInCurrentPath ? (
				<LoaderWrapper>No downloadable resources available.</LoaderWrapper>
			) : (
				<StyledFileBrowser aria-label="teacher resource browser">
					<FileGrid
						files={filesInCurrentPath}
						onFolderClick={onFolderClick}
						onFileClick={onFileClick}
					/>
				</StyledFileBrowser>
			)}
		</div>
	)
}

/**
 * A button to click and download launchpad
 * @param {string} title = 'Download' the text to display on the download button
 */
export function DownloadLaunchpadButton({ title = 'Download' }: { title?: string }): React$Node {
	const queryCache = useQueryClient()

	let launchpadDownloadUrl = config.loginServer + '/api/launchpad/download'
	if (config.OSName === 'MacOS') launchpadDownloadUrl += '?dmg=true'
	const newLaunchpadDownloadUrl =
		launchpadDownloadUrl + (launchpadDownloadUrl.includes('?') ? '&' : '?') + 'new=true'

	/**
	 * @function addLaunchpadMark adds isLaunchpadDownloaded mark to the current user database
	 */
	const addLaunchpadMark = useCallback(() => {
		NetworkCommunicator.PUT(`/api/users/set-onboarding-item/`, {
			host: config.missionsAPIURL,
			body: { onboardingItem: 'launchpadMark' },
		}).then(({ user }) => {
			queryCache.invalidateQueries(USER_QUERY_KEY)
		})
	}, [queryCache])

	return (
		<a
			className="btn btn-secondary"
			onClick={() => addLaunchpadMark()}
			href={newLaunchpadDownloadUrl}
			download="Launchpad"
			target="_blank"
			title={title}
			rel="noopener noreferrer">
			{title} <FaCloudDownload />
		</a>
	)
}

/**
 * useTeacherResources - get all the files in teacher-resources
 *
 * @return {UseQueryResult<{
 *    files: string[], - the file path relative to the `host` (example: '/path/to/file.js')
 *    host: string , - the host to prepend to the file to get the full url (example 'https://resources-cdn.mission.io/')
 *    prefixPath: string - the prefix search string for the bucket files (example: 'public/teacher-resources/downloads')
 * }>}
 */
function useTeacherResources(): UseQueryResult<
	{
		files: string[],
		host: string,
		prefixPath: string,
	},
	Error
> {
	return useQuery('teacher-resources', () =>
		axios
			.get(`${config.loginServer}/api/teacher-resources`, {
				withCredentials: true,
			})
			.then(res => {
				return res.data.fileData
			})
	)
}

const StyledFileBrowser = styled.div`
	height: 400px;

	& .MuiBox-root {
		border-color: Transparent;
	}
`

const StyledError = styled.div`
	text-align: center;
`

const LoaderWrapper = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	gap: var(--spacing1x-dont-use);
`
