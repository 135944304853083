// @flow
import React, { Component } from 'react'
import type { Student } from '../../../../../../models'
import type { Student_New } from '../../../../../../models/Student'
import { DragSource } from 'react-dnd'
import type { DragSourceProps } from '../../../../../../types'

type BaseProps = {
	student: Student_New,
	container: string,
	moveStudent?: (Student, boolean) => void,
}

type Props = {
	...BaseProps,
	...DragSourceProps,
}

const studentSource = {
	beginDrag(props: Props) {
		if (props.move) {
			props.move()
		}
		return props.student
	},
	canDrag(props) {
		const { container, student } = props
		return !(container === 'wordBank' && student.station)
	},
	endDrag(props, monitor, component) {
		if (props.moveStudent) {
			props.moveStudent(props.student, monitor.didDrop())
		}
	},
}

function collect(connect, monitor) {
	return {
		connectDragSource: connect.dragSource(),
		isDragging: monitor.isDragging(),
	}
}

class StudentComponent extends Component<Props> {
	render(): React$Node {
		const { connectDragSource, isDropped, isDragging, student } = this.props
		let studentTitle = student.firstName + ' ' + student.lastName

		return connectDragSource(
			<div
				style={{
					opacity: isDragging ? 0.5 : 1,
					cursor: 'move',
					fontWeight: 'bold',
					width: 'fit-content',
					margin: '3px',
				}}>
				{isDropped ? null : (
					<div className="inline-block p-2 font-medium text-sm rounded text-primary-950 bg-primary-50 shadow-md hover:bg-gray-b">
						{studentTitle}
					</div>
				)}
			</div>
		)
	}
}

export default (DragSource('student', studentSource, collect)(StudentComponent): (
	props: BaseProps
) => React$Node)
