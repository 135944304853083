// @flow
import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { Loading, Filters } from '../../components'
import SimulationTile from '../../components/helpers/SimulationTile'
import { PageTitleHeader, Spinner, SpinnerHolder } from '../../styles/sharedComponents'
import { ALL, INITIAL_GRADE_FILTER } from '../../components/helpers/Filters/Filters'
import { GRADE_ARRAY } from '../../store/missionPrep'
import styled from 'styled-components/macro'
import {
	useCategoryId,
	getSimulationCategories,
	useTrainingCategoryId,
} from '../../store/categories'
import { useUserSimulationPermissions } from '../../services/hooks/permissions'
import { useUserStandardState } from './../../services/hooks/user'
import { getSimulationGradesForState } from './../../utility/helpers'

import { Button } from '@mission.io/styles'
import { difference } from 'lodash'
import { Helmet } from 'react-helmet'

import type { AutomatedSimulation } from '../../models'
import type { ReduxStore } from '../../store/rootReducer'
import { InputSearch } from '../../components/helpers/Filters/InputSearch'
import type { Category } from '../../store/categories'
import { useQueryParam, StringParam, DelimitedArrayParam, withDefault } from 'use-query-params'
import type { SelectOption } from '../analytics/analyticsHelpers'
import { useStandardSets } from '../../services/hooks/standards'
import { AUTOMATED_SIMULATION_STATUSES } from '../../constants'

const trainingKey = 'Station Trainings'
const featuredKey = 'Featured Missions'
const otherKey = 'Other Missions'
const betaKey = 'BETA Missions'

const Wrapper = styled.div`
	padding: 0 var(--spacing2x-dont-use);
`

const FiltersWrapper = styled.div`
	.category-wrapper {
		grid-area: category-area;
	}
	.grade-wrapper {
		grid-area: grade-area;
	}
	.clear-button {
		grid-area: button-area;
	}

	display: grid;
	gap: var(--spacing1x-dont-use);
	margin: 0 0 var(--spacing4x-dont-use) 0;
	grid-template-areas: 'category-area grade-area button-area input-area';
	align-items: end;

	@media (max-width: 1024px) {
		grid-template-areas: 'category-area' 'grade-area' 'input-area' 'button-area';
	}
`

const SimulationWrapper = styled.div`
	display: flex;
	flex-flow: row wrap;
	margin: auto;
`

// this prop needed for passed in styled components
type Props = { className?: string }

const NameStringParam = withDefault(StringParam, '')
const GradeStringParam = withDefault(StringParam, INITIAL_GRADE_FILTER)
const DelimitedArrayParamWithDefault = withDefault(DelimitedArrayParam, [])

export default function MissionLibrary({ className }: Props): React$Node {
	const [nameFilter, setNameFilter] = useQueryParam('name', NameStringParam, {
		updateType: 'replaceIn',
		removeDefaultsFromUrl: true,
	})
	const [gradeFilter, setGradeFilter] = useQueryParam('grade', GradeStringParam, {
		updateType: 'replaceIn',
		removeDefaultsFromUrl: true,
	})
	const [_categoryFilter, _setCategoryFilter]: [
		string[],
		(newCategoryFilter: string[], insertType?: string) => mixed
	] = useQueryParam('category', DelimitedArrayParamWithDefault, {
		updateType: 'replaceIn',
		removeDefaultsFromUrl: true,
	})
	const categories = useSelector(getSimulationCategories)
	const setCategoryFilter = (categories: SelectOption[]) => {
		_setCategoryFilter(categories.map(category => category.value))
	}
	const categoryFilter: Array<SelectOption> = (_categoryFilter || [])
		.map(categoryId => {
			const category = categories?.find(category => category._id === categoryId)

			return category ? { value: categoryId, label: category.name } : null
		})
		.filter(Boolean)

	const {
		permissions: userSimulationPermissions,
		isLoading: isLoadingPermissions,
		error: permissionsError,
	} = useUserSimulationPermissions()

	const { data: standardSets, isLoading: isLoadingStandardSets } = useStandardSets()
	const _simulations: ?Array<AutomatedSimulation> = useSelector(
		(state: ReduxStore) => state.simulations.automatedSimulations
	)

	const canRun = userSimulationPermissions?.canRun || new Set()

	const standardState = useUserStandardState()
	const simulations = useMemo(() => {
		if (!userSimulationPermissions || !_simulations) {
			return
		}
		return _simulations
			.filter(({ _id }) => userSimulationPermissions.canView.has(_id))
			.map(simulation => ({
				...simulation,
				grades: getSimulationGradesForState(simulation, standardState, standardSets || []),
			}))
	}, [userSimulationPermissions, _simulations, standardState, standardSets])

	const isFetchingCategories = useSelector((state: ReduxStore) => state.categories.fetching)
	const trainingId: ?string = useTrainingCategoryId()
	const featuredCategoryId: ?string = useCategoryId('Featured')
	const betaCategoryId: ?string = useCategoryId('Beta')

	const categoriesWithSections = []
	if (trainingId) {
		categoriesWithSections.push({
			categoryId: trainingId,
			key: trainingKey,
		})
	}
	if (featuredCategoryId) {
		categoriesWithSections.push({
			categoryId: featuredCategoryId,
			key: featuredKey,
		})
	}
	if (betaCategoryId) {
		categoriesWithSections.push({
			categoryId: betaCategoryId,
			simulationStatus: AUTOMATED_SIMULATION_STATUSES.BETA,
			key: betaKey,
		})
	}

	const unusedCategories = useMemo(
		() => getUnusedCategories(simulations || [], categories || []),
		[simulations, categories]
	)

	const categoriesMap = useMemo(() => {
		const map = new Map()
		categories?.forEach(category => {
			map.set(category._id, category)
		})
		return map
	}, [categories])

	const shouldShowByBestMatches: boolean = gradeFilter !== ALL

	const filteredSimulations = simulations
		? filterSimulations(nameFilter, categoryFilter, [gradeFilter], simulations, categoriesMap)
		: []

	const simulationSectionMap = createSimulationSectionMap(
		filteredSimulations,
		categoriesWithSections
	)

	if (isFetchingCategories || isLoadingPermissions || isLoadingStandardSets) {
		return (
			<Wrapper className={className}>
				<SpinnerHolder>
					<Spinner className="spinner-border">
						<span className="only-visible-to-screen-readers">Loading...</span>
					</Spinner>
				</SpinnerHolder>
			</Wrapper>
		)
	}

	if (permissionsError) {
		return (
			<Wrapper className={className}>
				<p className="py-12 px-8">
					An error occurred while loading your permissions. Start a chat in the bottom
					left and we will get you running a mission in no time!
				</p>
			</Wrapper>
		)
	}

	const metaDescription =
		'Transform your classroom with standards-aligned missions that are anything but standard. 100+ missions to explore!'

	return (
		<Wrapper className={className} data-walkthrough="mission-library">
			<Helmet>
				<title>Mission Library: The Ultimate K-8 Classroom Experiences</title>
				<meta name="description" content={metaDescription} />
				<meta name="og:description" content={metaDescription} />
			</Helmet>
			<PageTitleHeader>Mission Library</PageTitleHeader>
			<FiltersWrapper>
				<Filters
					filters={{
						grade: { value: gradeFilter, set: setGradeFilter },
						category: { values: categoryFilter, set: setCategoryFilter },
					}}
					disabledValues={{
						categories: unusedCategories,
					}}
					categories={categories || []}
				/>
				<Button
					className="clear-button w-fit"
					onClick={() => {
						setCategoryFilter([])
						setGradeFilter(INITIAL_GRADE_FILTER)
						setNameFilter('')
					}}>
					Clear
				</Button>
				<InputSearch
					css="grid-area: input-area;"
					setNameFilter={setNameFilter}
					nameFilter={nameFilter}
				/>
			</FiltersWrapper>
			<SimulationWrapper>
				{simulations ? (
					shouldShowByBestMatches ? (
						<div className="w-full">
							<MissionTileSection
								header="Best Matches"
								simulations={filteredSimulations}
								canRun={canRun}
							/>
							<MissionTileSection
								header="Close Matches"
								simulations={difference(
									filterSimulations(
										nameFilter,
										categoryFilter,
										getCloseMatches(gradeFilter, GRADE_ARRAY),
										simulations,
										categoriesMap
									),
									filteredSimulations
								)}
								canRun={canRun}
							/>
						</div>
					) : (
						Object.keys(simulationSectionMap)
							.sort((a, b) => {
								// sort training, featured, and kindergarten to the top, in that order
								if (a === trainingKey) return -1
								if (b === trainingKey) return 1
								if (a === featuredKey) return -1
								if (b === featuredKey) return 1
								if (a === betaKey) return -1
								if (a === betaKey) return 1
								if (a.startsWith('K')) return -1
								if (b.startsWith('K')) return 1
								// sort other to the bottom
								if (a === otherKey) return 1
								if (b === otherKey) return -1

								return a > b ? 1 : a < b ? -1 : 0
							})
							.map(gradeSection => {
								const sectionSimulations = simulationSectionMap[gradeSection]
								if (sectionSimulations.length > 0)
									return (
										<MissionTileSection
											key={gradeSection}
											header={gradeSection}
											simulations={sectionSimulations}
											canRun={canRun}
										/>
									)
								else {
									return null
								}
							})
					)
				) : (
					<Loading className="my-8 mx-auto" size="small" />
				)}
			</SimulationWrapper>
		</Wrapper>
	)
}

const GRADE_SECTIONS = [
	{
		name: 'Kindergarten Missions',
		grades: new Set(['K']),
	},
	{
		name: '1st Grade Missions',
		grades: new Set(['1']),
	},
	{
		name: '2nd Grade Missions',
		grades: new Set(['2']),
	},
	{
		name: '3rd Grade Missions',
		grades: new Set(['3']),
	},
	...[4, 5, 6, 7, 8, 9].map(currentGrade => ({
		name: `${currentGrade}th Grade Missions`,
		grades: new Set([`${currentGrade}`]),
	})),
]

/**
 * Creates an object whose key-value pairs are sections to display in the mission library. The key is the section, and the value is an array of
 * simulations that should appear in that section. Simulations are sorted into sections based on the `grades` field. If a simulation has a grade
 * that falls in the set of grades for a specific section, the simulation will be added to that section. The list of grades that correspond to
 * each section is defined in `GRADE_SECTIONS`. A single simulation can be added to multiple sections.
 * Special sections:
 * For each category in `categorySections`, a section will be created for simulations that have that category
 * A section called "Other" will be created for simulations that have no grades and do not have a category in `categorySections`
 * @param simulations The list of simulations to sort into sections
 * @param categorySections The list of categories to create sections for
 * @return The sorted sections
 */
function createSimulationSectionMap(
	simulations: ?Array<AutomatedSimulation>,
	categorySections: Array<{ key: string, categoryId: string, simulationStatus?: string }>
): { [string]: Array<AutomatedSimulation> } {
	if (!simulations) return {}
	let simulationCollections: { [string]: Array<AutomatedSimulation> } = {}
	simulations.forEach(simulation => {
		GRADE_SECTIONS.forEach(gradeSection => {
			for (let i = 0; i < simulation.grades.length; i++) {
				const simulationGrade = simulation.grades[i]
				if (gradeSection.grades.has(simulationGrade)) {
					simulationCollections[gradeSection.name] ??= []
					simulationCollections[gradeSection.name].push(simulation)
					// Only add the simulation to each grade section once
					return
				}
			}
		})

		let didAddToSection = false
		categorySections.forEach(({ key, categoryId, simulationStatus }) => {
			if (
				simulation.categories.some(category => category === categoryId) ||
				(simulationStatus != null && simulationStatus === simulation.status)
			) {
				simulationCollections[key] ??= []
				simulationCollections[key].push(simulation)
				didAddToSection = true
			}
		})

		if (!didAddToSection && !simulation.grades.length) {
			simulationCollections[otherKey] ??= []
			simulationCollections[otherKey].push(simulation)
		}
	})
	return simulationCollections
}

/**
 * getUnusedCategories - get the id of the categories which are not used in any simulation
 *
 * @param  {AutomatedSimulation[]} simulations - the simulations to look through
 * @param  {Categories} categories - all categories
 * @returns Set<string> - a set containing the ids of every category which was not used by any simulation
 */
function getUnusedCategories(
	simulations: AutomatedSimulation[],
	categories: Array<Category>
): Set<string> {
	const unusedCategories = new Set<string>()
	categories.forEach(category => {
		unusedCategories.add(category._id)
	})
	simulations.forEach((simulation: AutomatedSimulation) => {
		simulation.categories.forEach((categoryId: string) => unusedCategories.delete(categoryId))
	})
	return unusedCategories
}

/**
 * Filters simulations by the provided search term, categories, and grades. Simulations will be excluded from the list
 * under the following conditions:
 * 1. Neither the simulation name nor subjectMatter include the searchTerm
 * 2. The simulation does not include any of the categories found in categoryFilter
 * 3. None of the grades in simulation.grades is in `gradesFilter`
 * @return {AutomatedSimulation[]} The list of filtered simulations
 */
function filterSimulations(
	_searchTerm: string,
	categoryFilter: $ReadOnlyArray<{ value: string }>,
	grades: string[],
	simulations: AutomatedSimulation[],
	categories: Map<string, Category>
): AutomatedSimulation[] {
	const gradesFilter = new Set(grades)
	const searchTerm = _searchTerm.trim().toLowerCase()
	const searchTermForStandards = searchTerm.replaceAll('.', '')

	return simulations.filter(simulation => {
		const passesSearchFilter =
			simulation.name.toLowerCase().includes(searchTerm) ||
			simulation.subjectMatter?.toLowerCase().includes(searchTerm) ||
			simulation.categories.some(categoryId =>
				categories
					.get(categoryId)
					?.name.toLowerCase()
					.includes(searchTerm)
			) ||
			simulation.standards.some(standard =>
				standard.name
					.replaceAll('.', '')
					.toLowerCase()
					.includes(searchTermForStandards)
			)

		// Exclude simulations whose name, subject matter, and categories do not match the searchTerm
		if (!passesSearchFilter) {
			return false
		}

		// Exclude simulations that do not include all categories in the categoryFilter
		const simulationCategories = new Set(simulation.categories || [])
		if (
			categoryFilter.length > 0 &&
			categoryFilter.every(category => !simulationCategories.has(category.value))
		) {
			return false
		}

		// Exclude simulations that do not have any of the grades in gradeFilter
		if (!gradesFilter.has(ALL) && !simulation.grades.some(grade => gradesFilter.has(grade))) {
			return false
		}

		return true
	})
}

/**
 * Gets those missions that match the criteria in the two closest grades.
 * There is a hard line drawn between 3rd and 4th grade.
 * A close match for a 3rd grade mission will never be >= to a 4th grade mission and vice versa.
 * Close matches for a Kindergarten mission is 1st and 2nd grade, and close matches for 12th grade are 10th and 11th.
 */
function getCloseMatches(value: string, gradeArray: $ReadOnlyArray<string>): string[] {
	let grades = []
	const valueIndex = gradeArray.findIndex(val => val === value)
	if (valueIndex > -1) {
		if (valueIndex === 0) {
			grades = [gradeArray[valueIndex + 1], gradeArray[valueIndex + 2]]
		} else if (valueIndex === 3) {
			grades = [gradeArray[valueIndex - 2], gradeArray[valueIndex - 1]]
		} else if (valueIndex === 4) {
			grades = [gradeArray[valueIndex + 1], gradeArray[valueIndex + 2]]
		} else if (valueIndex === gradeArray.length - 1) {
			grades = [gradeArray[valueIndex - 2], gradeArray[valueIndex - 1]]
		} else {
			grades = [gradeArray[valueIndex - 1], gradeArray[valueIndex + 1]]
		}
	}
	return grades
}

function MissionTileSection({
	header,
	simulations,
	canRun,
}: {
	header: string,
	simulations: AutomatedSimulation[],
	canRun: Set<string>,
}) {
	if (simulations.length < 1) {
		return null
	}

	const simulationCanRunComparison = (a, b) => {
		if (canRun.has(a._id)) return -1
		if (canRun.has(b._id)) return 1
		return -1
	}

	// sort by grade first, if simulation can be run second
	const sortedSimulations = simulations.sort((a, b) => {
		// sort simulations with no grades or multiple grades to the bottom
		if (a.grades.length !== 1) {
			if (b.grades.length !== 1) return simulationCanRunComparison(a, b)
			return 1
		}
		if (b.grades.length !== 1) {
			return -1
		}
		const aGrade = a.grades[0]
		const bGrade = b.grades[0]

		// sort Kindergarten simulations to the top
		if (aGrade === 'K') {
			if (bGrade === 'K') return simulationCanRunComparison(a, b)
			return -1
		}
		if (bGrade === 'K') {
			return 1
		}

		// sort by grade ascending
		return aGrade.localeCompare(bGrade) || simulationCanRunComparison(a, b)
	})
	return (
		<div data-walkthrough={header} className="w-full">
			<h2 className="text-2xl">{header}</h2>
			<hr className="my-2" />
			<ul css="padding-left: 0;">
				{sortedSimulations.map(sim => (
					<li css="list-style-type: none; display: inline;" key={sim._id}>
						<SimulationTile simulation={sim} link={'/prep/' + sim._id} />
					</li>
				))}
			</ul>
			<br />
		</div>
	)
}
