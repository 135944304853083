import { useQuery, type UseQueryResult } from 'react-query'
import NetworkCommunicator from '../NetworkCommunicator'
import useUser from '../../services/hooks/user'
import type { User } from '../../store/types'
import { config } from '../../config'

type LeafStage = {|
	id: string,
	name: string,
	videoUrl?: string,
	loomUrl?: string,
|}

export type LeafStageWithIsComplete = {| ...LeafStage, isComplete: boolean |}

type ParentStage<Leaf> = {|
	id: string,
	name: string,
	time: string,
	contents: string[],
	description: string,
	stages: Array<ParentStage<Leaf> | Leaf>,
	status?: 'COMING_SOON',
|}

type _Stage<Leaf> = ParentStage<Leaf> | Leaf

type Stage = _Stage<LeafStage>
export type StageWithIsComplete = _Stage<LeafStageWithIsComplete>

/**
 * Fetches the training stages from the server
 */
async function fetchTrainingStages(): Promise<Stage[]> {
	const result = await NetworkCommunicator.GET('/api/trainings', { host: config.loginServer })

	return result.stages
}

/**
 * Hook to get training stages from the server
 * @return {UseQueryResult} The result of calling `useQuery`, with an extra property `result.stages` that matches `result.data`
 */
export function useTrainingStages(): {
	...UseQueryResult<StageWithIsComplete[]>,
	stages: StageWithIsComplete[] | void,
	// refetch will return a react query result with Promise<Stage[]>, which is not how this hook is intended
	// to be used. Make refetch be a function with an unusable return value so it can only be used to trigger a refetch
	refetch: () => mixed,
} {
	const { user }: { user: ?User } = useUser()

	const reactQueryResult: UseQueryResult<Stage[]> = useQuery('trainings', fetchTrainingStages)

	const stages = reactQueryResult.data
	const completedStageIds = user ? getCompletedStageIds(user) : []
	const stagesWithIsComplete = stages
		? mapStagesWithIsComplete(stages, completedStageIds)
		: undefined

	return {
		...reactQueryResult,
		stages: stagesWithIsComplete,
		// $FlowIgnore[incompatible-return] `stagesWithIsComplete` can be undefined, and that matches the return type of this function
		data: stagesWithIsComplete,
	}
}

/**
 * Gets an array of `StageWithIsComplete` from an array of `Stage`. Sets the
 * `isComplete` property based on `completedStageIds`.
 * @param {Stage[]} stages The stages to add `isComplete` to
 * @param {string[]} completedStageIds Ids of all completed stages
 * @return {StageWithIsComplete[]}
 */
function mapStagesWithIsComplete(
	stages: Stage[],
	completedStageIds: string[]
): StageWithIsComplete[] {
	return stages.map(stage => {
		if (isLeafStage(stage)) {
			return {
				...stage,
				isComplete: completedStageIds.includes(stage.id),
			}
		}

		return {
			...stage,
			stages: mapStagesWithIsComplete(stage.stages, completedStageIds),
		}
	})
}

/**
 * Gets the list of stage ids that the given `user` has completed.
 */
function getCompletedStageIds(user: User): string[] {
	if (!user.training) {
		return []
	}

	const completedStages: { [string]: boolean } = {}

	user.training.stageActions.forEach(stageAction => {
		completedStages[stageAction.stageId] = stageAction.type === 'MARK_COMPLETE'
	})

	return Object.keys(completedStages).filter(stageId => completedStages[stageId])
}

/**
 * Whether the given `stage` is a leaf stage, i.e. has no children
 */
export function isLeafStage(stage: Stage | StageWithIsComplete): boolean %checks {
	return !stage.stages
}
