// @flow
import React, { Component } from 'react'
import { DropTarget } from 'react-dnd'
import styled from 'styled-components'
import StudentComponent from './StudentComponent'
import { TeamBoxWrapper, Header } from './TeamBoxStyles'

import type { Student_New } from '../../../../../../models/Student'
import type { Team } from '../RolesHelpers'
import type { TargetDropProps } from '../../../../../../types'

type BaseProps = {
	team: Team,
	onSelect: (team: Team, student: Student_New) => void,
}

type Props = {
	...BaseProps,
	...TargetDropProps,
}

const stationTarget = {
	drop(props: Props, monitor) {
		props.onSelect(props.team, monitor.getItem())
	},
}

function collect(connect, monitor) {
	return {
		connectDropTarget: connect.dropTarget(),
		isOver: monitor.isOver(),
		canDrop: monitor.canDrop(),
	}
}

class TeamBox extends Component<Props> {
	render() {
		const { isOver, canDrop, team, connectDropTarget } = this.props
		const isInvalid = team.isRequired ? team.students.length < team.minStudents - 1 : false
		return connectDropTarget(
			<div className="flex flex-col">
				<Header>
					Crew
					{isInvalid &&
						` (${team.minStudents} student${
							team.minStudents !== 1 ? 's' : ''
						} required)`}
				</Header>
				<TeamBoxWrapper
					className="flex-1"
					boxState={isOver && canDrop ? 'hovered' : isInvalid ? 'error' : 'valid'}>
					<StudentBox>
						{team.students.map((student: Student_New) => (
							<StudentComponent
								student={student}
								container="station-box"
								key={student._id}
							/>
						))}
					</StudentBox>
				</TeamBoxWrapper>
			</div>
		)
	}
}

export default (DropTarget('student', stationTarget, collect)(TeamBox): (
	props: BaseProps
) => React$Node)

const StudentBox = styled.div`
	display: flex;
	flex-direction: row;
`
