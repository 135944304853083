import { useMemo } from 'react'
import { useQuery, type UseQueryResult } from 'react-query'
import { fetchPermissions } from '../networkCalls'
import { fetchSimulationGroups } from '../../routes/admin/LicenseNetworkRoutes'
import { smartQueryRetry } from '../../utility/helpers'
import type { PermissionsObject, SimulationGroup } from '../../store/types' // PermissionsObject
import config from '../../config'
import { useUser } from '.'
import { EXAMPLE_PERMISSIONS } from '../../constants/exampleData'
import { ONE_MINUTE } from '../../constants'
import { PROMOTION_TYPES } from '../../utility/types'

const getPermissionsQueryKey = (isUser: boolean) => {
	return ['permissions', isUser.toString()]
}

/**
 * React query that gets the users' permissions
 * @return {UseQueryResult} The result of calling `useQuery`, with an extra property `result.permissions` that matches `result.data`
 */
export default function usePermissions(): {|
	...UseQueryResult<PermissionsObject>,
	permissions: ?PermissionsObject,
|} {
	const { user } = useUser()
	const useQueryResult = useQuery(
		getPermissionsQueryKey(Boolean(user)),
		() => {
			if (!user) {
				return EXAMPLE_PERMISSIONS
			}
			return fetchPermissions()
		},
		{
			...smartQueryRetry,
			staleTime: 5 * ONE_MINUTE,
		}
	)
	return {
		...useQueryResult,
		permissions: useQueryResult.data,
	}
}

// checks to see if the user is on a FREE account
export function useIsFreeAccount(): boolean {
	const { permissions } = usePermissions()
	return Boolean(permissions?.isFreeAccount)
}

/**
 * useSimulationGroups - get all the simulation groups from the server
 *
 * @return {UseQueryResult} The result of calling `useQuery`, with an extra property `result.simulationGroups` that matches `result.data`
 */
export function useSimulationGroups(): {
	...UseQueryResult<SimulationGroup[]>,
	simulationGroups: ?(SimulationGroup[]),
} {
	const useQueryResult = useQuery('simulation-groups', fetchSimulationGroups)
	return { ...useQueryResult, simulationGroups: useQueryResult.data }
}

type UserSimulationsPermissions = {|
	canRun: Set<string>, // user can run any of these simulations (includes `baseCanRun` and `promotionCanRun`)
	canView: Set<string>, // user can view any of these simulations (includes `baseCanView` and `promotionCanView`)
	baseCanRun: Set<string>, // user can run any of these simulation due to their base license without any promotions
	baseCanView: Set<string>, // user can view any of these simulation due to their base license without any promotions
	promotionCanRun: Set<string>, // user's promotions allows running any of these simulations (Note: the same entry can exist in this set as well as `baseCanRun`)
	promotionCanView: Set<string>, // user's promotions allows viewing any of these simulations (Note: the same entry can exist in this set as well as `baseCanView`)
	expiredPromotionsCanRun: Set<string>, // user's expired promotions allowed running any of these simulations
	expiredPromotionsCanView: Set<string>, // user's expired promotions allowed viewing any of these simulations
	promotions: Array<{
		expirationDate: Date, // when the promotion expires
		canRun: Set<string>, // what simulations this promotion allows running
		canView: Set<string>, // what simulations this promotion allows viewing
		type: $Keys<typeof PROMOTION_TYPES>,
	}>,
|}

/**
 * useUserSimulationPermissions - get the set of simulations the current user can view and run
 *
 * @return {Object} obj
 * @return {?{
 *	 canRun: Set<string>, - all the simulations the user can run
 *   canView: Set<string> - all the simulations the user can view
 * }} obj.permissions
 * @return {mixed} obj.error An optional error that was returned from the network requests
 * @return {boolean} obj.isLoading Whether the data is being fetched
 */
export function useUserSimulationPermissions(): {
	permissions: ?UserSimulationsPermissions,
	error: mixed,
	isLoading: boolean,
} {
	const { permissions, ...permissionsQueryResult } = usePermissions()
	const { simulationGroups, ...simulationGroupsQueryResult } = useSimulationGroups()

	const simulationPermissions = useMemo(() => {
		if (config.featureFlags.skipLicensePermissionChecks) {
			const setThatContainsEverything = new Set()
			// $FlowIgnore[cannot-write]
			setThatContainsEverything.has = () => true
			return {
				canRun: setThatContainsEverything,
				canView: setThatContainsEverything,
				baseCanRun: setThatContainsEverything,
				baseCanView: setThatContainsEverything,
				promotionCanRun: setThatContainsEverything,
				promotionCanView: setThatContainsEverything,
				expiredPromotionsCanRun: setThatContainsEverything,
				expiredPromotionsCanView: setThatContainsEverything,
				promotions: [],
			}
		}
		if (!permissions || !simulationGroups) {
			return null
		}

		const groupIdMap = {}
		simulationGroups.forEach(group => (groupIdMap[group._id] = group))

		const { canRun, canView } = getSimulationPermissionsFromSimulationGroups(
			permissions.simulationGroups,
			groupIdMap
		)

		const {
			canRun: promotionCanRun,
			canView: promotionCanView,
		} = getSimulationPermissionsFromSimulationGroups(
			permissions.promotionSimulationGroups,
			groupIdMap
		)

		const {
			canRun: expiredPromotionsCanRun,
			canView: expiredPromotionsCanView,
		} = getSimulationPermissionsFromSimulationGroups(
			permissions.expiredPromotionSimulationGroups,
			groupIdMap
		)

		const {
			canRun: baseCanRun,
			canView: baseCanView,
		} = getSimulationPermissionsFromSimulationGroups(
			permissions.baseSimulationGroups,
			groupIdMap
		)

		return {
			canRun,
			canView,
			baseCanRun,
			baseCanView,
			promotionCanRun,
			promotionCanView,
			expiredPromotionsCanRun,
			expiredPromotionsCanView,
			promotions: permissions.promotions.map(promotion => ({
				expirationDate: new Date(promotion.expirationDate),
				type: promotion.type,
				...getSimulationPermissionsFromSimulationGroups(
					promotion.permissions.simulationGroups,
					groupIdMap
				),
			})),
		}
	}, [permissions, simulationGroups])

	return {
		permissions: simulationPermissions,
		error: config.featureFlags.skipLicensePermissionChecks
			? null
			: permissionsQueryResult.error || simulationGroupsQueryResult.error,
		isLoading: config.featureFlags.skipLicensePermissionChecks
			? false
			: permissionsQueryResult.isLoading || simulationGroupsQueryResult.isLoading,
	}
}

/**
 * getSimulationPermissionsFromSimulationGroups - combine the simulation permissions of simulation groups
 *
 * @param {?Array<string>} simulationGroups - a list of ids of the simulation groups to combine permissions for
 * @param {{ [groupId: string]: SimulationGroup }} groupLookup - a lookup of the simulation group id to the simulation group permissions
 *
 * @return {|
 *   canRun: Set<string>,  - a set of all the simulationIds that the combined simulation groups can run
 *   canView: Set<string> - a set of all the simulationIds that the combined simulation groups can view
 * |}
 */
function getSimulationPermissionsFromSimulationGroups(
	simulationGroups: ?Array<string>,
	groupLookup: { [groupId: string]: SimulationGroup }
): {| canRun: Set<string>, canView: Set<string> |} {
	const canRun = new Set()
	const canView = new Set()

	if (!simulationGroups) {
		return {
			canRun,
			canView,
		}
	}

	for (let simulationGroupId of simulationGroups) {
		const group = groupLookup[simulationGroupId]
		if (!group) {
			continue
		}

		for (let simulationId of group.canRun) {
			canRun.add(simulationId)
		}
		for (let simulationId of group.canView) {
			canView.add(simulationId)
		}
	}

	return {
		canRun,
		canView,
	}
}

export type SimulationPermissions = {
	userCanRun: boolean,
	userCanOnlyRunDueToPromotion: ?{| expires: ?Date |},
	unlockMethods: string[],
}

/**
 * useSimulationPermissions - get the group unlock methods and user permissions for a specific simulation
 *
 * @param {?string} simulationId? - the id of the simulation to get permissions for
 *
 * @return {?{
 * 	 userCanRun: boolean, - if the user can run the simulation
 *   unlockMethods: string[] - the group unlock methods which can cause the simulation to be runnable by the user
 *   userCanOnlyRunDueToPromotion?: { - if truthy, the user can only run this simulation due to a promotion
 *     expires?: Date,  - the expiration date of the promotion which allows running this simulation
 *   }
 * }}
 */
export function useSimulationPermissions(simulationId?: ?string): ?SimulationPermissions {
	const { permissions: simulationPermissions } = useUserSimulationPermissions()
	const { simulationGroups } = useSimulationGroups()

	return useMemo(() => {
		if (!simulationPermissions || !simulationGroups || !simulationId) {
			return
		}

		const unlockMethods: string[] = []
		simulationGroups.forEach(({ canRun, unlockMessageType }) => {
			if (!unlockMessageType) {
				return
			}
			if (canRun.includes(simulationId)) {
				unlockMethods.push(unlockMessageType)
			}
		})

		const canRunWithoutPromotion = simulationPermissions.baseCanRun.has(simulationId)
		const canRunDueToPromotion = simulationPermissions.promotionCanRun.has(simulationId)

		let userCanOnlyRunDueToPromotion: ?{| expires: ?Date |} = null
		if (!canRunWithoutPromotion && canRunDueToPromotion) {
			for (let promotion of simulationPermissions.promotions) {
				if (!promotion.canRun.has(simulationId)) {
					continue
				}
				if (
					userCanOnlyRunDueToPromotion?.expires == null ||
					// pick the promotion which lasts the longest into the future
					promotion.expirationDate > userCanOnlyRunDueToPromotion.expires
				) {
					userCanOnlyRunDueToPromotion = {
						expires: promotion.expirationDate,
					}
				}
			}

			userCanOnlyRunDueToPromotion ??= {
				expires: null, // somehow the user has a promotion which allows running the simulation, but we were unable to figure out which promotion it was. This should not happen.
			}
		}

		return {
			userCanRun: canRunWithoutPromotion || canRunDueToPromotion,
			unlockMethods: unlockMethods,
			userCanOnlyRunDueToPromotion,
		}
	}, [simulationPermissions, simulationGroups, simulationId])
}
