import classnames from 'classnames'
import React from 'react'
import styled from 'styled-components'

export default function Checkbox({
	checked,
	label,
	disabled,
	...props
}: {
	checked: boolean,
	label: string,
	disabled?: boolean,
	labelClassName?: string,
	checkboxClassName?: string,
}): React$Node {
	return (
		<div>
			<label className={props.labelClassName}>
				<CheckboxContainer>
					<HiddenCheckbox
						{...props}
						className={props.checkboxClassName}
						checked={checked}
						disabled={disabled}
					/>
					<StyledCheckbox
						className={classnames(
							'inline-block size-5 rounded-md transition-all duration-150 border',
							checked
								? 'bg-primary-500 border-primary-500'
								: 'bg-white border-neutral'
						)}
						checked={checked}>
						<Icon
							viewBox="0 0 24 24"
							className={classnames('inline', !checked && 'invisible')}>
							<polyline points="20 6 9 17 4 12" />
						</Icon>
					</StyledCheckbox>
				</CheckboxContainer>
				<span className={classnames('ml-2', disabled && 'text-neutral')}>{label}</span>
			</label>
		</div>
	)
}

const CheckboxContainer = styled.div`
	display: inline-block;
	vertical-align: middle;
`

const Icon = styled.svg`
	fill: none;
	stroke: white;
	stroke-width: 2px;
	margin-top: -7px;
`
// Hide standard checkbox visually but remain accessible to screen readers.
const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
	border: 0;
	clip: rect(0 0 0 0);
	clippath: inset(50%);
	height: 1px;
	margin: -1px;
	overflow: hidden;
	padding: 0;
	position: absolute;
	white-space: nowrap;
	width: 1px;
`

const StyledCheckbox = styled.div`
	${HiddenCheckbox}:hover + & {
		${({ theme, checked }) => `
            background-color: ${checked && theme.neutralLight};
			border-color: ${theme.neutralLight};
        `}
	}
	${HiddenCheckbox}:disabled + & {
		${({ theme, checked }) => `
            background-color: ${checked && theme.neutralLight};
            border-color: ${theme.neutralLight};
		`}
	}
`
