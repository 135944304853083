import { IMAGE_FILE_TYPES } from '../components/FileViewer/FileTypeMap'
import { getFileExtensionFromSrc } from './fileFunctions'
import { theme } from '@mission.io/styles'

export type File = {
	id: string, // (Required) String that uniquely identifies the file
	name: string, // (Required) Full name, e.g. `MyImage.jpg`
	thumbnailUrl?: string, // Automatically load thumbnail from this URL
	isDir?: boolean, // Is a directory, default: false

	// custom properties
	filePreviewSrc?: ?string,
	actualName?: ?string, // name to show in file viewer
}

export type FolderMap = {
	[folderRoute: string]: {
		[fileOrFolder: string]: File,
	},
}

/**
 * formatFolderPath - convert a list of folder segments into a folder path string
 *
 * @param {string[]} segments - a list of folders (example: ["path", "to", "folder"])
 *
 * @return {[
 * 		string,  - the "absolute path" (example: "/path/to/folder/")
 * 		string   - the name of the folder (example: "folder")
 * ]}
 */
export function formatFolderPath(segments: string[]): [string, string] {
	if (segments.length === 0) {
		return ['/', 'resources']
	}
	return ['/' + segments.join('/') + '/', segments[segments.length - 1]]
}

/**
 * awsDataToFolderMap - convert aws file data to a conky folder map
 *
 * @param {Object} fileData - formatted aws file data
 * @param {string[]} fileData.files - a list of all the files to convert to a folder map
 * @param {string} fileData.host - a string to prepend to the files to get the source of the file
 * @param {string} fileData.prefixPath - the folder to consider the root path (example: 'teacher-resources/downloads')
 * @param {string} customRoutePrefix? - if supplied, the root directory will become the `customRoutePrefix`
 *
 * @return {FolderMap} - a map of absolute folder paths (ie: '/' and '/path/to/folder') to the files within those folders represented as Files
 */
export function awsDataToFolderMap(
	fileData: {
		files: string[],
		host: string,
		prefixPath: string,
	},
	customRoutePrefix?: string = ''
): FolderMap {
	// allow "+" signs to be in source
	const allFiles = fileData.files.map(filePath => {
		return {
			originalPath: filePath,
			baseSrc: `${fileData.host}${filePath}`,
			encodedSrc: `${fileData.host}${encodeURI(filePath).replace(
				/\+/g,
				'%2B' // encodeURI assumes that + are the same as spaces, but in this situation + should be considered the plus sign, explicitly convert the + sign to its encoding
			)}`,
		}
	})

	const thumbnailMap: { [baseFile: string]: string } = {}
	allFiles.forEach(({ baseSrc, encodedSrc }) => {
		const targetFile = getThumbnailTargetFile(baseSrc)
		if (targetFile != null) {
			thumbnailMap[targetFile] = encodedSrc
		}
	})

	const folderMap: FolderMap = {}

	allFiles.forEach(({ baseSrc, encodedSrc, originalPath }) => {
		if (getThumbnailTargetFile(baseSrc) != null) {
			// file is a thumbnail we should not show it in the file browser
			return
		}

		const file =
			customRoutePrefix + originalPath.slice(fileData.prefixPath.length, originalPath.length)
		const fileComponents = file.split('/')

		// populate folder parent folders
		for (let i = fileComponents.length - 1; i > 0; i--) {
			const [folder, folderName] = formatFolderPath(fileComponents.slice(0, i))
			const [parentFolder] = formatFolderPath(fileComponents.slice(0, i - 1))
			folderMap[parentFolder] ??= {}
			folderMap[parentFolder][folder] = {
				color: theme._skyBlue,
				id: folder,
				name: trimNames(folderName),
				actualName: folderName,
				isDir: true,
			}
		}

		const [parentFolder] = formatFolderPath(fileComponents.slice(0, fileComponents.length - 1))
		folderMap[parentFolder] ??= {}

		const fileName = fileComponents[fileComponents.length - 1]

		// note: anything ending with / is considered a path
		if (!originalPath.endsWith('/')) {
			folderMap[parentFolder][file] = {
				id: encodedSrc,
				name: trimNames(fileName),
				actualName: fileName,
				isDir: false,
				...getThumbnailOptions(thumbnailMap[baseSrc] || encodedSrc),
			}
		}
	})

	return folderMap
}

/**
 * getThumbnailOptions - get the options to handle thumbnails
 *
 * @param {string} src - the source of the file
 *
 * @return {{| thumbnailUrl?: string, color: string, icon?: null |}} - the options to display the appropriate thumbnail
 */
export function getThumbnailOptions(src: string): {| thumbnailUrl?: string |} {
	if (IMAGE_FILE_TYPES.has(getFileExtensionFromSrc(src))) {
		return {
			thumbnailUrl: src,
		}
	}
	return Object.freeze({})
}

/**
 * folderMapToChunkyFiles - get the list of files in a folder from the folder map
 *
 * @param {FolderMap} folderMap - the folder map to get the files from
 * @param {string} currentFolder - the folder to get the files in
 *
 * @return {File[]} - the files in the folder
 */
export function getFilesInCurrentFolder(folderMap: FolderMap, currentFolder: string): File[] {
	return ((Object.values(folderMap[currentFolder] || {}): any): File[])
}

/**
 * getFileWhichFileIsThumbnailFor - check if a file is a thumbnail for another file
 *
 * @param {string} src - the file source
 *
 * @return {?string} - the name of the file this file is a thumbnail for
 */
export function getThumbnailTargetFile(src: string): ?string {
	const srcSegments = src.split('/')
	const fileName = srcSegments[srcSegments.length - 1]

	const fileNameSegments = fileName.split('.')
	if (
		fileNameSegments.length > 2 &&
		fileNameSegments[fileNameSegments.length - 2] === 'thumbnail'
	) {
		return src.slice(0, src.indexOf('.thumbnail'))
	}
	return null
}

/**
 * trimNames - trims a fileName so that the length never exceeds 20 (used for better styling)
 *
 * @param {string} fileName - the name of the file to trim
 *
 * @return {string} - the trimmed fileName
 */
function trimNames(fileName: string): string {
	if (fileName.length < 20) {
		return fileName
	}
	return fileName.slice(0, 18) + '...'
}
