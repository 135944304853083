import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { SpecificMissionContext } from './SpecificMissionContext'
import styled from 'styled-components/macro'
import ProficiencyGraph from './AnalyticsCharts/ProficiencyBarGraph/ProficiencyBarGraph'
import StudentsTableManager from './AnalyticsCharts/StudentsTableManger'
import useUser, { useIsSchoolAdmin, useIsDistrictAdmin } from '../../services/hooks/user'
import { getAutomatedSimulationFromId } from '../../store/simulation'
import { DownloadLink } from '../../download/Downloader'
import HolisticView from './HolisticView'
import { formatAnalyticsForDisplay } from './AnalyticsCharts/dataHelpers'
import { SurveyResponseButton } from './SurveyResponseButton'
import { analyticsViewEnum, scoreFormatEnum } from './constants'
import type { AnalyticsObject } from '../../models/Analytics'
import type { NameLookup } from '../../download/questions'
import { Button } from '@mission.io/styles'
import { useSchool } from '../../services/hooks/organizations'
import { MissionDocumentReview } from './AnalyticsCharts/MissionDocumentReview'

export default function AnalyticsDataViewer({
	analyticsData,
	isSpecificSchool,
	teacherViewSpecified,
	nameLookup,
	onRequestSurveyOpen,
	isAllMissionsSelected,
	isDemoMode,
	csvDateRange,
}: {
	analyticsData: AnalyticsObject[],
	isSpecificSchool: boolean,
	teacherViewSpecified: boolean,
	nameLookup: NameLookup,
	onRequestSurveyOpen: (missionId: string) => void,
	isAllMissionsSelected?: boolean,
	isDemoMode: boolean,
	csvDateRange: string,
}): React$Node {
	const specificMissionAnalytics = analyticsData.length === 1 ? analyticsData[0] : null

	const simulation = useSelector(
		getAutomatedSimulationFromId(specificMissionAnalytics?.simulationId)
	)
	const hasDistrictAdminRole = useIsDistrictAdmin()
	const { user } = useUser()
	const userId = user?._id
	const hasSchoolAdminRole = useIsSchoolAdmin()

	const currentUserFirstName = user?.firstName
	const currentUserLastName = user?.lastName
	const currentUserId = user?._id
	const { data: currentUserSchool } = useSchool(user?.schoolId)
	const currentUserSchoolName = currentUserSchool?.name
	const formattedAnalytics = useMemo(
		() =>
			formatAnalyticsForDisplay({
				isDistrictAdmin: hasDistrictAdminRole,
				isSchoolAdmin: hasSchoolAdminRole,
				isSpecificSchool,
				teacherViewSpecified,
				analyticsData,
				userId,
				nameLookup,
				currentUser: {
					firstName: currentUserFirstName,
					lastName: currentUserLastName,
					schoolName: currentUserSchoolName,
					id: currentUserId,
				},
			}),
		[
			hasDistrictAdminRole,
			isSpecificSchool,
			hasSchoolAdminRole,
			teacherViewSpecified,
			analyticsData,
			userId,
			nameLookup,
			currentUserFirstName,
			currentUserLastName,
			currentUserSchoolName,
			currentUserId,
		]
	)

	if (
		formattedAnalytics.type !== analyticsViewEnum.MISSION &&
		formattedAnalytics.display.length === 0
	) {
		return <div>Not enough data to generate analytics</div>
	}

	const { individualSelScores, version } =
		formattedAnalytics.type === analyticsViewEnum.MISSION
			? formattedAnalytics.display
			: formattedAnalytics.display[formattedAnalytics.display.length - 1]

	return (
		<SpecificMissionContext.Provider
			value={{
				nameLookup,
				individualSelScores,
				isDemo: isDemoMode,
				selectedMission: specificMissionAnalytics,
			}}>
			{simulation && specificMissionAnalytics && (
				<div css="display: flex; justify-content: space-between; width: 100%;">
					<StyledSurveyResponseButton
						missionId={specificMissionAnalytics.missionId}
						onRequestSurveyOpen={onRequestSurveyOpen}
					/>

					<div css="display: flex;">
						<DownloadLink
							scoreFormat={scoreFormatEnum.PROFICIENCY_LEVEL}
							missionName={specificMissionAnalytics.missionName || 'Unknown Mission'}
							simulation={simulation}
							missionId={specificMissionAnalytics.missionId}
							nameLookup={nameLookup}
							selectedMission={specificMissionAnalytics}
						/>
						<a
							href={`/end-of-mission-results/${specificMissionAnalytics.missionId}`}
							target="_blank"
							rel="noreferrer">
							<Button className="ml-2">View Mission Results</Button>
						</a>
					</div>
				</div>
			)}

			<div className="font-normal max-w-[890px] lg:max-w-[65vw] my-0 mx-auto">
				{formattedAnalytics.type === analyticsViewEnum.MISSION ? (
					<>
						<ProficiencyGraph />
						<StudentsTableManager
							nameLookup={nameLookup}
							version={version}
							individualSelScores={individualSelScores}
							csvGroupName={formattedAnalytics.csvGroupName}
							filteredAnalytics={analyticsData}
						/>
						<MissionDocumentReview />
					</>
				) : (
					<HolisticView
						allScores={formattedAnalytics.display}
						csvGroupName={formattedAnalytics.csvGroupName}
						role={formattedAnalytics.type}
						proficiencyTableEntries={formattedAnalytics.proficiencyTableEntries}
						csvDateRange={csvDateRange}
						filteredAnalytics={analyticsData}
						nameLookup={nameLookup}
						analyticsVersion={version}
					/>
				)}
			</div>
		</SpecificMissionContext.Provider>
	)
}

const StyledSurveyResponseButton = styled(SurveyResponseButton)`
	${({ theme }) => `
		margin-left: var(--spacing1x-dont-use);
	`}
`
