import React from 'react'
import { Helmet } from 'react-helmet'
import { useLicenseJoinedPopup } from '../../components/popups'
import LeaderBoardAnalytics from '../../components/dashboardModules/LeaderBoardAnalytics/LeaderBoardAnalytics'

export default function Home(): React$Node {
	useLicenseJoinedPopup()
	const metaDescription =
		"See who's leading the educational revolution. Join the ranks of top-performing students, classes, and schools globally."
	return (
		<>
			<Helmet>
				<title>Leaderboard: Celebrate Top-Performing Students, Classes & Schools</title>
				<meta name="description" content={metaDescription} />
				<meta name="og:description" content={metaDescription} />
			</Helmet>
			<LeaderBoardAnalytics />
		</>
	)
}
