// @flow
import { Button } from '@mission.io/styles'
import React from 'react'
import { useEffect, useRef } from 'react'
import styled from 'styled-components/macro'
import FaCheckCircle from 'react-icons/lib/fa/check-circle-o'
import FaCircle from 'react-icons/lib/fa/circle-o'
import FaAngleDown from 'react-icons/lib/fa/angle-down'
import FaAngleUp from 'react-icons/lib/fa/angle-up'
import VideoTracker from '../../components/helpers/VideoTracker'
import NetworkCommunicator from '../../services/NetworkCommunicator'
import { useUpdateUserTraining } from './../../services/hooks/user'

import { type LeafStageWithIsComplete } from '../../services/hooks/training'
import RequireSignIn from '../../components/helpers/RequireSignIn'
import Collapse from '../../components/Collapse'
import { config } from '../../config'

type Props = {
	stage: LeafStageWithIsComplete,
	expanded: boolean,
	onClickExpander: () => mixed,
}

/**
 * A single training. Includes an expandable component where the video can be played. Also display whether the training is complete.
 */
const TrainingStage = ({ stage, expanded, onClickExpander }: Props): React$Node => {
	const updateTraining = useUpdateUserTraining()

	const CheckComponent = stage.isComplete ? FaCheckCircle : FaCircle

	const collapseAreaId = stage.id + '-collapse'

	const scrollRef = useRef<?HTMLDivElement>()
	useEffect(() => {
		// wait because we have an animation of the field expanding
		let timeout = setTimeout(() => {
			// $FlowIgnore[method-unbinding] scrollIntoView can be called here because scrollRef.current would be unset if the ref left the DOM
			if (scrollRef.current?.scrollIntoView) {
				scrollRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
			}
		}, 250)
		return () => clearTimeout(timeout)
	}, [expanded])

	return (
		<div>
			<DropdownButton
				onClick={() => onClickExpander()}
				aria-expanded={expanded}
				aria-controls={collapseAreaId}>
				<div css="display: flex; gap: var(--spacing2x-dont-use);">
					<CheckComponent
						className={`${stage.isComplete ? 'success' : 'primary'}`}
						size="24px"
						aria-hidden
					/>
					<label className="only-visible-to-screen-readers">
						Training Complete
						<input type="checkbox" checked={stage.isComplete} readOnly tabIndex={-1} />
					</label>
					<h5 className="text-xl">{stage.name}</h5>
				</div>
				{expanded ? <FaAngleUp /> : <FaAngleDown />}
			</DropdownButton>
			<Collapse id={collapseAreaId} expanded={expanded} className="flex items-start mt-2">
				{!expanded ? null : stage.loomUrl ? (
					<LoomPlayer url={stage.loomUrl} trainingName={stage.name} />
				) : stage.videoUrl ? (
					<VideoTracker
						src={stage.videoUrl}
						css="max-height: 60vh; max-width: 70%;"
						controls
						onEvent={({ type, videoCurrentTime }) => {
							NetworkCommunicator.POST('/api/trainings/actions', {
								host: config.loginServer,
								body: {
									videoAction: {
										type,
										videoCurrentTime,
										stageId: stage.id,
									},
								},
							})
						}}
						aria-label={stage.name + ' Video'}
					/>
				) : (
					'Invalid Training. Please select another training'
				)}
				<div className="max-w-16 flex-1" ref={scrollRef} />
				<RequireSignIn>
					<Button
						onClick={() => {
							updateTraining({
								type: stage.isComplete ? 'MARK_INCOMPLETE' : 'MARK_COMPLETE',
								stageId: stage.id,
							})
						}}>
						{stage.isComplete ? 'Mark Incomplete' : 'Mark Complete'}
					</Button>
				</RequireSignIn>
			</Collapse>
		</div>
	)
}

/**
 * A component that renders an embedded loom video in an iframe
 */
function LoomPlayer({ url, trainingName }: { url: string, trainingName: string }) {
	return (
		<div css="position: relative; padding-bottom: 25%; width: clamp(20vw, 70vw, 70%); height: clamp(10vh, 35vw, 60vh);">
			<iframe
				title={trainingName + ' Training Video'}
				src={url}
				frameBorder="0"
				webkitallowfullscreen
				mozallowfullscreen
				allowFullScreen
				css="position: absolute; top: 0; left: 0; width: 100%; height: 100%;"></iframe>
		</div>
	)
}

/**
 * The button that controls the expanding and collapsing of the training content.
 */
const DropdownButton = styled.button`
	display: flex;
	align-items: center;
	width: 100%;
	justify-content: space-between;
	background-color: inherit;
	font-size: inherit;
	font-weight: inherit;
	border: 0;
	padding: var(--spacing1x-dont-use) 0;
	&:focus {
		outline: none;
	}
	&:focus-visible {
		outline: var(--primary) auto 1px;
	}
`

export default TrainingStage
