import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { Marquee } from '../../Marquee'
import { MdInfoOutline } from 'react-icons/lib/md'
import CustomModal, {
	ModalHeader,
	ModalFooter,
	ModalBody,
	ExitX,
} from '../../../components/modal/Modal'
import { Button } from '@mission.io/styles'
import LoadingSpinner from '../../../styles/LoadingSpinner'
import {
	analyticsViewOptions,
	ICON_STYLES,
	TOP_SCORES,
	TOP_DISTRICTS,
	TOP_SCHOOLS,
} from './constants'
import { getValueById } from './helpers'

import type { ViewBoxItem } from './types'
import type { ViewOptionType } from './constants'
import classnames from 'classnames'

/**
 * Displays a list of scores in a box with a title and icon
 * @param props.type - the type of scores to display. This type determines the title and icon for the list
 * @param props.title - If given, overrides the title from the type
 * @param props.scores - the data to display in the list
 * @param props.explanation - the explanation of the scores in the list. Displayed when hovering over the info icon
 * @param props.isLoading - whether the data is still loading. If true, a loading spinner is displayed instead of the scores
 */
export function ScoresList({
	type,
	title: passedTitle,
	scores,
	explanation: passedExplanation,
	isLoading,
	className,
}: {|
	type: ViewOptionType,
	title?: string,
	scores: $ReadOnlyArray<ViewBoxItem>,
	explanation?: string,
	isLoading: boolean,
	className?: string,
|}): React$Node {
	const [showModal, setShowModal] = useState(false)
	const { title, image: IconImage } = analyticsViewOptions[type]
	const isLeaderboardBox = type === TOP_SCHOOLS || type === TOP_DISTRICTS
	const explanation = passedExplanation || analyticsViewOptions[type].info

	return (
		<div className={classnames('flex flex-col rounded-lg bg-primary-750', className)}>
			<div className="flex-1 flex flex-col items-center p-4">
				<IconImage style={ICON_STYLES} />
				<div className="tracking-wide p-2 font-bold">
					{passedTitle || title}
					<InfoSymbol title={explanation}>
						<MdInfoOutline className="inline" />
					</InfoSymbol>
				</div>

				{!isLoading ? (
					<ol className="flex flex-col gap-2 w-full">
						<Items data={scores.slice(0, 5)} id={type} />
					</ol>
				) : (
					<LoadingSpinner
						shouldShowSpinner
						className="border-t-celestial-blue"
						style={{
							width: '30px',
							height: '30px',
							borderTopWidth: '6px',
							borderWidth: '6px',
						}}
					/>
				)}
			</div>
			<Button className="rounded-t-none" onClick={() => setShowModal(true)}>
				SHOW {isLeaderboardBox ? 'MORE' : 'ALL'}
			</Button>
			<CustomModal
				shouldCloseOnEsc
				shouldCloseOnOverlayClick
				isOpen={showModal}
				onRequestClose={() => setShowModal(false)}
				style={{
					content: {
						top: '13%',
						width: '65%',
					},
				}}>
				<ModalHeader>
					{title}
					<ExitX onClick={() => setShowModal(false)} />
				</ModalHeader>
				<ModalBody>
					<ol className="grid grid-rows-5 grid-flow-col overflow-x-auto gap-2">
						<Items data={scores} id={type} />
					</ol>
				</ModalBody>
				<ModalFooter>
					<Button outline onClick={() => setShowModal(false)}>
						{type === TOP_SCORES ? 'Not Now' : 'Close'}
					</Button>
					{type === TOP_SCORES && (
						<Link to={{ pathname: '/analytics' }}>
							<Button className="mx-2">See Full Data</Button>
						</Link>
					)}
				</ModalFooter>
			</CustomModal>
		</div>
	)
}

const InfoSymbol = styled.span`
	cursor: pointer;
	${({ theme }) => `
		margin-left: var(--spacing1x-dont-use);
	`}
`

/**
 * The items in `data`, styled to be displayed in a grid or flex box
 */
function Items({ data, id }: { data: $ReadOnlyArray<ViewBoxItem>, id: ViewOptionType }) {
	if (data.length === 0) {
		return 'No Data to Show'
	}
	return data.map(({ name, score }, index) => {
		return (
			<li
				key={String(name + index)}
				className="rounded text-xs p-2 flex justify-between items-center overflow-hidden border !border-neutral">
				<NameSpan>{String(index + 1) + '. ' + name}</NameSpan>
				<span>{getValueById(score, id)}</span>
			</li>
		)
	})
}

const NameSpan = styled(Marquee)`
	width: 75%;
	text-overflow: ellipsis;
	:hover {
		text-overflow: initial;
	}
`
