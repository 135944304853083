import React from 'react'
import { ModalHeader } from '../../../components/modal/Modal'
import styled from 'styled-components/macro'
import { MdStar, MdStarOutline } from 'react-icons/lib/md'
import { toast } from 'react-toastify'
import { useUser } from '../../../services/hooks'
import {
	useAddFavoriteQuestionGroup,
	useDeleteFavoriteQuestionGroup,
} from '../../../services/hooks/user'
import SimpleConfirmation, {
	ACTIONABLE_TOAST_PROPERTIES,
} from '../../../components/helpers/SimpleConfirmation'
import type { QuestionGroupWithFavorite } from '../../../models'
import RequireSignIn from '../../../components/helpers/RequireSignIn'

export default function QuestionGroupView({
	questionGroup,
	onClick,
}: {|
	questionGroup: QuestionGroupWithFavorite,
	onClick: () => void,
|}): React$Node {
	const { firstName, lastName } = questionGroup.createdBy || {}
	const subjectsName = questionGroup.subjects?.map(s => s.name).join(', ') || ''

	return (
		<li
			onClick={onClick}
			className="duration-300 cursor-pointer flex-[1_0_20%] list-none rounded-lg hover:shadow-md overflow-hidden">
			<ModalHeader className="bg-primary-500">
				<span>{questionGroup.name}</span>
				<RequireSignIn>
					<FavoriteStar questionGroup={questionGroup} />
				</RequireSignIn>
			</ModalHeader>
			<p className="p-2 bg-primary-700">
				Subject: {subjectsName}
				<br />
				Grade: {questionGroup.grades.join(', ')}
				<br />
				Creator: {firstName} {lastName}
			</p>
		</li>
	)
}

/**
 * This component is a button to make/remove a
 * question group to favorites
 * @param {{isFavorite, questionGroupId}} params
 * @returns React$Node
 */
export const FavoriteStar = ({
	questionGroup,
}: {
	questionGroup: QuestionGroupWithFavorite,
}): React$Node => {
	const { user } = useUser()
	const userId = user?._id
	const isFavorite = questionGroup.isFavorite
	const addFavoriteQuestionGroupMutation = useAddFavoriteQuestionGroup(userId ?? '')
	const deleteFavoriteQuestionGroupMutation = useDeleteFavoriteQuestionGroup(userId ?? '')

	const handleFavorite = (closeToast?: () => void) => {
		if (closeToast) closeToast()
		if (!isFavorite) {
			addFavoriteQuestionGroupMutation.mutate(
				{ ...questionGroup, subjects: questionGroup.subjects?.map(s => s.id) ?? [] },
				{
					onError: err => {
						let errorMessage =
							err.status === 404
								? err.message
								: 'Our bad! Error favoriting question group'
						toast.error(errorMessage)
					},
				}
			)
		} else {
			deleteFavoriteQuestionGroupMutation.mutate(questionGroup._id, {
				onSuccess: () => {
					toast.success('Question group was removed from favorites')
				},
			})
		}
	}

	const alertConfirmation = () => {
		toast.info(
			({ toastProps, ...props }) => {
				return (
					<SimpleConfirmation
						message={
							'Are you sure you want to remove this question group from your favorites?'
						}
						confirmText={'Unfavorite'}
						onConfirm={() => {
							handleFavorite(toastProps?.closeToast)
						}}
						onCancel={() => {
							toastProps?.closeToast()
						}}
					/>
				)
			},
			{
				...ACTIONABLE_TOAST_PROPERTIES,
				position: toast.POSITION.BOTTOM_CENTER,
			}
		)
	}
	return userId ? (
		<StarWrapper
			className="bg-primary-800 cursor-pointer"
			title={isFavorite ? 'Remove from favorites' : 'Add to favorites'}
			onClick={e => {
				e.stopPropagation()
				if (isFavorite) alertConfirmation()
				else handleFavorite()
			}}>
			{isFavorite ? (
				<MdStar className="text-star" />
			) : (
				<MdStarOutline className="text-star" />
			)}
		</StarWrapper>
	) : (
		<></>
	)
}

const StarWrapper = styled.button`
	border: none;
	box-shadow: none;
	padding: 0px;
	border-radius: 50%;
	display: flex;
	&:hover {
		background-color: var(--app-dark-gray);
	}
	svg {
		margin: var(--spacing-half-dont-use);
		&:hover {
			opacity: 0.7;
		}
	}
`
