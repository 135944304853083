import React from 'react'
import { toast } from 'react-toastify'
import LinkIcon from 'react-icons/lib/md/link'
import 'styled-components/macro'
import { Tooltip } from 'react-tooltip'

/**
 * @param text the text to be copied
 * @param failureMessage an optional string with the text to be shown on failure
 * @param successMessage an optional string with the text to be shown on success
 * Writes the given text to the clipboard. If it fails, a failure toast is shown.
 * If it succeeds, a success toast is shown.
 *
 */
export function copyText({
	text,
	failureMessage,
	successMessage,
}: {
	text: string,
	failureMessage?: string,
	successMessage?: string,
}) {
	const onError = err => {
		console.error(err)
		toast.error(failureMessage || `Failed to copy text to clipboard`, {
			position: toast.POSITION.TOP_RIGHT,
		})
	}
	try {
		navigator.clipboard
			.writeText(text)
			.then(() => {
				toast.success(successMessage || `Copied text to clipboard!`, {
					position: toast.POSITION.TOP_RIGHT,
				})
			})
			.catch(onError)
	} catch (err) {
		onError(err)
	}
}
/**
 * A component that allows the user to copy a value to clipboard. Takes 2 props: link and tooltip
 * The link is the value copied to clipboard
 * Tooltip is the text/component the user sees when they hover over the link explaining what will be copied.
 * If `tooltip` is not provided, then no tooltip will be rendered
 * @returns
 */
export default function CopiableLink({
	link,
	tooltip,
}: {
	link: string,
	tooltip?: React$Node,
}): React$Node {
	const tooltipId = `link-${link}`

	return (
		<>
			<div
				data-tooltip-id={tooltipId}
				css="display: inline-flex; align-items: right; cursor: pointer;"
				onClick={() =>
					copyText({
						text: link,
						failureMessage: 'Failed to copy link to clipboard',
						successMessage: 'Copied link to clipboard!',
					})
				}>
				<LinkIcon css=" margin: auto 4px;" />
				{link}
			</div>
			{tooltip && (
				<Tooltip id={tooltipId}>
					<span>{tooltip}</span>
				</Tooltip>
			)}
		</>
	)
}
