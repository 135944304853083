import type { Simulation, IdMap, ReduxQuestionGroup, AutomatedSimulation } from '../models'
import type { ClientQuestion as Question } from '@mission.io/question-toolkit'
import type { APMDetails, SelScores, AnalyticsObject } from '../models/Analytics'
import type { Student_New } from '../models/Student'
import type { ClassType } from '../models/ClassType'
import type { Promotion } from '../utility/types'
export type { APMDetails, SelScores, AnalyticsObject }
export type { CategoryStore } from './categories'

export type Action = {
	type: string,
	payload?: mixed,
}

export type StageAction = {
	type: 'MARK_COMPLETE' | 'MARK_INCOMPLETE',
	stageId: string,
	timestamp: number,
}

export const rolesEnum = {
	DEPRECATED_SCHOOL_ADMIN: 'DEPRECATED_SCHOOL_ADMIN',
	SCHOOL_ADMIN: 'SCHOOL_ADMIN',
	DISTRICT_ADMIN: 'DISTRICT_ADMIN',
	LICENSE_ADMIN: 'LICENSE_ADMIN',
}

export type PermissionsObject = {
	canRunOnSiteMission: boolean,
	simulationGroups: string[], // a list of all the simulation groups the user has access to
	baseSimulationGroups: string[], // a list of all the simulation groups the user has access to (excluding promotions)
	promotionSimulationGroups: string[], // a list of all simulation groups that the user's active promotions have access to
	expiredPromotionSimulationGroups: string[], // a list of all simulation groups that the user's expired promotions have access to
	promotions: Array<Promotion>, // a list of all the promotions tied to the user's account
	isFreeAccount: boolean,
	usingGracePeriod: boolean,
}

export type SimulationGroup = {
	_id: string,
	name: string,
	canRun: string[],
	canView: string[],
	defaultPublish: { canView: boolean, canRun: boolean },
	isMajorGroup: boolean,
	unlockMessageType?: 'PAID_LICENSE',
}

export type Role = {
	role: $Keys<typeof rolesEnum>,
	verified: boolean,
	reasonForRejection?: string,
	verifier?: string,
}

type LinkedAccount =
	| {|
			type: 'clever',
			cleverId: string,
	  |}
	| {|
			type: 'google',
			googleResourceId: string,
	  |}

export type User = {
	_id: string,
	email: string,
	firstName?: string,
	lastName?: string,
	state: string,
	schoolName?: string,
	schoolId?: string,
	districtId?: string,
	roles: Role[],
	training?: {
		stageActions: StageAction[],
	},
	email_verification: {
		confirmed: boolean,
	},
	onboarding: {
		isOnboardingFinished: boolean,
		didUserStartMission: boolean,
	},
	classes: ClassType[],
	linkedAccounts: Array<LinkedAccount>,
}

export type UserStore = ?User

export type OnboardingPopupStore = {
	isClassesCreated: boolean,
	isMissionPrep: boolean,
	isMissionRun: boolean,
}

export type LoadingState = 'NOT_LOADED' | 'LOADING' | 'LOADED'

export const missionCategoriesEnum = {
	ON_SITE: 'ON_SITE',
	WHOLE_CLASS_REMOTE: 'WHOLE_CLASS_REMOTE',
	SQUADRON_REMOTE: 'SQUADRON_REMOTE',
	SQUADRON_HOMEWORK: 'SQUADRON_HOMEWORK',
}

export type MissionCategory = $Keys<typeof missionCategoriesEnum>

export type QuestionsStore = {
	allQuestions: IdMap<Question>,
	allQuestionGroups: IdMap<ReduxQuestionGroup>,
	questionGroupsLoadingState: LoadingState,
}

export type SimulationsStore = {
	missionTypes: ?Array<Simulation>,
	questTypes: ?Array<Simulation>,
	automatedSimulations: ?Array<AutomatedSimulation>,
}

export type JuniorTeam = {|
	id: string,
	displayName: string,
	type: string,
	minStudents: number,
	maxStudents: number,
	lead: ?string,
	students: string[],
	icon: string,
	isTeacherTeam: boolean,
	isRequired: boolean,
|}

export type PreppedMission = {
	crew?: {
		students?: Array<Student_New>,
		classId?: string,
	},
	juniorTeams: ?(JuniorTeam[]),
	simulationId: string,
	missionCategory: MissionCategory,
	questions: Question[],
	beginPrepTimestamp?: Date,
	// the following fields are not required on mission-server to create a mission
	// but we need them locally to prepare the mission
	useJrPlus: boolean,
	// this field is needed to know how to edit a mission
	_id?: string,
}

export type MissionPrepStore = {
	mission: ?PreppedMission,
	defaultQuestions: ?Array<Question>,
}
