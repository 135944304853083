// @flow
import React from 'react'
import { toast } from 'react-toastify'
import { Button } from '@mission.io/styles'
import 'styled-components/macro'
import CustomModal, {
	ModalHeader,
	ExitX,
	ModalBody,
	ModalFooter,
} from '../../../components/modal/Modal'
import { getEmailsFromString } from '../helpers'
import FileLoader from '../../../components/helpers/FileLoader'

const CSVInstructions = `In order to upload correctly, please see the format example by clicking the
‘Download Example’ button below. In your spreadsheet, box A1 should be the word
email. The first email you enter should then be in A2, then A3, and so on with
the rest of your emails. Make sure your file is in CSV format before uploading.
If you are having trouble uploading your own file, try downloading the example
csv then replace the example email data with your own and upload. If the file
won’t download, try allowing popups from this site.`

type Props = {
	isOpen: boolean,
	onComplete: (string[]) => void,
	onRequestClose: () => void,
}

/**
 * Opens a FileLoader that allows a user to upload CSV
 * files with emails.
 */
export default function CSVEmailModal({ isOpen, onComplete, onRequestClose }: Props): React$Node {
	return (
		<CustomModal isOpen={isOpen} onRequestClose={onRequestClose}>
			<ModalHeader>
				Upload email info in .csv format
				<ExitX
					role={'button'}
					title={'Close'}
					aria-label={'Close CSV Upload Dialog'}
					onClick={onRequestClose}
				/>
			</ModalHeader>
			<ModalBody>
				<p>{CSVInstructions}</p>
			</ModalBody>
			<ModalFooter css="justify-content: space-between;">
				<FileLoader
					wantedFields={WANTED_CSV_FIELDS}
					onLoad={importedEmails => {
						if (importedEmails.length < 1) {
							toast.error('Email list must have at least 1 email!')
						} else {
							const csvData = importedEmails
								.map(emailObject => emailObject.email)
								.join(' ')
							// don't upload bad data, so find the emails from the uploaded data
							const [csvEmails] = csvData ? getEmailsFromString(csvData) : [null]
							if (csvEmails) {
								onComplete(csvEmails)
							}
							onRequestClose()
						}
					}}
					onError={(error: any) => {
						toast.error(
							'Error uploading file! Please ensure it is formatted correctly.'
						)
						console.error('CSV Email Upload error: ', error)
					}}
					exampleData={EXAMPLE_DATA}
				/>
				<Button outline onClick={() => onRequestClose()}>
					Cancel
				</Button>
			</ModalFooter>
		</CustomModal>
	)
}

const WANTED_CSV_FIELDS = [
	{ field: 'email', aliases: ['address', 'emailAddress', 'email address'] },
]

const EXAMPLE_DATA = [
	['email'],
	['example@example.com'],
	['test@test.com'],
	['teacher@teacher.com'],
]
