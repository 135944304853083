import React, { useState } from 'react'
import FaArrowLeft from 'react-icons/lib/fa/arrow-left'
import FaArrowRight from 'react-icons/lib/fa/arrow-right'
import EmptyMissionImage from '../../assets/EmptyMissionImage.png'
import { BEGIN_PREP, START_MISSION, OPEN_MISSION } from '../../constants'
import { sortMissions } from '../../utility/helpers'
import MissionReviewBox from './MissionReviewBox'
import { PAUSED, PREPPED } from './constants'
import LoadingSpinner from '../../styles/LoadingSpinner'
import { useUnfinishedMissions } from '../../queryHooks/mission'
import type { SimpleMission } from '../../models/Mission'
import { useUserClasses } from '../../services/hooks/classes'
import { Button } from '@mission.io/styles'
import { ExitX } from '../../components/modal/Modal'
import classnames from 'classnames'

/**
 * If a teacher has no missions, we want to show them Jerem's picture and text.
 * This function gets it for us, and since it is outside the functional component,
 * it is not re-rendered all the time.
 */
function NoMissionsImage() {
	return (
		<>
			<h2 className="text-4xl mb-3">Prepped Missions</h2>
			<div className="text-center flex flex-col items-center">
				<img src={EmptyMissionImage} className="h-[20vw] p-6" alt="" />
				<p className="text-2xl">No mission yet?</p>
				<p className="text-2xl">Let&#39;s get started! Go ahead and look </p>
				<p className="text-2xl">through the mission library</p>
			</div>
		</>
	)
}

/**
 * A hook that returns a disabled state and a function that will set disabled to true for a short time
 */
function useQuickDisable(): [boolean, () => void] {
	const [disabled, setDisabled] = useState(false)
	const disable = () => {
		setDisabled(true)
		setTimeout(() => setDisabled(false), 200)
	}
	return [disabled, disable]
}

export const SEE_MISSIONS_ID = 'see_missions_button'
export const HIDE = 'Hide'

export default function MissionReview(): React$Node {
	const [showPreppedMissions, setShowPreppedMissions] = useState(false)
	const { data: unfinishedMissions, isLoading, error } = useUnfinishedMissions()
	const { isLoading: isLoadingClasses } = useUserClasses()
	const [disabled, quickDisable] = useQuickDisable()

	const unfinishedMissionArray: SimpleMission[] = (Object.values(unfinishedMissions || {}): any)
	// order the unfinished missions by most recently prepped to oldest
	const sortedUnfinishedMissions = sortMissions(unfinishedMissionArray, BEGIN_PREP)

	let pausedMissions = []
	let preppedMissions = []

	sortedUnfinishedMissions.forEach(mission => {
		if (
			mission.events.some(
				event => event.type === START_MISSION || event.type === OPEN_MISSION
			)
		) {
			pausedMissions.push(mission)
		} else {
			preppedMissions.push(mission)
		}
	})

	const onToggle = () => {
		if (disabled) return
		quickDisable()
		if (!showPreppedMissions) {
			window.scrollTo(0, 0)
		}
		setShowPreppedMissions(state => !state)
	}

	return (
		<div
			className={classnames(
				'bg-primary-900 z-50 shadow fixed top-[var(--header-height)] bottom-0 right-0 w-fit duration-700 transition-transform',
				!showPreppedMissions && 'translate-x-full'
			)}
			showPreppedMissions={showPreppedMissions}
			data-walkthrough="prepped-missions">
			{/* relative anchor for the show/hide button */}
			<div className="relative">
				<Button
					data-walkthrough="see-prepped-missions"
					onClick={onToggle}
					id={SEE_MISSIONS_ID}
					className="flex items-center justify-between gap-2 absolute top-8 rounded-r-none -translate-x-full after:w-2">
					{showPreppedMissions ? (
						<>
							<FaArrowRight /> {HIDE}
						</>
					) : (
						<>
							<FaArrowLeft />
							My Missions
						</>
					)}
				</Button>
			</div>
			<div className="overflow-y-auto p-4 max-h-full">
				{isLoading || isLoadingClasses ? (
					<div className="text-xl mt-8 flex flex-col items-center justify-center gap-8">
						Loading Your Missions...
						<LoadingSpinner
							shouldShowSpinner={true}
							className="size-12 border-4 border-[--primary]"
						/>
					</div>
				) : error ? (
					<div className="text-xl mt-8 flex flex-col items-center justify-center gap-8 text-center text-[--error]">
						An Error Occurred While Getting Your Missions
					</div>
				) : sortedUnfinishedMissions.length === 0 ? (
					<NoMissionsImage />
				) : (
					<div className="flex flex-col gap-8">
						<MissionReviewBox type={PAUSED} missions={pausedMissions} />
						<MissionReviewBox type={PREPPED} missions={preppedMissions} />
					</div>
				)}
				<ExitX onClick={onToggle} className="absolute top-4 right-4 sm:hidden" />
			</div>
		</div>
	)
}
