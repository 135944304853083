import React from 'react'
import styled from 'styled-components/macro'
import { BackMenu, Loading } from '../../../components'
import { useSelector } from 'react-redux'
import { getLongDate } from '../../../services/DateService'
import { useUser } from '../../../services/hooks'
import {
	useFavoriteQuestionGroups,
	useIsDistrictAdmin,
	useUserDistrictId,
} from '../../../services/hooks/user'
import { OWNER_TYPES } from '../../../models/QuestionGroup'
import EditQuestionGroup from './EditQuestionGroup'
import { Question } from '@mission.io/question-views'
import { convertQuestionGroup, useQuestionGroup } from '../../../services/hooks/questionGroup'
import { FavoriteStar } from './QuestionGroupCard'
import { getQuestionCategories } from '../../../store/categories'
import { toIdMap } from '../../../utility/functions'

export default function QuestionGroup({
	leave,
	questionGroupId,
}: {
	leave: () => void,
	questionGroupId: string,
}): React$Node {
	const { data: _questionGroup, isLoading } = useQuestionGroup(questionGroupId)
	const categoryLookup = toIdMap(useSelector(getQuestionCategories) ?? [])
	const { user } = useUser()
	const userDistrictId = useUserDistrictId()
	const isUserDistrictAdmin = useIsDistrictAdmin()

	const questionGroup = _questionGroup
		? convertQuestionGroup(_questionGroup, categoryLookup)
		: null

	const canEdit =
		user &&
		questionGroup &&
		((questionGroup.owner.type === OWNER_TYPES.USER && user._id === questionGroup.owner.id) ||
			(userDistrictId &&
				questionGroup.owner.type === OWNER_TYPES.DISTRICT &&
				questionGroup.owner.id === userDistrictId &&
				isUserDistrictAdmin))

	const favoriteQuestionGroups = useFavoriteQuestionGroups()
	const isFavorite = favoriteQuestionGroups?.some(q => q._id === questionGroup?._id)

	const headerWrapper = questionGroup && (
		<HeaderWrapper className="flex-grow">
			<div className="text-2xl my-2">{questionGroup.name}</div>
			<FavoriteStar questionGroup={{ ...questionGroup, isFavorite: !!isFavorite }} />
		</HeaderWrapper>
	)
	if (isLoading) {
		return <Loading css=" width: auto; height: 100px;" />
	}
	return (
		<div css="height: 100%;">
			<BackMenu onClick={leave}>Question Library</BackMenu>

			{!questionGroup ? (
				<div className="text-2xl my-2">
					Invalid Question Group. Go back to the Question Library to make one!
				</div>
			) : canEdit ? (
				<EditQuestionGroup
					headerWrapper={headerWrapper}
					questionGroup={questionGroup}
					goBack={leave}
				/>
			) : (
				<>
					{headerWrapper}
					<InfoGroup>
						<Info>
							<span className="font-semibold">Created on: </span>
							{getLongDate(questionGroup.createdAt)}
						</Info>
						<Info>
							<span className="font-semibold">Subject: </span>
							{questionGroup.subjects ? (
								questionGroup.subjects.map(s => s.name)?.join(', ')
							) : (
								<>None</>
							)}
						</Info>
						<Info>
							<span className="font-semibold">Grade: </span>
							{questionGroup.grades.join(', ')}
						</Info>
						<Info>
							<span className="font-semibold">Creator: </span>
							{questionGroup.createdBy.firstName} {questionGroup.createdBy.lastName}
						</Info>
					</InfoGroup>
					<hr />
					<GridList>
						{questionGroup.questions.length > 0
							? questionGroup.questions.map((question, index) => (
									<div key={question._id}>
										<Question
											readOnly
											teacherQuestion
											id={question._id}
											question={question}
											questionIndex={index}
										/>
										{index < questionGroup.questions.length - 1 && <hr />}
									</div>
							  ))
							: 'No Questions'}
					</GridList>
				</>
			)}
		</div>
	)
}

const Info = styled.div`
	:not(:last-child) {
		margin-right: var(--spacing4x-dont-use);
	}
`

const InfoGroup = styled.div`
	display: flex;
`
const HeaderWrapper = styled.div`
	display: flex;
	gap: var(--spacing-half-dont-use);
	align-items: center;
	div {
		margin: 0px !important;
	}
`

const GridList = styled.div`
	display: grid;
	grid-layout-column: 1fr 1fr;
	scroll: auto;
	// TODO: Remove this style when question-views is updated
	.question-phrase {
		padding-left: 8px;
	}
`
