// @flow
import React, { createContext, useMemo, useState, useContext, useEffect } from 'react'
import { useLocation } from 'react-router-dom'

export type FileToView = {
	src: string,
	name?: ?string,
	preview?: ?string, // will display this file in the file viewer if defined and truthy
	disallowDownload?: ?boolean, // true if the file should not be downloadable
	captions?: ?string, // for videos only
}

const FileViewerContext = createContext<{
	fileToView: ?FileToView,
	setFileToView: (fileSrc: ?FileToView) => void,
}>({
	fileToView: null,
	setFileToView: () => {},
})

/**
 * FileViewerProvider - context used for the fileViewer to easily show files to the user
 *
 * @param {{children:React$Node}} {children} - the children the context should be applied to (should have one FileViewer component so file can be shown the user)
 *
 * @return {React$Node} - the context provider
 */
export function FileViewerProvider({ children }: { children: React$Node }): React$Node {
	const [fileToView, setFileToView] = useState<?FileToView>(null)

	const value = useMemo(() => ({ fileToView, setFileToView }), [fileToView])

	// close file viewer when navigating to a new page
	const { pathname } = useLocation()
	useEffect(() => {
		setFileToView(null)
	}, [pathname])

	return <FileViewerContext.Provider value={value}>{children}</FileViewerContext.Provider>
}

/**
 * useFileViewerFile - get the data of the file to show the user (null/undefined if no file to show)
 *
 * @return {?FileToView} - the data of the file to show to the user
 */
export function useFileViewerFile(): ?FileToView {
	return useContext(FileViewerContext).fileToView
}

/**
 * useShowFileInFileViewer - get a callback to use which will allow viewing a file in the browser
 *
 * @return {(fileSrc: ?FileToView) => void} - call with a file source to show the file to the user
 */
export function useShowFileInFileViewer(): (fileSrc: ?FileToView) => void {
	return useContext(FileViewerContext).setFileToView
}
