export const MISSION_TYPES = {
	STANDARD: 'STANDARD',
	JUNIOR: 'JUNIOR',
	JUNIOR_PLUS: 'JUNIOR_PLUS',
}

export const ONBOARDING_ITEMS = {
	INTRO: 'Watch Intro (4:42)',
	CLASS: 'Create a Class (2:33)',
	PREP_MISSION: 'Prep a Mission (4:28)',
	RUN_MISSION: 'Run a Mission (6:05)',
}

export const ONBOARDING_FINISHED_MODAL = 'ONBOARDING_FINISHED_MODAL'

export const SUITABLE_ONBOARDING_ROUTES: {
	[link: $Values<typeof ONBOARDING_ITEMS>]: string,
} = {
	[ONBOARDING_ITEMS.INTRO]:
		'/training?training=basicTraining&trainingStage=gettingstarted-launch',
	[ONBOARDING_ITEMS.CLASS]:
		'/training?training=basicTraining&trainingStage=creatingandeditingclasses',
	[ONBOARDING_ITEMS.PREP_MISSION]:
		'/training?training=basicTraining&trainingStage=creatingandeditingmissions',
	[ONBOARDING_ITEMS.RUN_MISSION]: '/training?training=basicTraining&trainingStage=gettingstarted',
}
const EVENTS = {
	END_MISSION: 'END_MISSION',
	OPEN_MISSION: 'OPEN_MISSION',
	START_MISSION: 'START_MISSION',
	BEGIN_PREP: 'BEGIN_PREP',
	SAVE_FOR_LATER: 'SAVE_FOR_LATER',
}
export const END_MISSION = EVENTS.END_MISSION
export const OPEN_MISSION = EVENTS.OPEN_MISSION
export const START_MISSION = EVENTS.START_MISSION
export const BEGIN_PREP = EVENTS.BEGIN_PREP
export const SAVE_FOR_LATER = EVENTS.SAVE_FOR_LATER
export type MissionEvents = $Keys<typeof EVENTS>

export const ALPHABET: string[] = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('')

export const ONE_SECOND = 1000 // ms
export const ONE_MINUTE = ONE_SECOND * 60
export const ONE_HOUR = 60 * ONE_MINUTE
export const ONE_DAY = 24 * ONE_HOUR

export const AUTOMATED_SIMULATION_STATUSES = {
	UNAVAILABLE: 'UNAVAILABLE',
	COMING_SOON: 'COMING_SOON',
	NEW: 'NEW',
	ACTIVE: 'ACTIVE',
	BETA: 'BETA',
	UNDER_CONSTRUCTION: 'UNDER_CONSTRUCTION',
}

export const ENTER_KEY = 'Enter'
export const TAB_KEY = 'Tab'

export const CUSTOM_CLASS_NAME_ACTIONABLE_TOAST = 'actionable-toast'
export const CUSTOM_CLASS_NAME_ACTIONABLE_TOAST_BODY = 'actionable-toast-body'

export const STATE_KEYS = {
	restoreScrollToElementId: 'restoreScrollToElementId',
	restoreToTop: 'restoreToTop',
}

export const AUTO_DEMO_ROUTE = '/demo'

export const QUESTION_ATTEMPTS_TO_SHOW_ON_TABLE = 3
