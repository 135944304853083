import {
	HomeworkIcon,
	SchoolIcon,
	TimerIcon,
	TrophyIcon,
	DeskIcon,
	StairsIcon,
} from '../../../assets/icons/analyticsIcons'
import { rolesEnum } from '../../../store/types'

export const TEACHER = 'TEACHER'
export const SCHOOL_ADMIN = rolesEnum.SCHOOL_ADMIN
export const DEPRECATED_SCHOOL_ADMIN = rolesEnum.DEPRECATED_SCHOOL_ADMIN
export const DISTRICT_ADMIN = rolesEnum.DISTRICT_ADMIN
export const LICENSE_ADMIN = rolesEnum.LICENSE_ADMIN

export const TOP_SCORES = 'TOP_SCORES'
export const MOST_IMPROVED = 'MOST_IMPROVED'
export const HIGHEST_USAGE = 'HIGHEST_USAGE'
export const LOCAL = 'LOCAL'
export const TOP_SCHOOLS = 'TOP_SCHOOLS'
export const TOP_DISTRICTS = 'TOP_DISTRICTS'
export const DEATHS = 'DEATHS'
export const PROBLEMS_SOLVED = 'PROBLEMS_SOLVED'

export const ICON_STYLES = { width: '25%' }

type ViewOptions = { title: string, info: string, image: React$ComponentType<{}> }

export type ViewOptionType =
	| 'TOP_SCORES'
	| 'MOST_IMPROVED'
	| 'HIGHEST_USAGE'
	| 'TOP_SCHOOLS'
	| 'TOP_DISTRICTS'
	| 'LOCAL'

export const analyticsViewOptions: { [type: ViewOptionType]: ViewOptions } = {
	TOP_SCORES: {
		title: 'Top Scores',
		info: 'These rankings are by average overall score.',
		image: TrophyIcon,
	},
	MOST_IMPROVED: {
		title: 'Most Improved',
		info: 'These rankings are by average overall score change.',
		image: StairsIcon,
	},
	HIGHEST_USAGE: {
		title: 'Highest Usage',
		info: 'These rankings are by total time in hours spent running Missions.',
		image: TimerIcon,
	},
	TOP_SCHOOLS: {
		title: 'Top Schools',
		info: '<Comes From Server>',
		image: HomeworkIcon,
	},
	TOP_DISTRICTS: {
		title: 'Top Districts',
		info: '<Comes From Server>',
		image: SchoolIcon,
	},
	LOCAL: {
		title: 'Local',
		info: 'These rankings are by highest mission score average.',
		image: DeskIcon,
	},
}
