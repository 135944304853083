import React from 'react'
import FaTrashO from 'react-icons/lib/fa/trash-o'
import { IconCircleWrapper } from '../../styles/sharedComponents'
import { ENTER_KEY, TAB_KEY } from '../../constants'
import type { Student_New, NewStudent as NewStudentType } from '../../models/Student'
import { Input } from '../../components/inputs/Input'

type Props<StudentType: NewStudentType | Student_New> = {
	student: StudentType,
	index: number,
	onChange: StudentType => mixed,
	onFocus: () => mixed,
	onPressEnter: (SyntheticKeyboardEvent<>) => mixed,
	onPressTabFromLastInput: (SyntheticKeyboardEvent<>) => mixed,
	removeStudent: Function,
	firstNameRef: () => mixed,
}

export default function NewStudent<StudentType: NewStudentType | Student_New>({
	index,
	student,
	onChange,
	onFocus,
	onPressEnter,
	onPressTabFromLastInput,
	removeStudent,
	firstNameRef,
}: Props<StudentType>): React$Node {
	return (
		<div className="flex text-xs gap-2">
			<Input
				className="min-w-20 flex-auto"
				required={student.lastName !== ''}
				type="text"
				placeholder="First Name"
				value={student.firstName}
				onChange={e => onChange({ ...student, firstName: e.currentTarget.value })}
				onKeyDown={e => {
					if (e.code === ENTER_KEY) {
						e.preventDefault()
						onPressEnter(e)
					}
				}}
				onFocus={onFocus}
				ref={firstNameRef}
				aria-label="First Name"
			/>
			<Input
				className="min-w-20 flex-auto"
				required={student.firstName !== ''}
				type="text"
				placeholder="Last Name"
				value={student.lastName}
				onFocus={onFocus}
				onChange={e => onChange({ ...student, lastName: e.currentTarget.value })}
				onKeyDown={e => {
					if (e.code === ENTER_KEY) {
						e.preventDefault()
						onPressEnter(e)
					} else if (e.code === TAB_KEY) {
						onPressTabFromLastInput(e)
					}
				}}
				aria-label="Last Name"
			/>
			<IconCircleWrapper
				onClick={removeStudent}
				type="button"
				aria-label="Delete Student"
				className="bg-primary-250">
				<FaTrashO size="15" className="text-primary-950" />
			</IconCircleWrapper>
		</div>
	)
}
