import { useMemo } from 'react'
import { useFinishedMissions } from '../../queryHooks/mission'
import { useUser } from '../../services/hooks'
import STEPS, { type Step } from './steps'
import { useQueryClient } from 'react-query'
import type { AutomatedSimulation } from '../../models/Simulation'
import type { ReduxStore } from '../../store/rootReducer'
import { useSelector } from 'react-redux'
import { useTrainingCategoryId } from '../../store/categories'
import {
	CONDITIONALS,
	INTERACTION_QUERY_KEY,
	getLatestInteractionDate,
	useUserInteractions,
} from './userGuideHelpers'
import type { Conditional, GenerateStepsArg } from './userGuideHelpers'
import { useLocation } from 'react-router-dom'

/**
 * Hook that determines the necessary steps for a user guide based on users interactions
 * @returns {Step[]}
 */
export function useSteps(): Step[] {
	const { data: interactions } = useUserInteractions()
	const { user } = useUser()
	const { data: finishedMissions } = useFinishedMissions()
	const simulations: ?Array<AutomatedSimulation> = useSelector(
		(state: ReduxStore) => state.simulations.automatedSimulations
	)
	const trainingCategoryId = useTrainingCategoryId()
	const location = useLocation()

	return useMemo(() => {
		if (!interactions) {
			return []
		}
		const steps = generateStepsFromConditions({
			user,
			finishedMissions,
			interactions,
			simulations,
			trainingCategoryId,
			pathname: location.pathname,
		})
		return steps
		// Purposefully not including pathname in dependencies so that we don't rerender the steps if the tour changes pages
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [user, finishedMissions, interactions, simulations, trainingCategoryId])
}

/**
 * Generate the steps needed for the user guide based on interactions the user has made
 * and conditions defined in the CONDITIONALS object.
 *
 * @param {?User} user
 * @param {?IdMap<SimpleMission>} missions
 * @param {Interactions} interactions
 * @returns
 */
export function generateStepsFromConditions(options: GenerateStepsArg): Step[] {
	const stepIds = new Set()
	const insertStepChain = (stepId: string) => {
		const step = STEPS[stepId]
		if (!step) {
			return
		}
		stepIds.add(step.id)
		if (step.getNext) {
			insertStepChain(
				step.getNext({ locationPathname: options.pathname, user: options.user })
			)
		} else if (step.next) {
			insertStepChain(step.next)
		}
	}
	const passesCondition: Conditional => boolean = condition => {
		if (condition.type === 'USER_INTERACTION') {
			const interactionDate = getLatestInteractionDate(
				options.interactions[condition.value],
				condition.service
			)

			if (condition.when && typeof condition.when === 'object') {
				if (condition.when.type === 'AFTER_CLICK') {
					const compareDate = getLatestInteractionDate(
						options.interactions[condition.when.clickId],
						condition.when.service
					)
					return Boolean(compareDate && interactionDate && interactionDate > compareDate)
				} else if (condition.when.type === 'BEFORE_CLICK') {
					const compareDate = getLatestInteractionDate(
						options.interactions[condition.when.clickId],
						condition.when.service
					)
					return !compareDate || Boolean(interactionDate && interactionDate < compareDate)
				}
			} else if (condition.when === 'NEVER') {
				return !interactionDate
			} else if (condition.when === 'AT_LEAST_ONCE') {
				return Boolean(interactionDate)
			} else if (condition.when === 'TODAY') {
				const today = new Date()
				return interactionDate
					? today.getFullYear() === interactionDate.getFullYear() &&
							today.getMonth() === interactionDate.getMonth() &&
							today.getDate() === interactionDate.getDate()
					: false
			}
		} else if (condition.type === 'CALLBACK') {
			return condition.fn(options)
		} else if (condition.type === 'OR') {
			return condition.conditions.some(passesCondition)
		} else if (condition.type === 'AND') {
			return condition.conditions.every(passesCondition)
		}
		return false
	}
	CONDITIONALS.forEach(conditional => {
		const conditionPasses = conditional.checkers.every(passesCondition)
		if (conditionPasses) {
			insertStepChain(conditional.step)
		}
	})
	return Array.from(stepIds, stepId => STEPS[stepId])
}

/** Returns a callback that will refetch interactions */
export function useRefetchUserInteractions(): () => void {
	const queryCache = useQueryClient()
	return () => {
		queryCache.invalidateQueries(INTERACTION_QUERY_KEY)
	}
}
