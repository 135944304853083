import React from 'react'
import { useImpression } from '../../queryHooks/impressions'
import { Button } from '@mission.io/styles'

export function SurveyResponseButton({
	onRequestSurveyOpen,
	missionId,
	className,
}: {
	onRequestSurveyOpen: (missionId: string) => void,
	missionId: string,
	className?: ?string,
}): React$Node {
	const { impression } = useImpression(missionId)
	if (impression && !impression.teacher) {
		return (
			<Button onClick={() => onRequestSurveyOpen(missionId)} className={className}>
				Complete Survey
			</Button>
		)
	}
	return <div />
}
