import React from 'react'
import { useState } from 'react'
import {
	ACCOUNT_DATA_VERIFICATION_STATUS,
	useAccountDataVerificationStatus,
} from '../../services/hooks/user'
import Modal, { ModalHeader, ModalBody, ModalFooter } from '../modal/Modal'
import { Button } from '@mission.io/styles'
import { config } from '../../config'
import styled from 'styled-components'

const ACCOUNT_DATA_VERIFICATION_URL = `${config.loginClientUrl}/confirm-account-data?from=DASHBOARD`

/**
 * AccountDataVerificationStatusModal - a component which will force a modal open if the signed in user needs to verify their account data
 *
 * @return {React$Node}
 */
export function AccountDataVerificationStatusModal(): React$Node {
	const { data: status } = useAccountDataVerificationStatus()
	const [isModalClosed, setIsModalClosed] = useState(false)

	if (isModalClosed) {
		return null
	}

	if (
		status === ACCOUNT_DATA_VERIFICATION_STATUS.ALLOWED ||
		status === ACCOUNT_DATA_VERIFICATION_STATUS.REQUIRED
	) {
		return (
			<SmallModal isOpen={true}>
				<ModalHeader>Welcome to a New School Year 🎉</ModalHeader>
				<ModalBody>
					It&apos;s time for our annual information check-up to ensure your account is in
					tip-top shape. This quick process helps us keep your account secure and makes
					sure you have access to all the latest missions and features.
					<br />
					<br />
					<span>
						<b>Note:</b> by doing so, you will lose access to your last year&apos;s
						classes, but don&apos;t worry - you can easily reimport them once your
						account is confirmed.
					</span>
				</ModalBody>
				<ModalFooter>
					{status === ACCOUNT_DATA_VERIFICATION_STATUS.ALLOWED ? (
						<Button
							outline
							onClick={() => {
								setIsModalClosed(true)
							}}>
							Later
						</Button>
					) : null}
					<Button
						onClick={() => {
							window.location.href = ACCOUNT_DATA_VERIFICATION_URL
						}}>
						Confirm my information
					</Button>
				</ModalFooter>
			</SmallModal>
		)
	}
	return null
}

const SmallModal = styled(Modal)`
	max-width: max(50vw, min(700px, 100vw));
	min-width: min(500px, 100vw);
`
