// @flow
import {
	createStore,
	applyMiddleware,
	type Store as _Store,
	type Middleware,
	type Dispatch,
} from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import * as customMiddleware from './middleware'
// import logger from 'redux-logger'
import thunk from 'redux-thunk'
import createRootReducer, { type ReduxStore, type GetState } from './rootReducer'
import type { Action } from './types'
import { NetworkCommunicator } from '../services'
const enhancers = []

type AllowableDispatch =
	| Action
	| ((
			dispatch: Dispatch<AllowableDispatch>,
			getState: GetState,
			NetworkCommunicator: typeof NetworkCommunicator
	  ) => any)

const middleware: Array<Middleware<ReduxStore, Action, Dispatch<AllowableDispatch>>> = [
	((customMiddleware.missionPrep: any): Middleware<
		ReduxStore,
		Action,
		Dispatch<AllowableDispatch>
	>),
	thunk.withExtraArgument(NetworkCommunicator),
]

if (process.env.NODE_ENV === 'development') {
	// middleware.push(logger)
}

const composedEnhancers = composeWithDevTools(applyMiddleware(...middleware), ...enhancers)

// $FlowIgnore[prop-missing] redux does not handle promises by default
export type Store = _Store<ReduxStore, Action, Dispatch<AllowableDispatch>>

export function createReduxStore(initialState?: ?ReduxStore): Store {
	if (initialState) {
		return createStore(createRootReducer(), initialState, composedEnhancers)
	}
	return createStore(createRootReducer(), composedEnhancers)
}

const store: Store = createReduxStore()

export default store
