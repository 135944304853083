import React from 'react'
import styled from 'styled-components'
import { useSelector, useDispatch } from 'react-redux'
import { isUsingJuniorPlusControls, setJuniorTeams } from '../../../store/missionPrep/index'
import AssignRolesTab from './prepSteps/AssignRolesTab'
import { useClass } from '../../../services/hooks/classes'

import type { JuniorTeam } from '../../../store/types'

type RolesProps = {
	classId: string,
	simulationId: string,
	goBack: () => mixed,
}

export default function PrepModalRolesContent({
	classId,
	simulationId,
	goBack,
}: RolesProps): React$Node {
	const dispatch = useDispatch()
	const usingJuniorPlusControls = useSelector(isUsingJuniorPlusControls)
	const { data: classData } = useClass(classId)

	const handleSave = (newTeams: JuniorTeam[]) => {
		dispatch(setJuniorTeams(newTeams))
	}

	return classData ? (
		<AssignRolesTab
			isJuniorPlusMission={usingJuniorPlusControls}
			crew={classData}
			goBack={goBack}
			onSave={handleSave}
			simulationId={simulationId}
		/>
	) : (
		<ErrorDiv>There was an error showing the roles</ErrorDiv>
	)
}

const ErrorDiv = styled.div`
	display: flex;
	min-height: 300px;
	justify-content: center;
	align-items: center;
`
