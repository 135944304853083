import styled from 'styled-components'
import type { StyledType } from 'global-types'
import React from 'react'
import classnames from 'classnames'

export const ButtonLink: StyledType<> = styled.button`
	border: none;
	background-color: transparent;
	color: var(--blue);
	:hover {
		filter: brightness(70%);
	}
	padding: 0;
	:focus {
		outline: none;
	}
`

/**
 * A button that looks good holding an icon
 * Usage:
 * <IconButton onClick={doSomething}>
 * 	 <FaStar />
 * </IconButton>
 */
export function IconButton({
	children,
	className,
	...rest
}: {
	children: React$Node,
	onClick?: () => mixed,
	className?: string,
}): React$Node {
	return (
		<button
			{...rest}
			className={classnames(
				'text-primary-50 transition-all duration-100 inline-flex rounded-full p-2 hover:brightness-90 active:brightness-75 hover:scale-110',
				className
			)}>
			{children}
		</button>
	)
}
