import React from 'react'
import styled from 'styled-components'

type Props = {
	onboardingItem: {
		finished: boolean,
		label: string,
	},
	onClick: (label: string) => void,
}

/**
 * A single item in the Onboarding Popup. Shows the complete status based on the `onboardingItem.finished` variable.
 * Label of each item contains in the `onboardingItem.label`.
 * @param onboardingItem The onboarding item. Contains finished and label properties
 * @callback onClick allow us to switch user on the appropriate page and show a suitable modal window
 */
export default function OnboardingItem({ onboardingItem, onClick }: Props): React$Node {
	return (
		<OnboardingItemWrapper
			finished={onboardingItem.finished}
			onClick={() => onClick(onboardingItem.label)}>
			<OnboardingItemMark finished={onboardingItem.finished} className="item-mark">
				<Icon viewBox="0 0 24 24">
					<polyline points="20 6 9 17 4 12" />
				</Icon>
			</OnboardingItemMark>
			<span className="text-sm font-medium text-[--primary] OnboardingItemLabel">
				{onboardingItem.label}
			</span>
		</OnboardingItemWrapper>
	)
}

const OnboardingItemWrapper = styled.li`
	display: flex;
	${({ theme, finished }) => `
    margin: var(--spacing1x-dont-use) 0;
    &:hover {
		cursor: pointer;
		.item-mark {
		border: 2px solid ${theme.neutralLight};
		background-color: ${finished ? theme.neutralLight : '#fff'};
		}
		.OnboardingItemLabel {
			color: ${theme.neutralLight}
		}
	}
  `}
`

const OnboardingItemMark = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 50%;
	margin-right: 6px;
	${({ theme, finished }) => `
    border: 2px solid ${theme.primary};
    background-color: ${finished ? theme.primary : '#fff'};
    width: var(--spacing3x-dont-use);
    height: var(--spacing3x-dont-use);
  `}
`

const Icon = styled.svg`
	fill: none;
	stroke: white;
	stroke-width: 4px;
	width: 16px;
`
