import classnames from 'classnames'
import React from 'react'

/**
 * Collapse - a react node for elements which should be collapsable
 *
 * @param {Object} props - the react props
 * @param {boolean} props.expanded - true if we should show the children, false otherwise
 * @param {React$Node} props.children - the elements which should be collapsed/expanded
 * @param {?string} props.id? - the id of the collapse element
 * @param {?string} props.className? - css classNames to apply to the component
 *
 * @return {React$Node}
 */
export default function Collapse({
	expanded,
	children,
	className,
	id,
}: {
	expanded: boolean,
	children: React$Node,
	className?: ?string,
	id?: ?string,
}): React$Node {
	return (
		<div
			id={id}
			className={classnames(
				className,
				'overflow-y-auto transition-all',
				expanded ? 'max-h-super-large' : 'max-h-0'
			)}
			aria-hidden={!expanded}>
			{children}
		</div>
	)
}
