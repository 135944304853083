import { Button } from '@mission.io/styles'
import React from 'react'
import 'styled-components/macro'
import { Row } from './basic'
import { ArrayParam, useQueryParam } from 'use-query-params'
import type { AnalyticsObject } from '../../../../models/Analytics'
import { useQuery } from 'react-query'
import NetworkCommunicator from '../../../../services/NetworkCommunicator'
import config from '../../../../config'
import LoadingSpinner from '../../../../styles/LoadingSpinner'

// Makes sure there is a mission id in the url that we can use to show end of mission results data for.
export default function Validated(props: { disabled?: boolean }): React$Node {
	const [missions] = useQueryParam('missions', ArrayParam)
	let skip = !missions || missions.length === 0 || props.disabled
	return skip ? null : <EndOfMissionResultsBlurb missionId={missions[0]} />
}

/**
 * Hook that retrieves analytics for a given missionId
 * @returns {{isLoading: boolean, error: mixed, analytics: ?AnalyticsObject}}}}
 */
export function useAnalyticsForMissionId(
	missionId: ?string
): { analytics: ?AnalyticsObject, isLoading: boolean, error: mixed } {
	const { data, isLoading, error } = useQuery(
		'allAnalytics',
		() =>
			NetworkCommunicator.GET('/api/analytics/all', {
				host: config.missionsAPIURL,
				withCredentials: true,
			}),
		{
			staleTime: Infinity,
			cacheTime: Infinity,
		}
	)
	let analytics
	if (data && data.validAnalytics.length) {
		analytics = data.validAnalytics.find(obj => obj.missionId === missionId)
	}
	return { analytics, isLoading, error }
}

// A row in the category info page that leads a user to the end of mission results. It will only appear if the mission id is available in the url and if that mission id
// is connected to analytics.
function EndOfMissionResultsBlurb(props: { disabled?: boolean, missionId: string }): React$Node {
	const { analytics, isLoading, error } = useAnalyticsForMissionId(props.missionId)
	if (isLoading) {
		return (
			<Row>
				<LoadingSpinner shouldShowSpinner />
			</Row>
		)
	}
	if (error || !analytics) {
		return null
	}

	return (
		<Row>
			Take another look at the end-of-mission results to address specific areas of confusion
			and/or misconceptions with students. This is a great (and easy) way to do some quick
			re-teaching!
			<a href={`/end-of-mission-results/${props.missionId}`}>
				<Button {...props} className="text-center">
					End-of-Mission Results
				</Button>
			</a>
		</Row>
	)
}
