export const gradeValueMap = {
	K: -1,
	'1': 1,
	'2': 2,
	'3': 3,
	'4': 4,
	'5': 5,
	'6': 6,
	'7': 7,
	'8': 8,
	'9': 9,
	'10': 10,
	'11': 11,
	'12': 12,
	other: 13,
}

export const CLASS_NAME = 'CLASS_NAME'
export const GRADE = 'GRADE'
export type SortingOption = 'CLASS_NAME' | 'GRADE'
export const kindergartenKey = 'K'

export const gradeLabelMap = {
	K: 'K',
	'1': '1st',
	'2': '2nd',
	'3': '3rd',
	'4': '4th',
	'5': '5th',
	'6': '6th',
	'7': '7th',
	'8': '8th',
	'9': '9th',
	'10': '10th',
	'11': '11th',
	'12': '12th',
	training: 'training',
	other: 'other',
}

export const gradeLevelAbbreviationMap = {
	K: 'K',
	'1': '1',
	'2': '2',
	'3': '3',
	'4': '4',
	'5': '5',
	'6': '6',
	'7': '7',
	'8': '8',
	'9': '9',
	'10': '10',
	'11': '11',
	'12': '12',
	training: 'T',
	other: 'O',
}

export const classGradeOptions = [
	{ value: 'K', label: 'K' },
	{ value: '1', label: '1st' },
	{ value: '2', label: '2nd' },
	{ value: '3', label: '3rd' },
	{ value: '4', label: '4th' },
	{ value: '5', label: '5th' },
	{ value: '6', label: '6th' },
	{ value: '7', label: '7th' },
	{ value: '8', label: '8th' },
	{ value: '9', label: '9th' },
	{ value: '10', label: '10th' },
	{ value: '11', label: '11th' },
	{ value: '12', label: '12th' },
	{ value: 'other', label: 'other' },
]
