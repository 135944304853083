import React from 'react'
import styled from 'styled-components'
import SingleClass from './SingleClass'
import { gradeValueMap, GRADE, CLASS_NAME } from './constants'

import type { SortingOption } from './constants'
import type { ClassType } from '../../models/ClassType'

type Props = {
	classSortType: SortingOption,
	classes: $ReadOnlyArray<ClassType>,
}

export default function ClassLayoutPresenter({ classes, classSortType }: Props): React$Node {
	return (
		<ClassWrapper>
			{[...classes]
				.sort((a, b) => {
					if (classSortType === GRADE) {
						const aFirstOption = gradeValueMap[a.grades[0]] || 0
						const bFirstOption = gradeValueMap[b.grades[0]] || 0
						const arrayLengthDifference = a.grades.length - b.grades.length
						return aFirstOption - bFirstOption || arrayLengthDifference
					} else if (classSortType === CLASS_NAME) {
						const aName = a.name || ''
						const bName = b.name || ''
						return aName.localeCompare(bName)
					}
					return 0
				})
				.map(_class => {
					return <SingleClass key={_class._id} classId={_class._id} />
				})}
		</ClassWrapper>
	)
}

const ClassWrapper = styled.div`
	column-count: 1;
	column-break-inside: avoid;
`
