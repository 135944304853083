import type { Dispatch } from 'redux'
import type { GetState } from '../rootReducer'
import type { Action } from '../types'

/**
 * this middleware counts as a way to watch the store, and dispatch actions based on actions we observe
 * its a way to introduce clean side effects in the code
 *
 * Example
 * 
	if (action.type === <INSERT_ACTION_TYPE_HERE>) {
		store.dispatch(<Choose_A_DISPATCH_FUNCTION()>
	}
*
*/

export const generalMiddleware = (store: {
	getState: GetState,
	dispatch: Dispatch<*>,
}): ((next: (Action) => void) => (action: Action) => any) => (next: Action => void) => (
	action: Action
) => {
	return next(action)
}
