import type { AutomatedSimulation } from '../../models/Simulation'
import type { ReduxStore } from '../../store/rootReducer'
import { Helmet } from 'react-helmet'
import states from '../../constants/states'
import { useStandardSets, useStandards } from '../../services/hooks/standards'
import { PageTitleHeader } from '../../styles/sharedComponents'
import useUser, { useUserPreference } from '../../services/hooks/user'
import React, { useMemo, useState, useCallback } from 'react'
import styled from 'styled-components/macro'
import { useSelector } from 'react-redux'
import Selector from '../../components/dashboardModules/LeaderBoardAnalytics/Selector'
import { getCurriculumStandards, getFilteredStandardsToSimulations } from './helpers'
import StandardsTable from './StandardsTable'
import { ErrorLinkButton, ErrorOccurred } from '../../components/Error'
import LoadingSpinner from '../../styles/LoadingSpinner'
import { MATH, SCIENCE, LITERACY, getStandardsForState } from '../../utility/standards'
import { useQueryParam, StringParam, NumberParam, withDefault } from 'use-query-params'
import { screenSizes_dontUse } from '../../styles/theme'
import { Select } from '../../components/inputs/Select'

const K_2 = ['K', '1', '2', 'K-2']
const GRADES_3_5 = ['3', '4', '5', '3-5']
const GRADES_6_8 = ['6', '7', '8', '6-8', 'MS']

export const GRADE_MAP: { [string]: Array<string> } = {
	'All Grades': [...K_2, ...GRADES_3_5, ...GRADES_6_8],
	'K-2': K_2,
	'3-5': GRADES_3_5,
	'6-8': GRADES_6_8,
}

export type GradeCategory = $Keys<typeof GRADE_MAP>
const GRADES = Object.keys(GRADE_MAP)

type StateOption = { value: string | null, label: string }

export const NATIONAL_STATE = {
	name: 'National',
	abbreviation: 'NAT',
}

const DEFAULT_STATE_OPTION: StateOption = {
	value: NATIONAL_STATE.abbreviation,
	label: NATIONAL_STATE.name,
}

const GradeIndexNumberParam = withDefault(NumberParam, 0)
const SubjectStringParam = withDefault(StringParam, SCIENCE)

/**
 * Standards Page that displays a table of standards and simulations based on the user's selected state.
 */
export default function Standards(): React$Node {
	const {
		data: standardSets,
		isLoading: standardSetsLoading,
		isError: standardSetsError,
		refetch: refetchStandardSets,
	} = useStandardSets()
	const { user } = useUser()
	const {
		data: userPreferences,
		isLoading: preferencesLoading,
		isError: preferencesError,
		refetch: refetchPreferences,
	} = useUserPreference()

	const _simulations: ?Array<AutomatedSimulation> = useSelector(
		(state: ReduxStore) => state.simulations.automatedSimulations
	)

	const defaultState =
		userPreferences?.stateForStandards || user?.state || DEFAULT_STATE_OPTION.value

	const [currentState, setCurrentState] = (useQueryParam(
		'state',
		withDefault(StringParam, defaultState),
		{
			updateType: 'replaceIn',
			removeDefaultsFromUrl: true,
		}
	): [?string, (newState: ?string) => void])

	const [selectedGradeIndex, setSelectedGradeIndex] = useQueryParam(
		'gradeIndex',
		GradeIndexNumberParam,
		{
			updateType: 'replaceIn',
			removeDefaultsFromUrl: true,
		}
	)
	const [subject, setSubject] = useQueryParam('subject', SubjectStringParam, {
		updateType: 'replaceIn',
		removeDefaultsFromUrl: true,
	})
	const [filtersHeight, setFiltersHeight] = useState(0)

	const filtersRef = useCallback(node => {
		if (node !== null) {
			setFiltersHeight(node.getBoundingClientRect().height)
		}
	}, [])

	const stateStandardSetIds = useMemo(() => {
		if (!standardSets) return []
		const stateStandardSets = getStandardsForState(
			currentState ?? NATIONAL_STATE.abbreviation,
			standardSets
		)
		if (stateStandardSets) return stateStandardSets?.map(standardSet => standardSet._id)
		return []
	}, [standardSets, currentState])

	const {
		data: standards,
		isLoading: standardsLoading,
		refetch: refetchStandards,
		isError: standardsError,
	} = useStandards(stateStandardSetIds)

	const standardSetName = useMemo(() => {
		if (!standardSets || !currentState) return ''
		const stateStandardSets = getStandardsForState(currentState, standardSets)
		const currentStandardSet = stateStandardSets?.find(
			standardSet => standardSet.subject.name === subject
		)
		return currentStandardSet?.abbreviation || currentStandardSet?.name || ''
	}, [currentState, standardSets, subject])

	const curriculumStandards = useMemo(() => getCurriculumStandards(standardSets, standards), [
		standardSets,
		standards,
	])

	const filteredStandardsToSimulations = useMemo(
		() =>
			getFilteredStandardsToSimulations({
				standardSets,
				currentState: currentState,
				selectedGrade: GRADES[selectedGradeIndex],
				standards: curriculumStandards,
				simulations: _simulations,
				subjects: [subject],
			}),
		[standardSets, currentState, selectedGradeIndex, curriculumStandards, _simulations, subject]
	)

	const refetch = () => {
		refetchStandardSets()
		refetchPreferences()
		refetchStandards()
	}

	const allUserStateOptions: Array<StateOption> = [NATIONAL_STATE, ...states].map(state => ({
		value: state.abbreviation,
		label: state.name,
	}))

	const metaDescription =
		'Streamline your planning by easily matching your educational standards in Science and Math to our immersive classroom missions.'

	return (
		<>
			<Helmet>
				<title>Standard Alignment: Find Missions Matching State Standards</title>
				<meta name="description" content={metaDescription} />
				<meta name="og:description" content={metaDescription} />
			</Helmet>
			<PageHeader>Standards Alignment</PageHeader>
			{preferencesLoading ||
			standardSetsLoading ||
			standardsLoading ||
			!_simulations ||
			!filteredStandardsToSimulations ? (
				<LoadingSpinner shouldShowSpinner css="width: 30px; height: 30px;" />
			) : preferencesError || standardSetsError || standardsError ? (
				<div>
					<ErrorOccurred>
						Failed to load standards
						<ErrorLinkButton onClick={() => refetch()}>Retry</ErrorLinkButton>
					</ErrorOccurred>
				</div>
			) : (
				<>
					<StandardsFilters
						filtersRef={filtersRef}
						subject={subject}
						setSubject={setSubject}
						selectedGradeIndex={selectedGradeIndex}
						setSelectedGradeIndex={setSelectedGradeIndex}
						currentState={
							allUserStateOptions.find(state => state.value === currentState) ??
							DEFAULT_STATE_OPTION
						}
						allUserStateOptions={allUserStateOptions}
						setCurrentState={setCurrentState}
					/>
					{Object.keys(filteredStandardsToSimulations)?.length < 1 ||
					curriculumStandards?.length < 1 ? (
						<div className="text-center text-2xl mt-4">
							No {subject} standards could be found for {currentState}.
						</div>
					) : (
						<StandardsTable
							standardToSimulations={filteredStandardsToSimulations}
							standards={curriculumStandards}
							filtersHeight={filtersHeight}
							standardSetName={standardSetName}
						/>
					)}
				</>
			)}
		</>
	)
}

const StandardsFilters = ({
	filtersRef,
	subject,
	setSubject,
	selectedGradeIndex,
	setSelectedGradeIndex,
	currentState,
	allUserStateOptions,
	setCurrentState,
}: {
	filtersRef: any, // TODO what type should this be?
	subject: string,
	setSubject: (newSubject: string) => void,
	selectedGradeIndex: number,
	setSelectedGradeIndex: (val: number) => void,
	currentState: StateOption,
	allUserStateOptions: StateOption[],
	setCurrentState: (option: string) => void,
}): React$Node => {
	const options = [
		{ value: SCIENCE, label: 'Science' },
		{ value: MATH, label: 'Math' },
		{ value: LITERACY, label: 'Literacy/ELA' },
	]

	return (
		<div
			ref={filtersRef}
			className="sticky top-[var(--header-height)] bg-primary-950 p-0 pb-1 z-10 w-full justify-between items-end flex gap-2 mb-2">
			<Select
				{...{
					'aria-label': 'Subject',
					options,
					value: options.find(option => option.value === subject),
					onChange: value => setSubject(value.value),
					className: 'w-36',
				}}
			/>
			<div className="flex gap-2">
				<Select
					className="lg:hidden"
					options={GRADES.map((grade, gradeIndex) => ({
						value: grade,
						label: grade,
					}))}
					value={{
						value: GRADES[selectedGradeIndex],
						label: selectedGradeIndex
							? `Grades ${GRADES[selectedGradeIndex]}`
							: GRADES[selectedGradeIndex],
					}}
					onChange={newGrade =>
						setSelectedGradeIndex(GRADES.findIndex(grade => grade === newGrade.value))
					}
				/>
				<Selector
					options={GRADES}
					selected={selectedGradeIndex}
					onSelect={setSelectedGradeIndex}
					itemStyles={{ letterSpacing: 1 }}
					className="hidden lg:flex"
				/>
				<Select
					aria-label="State"
					value={currentState}
					options={allUserStateOptions}
					onChange={option => setCurrentState(option.value)}
					className="min-w-40"
				/>
			</div>
		</div>
	)
}

const PageHeader = styled(PageTitleHeader)`
	${({ theme }) => `
		@media (max-width: ${screenSizes_dontUse.desktopDevice}px) {
			margin-bottom: 0;
		}	
		
	`}
`
