import React from 'react'
import moment from 'moment'
import { LightTheme as theme } from '../styles/theme'
import { View, Text } from '@react-pdf/renderer'
import styles from './sharedStyles'

import type { AnalyticsObject } from '../models/Analytics'
import type { AutomatedSimulation } from '../models/Simulation'
import type { CurriculumStandard } from '../utility/standards'

/**
 * Displays mission info for mission and simulation in react pdf
 */
export const MissionInfo = ({
	simulation,
	analyticsData,
}: {
	simulation: AutomatedSimulation,
	analyticsData: AnalyticsObject,
}): React$Node => {
	return (
		<View style={{ flex: 1, marginRight: '16px' }}>
			<Text style={[styles.p, { fontSize: 14 }]}>
				<Text style={styles.b}>Mission: </Text> {simulation.name}
			</Text>
			<Text style={styles.p}>
				<Text style={styles.b}>Grade: </Text> {analyticsData.grades[0]}
			</Text>
			<Text style={styles.p}>
				<Text style={styles.b}>Class: </Text> {analyticsData.className}
			</Text>
			<Text style={styles.p}>
				<Text style={styles.b}>Date: </Text>
				{moment(analyticsData.createdTime).format('MM/DD/YY')}
			</Text>
			<Text style={styles.p}>
				<Text style={styles.b}>Summary: </Text>
				{removeTags(simulation.summary)}
			</Text>
		</View>
	)
}

/**
 * Removes html tags and the markup value `&nbsp;` so that we can display the simulation narration as plain text in the pdf
 */
function removeTags(str: string): string {
	return str
		.replace(/<[^>]*>/g, ' ')
		.replace(/\s{2,}/g, ' ')
		.replace(/&nbsp;/g, '')
		.trim()
}

/**
 * Displays mission standards in the react pdf
 */
export const Standards = ({ standards }: { standards: CurriculumStandard[] }): React$Node => {
	return (
		<View
			style={{
				width: '50%',
				backgroundColor: theme.neutralLight,
				padding: `var(--spacing1x-dont-use) var(--spacing2x-dont-use)`,
			}}>
			<Text style={[styles.p, styles.b]}> Standards</Text>

			{standards.map(
				(
					{ standardSet: { abbreviation: standardSetAbbreviation }, name, summary },
					index
				) => (
					<Text style={styles.p} key={`${standardSetAbbreviation}-${name}-${index}`}>
						<Text style={styles.b}>
							{standardSetAbbreviation} {name}
						</Text>
						{summary}
					</Text>
				)
			)}
		</View>
	)
}

const altColorDefault = {
	light: '#F6F7FB',
	grey: '#EFF0F4',
}
/**
 * A table component that displays columns and rows following Mission.io theme.
 * Takes the following props:
 * columns: an array of objects that must contain a Header component, an id and an accessor which returns a display for each row element on that column
 * rows: an array of objects that must contain a name string and a key value and also must be an object that the column accessor function expects.
 */
export function Table<E: { name: string, key: string }>({
	columns,
	rows,
}: {
	columns: { Header: React$ComponentType<mixed>, id: string, accessor: E => React$Node }[],
	rows: E[],
}): React$Node {
	return (
		<View style={[styles.table]}>
			<View style={[styles.tr, styles.bordered]} fixed>
				{columns.map(({ Header, id }) => (
					<Header key={id} />
				))}
			</View>
			{rows.map((data, index) => (
				<View style={[styles.tr]} key={data.key} wrap={false}>
					{columns.map(({ accessor, id }) => {
						return (
							<View
								key={id}
								style={[
									styles.td,
									{
										backgroundColor:
											index % 2
												? altColorDefault.grey
												: altColorDefault.light,
									},
								]}>
								<View>{accessor(data)}</View>
							</View>
						)
					})}
				</View>
			))}
		</View>
	)
}
