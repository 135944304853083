import React, { Component } from 'react'
import styled from 'styled-components'
import { DropTarget } from 'react-dnd'
import StudentComponent from './StudentComponent'

import type { Student_New } from '../../../../../../models/Student'
import type { TargetDropProps } from '../../../../../../types'
import classnames from 'classnames'

type BaseProps = {
	students: Student_New[],
	moveStudent: (student: Student_New) => void,
	className?: string,
}
type Props = {
	...BaseProps,
	...TargetDropProps,
}

const studentsTarget = {
	drop(props: Props, monitor) {
		props.moveStudent(monitor.getItem())
	},
}

function collect(connect, monitor) {
	return {
		connectDropTarget: connect.dropTarget(),
		isOver: monitor.isOver(),
	}
}

class StudentsBox extends Component<Props> {
	render(): React$Node {
		const { isOver, students, connectDropTarget, className } = this.props
		return connectDropTarget(
			<div className={className}>
				<StudentsContainer hovered={isOver}>
					<NoStudents>
						{students.length > 0
							? "Drag the student's name onto the team roles"
							: 'All students have been assigned a team'}
					</NoStudents>
					{students.map((obj: Student_New) => (
						<StudentComponent student={obj} key={obj._id} container="wordBank" />
					))}
				</StudentsContainer>
			</div>
		)
	}
}

export default (DropTarget('student', studentsTarget, collect)(StudentsBox): (
	props: BaseProps
) => React$Node)

const StudentsContainer = styled.div.attrs(({ hovered }) => ({
	className: classnames('p-4 bg-primary-750 rounded-lg', hovered && 'shadow'),
}))``

const NoStudents = styled.div`
	${({ theme }) => `
		margin: var(--spacing2x-dont-use) 0;
	`}
`
