import React, { useState } from 'react'
import styled from 'styled-components'

import { toast } from 'react-toastify'
import Modal, {
	ModalHeader,
	ExitX,
	ModalBody,
	ModalFooter as ButtonFooter,
} from '../../../components/modal/Modal'
import LoadingSpinner from '../../../styles/LoadingSpinner'
import { Button } from '@mission.io/styles'
import { useUser } from '../../../services/hooks'
import { getName } from '../../../utility/helpers'
import { PAUSED, type MissionType } from '../constants'

import type { User } from '../../../store/types'
import { useCompleteMission, useDeleteMission } from '../../../queryHooks/mission'

/**
 * A modal that will appear when a user attempts to delete a mission from the MissionReviewTile component
 *
 * @param {boolean} props.isOpen whether or not the modal should be open
 * @param {() => boid} props.onClose callback to close the modal
 * @param {MissionType} props.missionStatus the status the mission is currently in, 'PREPPED' or 'PAUSED'
 * @param {User} props.missionOwner the owner of the mission
 * @param {string} props.missionId the mission id
 * @returns React Component
 */
export default function DeleteMissionModal({
	isOpen,
	onClose,
	missionStatus,
	missionOwner,
	missionId,
}: {
	isOpen: boolean,
	onClose: () => void,
	missionStatus: MissionType,
	missionOwner: User,
	missionId: string,
}): React$Node {
	const { user } = useUser()
	const userCreatedMission = (user ? String(user._id) : null) === String(missionOwner?._id || '')
	const [showDeleteMissionSpinner, setShowDeleteMissionSpinner] = useState(false)
	const [showDeleteMissionWithDataSpinner, setShowDeleteMissionWithDataSpinner] = useState(false)

	const deleteMission = useDeleteMission()
	const completeMission = useCompleteMission()

	return (
		<Modal isOpen={isOpen} style={{ content: { width: '50%' } }} onRequestClose={onClose}>
			<ModalHeader>
				End Mission <ExitX onClick={onClose} />
			</ModalHeader>
			<Body>
				{!userCreatedMission && (
					<div className="text-2xl font-medium">
						This mission was created by{' '}
						{getName(missionOwner?.firstName, missionOwner?.lastName) || 'someone else'}
						.<p>You did not create this mission.</p>
					</div>
				)}
				{missionStatus !== PAUSED ? (
					<p>Are you sure you want to delete this mission?</p>
				) : (
					<>
						<p>Are you sure?</p>
						<p>
							To keep any student analytics that have been generated, click End with
							data.
						</p>
						<p>
							To end the mission without saving the student analytics, click End
							without data.
						</p>
					</>
				)}
			</Body>
			<ButtonFooter>
				{missionStatus !== PAUSED && (
					<Button outline onClick={onClose}>
						Cancel
					</Button>
				)}
				<SpinnerButton
					loading={showDeleteMissionSpinner}
					outline={missionStatus === PAUSED}
					onClick={() => {
						if (!showDeleteMissionSpinner && !showDeleteMissionWithDataSpinner) {
							setShowDeleteMissionSpinner(true)
							deleteMission(missionId)
								.then(() => {
									toast.success('Mission deleted!', {
										position: toast.POSITION.BOTTOM_CENTER,
									})
									onClose()
								})
								.catch(error => {
									if (!error) {
										return
									}
									console.error('Could not delete mission', error)
									toast.error('Unable to delete mission.', {
										position: toast.POSITION.BOTTOM_CENTER,
									})
								})
								.finally(() => setShowDeleteMissionSpinner(false))
						}
					}}>
					<LoadingSpinner
						shouldShowSpinner={showDeleteMissionSpinner}
						beforeActionText={
							missionStatus === PAUSED ? 'End without data' : 'Delete Mission'
						}
					/>
				</SpinnerButton>
				{missionStatus === PAUSED && (
					<SpinnerButton
						loading={showDeleteMissionWithDataSpinner}
						onClick={() => {
							if (!showDeleteMissionWithDataSpinner && !showDeleteMissionSpinner) {
								setShowDeleteMissionWithDataSpinner(true)
								completeMission(missionId)
									.then(() => {
										toast.success('Mission marked complete!', {
											position: toast.POSITION.BOTTOM_CENTER,
										})
										onClose()
									})
									.catch(error => {
										if (!error) {
											return
										}
										console.error('Unable to complete mission', error)
										toast.error('Unable to mark mission complete.', {
											position: toast.POSITION.BOTTOM_CENTER,
										})
									})
									.finally(() => setShowDeleteMissionWithDataSpinner(false))
							}
						}}>
						<LoadingSpinner
							shouldShowSpinner={showDeleteMissionWithDataSpinner}
							beforeActionText={'End with data'}
						/>
					</SpinnerButton>
				)}
			</ButtonFooter>
		</Modal>
	)
}

const Body = styled(ModalBody)`
	text-align: center;
`

const SpinnerButton = styled(Button)`
	${({ theme, loading }) =>
		loading &&
		`
		background-color: ${theme.neutralLight};
		display: flex;
		justify-content: center;
	`}
`
