import React from 'react'
import { components } from 'react-select'
import Checkbox from '../inputs/Checkbox'
import type { SelectOption } from '../../routes/analytics/analyticsHelpers'

type SelectProps = {
	value: SelectOption[],
	options: SelectOption[],
	onChange: mixed => mixed,
	isSelected: string => boolean,
	isError?: boolean,
}

type Props = {
	selectProps: SelectProps,
}

export const customSelectStyles = {
	control: (provided: { [string]: mixed }, state: Props): { [string]: mixed } => {
		const isError = state.selectProps.isError
		return {
			...provided,
			outline: isError ? 'var(--error) auto 1px !important' : provided.outline,
			border: isError ? '2px solid var(--error) !important' : provided.border,
		}
	},
}

/**
 * This components returns the list of options with a checkbox inside for the react-select input
 */
export const CheckMenuList = (props: Props): React$Node => {
	const { value, onChange, options, isSelected } = props.selectProps

	const onMissionClick = (selected: boolean, item: SelectOption) => {
		if (selected) {
			onChange(value.filter(option => option.value !== item.value))
		} else {
			onChange([...value, item])
		}
	}

	const isAllSelected = value.length === options.length
	const handleSelectAll = () => onChange(isAllSelected ? [] : [...options])

	return (
		<components.MenuList {...props}>
			<Checkbox
				checkboxClassName="hidden"
				labelClassName="px-2 py-1 block"
				checked={isAllSelected}
				onChange={handleSelectAll}
				label="All"
			/>
			{options.map(missionItem => {
				const missionId = missionItem.value
				const selected = isSelected(missionId)

				return (
					<Checkbox
						checkboxClassName="hidden"
						labelClassName="px-2 py-1 block"
						checked={selected}
						key={missionId}
						onChange={() => onMissionClick(selected, missionItem)}
						label={missionItem.label}
					/>
				)
			})}
		</components.MenuList>
	)
}

/**
 * This is as readonly input that display the total of missions selected used in Analytics views.
 */
export const Input = ({ selectProps, ...props }: Props): React$Node => {
	const currentValue = selectProps.value

	let value = ''
	if (currentValue.length === 1) value = currentValue[0].label
	else value = `${currentValue.length} missions selected`
	return <components.Input {...props} readOnly value={value} />
}

export const EmptyComponent = (): React$Node => <></>
