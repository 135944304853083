import type { DisplayAnalytics, ProficiencyTableEntry } from '../../models/Analytics'
import { ProficiencyTable, getNameColumn } from './AnalyticsCharts/StudentsTableManger'
import React, { useState, useMemo } from 'react'
import styled from 'styled-components/macro'
import {
	CATEGORIES,
	translateScoreToProficiencyLevel,
} from './AnalyticsCharts/ProficiencyBarGraph/constants'
import { CATEGORIES as lookup, type Category } from './StudentPage/constants'
import { type AnalyticsViewType, SCORE_FORMAT_DISPLAY, analyticsViewEnum } from './constants'
import { Summary } from './StudentPage/StudentSummary'
import LineGraph from './StudentPage/LineGraph'
import { ProficiencyLegend } from './AnalyticsCharts/ProficiencyBarGraph/Legend'
import DataViewContext, { dataViewEnum, useScores, useViewType } from './DataViewContext'
import moment from 'moment'
import { averageSelScores } from './AnalyticsCharts/dataHelpers'
import type { AnalyticsObject } from '../../store/types'
import { Select } from '../../components/inputs/Select'
import type { NameLookup } from '../../download/questions'
import type { ScoreFormatType } from './constants'

const categories = CATEGORIES.map(category => lookup[category.id])

// A Holistic View for multiple mission analytics and average student scores.
export default function HolisticView({
	allScores,
	role,
	csvGroupName,
	csvDateRange,
	filteredAnalytics,
	proficiencyTableEntries,
	analyticsVersion,
	nameLookup,
}: {|
	allScores: Array<DisplayAnalytics>,
	role: AnalyticsViewType,
	csvGroupName: string,
	csvDateRange: string,
	filteredAnalytics: AnalyticsObject[],
	proficiencyTableEntries: Array<ProficiencyTableEntry>,
	analyticsVersion: number,
	nameLookup: NameLookup,
|}): React$Node {
	const averageScores = averageSelScores(
		allScores.map(score => ({ all: score.combinedSelScores }))
	).all // get combined sel scores from all scores

	const pastMissionScores = useMemo(() => {
		return allScores.map(displayAnalytics => ({
			scores: displayAnalytics.combinedSelScores,
			studentScores: displayAnalytics.individualSelScores,
			missionDate: displayAnalytics.time,
		}))
	}, [allScores])
	const keyword =
		role === analyticsViewEnum.DISTRICT
			? 'District'
			: role === analyticsViewEnum.ADMIN
			? 'School'
			: 'Class'

	const initialColumns = useMemo(() => {
		return [
			getNameColumn({
				namingType:
					role === analyticsViewEnum.DISTRICT
						? 'School'
						: role === analyticsViewEnum.ADMIN
						? 'Teacher'
						: 'Student',
			}),
		]
	}, [role])

	return (
		<div css="width: 100%;">
			<h4 className="text-center text-xl mb-2">
				Overall {keyword} Performance this School Year
			</h4>
			<Summary
				categories={categories}
				entity={{
					totalMissions: allScores.length,
					averages: averageScores,
				}}
			/>
			<DataViewContext scores={pastMissionScores}>
				<h4 className="text-center text-xl mb-2 mt-12">
					Average {keyword} Proficiency over Time (by Skill)
				</h4>
				<LineGraph categories={categories} />
				<ProficiencyTotalsOverTime categories={categories} />
				<h4 className="text-center text-xl mb-2 mt-12">
					Overall {keyword} Performance this School Year
				</h4>
				<ProficiencyTable
					{...{
						version: analyticsVersion,
						nameLookup: nameLookup,
						initialColumns,
						allowDownloadAll: role === analyticsViewEnum.TEACHER,
						getCsvName: displayType =>
							getCSVName(displayType, role, csvGroupName, csvDateRange),
						filteredAnalytics: filteredAnalytics,
						data: proficiencyTableEntries,
					}}
				/>
			</DataViewContext>
		</div>
	)
}

/**
 * getCSVName - get the name of the csv file to generate
 *
 * @param {ScoreFormatType} displayType - the type of data being displayed
 * @param {AnalyticsViewType} analyticsViewType - the view the analytics is being rendered with
 * @param {string} csvGroupName - describes how the analytics are connected (used in csv file name generation)
 * @param {string} csvDateRange - describes the range of dates the analytics were collected for (used in csv file name generation)
 *
 * @return {string}
 */
function getCSVName(
	displayType: ScoreFormatType,
	analyticsViewType: AnalyticsViewType,
	csvGroupName: string,
	csvDateRange: string
): string {
	const dataType = SCORE_FORMAT_DISPLAY[displayType] ?? 'Unknown Data Type'
	switch (analyticsViewType) {
		case analyticsViewEnum.DISTRICT: // seeing all schools in district
			return `All Schools Mission Performance - ${dataType} (${csvDateRange})`
		case analyticsViewEnum.TEACHER: // seeing all students who ran missions with teacher
			return `${csvGroupName} Student Performance - ${dataType} (${csvDateRange})`
		case analyticsViewEnum.ADMIN: // school admin, seeing all teachers in school
			return `${csvGroupName} Classes - ${dataType} (${csvDateRange})`
		default: {
		}
	}
	return `data`
}

/**
 * Custom chart to allow a more in depth look at proficiencies over time on a per category basis.
 * @param {Category[]} props.categories
 * @returns {React$Node}
 */
function ProficiencyTotalsOverTime(props: { categories: Category[] }) {
	const scores = useScores()
	const viewType = useViewType()
	const [category, setCategory] = useState(props.categories[0])
	const sufficientCategoryDataForGraph = scores.some(score => {
		const studentScores = score.studentScores || {}
		return Object.keys(studentScores || {}).some(
			studentId => category.accessor(studentScores?.[studentId]) != null
		)
	})

	return (
		<div css="display: flex; margin-top: 48px; align-items: center;">
			<ProficiencyLegend />
			<div css="border-left: 1px solid black; margin: var(--spacing3x-dont-use); height: 120px;" />
			<Chart>
				<h5>
					<label htmlFor="Proficiency Category">Proficiency over Time:</label>
					<Select
						inputId="Proficiency Category"
						styles={{
							container: baseStyles => ({
								...baseStyles,
								width: '200px',
								marginLeft: '8px',
							}),
							menu: baseStyles => ({
								...baseStyles,
								zIndex: 2,
							}),
						}}
						value={category ? { value: category.key, label: category.title } : null}
						onChange={({ value }) => {
							setCategory(lookup[value])
						}}
						options={props.categories.map(category => ({
							label: category.title,
							value: category.key,
						}))}
					/>
				</h5>
				{sufficientCategoryDataForGraph ? (
					<>
						<div className="wrapper">
							<div className="y-axis-label">Percentage of Students per Level</div>
							<div className="data-area">
								{scores.map((score, index) => {
									const totalsPerLevel = [0, 0, 0, 0]
									const studentScores = score.studentScores || {}
									Object.keys(studentScores).forEach(studentId => {
										const targetScore = category.accessor(
											studentScores[studentId]
										)
										if (targetScore != null) {
											const proficiency = translateScoreToProficiencyLevel(
												targetScore
											)
											const proficiencyLevelIndex = (proficiency ?? 0) - 1

											if (
												totalsPerLevel[proficiencyLevelIndex] !== undefined
											) {
												totalsPerLevel[proficiencyLevelIndex] += 1
											}
										}
									})
									const total = totalsPerLevel.reduce(
										(total, each) => total + each,
										0
									)
									if (total === 0) {
										return null
									}
									const percentages = totalsPerLevel.map(
										perLevel => (perLevel / total) * 100
									)
									return (
										<MissionDataColumn
											key={`${score.missionDate.toISOString()}-${index}`}>
											<div css="display: flex; flex-direction: column-reverse; height: 100%;">
												{percentages.map((percentage, index) => (
													<Percentage
														style={{ height: percentage + '%' }}
														key={`proficiency-${index + 1}`}
														level={index + 1}>
														{percentage !== 0
															? `${Math.round(percentage)}%`
															: ''}
													</Percentage>
												))}
											</div>
											<div className="text-center min-w-[30px] text-sm mt-1">
												{moment(score.missionDate).format(
													viewType === dataViewEnum.AVERAGED_OVER_MONTHS
														? 'MMMM'
														: 'MMM. D'
												)}
											</div>
										</MissionDataColumn>
									)
								})}
							</div>
						</div>
						<div className="x-axis-label">
							{viewType === dataViewEnum.AVERAGED_OVER_MONTHS
								? 'Months'
								: viewType === dataViewEnum.AVERAGED_OVER_WEEKS
								? 'Weeks'
								: 'Mission Dates'}
						</div>
					</>
				) : (
					<div css="text-align: center; font-style: italic; margin-top: var(--spacing3x-dont-use);">
						Not enough data to display student performance in this category.
					</div>
				)}
			</Chart>
		</div>
	)
}

const Chart = styled.div`
	flex: 1;
	h5 {
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.wrapper {
		position: relative;
		display: flex;
		padding-left: 30px;
		width: 100%;
		.y-axis-label {
			position: absolute;
			bottom: 0;
			left: 0;
			transform: rotate(270deg);
			transform-origin: 0 0;
		}

		.data-area {
			border: 1px solid black;
			flex: 1;
			display: flex;
			min-height: 300px;
		}
	}
	.x-axis-label {
		text-align: center;
		margin-top: 24px;
	}
`

const MissionDataColumn = styled.div`
	flex: 1;
	height: 100%;
	min-width: 30px;
`

const Percentage = styled.div`
	background-color: ${({ level }) => `var(--proficiency-level-${level})`};
	display: flex;
	align-items: center;
	justify-content: center;
`
