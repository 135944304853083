import React from 'react'
import { ICON_STYLES } from './constants'
import { MdInfoOutline } from 'react-icons/lib/md'
import { StarIcon } from '../../../assets/icons/analyticsIcons'

/**
 * A box that displays a single number with a title and an icon
 * @param props.value - the number to display
 * @param props.title - the title or header of the box
 * @param props.explanation - the explanation of the value. Displayed when hovering over the info icon
 * @param props.icon - the icon to display
 */
export default function FunFactViewer({
	value,
	title,
	explanation,
}: {|
	value: number,
	title: string,
	explanation?: string,
|}): React$Node {
	return (
		<div className="rounded-lg flex flex-col items-center py-6 px-0 font-bold bg-primary-750">
			<StarIcon style={ICON_STYLES} />
			<div className="font-bold my-2 mx-0">
				{title}
				{explanation && (
					<span className="cursor-pointer ml-2" title={explanation}>
						<MdInfoOutline className="inline" />
					</span>
				)}
			</div>
			<div className="py-2 px-6 bg-primary-400 rounded">{value}</div>
		</div>
	)
}
