import { useState, useEffect } from 'react'
import {
	useQuery,
	useQueryClient,
	useMutation,
	type UseQueryResult,
	type UseMutationResult,
} from 'react-query'
import axios from 'axios'
import { produce } from 'immer'
import { fetchUser, handleMarkTrainingStatus, fetchDistrictFromSchool } from '../networkCalls'
import { getName } from '../../utility/helpers'
import { rolesEnum } from '../../store/types'
import config from '../../config'
import { ONE_MINUTE } from '../../constants'
import NetworkCommunicator from '../NetworkCommunicator'
import type { UserPreferences, UserEmailPreferences } from '../../types'
import type { User, Role } from '../../store/types'
import type { ReduxQuestionGroup, FavoriteQuestionGroup } from '../../models/QuestionGroup'
import { EXAMPLE_FAVORITE_QUESTION_GROUP_ID } from '../../constants/exampleData'
import { getQuestionGroupQuery } from './questionGroup'

export const USER_QUERY_KEY = 'user'
export const USER_DISTRICT_KEY = 'user-district'
export const USER_PREFERENCE_QUERY_KEY = ['user-preference']
export const USER_EMAIL_CHANGE_REQUEST_KEY = 'user-change-requests'

export const getUserQuestionGroupsKey = (isUser: boolean): Array<string> => {
	return ['user-question-groups', isUser.toString()]
}

export const getUserPreferenceQueryKey = (isUser: boolean): Array<string> => {
	return ['user-preference', isUser.toString()]
}

export const getUserAccountDataVerificationStatusNeededQueryKey = (
	userId: ?string
): Array<?string> => {
	return ['account-data-verification-needed', userId]
}

export const USER_LICENSE_DATA = 'user-license-data'
/**
 * Gets the current user if there is one. If there is not a user, null is returned.
 *
 * @return {UseQueryResult} The result of calling `useQuery`, with an extra property `user` that matches `result.data`
 */
export default function useUser(): {
	...UseQueryResult<User>,
	user: ?User,
} {
	const result = useQuery(USER_QUERY_KEY, fetchUser, {
		staleTime: 5 * ONE_MINUTE,
	})
	return { ...result, user: result.data }
}

/**
 * useUserDistrictId - get the current user's district Id
 *
 * @return {?string} - the id of the current user (null/undefined if user belongs to no district or district is still loading)
 */
export function useUserDistrictId(): ?string {
	// TODO - it would be better if the district was added to the user
	const { user } = useUser()
	const schoolsDistrictId = useQuery(USER_DISTRICT_KEY, fetchDistrictFromSchool(user?.schoolId), {
		staleTime: 5 * ONE_MINUTE,
	}).data
	return user?.districtId || schoolsDistrictId
}

export type UpdateTrainingPayload = { type: 'MARK_COMPLETE' | 'MARK_INCOMPLETE', stageId: string }
/**
 * Provides a function that will mark a user training status both optimistically on the front end and also makes a network call to
 * update user training.
 */
export function useUpdateUserTraining(): UpdateTrainingPayload => void {
	const queryCache = useQueryClient()
	const { mutate } = useMutation(handleMarkTrainingStatus, {
		onMutate: async payload => {
			await queryCache.cancelQueries(USER_QUERY_KEY)
			const previousValue = queryCache.getQueryData(USER_QUERY_KEY)
			queryCache.setQueryData(USER_QUERY_KEY, old => {
				return optimisticTrainingUpdate(old, payload)
			})
			return previousValue
		},
		onError: (_, __, previousValue) => {
			return queryCache.setQueryData(USER_QUERY_KEY, previousValue)
		},
		onSuccess: () => {
			queryCache.invalidateQueries(USER_QUERY_KEY)
		},
	})
	return mutate
}

// create a string with as much of the user's name as we can get
export function useUserName(): ?string {
	const { user } = useUser()
	return getName(user?.firstName, user?.lastName)
}

const NO_ROLES = []
// returns the user.roles array, else []
export function useUserRoles(): Role[] {
	const { user } = useUser()
	return user?.roles || NO_ROLES
}

// checks to see if the user has a unverified DistrictAdmin or SchoolAdmin role
export function useHasUnverifiedRole(): boolean {
	const roles = useUserRoles()
	return Boolean(roles.find(role => !role.verified && !role.hasOwnProperty('reasonForRejection')))
}

// checks to see if the user has a verified LICENSE_ADMIN role
export function useIsLicenseAdmin(): boolean {
	const roles = useUserRoles()
	return roles.some(role => role.role === rolesEnum.LICENSE_ADMIN && role.verified)
}

// checks to see if the user has a verified SCHOOL_ADMIN role
export function useIsSchoolAdmin(): boolean {
	const roles = useUserRoles()
	return roles.some(role => role.role === rolesEnum.SCHOOL_ADMIN && role.verified)
}

// checks to see if the user has a verified DEPRECATED_SCHOOL_ADMIN role
export function useIsSchoolFlightDirector(): boolean {
	const roles = useUserRoles()
	return roles.some(role => role.role === rolesEnum.DEPRECATED_SCHOOL_ADMIN && role.verified)
}

// checks to see if the user has a verified DISTRICT_ADMIN role
export function useIsDistrictAdmin(): boolean {
	const roles = useUserRoles()
	return roles.some(role => role.role === rolesEnum.DISTRICT_ADMIN && role.verified)
}

/**
 * Gets a set of the user's verified roles.
 */
export function useVerifiedRoles(): Set<$Keys<typeof rolesEnum>> {
	const roles = useUserRoles()
	return new Set(roles.filter(role => role.verified).map(role => role.role))
}

// checks to see if the user has a verified account
export function useIsUserVerified(): boolean {
	const { user } = useUser()
	return Boolean(user?.email_verification?.confirmed)
}

// checks to see if user is connected to a specific school.
export function useUserHasNoSchool(): boolean {
	const { user } = useUser()
	return !user?.schoolId
}

/**
 * Adds `payload` to the given users training.stageActions. If user.training doesn't exist, adds it. Does not mutate user.
 * @param {User} user
 * @param {UpdateTrainingPayload} payload
 * @returns {User} the new user with the update training info.
 */
function optimisticTrainingUpdate(user: User, payload: UpdateTrainingPayload) {
	return produce(user, (draft: User) => {
		if (!draft.training) {
			draft.training = { stageActions: [] }
		}
		draft.training.stageActions.push({ ...payload, timestamp: Date.now() })
	})
}

/**
 * useEmailPreference - get the email preferences for the currently logged in user
 *
 * @return {UseQueryResult<UserEmailPreferences, Error>} - the react query result for the user's email preferences
 */
export function useEmailPreference(): UseQueryResult<UserEmailPreferences, Error> {
	const [shouldRefetchQuickly, setShouldRefetchQuickly] = useState(false)
	const queryData = useQuery(
		['user-email-preference'],
		() =>
			axios
				.get(`${config.loginServer}/api/email-preferences`, { withCredentials: true })
				.then(res => res.data?.preferences),
		{
			cacheTime: Infinity,
			refetchInterval: shouldRefetchQuickly ? 2000 : Infinity,
		}
	)

	const currentlyUpdating = Boolean(queryData.data?.pendingUpdates)
	useEffect(() => {
		setShouldRefetchQuickly(currentlyUpdating)
	}, [currentlyUpdating])

	return queryData
}

/**
 * useUserPreference - returns the current user's preferences query from react-query
 *
 * @return {UseQueryResult<UserPreferences, Error>} - the running query for user preferences
 */
export function useUserPreference(): UseQueryResult<UserPreferences, Error> {
	const { user } = useUser()
	return useQuery(getUserPreferenceQueryKey(Boolean(user)), () => {
		if (!user) return {}
		return axios
			.get(`${config.loginServer}/api/user-preferences`, { withCredentials: true })
			.then(res => res.data?.preferences)
	})
}

/**
 * useUserStandardState - get the state to display for the standards for
 *
 * @returns string - the state to display the standards for
 */
export function useUserStandardState(): ?string {
	const { user } = useUser()
	const { data: userPreferences } = useUserPreference()
	return userPreferences?.stateForStandards || user?.state
}

/**
 * useFavoriteQuestionGroups - get the ids for the
 * favorite question groups for the current user
 *
 * @returns the array of favorite question groups for a specific user
 */
export function useFavoriteQuestionGroups(): ReduxQuestionGroup[] | void {
	const queryCache = useQueryClient()
	const { user } = useUser()
	const userId = user?._id ?? ''

	const { data } = useQuery(
		getUserQuestionGroupsKey(Boolean(user)),
		() => {
			if (!userId) {
				return getQuestionGroupQuery(EXAMPLE_FAVORITE_QUESTION_GROUP_ID).then(
					favoriteQuestionGroup => {
						return favoriteQuestionGroup ? [favoriteQuestionGroup] : []
					}
				)
			}
			return NetworkCommunicator.GET(`/users/${userId}/favoriteQuestionGroups`, {
				host: `${config.missionsAPIURL}/api`,
			}).then(res => {
				return res.questionGroups
			})
		},
		{
			refetchOnWindowFocus: false,
			staleTime: 5 * ONE_MINUTE,
			onError: (_, __, previousValue) => {
				return queryCache.setQueryData(
					getUserQuestionGroupsKey(Boolean(user)),
					previousValue
				)
			},
		}
	)
	return data
}

/**
 * A hook for adding a new favorite question group to the current user. Returns the UseMutationResult for the
 * create favorite question group mutation.
 */
export function useAddFavoriteQuestionGroup(
	userId: string
): UseMutationResult<ReduxQuestionGroup, Error & { status?: number }, any> {
	const queryClient = useQueryClient()
	const { user } = useUser()

	return useMutation(
		(questionGroup: ReduxQuestionGroup): Promise<ReduxQuestionGroup> => {
			return NetworkCommunicator.PUT(`/users/${userId}/favoriteQuestionGroups`, {
				host: `${config.missionsAPIURL}/api`,
				body: { questionGroupId: questionGroup._id },
			})
		},
		{
			onMutate: async (questionGroup: ReduxQuestionGroup) => {
				await queryClient.cancelQueries({
					queryKey: getUserQuestionGroupsKey(Boolean(user)),
				})
				const previousValue = queryClient.getQueryData(
					getUserQuestionGroupsKey(Boolean(user))
				)

				queryClient.setQueryData(getUserQuestionGroupsKey(Boolean(user)), (old = []) => [
					...old,
					questionGroup,
				])

				return previousValue
			},
			onError: (_, __, previousValue) => {
				return queryClient.setQueryData(
					getUserQuestionGroupsKey(Boolean(user)),
					previousValue
				)
			},
		}
	)
}

/**
 * A hook for deleting a favorite question group to the current user. Returns the UseMutationResult for the
 * delete favorite question group mutation.
 */
export function useDeleteFavoriteQuestionGroup(
	userId: string
): UseMutationResult<FavoriteQuestionGroup, Error & { status?: number }, any> {
	const { user } = useUser()
	const queryClient = useQueryClient()

	return useMutation(
		(questionGroupId: string): Promise<FavoriteQuestionGroup> => {
			return NetworkCommunicator.DELETE(`/users/${userId}/favoriteQuestionGroups`, {
				host: `${config.missionsAPIURL}/api`,
				body: { questionGroupId },
			})
		},
		{
			onMutate: async (questionGroupId: string) => {
				await queryClient.cancelQueries({
					queryKey: getUserQuestionGroupsKey(Boolean(user)),
				})
				const previousValue = queryClient.getQueryData(
					getUserQuestionGroupsKey(Boolean(user))
				)

				queryClient.setQueryData(getUserQuestionGroupsKey(Boolean(user)), (old = []) => {
					return old.filter(oldQuestionGroup => oldQuestionGroup._id !== questionGroupId)
				})
				return previousValue
			},
			onError: (_, __, previousValue) => {
				return queryClient.setQueryData(
					getUserQuestionGroupsKey(Boolean(user)),
					previousValue
				)
			},
		}
	)
}

// This type enforces that the user can only update properties in this type
type UpdateUserPayload = {|
	schoolId?: string,
	firstName?: string,
	lastName?: string,
	email?: string,
	license?: null, // null means leave license
|}

type UpdateUserArg = {
	userId: string,
	userUpdate: UpdateUserPayload,
}

/**
 * A hook for updating a user's school. Returns the UseMutationResult for the
 * update user mutation.
 */
export function useUpdateUser(): UseMutationResult<User, Error, UpdateUserArg> {
	const queryClient = useQueryClient()
	const { user } = useUser()
	return useMutation(
		({ userId, userUpdate }) => {
			return NetworkCommunicator.PATCH(`/api/users/${userId}`, {
				host: `${config.loginServer}`,
				body: userUpdate,
			})
		},
		{
			onSuccess: () => {
				queryClient.invalidateQueries(USER_QUERY_KEY)
				queryClient.invalidateQueries(USER_PREFERENCE_QUERY_KEY)
				queryClient.invalidateQueries(USER_EMAIL_CHANGE_REQUEST_KEY)
				queryClient.invalidateQueries(USER_LICENSE_DATA)
				queryClient.invalidateQueries(getUserPreferenceQueryKey(Boolean(user)))
			},
		}
	)
}

/**
 * useUserEmailChangeRequests - use the email change requests for the given user
 *
 * @param {Object} args
 * @param {string} args.userId - the id of the user to get the email requests for
 *
 * @return {UseQueryResult<Array<{ newEmail: string, _id: string }>, { status?: 403 }>} - the email change request data for the given user
 */
export function useUserEmailChangeRequests({
	userId,
}: {
	userId: string,
}): UseQueryResult<Array<{ newEmail: string, _id: string }>, { status?: 403 }> {
	const currentUserId = useUser()?.user?._id
	return useQuery(
		USER_EMAIL_CHANGE_REQUEST_KEY,
		() =>
			axios
				.get(`${config.loginServer}/api/users/email-change-request/${userId}`, {
					withCredentials: true,
				})
				.then(({ data }) => {
					return data?.emailChangeRequests
				}),
		{
			enabled: Boolean(currentUserId),
		}
	)
}

/**
 * useDeleteEmailChangeRequest - a mutation used to delete an email change request
 */
export function useDeleteEmailChangeRequest(): UseMutationResult<
	Array<{ newEmail: string, _id: string }>,
	Error,
	{ requestId: string }
> {
	const queryClient = useQueryClient()
	return useMutation(
		({ requestId }) =>
			axios
				.delete(`${config.loginServer}/api/users/email-change-request/${requestId}`, {
					withCredentials: true,
				})
				.then(({ data }) => {
					return data?.emailChangeRequests
				}),
		{
			onSuccess: () => {
				queryClient.invalidateQueries(USER_EMAIL_CHANGE_REQUEST_KEY)
			},
		}
	)
}

export const LICENSE_OWNER_TYPES = {
	DISTRICT: 'DISTRICT',
	SCHOOL: 'SCHOOL',
	SINGLE_OWNER: 'SINGLE_OWNER',
	FREE: 'FREE',
}

export type LicenseData = {|
	_id: string,
	expiresOn: string,
	owner:
		| {
				type: 'DISTRICT',
				districtId: string,
		  }
		| { type: 'SCHOOL', schoolId: string }
		| { type: 'SINGLE_OWNER', userId: string }
		| { type: 'FREE', userId: string },
|}

/**
 * useMyLicenseData - get data about the license the user is currently a part of
 *
 * @return {UseQueryResult<?LicenseData, Error>}
 */
export function useMyLicenseData(): UseQueryResult<LicenseData | null, Error> {
	return useQuery(
		USER_LICENSE_DATA,
		() => {
			return axios
				.get(`${config.loginServer}/api/licenses/my-license-data`, {
					withCredentials: true,
				})
				.then(({ data }) => data.licenseData)
		},
		{
			staleTime: 5 * ONE_MINUTE,
		}
	)
}

export const ACCOUNT_DATA_VERIFICATION_STATUS = {
	REQUIRED: 'REQUIRED',
	ALLOWED: 'ALLOWED',
	COMPLETED: 'COMPLETED',
}

export type AccountDataVerificationStatus = $Keys<typeof ACCOUNT_DATA_VERIFICATION_STATUS>

/**
 * useAccountDataVerificationStatus - get the status of the user's data verification (ie. weather they need to verify their account data)
 */
export function useAccountDataVerificationStatus(): UseQueryResult<
	AccountDataVerificationStatus,
	Error
> {
	const { user } = useUser()
	const userId = user?._id
	return useQuery(
		getUserAccountDataVerificationStatusNeededQueryKey(userId),
		() => {
			if (!userId) {
				return null
			}
			return axios
				.get(`${config.loginServer}/api/users/account-data-verification/status`, {
					withCredentials: true,
				})
				.then(({ data }) => data.status)
		},
		{
			staleTime: 30 * ONE_MINUTE,
		}
	)
}
