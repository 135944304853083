// @flow
import { config } from '../config'

import debounce from 'lodash/debounce'
import { useRef, useMemo, useEffect, useState, useCallback, useLayoutEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { toast } from 'react-toastify'
import { useQueryParams, StringParam } from 'use-query-params'

export function usePrevious<T>(value: ?T): ?T {
	const ref = useRef()

	useEffect(() => {
		ref.current = value
	}, [value])

	// Return previous value (happens before update in useEffect above)
	return ref.current
}

/**
 * useLocationParameters - get a map of all the search parameters in the url
 *
 * @return {{ [key: string]: string }} - a map of the search parameter names to their values
 */
export function useLocationParameters(): { [key: string]: string } {
	const searchString = useLocation().search
	return useMemo(() => {
		const searchData: { [key: string]: string } = {}
		new URLSearchParams(searchString).forEach((value: string, key: string) => {
			searchData[key] = value
		})
		return searchData
	}, [searchString])
}

export type PartialPanel = {
	title?: string,
	component: React$ComponentType<any>,
	props?: ?any,
}

type Panel = {
	...PartialPanel,
	id: string,
}
type PanelStack = Array<Panel>
export type AddPanel = PartialPanel => mixed

/**
 * A hook that provides a panel stack along with functions to add to and remove from the stack. The `panelStack` is made up of
 * `Panel`s that indicate which component to show for the given panel. The `initialPanel` can never be removed from the stack
 * @param {Panel} initialPanel The initial panel in the stack. This panel can never be removed.
 * @return {Object} object The panel stack functionality
 * @return {PanelStack} object.panelStack The current state of the panel stack
 * @return {Function} object.goBack A function to remove a panel from the stack
 * @return {(Panel) => mixed} object.add A function to add a panel to the stack
 * @return {Function} object.goToStart A function which removes all but the first panel on the stack
 */
export function usePanelStack(
	initialPanel: PartialPanel
): { panelStack: PanelStack, goBack: () => mixed, add: AddPanel, goToStart: () => mixed } {
	const panelIdRef = useRef(0)
	/**
	 * Creates a full `Panel` by combining the given `PartialPanel` and a new unique id.
	 */
	function getPanelWithId(panel: PartialPanel): Panel {
		return { ...panel, id: String(panelIdRef.current++) }
	}
	const [panelStack, setPanelStack] = useState(() => [getPanelWithId(initialPanel)])

	const goBack = useCallback(function() {
		setPanelStack(panelStack => {
			if (panelStack.length <= 1) {
				return panelStack
			}
			const panelStackCopy = [...panelStack]
			panelStackCopy.pop()
			return panelStackCopy
		})
	}, [])

	const add = useCallback(function(panel: PartialPanel) {
		setPanelStack(panelStack => [...panelStack, getPanelWithId(panel)])
	}, [])

	const goToStart = useCallback(function() {
		setPanelStack(panelStack => {
			if (!panelStack?.length) {
				return []
			}
			return [panelStack[0]]
		})
	}, [])

	return { panelStack, goBack, add, goToStart }
}

/**
 * Gets the dimensions of the window.
 * Copied from https://usehooks.com/useWindowSize/
 */
export function useWindowSize(): { width: number, height: number } {
	const [windowSize, setWindowSize] = useState({
		width: window.innerWidth,
		height: window.innerHeight,
	})

	useEffect(() => {
		// Handler to call on window resize
		function handleResize() {
			setWindowSize({
				width: window.innerWidth,
				height: window.innerHeight,
			})
		}
		window.addEventListener('resize', handleResize)
		return () => window.removeEventListener('resize', handleResize)
	}, [])

	return windowSize
}

/**
 * Gets dimensions that match the provided aspect ratio, but fit inside of the current window dimensions. If the device is in portrait mode, the width
 * and height will be swapped, meaning it will use the larger of screen width and screen height as the device width.
 * @param {number} aspectRatioWidth The width of the desired aspect ratio
 * @param {number} aspectRatioHeight The height of the desired aspect ratio
 * @return {Object} An object with `width` and `height`, which are the requested dimensions
 */
export function useScaledAspectRatio(
	aspectRatioWidth: number,
	aspectRatioHeight: number
): {
	width: number,
	height: number,
} {
	let { width, height } = useWindowSize()
	const orientation = useScreenOrientation()
	if (orientation?.startsWith('portrait')) {
		;[width, height] = [height, width]
	}
	const windowIsTooWide = width / height > aspectRatioWidth / aspectRatioHeight
	if (windowIsTooWide) {
		return {
			width: (aspectRatioWidth / aspectRatioHeight) * height,
			height,
		}
	} else {
		return {
			width,
			height: width * (aspectRatioHeight / aspectRatioWidth),
		}
	}
}

type Orientation =
	| 'portrait-primary'
	| 'portrait-secondary'
	| 'landscape-primary'
	| 'landscape-secondary'

const getOrientation = (): Orientation | void => {
	let orientation =
		window.screen?.orientation?.type ||
		window.screen?.mozOrientation ||
		window.screen?.msOrientation
	if (orientation) {
		return orientation
	}

	if (typeof window.orientation === 'number') {
		return window.orientation === 0
			? 'portrait-primary'
			: window.orientation === 90
			? 'landscape-primary'
			: window.orientation === -90
			? 'landscape-secondary'
			: 'portrait-secondary'
	}

	return window.innerWidth > window.innerHeight ? 'landscape-primary' : 'portrait-primary'
}

/**
 * A hook to get the screen orientation
 * @return {Orientation | void} The orientation if it's supported, otherwise undefined
 */
export function useScreenOrientation(): Orientation | void {
	const [orientation, setOrientation] = useState<Orientation | void>(getOrientation())

	useEffect(() => {
		const updateOrientation = () => {
			setOrientation(getOrientation())
		}
		if (window.screen?.orientation != null) {
			window.screen.orientation.addEventListener('change', updateOrientation)
			return () => window.screen.orientation.removeEventListener('change', updateOrientation)
		} else {
			window.addEventListener('resize', updateOrientation)
			return () => window.removeEventListener('resize', updateOrientation)
		}
	}, [])

	return orientation
}

export const QUERY_PARAM_NOTIFICATIONS = {
	linkError: {
		toastType: 'error',
		notifications: {
			USER_LINKED_TO_SERVICE: `Your ${config.companyName.base} account is already connected to a different account from that external service.`,
			PREVIOUSLY_LINKED_EXTERNAL_ACCOUNT: `That external account is already connected to another ${config.companyName.base} account`,
			DEFAULT:
				'There was an unknown error while connecting your external account. Please try again.',
			NO_USER: 'Failed to get a user on launch. Please contact us if this problem persists.',
			FAILURE:
				'There was an error while connecting your external account. Please contact us if this problem persists.',
		},
	},
	requestClassroomError: {
		toastType: 'error',
		notifications: {
			FAILED_CLASSES:
				'We were unable to get classes from that external service. Make sure you accept all requested permissions.',
			DEFAULT:
				'There was an unknown error while updating classes. Make sure you accept all requested permissions.',
		},
	},
}

type NotificationQueryKey = $Keys<typeof QUERY_PARAM_NOTIFICATIONS>

/**
 * Gets the message that should be displayed when the url query params has a given `queryKey` and `value`.
 *
 * @param {NotificationQueryKey} queryKey The key of the query param from the url
 * @param {string} value The value of the query param from the url that corresponds to `queryKey`
 * @returns The message for the given key and value, as well as whether the message is a default message
 */
function getNotificationMessage(queryKey: NotificationQueryKey, value: string) {
	const notifications = QUERY_PARAM_NOTIFICATIONS[queryKey].notifications
	return notifications[value]
		? { message: notifications[value] }
		: { message: notifications.DEFAULT, isDefaultMessage: true }
}

/**
 * A hook that will display a notification for any of the passed `queryKeys` that appear in the url. If any of the query param keys in the url
 * match any values in `queryKeys`, a notification will be displayed. The displayed message corresponds to the value of the query param, and those
 * messages are defined in `QUERY_PARAM_NOTIFICATIONS`. If the message is expected, the query param is removed from the url. If the value is not expected,
 * a default message is displayed and the query param is not removed from the url (this should not happen in practice).
 *
 * Usage:  useQueryNotifications('linkError')
 *     This example will display a notification anytime the url query contains `linkError=<anything>`
 * @param {NotificationQueryKey[]} queryKeys Any keys that should be watched in the url query string
 */
export function useQueryNotifications(...queryKeys: NotificationQueryKey[]) {
	const queryParamsConfig = {}
	queryKeys.forEach(queryKey => {
		queryParamsConfig[queryKey] = StringParam
	})

	const [query, setQuery]: [
		{ [NotificationQueryKey]: string | void },
		({ [NotificationQueryKey]: string | void }, 'replaceIn') => mixed
	] = useQueryParams(queryParamsConfig)

	const displayedMessages = useRef(new Set())

	useEffect(() => {
		const paramsToDelete = {}
		queryKeys.forEach((queryKey: NotificationQueryKey) => {
			if (!query[queryKey] || displayedMessages.current.has(queryKey)) {
				return
			}

			const { message, isDefaultMessage } = getNotificationMessage(queryKey, query[queryKey])
			if (!isDefaultMessage) {
				paramsToDelete[queryKey] = undefined
			}
			toast[QUERY_PARAM_NOTIFICATIONS[queryKey].toastType](message, {
				autoClose: false,
			})
			displayedMessages.current.add(queryKey)
		})
		if (Object.keys(paramsToDelete).length > 0) {
			setQuery(
				paramsToDelete,
				'replaceIn' // Don't add to the browser history
			)
		}
	}, [query, setQuery, queryKeys])
}

/**
 * Copied form https://www.30secondsofcode.org/react/s/use-hash
 * @returns
 */
export const useHash = (): [string, (newHash: string) => void] => {
	const [hash, setHash] = useState(() => window.location.hash)

	useEffect(() => {
		const hashChangeHandler = () => {
			setHash(window.location.hash)
		}
		window.addEventListener('hashchange', hashChangeHandler)
		return () => {
			window.removeEventListener('hashchange', hashChangeHandler)
		}
	}, [])

	const updateHash = useCallback(
		newHash => {
			if (newHash !== hash) window.location.replace(newHash)
		},
		[hash]
	)

	return [hash, updateHash]
}

/**
 * A hook that will debounce the state change for a certain value provided.
 * Updated from from https://usehooks.com/useDebounce/
 *
 * @param {T} value value that will be updated after a certain delay
 * @param {number} delay delay in seconds
 * @returns {T} The debounced value
 */
export function useDebounce<T>(value: T, delay?: number = 500): T {
	// State and setters for debounced value
	const [debouncedValue, setDebouncedValue] = useState(value)
	useEffect(
		() => {
			// Update debounced value after delay
			const handler = setTimeout(() => {
				setDebouncedValue(value)
			}, delay)
			// Cancel the timeout if value changes (also on delay change or unmount)
			// This is how we prevent debounced value from updating if value is changed ...
			// .. within the delay period. Timeout gets cleared and restarted.
			return () => {
				clearTimeout(handler)
			}
		},
		[value, delay] // Only re-call effect if value or delay changes
	)
	return debouncedValue
}

/**
 * This hook can be used in any component, displaying to the developer why the component updated.
 * Pass in the name of the component, for logging purposes, and a props object containing the data
 * you would like to monitor. It not only works with a component's props but also state objects.
 * @param {string} name
 * @param {{}} props
 */
export function useWhyDidYouUpdate(name: string, props: any) {
	// Get a mutable ref object where we can store props ...
	// ... for comparison next time this hook runs.
	const previousProps = useRef()

	useEffect(() => {
		if (previousProps.current) {
			// Get all keys from previous and current props
			const allKeys = Object.keys({ ...previousProps.current, ...props })
			// Use this object to keep track of changed props
			const changesObj = {}
			// Iterate through keys
			allKeys.forEach(key => {
				// If previous is different from current
				if (previousProps.current && previousProps.current[key] !== props[key]) {
					// Add to changesObj
					changesObj[key] = {
						from: previousProps.current[key],
						to: props[key],
					}
				}
			})

			// If changesObj not empty then output to console
			if (Object.keys(changesObj).length) {
				console.log('[why-did-you-update]', name, changesObj)
			}
		}

		// Finally update previousProps with current props for next hook call
		previousProps.current = props
	})
}

/**
 * a hook that will check if a user is using a mobile sized screen or not
 */
export const useIsMobile = (): boolean => {
	const [isMobile, setIsMobile] = useState(false)

	useLayoutEffect(() => {
		const checkSize = (): void => {
			setIsMobile(window.innerWidth < 992)
		}
		checkSize()

		window.addEventListener('resize', debounce(checkSize, 250))
		return (): void => window.removeEventListener('resize', checkSize)
	}, [])

	return isMobile
}
