import React, { useState } from 'react'
import { StartMissionButton } from './../../components/StartMissionButton'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components/macro'
import { GoTriangleUp, GoTriangleDown } from 'react-icons/lib/go'
import { MinimalDialog } from './../../components/modal/Modal'
import RalfLearning from '../../assets/RALF_Learning_Illustration.png'
import { useUnfinishedMissions } from './../../queryHooks/mission'
import { useInteractionContext } from '../../components/UserGuide/interactions'
import { useSelector } from 'react-redux'
import { getAutomatedSimulationFromId } from '../../store/simulation'
import type { AutomatedSimulation } from '../../models/Simulation'
import MissionGuideModal from '../../components/missionGuideModal'
import { useMissionGuide } from '../../components/missionGuideModal'
import Markdown from '../../components/Markdown'
import { getMostRelevantStandards } from '../../utility/standards'
import { useUserStandardState } from '../../services/hooks/user'
import { useStandardSets } from '../../services/hooks/standards'
import { Button } from '@mission.io/styles'
import { MdLink } from 'react-icons/lib/md'
import { copyText } from '../../components/helpers/CopiableLink'
import type { ThemeType } from '@mission.io/styles'
import { config } from '../../config'

const CUT_OFF_LENGTH = 215

/**
 * A dialog box that is displayed after a mission is prepped. Gives some links for things to do after prepping a mission.
 */
export function MissionPreppedDialog({ missionId }: { missionId: string }): React$Node {
	const history = useHistory()
	const { data: unfinishedMissions } = useUnfinishedMissions()
	const mission = missionId ? unfinishedMissions?.[missionId] : null
	const simulationId = mission?.simulationId
	const { lookups: interactionLookup, sendClickInteraction } = useInteractionContext()
	const simulation: ?AutomatedSimulation = useSelector(getAutomatedSimulationFromId(simulationId))

	const { data: missionGuide } = useMissionGuide(simulationId)
	const standardState = useUserStandardState()
	const { data: standardSets } = useStandardSets()

	const relevantStandards = getMostRelevantStandards({
		stateAbbreviation: standardState,
		standards: simulation?.standards ?? [],
		fallback: true,
		standardSets: standardSets ?? [],
	})

	const [isGuideOpen, setIsGuideOpen] = useState(false)
	const [isShowingMore, setIsShowingMore] = useState(false)

	const { standardsPreview, hasMore } = getStandardsPreview(relevantStandards)

	if (!mission) {
		// If their mission doesn't exist as an unfinished mission, don't show the dialog
		return null
	}

	return (
		<MinimalDialog
			shouldCloseOnOverlayClick={false}
			isOpen={!!simulation}
			onClose={() => {
				sendClickInteraction(interactionLookup.clickIds.CANCEL_MISSION_PREPPED_DIALOG)
				history.replace({ ...history.location, state: undefined })
			}}
			css="padding: 0;"
			aria={{ labelledby: 'mission-ready-header' }}>
			<Header>
				<h2 id="mission-ready-header" className="text-3xl">
					Your mission is ready to launch!
				</h2>
				<Img src={RalfLearning} alt="Ralf learning" />
			</Header>
			<div className=" max-w-3xl flex flex-col items-center gap-2 py-6 px-8">
				<h3 className="font-bold text-2xl">What now?</h3>
				<div className="text-lg mb-2">
					Before launching into the mission, review the standards with your students and
					check out the step-by-step mission guide for a successful mission!
				</div>
				{relevantStandards.length > 0 && (
					<DetailsBox className="pb-0">
						<h4 className="font-bold mb-2">Targeted Standards</h4>
						{(!hasMore || !isShowingMore) && <Markdown>{standardsPreview}</Markdown>}
						{hasMore && (
							<>
								{isShowingMore &&
									relevantStandards.map(standard => (
										<div key={standard.name}>
											<Markdown>{standard.full}</Markdown>
										</div>
									))}
								<Button
									outline
									variant="secondary"
									onClick={() => setIsShowingMore(!isShowingMore)}
									className="flex items-center gap-2 -mb-4 rounded-b-none mx-auto">
									{isShowingMore ? 'Show Less' : 'More Info'}{' '}
									{isShowingMore ? (
										<GoTriangleUp className="inline" />
									) : (
										<GoTriangleDown className="inline" />
									)}
								</Button>
							</>
						)}
					</DetailsBox>
				)}
				{!!missionGuide && simulation && (
					<DetailsBox>
						<MissionGuideRow>
							<div>
								<h4 className="font-bold mb-2">Mission Guide</h4>
								<div>
									The guide contains all of the questions (and answers),
									vocabulary, and supplemental resources to prepare your students
									prior to mission launch.
								</div>
							</div>
							<SeeGuideButton
								outline
								variant="secondary"
								onClick={() => setIsGuideOpen(true)}>
								See Guide
							</SeeGuideButton>
							<MissionGuideModal
								simulationId={simulation._id}
								isOpen={isGuideOpen}
								onRequestClose={() => setIsGuideOpen(false)}
							/>
						</MissionGuideRow>
					</DetailsBox>
				)}
				<StartMissionButton variant="secondary" mission={mission}>
					Start Mission
				</StartMissionButton>
				<div
					className="font-bold cursor-pointer flex flex-row gap-1"
					role="button"
					title="Copy Mission Link"
					onClick={() =>
						copyText({
							text: `${config.controlsUrl}/${mission.code}`,
							failureMessage: 'Failed to copy link to clipboard',
							successMessage: 'Copied link to clipboard!',
						})
					}>
					Copy Mission Link <MdLink className="w-6 h-6" />
				</div>
			</div>
		</MinimalDialog>
	)
}

/**
 * Gets preview text for the given `relevantStandards` array. If the preview is the entire text, `hasMore` will be false.
 */
function getStandardsPreview(relevantStandards): { standardsPreview: string, hasMore: boolean } {
	if (!relevantStandards.length) {
		return { standardsPreview: '', hasMore: false }
	}

	const firstStandard = relevantStandards[0]

	if (firstStandard.full.length <= CUT_OFF_LENGTH && relevantStandards.length === 1) {
		return { standardsPreview: firstStandard.full, hasMore: false }
	}

	return {
		standardsPreview: `${firstStandard.full.substring(0, CUT_OFF_LENGTH)}${
			firstStandard.full.length > CUT_OFF_LENGTH ? '...' : ''
		}`,
		hasMore: true,
	}
}

const Header = styled.div.attrs({ className: 'w-full flex flex-col items-center p-6 font-bold' })`
	${({ theme }: { theme: ThemeType }) => `
		background-image: linear-gradient(${theme.primaryDark}, #1B4161);
		color: ${theme.white};
	`}
`

const Img = styled.img`
	width: max(12vw, 200px);
	min-width: max(12vw, 200px);
	height: max(12vw, 200px);
	min-height: max(12vw, 200px);
`

const DetailsBox = styled.div`
	width: 100%;

	${({ theme }) => `
		border: 3px solid ${theme.primary100};
		border-radius: var(--spacing2x-dont-use);
		padding: var(--spacing2x-dont-use);
		margin-bottom: var(--spacing1x-dont-use);
	`}
`

const MissionGuideRow = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
`

const SeeGuideButton = styled(Button)`
	height: fit-content;
`
