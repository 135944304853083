import type { StageWithIsComplete, LeafStageWithIsComplete } from '../../services/hooks/training'

function isActionable(stage: StageWithIsComplete): boolean %checks {
	return !!stage.videoUrl || !!stage.loomUrl
}

export function getStageCompletionPercentage(stage: StageWithIsComplete): number {
	return (getTotalStagesCompletedCount(stage) / getTotalStageCount(stage)) * 100
}

function getTotalStagesCompletedCount(stage: StageWithIsComplete) {
	let count = isComplete(stage) ? 1 : 0

	if (stage.stages) {
		count = stage.stages.reduce((acc, currentStage) => {
			return acc + getTotalStagesCompletedCount(currentStage)
		}, count)
	}

	return count
}

function isComplete(stage: StageWithIsComplete) {
	return isActionable(stage) && stage.isComplete
}

function getTotalStageCount(stage: StageWithIsComplete): number {
	let count = isActionable(stage) ? 1 : 0

	if (stage.stages) {
		count = stage.stages.reduce((acc, currentStage) => {
			return acc + getTotalStageCount(currentStage)
		}, count)
	}

	return count
}

/**
 * Finds all leaf trainings in the given trainings array, and returns them as an array.
 * @param {StageWithIsComplete[]} trainings The trainings to use to find leaf trainings
 * @return {LeafStageWithIsComplete[]} The leaf trainings
 */
export function getOrderedLeafTrainings(
	trainings: StageWithIsComplete[]
): LeafStageWithIsComplete[] {
	return trainings.flatMap(training =>
		!training.stages ? training : getOrderedLeafTrainings(training.stages)
	)
}
