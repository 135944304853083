import styled from 'styled-components'
import type { StyledType } from 'global-types'

export const FlexContainer: StyledType<> = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	align-content: center;
	text-align: center;
`

export const GridContainer: StyledType<> = styled.div`
	display: flex;
	flex-wrap: wrap;
	gap: var(--spacing2x-dont-use);
	${({ theme }) => `
	& > * {
		min-width: 250px;
		flex: 1 0 auto;
	}
	`}
`
