import React from 'react'
import type {
	SimulationLeaderboardElement,
	SimulationLeaderboard,
} from '@mission.io/mission-toolkit'
import type { UseQueryResult } from 'react-query'
import { useQuery } from 'react-query'
import axios from 'axios'
import { config } from '../../../config'
import { useUser } from '../../../services/hooks'
import { LEADERBOARD_STATUS } from '@mission.io/mission-toolkit'

/**
 * Leaderboard - a react component used to show the leaderboard
 *
 * @param  {{leaderboard:SimulationLeaderboard}} {leaderboard} - the leaderboard to show
 */
export function Leaderboard({ leaderboard }: { leaderboard: SimulationLeaderboard }): React$Node {
	return (
		<div className="py-2 px-4 max-h-[max(50vh,250px)] overflow-y-auto">
			{(leaderboard.status ?? LEADERBOARD_STATUS.AVAILABLE) ===
			LEADERBOARD_STATUS.AVAILABLE ? (
				leaderboard.order.map((element, index) => (
					<LeaderboardElement element={element} index={index} key={index} />
				))
			) : (
				<div>Leaderboard is disabled for this mission</div>
			)}
		</div>
	)
}

/**
 * LeaderboardElement - display an element for the leaderboard
 *
 * @param  {Object} props -the react props
 * @param  {SimulationLeaderboardElement} props.element - the leaderboard element to display
 * @param  {number} props.index - the index of the element in the leaderboard
 */
function LeaderboardElement({
	element,
	index,
}: {
	element: SimulationLeaderboardElement,
	index: number,
}) {
	let moreInfo = []
	if (element.className) {
		moreInfo.push(element.className)
	}
	if (element.teacherName) {
		moreInfo.push(element.teacherName)
	}

	let moreInfoString = moreInfo.length > 0 ? `(${moreInfo.join(' - ')})` : ''
	return (
		<div className="flex flex-row items-center justify-between w-full rounded-sm border-b !border-dusk last:!border-transparent p-1 mb-1">
			<div>
				{index + 1}. {element.schoolName}
				{element.state ? `, ${element.state}` : ''} {moreInfoString}
			</div>
			<div>{Math.floor(element.score).toLocaleString()}</div>
		</div>
	)
}

const getLeaderboardQueryKey = (simulationId, isUser: boolean) => {
	return ['leaderboard', simulationId, isUser.toString()]
}

/**
 * useLeaderboard - get the leaderboard for the given simulation
 *
 * @param  {string} simulationId - the id of the simulation to the leaderboard for
 *
 * @returns UseQueryResult<SimulationLeaderboard>
 */
export function useLeaderboard(simulationId: string): UseQueryResult<SimulationLeaderboard> {
	const { user } = useUser()
	return useQuery(
		getLeaderboardQueryKey(simulationId, Boolean(user)),
		() => {
			if (!user) return null
			return axios
				.get(`${config.missionsAPIURL}/api/leaderboards/${String(simulationId)}`, {
					withCredentials: true,
				})
				.then(res => res.data.leaderboard)
		},

		{ enabled: Boolean(simulationId) }
	)
}
