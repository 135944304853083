import React, { type ComponentType, useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import classNames from 'classnames'
import { STATE_KEYS } from '../../constants'
import styled from 'styled-components/macro'
import { getValueFromState, scrollToTop, getLocationStateFor } from '../../utility/functions'
import { FaUser, FaBars } from 'react-icons/lib/fa'

import GoKey from 'react-icons/lib/go/key'
import { useIsLicenseAdmin } from '../../services/hooks/user'
import useUser from '../../services/hooks/user'
import config from '../../config'
import { ControlledTabs } from '..'
import { Tooltip } from 'react-tooltip'
import { theme } from '@mission.io/styles'

export const links: Array<{
	route: string,
	icon?: string,
	on?: 'fullscreen-hidden' | 'profile',
	border?: boolean,
	text: string,
	highlightedRoutes: string[],
	showNewUntil?: Date,
	hideWhenLoggedIn?: boolean,
	hideWhenNotLoggedIn?: boolean,
}> = [
	{
		route: '/missionLibrary',
		text: 'Missions',
		highlightedRoutes: ['/prep'],
	},
	{
		route: '/standards',
		text: 'Standards',
		highlightedRoutes: [],
		showNewUntil: new Date('1/31/2024'),
	},
	{
		route: '/classes',
		text: 'Classes',
		highlightedRoutes: [],
	},
	{
		route: '/questionGroups',
		text: 'Quiz Questions',
		highlightedRoutes: [],
	},
	{
		route: '/analytics',
		text: 'Analytics',
		highlightedRoutes: [],
	},
	{
		route: 'fullscreen-dropdown',
		icon: FaBars,
		border: true,
		text: 'More',
		highlightedRoutes: [],
	},
	{
		route: '/leaderboards',
		on: 'fullscreen-hidden',
		text: 'Leaderboard',
		highlightedRoutes: [],
	},

	{
		route: '/training',
		on: 'fullscreen-hidden',
		text: 'Training',
		highlightedRoutes: ['/training'],
	},
	{
		route: '/resources',
		on: 'fullscreen-hidden',
		text: 'Resources',
		border: true,
		highlightedRoutes: [],
	},
	{
		route: '/admin',
		hideWhenNotLoggedIn: true,
		on: 'profile',
		icon: GoKey,
		text: 'Admin',
		highlightedRoutes: [],
	},

	{
		route: `${config.loginClientUrl}?sendto=${window.location.toString()}`,
		hideWhenLoggedIn: true,
		text: 'Sign in',
		highlightedRoutes: [],
	},
]

const NAV_SHOW_MORE_ELEMENT_ID = 'NAV_SHOW_MORE_ELEMENT_ID'

const hiddenLinks = links.filter(link => link.on === 'fullscreen-hidden')
const profileLinks = links.filter(link => link.on === 'profile')

/**
 * A list of `li` elements that link to different routes in the app.
 *
 * @param onSelect A function to call when a link is selected
 */
export default function NavLinks({ onSelect }: { onSelect: () => mixed }): React$Node {
	const location = useLocation()
	const currentPath = location.pathname
	const isAdminOnLicense = useIsLicenseAdmin()
	const scrollToTopIndicator = getValueFromState(location.state, STATE_KEYS.restoreToTop)
	const scrollToElementId = getValueFromState(location.state, STATE_KEYS.restoreScrollToElementId)
	const [isMoreOpen, setIsMoreOpen] = useState(false)

	const { user } = useUser()

	useEffect(() => {
		if (scrollToTopIndicator) {
			scrollToTop()
			return
		}
		if (scrollToElementId) {
			const item = document.getElementById(scrollToElementId)
			item && item.scrollIntoView()
		}
	}, [scrollToTopIndicator, scrollToElementId])

	const linkIsActive = link =>
		link.route === currentPath ||
		link.highlightedRoutes.some(highlightedRoute =>
			currentPath.toLowerCase().startsWith(highlightedRoute)
		)

	return links.map(link => {
		if (link.on === 'profile') {
			return null
		}
		if (link.route === '/admin' && !isAdminOnLicense) {
			// do not show the admin tab if they are not an admin on their license
			return null
		}
		if (link.hideWhenNotLoggedIn && !user) {
			return null
		}
		if (link.hideWhenLoggedIn && user) {
			return null
		}

		const props = {}
		let Element
		let isActive = false
		if (link.route === 'fullscreen-dropdown') {
			// item that opens a dropdown
			Element = 'button'
			isActive = isMoreOpen
		} else if (!link.route.startsWith('/')) {
			// external links
			Element = 'a'
			props.href = link.route
		} else {
			Element = Link
			props.to = {
				pathname: link.route,
				state: getLocationStateFor(link.route, STATE_KEYS.restoreToTop),
			}
			isActive = linkIsActive(link)
		}

		return (
			<li
				key={link.route}
				onClick={onSelect}
				className={classNames(
					'py-2 lg:px-2.5 text-base text-primary-50 relative',
					link.on === 'fullscreen-hidden' && 'lg:hidden',
					link.route === 'fullscreen-dropdown' && 'hidden lg:flex',
					link.border &&
						'border-b-2 border-primary-50 lg:border-b-0 lg:!border-r-2 lg:pr-3'
				)}>
				<Element
					{...props}
					className={classNames('hover:underline', isActive && 'underline')}
					data-walkthrough={link.route + '-tab'}>
					{link.route === 'fullscreen-dropdown' ? (
						<>
							<div
								key={NAV_SHOW_MORE_ELEMENT_ID}
								id={NAV_SHOW_MORE_ELEMENT_ID}
								onClick={() => setIsMoreOpen(!isMoreOpen)}
								className="cursor-pointer flex items-center gap-1">
								{link.icon && <link.icon />}
								{link.text}
							</div>
							{isMoreOpen ? (
								<Tooltip
									key={NAV_SHOW_MORE_ELEMENT_ID + '-tooltip'}
									className="dropdown-menu [&&]:pointer-events-all [&&]:opacity-100 shadow-lg [&&&&]:bg-primary-400"
									border={`1px solid ${theme._cloudBlue}`}
									anchorSelect={`#${NAV_SHOW_MORE_ELEMENT_ID}`}
									isOpen={isMoreOpen}
									setIsOpen={setIsMoreOpen}
									closeEvents={{ click: true, dblclick: true }}
									globalCloseEvents={{ clickOutsideAnchor: true }}>
									<div className="text-left">
										{hiddenLinks.map(hiddenLink => {
											const active = linkIsActive(hiddenLink)

											return (
												<li key={hiddenLink.route}>
													<Link
														className={classNames(
															'text-primary-50 hover:underline block py-2 px-2',
															active && 'underline'
														)}
														aria-current={active ? 'page' : undefined}
														to={{
															pathname: hiddenLink.route,
															state: getLocationStateFor(
																hiddenLink.route,
																STATE_KEYS.restoreToTop
															),
														}}
														onClick={() => setIsMoreOpen(false)}>
														{hiddenLink.text}
														{hiddenLink.showNewUntil &&
															hiddenLink.showNewUntil.getTime() >
																Date.now() && <NewBubble />}
													</Link>
												</li>
											)
										})}
									</div>
								</Tooltip>
							) : null}
						</>
					) : (
						<div>
							{link.text}
							{link.showNewUntil && link.showNewUntil.getTime() > Date.now() && (
								<NewBubble />
							)}
						</div>
					)}
				</Element>
			</li>
		)
	})
}

const NewBubble = () => {
	return (
		<Bubble className="rounded-full py-1 px-2 text-sm w-fit h-fit tracking-tighter z-10 -mt-1 ml-1 lg:absolute lg:top-[-2px] lg:right-2 lg:py-[2px] lg:px-2">
			NEW!
		</Bubble>
	)
}

const Bubble = styled.div`
	${({ theme }) => `
		background-color: ${theme.secondary};
		color: ${theme.white};
	`}
`

/**
 * A list of tabs to navigate between the profile route and other routes defined in `profileLinks`
 */
export function ProfileNav(): React$Node {
	const currentPath = useLocation().pathname

	const isAdminOnLicense = useIsLicenseAdmin()
	const isHomeInProfile = currentPath === '/profile'

	const profileKey = 'profile'
	const tabs = [
		{
			key: 'profile',
			tabNode: (
				<Link
					className={classNames(isHomeInProfile && 'active')}
					aria-current={isHomeInProfile ? 'page' : undefined}
					to="/profile">
					Profile
				</Link>
			),
			content: null,
		},
	]

	let startingTab = profileKey

	profileLinks.forEach(({ route, highlightedRoutes, text, icon: Icon }) => {
		const active =
			route === currentPath ||
			highlightedRoutes.some(highlightedRoute =>
				currentPath.toLowerCase().startsWith(highlightedRoute)
			)

		if (window.location.pathname.startsWith(route)) {
			startingTab = text
		}
		if (route === '/admin' && !isAdminOnLicense) {
			// do not show the admin tab if they are not an admin on their license
			return null
		}
		tabs.push({
			key: text,
			tabNode: (
				<Link
					className={classNames('flex items-center gap-2', active && 'active')}
					aria-current={active ? 'page' : undefined}
					to={route}>
					{Icon && <Icon className="icon" />} {text}
				</Link>
			),
			content: null,
		})
	})

	return <ControlledTabs tabs={tabs} startingTab={startingTab} tabsClassName="mt-4" />
}

// A button that links to the profile page
export const AccountNavButton: ComponentType<$Shape<{||}>> = props => {
	return (
		<Link
			className="rounded-full size-10 flex items-center justify-center hover:brightness-90"
			to="/profile">
			<FaUser size={25} />
		</Link>
	)
}
