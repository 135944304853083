import FaExclamation from 'react-icons/lib/fa/exclamation-triangle'
import React from 'react'
import styled from 'styled-components'
import type { StyledComponent } from 'styled-components'

/**
 * ErrorOccurred - a component for displaying errors in a standard way
 *
 * @param  {{children?:?React$Node}} {children} - A React$Node which explains the error
 *
 * @returns React$Node
 */
export function ErrorOccurred({ children }: { children?: ?React$Node }): React$Node {
	return (
		<StyledError>
			<FaExclamation />
			{children ?? null}
		</StyledError>
	)
}

const StyledError = styled.div`
	gap: var(--spacing1x-dont-use);
	color: ${({ theme }) => theme.error};
	display: flex;
	align-items: center;
`

export const ErrorLinkButton: StyledComponent<{||}, {||}, HTMLButtonElement> = styled.button`
	background-color: Transparent;
	border: 0px solid Transparent;
	padding: 0px;
	margin: 0px;
	color: inherit;
	text-decoration: underline;
`
