import React from 'react'
import { LightTheme as theme } from '../styles/theme'
import { View, Text, StyleSheet } from '@react-pdf/renderer'
import { getCategoriesForControlSet } from '../routes/analytics/StudentPage/constants'
import type { SelScores } from '../store/types'
import genericStyles from './sharedStyles'
import {
	getColorForProficiencyLevel,
	getTextColorForProficiencyLevelBackground,
} from './sharedStyles'
import { SCORES } from '../routes/analytics/AnalyticsCharts/ProficiencyBarGraph/constants'
import { useSortStudentsIntoLevels } from '../routes/analytics/AnalyticsCharts/ProficiencyBarGraph/ProficiencyBarGraph'
import type { SelScoresByEntityId } from '../models/Analytics'

/*
 Show class performance distribution for a specific mission in the pdf. 
 Almost identical to the ProficiencyBarGraph component in the browser: src/routes/analytics/AnalyticsCharts/ProficiencyBarGraph/ProficiencyBarGraph.js
*/
export default function ProficiencyBarChartPDF({
	controlSet,
	individualSelScores,
}: {
	controlSet: ?string,
	individualSelScores: SelScoresByEntityId,
}): React$Node {
	const categories = getCategoriesForControlSet(controlSet)

	return (
		<View style={{ width: '100%' }}>
			<Text style={[genericStyles.h1, { marginBottom: '16px' }]}>Mission Score</Text>
			<View
				style={{
					display: 'flex',
					flexDirection: 'row',
					width: '100%',
					alignItems: 'center',
				}}>
				<Legend />
				<View
					style={{
						borderLeft: '1px solid black',
						margin: '24px',
						height: '80%',
					}}
				/>
				<View style={{ textAlign: 'right' }}>
					{categories.map(category => (
						<Text
							key={category.key}
							style={[
								styles.listItem,
								{ justifyContent: 'flex-end', marginBottom: '10pt' },
							]}>
							{category.title}
						</Text>
					))}
				</View>
				<View style={{ flex: 1, marginLeft: 8 }}>
					{categories.map(({ title, accessor, key }) => (
						<View key={key} style={styles.listItem}>
							<ScoreRow
								accessor={accessor}
								title={title}
								individualSelScores={individualSelScores}
							/>
						</View>
					))}
				</View>
			</View>
		</View>
	)
}

// Legend that explains the class proficiency color scores.
function Legend() {
	return (
		<View>
			<View style={{ marginBottom: '8px' }}>
				{SCORES.map(({ level, description }) => (
					<View style={styles.listItem} key={level}>
						<View
							style={[
								styles.colorCoin,
								{ backgroundColor: getColorForProficiencyLevel(level) },
							]}
						/>
						<Text>
							{level} - {description}
						</Text>
					</View>
				))}
			</View>
			<View style={styles.legend}>
				<View style={styles.listItem}>
					<View style={styles.lineSegment} />
					<Text style={{ marginLeft: '8pt' }}>- Class Average</Text>
				</View>
			</View>
		</View>
	)
}

// A row to display how the class scored in a specific analytics category.
function ScoreRow({
	individualSelScores,
	accessor,
	title,
}: {
	individualSelScores: SelScoresByEntityId,
	accessor: SelScores => ?number,
	title: string,
}) {
	const { classAverage, levelsByStudentId, scoredStudentCount } = useSortStudentsIntoLevels(
		accessor,
		individualSelScores
	)
	const firstVisibleLevel =
		levelsByStudentId.findIndex(studentsInLevel => studentsInLevel.length) + 1
	return (
		<View style={styles.scoreRow}>
			{levelsByStudentId.map((studentsInLevel, levelIndex) => {
				const level = levelIndex + 1
				const id = `level-${level}-${title}`
				const percentStudentsAtLevel = Math.round(
					(studentsInLevel.length / scoredStudentCount) * 100
				)
				const showClassAverage = Math.floor(classAverage) === level

				return percentStudentsAtLevel ? (
					<View
						key={id}
						style={[
							styles.scoreSegment,
							{
								width: `${percentStudentsAtLevel}%`,
								backgroundColor: getColorForProficiencyLevel(level),
								color: getTextColorForProficiencyLevelBackground(level),
								marginLeft:
									level > firstVisibleLevel && percentStudentsAtLevel
										? '2pt'
										: '0pt',
							},
						]}>
						<Text>{percentStudentsAtLevel}%</Text>
						{showClassAverage && (
							<View
								style={[
									styles.lineSegment,
									{ left: `${(classAverage - level) * 100}%` },
								]}
							/>
						)}
					</View>
				) : null
			})}
		</View>
	)
}
const styles = (StyleSheet.create({
	scoreRow: {
		width: '100%',
		borderRadius: '8pt',
		height: '16pt',
		display: 'flex',
		flexDirection: 'row',
		overflow: 'hidden',
	},
	scoreSegment: {
		textAlign: 'center',
		fontSize: '8pt',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		flexDirection: 'row',
		position: 'relative',
	},
	listItem: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		marginBottom: '8px',
	},
	lineSegment: {
		position: 'absolute',
		top: 0,
		height: '16pt',
		width: '0pt',
		borderLeft: '2pt solid black',
	},
	legend: {
		backgroundColor: theme.neutralLight,
		padding: '8px',
		borderRadius: '8pt',
	},
	colorCoin: {
		borderRadius: '8pt',
		height: '16pt',
		width: '16pt',
		marginRight: '8pt',
	},
}): { [string]: {} })
