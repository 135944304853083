import React, { useRef, useEffect, useMemo } from 'react'
import { toast } from 'react-toastify'
import { config } from '../../config'
import { useUser } from '../../services/hooks'

/**
 * A popup toast that will display based on if there is a user logged in value in local storage
 */
export default function useSessionEndedPopup() {
	const toastShown = useRef()
	const { user, isLoading } = useUser()

	const userHasLoggedIn = useMemo(() => hasUserLoggedIn(), [])

	useEffect(() => {
		if (!toastShown.current && !isLoading && !user && userHasLoggedIn) {
			toast.error(
				<div>
					Your session has timed out! Please click{' '}
					<a
						href={`${config.loginClientUrl}?sendto=${window.location.toString()}`}
						className="underline inline text-sun">
						here
					</a>{' '}
					to log in!
				</div>,
				{
					toastId: 'session-timeout-toast-id',
					position: 'top-center',
					autoClose: false,
					draggable: false,
					closeOnClick: false,
					onOpen: () => {
						toastShown.current = true
					},
				}
			)
		}
	}, [user, userHasLoggedIn, isLoading])
}

const USER_LOGGED_IN_KEY = 'mission.io_user_logged_in'

/**
 * Returns true if the USER_LOGGED_IN_KEY is set in local storage, false otherwise
 */
function hasUserLoggedIn(): boolean {
	try {
		return Boolean(localStorage.getItem(USER_LOGGED_IN_KEY))
	} catch (e) {
		console.log('error getting if user has logged in from local storage', e)
		return false
	}
}

/**
 * Sets USER_LOGGED_IN_KEY in local storage to true or removes it from local storage based on param
 * @param val boolean of whether to set user_logged_in_key in local storage or remove it
 */
export function setUserLoggedInLocalStorage(val: boolean) {
	try {
		if (val) localStorage.setItem(USER_LOGGED_IN_KEY, val.toString())
		else localStorage.removeItem(USER_LOGGED_IN_KEY)
	} catch (e) {
		console.log('error setting user logged in value in local storage', e)
	}
}
