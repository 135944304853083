// @flow
import React, { createContext, useMemo, useState, useContext, useEffect } from 'react'
import type { FolderMap } from '../../utility/FileHelpers'
import { useLocation } from 'react-router-dom'

export type FilesToBrowse = {
	folderMap: FolderMap, // the map of folders to files
	startingFolder: string, // the path to start the file browser at
}

const FileBrowserContext = createContext<{
	folderMap: ?FilesToBrowse,
	setFolderMap: (fileSrc: ?FilesToBrowse) => void,
}>({
	folderMap: null,
	setFolderMap: () => {},
})

/**
 * FileBrowserProvider - context used for the fileBrowser to easily show a file and folder structure to the user
 *
 * @param {{children:React$Node}} {children} - the children the context should be applied to (should have one FileBrowser component so structure can be shown the user)
 *
 * @return {React$Node} - the context provider
 */
export function FileBrowserProvider({ children }: { children: React$Node }): React$Node {
	const [folderMap, setFolderMap] = useState<?FilesToBrowse>(null)

	const value = useMemo(() => ({ folderMap, setFolderMap }), [folderMap])

	// close file browser when navigating to a new page
	const { pathname } = useLocation()
	useEffect(() => {
		setFolderMap(null)
	}, [pathname])

	return <FileBrowserContext.Provider value={value}>{children}</FileBrowserContext.Provider>
}

/**
 * useFileBrowserFolderMap - get the current folder and file structure to show to the user
 *
 * @return {?FilesToBrowse}
 */
export function useFileBrowserFolderMap(): ?FilesToBrowse {
	return useContext(FileBrowserContext).folderMap
}

/**
 * useShowFolderMapInFileBrowser - a callback to set the folder and file structure to show to the user
 *
 * @return {(filesToBrowse: ?FilesToBrowse) => void}
 */
export function useShowFolderMapInFileBrowser(): (filesToBrowse: ?FilesToBrowse) => void {
	return useContext(FileBrowserContext).setFolderMap
}
