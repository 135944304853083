import useInterval from '@use-it/interval'
import 'styled-components/macro'

import React, { useLayoutEffect, useRef, useState, useEffect } from 'react'

const HORIZONTAL_FPS = 20
const VERTICAL_FPS = 8
const STEP = 1
const HORIZONTAL_TIMEOUT = (1 / HORIZONTAL_FPS) * 1000
const VERTICAL_TIMEOUT = (1 / VERTICAL_FPS) * 1000
const BUFFER = 10 // Adds a buffer to the marquee so that letters don't get cut off at the end of the scroll.

type Props = {|
	children: React$Node,
	autoplay?: boolean,
	stopOnHover?: boolean,
	loop?: boolean,
	className?: string,
	leadingTime?: number,
	trailingTime?: number,
	scrollDirection?: 'vertical' | 'horizontal',
	bufferForNoScroll?: number,
	shouldScroll?: boolean,
	onMouseLeaveOverride?: () => void,
|}

/**
 * A marquee component that scrolls text from right to left. By default, does not scroll until hovered.
 *
 * @param props
 * @param props.children The text to scroll
 * @param props.autoplay Whether to scroll automatically. If true, will never pause unless stopOnHover is true.
 * @param props.stopOnHover Whether to pause when hovered. This is only relevant if autoplay is true.
 * @param props.loop Whether the scrolling loops
 * @param props.className A css class to add to the component
 * @param props.leadingTime How long to wait before starting the animation
 * @param props.trailingTime How long to wait after the animation completes before resetting the animation
 * @param props.scrollDirection Whether to scroll vertically or horizontally
 * @param props.bufferForNoScroll Use this if you want to prevent scrolling when the children is only slightly larger than the container
 */
export function Marquee({
	children,
	autoplay = false,
	stopOnHover = false,
	loop = false,
	className,
	leadingTime = 0,
	trailingTime = 0,
	scrollDirection = 'horizontal',
	bufferForNoScroll = 0,
	shouldScroll = false,
	onMouseLeaveOverride,
}: Props): React$Node {
	const containerRef = useRef(null)
	const textRef = useRef(null)
	const trailingTimeoutRef = useRef(null)
	// How much the text has moved to the left
	const [animatedLength, setAnimatedLength] = useState(0)
	// How much the text overflows the container
	const [overflowLength, setOverflowLength] = useState(0)

	const [_isAnimating, setIsAnimating] = useState(autoplay)
	const isAnimating = shouldScroll ?? _isAnimating

	const startAnimation = () => {
		setIsAnimating(true)
	}

	const stopAnimation = () => {
		setAnimatedLength(0)
		setIsAnimating(false)
	}

	useEffect(() => {
		if (!shouldScroll && !autoplay) stopAnimation()
	}, [shouldScroll, autoplay])

	useLayoutEffect(() => {
		setOverflowLength(
			containerRef.current && textRef.current
				? scrollDirection === 'vertical'
					? textRef.current.offsetHeight - containerRef.current.offsetHeight
					: textRef.current.offsetWidth - containerRef.current.offsetWidth
				: 0
		)
	}, [scrollDirection])

	const textIsOverflowing = overflowLength > bufferForNoScroll

	// The time until the next animation frame
	let intervalTime = null
	if (textIsOverflowing && isAnimating) {
		if (animatedLength === 0 && leadingTime) {
			intervalTime = leadingTime
		} else if (scrollDirection === 'vertical') {
			intervalTime = VERTICAL_TIMEOUT
		} else {
			intervalTime = HORIZONTAL_TIMEOUT
		}
	}

	useInterval(() => {
		const newAnimatedLength = animatedLength + STEP

		if (newAnimatedLength <= overflowLength + BUFFER) {
			// mid animation

			setAnimatedLength(newAnimatedLength)
		} else {
			// Completed the animation

			if (!loop) {
				setIsAnimating(false)
			} else if (trailingTime) {
				if (!trailingTimeoutRef.current) {
					trailingTimeoutRef.current = setTimeout(() => {
						setAnimatedLength(0)
						trailingTimeoutRef.current = null
					}, trailingTime)
				}
			} else {
				setAnimatedLength(0)
			}
		}
	}, intervalTime)

	const style: { [string]: mixed } = {
		position: 'relative',
	}
	if (scrollDirection === 'vertical') {
		style.bottom = animatedLength
	} else {
		style.right = animatedLength
		style.whiteSpace = 'nowrap'
	}

	return (
		<div
			ref={containerRef}
			className={className}
			css="overflow: hidden; max-height: 100%; position: relative;"
			onMouseEnter={() => {
				if (stopOnHover) {
					setIsAnimating(false)
				} else if (textIsOverflowing && !autoplay) {
					startAnimation()
				}
			}}
			onMouseLeave={() => {
				if (onMouseLeaveOverride) {
					onMouseLeaveOverride()
					return
				}
				if (stopOnHover && textIsOverflowing && autoplay) {
					startAnimation()
				} else if (!autoplay) {
					stopAnimation()
				}
			}}>
			<span
				ref={textRef}
				style={style}
				title={typeof children === 'string' ? children : undefined}>
				{children}
			</span>
		</div>
	)
}
