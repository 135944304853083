import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import 'styled-components/macro'
import { Button } from '@mission.io/styles'
import { GoBackButton } from '../../../../styles/sharedComponents'
import { ModalFooter, ModalBody } from './../../../../components/modal/Modal'
import ChooseStudentTeams from '../DetailComponents/AssignRoles/DragDropComponents/ChooseStudentTeams'
import { getJuniorPlusTeams, getJuniorTeams } from '../../../../store/missionPrep'
import { juniorTeamsToTeams, normalizeTeams } from '../DetailComponents/AssignRoles/RolesHelpers'
import { Loading } from '../../../../components'

import type { JuniorTeam } from '../../../../store/types'
import type { ClassType } from '../../../../models/ClassType'

type Props = {
	isJuniorPlusMission: boolean,
	simulationId: string,
	crew: ClassType,
	goBack: () => mixed,
	onSave: (newTeams: JuniorTeam[]) => void,
}

export default function AssignRolesTab({
	isJuniorPlusMission,
	simulationId,
	crew,
	goBack,
	onSave,
}: Props): React$Node {
	const dispatch = useDispatch()
	const [teamsAcceptable, setTeamsAcceptable] = useState(false)
	const juniorTeams = useSelector(getJuniorPlusTeams)
	const [teams, setTeams] = useState(null)
	useEffect(() => {
		if (!juniorTeams) {
			dispatch(getJuniorTeams(simulationId, crew.students.length))
		} else {
			setTeams(juniorTeamsToTeams(crew, juniorTeams))
		}
	}, [juniorTeams, setTeams, dispatch, crew, simulationId])

	const handleSave = () => {
		if (teams) {
			onSave(normalizeTeams(teams))
			goBack()
		}
	}

	return (
		<>
			<ModalBody>
				{isJuniorPlusMission ? (
					teams ? (
						<ChooseStudentTeams
							_class={crew}
							setTeamsAcceptable={setTeamsAcceptable}
							teams={teams}
							setTeams={setTeams}
							simulationId={simulationId}
						/>
					) : (
						<Loading className="my-8" size="small" />
					)
				) : (
					<div>
						There{"'"}s been some mistake. This mission does not require students to be
						assigned roles.
					</div>
				)}
			</ModalBody>
			<ModalFooter>
				<GoBackButton outline onClick={goBack}>
					Go Back
				</GoBackButton>
				<Button disabled={!teamsAcceptable} onClick={handleSave}>
					Save Roles
				</Button>
			</ModalFooter>
		</>
	)
}
