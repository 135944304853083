import React, { useContext } from 'react'
import Modal, { type ModalProps } from 'react-modal'
import styled, { ThemeContext } from 'styled-components/macro'
import { rgba } from '../../utility/functions'
import FaClose from 'react-icons/lib/fa/close'
import Close from 'react-icons/lib/io/ios-close-empty'
import type { StyledType } from 'global-types'
import classnames from 'classnames'
import type { ThemeType } from '@mission.io/styles'

type Props = {
	shouldCloseOnEsc?: boolean,
	shouldCloseOnOverlayClick?: boolean,
	className?: string,
	isOpen: boolean,
	children: React$Node,
	onRequestClose?: () => void,
	style?: {
		overlay?: Object,
		content?: Object,
	},
	onAfterOpen?: () => void,
	aria?: {|
		labelledby: string,
		describedby?: string,
	|},
}

export const MODAL_Z_INDEX = 200

function getDefaultStyles(theme: ThemeType) {
	return {
		overlay: {
			zIndex: MODAL_Z_INDEX,
			background: `${rgba(theme.white, 0.5)}`,
		},
		content: {
			position: 'absolute',
			top: '3rem',
			left: '50%',
			right: 'auto',
			bottom: 'auto',
			display: 'block',
			transform: 'translate(-50%, 0%)',
			width: '75%',
			height: 'fit-content',
			maxHeight: '80vh',
			overflowY: 'auto',
			boxShadow: `0 3px 6px rgb(0 0 0 / 0.1)`,
			WebkitOverflowScrolling: 'touch',
			borderRadius: '0.5rem',
			outline: 'none',
			padding: '0',
		},
	}
}

export default function CustomModal({ children, style, ...passThroughProps }: Props): React$Node {
	const themeContext: ThemeType = useContext(ThemeContext)
	const defaultStyle = getDefaultStyles(themeContext)

	const finalStyle = style
		? {
				overlay: {
					...defaultStyle.overlay,
					...style.overlay,
				},
				content: {
					...defaultStyle.content,
					...style.content,
				},
		  }
		: defaultStyle

	return (
		<StyledModal
			{...passThroughProps}
			className={classnames(passThroughProps.className, 'bg-primary-800')}
			ariaHideApp={false}
			style={finalStyle}
			role="dialog">
			{children}
		</StyledModal>
	)
}

const StyledModal = styled(Modal)`
	::-webkit-scrollbar {
		${({ theme }) => `
			background-color: ${theme.neutral};
		`}
	}
`

export const ModalHeader: StyledType<> = styled.div.attrs(props => ({
	className: classnames(
		'font-base text-lg font-semibold py-2 px-3 bg-primary-600',
		props.className
	),
}))`
	display: flex;
	justify-content: space-between;
	align-items: center;
`

export const ModalBody: StyledType<> = styled.div.attrs(props => ({
	className: classnames('p-6', props.className),
}))`
	max-height: 80vh;
	overflow-y: auto;
`

export const ModalFooter: StyledType<> = styled.div.attrs(props => ({
	className: classnames('p-2 flex gap-2 border-t border-primary-50', props.className),
}))`
	justify-content: flex-end;
	align-items: center;
`

export const ButtonTitle: StyledType<> = styled.div.attrs(props => ({
	className: classnames('font-base font-semibold mb-2', props.className),
}))``

export const ExitX: StyledType<> = styled(FaClose)`
	cursor: pointer;
	font-size: 1.2em;
	text-align: center;
`

/** *****************************
New minimal dialog box
********************************/

/**
 * Dialog with a more minimalist style. A main difference from `CustomModal` above is that there is no header or footer.
 */
export function MinimalDialog({
	onClose,
	style: providedStyle,
	children,
	...passAlongProps
}: {
	...ModalProps,
	onClose?: () => mixed,
	children?: React$Node,
}): React$Node {
	const style: $PropertyType<ModalProps, 'style'> = {
		...providedStyle,
		// $FlowFixMe[cannot-spread-inexact] We want to override these default styles
		overlay: {
			zIndex: MODAL_Z_INDEX,
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
			...providedStyle?.overlay,
		},
	}

	return (
		<MinimalModal
			shouldCloseOnOverlayClick
			onRequestClose={() => onClose?.()}
			{...passAlongProps}
			style={style}>
			{onClose && (
				<IconButton
					Icon={Close}
					onClick={() => onClose()}
					css="position: absolute; top: var(--spacing2x-dont-use); right: var(--spacing2x-dont-use);"
				/>
			)}
			{children}
		</MinimalModal>
	)
}

const MinimalModal = styled(Modal).attrs(({ className }) => ({
	className: classnames('rounded-lg bg-primary-400 shadow-primary-300', className),
}))`
	position: absolute;
	padding: var(--spacing2x-dont-use);
	max-height: 95vh;
	overflow-y: auto;
	overflow-x: hidden;
`

/**
 * A small button with an icon.
 * @param {react-icon} Icon The icon from react-icons to use in the button
 * @param {Function} onClick The function to run when the button is clicked
 * @param {?string} className An optional className to apply to the button
 */
function IconButton({
	Icon,
	onClick,
	className,
}: {
	Icon: ({ size: string | number }) => React$Node,
	onClick: () => mixed,
	className?: string,
}) {
	return (
		<button
			onClick={() => onClick()}
			className={classnames(
				'rounded-full p-1 border-none text-[--primary] bg-[--app-lightest-gray]',
				className
			)}>
			<Icon size="1.5em" />
		</button>
	)
}
