import React, { useState } from 'react'
import classnames from 'classnames'

type TabDescriptor = {|
	key: string, // the unique id for the tab
	tabNode: React$Node, // what to show in the tab
	content: React$Node, // the content of the tab
|}

/**
 * ControlledTabs - show a list of tabs which, when clicked, will show the content pertaining to that tab
 *
 * @param {Object} props - the react props
 * @param {Array<TabDescriptor>} props.tabs - a list of the tabs to allow users to pick from
 * @param {?string} props.startingTab? - the key of the tab to start selected
 * @param {?string} props.tabsClassName? - the className of the tab wrapper
 * @param {?(string) => any} props.onTabChange? - a callback for when the tab change. The argument will be the key of the tab which was changed to
 *
 * React$Node
 */
export default function ControlledTabs({
	startingTab,
	tabs,
	tabsClassName,
	onTabChange,
}: {
	tabs: Array<TabDescriptor>,
	startingTab?: ?string,
	tabsClassName?: ?string,
	onTabChange?: ?(selectedTab: string) => any,
}): React$Node {
	const [_selectedTabKey, _setSelectedTabKey] = useState(startingTab)
	const setSelectedTabKey = (newKey: string) => {
		_setSelectedTabKey(newKey)
		if (onTabChange) {
			onTabChange(newKey)
		}
	}

	let selectedTab = tabs.find(({ key }) => key === _selectedTabKey)
	if (!selectedTab && tabs.length) {
		selectedTab = tabs[0]
	}

	let selectedTabKey = selectedTab?.key

	return (
		<>
			<div role="tablist" className={classnames('flex flex-row gap-4', tabsClassName)}>
				{tabs.map(({ key, tabNode }) => {
					const selected = selectedTabKey === key

					return (
						<div
							key={key}
							onClick={() => setSelectedTabKey(key)}
							className={classnames(
								'font-normal pb-2 text-xl hover:text-primary-100 cursor-pointer',
								selected && 'font-bold border-b-[3px] border-b-primary-500'
							)}
							role="tab"
							aria-selected={selected}
							aria-controls={key}
							aria-expanded={selected}>
							{tabNode}
						</div>
					)
				})}
			</div>
			{selectedTab?.content ? <div className="mt-2">{selectedTab.content}</div> : null}
		</>
	)
}
