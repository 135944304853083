import React, { useContext } from 'react'
import styled, { ThemeContext } from 'styled-components/macro'
import { MenuList, GradeInput } from './MenuList'
import { GRADE_ARRAY } from '../../../store/missionPrep'
import { EmptyComponent, CheckMenuList } from '../MissionMenuList'
import type { SelectOption } from '../../../routes/analytics/analyticsHelpers'
import type { Category } from '../../../store/categories'
import { Select } from '../../inputs/Select'

export const ALL = 'All'
export const INITIAL_GRADE_FILTER = ALL
export const INITIAL_CREATION_GRADE_FILTER = GRADE_ARRAY[0]

export const GRADE_FILTER_ARRAY: string[] = [ALL, ...GRADE_ARRAY]

type GradeType = string
type CategoriesType = Array<SelectOption>
type GradesType = Array<SelectOption>

type FilterType = {
	grade?: {
		id?: string,
		label?: string,
		value: GradeType,
		set: GradeType => mixed,
	},
	grades?: {
		id?: string,
		label?: string,
		values: GradesType,
		set: SetState<GradesType>,
	},
	category: {
		id?: string,
		label?: string,
		values: CategoriesType,
		set: CategoriesType => mixed,
	},
}

type DisabledType = {
	categories?: ?Set<string>,
}

/**
 * A component that contains the shared filters that are used in dashboard. Filter values must be provided
 * in props.
 * @param props
 * @param {FilterType} props.filters
 * @param props.filterOptions - optional, the options to include in the filters. if not provided, defaults to using all options.
 * @param props.categorySelector - a redux selector to get the categories available to be filtered by
 */
export default function Filters({
	filters,
	allowMultiCategory = true,
	creating,
	disabledValues,
	filterOptions,
	categories: reduxCategories,
	portalToBody,
}: {
	filters: FilterType,
	allowMultiCategory?: boolean,
	creating?: ?boolean,
	disabledValues?: ?DisabledType,
	filterOptions?: {
		categories: Array<Category>,
	},
	categories: Array<Category>,
	portalToBody?: boolean,
}): React$Node {
	const themeContext = useContext(ThemeContext)

	const { category, grades, grade } = filters

	// checks to see if a given category is selected and included in categoryFilter
	const isCategorySelected = id => Boolean(category.values?.some(val => val.value === id))

	const isGradeSelected = (id: string) =>
		Boolean(grades && grades.values.some(val => val.value === id))

	/** adds item to categoryFilter if its not already selected, otherwise removes the item from the category filter */
	const onCategoryClick = (selected: boolean, value: string, label: string) => {
		if (selected) {
			let index = category.values
				? category.values.findIndex(item => item.value === value)
				: -1
			if (index > -1) {
				let newFilter = [...category.values]
				newFilter.splice(index, 1)
				category.set(newFilter)
			}
		} else {
			const filterItem = { value, label }
			const newCategories = category.values ? [...category.values, filterItem] : [filterItem]
			category.set(newCategories)
		}
	}

	// Props that should be passed to all Select components
	const sharedReactSelectProps = {
		menuPortalTarget: portalToBody ? document.body : null,
		styles: {
			// portaled menus should be above everything
			menuPortal: base => ({ ...base, zIndex: 9999 }),
		},
	}

	return (
		<>
			<GradeWrapper className="grade-wrapper z-20">
				<label
					htmlFor={`${(grades?.id || grade?.id) ?? 'gradeSelect'}`}
					className="text-sm mb-0">
					{`${(grade?.label || grades?.label) ?? 'Grade'}`}
				</label>
				{grade && (
					<Select
						placeholder=""
						inputId={`${grade.id ?? 'gradeSelect'}`}
						value={{
							value: grade.value,
							label: grade.value,
						}}
						onChange={({ value }) => {
							grade.set(value)
						}}
						options={(creating ? GRADE_ARRAY : GRADE_FILTER_ARRAY).map(grade => ({
							value: grade,
							label: grade,
						}))}
						{...sharedReactSelectProps}
					/>
				)}
				{grades && (
					<Select
						isMulti
						inputId={`${grades.id ?? 'gradeSelect'}`}
						placeholder=""
						closeMenuOnSelect={false}
						value={grades.values}
						options={GRADE_ARRAY.map(grade => ({
							value: grade,
							label: grade,
						}))}
						isSelected={isGradeSelected}
						onChange={values => grades.set(values)}
						components={{
							MultiValueContainer: EmptyComponent,
							ClearIndicator: EmptyComponent,
							MenuList: CheckMenuList,
							Input: GradeInput,
						}}
						{...sharedReactSelectProps}
					/>
				)}
			</GradeWrapper>

			<div data-walkthrough="subject-select">
				<label htmlFor={`${category.id ?? 'categories'}`} className="text-sm mb-0">
					{category.label ?? 'Subject'}
				</label>
				<Select
					className="z-20"
					inputId={`${category.id ?? 'categories'}`}
					closeOnSelect={!allowMultiCategory}
					value={category.values}
					isMulti={allowMultiCategory}
					categories={reduxCategories}
					isSelected={isCategorySelected}
					onChange={values => category.set(values)}
					onCategoryClick={onCategoryClick}
					components={{ MenuList }}
					theme={{ dashboardTheme: themeContext }}
					disabledValues={disabledValues?.categories || new Set()}
					{...sharedReactSelectProps}
				/>
			</div>
		</>
	)
}

const GradeWrapper = styled.div`
	display: flex;
	justify-content: flex-end;
	flex-direction: column;
`
